import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fill?: string;
}

export const ReportsIcon: React.FC<IProps> = ({ className, fill = '#fff' }) => {
	const height = 20;
	const width = 20;
	return (
		<SvgIcon className={className} height={20} viewBox={`0 0 ${width} ${height}`} width={20}>
			<g fill={fill} fillRule='evenodd' transform='translate(0 2)'>
				<path d='M17.9971,14 L2.0001,14 L2.0001,2 L18.0001,2 L17.9971,14 Z M18.0001,0 L2.0001,0 C0.8971,0 0.0001,0.897 0.0001,2 L0.0001,14 C0.0001,15.103 0.8971,16 2.0001,16 L18.0001,16 C19.1031,16 20.0001,15.103 20.0001,14 L20.0001,2 C20.0001,0.897 19.1031,0 18.0001,0 L18.0001,0 Z' />
				<polygon points='7 8 5 8 5 12 7 12' />
				<polygon points='11 6 9 6 9 12 11 12' />
				<polygon points='15 7 13 7 13 12 15 12' />
			</g>
		</SvgIcon>
	);
};
