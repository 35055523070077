import { StyleSheet } from 'aphrodite';
import { borderOutline, destructive, label, navigation, titles, warningBackground } from '../../../styles/colors';
import { CssSelectors, Layouts } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	calendarInPageContainer: {
		borderRadius: '3px',
		boxShadow: '0 1px 3px 0 rgba(0,0,0,0.20)',
		padding: '10px 0',
	},
	calendarPopoverContent: {
		boxSizing: 'border-box',
		padding: '10px 0',
	},
	calendarPopoverContentHeader: {
		alignItems: 'center',
		borderBottom: `1px solid ${borderOutline}`,
		display: 'flex',
		fontSize: 12,
		justifyContent: 'space-between',
		margin: '0 20px 20px 20px',
		padding: '10px 0',
	},
	calendarPopoverContentHeaderLeft: {
		...Layouts.horizontalStack(8),
	},
	container: {
		textAlign: 'center',
	},
	dateTimeInputs: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		marginTop: 16,
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	dateTimeInputsInPage: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		marginTop: 16,
		...CssSelectors.allChildren(
			{
				marginTop: 16,
			},
			':not(:first-child)'
		),
	},
	icon: {},
	inputButton: {
		height: 40,
		width: 110,
	},
	inputButtonPlaceholder: {
		color: navigation,
	},
	inputButtonText: {
		alignItems: 'center',
		color: titles,
		display: 'flex',
		fontSize: 14,
		justifyContent: 'space-between',
	},
	message: {
		color: label,
		fontSize: 14,
		lineHeight: '18px',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: 15,
		maxWidth: 400,
		textAlign: 'center',
	},
	sendingLoader: {
		marginTop: 20,
	},
	setScheduleButton: {
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: 20,
	},
	timeDropdown: {
		...CssSelectors.allChildren(
			{
				minWidth: 120,
			},
			'.select-box-menu'
		),
	},
	timeDropdownOption: {
		fontSize: 14,
	},
	timeDropdownTrigger: {
		fontSize: 14,
		height: 'auto',
		width: 108,
	},
	title: {
		color: titles,
		fontSize: 18,
		marginTop: 26,
	},
	warningMessage: {
		background: warningBackground,
		border: `1px solid ${destructive}`,
		borderRadius: 20,
		color: label,
		fontSize: 14,
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: 20,
		maxWidth: 400,
		padding: '10px 20px',
	},
	whyRandom: {
		maxWidth: 400,
		padding: 20,
		textAlign: 'center',
	},
	whyRandomAnswer: {
		fontSize: 12,
		maxWidth: 400,
		padding: 20,
	},
});
