import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	ccEmployeeOptions: {
		color: '#767676',
		display: 'block',
		fontSize: 14,
	},
	ccEmployeeOptionsInputs: {
		alignItems: 'center',
		display: 'flex',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	ccEmployeeSearchField: {
		flexGrow: 1,
	},
	daysInput: {
		textAlign: 'center',
		width: 30,
	},
	followUpReminderLabel: {
		alignItems: 'center',
		display: 'flex',
	},
	option: {
		':not(:first-child)': {
			marginTop: 8,
		},
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		minHeight: 25,
	},
	sendOnBehalfDisabledWarning: {
		color: '#767676',
		fontSize: 12,
		fontStyle: 'italic',
		maxWidth: 300,
	},
	textInput: {
		margin: '0 5px',
		padding: 5,
	},
	warningMessage: {
		alignItems: 'center',
		display: 'inline-flex',
		marginTop: 5,
		padding: 5,
	},
	warningMessageContent: {
		fontSize: 11,
	},
});
