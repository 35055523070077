import { css } from 'aphrodite';
import { inject } from 'mobx-react';
import * as React from 'react';
import { EnvironmentKey, IEnvironmentComponentProps } from '../../../../models/AppState';
import { baseStyleSheet } from '../../../styles/styles';
import { SearchIcon } from '../../svgs/icons/SearchIcon';
import { styleSheet } from './styles';

interface IProps
	extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
		IEnvironmentComponentProps {
	className?: string;
	compactLayout?: boolean;
	hideCancelButton?: boolean;
	hideSaveButton?: boolean;
	saveButtonDisabled?: boolean;
	onCancelButtonClicked?(e: React.MouseEvent<HTMLButtonElement>): void;
	onRenderInput?(input: JSX.Element): void;
	onSaveButtonClicked?(e: React.MouseEvent<HTMLButtonElement>): void;
}

export const _InlineValueEditor: React.FC<IProps> = ({
	className,
	compactLayout,
	disabled,
	environment,
	hideCancelButton,
	hideSaveButton,
	saveButtonDisabled,
	onCancelButtonClicked,
	onRenderInput,
	onSaveButtonClicked,
	...restProps
}: IProps) => {
	const input = (
		<div className={css(styleSheet.inputWrap)}>
			<SearchIcon className={css(styleSheet.searchIcon)} />
			<input
				className={css(styleSheet.input, compactLayout ? styleSheet.inputCompact : null)}
				disabled={disabled}
				{...restProps}
			/>
		</div>
	);
	return (
		<div
			className={`${css(styleSheet.container)} inline-value-editor ${className || ''}`}
			style={
				environment.appType === 'plugin' || compactLayout
					? {
							flexWrap: 'wrap',
						}
					: {}
			}
		>
			{onRenderInput ? onRenderInput(input) : input}
			<button
				className={css(baseStyleSheet.ctaButtonSmall)}
				style={environment.appType === 'plugin' ? { marginTop: '8px', flex: '1 0' } : {}}
				disabled={saveButtonDisabled}
				onClick={onSaveButtonClicked}
			>
				<span>Save</span>
			</button>
			{!hideCancelButton && (
				<button
					className={css(baseStyleSheet.brandLink)}
					style={environment.appType === 'plugin' ? { marginTop: '8px', flex: '1 0' } : {}}
					disabled={disabled}
					onClick={onCancelButtonClicked}
				>
					<span>Cancel</span>
				</button>
			)}
		</div>
	);
};

export const InlineValueEditor = inject(EnvironmentKey)(_InlineValueEditor);
