import * as Api from '@ViewModels';
import * as React from 'react';

export const convertHtmlValueToPostcardText = (html: string) => {
	const el = document.createElement('div');
	el.innerHTML = html;
	const placeholderEls = el.querySelectorAll('span[data-placeholder="true"]');
	placeholderEls.forEach(placeholderEl => (placeholderEl.outerHTML = `{{${placeholderEl.innerHTML}}}`));
	return el.textContent;
};

export const HANDWRITTEN_CARD_TEXT_MAX_ALLOWED_CHARACTERS = 400;

export function usePostcardTextEditor({
	defaultHandwritingStyle = Api.HandwritingStyle.Aladdin,
	handwritingStyleEnabled = true,
	handwritingStyle: hwStyle,
	content,
	onContentChanged,
	onHandwritingStyleChanged,
}: {
	handwritingStyle?: Api.HandwritingStyle;
	handwritingStyleEnabled?: boolean;
	content?: string;
	defaultHandwritingStyle?: Api.HandwritingStyle;
	onContentChanged?: (value: string) => void;
	onHandwritingStyleChanged?: (value: Api.HandwritingStyle) => void;
} = {}) {
	const [handwritingStyle, setHandwritingStyle] = React.useState<Api.HandwritingStyle>(defaultHandwritingStyle);
	const [postcardContent, setPostcardContent] = React.useState('');
	const [touched, setTouched] = React.useState(false);
	const contentControlled = content != null;
	const hwStyleControlled = hwStyle != null;
	const contentValue = contentControlled ? content : postcardContent;
	const hwValue = hwStyleControlled ? hwStyle : handwritingStyle;
	const postcardText = React.useMemo(() => {
		return convertHtmlValueToPostcardText(contentValue);
	}, [contentValue]);
	const handleContentChanged = (value: string) => {
		setTouched(true);
		if (contentControlled) {
			onContentChanged?.(value);
		} else {
			setPostcardContent(value);
		}
	};
	const handleHandwritingStyleChanged = (value: Api.HandwritingStyle) => {
		if (hwStyleControlled) {
			onHandwritingStyleChanged?.(value);
		} else {
			setHandwritingStyle(value);
		}
	};

	const getPostcardTextEditorProps = () => {
		return {
			...(handwritingStyleEnabled && {
				handwritingStyle: hwValue,
				onChangeHandwritingStyle: handleHandwritingStyleChanged,
			}),
			onChangePostcardContent: handleContentChanged,
			postcardContent: contentValue,
		};
	};
	return {
		getPostcardTextEditorProps,
		handwritingStyle,
		postcardContent: contentValue,
		postcardText,
		touched,
	};
}
