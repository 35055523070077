import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fill?: string;
}

export const ScheduleMeetingIcon: React.FC<IProps> = ({ className, fill = '#A7ABAD' }) => {
	const height = 20;
	const width = 20;
	return (
		<SvgIcon className={className} height={height} viewBox={`0 0 ${width} ${height}`} width={width}>
			<g id='DESIGN' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
				<g id='Schedule-Meeting-Copy' transform='translate(-972.000000, -139.000000)' fill={fill}>
					<g id='Fill-1718-+-Fill-1719-+-Fill-1720' transform='translate(972.000000, 139.000000)'>
						<path
							d='M17.9971,18 L2.0001,18 L2.0001,7 L17.9991,7 L17.9971,18 Z M18.0001,3 L17.0001,3 L17.0001,0 L15.0001,0 L15.0001,5 L14.0001,5 L14.0001,3 L7.0001,3 L7.0001,0 L5.0001,0 L5.0001,5 L4.0001,5 L4.0001,3 L2.0001,3 C0.8971,3 0.0001,3.898 0.0001,5 L0.0001,18 C0.0001,19.103 0.8971,20 2.0001,20 L18.0001,20 C19.1031,20 20.0001,19.103 20.0001,18 L20.0001,5 C20.0001,3.898 19.1031,3 18.0001,3 L18.0001,3 Z'
							id='Fill-1718'
						/>
						<polygon id='Fill-1719' points='8 13 4 13 4 9 8 9' />
						<polygon id='Fill-1720' points='13 13 9 13 9 9 13 9' />
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
