import { brandPrimary } from '../../styles/colors';
import { CssSelectors } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	body: {
		color: '#4A4A4A',
		fontSize: '14px',
		letterSpacing: 0,
		margin: '20px 0',
	},
	footer: {
		flexWrap: 'wrap',
		justifyContent: 'center',
	},
	footerVertical: {
		...CssSelectors.allChildren({
			marginLeft: 'auto',
			marginRight: 'auto',
		}),
		textAlign: 'center',
	},
	header: {
		textAlign: 'right',
	},
	icon: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		marginBottom: 20,
	},
	title: {
		color: brandPrimary,
		fontSize: '18px',
		lineHeight: '22px',
		marginBottom: 10,
		textAlign: 'center',
	},
});
