import { StyleSheet } from 'aphrodite';
import { brandPrimary } from '../../styles/colors';
import { BrowserMediaQueries } from '../../styles/styles';

const searchSectionHeight = 60;

export const styleSheet = StyleSheet.create({
	addCampaignButton: {
		display: 'inline-flex',
		justifyContent: 'flex-end',
	},
	buttonAnchor: {
		alignItems: 'center',
		backgroundColor: brandPrimary,
		border: '1px solid #ccc',
		borderRadius: 8,
		display: 'inline-flex',
		fontSize: 14,
		justifyContent: 'space-between',
		padding: '10px 15px',
		width: '100%',
	},
	buttonContainer: {
		display: 'flex',
		marginRight: 20,
	},
	buttonText: {
		color: 'white',
		height: '100%',
		paddingLeft: 14,
		width: '100%',
	},
	menuButton: {
		border: '0 !important',
		display: 'block',
		padding: '8px 4px !important',
		width: '100%',
	},
	menuContainer: {
		width: '100% !important',
	},
	menuStyles: {
		background: '#fff',
		color: brandPrimary,
		flexDirection: 'column',
		justifyContent: 'center',
		left: 0,
		minWidth: 100,
		overflowY: 'initial',
		position: 'absolute',
		top: 0,
		width: '100%',
	},
	newCampaignButton: {
		flexGrow: 1,
		marginLeft: 12,
		maxHeight: searchSectionHeight,
		maxWidth: 350,
		[BrowserMediaQueries.IE11]: {
			flexGrow: 0,
		},
	},
	soloButton: {
		height: 40,
		width: '100%',
	},
});
