import { css } from 'aphrodite';
import { ContactViewModel } from '../../../../extViewmodels';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { useScheduleMeetingSettings } from '../../../containers/ScheduleMeeting/useScheduleMeetingSettings';
import { white } from '../../../styles/colors';
import { CalendarDateIcon } from '../../svgs/icons/CalendarDateIcon';
import { styleSheet } from './styles';

export const CalendarButton = ({
	contact,
	onBookAppointmentBtnClicked,
}: {
	contact: ContactViewModel;
	onBookAppointmentBtnClicked?: (url: string) => void;
}) => {
	const userSession = useUserSession();
	const { meetingConfigs } = useScheduleMeetingSettings({ user: userSession.user! });
	const firstPublishedMeeting = meetingConfigs?.filter(config => config.isPublished)?.[0];

	const onBookAppointmentBtnClick = () => {
		const url = firstPublishedMeeting?.link?.replace(
			`${firstPublishedMeeting.shortCode}/${firstPublishedMeeting.vanityPath}`,
			`${firstPublishedMeeting.shortCode}?landing=true&contactId=${contact.id}`
		);
		if (url) {
			onBookAppointmentBtnClicked?.(url);
		}
	};

	return userSession?.user?.emailProviderFeatures?.calendar?.canAddUpdateEvents &&
		userSession?.account?.features?.meetingScheduler?.enabled ? (
		<a className={css(styleSheet.quickActionButton)} onClick={onBookAppointmentBtnClick}>
			<CalendarDateIcon fillColor={white} width={16} height={14} />
		</a>
	) : (
		<></>
	);
};
