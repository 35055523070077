import { StyleSheet } from 'aphrodite';
import {
	borderColor,
	brandPrimaryText,
	mentionDark,
	navigation,
	tagBackgroundColor,
	titles,
} from '../../styles/colors';
import { CssSelectors, truncateTextStyle } from '../../styles/styles';

export const styleSheet = StyleSheet.create({
	container: {
		background: '#fff',
		border: `1px solid ${borderColor}`,
		borderRadius: 3,
		boxShadow: '0 0 6px 0 rgba(139,139,139,0.30)',
		boxSizing: 'border-box',
		marginTop: 2,
		padding: '21px 0 25px',
		width: 360,
	},
	highlightContact: {
		background: tagBackgroundColor,
	},
	highlightTag: {
		background: tagBackgroundColor,
		...CssSelectors.allChildren(
			{
				background: mentionDark,
			},
			'.tag'
		),
	},
	inputContainer: {
		alignItems: 'center',
		background: '#f9f9f9',
		border: `1px solid #ccc`,
		borderRadius: 3,
		boxSizing: 'border-box',
		display: 'flex',
		height: '100%',
		width: '100%',
		...CssSelectors.allChildren(
			{
				height: 21,
				margin: '0 2px 0 14px',
				width: 21,
			},
			'svg'
		),
		...CssSelectors.allChildren(
			{
				background: 'none',
				border: 'none',
				boxSizing: 'border-box',
				color: titles,
				fontSize: '14px',
				height: '100%',
				outline: 'none',
				padding: 10,
				width: '100%',
			},
			'input'
		),
	},
	loadingSpinnerContainer: {
		position: 'relative',
	},
	resultContentContainer: {
		alignItems: 'flex-end',
		display: 'flex',
		maxWidth: 279,
		...CssSelectors.allChildren(
			{
				display: 'inline-block',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
			},
			'span'
		),
		...CssSelectors.allChildren(
			{
				flexShrink: 0,
				fontSize: '16px',
			},
			'span:first-child'
		),
		...CssSelectors.allChildren(
			{
				color: navigation,
				flexShrink: 1,
				fontSize: '12px',
				marginLeft: 7,
			},
			'span:last-child'
		),
		...CssSelectors.allChildren(
			{
				textTransform: 'capitalize',
			},
			'span:last-child:not(.noResults)'
		),
	},
	resultItem: {
		':hover': {
			background: tagBackgroundColor,
			cursor: 'pointer',
			...CssSelectors.allChildren(
				{
					background: mentionDark,
				},
				'.tag'
			),
		},
		alignItems: 'center',
		display: 'flex',
		padding: '4px 10px 4px 45px',
		...truncateTextStyle,
		...CssSelectors.allChildren(
			{
				flexShrink: 0,
				marginRight: 10,
			},
			'svg'
		),
	},
	tag: {
		alignItems: 'center',
		background: tagBackgroundColor,
		borderRadius: 30,
		display: 'flex',
		fontSize: '12px',
		padding: '3px 10px',
	},
	title: {
		color: brandPrimaryText,
		fontSize: '11px',
		letterSpacing: 1,
		padding: '4px 0 4px 25px',
	},
});
