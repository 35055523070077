import { borderColor, brandPrimary, brandPrimaryActive, titles } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	cardListContainer: {
		marginTop: '0.5rem',
		maxHeight: 400,
		overflowY: 'auto',
		...CssSelectors.allChildren(
			{
				marginTop: '0.5rem',
			},
			':not(:first-child)'
		),
	},
	cardListEmpty: {
		color: brandPrimaryActive,
		fontSize: 14,
		padding: 20,
		textAlign: 'center',
	},
	cardListItem: {
		alignItems: 'center',
		border: `1px solid ${borderColor}`,
		borderRadius: 4,
		boxSizing: 'border-box',
		display: 'flex',
		padding: 20,
		width: '100%',
	},
	cardListItemCta: {
		...CssSelectors.allChildren(
			{
				background: 'none',
				color: brandPrimaryActive,
				fontWeight: 'normal',
			},
			'.cta-button-reverse-small, .cta-button-reverse-small:active'
		),
	},
	cardListItemImage: {
		display: 'block',
		width: '7rem',
	},
	cardListItemTitle: {
		color: brandPrimary,
		flex: 1,
		fontSize: 14,
		paddingLeft: 20,
		paddingRight: 20,
	},

	container: {
		minHeight: 540,
		minWidth: 520,
	},

	field: {
		marginTop: 10,
		maxWidth: '100%',
		width: 'auto',
	},
	fieldDisabled: {
		opacity: 0.5,
	},

	header: {
		padding: 20,
		paddingTop: 0,
		textAlign: 'center',
	},

	loadingContainer: {
		alignItems: 'center',
		backgroundColor: 'rgba(255, 255, 255, .7)',
		display: 'flex',
		justifyContent: 'center',
	},

	searchField: {
		position: 'relative',
	},
	searchFieldClear: {
		height: '100%',
		padding: '10px 15px',
		position: 'absolute',
		right: 0,
		top: 0,
	},

	trigger: {
		height: '40px',
		padding: 0,
		paddingRight: 15,
	},
	triggerLabel: {
		borderRight: '1px solid rgb(216,216,216)',
		color: '#D1d1d1',
		fontSize: 12,
		height: '100%',
		letterSpacing: 1.1,
		paddingLeft: 10,
		paddingRight: 10,
		paddingTop: 24,
		textTransform: 'uppercase',
		width: 100,
	},
	triggerText: {
		color: titles,
		paddingLeft: 10,
		width: '80%',
	},
});
