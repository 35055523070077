import {
	CardSize,
	IImpersonationContextComponentProps,
	IModalContext,
	ImpersonationContextKey,
	ModalChildComponentContextKey,
} from '@AppModels/.';
import { IErrorMessageComponentProps, IUserSessionComponentProps, UserSessionViewModelKey } from '@AppModels/AppState';
import { ITemplate } from '@ViewModels';
import { asModalComponent } from '@WebComponents/Modal';
import { NewMessageIcon } from '@WebComponents/svgs/icons/NewMessageIcon';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { CampaignType } from '../../../../../models/AdminModels';
import { IEventLoggingComponentProps } from '../../../../../models/Logging';
import { EmailCampaignBrowserViewModel, TemplateType } from '../../../../../viewmodels/AppViewModels';
import { CampaignSearch } from '../../../campaigns/CampaignSearch';
import { styleSheet } from './styles';

interface IProps
	extends IUserSessionComponentProps,
		IEventLoggingComponentProps,
		IModalContext<ITemplate>,
		IErrorMessageComponentProps,
		IImpersonationContextComponentProps {
	campaigns?: EmailCampaignBrowserViewModel;
	className?: string;
	hideCreateNewMessageOption?: boolean;
	onCreatNewMessageButtonClicked?(): void;
	onTemplateSelected?(template: ITemplate): void;
	title?: React.ReactNode;
}

class _MessageTemplateSelect extends React.Component<IProps> {
	private mCampaignBrowser: EmailCampaignBrowserViewModel;

	constructor(props: IProps) {
		super(props);
		const { campaigns } = props;
		this.mCampaignBrowser =
			// @ts-ignore
			campaigns || new EmailCampaignBrowserViewModel(props.userSession).impersonate(props.impersonationContext);
	}

	public render() {
		const { className, onCreatNewMessageButtonClicked, hideCreateNewMessageOption, title } = this.props;
		return (
			<div className={`${css(styleSheet.container)} message-template-select ${className || ''}`}>
				<div className={css(styleSheet.header)}>
					{title || 'Create a new message template or pick from an existing one...'}
				</div>
				<div className={css(styleSheet.body)}>
					{!hideCreateNewMessageOption && (
						<div className={css(styleSheet.column, styleSheet.createNewMessageColumn, styleSheet.box)}>
							<button className={css(styleSheet.createNewMessageButton)} onClick={onCreatNewMessageButtonClicked}>
								<NewMessageIcon />
								<span>Create new message</span>
							</button>
						</div>
					)}
					<div className={css(styleSheet.column, hideCreateNewMessageOption ? styleSheet.singleColumn : null)}>
						<CampaignSearch
							campaignBrowser={this.mCampaignBrowser}
							cardSize={CardSize.Large}
							cardCtaText='Select'
							onCampaignTemplateClicked={this.onTemplateSelected}
							styles={[styleSheet.campaignSearch]}
							showNewCampaignButtons={false}
							supportedCampaignTypes={[CampaignType.Email]}
						/>
					</div>
				</div>
			</div>
		);
	}

	private onTemplateSelected = (template: ITemplate) => {
		const { parentModal, onTemplateSelected } = this.props;
		if (template?.templateType === TemplateType.Email) {
			onTemplateSelected?.(template);
			parentModal?.onRequestClose(template, !template);
		}
	};
}

const MessageTemplateSelectAsObserver = observer(_MessageTemplateSelect);
export const MessageTemplateSelect = inject(
	UserSessionViewModelKey,
	ModalChildComponentContextKey,
	ImpersonationContextKey
)(MessageTemplateSelectAsObserver);

export const MessageTemplateSelectModal = asModalComponent(MessageTemplateSelect, {
	className: `${css(styleSheet.modal)} email-message-templates`,
	shouldCloseOnOverlayClick: false,
});
