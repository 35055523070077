import { navigation } from '../../styles/colors';
import { CssSelectors, truncateTextStyle } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

const itemSpacing = 8;

export const styleSheet = StyleSheet.create({
	attachment: {
		marginLeft: itemSpacing,
		marginTop: itemSpacing,
	},
	attachmentsContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		marginLeft: -itemSpacing,
		marginTop: -itemSpacing,
	},
	attachmentSize: {
		color: navigation,
		flexShrink: 0,
	},
	currentAttachment: {
		alignItems: 'center',
		background: '#EDEDED',
		borderRadius: 4,
		color: '#046CB6',
		cursor: 'pointer',
		display: 'flex',
		fontSize: 12,
		lineHeight: '22px',
		overflow: 'hidden',
		padding: 8,
		...CssSelectors.allChildren(
			{
				marginLeft: 5,
			},
			':not(:first-child)'
		),
	},
	currentAttachments: {
		overflow: 'hidden',
	},
	fileName: {
		maxWidth: 200,
		...truncateTextStyle,
	},
});
