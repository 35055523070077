import { brandSecondary, titles } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';
import warningGraphicImageUrl from '../../assets/warning-graphic.png';

export const styleSheet = StyleSheet.create({
	graphic: {
		margin: '30px auto 10px auto',
		backgroundImage: `url(${warningGraphicImageUrl})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'left center',
		backgroundSize: 90,
		width: 90,
		height: 68,
		paddingRight: 10,
	},
	title: {
		textAlign: 'center',
		color: brandSecondary,
		fontSize: 18,
		marginBottom: 15,
	},
	body: {
		textAlign: 'center',
		maxWidth: 350,
		color: titles,
		fontSize: 14,
		padding: '0 20',
	},
	bodyItem: {
		marginBottom: 10,
	},
	buttonContainer: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		marginTop: 30,
		textAlign: 'center',
		gap: 20,
	},
	closeButtonContainer: {
		textAlign: 'right',
	},
});
