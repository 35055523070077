import {
	EntityViewModel,
	IAccountTag,
	IContact,
	IDictionary,
	IOperationResultNoValue,
	ResourceAutoCompleteViewModelType,
	TagViewModel,
	getTypeForEntity,
} from '@ViewModels';
import { css } from 'aphrodite';
import { computed } from 'mobx';
import { Observer, inject, observer } from 'mobx-react';
import React from 'react';
import Measure, { ContentRect } from 'react-measure';
import { integrationSources } from '../../../../../extViewmodels/Utils';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '../../../../../models/AppState';
import { useErrorMessages, useUserSession } from '../../../../../models/hooks/appStateHooks';
import { IEventLoggingComponentProps, useEventLogging, withEventLogging } from '../../../../../models/Logging';
import { PersistentStorageManager } from '../../../../../models/Storage';
import { contactIsFromEnabledIntegration } from '../../../../../models/UiUtils';
import {
	AutomationTemplateViewModel,
	ContactViewModel,
	ICreateAutomationRequest,
	IntegrationSources,
	ResourceAutoCompleteViewModel,
	TagAlertViewModel,
	TagsViewModel,
	TemplateType,
} from '../../../../../viewmodels/AppViewModels';
import { brandPrimary } from '../../../../styles/colors';
import { baseStyleSheet } from '../../../../styles/styles';
import { DisclosureIcon } from '../../../svgs/icons/DisclosureIcon';
import { AddTagButton } from '../../../tags/AddTagButton';
import { Tag } from '../../../tags/Tag';
import { InlineValueEditor } from '../../InlineValueEditor';
import { TagSearchesStorageKey } from './hooks';
import { AddTagInput } from './presentation';
import { styleSheet } from './styles';
import './styles.less';

const MAX_TAGS = 6;

interface IProps extends IUserSessionComponentProps, IEventLoggingComponentProps, IErrorMessageComponentProps {
	disabled?: boolean;
	entity?: EntityViewModel;
	tagType: ResourceAutoCompleteViewModelType.AccountTag | ResourceAutoCompleteViewModelType.Tag;
	onRequestAddTag(tag: IAccountTag): void;
	compactLayout?: boolean;
	onPendingTagToSetHasAutomationTemplateId?: (automationTemplateId: IAccountTag) => void;
}
interface IState {
	disabled?: boolean;
	editingPendingTag?: boolean;
	highlightedAutoCompleteSuggestionIndex?: number;
	isAutocompleteOpen?: boolean;
	pendingTag?: string;
	popularTags?: string[];
	tagAlerts?: IDictionary<TagAlertViewModel>;
	tagsListMountedContentRect?: ContentRect;
}

class _TagsEditor extends React.Component<IProps, IState> {
	private mMounted = false;
	private mAutoCompleteVm: ResourceAutoCompleteViewModel<string | IAccountTag>;
	private tagsVm: TagsViewModel;

	private recentSearches: (string | IAccountTag)[] = [];

	public state: IState = {
		isAutocompleteOpen: false,
	};

	public UNSAFE_componentWillMount() {
		const { userSession, logEvent, entity, tagType } = this.props;

		this.mAutoCompleteVm = new ResourceAutoCompleteViewModel<string | IAccountTag>(userSession, {
			type: tagType,
			pageSize: 25,
		});

		this.tagsVm = new TagsViewModel(userSession);

		// get recommended tags
		const onComplete = (tags: string[]) => {
			if (this.mMounted) {
				this.setState({
					popularTags: tags,
				});
			}
		};
		const onError = (action: string) => (error: IOperationResultNoValue) => {
			logEvent(action, { ...error });
		};
		if (!entity || (!!entity && getTypeForEntity(entity.toJs()) === 'company')) {
			const promise = this.tagsVm.getTags({ sort: 'desc', sortBy: 'Stats.Total' }, MAX_TAGS);
			if (promise) {
				logEvent('LoadPopularTags');
				promise
					.then((opResult: any) => {
						onComplete((opResult.values || []).map((x: any) => x.tag));
					})
					.catch(onError('LoadPopularTags-Error'));
			}
		} else {
			const promise = this.tagsVm.getSuggestedTagsForContactsV2(false, 25);
			if (promise) {
				logEvent('LoadSuggestedTags');
				promise
					.then((tags: IAccountTag[]) => {
						onComplete((tags || []).map((x: IAccountTag) => x.tag) as string[]);
					})
					.catch(onError('LoadSuggestedTags-Error'));
			}
		}

		const nextState = this.getNextStateWithProps(this.props);
		if (nextState) {
			this.setState(nextState);
		}
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		const nextState = this.getNextStateWithProps(nextProps);
		if (nextState) {
			this.setState(nextState);
		}
	}

	public componentDidMount() {
		this.mMounted = true;
	}

	public componentWillUnmount() {
		this.mMounted = false;
	}

	public render() {
		const { editingPendingTag, pendingTag, disabled } = this.state;
		const { compactLayout } = this.props;

		return (
			<footer className={css(styleSheet.tagListItem, styleSheet.tagListItemFooter)} key='footer'>
				{!!editingPendingTag && (
					<div>
						<InlineValueEditor
							compactLayout={compactLayout}
							autoComplete='off'
							autoFocus={true}
							placeholder='Search Tags'
							disabled={disabled}
							saveButtonDisabled={disabled || this.suggestions.length === 0}
							id='tags-editor-input'
							onBlur={this.onInputBlur}
							onCancelButtonClicked={this.onClickCancelAddTag}
							onChange={this.onPendingTagChange}
							onFocus={this.onInputFocus}
							onKeyDown={this.onInputKeyDown}
							onRenderInput={this.onRenderAddTagInput}
							onSaveButtonClicked={() => this.addTag({ tag: pendingTag })}
							value={pendingTag || ''}
						/>
					</div>
				)}
				{!editingPendingTag && <AddTagButton disabled={disabled} onClick={() => this.addTag({ tag: pendingTag })} />}
			</footer>
		);
	}

	@computed
	private get suggestions() {
		const { pendingTag, popularTags } = this.state;
		const suggestions = pendingTag
			? this.mAutoCompleteVm.searchResults || []
			: !!popularTags && popularTags.length > 0
				? popularTags.slice(0, 3)
				: [];
		return suggestions;
	}

	private onRenderAddTagInput = (input: JSX.Element) => {
		return (
			<>
				<Observer>
					{() => {
						const { isAutocompleteOpen, highlightedAutoCompleteSuggestionIndex, pendingTag } = this.state;
						const storedContactsQueryStringValue = PersistentStorageManager.local.get(TagSearchesStorageKey);
						this.recentSearches = storedContactsQueryStringValue ? JSON.parse(storedContactsQueryStringValue) : [];
						return (
							<AddTagInput
								input={input}
								pendingTag={pendingTag}
								highlightedAutoCompleteSuggestionIndex={highlightedAutoCompleteSuggestionIndex}
								isAutocompleteOpen={isAutocompleteOpen}
								isBusy={!!this.mAutoCompleteVm.isBusy}
								suggestions={this.suggestions}
								searchResults={this.mAutoCompleteVm.searchResults}
								recentSearches={this.recentSearches}
								addTag={this.addTag}
							/>
						);
					}}
				</Observer>
			</>
		);
	};

	private getNextStateWithProps = (nextProps: IProps) => {
		const nextState: IState = {};
		if (nextProps.disabled !== this.state.disabled) {
			nextState.disabled = nextProps.disabled;
		}

		if (Object.keys(nextState).length > 0) {
			return nextState;
		}
	};

	private onClickCancelAddTag = () => {
		if (this.state.editingPendingTag) {
			this.setState({
				editingPendingTag: false,
				pendingTag: '',
			});
		}
	};

	private onInputBlur = () => {
		this.setState(
			{
				highlightedAutoCompleteSuggestionIndex: -1,
				isAutocompleteOpen: false,
			},
			() => {
				this.mAutoCompleteVm.reset();
			}
		);
	};

	private onInputFocus = () => {
		this.setState(
			{
				editingPendingTag: true,
				highlightedAutoCompleteSuggestionIndex: -1,
				isAutocompleteOpen: true,
			},
			() => {
				const { pendingTag } = this.state;
				if (pendingTag) {
					this.mAutoCompleteVm.setSearchQuery(pendingTag);
				}
			}
		);
	};

	private onInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		const { highlightedAutoCompleteSuggestionIndex, pendingTag } = this.state;
		switch (e.key) {
			case 'Enter': {
				if (highlightedAutoCompleteSuggestionIndex > -1) {
					let selectedSuggestion = this.suggestions[highlightedAutoCompleteSuggestionIndex];
					if (!this.state.pendingTag) {
						selectedSuggestion =
							highlightedAutoCompleteSuggestionIndex < 3
								? this.state.popularTags[highlightedAutoCompleteSuggestionIndex]
								: this.recentSearches[highlightedAutoCompleteSuggestionIndex - this.suggestions.length];
					}
					const accountTagToAdd =
						typeof selectedSuggestion === 'string' ? { tag: selectedSuggestion } : selectedSuggestion;
					this.addTag(accountTagToAdd);
				} else {
					this.addTag({ tag: pendingTag });
				}
				break;
			}
			case 'ArrowUp': {
				const index = highlightedAutoCompleteSuggestionIndex - 1;
				if (index >= -1) {
					this.setState({
						highlightedAutoCompleteSuggestionIndex: index,
					});
				}
				break;
			}
			case 'ArrowDown': {
				const index = highlightedAutoCompleteSuggestionIndex + 1;
				if (!this.state.pendingTag) {
					if (index < this.suggestions.length + this.recentSearches.length) {
						this.setState({
							highlightedAutoCompleteSuggestionIndex: index,
						});
					}
				} else {
					if (index < this.suggestions.length) {
						this.setState({
							highlightedAutoCompleteSuggestionIndex: index,
						});
					}
				}

				break;
			}
			default: {
				break;
			}
		}
	};

	private addTag = (pendingTag: IAccountTag) => {
		const { editingPendingTag } = this.state;

		if (!editingPendingTag) {
			this.setState({
				editingPendingTag: true,
			});
			return;
		}

		if (pendingTag.tag) {
			const { onRequestAddTag } = this.props;
			const indexOfMatchingRecentSearch = this.recentSearches.indexOf(pendingTag.tag);
			// tag not in recent searches array
			if (indexOfMatchingRecentSearch < 0) {
				this.recentSearches.unshift(pendingTag.tag);
				if (this.recentSearches.length > 10) {
					this.recentSearches.pop();
				}
			} else {
				this.recentSearches.unshift(this.recentSearches.splice(indexOfMatchingRecentSearch, 1)[0]);
			}
			PersistentStorageManager.local.setObject(TagSearchesStorageKey, this.recentSearches);
			const nextState: IState = {
				editingPendingTag: false,
				isAutocompleteOpen: false,
				pendingTag: '',
				highlightedAutoCompleteSuggestionIndex: -1,
			};

			const onFinish = (pendingTagToSet: IAccountTag) => {
				const { onPendingTagToSetHasAutomationTemplateId } = this.props;

				if (pendingTagToSet.automationTemplateId && onPendingTagToSetHasAutomationTemplateId) {
					onPendingTagToSetHasAutomationTemplateId(pendingTagToSet);
				}

				this.setState(nextState, () => {
					if (onRequestAddTag) {
						onRequestAddTag(pendingTagToSet);
					}
				});
			};

			if (!pendingTag.id) {
				const { userSession } = this.props;
				const tagVM = new TagViewModel(userSession, { tag: pendingTag.tag });
				this.setState({ isAutocompleteOpen: false });

				tagVM
					.load()
					?.then(result => {
						onFinish(result);
					})
					?.catch(() => {
						onFinish(pendingTag);
					});
			} else {
				onFinish(pendingTag);
			}
		} else {
			this.setState({
				pendingTag: pendingTag.tag,
			});
		}
	};

	private onPendingTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const pendingTag = e.target.value;
		this.setState(
			{
				highlightedAutoCompleteSuggestionIndex: -1,
				isAutocompleteOpen: true,
				pendingTag,
			},
			() => {
				this.mAutoCompleteVm.setSearchQuery(pendingTag);
			}
		);
	};
}

const TagsEditorAsObserver = observer(_TagsEditor);
const TagsEditorWithContext = inject(UserSessionViewModelKey, ErrorMessagesViewModelKey)(TagsEditorAsObserver);
export const TagsList = withEventLogging(TagsEditorWithContext, 'TagsEditor');

interface TagsListWithTagsEditorProps {
	className?: string;
	disabled?: boolean;
	entity?: EntityViewModel;
	tagType?: ResourceAutoCompleteViewModelType.AccountTag | ResourceAutoCompleteViewModelType.Tag;
	onRequestAddTag(tag: IAccountTag): void;
	onRequestRemoveTag(tag: IAccountTag): void;
	tags: IAccountTag[];
	compactLayout?: boolean;
}

export const TagsListWithTagsEditor = ({
	tags,
	entity,
	className,
	disabled,
	onRequestAddTag,
	onRequestRemoveTag,
	tagType,
	compactLayout,
}: TagsListWithTagsEditorProps) => {
	const [tagsListMountedContentRect, setTagsListMountedContentRect] = React.useState<ContentRect | null>(null);
	const [showAllTags, setShowAllTags] = React.useState(false);
	const [tagsWithAutomationLookup, setTagsWithAutomationLookup] = React.useState<
		Record<string, { tag: IAccountTag; automationVM: AutomationTemplateViewModel }>
	>({});
	const { logApiError } = useEventLogging('TagsEditor');
	const errorMessages = useErrorMessages();
	const userSession = useUserSession();

	const onPendingTagToSetHasAutomationTemplateId = (pendingTagToSet: IAccountTag) => {
		const automationVM = getAutomationVM(pendingTagToSet);
		setTagsWithAutomationLookup(existingTags => ({
			...existingTags,
			[pendingTagToSet.tag]: { tag: pendingTagToSet, automationVM },
		}));
	};
	const tagsWithAlerts = React.useMemo(
		() => (!!entity && getTypeForEntity(entity) !== 'company' ? (entity as IContact).tagsWithAlerts || [] : []),
		[entity]
	);

	const tagAlerts = React.useMemo(
		() =>
			tagsWithAlerts
				.map(x => new TagAlertViewModel(userSession, { tag: x }))
				.reduce<IDictionary<TagAlertViewModel>>((result, x) => {
					result[x.tagValue] = x;
					return result;
				}, {}),
		[tagsWithAlerts, userSession]
	);
	const getTags = () => {
		const tagsWithAutomationsLookupKeys = Object.keys(tagsWithAutomationLookup);
		return tags.map(accountTag => {
			if (tagsWithAutomationsLookupKeys.includes(accountTag.tag)) {
				return tagsWithAutomationLookup[accountTag.tag].tag;
			}
			return accountTag;
		});
	};
	const sortTags = () => {
		return getTags().sort((t1, t2) => {
			if (t1.tag.toLowerCase() < t2.tag.toLowerCase()) {
				return -1;
			}
			if (t1.tag.toLowerCase() > t2.tag.toLowerCase()) {
				return 1;
			}
			return 0;
		});
	};

	const showLimitTags = tags.length > MAX_TAGS;

	const sortedTags = sortTags();

	const tagsWithLimit = showLimitTags ? sortedTags.slice(0, MAX_TAGS) : sortedTags;

	const tagsToRender = showAllTags ? sortedTags : tagsWithLimit;

	const onListResize = (contentRect: ContentRect) => {
		setTagsListMountedContentRect(contentRect);
	};

	const toggleTagsLimit = () => {
		setShowAllTags(showAll => !showAll);
	};

	const getAutomationVM = (tag: IAccountTag) => {
		const automationViewModel = new AutomationTemplateViewModel(userSession, {
			id: tag.automationTemplateId,
			templateType: TemplateType.Automation,
		});
		automationViewModel.load()?.catch((err: IOperationResultNoValue) => {
			errorMessages.pushApiError(err);
			logApiError('LoadAutomationFromTag', err);
		});
		return automationViewModel;
	};

	type OnStartAutomationFunctionOrUndefined = (result?: ICreateAutomationRequest) => void | undefined;

	const getOnStartAutomation = (
		automationVM: AutomationTemplateViewModel | null
	): OnStartAutomationFunctionOrUndefined => {
		if (!automationVM) {
			return undefined;
		}
		return (result?: ICreateAutomationRequest) => {
			if (result) {
				automationVM.startForContacts(result).catch((err: IOperationResultNoValue) => {
					errorMessages.pushApiError(err);
					logApiError('StartAutomationForContacts', err);
				});
				return;
			}
			if (!entity) {
				return;
			}

			automationVM.createAutomationForContact(entity, true).catch((err: IOperationResultNoValue) => {
				errorMessages.pushApiError(err);
				logApiError('StartAutomationFromTag', err);
			});
		};
	};

	/**
	 * Method checks if tag can be removed
	 *
	 * @param item { IAccountTag } the account tag object
	 * @returns {boolean} True if the tag is not from integration (can delete).
	 *
	 *   - If the tag is from integration and the integration is enabled block tag from being deleted.
	 */
	const canDeleteTag = (item: IAccountTag): boolean => {
		const hasIntegrationSource = entity?.tagsCollection?.find(x => x.tag === item.tag)?.source;
		// if tag has a source
		if (hasIntegrationSource) {
			// if the tag is enabled and is a source from the integration.
			return (
				!contactIsFromEnabledIntegration(entity as ContactViewModel, userSession.account.integrations) &&
				integrationSources.includes(hasIntegrationSource as IntegrationSources)
			);
		}
		return true;
	};

	return (
		<div className={`tags-editor ${className || ''}`}>
			<Measure client={true} onResize={onListResize}>
				{({ measureRef }) => {
					return (
						<>
							<div className={css(styleSheet.tagList)} ref={measureRef}>
								{tagsToRender.map(tagObject => {
									const automationVM: AutomationTemplateViewModel | undefined =
										tagsWithAutomationLookup[tagObject.tag]?.automationVM;
									const onStartAutomation: OnStartAutomationFunctionOrUndefined = getOnStartAutomation(automationVM);
									return (
										<Tag
											automation={entity && automationVM}
											className={`${css(styleSheet.tagListItem)} truncate-text`}
											disabled={disabled}
											entity={entity}
											key={tagObject.tag}
											onRemoveButtonClicked={() => onRequestRemoveTag(tagObject)}
											onStartAutomationButtonClicked={onStartAutomation}
											showRemoveButton={canDeleteTag(tagObject)}
											style={
												tagsListMountedContentRect
													? {
															maxWidth: tagsListMountedContentRect.client.width,
														}
													: undefined
											}
											tagAlert={tagAlerts ? tagAlerts[tagObject.tag] : undefined}
											tagValue={tagObject}
										/>
									);
								})}
								<TagsList
									onRequestAddTag={onRequestAddTag}
									tagType={tagType}
									compactLayout={compactLayout}
									onPendingTagToSetHasAutomationTemplateId={onPendingTagToSetHasAutomationTemplateId}
								/>
							</div>
							{showLimitTags && (
								<button className={css(baseStyleSheet.brandLink)} onClick={toggleTagsLimit}>
									<span className={css(styleSheet.chevron)}>
										<DisclosureIcon
											type='chevron'
											className={css(styleSheet.chevronIcon, showAllTags && styleSheet.chevronFlip)}
											fillColor={brandPrimary}
										/>
									</span>
									<span>{showAllTags ? 'Hide Tags' : 'Show All Tags'}</span>
								</button>
							)}
						</>
					);
				}}
			</Measure>
		</div>
	);
};
