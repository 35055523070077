import { css } from 'aphrodite';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { SizeConstraint } from '../../../../models';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import { baseStyleSheet } from '../../../styles/styles';
import { BusinessPlanPlaceholderIcon } from '../../svgs/icons/BusinessPlanPlaceholderIcon';
import { styleSheet } from './styles';

interface IProps extends IEventLoggingComponentProps, RouteComponentProps<any> {
	className?: string;
	sizeConstraint?: SizeConstraint;
}

class _EntityAnalysisUpgradeHeader extends React.Component<IProps> {
	public render() {
		const { className, sizeConstraint } = this.props;
		return (
			<div className={`${css(styleSheet.container)} entity-analysis-upgrade-header ${className || ''}`}>
				{sizeConstraint !== 'compact' && <BusinessPlanPlaceholderIcon className={css(styleSheet.icon)} />}
				<div className={css(styleSheet.message, sizeConstraint === 'compact' ? styleSheet.messageCompact : null)}>
					<div className={css(styleSheet.messageTitle)}>Upgrade to Business Plan</div>
					<div className={css(styleSheet.messageBody)}>
						By upgrading to Business Plan, you can access analytics regarding your interaction with this individual.
					</div>
					<a
						className={css(baseStyleSheet.ctaButtonSmall, styleSheet.messageContactButton)}
						onClick={this.onContactUsButtonClicked}
						href='mailto:support@levitateapp.com'
					>
						Contact us
					</a>
				</div>
			</div>
		);
	}

	private onContactUsButtonClicked = () => {
		const { logInput } = this.props;
		// @ts-ignore
		logInput('UpgradeContactUs', 'Click');
	};
}

const EntityAnalysisUpgradeHeaderWithLogging = withEventLogging(
	_EntityAnalysisUpgradeHeader,
	'EntityAnalysisUpgradeHeader'
);
export const EntityAnalysisUpgradeHeader = withRouter(EntityAnalysisUpgradeHeaderWithLogging);
