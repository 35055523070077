import { darkGrayFontColor } from '../../../styles/colors';
import { CssSelectors, baseStyles } from '../../../styles/styles';
import { styles as bulletListStyles } from '../../entities/EntityInfoBulletList/styles';
import { CSSProperties, StyleSheet } from 'aphrodite';

const inputWidth = 130;

export const styleSheet = StyleSheet.create({
	bulletListItemTextField: {
		width: inputWidth,
	},
	bulletListItemTextFieldInput: {
		width: '100%',
	},
	container: {},
	editButton: {
		display: 'flex',
		position: 'absolute',
		right: 0,
		top: 0,
	},
	item: {
		...(bulletListStyles.item as CSSProperties),
		alignItems: 'flex-start',
		...CssSelectors.allChildren(
			{
				marginTop: 2,
			},
			'.entity-info-bullet-list-item-bullet'
		),
	},
	itemEditing: {
		...CssSelectors.allChildren(
			{
				marginTop: 14,
			},
			'.entity-info-bullet-list-item-bullet'
		),
	},
	newItemFooter: {},
	newItemFooterEditing: {
		alignItems: 'flex-start',
		height: 'auto',
		...CssSelectors.allChildren(
			{
				marginTop: 14,
			},
			'.entity-info-bullet-list-footer-icon'
		),
	},
	phoneNumber: {
		flexGrow: 1,
		position: 'relative',
	},
	phoneNumberEditor: {
		...CssSelectors.allChildren(
			{
				marginTop: 10,
			},
			':not(:first-child)'
		),
	},
	phoneNumberEditorRow: {
		alignItems: 'center',
		display: 'flex',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	phoneNumberSelectBox: {
		flexShrink: 0,
		width: inputWidth,
	},
	phoneNumberSelectBoxOption: {
		padding: 10,
	},
	phoneNumberSelectBoxTrigger: {
		...baseStyles.textField,
	},
	phoneNumberText: {
		marginRight: 5,
	},
	placeholder: {
		color: darkGrayFontColor,
	},
	sendTextButton: {
		// override specificity without using !important
		':nth-of-type(1n)': {
			':nth-of-type(1n)': {
				marginLeft: 'auto',
			},
		},
		display: 'flex',
		justifyContent: 'flex-end',
	},
});
