import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { getFileSizeStringValue } from '../../../../models/UiUtils';
import { DeprecatedCloseButton } from '../../DeprecatedCloseButton';
import { styleSheet } from './styles';

interface IProps {
	attachment: File;
	className?: string;
	error?: boolean;
	onClick?(attachment: File): void;
	onRemoveClick?(attachment: File): void;
}

export class NewAttachment extends React.PureComponent<IProps> {
	public render() {
		const { onClick, onRemoveClick, attachment, className, error } = this.props;

		const fileSizeStringValue = getFileSizeStringValue(attachment.size);
		const classes: StyleDeclarationValue[] = [styleSheet.container];
		if (onClick) {
			classes.push(styleSheet.clickable);
		}
		if (error) {
			classes.push(styleSheet.error);
		}

		return (
			<div
				className={`${css(...classes)} new-attachment ${className || ''}`}
				onClick={this.onAttachmentClicked}
				title={attachment.name}
			>
				<div className={`${css(styleSheet.fileName)}`}>{attachment.name}</div>
				<span className={css(styleSheet.attachmentSize)} title={fileSizeStringValue}>
					{fileSizeStringValue}
				</span>
				{!!onRemoveClick && <DeprecatedCloseButton onClick={this.onRemoveClicked} />}
			</div>
		);
	}

	private onAttachmentClicked = () => {
		const { attachment, onClick } = this.props;
		if (onClick) {
			onClick(attachment);
		}
	};

	private onRemoveClicked = () => {
		const { attachment, onRemoveClick } = this.props;
		if (onRemoveClick) {
			onRemoveClick(attachment);
		}
	};
}
