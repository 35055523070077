import * as React from 'react';

function getLocalStorageItem<T>({ key, initialValue }: { key: string; initialValue: T }) {
	const item = window.localStorage.getItem(key);
	if (item) {
		return JSON.parse(item);
	}
	return initialValue;
}

export function useLocalStorage<T>({ key, initialValue }: { key: string; initialValue: T }): [T, (value: T) => void] {
	const [storedValue, setStoredValue] = React.useState(() => getLocalStorageItem<T>({ key, initialValue }));
	const [prevKey, setPrevKey] = React.useState(key);
	if (key !== prevKey) {
		setPrevKey(key);
		setStoredValue(() => getLocalStorageItem<T>({ key, initialValue }));
	}
	const setValue = (value: T) => {
		setStoredValue(value);
		window.localStorage.setItem(key, JSON.stringify(value));
	};
	return [storedValue, setValue];
}
