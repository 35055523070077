import { TinyPopover } from '../TinyPopover';
import { QuestionMarkIcon } from '../svgs/icons/QuestionMarkIcon';
import './styles.less';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	/** Optional: In order of desired placement Not all options need to be supplied */
	iconColor?: string;
	preferredPlacement?: ('left' | 'right' | 'top' | 'bottom')[];
	renderAnchor?: React.ReactElement;
	styles?: StyleDeclarationValue[];
}

interface IState {
	isOpen?: boolean;
}

export class HelpTooltip extends React.Component<IProps, IState> {
	public readonly state: IState = { isOpen: false };

	private toggleVisibility = (isOpen: boolean) => () => {
		this.setState({ isOpen });
	};

	public render() {
		const { className, iconColor, styles, children, preferredPlacement, renderAnchor } = this.props;
		const { isOpen } = this.state;
		return (
			<TinyPopover
				onRequestClose={this.toggleVisibility(false)}
				dismissOnOutsideAction={true}
				anchor={
					<div
						className={`${css(...(styles || []))} helpTooltipIcon ${className || ''}`}
						onMouseEnter={this.toggleVisibility(true)}
						onMouseLeave={this.toggleVisibility(false)}
					>
						{renderAnchor ? renderAnchor : <QuestionMarkIcon fillColor={iconColor} />}
					</div>
				}
				isOpen={isOpen}
				placement={preferredPlacement}
			>
				<div className={isOpen ? 'helpTooltipContainer' : 'hide'}>{children}</div>
			</TinyPopover>
		);
	}
}
