import * as React from 'react';
import { brandPrimaryHover } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';

interface IProps {
	height?: number;
	width?: number;
	className?: string;
	fillColor?: string;
	onClick?: (e: React.MouseEvent<SVGSVGElement>) => void;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
}

export const SocialMediaIndicatorIcon: React.FC<IProps> = ({
	className = '',
	fillColor,
	onClick,
	onMouseEnter,
	onMouseLeave,
	width = 25,
	height = 25,
}) => {
	return (
		<SvgIcon
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			width={width}
			height={height}
			fill='none'
			className={`social-media-indicator-icon ${className}`}
			viewBox='0 0 25 25'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M24.8589 24.7751V7.29395C24.8589 3.42795 21.7249 0.293945 17.8589 0.293945H0.669922L24.8589 24.7751Z'
				fill={fillColor || brandPrimaryHover}
			/>
		</SvgIcon>
	);
};
