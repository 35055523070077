import { css } from 'aphrodite';
import { inject } from 'mobx-react';
import * as React from 'react';
import { useState } from 'react';
import { IAddress } from '../../../../extViewmodels';
import { IModalContext, ModalChildComponentContextKey } from '../../../../models';
import { IEnvironmentComponentProps, IToasterComponentProps } from '../../../../models/AppState';
import { useEnvironment } from '../../../../models/hooks/appStateHooks';
import { baseStyleSheet } from '../../../styles/styles';
import { LoadingSpinner } from '../../LoadingSpinner';
import { asModalComponent } from '../../Modal';
import { TextInput } from '../../TextInput';
import { CountrySelector } from '../../ValueSelectors/CountrySelector';
import { INameValue } from '../../ValueSelectors/ValueSelector';
import { styleSheet } from './styles';

export type INameExtendedAddress = IAddress & { firstName?: string; lastName?: string };

interface IProps extends IModalContext<any>, IEnvironmentComponentProps, IToasterComponentProps {
	initAddress?: INameExtendedAddress;
	isLoading?: boolean;
	onClickToSave: (updatedAddress: INameExtendedAddress) => void;
	withNames?: boolean;
	saveButtonLabel?: string;
}

const UpdateAddressModalComponent: React.FC<IProps> = ({
	initAddress,
	isLoading = false,
	onClickToSave,
	parentModal,
	withNames = false,
	saveButtonLabel = 'Save',
}) => {
	const environment = useEnvironment();
	// @ts-ignore
	const [address, setAddress] = useState<INameExtendedAddress>(initAddress);
	const handleClickToSave = () => {
		onClickToSave(address);
	};
	const handleFieldChange = (field: keyof INameExtendedAddress) => (ev: React.ChangeEvent<HTMLInputElement>) => {
		setAddress({
			...address,
			[field]: ev.target.value,
		});
	};
	const onCountryChanged = (country: INameValue) => {
		setAddress({
			...address,
			// @ts-ignore
			country: country.value !== 'None' ? country.value : null,
		});
	};
	return (
		<div
			className={css(
				styleSheet.modalContainer,
				environment.appType === 'plugin' ? styleSheet.pluginModalContainer : undefined
			)}
		>
			{isLoading ? (
				<div className={css(styleSheet.modalLoadingOverlay)}>
					<LoadingSpinner type='large' />
				</div>
			) : null}
			<div className={css(styleSheet.modalHeader)}>
				<h1 className={css(styleSheet.modalHeaderName)}>Add Address</h1>
			</div>
			{withNames ? (
				<div className={css(styleSheet.addresslineGroup)}>
					<div className={css(styleSheet.addresslineCol)}>
						<label className={css(styleSheet.label)} htmlFor='address-modal-firstName-input'>
							First Name
						</label>
						<TextInput
							name='firstName'
							inputId='address-modal-firstName-input'
							onChange={handleFieldChange('firstName')}
							type='text'
							value={address?.firstName}
						/>
					</div>
					<div className={css(styleSheet.addresslineCol)}>
						<label className={css(styleSheet.label)} htmlFor='address-modal-lastName-input'>
							Last Name
						</label>
						<TextInput
							name='lastName'
							inputId='address-modal-lastName-input'
							onChange={handleFieldChange('lastName')}
							type='text'
							value={address?.lastName}
						/>
					</div>
				</div>
			) : null}

			<label className={css(styleSheet.label)} htmlFor='address-modal-address1-input'>
				Address Line 1
			</label>
			<TextInput
				name='address1'
				inputId='address-modal-address1-input'
				onChange={handleFieldChange('address1')}
				type='text'
				value={address?.address1}
			/>
			<label className={css(styleSheet.label)} htmlFor='address-modal-address2-input'>
				Address Line 2
			</label>
			<TextInput
				name='address2'
				inputId='address-modal-address2-input'
				onChange={handleFieldChange('address2')}
				type='text'
				value={address?.address2}
			/>
			<div
				data-testid='address-modal-addresslineGroup'
				className={`${css(
					styleSheet.addresslineGroup,
					environment.appType === 'plugin' ? styleSheet.pluginAddresslineGroup : undefined
				)}`}
			>
				<div className={css(styleSheet.addresslineCol)}>
					<label className={css(styleSheet.label)} htmlFor='address-modal-city-input'>
						City
					</label>
					<TextInput
						name='city'
						inputId='address-modal-city-input'
						onChange={handleFieldChange('city')}
						type='text'
						value={address?.city}
					/>
				</div>
				<div className={css(styleSheet.addresslineCol)}>
					<label className={css(styleSheet.label)} htmlFor='address-modal-state-input'>
						State/Province
					</label>
					<TextInput
						name='stateProvince'
						inputId='address-modal-state-input'
						onChange={handleFieldChange('stateProvince')}
						type='text'
						value={address?.stateProvince}
					/>
				</div>
				<div className={css(styleSheet.addresslineCol)}>
					<label className={css(styleSheet.label)} htmlFor='address-modal-postalCode-input'>
						Zip/Postal Code
					</label>
					<TextInput
						name='postalCode'
						inputId='address-modal-postalCode-input'
						onChange={handleFieldChange('postalCode')}
						type='text'
						value={address?.postalCode}
					/>
				</div>
			</div>
			<label className={css(styleSheet.label)} htmlFor='address-modal-country-input'>
				Country
			</label>
			<CountrySelector onSelectionChanged={onCountryChanged} selectedValue={address?.country} />
			<div className={css(styleSheet.modalFooter)}>
				<button className={css(baseStyleSheet.ctaButton)} onClick={handleClickToSave}>
					<span>{saveButtonLabel}</span>
				</button>
				<button
					className={css(baseStyleSheet.outlineButtonContainer, styleSheet.customOutlineButton)}
					// @ts-ignore
					onClick={parentModal.onRequestClose}
				>
					<span>Cancel</span>
				</button>
			</div>
		</div>
	);
};

const UpdateAddressModalWithContext = inject(ModalChildComponentContextKey)(UpdateAddressModalComponent);

export const UpdateAddressModal = asModalComponent(UpdateAddressModalWithContext, {
	shouldCloseOnOverlayClick: false,
	useDefaultHeader: true,
});
