import { navigation } from '../../../styles/colors';
import { StyleSheet, css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	title: React.ReactNode;
}

export const styleSheet = StyleSheet.create({
	container: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: 8,
	},
	label: {
		color: navigation,
		fontSize: 12,
		letterSpacing: 0,
	},
});

export const EntityInfoFieldLabel: React.FC<IProps> = props => {
	const { className, title, children } = props;
	return (
		<div className={`${css(styleSheet.container)} entity-info-field-label ${className || ''}`}>
			<div className={css(styleSheet.label)}>{title}</div>
			{children}
		</div>
	);
};
