import { IAppBarContext, MutableAppBarContext } from '../AppBar/context';
import { FullscreenModalHeaderContext } from '@AppModels/.';
import { AppBarNavigationItemPortalDestinationId } from '@WebComponents/AppBar';
import { Portal } from '@WebComponents/Portal';
import { FullscreenModalHeader } from '@WebComponents/fullscreen/FullscreenModalHeader';
import { Observer } from 'mobx-react';
import * as React from 'react';

interface IProps extends IAppBarContext {
	appBarHeader?: React.ReactNode;
	appBarPortalDestinationId?: string;
	fullscreenHeader?: React.ReactNode;
	onFullscreenRequestBack?(e: React.MouseEvent<HTMLElement>): void;
	onFullscreenRequestClose?(e: React.MouseEvent<HTMLElement>): void;
	navHeader?: React.ReactNode;
}

export const MultiContainerHeader: React.FC<IProps> = props => {
	const {
		fullscreenHeader,
		appBarHeader,
		children,
		onFullscreenRequestClose,
		onFullscreenRequestBack,
		appBarPortalDestinationId,
		appBarContentStyles,
		appBarStyles,
		navHeader,
	} = props;

	const { setAppBarContentStyles, setAppBarStyles } = React.useContext(MutableAppBarContext) || {};

	React.useEffect(() => {
		if (appBarContentStyles || appBarStyles) {
			setAppBarContentStyles?.(appBarContentStyles);
			setAppBarStyles?.(appBarStyles);
			return () => {
				setAppBarContentStyles?.(null);
				setAppBarStyles?.(null);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!children && !appBarHeader && !fullscreenHeader && !navHeader) {
		return null;
	}
	return (
		<FullscreenModalHeaderContext.Consumer>
			{({ headerTitlePortalDestinationId, headerRightAccessoryPortalDestinationId }) => {
				if (navHeader) {
					return navHeader;
				}
				if (!!headerTitlePortalDestinationId || !!headerRightAccessoryPortalDestinationId) {
					return (
						<FullscreenModalHeader onRequestBack={onFullscreenRequestBack} onRequestClose={onFullscreenRequestClose}>
							<>{fullscreenHeader || children || null}</>
						</FullscreenModalHeader>
					);
				}
				return (
					<Portal destination={appBarPortalDestinationId || AppBarNavigationItemPortalDestinationId}>
						<Observer>
							{() => {
								return <>{appBarHeader || children || null}</>;
							}}
						</Observer>
					</Portal>
				);
			}}
		</FullscreenModalHeaderContext.Consumer>
	);
};
