import { header, inputBorderColor } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		background: '#fff',
		position: 'relative',
	},
	field: {
		background: '#F9F9F9',
		border: `1px solid ${inputBorderColor}`,
		borderRadius: '3px',
		color: '#4A4A4A',
		marginTop: 0,
		minWidth: 280,
	},
	fieldContainer: {
		':not(:first-child)': {
			marginTop: 10,
		},
	},
	fields: {
		marginBottom: 20,
		marginRight: 15,
		marginTop: 20,
	},
	label: {
		color: header,
	},
	title: {
		color: header,
		fontSize: '14px',
		textTransform: 'uppercase',
	},
	visibility: {
		marginBottom: 20,
		textAlign: 'left',
	},
	visibilityRadioButton: {
		':not(:first-child)': {
			marginTop: 10,
		},
		fontSize: '12px',
	},
});
