import { ContactViewModel } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { getDefaultDateStringValue, getDefaultTimeStringValue, getUnsubscribeReason } from '../../../../models/UiUtils';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	contact?: ContactViewModel;
	styles?: StyleDeclarationValue[];
}

export function ContactTextUnsubscribeInfo(props: IProps) {
	const { className = '', styles = [], contact } = props;
	// @ts-ignore
	const unsubscribeDate = contact.automatedSmsOptOutMetadata?.date;
	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} contact-text-unsubscribe-info ${className || ''}`}>
			{unsubscribeDate ? (
				<div>
					{`Contact unsubscribed ${getUnsubscribeReason(
						// @ts-ignore
						contact.automatedSmsOptOutMetadata
					)} on ${getDefaultDateStringValue(unsubscribeDate)} at ${getDefaultTimeStringValue(unsubscribeDate)}`}
				</div>
			) : null}
		</div>
	);
}
