import { IContact, IKeyFact, IOperationResultNoValue, IPhoneNumber } from '@ViewModels';
import { css } from 'aphrodite';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import * as AppState from '../../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import { dateIsToday, getMultilineFormattedAddress } from '../../../../models/UiUtils';
import { ContactViewModel, EmailType, IAddress } from '../../../../viewmodels/AppViewModels';
import { ContactInfoKeyFacts } from '../../../containers/Contact/components/ContactInfoKeyFacts';
import { ContactInfoTags } from '../../../containers/Contact/components/ContactInfoTags';
import { PostcardComposerStep } from '../../../containers/PostcardComposer';
import { baseStyleSheet, bs } from '../../../styles/styles';
import { Button } from '../../Button';
import { Checkbox } from '../../Checkbox';
import { HandwrittenCardTemplateSearchModal } from '../../HandwrittenCards/HandwrittenCardTemplateSearchModal';
import { LoadingSpinner } from '../../LoadingSpinner';
import { CustomFieldsEditor } from '../../entities/CustomFieldsEditor';
import { IAddPhoneNotification } from '../../entities/EntityInfoBulletList';
import { EntityInfoField } from '../../entities/EntityInfoField';
import { EntityInfoFieldLabel } from '../../entities/EntityInfoFieldLabel';
import { EntityInfoPhoneNumbers } from '../../entities/EntityInfoPhoneNumbers';
import { EntityInfoSection } from '../../entities/EntityInfoSection';
import { UpdateAddressModal } from '../../entities/UpdateAddressModal';
import { AIStarsIcon } from '../../svgs/icons/AIStars';
import { EditItemPenIcon } from '../../svgs/icons/EditItemPenIcon';
import { RoundAddIcon } from '../../svgs/icons/RoundAddIcon';
import { ContactEmailUnsubscribeInfo } from '../ContactEmailUnsubscribeInfo';
import { ContactInfoEmails } from '../ContactInfoEmails';
import { ContactInfoHeader } from '../ContactInfoHeader';
import { ContactTextUnsubscribeInfo } from '../ContactTextUnsubscribeInfo';
import { KitDropdown } from '../KitDropdown';
import { RelationshipHealthStatusBar } from '../RelationshipHealthStatusBar';
import { styleSheet } from './styles';

interface IProps
	extends AppState.IUserSessionComponentProps,
		IEventLoggingComponentProps,
		RouteComponentProps,
		AppState.IErrorMessageComponentProps,
		AppState.IQuickAddEntityComponentProps,
		AppState.ISingleEmailComposerComponentProps,
		AppState.IEnvironmentComponentProps,
		AppState.IFullscreenModalComponentProps {
	addPhoneNotification?: IAddPhoneNotification;
	className?: string;
	compactLayout?: boolean;
	contact?: ContactViewModel;
	disableCompanyLink?: boolean;
	showSendMessage?: boolean;
	showHwcActionButton: boolean;
	keyFactToHighlight?: IKeyFact;
	linkToProfile?: boolean;
	shouldPollForRelationshipHealth?: boolean;
	onPhoneNumberAdded?(contact: ContactViewModel): void;
	onPhoneNumberEdited?(origPhone: IPhoneNumber, newPhone: IPhoneNumber): void;
	onShowRelationshipAnalysisButtonClicked?(): void;
}

interface IState {
	addAddressModalOpen: boolean;
	phoneNumbers?: IPhoneNumber[];
	savingAsNote?: boolean;
	hwcTemplateChooserModalOpen: boolean;
}

class _ContactInfo extends React.Component<IProps, IState> {
	@observable private mMounted: boolean;
	private mPollingTimeoutHandle: any;
	public readonly state: IState = {
		addAddressModalOpen: false,
		hwcTemplateChooserModalOpen: false,
	};
	private mShouldPollForRelationshipHealth: boolean | undefined;

	public componentDidMount() {
		this.mMounted = true;
		this.mShouldPollForRelationshipHealth = false;
		if (this.props.shouldPollForRelationshipHealth) {
			this.mShouldPollForRelationshipHealth = this.props.shouldPollForRelationshipHealth;
		}
	}
	public componentDidUpdate() {
		if (this.props.shouldPollForRelationshipHealth) {
			this.mShouldPollForRelationshipHealth = this.props.shouldPollForRelationshipHealth;
		}
		if (this.mShouldPollForRelationshipHealth) {
			this.pollForRelationshipHealthUpdate();
		}
	}
	public componentWillUnmount() {
		this.mMounted = false;
		this.mShouldPollForRelationshipHealth = false;
		this.cancelRelationshipHealthPolling();
	}

	public render() {
		const { className, contact } = this.props;
		return (
			<div className={`${css(styleSheet.container)} contact-info ${className || ''}`}>
				{!!contact && !contact.isLoading ? (
					this.renderContent()
				) : (
					<LoadingSpinner className={css(styleSheet.loading)} type='large' />
				)}
			</div>
		);
	}

	private closeModal = (modalStateName: string) => {
		switch (modalStateName) {
			case 'addAddressModalOpen':
				this.setState({ addAddressModalOpen: false });
				break;
			case 'hwcTemplateChooserModalOpen':
				this.setState({ hwcTemplateChooserModalOpen: false });
				break;
			default:
		}
	};

	private renderContent() {
		const {
			addPhoneNotification,
			compactLayout,
			contact,
			disableCompanyLink,
			environment,
			fullscreenModal,
			showSendMessage,
			showHwcActionButton,
			keyFactToHighlight,
			linkToProfile,
		} = this.props;
		const { phoneNumbers, addAddressModalOpen, hwcTemplateChooserModalOpen } = this.state;

		const handleClickToSaveAddress = (address: IAddress) => {
			contact.setAddress(address).then(() => this.closeModal('addAddressModalOpen'));
		};

		return (
			<>
				<ContactInfoHeader
					className={css(styleSheet.header)}
					compactLayout={compactLayout}
					contact={contact}
					disableCompanyLink={disableCompanyLink}
					linkToProfile={linkToProfile}
					showEmailQuickBtn={showSendMessage && !!contact.emailAddresses && contact.emailAddresses.length > 0}
					showHwcActionButton={showHwcActionButton}
					onEmailQuickBtnClicked={this.onShowSendMessageModal}
					onPostageBtnClicked={this.handleOpenHwcModal}
					onBookAppointmentBtnClicked={this.handleBookAppointClick}
				/>
				<KitDropdown className={css(styleSheet.kitDropdown)} contact={contact} />
				<RelationshipHealthStatusBar
					className={css(styleSheet.relationshipHealth)}
					contact={contact}
					environment={environment}
				/>
				<div className={css(bs.mt5)}>
					<ContactInfoKeyFacts
						compactLayout={compactLayout}
						contactVm={contact}
						keyFactToHighlight={keyFactToHighlight}
					/>
					<CustomFieldsEditor contact={contact} />
					<ContactInfoTags contactVm={contact} />
				</div>

				<EntityInfoSection hideBorder={true}>
					<div className={css(styleSheet.fieldEntitySection)}>
						<Checkbox
							checked={!!(contact.tags || []).find(x => !!x && x.toLocaleLowerCase() === 'unsubscribe')}
							className={css(styleSheet.unsubscribeCheckbox)}
							id='email-unsubscribe-checkbox'
							onChange={this.onUnsubscribeFromEmailCheckboxChanged}
						>
							<span>Unsubscribe this contact from my group emails</span>
						</Checkbox>
						<ContactEmailUnsubscribeInfo contact={contact} />
						<div>
							<EntityInfoFieldLabel title='Email'>
								{showSendMessage && !!contact.emailAddresses && contact.emailAddresses.length > 0 && (
									<div className={css(styleSheet.sendButtonStack)}>
										<button className={css(baseStyleSheet.ctaButtonSmall)} onClick={this.onShowSendMessageModal}>
											<span>Send Email</span>
										</button>
										{this.props.userSession?.account?.features?.contentGeneration?.enabled ? (
											<Button
												kind='reverse'
												size='small'
												onClick={this.onShowSendMessageModalWithAI}
												label={
													<span>
														<AIStarsIcon />
														&nbsp; AI Email
													</span>
												}
											/>
										) : null}
									</div>
								)}
							</EntityInfoFieldLabel>
							<ContactInfoEmails compactLayout={compactLayout} contact={contact} />
						</div>
					</div>
					<div className={css(styleSheet.fieldEntitySection)}>
						<Checkbox
							checked={!!contact.automatedSmsOptOutMetadata}
							className={css(styleSheet.unsubscribeCheckbox)}
							id='text-unsubscribe-checkbox'
							onChange={this.onUnsubscribeFromTextCheckboxChanged}
						>
							<span>Unsubscribe this contact from texting</span>
						</Checkbox>
						<ContactTextUnsubscribeInfo contact={contact} />
						<div>
							{!contact.phoneNumbers ||
								(contact.phoneNumbers.length === 0 ? <EntityInfoFieldLabel title='Phone Number' /> : null)}
							<EntityInfoPhoneNumbers
								addPhoneNotification={addPhoneNotification}
								disabled={!!contact.isBusy}
								onRequestAddPhoneNumber={this.onAddPhoneNumber}
								onRequestRemovePhoneNumber={this.onRemovePhoneNumber}
								onRequestUpdatePhoneNumber={this.onUpdatePhoneNumber}
								phoneNumbers={phoneNumbers || contact.phoneNumbers || []}
								entity={contact.toJs()}
							/>
						</div>
					</div>
					<div className={css(styleSheet.fieldEntitySection)}>
						{this.renderAddress()}
						{this.renderLabeledField('Website', contact.webSite)}
						{this.renderLabeledField('Misc', contact.bio, css(styleSheet.bio), false)}
					</div>
				</EntityInfoSection>
				{!!contact.isBusy && <LoadingSpinner className='absolute-center' type='large' />}
				<UpdateAddressModal
					initAddress={contact.address}
					isLoading={contact.isBusy}
					onClickToSave={handleClickToSaveAddress}
					modalProps={{
						isOpen: addAddressModalOpen,
						onRequestClose: () => this.closeModal('addAddressModalOpen'),
					}}
				/>
				{hwcTemplateChooserModalOpen ? (
					<HandwrittenCardTemplateSearchModal
						isOpen={hwcTemplateChooserModalOpen}
						onClose={() => this.closeModal('hwcTemplateChooserModalOpen')}
						onTemplateClicked={template => {
							this.closeModal('hwcTemplateChooserModalOpen');
							fullscreenModal.history.push({
								pathname: `/postcard/${template.id}/${PostcardComposerStep.Preview}`,
								state: {
									contactId: contact.id,
								},
							});
						}}
					/>
				) : null}
			</>
		);
	}

	private handleOpenAddAddressModal = () => {
		this.setState({ addAddressModalOpen: true });
	};

	private handleOpenHwcModal = () => {
		this.setState({ hwcTemplateChooserModalOpen: true });
	};

	private handleBookAppointClick = (url: string) => {
		window.open(url, '_blank');
	};

	private renderAddress = () => {
		const { contact, userSession, showHwcActionButton } = this.props;
		const address = contact.address;
		const formattedAddress = getMultilineFormattedAddress(address ?? {});
		const hasAtleastPartialAddress = !!formattedAddress;

		return (
			<div className={css(styleSheet.addressInfoField)}>
				{hasAtleastPartialAddress ? (
					<button
						className={`contact-info-edit-address-btn ${css(styleSheet.addressInfoFieldEditBtn)}`}
						onClick={this.handleOpenAddAddressModal}
					>
						<EditItemPenIcon />
					</button>
				) : null}
				<EntityInfoField
					label='Address'
					value={
						hasAtleastPartialAddress ? (
							<div className={css(styleSheet.addressDisplay)}>{formattedAddress}</div>
						) : (
							<div className={css(styleSheet.addressAddButtonWrapper)}>
								<RoundAddIcon />
								<button className='brand-link' onClick={this.handleOpenAddAddressModal}>
									<span>Add Address</span>
								</button>
							</div>
						)
					}
				/>
				{userSession.account.features.handwrittenCards?.enabled && showHwcActionButton ? (
					<>
						<button className={css(baseStyleSheet.ctaButtonSmall)} onClick={this.handleOpenHwcModal}>
							<span>Send Card</span>
						</button>
					</>
				) : null}
			</div>
		);
	};

	private cancelRelationshipHealthPolling = () => {
		if (this.mPollingTimeoutHandle) {
			clearTimeout(this.mPollingTimeoutHandle);
			this.mPollingTimeoutHandle = null;
		}
	};

	private onAddPhoneNumber = (phoneNumber: IPhoneNumber) => {
		const { contact } = this.props;
		const phoneNumbers = [...(contact.phoneNumbers || []), phoneNumber];
		this.updatePhoneNumbers(phoneNumbers, 'AddPhoneNumber');
	};

	private onRemovePhoneNumber = (_: IPhoneNumber, index: number) => {
		if (index >= 0) {
			const { contact } = this.props;
			const phoneNumbers = [...(contact.phoneNumbers || [])];
			phoneNumbers.splice(index, 1);
			this.updatePhoneNumbers(phoneNumbers, 'RemovePhoneNumber');
		}
	};

	private onShowSendMessageModal = () => {
		const { contact, singleEmailComposer } = this.props;
		singleEmailComposer.showForRecipients([contact]);
	};

	private onShowSendMessageModalWithAI = () => {
		const { contact, singleEmailComposer } = this.props;
		singleEmailComposer.showForRecipientWithAI([contact], EmailType.KeepInTouch);
	};

	private onUnsubscribeFromEmailCheckboxChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		const checked = !!(e.target as HTMLInputElement).checked;
		const { contact, logApiError, logEvent } = this.props;
		const promise = checked ? contact.addTags(['Unsubscribe']) : contact.removeTags(['Unsubscribe']);
		if (promise) {
			logEvent('ToggleEmailUnsubscribe', { unsubscribe: !!checked });
			promise.then(() => {
				contact.load()?.catch((error: IOperationResultNoValue) => {
					logApiError('ContactReload-Error', error);
				});
			});
			promise.catch((error: IOperationResultNoValue) => {
				logApiError('ToggleEmailUnsubscribe-Error', error);
			});
		}
	};

	private onUnsubscribeFromTextCheckboxChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { contact, logApiError, logEvent } = this.props;
		const checked = !!(e.target as HTMLInputElement).checked;
		logEvent('ContactUnsubscribeText', { unsubscribe: !!checked });
		contact.toggleTextSubscriptionForContact(checked)?.catch((error: IOperationResultNoValue) => {
			logApiError('ContactUnsubscribeText-Error', error);
		});
	};

	private onUpdatePhoneNumber = (phoneNumber: IPhoneNumber, index: number) => {
		if (index >= 0) {
			const { contact, onPhoneNumberEdited } = this.props;
			const phoneNumbers = [...(contact.phoneNumbers || [])];
			const [origPhone] = phoneNumbers.splice(index, 1, phoneNumber);
			this.updatePhoneNumbers(phoneNumbers, 'UpdatePhoneNumber').then(() =>
				onPhoneNumberEdited?.(origPhone, phoneNumber)
			);
		}
	};

	private pollForRelationshipHealthUpdate = () => {
		const prevRelationshipHealth = this.props.contact.relationshipHealth;

		/** Granularity is to the day. If the last interaction is today, no need to poll */
		if (!prevRelationshipHealth?.lastInteractionDate || !!dateIsToday(prevRelationshipHealth.lastInteractionDate)) {
			return;
		}

		return this.updateRelationshipHealth(true);
	};

	private renderLabeledField(label: string, value: React.ReactNode, valueClassName?: string, truncateText = true) {
		return (
			<EntityInfoField
				enableLinkify={true}
				label={label}
				truncateText={truncateText}
				value={value}
				valueClassName={valueClassName}
			/>
		);
	}

	private updatePhoneNumbers = (phoneNumbers: IPhoneNumber[], eventName: string) => {
		const { contact, logEvent, errorMessages, onPhoneNumberAdded } = this.props;
		const contactModel: IContact = {
			...contact.toJs(),
			phoneNumbers,
		};

		const promise = contact.update(contactModel);
		if (promise) {
			logEvent(eventName, { count: phoneNumbers.length });
			// set override
			this.setState({
				phoneNumbers,
			});
			promise
				.then(() => {
					this.setState({
						phoneNumbers: null,
					});
					switch (eventName) {
						case 'AddPhoneNumber':
							onPhoneNumberAdded?.(contact);
							break;
						default:
							break;
					}
				})
				.catch((error: IOperationResultNoValue) => {
					this.setState({
						phoneNumbers: null,
					});
					logEvent(`${eventName}-Error`, { ...error });
					errorMessages.pushApiError(error);
				});
		}
		return promise;
	};

	private updateRelationshipHealth = (poll = false) => {
		const { logEvent, contact } = this.props;
		const contactId = contact?.id;
		const promise = contact.updateRelationshipHealth(false);
		if (promise) {
			promise
				.then(() => {
					if (
						!!poll &&
						!!this.mMounted &&
						!!this.props.contact?.relationshipHealth?.lastInteractionDate &&
						!dateIsToday(this.props.contact.relationshipHealth.lastInteractionDate)
					) {
						this.mPollingTimeoutHandle = setTimeout(() => {
							this.updateRelationshipHealth(true);
						}, 5000);
					} else {
						this.cancelRelationshipHealthPolling();
					}
				})
				.catch((error: IOperationResultNoValue) => {
					logEvent('RefreshRelationshipHealthStatus-Error', {
						...error,
						contactId,
					});
					this.cancelRelationshipHealthPolling();
				});
		}
		return promise;
	};
}

const ContactInfoAsObserver = observer(_ContactInfo);
const ContactInfoWithRouter = withRouter(ContactInfoAsObserver);
const ContactInfoWithContext = inject(
	AppState.EnvironmentKey,
	AppState.ErrorMessagesViewModelKey,
	AppState.QuickAddEntityViewModelKey,
	AppState.SingleEmailComposerKey,
	AppState.UserSessionViewModelKey,
	AppState.FullScreenModalViewModelKey
)(ContactInfoWithRouter);
export const ContactInfo = withEventLogging(ContactInfoWithContext, 'ContactInfo');
