import {
	ILocalNotification,
	LocalNotificationObserver,
	addObserver,
	postNotification,
	removeObserver,
} from '../../../models/LocalNotifications';
import * as React from 'react';

export interface INotificationServiceComponentProps<T> {
	addObserver?(topic: string, observer: LocalNotificationObserver<T>): any;
	postNotification?(notification: ILocalNotification<T>): void;
	removeObserver?(observer: LocalNotificationObserver<T> | any): void;
}

export const withNotificationService = <P extends INotificationServiceComponentProps<any>>(
	WrappedComponent: React.ComponentType<P>
): React.ComponentClass<P> => {
	return class Wrapper extends React.PureComponent<P> {
		public render() {
			return (
				<WrappedComponent
					postNotification={postNotification}
					addObserver={addObserver}
					removeObserver={removeObserver}
					{...this.props}
				/>
			);
		}
	};
};
