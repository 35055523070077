import { brandSecondary } from '../../../styles/colors';
import { CssSelectors, Layouts } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

const iconSize = 30;
const iconMarginRight = 22;

export const styleSheet = StyleSheet.create({
	container: {
		postition: 'relative',
	},
	richContentEditor: {
		padding: 0,
		...CssSelectors.allChildren(
			{
				padding: 0,
			},
			'.rich-content-document-editor-readonly'
		),
	},
	segmentStepHeader: {
		alignItems: 'center',
		display: 'flex',
	},
	step: {
		display: 'flex',
		minHeight: 50,
		width: '100%',
	},
	stepContent: {
		...Layouts.verticalStack(8),
		':after': {
			content: '""',
			display: 'block',
			height: 10,
		},
		width: `calc(100% - ${iconSize + iconMarginRight}px)`,
	},
	stepContentFullWidth: {
		width: '100%',
	},
	stepIndicator: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		flexShrink: 0,
		position: 'relative',
		width: iconSize + iconMarginRight,
	},
	stepIndicatorIcon: {
		flexShrink: 0,
		height: iconSize,
		width: iconSize,
	},
	stepIndicatorLine: {
		borderLeft: `1px dashed ${brandSecondary}`,
		flexGrow: 1,
	},
	stepTitle: {
		color: '#AAAAAA',
		marginTop: 8,
	},
	stepTitleNoMargin: {
		marginTop: 0,
	},
	tag: {
		display: 'inline-block',
	},
});
