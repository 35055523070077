import { titles } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const chipHeight = 32;

export const styleSheet = StyleSheet.create({
	accessory: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		overflow: 'hidden',
	},
	chip: {
		alignItems: 'center',
		border: '1px solid #D3DBDE',
		borderRadius: '16px',
		boxSizing: 'border-box',
		color: titles,
		display: 'inline-flex',
		fontSize: '14px',
		height: chipHeight,
		letterSpacing: 0,
		lineHeight: '22px',
		overflow: 'hidden',
		padding: 4,
	},
	label: {
		flexGrow: 1,
		margin: '0 8px',
		minWidth: 0,
	},
	remove: {
		borderRadius: '8px',
		height: 16,
		width: 16,
	},
	thumb: {
		borderRadius: '12px',
		flexShrink: 0,
		height: 24,
		width: 24,
	},
});
