import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const LoginFormErrorIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;

	return (
		<SvgIcon className={`login-form-error-icon ${className || ''}`} width={20} height={20} fill='none'>
			<g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
				<g fill={fillColor || '#D0021B'}>
					<path d='M2.6182,18 L17.3822,18 L10.0002,3.236 L2.6182,18 Z M19.0002,20 L1.0002,20 C0.6532,20 0.3312,19.82 0.1492,19.526 C-0.0328,19.231 -0.0488,18.863 0.1052,18.553 L9.1052,0.553 C9.4442,-0.125 10.5552,-0.125 10.8942,0.553 L19.8942,18.553 C20.0492,18.863 20.0332,19.231 19.8502,19.526 C19.6682,19.82 19.3472,20 19.0002,20 L19.0002,20 Z' />
					<polygon points='11 14 9 14 9 8 11 8' />
					<path d='M11.25,16 C11.25,16.69 10.69,17.25 10,17.25 C9.31,17.25 8.75,16.69 8.75,16 C8.75,15.31 9.31,14.75 10,14.75 C10.69,14.75 11.25,15.31 11.25,16' />
				</g>
			</g>
		</SvgIcon>
	);
};
