import { StyleDeclarationValue, css } from 'aphrodite';
import React from 'react';
import { FilterOperator, IAccountTag, ResourceAutoCompleteViewModelType } from '../../../../../extViewmodels';

import { AutoCompleteSearchField } from '../../../autocomplete/AutoCompleteSearchField';
import { AddTagButton } from '../../../tags/AddTagButton';
import { Tag } from '../../../tags/Tag';
import { useTagsStorage } from '../TagsEditor/hooks';
import { FeaturedAndRecentTags } from '../TagsEditor/presentation';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
	tags: string[];
	inputId?: string;
	separator?: FilterOperator.And | FilterOperator.Or | FilterOperator.Not;
	onTagAdded(tag: string): void;
	onTagRemoved(tag: string): void;
	openOnFocus?: boolean;
}

const BaseInputProps: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> = {
	autoFocus: true,
	placeholder: 'Search',
};

export const TagListBuilder: React.FC<IProps> = ({
	tags,
	inputId,
	separator,
	onTagAdded,
	onTagRemoved,
	className,
	styles = [],
	openOnFocus = false,
}) => {
	const [isAdding, setAdding] = React.useState(false);
	const [isOpen, setIsOpen] = React.useState(false);
	const [inputValue, setInputValue] = React.useState('');
	const { updateTagsRecentSearches } = useTagsStorage();

	const onTagRemovedInternal = (tag: string) => {
		const tagToRemove = tags.find(x => x === tag);
		// @ts-ignore
		onTagRemoved(tagToRemove);
	};

	const onAutocompleteItemSelectedTag = (tag: string) => {
		onTagAdded(tag);
		updateTagsRecentSearches(tag);
		setInputValue('');
		setAdding(false);
	};

	const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value);
	};

	return (
		<div className={`${css(styleSheet.container, ...styles)} tag-list-builder ${className || ''}`}>
			{tags.map((tag, index, array) => {
				return (
					<span key={`${tag}-${index}`}>
						<Tag
							tagValue={tag}
							onRemoveButtonClicked={onTagRemovedInternal}
							showRemoveButton={true}
							className={css(styleSheet.tag, separator === FilterOperator.Not && styleSheet.tagNot)}
						/>
						{index < array.length - 1 && <span className={css(styleSheet.separator)}>{separator}</span>}
					</span>
				);
			})}
			{isAdding ? (
				<AutoCompleteSearchField
					sectionTitleElement={
						<>
							<div className={css(styleSheet.sectionWrap)}>
								<p className={css(styleSheet.sectionTitle)}>Top Results</p>
							</div>
						</>
					}
					emptyResults={
						<>
							<FeaturedAndRecentTags
								onSelected={(tag: IAccountTag) => {
									onAutocompleteItemSelectedTag(tag.tag);
								}}
								showNoResults={Boolean(inputValue)}
							/>
						</>
					}
					dropdownContentClassName={css(styleSheet.autocompleteBox)}
					anchorClassName={css(styleSheet.searchBox)}
					customQueryParams={{ excludeHidden: true, pageSize: 25 }}
					initialSearchQuery=''
					inputId={inputId || 'tag-search-box'}
					inputProps={{
						...BaseInputProps,
						placeholder: 'Search tags',
						onChange: handleValueChange,
						onFocus: () => setIsOpen(true),
						onBlur: () => setIsOpen(false),
					}}
					onItemSelected={onAutocompleteItemSelectedTag}
					resultsLimit={25}
					type={ResourceAutoCompleteViewModelType.Tag}
					openOnFocus={openOnFocus}
					isOpen={isOpen}
				/>
			) : (
				<AddTagButton onClick={() => setAdding(true)} />
			)}
		</div>
	);
};
