import { StyleDeclaration, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { integrationSources, isAMSIntegration } from '../../../extViewmodels/Utils';
import { EnvironmentKey, IEnvironmentComponentProps } from '../../../models/AppState';
import {
	ContactViewModel,
	IKeyFact,
	IPolicyKeyFactMetaData,
	IntegrationSources,
	KeyDateKind,
} from '../../../viewmodels/AppViewModels';
import { TinyPopover } from '../../components/TinyPopover';
import { QuestionMarkIcon } from '../../components/svgs/icons/QuestionMarkIcon';
import { dataOriginDark, styleSheet } from './styles';

interface IProps extends IEnvironmentComponentProps {
	className?: string;
	contact: ContactViewModel;
	dataType: 'Email' | 'KeyFact' | 'Name' | 'Tags';
	email?: string;
	iconPlacement?: 'bottom' | 'center' | 'top';
	keyFact?: IKeyFact;
	popoverStyles?: StyleDeclaration[];
}

const DataOriginContainerBase: React.FC<IProps> = ({
	children,
	className,
	contact,
	dataType,
	email,
	environment,
	iconPlacement = 'center',
	keyFact,
	popoverStyles = [],
}) => {
	const [isEnabled, setIsEnabled] = useState(false);
	const [originDataIsOpen, setOriginDataIsOpen] = useState(false);

	useEffect(() => {
		let enabled = false;
		if (contact?.dataOriginEnabled && contact?.integrationData?.length > 0) {
			switch (dataType) {
				case 'Email':
					enabled =
						contact.integrationData.filter(d => {
							return d.emails.includes(email);
						}).length > 0;
					break;
				case 'KeyFact':
					if (keyFact && keyFact.keyDate) {
						if (
							keyFact.keyDate.kind === KeyDateKind.Renewal ||
							keyFact.keyDate.kind === KeyDateKind.Birthday ||
							keyFact.keyDate.kind === KeyDateKind.FinancialReview
						) {
							enabled = integrationSources.includes(keyFact.source?.source);
						}
					}
					break;
				case 'Name':
					enabled = contact.integrationData.filter(d => d.firstName || d.lastName).length > 0;
					break;
				case 'Tags':
					enabled = contact.integrationData.filter(d => d.tags.length).length > 0;
					break;
				default:
					break;
			}
		}
		setIsEnabled(enabled);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contact.dataOriginEnabled, contact.integrationData]);

	const getContacts = () => {
		return contact.integrationData.map(x => {
			return [
				`${x.firstName ? `${x.firstName}` : ''}${x.lastName ? ` ${x.lastName}` : ''}: (ID: ${
					x.customerId || 'unknown'
				})`,
				styleSheet.noMargin,
			] as [string, StyleDeclaration?];
		});
	};

	const getCustomerId = () => {
		const info = contact.integrationData.filter(
			x => x.firstName === contact.firstName && x.lastName === contact.lastName
		);
		return info.length ? info[0].customerId : null;
	};

	const getEmailContent = (): [string, StyleDeclaration?][] => {
		return [
			[
				`This email comes from the Customer ID: ${getCustomerId()}. Levitate will merge multiple contacts with the same email so they don't get duplicated sends.`,
			],
			['Other accounts with the same email that have been merged include:'],
			...getContacts(),
		];
	};

	const getKeyFactContent = (): [string, StyleDeclaration?][] => {
		if (keyFact.keyDate?.kind === KeyDateKind.Renewal) {
			return [
				[
					`This policy information comes from the Customer ID: ${getCustomerId()} ${
						(keyFact.source?.additionalMetadata as IPolicyKeyFactMetaData)?.carrier
							? `(${(keyFact.source.additionalMetadata as IPolicyKeyFactMetaData).carrier})`
							: ''
					}`,
				],
			];
		} else if (keyFact.keyDate?.kind === KeyDateKind.Birthday) {
			if (keyFact.source?.source === IntegrationSources.AMS360) {
				return [['This date comes from the Customer Record from AMS360.']];
			} else if (keyFact.source?.source === IntegrationSources.HawkSoft) {
				return [['This date comes from the Personal Profile in Hawksoft.']];
			} else if (keyFact.source?.source === IntegrationSources.Eclipse) {
				return [['This date comes from the Family Contact in Eclipse.']];
			}
			return [['This date comes from the integration.']];
		} else if (keyFact.keyDate?.kind === KeyDateKind.FinancialReview) {
			if (keyFact.source?.source === IntegrationSources.Redtail) {
				return [['This date comes from Redtail based on your Levitate account setup.']];
			}
			return [['This date comes from the integration.']];
		} else {
			return [['invalid keyFact kind found.']];
		}
	};

	const getNameContent = (): [string, StyleDeclaration?][] => {
		const emailNameComesFrom = contact.integrationData.map(contactData => {
			return contactData.emails?.filter(emailAddress => {
				return (
					(contactData.firstName && emailAddress.toLowerCase().includes(contactData.firstName.toLowerCase())) ||
					(contactData.lastName && emailAddress.toLowerCase().includes(contactData.lastName.toLowerCase()))
				);
			});
		})[0];

		return emailNameComesFrom?.length
			? [
					[`The name comes from the email address "${emailNameComesFrom}". Customer ID: ${getCustomerId()}.`],
					["Levitate will merge multiple contacts with the same email so they dont't get duplicated sends."],
					['Other names with the same email that have been merged include:'],
					...getContacts(),
				]
			: [[`The name comes from the Customer ID: ${getCustomerId()}.`]];
	};

	const getTagsContent = (): [string, StyleDeclaration?][] => {
		let paras: [string, StyleDeclaration?][] = [
			[
				`Contacts that are synced from your ${
					isAMSIntegration(contact?.source) ? 'Agency Management System' : 'CRM'
				} will have a ${contact?.source} tag. ${
					isAMSIntegration(contact?.source)
						? `Anything with initials or username - for example "${contact?.source}: ADF" is the initial of an Executive or CSR.`
						: ''
				}`,
			],
		];

		if (isAMSIntegration(contact?.source)) {
			paras = [
				...paras,
				[
					'Other tags are based on active policies. A "Client" tag is applied to anyone who has at least one active policy.',
				],
				[
					'Contacts without an active policy have the "Prospect" tag; contacts who used to have an active policy but cancelled have a "Winback" tag.',
				],
			];
		}

		return paras;
	};

	const getPopoverContent = () => {
		let paras: [string, StyleDeclaration?][] = [];
		switch (dataType) {
			case 'Email':
				paras = getEmailContent();
				break;
			case 'KeyFact':
				if (!keyFact) {
					break;
				}
				paras = getKeyFactContent();
				break;
			case 'Name':
				paras = getNameContent();
				break;
			case 'Tags':
				if (contact?.source) {
					paras = getTagsContent();
				}
				break;
			default:
				paras = [['Invalid data type found.']];
				break;
		}

		if (!paras) {
			return null;
		}

		return paras.map((p, i) => (
			<p key={i} className={css(styleSheet.popoverParagraph, p.length > 1 && p[1])}>
				{p[0]}
			</p>
		));
	};

	const onMouseOut = () => {
		setOriginDataIsOpen(false);
	};

	const onMouseOver = () => {
		setOriginDataIsOpen(true);
	};

	const onPopoverClose = () => {
		setOriginDataIsOpen(false);
	};

	const renderIcon = () => {
		if (!isEnabled) {
			return null;
		}

		const anchor = (
			<button
				className={`${css(styleSheet.button)} data-origin-icon`}
				onMouseOut={onMouseOut}
				onMouseOver={onMouseOver}
			>
				<QuestionMarkIcon className={css(styleSheet.icon)} fillColor={dataOriginDark} />
			</button>
		);

		const classes = [styleSheet.popover];
		let align: 'center' | 'start' = 'center';

		if (dataType === 'Name' || dataType === 'Tags' || dataType === 'Email') {
			classes.push(styleSheet.popoverOffset);
			align = 'start';
		}

		let styles: StyleDeclaration[] = [];
		if (environment.appType === 'plugin') {
			styles = [styleSheet.pluginEnv];
		}
		styles = [...styles, ...classes, ...popoverStyles];

		return (
			<TinyPopover
				align={align}
				anchor={anchor}
				dismissOnOutsideAction={true}
				autoCloseOtherPopoversOnHover={true}
				isOpen={originDataIsOpen}
				onRequestClose={onPopoverClose}
				placement={['right', 'left']}
				styles={styles}
			>
				<div className={css(styleSheet.originData)}>{getPopoverContent()}</div>
			</TinyPopover>
		);
	};

	return (
		<div className={`${css(styleSheet.container, isEnabled && styleSheet.enabled)} ${className}`}>
			<div className={css(styleSheet.childrenContainer)}>{children}</div>
			{!!renderIcon() && (
				<div
					className={`${css(
						styleSheet.iconContainer,
						iconPlacement === 'top'
							? styleSheet.iconContainerTop
							: iconPlacement === 'bottom'
								? styleSheet.iconContainerBottom
								: null
					)}`}
				>
					{renderIcon()}
				</div>
			)}
		</div>
	);
};

const DataOriginContainerAsObserver = observer(DataOriginContainerBase);
export const DataOriginContainer = inject(EnvironmentKey)(DataOriginContainerAsObserver);
