import { AdminUserSessionContext } from '../viewmodels/AdminViewModels';
import { IAccount, IEmailProviderConfiguration, UserSessionContext } from '@ViewModels';

export function booleanToString(field: boolean) {
	if (field) {
		return field ? 'Yes' : 'No';
	}
	return 'No';
}

export function numberToString(field: number) {
	return field !== undefined && field !== null ? field.toString() : 'Not available';
}

export function formatDate(d: Date) {
	return d ? new Date(Date.parse(d.toString())).toLocaleDateString() : 'Not available';
}

export const UserRoleName: Record<string, string> = {
	admin: 'Administrator',
	limitedUser: 'Limited User',
	superAdmin: 'Super Administrator',
	user: 'User',
};

export function areEmailProvidersConfigured(account: IAccount) {
	if (!account || !account.emailProviderConfigurations) {
		return false;
	}

	for (const configuration of account.emailProviderConfigurations) {
		if (!isEmailProviderConfigured(configuration)) {
			return false;
		}
	}

	return true;
}

export function isEmailProviderConfigured(providerConfiguration: IEmailProviderConfiguration) {
	if (!providerConfiguration.emailProvider) {
		return false;
	}

	switch (providerConfiguration.emailProvider) {
		case 'Yahoo':
		case 'Aol':
		case 'Imap':
			return (
				!!providerConfiguration.imap &&
				!!providerConfiguration.imap.imapHost &&
				!!providerConfiguration.imap.imapPort &&
				!!providerConfiguration.imap.smtpHost &&
				!!providerConfiguration.imap.smtpPort
			);
		case 'Exchange':
			return !!providerConfiguration.exchange && !!providerConfiguration.exchange.webServicesUrl;
		default:
			return true;
	}
}

/** @returns If the user session is an admin user session, a value will be returned. Null otherwise. */
export const asAdminUserSession = <TUserSession extends UserSessionContext = UserSessionContext>(
	userSession: TUserSession
) => {
	if (userSession instanceof AdminUserSessionContext) {
		return userSession;
	}
	return null;
};
