import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { LocationDescriptorObject } from 'history';
import produce from 'immer';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { ILocationState } from '../../../../../models';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../../models/AppState';
import { useFullscreenModal } from '../../../../../models/hooks/appStateHooks';
import { useLambda } from '../../../../../models/hooks/useLambda';
import { useRecentSearches } from '../../../../../models/hooks/useRecentSearches';
import { TinyPopover } from '../../../../components/TinyPopover';
import { SearchIcon } from '../../../../components/svgs/icons/SearchIcon';
import { baseStyleSheet } from '../../../../styles/styles';
import { ContactsAutocompleteResults } from '../../shared/ContactsAutocompleteResults';
import { ContactsAutocompleteResultsItem } from '../../shared/ContactsAutocompleteResultsItem';
import { ContactsNoRecentSearches } from '../../shared/ContactsNoRecentSearches';
import {
	getCompanyCriteriaFromTab,
	getCompanySearchFromCriteria,
	useCompaniesInput,
	useCompaniesSearch,
	useCompanyTabs,
} from '../CompaniesContext';
import { styleSheet } from './styles';

const pageSize = 3;
export const RecentCompanySearchesStorageKey = 'RecentCompanySearches';

export const CompaniesGlobalSearch: React.FC<IUserSessionComponentProps> = inject(UserSessionViewModelKey)(
	observer(({ userSession }) => {
		const { search, setSearch } = useCompaniesInput();
		// @ts-ignore
		const { history } = useFullscreenModal();
		const { filter, sort, load } = useCompaniesSearch();
		const { tab } = useCompanyTabs();
		const { recentSearches, addToRecent } = useRecentSearches(RecentCompanySearchesStorageKey);
		const [isOpen, setIsOpen] = React.useState(false);
		const [highlight, setHighlight] = useLambda(-1);
		const [input, setInputValue] = React.useState('');
		const inputRef = React.useRef<HTMLInputElement>(null);
		const setInput = (event: React.ChangeEvent<HTMLInputElement>) => setInputValue(event.target.value);
		const companiesRef = React.useRef(
			// @ts-ignore
			new Api.ResourceAutoCompleteViewModel(userSession, {
				pageSize,
				type: Api.ResourceAutoCompleteViewModelType.Company,
			})
		);
		const tagsRef = React.useRef(
			// @ts-ignore
			new Api.ResourceAutoCompleteViewModel(userSession, {
				pageSize,
				type: Api.ResourceAutoCompleteViewModelType.AccountTag,
			})
		);

		React.useEffect(() => {
			companiesRef.current.setSearchQuery(search, 50);
			tagsRef.current.setSearchQuery(search, 50);
		}, [search]);

		React.useEffect(() => {
			setSearch(input);
		}, [input, setSearch]);

		React.useEffect(() => {
			// @ts-ignore
			setInputValue(getCompanySearchFromCriteria(filter.criteria));
		}, [filter]);

		const blurSearch = React.useCallback(() => {
			setIsOpen(false);
			// @ts-ignore
			inputRef.current.blur();
			setSearch('');
		}, [setSearch]);

		const onSearch = () => {
			const nextFilter = produce(filter, draftFilter => {
				// @ts-ignore
				const newCriteria = draftFilter.criteria.filter(x => {
					return (
						x.property !== Api.CompanySearchRequestProperty.All &&
						x.property !== Api.CompanySearchRequestProperty.Name &&
						x.property !== Api.CompanySearchRequestProperty.Tag
					);
				});
				newCriteria.push({
					property: getCompanyCriteriaFromTab(tab),
					value: search,
				});
				draftFilter.criteria = newCriteria;
			});
			load(nextFilter, sort);
			setIsOpen(false);
		};

		const onCompanySearched = (company: Api.ICompany) => () => {
			blurSearch();
			addToRecent({ entity: company, type: 'company' });

			const locationState: ILocationState<Api.EntityViewModel, Api.IEntity> = {
				// @ts-ignore
				viewModel: new Api.CompanyViewModel(userSession, company),
			};
			const redirection: LocationDescriptorObject = {
				pathname: `/companies/${company.id}`,
				state: locationState,
			};
			history.push(redirection);
		};

		const addTagToSearch = React.useCallback(
			(tag: string) => {
				const nextFilter = produce(filter, draftFilter => {
					draftFilter.criteria = [{ property: Api.CompanySearchRequestProperty.Tag, value: tag }];
				});

				load(nextFilter, sort);
			},
			[filter, sort, load]
		);

		const onTagSearched = (tag: Api.IAccountTag) => () => {
			// @ts-ignore
			addTagToSearch(tag.tag);
			blurSearch();
			addToRecent({
				tag,
				type: 'tag',
			});
		};

		const onViewMoreCompanies = () => {
			onSearch();
			blurSearch();
		};

		const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
			const companies = companiesRef.current;
			const tags = tagsRef.current;
			const companiesMore = companies.totalCount > companies.searchResults.length;
			const companiesLength = companies.searchResults.length + (companiesMore ? 1 : 0);
			const tagsLength = tags.searchResults.length;
			const results = [
				...companies.searchResults,
				companiesMore ? { companies: 'more' } : null,
				...tags.searchResults,
			].filter(x => Boolean(x));

			if (event.key === 'Enter') {
				if (highlight === -1) {
					onSearch();
					return;
				}

				if (search === '') {
					const recent = recentSearches[highlight];
					const action =
						recent.type === 'company'
							? onCompanySearched(recent.entity as Api.ICompany)
							: onTagSearched(recent.tag as Api.IAccountTag);
					action();
					return;
				}

				const selectedItem = results[highlight] as any;
				if ('tag' in selectedItem) {
					onTagSearched(selectedItem)();
				} else if ('id' in selectedItem && !('tag' in selectedItem)) {
					onCompanySearched(selectedItem)();
				} else if ('companies' in selectedItem) {
					onViewMoreCompanies();
				}
			} else if (event.key === 'ArrowDown') {
				if (search === '' && highlight < recentSearches.length - 1) {
					setHighlight(highlight + 1);
				} else if (highlight < companiesLength + tagsLength - 1) {
					setHighlight(highlight + 1);
				}
			} else if (event.key === 'ArrowUp') {
				if (highlight > -1) {
					setHighlight(highlight - 1);
				}
			} else {
				// set back to the input
				setHighlight(-1);
				setIsOpen(true);
			}
		};

		const onRenderCompany = (x: Api.ICompany, idx: number) => {
			return (
				<ContactsAutocompleteResultsItem
					onClick={onCompanySearched(x)}
					highlight={idx === highlight}
					entity={x}
					type='company'
					key={x.id}
				/>
			);
		};

		const onRenderTag = (x: Api.IAccountTag, idx: number) => {
			const companies = companiesRef.current;
			return (
				<ContactsAutocompleteResultsItem
					onClick={onTagSearched(x)}
					highlight={
						idx + companies.searchResults.length + (companies.totalCount > companies.searchResults.length ? 1 : 0) ===
						highlight
					}
					entity={x}
					type='tag'
					key={x.id}
				/>
			);
		};

		return (
			<TinyPopover
				isOpen={isOpen}
				anchor={
					<div className={css(baseStyleSheet.textField, styleSheet.inputContainer)}>
						<input
							ref={inputRef}
							autoComplete='off'
							id='company-search'
							onChange={setInput}
							placeholder='Search'
							type='text'
							value={input}
							onKeyDown={onKeyDown}
							onFocus={() => setIsOpen(true)}
						/>
						<div className={css(styleSheet.searchButton)} onClick={onSearch}>
							<SearchIcon className={css(styleSheet.searchIcon)} fillColor='white' />
						</div>
					</div>
				}
				anchorStyles={[styleSheet.inputAnchor]}
				onRequestClose={() => setIsOpen(false)}
				dismissOnOutsideAction={true}
				placement={['bottom']}
			>
				<div className={css(styleSheet.autocompleteContainer)}>
					{input ? (
						<div>
							<div className={css(styleSheet.title)}>companies</div>
							<ContactsAutocompleteResults
								type='company'
								pageSize={pageSize}
								items={companiesRef.current}
								renderItem={onRenderCompany}
								onMoreClicked={onViewMoreCompanies}
								highlightIndex={highlight}
								search={search}
								shiftedBy={0}
							/>
							<div className={css(styleSheet.title)}>tags</div>
							<ContactsAutocompleteResults
								type='company'
								pageSize={pageSize}
								items={tagsRef.current}
								renderItem={onRenderTag}
								highlightIndex={highlight}
								search={search}
								shiftedBy={
									companiesRef.current.searchResults.length +
									(companiesRef.current.totalCount > companiesRef.current.searchResults.length ? 1 : 0)
								}
							/>
						</div>
					) : (
						<div>
							<div className={css(styleSheet.title)}>recent searches</div>
							<div>
								{recentSearches.length ? (
									recentSearches.map((x, i) => {
										return (
											<>
												<ContactsAutocompleteResultsItem
													key={i}
													type={x.type}
													highlight={highlight === i}
													entity={x.type === 'tag' ? x.tag : x.entity}
													onClick={
														x.type === 'company'
															? onCompanySearched(x.entity as Api.ICompany)
															: onTagSearched(x.tag as Api.IAccountTag)
													}
													className={css(styleSheet.recentSearch)}
												/>
											</>
										);
									})
								) : (
									<ContactsNoRecentSearches />
								)}
							</div>
						</div>
					)}
				</div>
			</TinyPopover>
		);
	})
);
