import { css } from 'aphrodite';
import * as React from 'react';
import { brandPrimary, navigation } from '../../../../styles/colors';
import { ActionItemsIcon } from '../ActionItemsIcon';
import { AutomationsIcon } from '../AutomationsIcon';
import { CalendarDateIcon } from '../CalendarDateIcon';
import { CompaniesIcon } from '../CompaniesIcon';
import { DevToolsIcon } from '../DevToolsIcon';
import { EmailIcon } from '../EmailIcon';
import { HomeIcon } from '../HomeIcon';
import { IntegrationIcon } from '../IntegrationIcon';
import { InviteTeammatesIcon } from '../InviteTeammatesIcon';
import { LogoutIcon } from '../LogoutIcon';
import { NotesIcon } from '../NotesIcon';
import { DataboardsIcon } from '../DataboardsIcon';
import { PeopleIcon } from '../PeopleIcon';
import { ReferAFriendIcon } from '../ReferAFriendIcon';
import { ReportsIcon } from '../ReportsIcon';
import { ScheduleMeetingIcon } from '../ScheduleMeetingIcon';
import { SettingsIcon } from '../SettingsIcon';
import { StarIcon } from '../StarIcon';
import { SupportIcon } from '../SupportIcon';
import { TagsAndReportingIcon } from '../TagsAndReportingIcon';
import { TextingIcon } from '../TextingIcon';
import { ToolsIcon } from '../ToolsIcon';
import { styleSheet } from './styles';

export interface IProps {
	className?: string;
	iconName?: string;
	isActive?: boolean;
	fillColor?: string;
}

export interface IWrapperProps {
	children: JSX.Element;
	width?: number;
}

export enum NavIconType {
	Accounts = 'Accounts',
	Automations = 'Automations',
	Campaigns = 'Campaigns',
	Companies = 'Companies',
	Contacts = 'Contacts',
	ContentCalendar = 'Content Calendar',
	Dashboard = 'Dashboard',
	DevTools = 'DevTools',
	Integrations = 'Integrations',
	InviteTeammates = 'Invite Teammates',
	Logout = 'Logout',
	NotesAndActions = 'Notes & Actions',
	DataBoards = 'Data Boards',
	People = 'People',
	Reporting = 'Reporting',
	Reports = 'Reports',
	ScheduleMeeting = 'Schedule a Meeting',
	Settings = 'Settings',
	Support = 'Support',
	Surveys = 'Surveys',
	TagsAndReports = 'Tags & Reports',
	Templates = 'Templates',
	Texting = 'Texting',
	Tools = 'Tools',
	ReferAFriend = 'Refer a Friend',
}

export const NavIcon: React.FC<IProps> = ({ className, fillColor, iconName, isActive }) => {
	let color: string = isActive ? brandPrimary : navigation;
	if (fillColor) {
		color = fillColor;
	}

	switch (iconName) {
		case NavIconType.Accounts:
		case NavIconType.Dashboard: {
			return getDashboardIcon(color, className);
		}
		case NavIconType.People:
		case NavIconType.Contacts: {
			return getPeopleIcon(color, className);
		}
		case NavIconType.Companies: {
			return getCompaniesIcon(color, className);
		}
		case NavIconType.DevTools: {
			// @ts-ignore
			return getDevToolsIcon(color, className);
		}
		case NavIconType.NotesAndActions: {
			return getNotesIcon(color, className);
		}
		case NavIconType.Settings: {
			return getSettingsIcon(color, className);
		}
		case NavIconType.TagsAndReports:
		case NavIconType.Reporting: {
			return getTagsAndReportingIcon(color, className);
		}
		case NavIconType.Reports: {
			// @ts-ignore
			return getReportsIcon(color, className);
		}
		case NavIconType.Support: {
			return getSupportIcon(color, className);
		}
		case NavIconType.Logout: {
			return getLogoutIcon(color, className);
		}
		case NavIconType.InviteTeammates: {
			return getInviteTeammatesIcon(color, className);
		}
		case NavIconType.DataBoards: {
			return getDataboardsIcon(color, className);
		}
		case NavIconType.Integrations: {
			return getIntegrationsIcon(color, className);
		}
		case NavIconType.Automations: {
			return getAutomationsIcon(color, className);
		}
		case NavIconType.Templates:
		case NavIconType.Campaigns: {
			// @ts-ignore
			return getCampaignsIcon(color, className);
		}
		case NavIconType.ContentCalendar: {
			// @ts-ignore
			return getCalendarIcon(color, className);
		}
		case NavIconType.Tools: {
			// @ts-ignore
			return getToolsIcon(color, className);
		}
		case NavIconType.Texting: {
			return getTextingIcon(color, className);
		}
		case NavIconType.ScheduleMeeting: {
			// @ts-ignore
			return getScheduleMeetingIcon(color, className);
		}
		case NavIconType.Surveys: {
			return getSurveyIcon(color, className);
		}
		case NavIconType.ReferAFriend: {
			// @ts-ignore
			return getReferAFriendIcon(color, className);
		}
		default: {
			return getSupportIcon(color, className);
		}
	}
};

export const NavIconWrapper: React.FC<IWrapperProps> = ({ children, width }) => {
	return (
		<div className={css(styleSheet.icon)} style={{ width }}>
			{children}
		</div>
	);
};

export const getSurveyIcon = (color: string, className?: string) => {
	return (
		<NavIconWrapper width={21}>
			<StarIcon fillColor={color} className={className} />
		</NavIconWrapper>
	);
};

export const getActionItemsIcon = (color: string, className?: string) => {
	return (
		<NavIconWrapper width={18}>
			<ActionItemsIcon fill={color} className={className} />
		</NavIconWrapper>
	);
};

export const getAutomationsIcon = (color: string, className?: string) => {
	return (
		<NavIconWrapper width={20}>
			<AutomationsIcon fillColor={color} className={className} />
		</NavIconWrapper>
	);
};

export const getCalendarIcon = (color: string, className: string) => {
	return (
		<NavIconWrapper width={18}>
			<CalendarDateIcon fillColor={color} className={className} />
		</NavIconWrapper>
	);
};

export const getCampaignsIcon = (color: string, className: string) => {
	return (
		<NavIconWrapper width={18}>
			<EmailIcon fillColor={color} className={className} />
		</NavIconWrapper>
	);
};

export const getCompaniesIcon = (color: string, className?: string) => {
	return (
		<NavIconWrapper width={20}>
			<CompaniesIcon stroke={color} className={className} />
		</NavIconWrapper>
	);
};

export const getDashboardIcon = (color: string, className?: string) => {
	return (
		<NavIconWrapper width={18}>
			<HomeIcon stroke={color} className={className} />
		</NavIconWrapper>
	);
};

export const getDevToolsIcon = (color: string, className: string) => {
	return (
		<NavIconWrapper width={20}>
			<DevToolsIcon fill={color} className={className} />
		</NavIconWrapper>
	);
};

export const getIntegrationsIcon = (color: string, className?: string) => {
	return (
		<NavIconWrapper width={20}>
			<IntegrationIcon fill={color} className={className} />
		</NavIconWrapper>
	);
};

export const getInviteTeammatesIcon = (color: string, className?: string) => {
	return (
		<NavIconWrapper width={22}>
			<InviteTeammatesIcon fill={color} className={className} />
		</NavIconWrapper>
	);
};

export const getLogoutIcon = (color: string, className?: string) => {
	return (
		<NavIconWrapper width={20}>
			<LogoutIcon fill={color} className={className} />
		</NavIconWrapper>
	);
};

export const getNotesIcon = (color: string, className?: string) => {
	return (
		<NavIconWrapper width={16}>
			<NotesIcon fillColor={color} className={className} />
		</NavIconWrapper>
	);
};

export const getDataboardsIcon = (color: string, className?: string) => {
	return (
		<NavIconWrapper width={21}>
			<DataboardsIcon fill={color} className={className} />
		</NavIconWrapper>
	);
};

export const getPeopleIcon = (color: string, className?: string) => {
	return (
		<NavIconWrapper width={20}>
			<PeopleIcon stroke={color} className={className} />
		</NavIconWrapper>
	);
};

export const getReportsIcon = (color: string, className: string) => {
	return (
		<NavIconWrapper width={20}>
			<ReportsIcon fill={color} className={className} />
		</NavIconWrapper>
	);
};

export const getSettingsIcon = (color: string, className?: string) => {
	return (
		<NavIconWrapper width={20}>
			<SettingsIcon stroke={color} className={className} />
		</NavIconWrapper>
	);
};

export const getSupportIcon = (color: string, className?: string) => {
	return (
		<NavIconWrapper width={16}>
			<SupportIcon stroke={color} className={className} />
		</NavIconWrapper>
	);
};

export const getTagsAndReportingIcon = (color: string, className?: string) => {
	return (
		<NavIconWrapper width={19}>
			<TagsAndReportingIcon fill={color} className={className} />
		</NavIconWrapper>
	);
};

export const getTextingIcon = (color: string, className?: string) => {
	return (
		<NavIconWrapper width={21}>
			<TextingIcon fill={color} className={className} />
		</NavIconWrapper>
	);
};

export const getToolsIcon = (color: string, className: string) => {
	return (
		<NavIconWrapper width={20}>
			<ToolsIcon fill={color} className={className} />
		</NavIconWrapper>
	);
};

export const getScheduleMeetingIcon = (color: string, className: string) => {
	return (
		<NavIconWrapper width={20}>
			<ScheduleMeetingIcon fill={color} className={className} />
		</NavIconWrapper>
	);
};

export const getReferAFriendIcon = (color: string, className: string) => {
	return (
		<NavIconWrapper width={22}>
			<ReferAFriendIcon fill={color} className={className} />
		</NavIconWrapper>
	);
};
