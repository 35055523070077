import { StyleSheet } from 'aphrodite';
import { background, borderColor, brandPrimary, destructive } from '../../../../styles/colors';
import { CssSelectors } from '../../../../styles/styles';

export const styleSheet = StyleSheet.create({
	autoCompleteMenuItem: {
		color: brandPrimary,
	},
	autocompleteBox: {
		marginLeft: 5,
		width: 233,
		maxHeight: 304,
		overflowY: 'auto',
		overflowX: 'hidden',
		...CssSelectors.allChildren(
			{
				...CssSelectors.allChildren(
					{
						...CssSelectors.allChildren(
							{
								padding: '5px 10px',
							},
							'div'
						),
					},
					'div'
				),
				width: 205,
			},
			'div'
		),
	},
	container: {
		alignItems: 'center',
		background,
		border: `1px solid ${borderColor}`,
		borderRadius: 3,
		display: 'flex',
		flexWrap: 'wrap',
		margin: '10px 0',
		minHeight: 40,
		width: 360,
		...CssSelectors.allChildren({
			margin: 4,
		}),
	},
	createTag: {
		display: 'block',
		padding: 10,
		fontSize: 14,
	},
	searchBox: {
		border: 'none',
		height: 30,
	},
	separator: {
		fontSize: 12,
		marginLeft: 4,
	},
	sectionIcon: {
		marginRight: 8,
		height: 16,
		width: 16,
	},
	tag: {
		display: 'inline-block',
	},
	tagNot: {
		':nth-of-type(1n)': {
			background: '#FEF3F5',
		},
		...CssSelectors.allDescendants(
			{
				...CssSelectors.allChildren({
					':first-child': {
						fill: destructive,
					},
				}),
			},
			'.remove-tag-icon'
		),
	},
	sectionWrap: {
		borderBox: 'box-sizing',
		alignItems: 'center',
		display: 'flex',
		padding: 10,
		borderTop: `1px solid ${borderColor}`,
		borderBottom: `1px solid ${borderColor}`,
	},
	sectionTitle: {
		minWidth: 200,
		color: brandPrimary,
		fontSize: 14,
		margin: 0,
	},
	noMargin: {
		margin: 0,
	},
});
