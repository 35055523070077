import { nameCircles } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	fallback: {
		alignItems: 'center',
		backgroundColor: nameCircles,
		color: 'white',
		display: 'flex',
		fontSize: '1rem',
		height: '100%',
		justifyContent: 'center',
		lineHeight: 1,
		textTransform: 'uppercase',
		width: '100%',
	},
	image: {
		borderRadius: 'inherit',
		height: '100%',
		objectFit: 'cover',
		width: '100%',
	},
	root: {
		alignItems: 'center',
		backgroundColor: nameCircles,
		borderRadius: '100%',
		display: 'inline-flex',
		height: 60,
		justifyContent: 'center',
		overflow: 'hidden',
		userSelect: 'none',
		verticalAlign: 'middle',
		width: 60,
	},
});
