import { SvgIcon } from '../SvgIcon';

interface IProps {
	className?: string;
	fillColor?: string;
	height?: number;
	width?: number;
}

export const PostageIcon = ({ className, fillColor = '#fff', width = 20, height = 20 }: IProps) => {
	return (
		<SvgIcon className={className} fill={fillColor} height={height} viewBox='0 0 20 20' width={width}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.5 9C8.328 9 9 8.329 9 7.5C9 6.672 8.328 6 7.5 6C6.672 6 6 6.672 6 7.5C6 8.329 6.672 9 7.5 9Z'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M15.0002 13.9755L11.2752 7.99951L8.68622 12.1545L7.43722 10.1505L5.05322 13.9755H15.0002Z'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M16 16H4V4H16V16ZM20 3V2C20 0.896 19.104 0 18 0H17V2H15V0H13V2H11V0H9V2H7V0H5V2H3V0H2C0.896 0 0 0.896 0 2V3H2V5H0V7H2V9H0V11H2V13H0V15H2V17H0V18C0 19.104 0.896 20 2 20H3V18H5V20H7V18H9V20H11V18H13V20H15V18H17V20H18C19.104 20 20 19.104 20 18V17H18V15H20V13H18V11H20V9H18V7H20V5H18V3H20Z'
			/>
		</SvgIcon>
	);
};
