import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const ActionItemCheckboxSize = 18;

export const styleSheet = StyleSheet.create({
	container: {
		height: ActionItemCheckboxSize,
		width: ActionItemCheckboxSize,
		...CssSelectors.allDescendants(
			{
				display: 'none',
				opacity: 0,
				visibility: 'collapse',
			},
			'.action-item-checkbox-checked-icon'
		),
		...CssSelectors.allDescendants(
			{
				display: 'inline-block',
				opacity: 1,
				visibility: 'visible',
			},
			'.action-item-checkbox-unchecked-icon'
		),
	},
	input: {
		':checked': {
			':nth-child(1n) ~ svg .action-item-checkbox-checked-icon': {
				display: 'inline-block',
				opacity: 1,
				visibility: 'visible',
			},
			':nth-child(1n) ~ svg .action-item-checkbox-unchecked-icon': {
				display: 'none',
				opacity: 0,
				visibility: 'collapse',
			},
		},
		display: 'none',
		margin: 0,
		opacity: 0,
		padding: 0,
		visibility: 'collapse',
	},
});
