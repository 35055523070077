import { brandPrimary } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const DropDownIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;
	return (
		<SvgIcon className={`drop-down-icon ${className || ''}`} width={8} height={4} viewBox='0 0 8 4'>
			<path fillRule='evenodd' clipRule='evenodd' d='M4 4L8 0H0L4 4Z' fill={fillColor || brandPrimary} />
		</SvgIcon>
	);
};
