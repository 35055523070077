import { useEffect, useState } from 'react';
import { PersistentStorageManager } from '../../../../../models/Storage';
export const TagSearchesStorageKey = 'TagSearches';

export const updateTagsRecentSearchesLS = (tag: string, searches: string[]) => {
	const _searches = [...searches];
	if (!_searches.includes(tag)) {
		_searches.unshift(tag);
		if (_searches.length > 10) {
			_searches.pop();
		}
		PersistentStorageManager.local.setObject(TagSearchesStorageKey, _searches);
		return _searches;
	}
};

export const useTagsStorage = () => {
	const [recentSearches, setRecentSearches] = useState<string[]>([]);
	const storedContactsQueryStringValue = PersistentStorageManager.local.get(TagSearchesStorageKey);
	useEffect(() => {
		if (storedContactsQueryStringValue) {
			setRecentSearches(JSON.parse(storedContactsQueryStringValue) || []);
		}
	}, [storedContactsQueryStringValue]);

	const updateTagsRecentSearches = (tag: string) => {
		const searches = [...recentSearches];
		if (!searches.includes(tag)) {
			const newSearches = updateTagsRecentSearchesLS(tag, searches);
			setRecentSearches(newSearches);
		}
	};
	return { recentSearches, updateTagsRecentSearches };
};
