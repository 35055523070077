import {
	ContactViewModel,
	EmailAddress,
	IAccountTag,
	IAddress,
	ICompany,
	IContact,
	IKeepInTouch,
	IKeyFact,
	IOperationResult,
	IOperationResultNoValue,
	IPhoneNumber,
	KeepInTouchViewModel,
	QuickAddEntityViewModel,
	ResourceAutoCompleteViewModelType,
	ToastMessageType,
	VmUtils,
	getValueAtPropertyPath,
} from '@ViewModels';
import { css } from 'aphrodite';
import { action, computed, observable } from 'mobx';
import { Observer, inject, observer } from 'mobx-react';
import * as React from 'react';
import { v4 as uuidgen } from 'uuid';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IToasterComponentProps,
	IUserSessionComponentProps,
	ToasterViewModelKey,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import { getPrincipalInitials } from '../../../../models/UiUtils';
import { baseStyleSheet } from '../../../styles/styles';
import { Avatar2 } from '../../Avatar2';
import { LoadingSpinner } from '../../LoadingSpinner';
import { MoreMenu, MoreMenuItem } from '../../MoreMenu';
import { Portal } from '../../Portal';
import { TextArea } from '../../TextArea';
import { TextInput } from '../../TextInput';
import { VisibilityDropdown } from '../../VisibilityDropdown';
import {
	AutoCompleteSearchField,
	AutoCompleteSearchFieldAccessoryViewMode,
	IAutoCompleteSearchFieldComponent,
} from '../../autocomplete/AutoCompleteSearchField';
import { EditEntityInfoPhoneNumber } from '../../entities/EditEntityInfoPhoneNumber';
import { EditableEntityAddress } from '../../entities/EditableEntityAddress';
import { EditableEntityInfoBulletList } from '../../entities/EditableEntityInfoBulletList';
import { EntityInfoFieldLabel } from '../../entities/EntityInfoFieldLabel';
import { EntityInfoSection } from '../../entities/EntityInfoSection';
import { TagsListWithTagsEditor } from '../../entities/tags/TagsEditor';
import { ClearFieldIcon } from '../../svgs/icons/ClearFieldIcon';
import { KitDropdown } from '../KitDropdown';
import { styleSheet } from './styles';

interface IProps
	extends IEventLoggingComponentProps,
		IToasterComponentProps,
		IErrorMessageComponentProps,
		IUserSessionComponentProps {
	className?: string;
	compactLayout?: boolean;
	contact?: ContactViewModel;
	footerPortalDestinationId?: string;
	onFinish?(cancel: boolean): void;
}

interface IState {
	companyNameFieldText?: string;
	isEditingCompany?: boolean;
	kitFrequency?: number;
}

const TWO_MB = 2097152;

class _EditContactInfo extends React.Component<IProps, IState> {
	private mCompanyAutoCompleteFieldRef: IAutoCompleteSearchFieldComponent;
	private inputRef: React.RefObject<HTMLInputElement>;
	@observable private mEditableContactModel: IContact;
	@observable.ref private mQuickAddEntityViewModel: QuickAddEntityViewModel;
	public readonly state: IState = {};

	constructor(props: IProps) {
		super(props);
		this.inputRef = React.createRef<HTMLInputElement>();
	}

	public UNSAFE_componentWillMount() {
		const { userSession, logApiError, contact } = this.props;
		const editableContactModel: IContact = {
			address: null,
			bio: null,
			company: null,
			companyId: null,
			companyName: null,
			emailAddresses: [],
			firstName: null,
			id: null,
			jobTitle: null,
			keyFactsCollection: [],
			lastName: null,
			phoneNumbers: [],
			profilePic: null,
			tags: [],
			visibility: VmUtils.getDefaultVisibility(userSession.user),
			webSite: null,
			...(contact.toJs() || {}),
		};
		this.mEditableContactModel = editableContactModel;
		this.setState({
			companyNameFieldText: contact.companyName ? contact.companyName : null,
		});

		if (contact?.isValid) {
			const promise = contact.loadKeepInTouch();
			if (promise) {
				promise
					.then(() => {
						this.setState({
							kitFrequency: contact.keepInTouch.frequency,
						});
					})
					.catch((error: IOperationResultNoValue) => {
						if (error.systemCode !== 404) {
							logApiError('LoadKit-Error', error);
						}
					});
			}
		}
	}

	public render() {
		const { className, footerPortalDestinationId, compactLayout, contact } = this.props;
		const { kitFrequency } = this.state;
		const creatingCompany = !!this.mQuickAddEntityViewModel && !!this.mQuickAddEntityViewModel.isBusy;

		return (
			<div className={`${css(styleSheet.container)} edit-contact-info ${className || ''}`}>
				{this.renderSaveCancelButtons(css(styleSheet.saveCancelHeader))}
				<div className={css(styleSheet.title)}>Add/Edit Contact</div>
				<div className={css(compactLayout ? styleSheet.headerCompact : styleSheet.header)}>
					<div
						className={css(compactLayout ? styleSheet.headerAvatarContainerCompact : styleSheet.headerAvatarContainer)}
					>
						<Avatar2
							styleDeclaration={[styleSheet.avatar, styleSheet.avatarOverride]}
							fallbackText={getPrincipalInitials(contact)}
							imgSrc={contact?.profilePic}
						/>
						<input
							id='profileUploadInput'
							accept='image/png, image/jpeg, image/jpg'
							className=''
							onChange={this.onFileChange}
							type='file'
							ref={this.inputRef}
							style={{ position: 'absolute', visibility: 'hidden', zIndex: -1 }}
						/>
						{contact?.profilePic ? (
							<button className={css(styleSheet.deletePicButton)} onClick={this.onDeletePicClicked}>
								<span>Delete</span>
							</button>
						) : (
							<div>
								<p className={css(styleSheet.profilePlaceHolderBlurb)}>JPG or PNG, max size 2MB</p>
								<button type='button' className={css(baseStyleSheet.ctaButtonSmall)} onClick={this.handleUploadClick}>
									{contact.uploadingProfilePic ? <LoadingSpinner type='tiny' /> : <span>Upload</span>}
								</button>
							</div>
						)}
					</div>
				</div>
				<div className={css(styleSheet.nameFields, styleSheet.fieldGroup)}>
					{this.renderInputField('firstName', 'First Name')}
					{this.renderInputField('lastName', 'Last Name')}
				</div>
				<EntityInfoSection title='Title / Company'>
					<div className={css(styleSheet.fieldGroup)}>
						{this.renderInputField('jobTitle', 'Title')}
						<AutoCompleteSearchField
							anchorClassName={css(styleSheet.companyAutoCompleteAnchor)}
							className={css(styleSheet.companyAutoComplete)}
							dropdownClassName={css(styleSheet.companyAutoCompleteDropdown)}
							initialSearchQuery={contact.companyName ? contact.companyName : undefined}
							inputId='edit-contact-company-input'
							inputProps={{
								disabled: creatingCompany,
								onBlur: this.onCompanyAutoCompleteFieldBlur,
								onChange: this.onCompanyFieldChanged,
							}}
							onInnerRef={this.onCompanyAutoCompleteFieldRef}
							onItemSelected={this.onCompanyChanged}
							placeholder='Company'
							rightAccessory={
								this.mEditableContactModel?.companyName && (
									<button className={css(styleSheet.compayClearButton)} onMouseDown={this.clearCompany}>
										<ClearFieldIcon className={css(styleSheet.compayClearButtonIcon)} />
									</button>
								)
							}
							rightAccessoryViewMode={AutoCompleteSearchFieldAccessoryViewMode.WhileEditing}
							type={ResourceAutoCompleteViewModelType.Company}
						/>
					</div>
				</EntityInfoSection>
				<EntityInfoSection title='Visibility'>
					<VisibilityDropdown
						onVisibilityChanged={this.onVisibilityChanged}
						visibility={this.mEditableContactModel.visibility || 'admin'}
					/>
				</EntityInfoSection>
				<EntityInfoSection title='Reminders / Key Facts / Tags'>
					<div className={css(styleSheet.fieldGroup)}>
						<div>
							<EntityInfoFieldLabel
								title={`Keep in touch${
									this.mEditableContactModel.firstName ? ` ${this.mEditableContactModel.firstName}` : ''
								}`}
							/>
							<KitDropdown
								className={css(styleSheet.kitDropdown)}
								keepInTouchFrequency={kitFrequency}
								onFrequencyChanged={this.onKitFrequencyChanged}
							/>
						</div>
						<div>
							<EntityInfoFieldLabel title='Key facts' />
							<div className={css(styleSheet.fieldGroup)}>
								<EditableEntityInfoBulletList
									addButtonContent={<span>Add key fact</span>}
									items={this.mEditableContactModel.keyFactsCollection || []}
									itemValueKeyPath='value'
									onAddButtonClicked={this.onAddKeyFactButtonClicked}
									onItemValueChanged={this.onKeyFactValueChanged}
									onRemoveButtonClicked={this.onRemoveKeyFact}
								/>
							</div>
						</div>
						<div>
							<EntityInfoFieldLabel title='Tags' />
							<TagsListWithTagsEditor
								compactLayout={compactLayout}
								tagType={ResourceAutoCompleteViewModelType.AccountTag}
								entity={contact}
								onRequestAddTag={this.onRequestAddTag}
								onRequestRemoveTag={this.onRequestRemoveTag}
								tags={this.mEditableContactModel.tags.map(tag => ({ tag })) || []}
							/>
						</div>
					</div>
				</EntityInfoSection>
				<EntityInfoSection title='Contact Information'>
					<div className={css(styleSheet.fieldGroup)}>
						<div>
							<EntityInfoFieldLabel title='Email' />
							<EditableEntityInfoBulletList
								addButtonContent={<span>Add email</span>}
								items={this.mEditableContactModel.emailAddresses || []}
								itemValueKeyPath='value'
								onAddButtonClicked={this.addEmailButtonClicked}
								onRemoveButtonClicked={this.onRemoveEmail}
								onRenderItem={this.onRenderEditableEmail}
							/>
						</div>
						<div>
							<EntityInfoFieldLabel title='Phone Number' />
							<div className={css(styleSheet.fieldGroup)}>
								<EditableEntityInfoBulletList
									addButtonContent={<span>Add phone number</span>}
									items={this.mEditableContactModel.phoneNumbers || []}
									itemValueKeyPath='value'
									onAddButtonClicked={this.onAddPhoneNumber}
									onRemoveButtonClicked={this.onRemovePhoneNumber}
									onRenderItem={this.onRenderEditablePhoneNumber}
								/>
							</div>
						</div>
						<div>
							<EntityInfoFieldLabel title='Website' />
							{this.renderInputField('webSite', 'Website')}
						</div>
						<EditableEntityAddress
							address={this.mEditableContactModel.address}
							onAddressChanged={this.onAddressChanged}
						/>
						<div>
							<EntityInfoFieldLabel title='Misc' />
							<TextArea
								inputClassName={css(styleSheet.bioTextArea)}
								inputId='edit-contact-bio-input'
								onChange={this.onBioChanged}
								value={this.mEditableContactModel.bio || ''}
							/>
						</div>
					</div>
				</EntityInfoSection>
				{footerPortalDestinationId ? (
					<Portal destination={footerPortalDestinationId}>
						{this.renderSaveCancelButtons(css(styleSheet.footer))}
					</Portal>
				) : (
					this.renderSaveCancelButtons(css(styleSheet.footer))
				)}
				{!!this.isBusy && <LoadingSpinner className='absolute-center' type='large' />}
			</div>
		);
	}

	private handleUploadClick = () => {
		this.inputRef.current?.click();
	};

	private onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const { logApiError, contact } = this.props;
		const file: File = e.currentTarget.files?.[0];
		e.currentTarget.value = null;

		if (!file || file.size > TWO_MB) {
			this.showToasterMessage('File size exceeded 2MB', 'errorMessage');
			return; // cancelled the file picker
		}

		try {
			const opResult = await contact.uploadProfilePic(file);

			if (opResult) {
				this.mEditableContactModel.profilePic = opResult.profilePic;
				this.showToasterMessage('Contact profile picture uploaded and saved', 'successMessage');
			}
		} catch (error) {
			this.showToasterMessage('there was an issue uploading the image please try again later', 'errorMessage');
			logApiError('UploadContactPic-Error', error);
		}
	};

	private onDeletePicClicked = () => {
		const { logApiError, logInput, contact } = this.props;
		const contactModel = { ...(contact.toJs() || {}) };
		contactModel.profilePic = '';

		const promise = contact.update(contactModel);
		if (promise) {
			logInput('DeleteProfilePic', 'Click', { id: contact.id });
			promise
				.then(() => {
					this.mEditableContactModel.profilePic = '';
					this.showToasterMessage('Contact image deleted', 'successMessage');
				})
				.catch((error: IOperationResultNoValue) => {
					this.showToasterMessage('there was an issue removing the image please try again later', 'errorMessage');
					logApiError('DeleteProfilePic-Error', error);
				});
		}
	};

	private showToasterMessage = (message: string, type: ToastMessageType) => {
		if (this.props.toaster) {
			this.props.toaster.push({
				message,
				type,
			});
		}
	};

	private onEmailPrimaryClicked = (email: EmailAddress) => {
		this.mEditableContactModel.emailAddresses = [
			email,
			...this.mEditableContactModel.emailAddresses.filter(x => x !== email),
		];
	};

	private renderSaveCancelButtons(className?: string) {
		return (
			<div className={className || undefined}>
				<button className={css(baseStyleSheet.ctaButtonSmall)} disabled={!!this.isBusy} onClick={this.onSaveClicked}>
					<span>Save</span>
				</button>
				<button
					className={css(baseStyleSheet.ctaButtonReverseSmall)}
					disabled={!!this.isBusy}
					onClick={this.onCancelClicked}
				>
					<span>Cancel</span>
				</button>
			</div>
		);
	}

	private renderInputField(propertyName: keyof IContact, placeholder?: string) {
		return (
			<TextInput
				autoComplete='off'
				inputId={`edit-contact-${propertyName}-input`}
				onChange={this.onPropertyInputChanged(propertyName)}
				placeholder={placeholder}
				type='text'
				value={(this.mEditableContactModel[propertyName] as string) || ''}
			/>
		);
	}

	private onRenderEditablePhoneNumber = (phoneNumber: IPhoneNumber) => {
		return (
			<Observer>
				{() => {
					return (
						<EditEntityInfoPhoneNumber
							rootElementType='none'
							label={phoneNumber.label}
							onLabelChanged={this.onPhoneNumberLabelChanged(phoneNumber)}
							onValueChanged={this.onPhoneNumberValueChanged(phoneNumber)}
							value={phoneNumber.value}
						/>
					);
				}}
			</Observer>
		);
	};

	private onRenderEditableEmail = (email: EmailAddress, i: number) => {
		return (
			<Observer>
				{() => {
					const value = getValueAtPropertyPath(email, 'value');
					const moreThanOne = this.mEditableContactModel.emailAddresses?.length > 1;
					return (
						<div className={css(baseStyleSheet.flex, styleSheet.listItemTextField)} key={i}>
							<TextInput
								autoComplete='off'
								className={css(moreThanOne ? styleSheet.emailTextField : styleSheet.emailTextFieldFull)}
								inputId={`editable-entity-info-bullet-list-input-${i}`}
								onChange={this.onEmailValueChanged(email)}
								type='text'
								value={value || ''}
							/>
							{i > 0 && (
								<MoreMenu menuButtonClassName={css(styleSheet.emailMoreMenu)}>
									<MoreMenuItem onClick={() => this.onEmailPrimaryClicked(email)}>Set as primary email</MoreMenuItem>
								</MoreMenu>
							)}
							{i === 0 && (
								<div className={css(moreThanOne ? styleSheet.primaryText : styleSheet.primaryTextFull)}>primary</div>
							)}
						</div>
					);
				}}
			</Observer>
		);
	};

	@computed
	private get isBusy() {
		const { contact } = this.props;
		return !!contact.isBusy || (!!contact.keepInTouch && contact.keepInTouch.isBusy);
	}

	private createCompanyWithName = (companyName: string) => {
		const trimmedCompanyName = (companyName || '').trim();
		if (trimmedCompanyName) {
			const { userSession, logApiError, logInput, errorMessages } = this.props;
			this.mQuickAddEntityViewModel = new QuickAddEntityViewModel(userSession);
			this.mQuickAddEntityViewModel.companyName = trimmedCompanyName;
			this.mQuickAddEntityViewModel.entityType = 'company';
			const promise: Promise<IOperationResult<ICompany>> = this.mQuickAddEntityViewModel.save();
			if (promise) {
				logInput('CreateCompany', 'Click');
				promise
					.then(opResult => {
						this.onCompanyChanged(opResult.value);
						if (this.mCompanyAutoCompleteFieldRef) {
							this.mCompanyAutoCompleteFieldRef.setSearchQuery(opResult.value.companyName);
						}
						this.mQuickAddEntityViewModel = null;
					})
					.catch((error: IOperationResultNoValue) => {
						logApiError('CreateCompany-Error', error);
						errorMessages.pushApiError(error);
					});
			} else {
				this.mQuickAddEntityViewModel = null;
			}

			return promise;
		}
		return null;
	};

	@action
	private onCompanyChanged = (company: ICompany) => {
		this.mEditableContactModel.company = {
			companyName: company.companyName,
			id: company.id,
		};
		this.mEditableContactModel.companyId = company.id;
		this.mEditableContactModel.companyName = company.companyName;
		this.setState({
			companyNameFieldText: company.companyName,
		});
	};

	private onCompanyAutoCompleteFieldBlur = () => {
		const { companyNameFieldText } = this.state;
		if (this.mEditableContactModel.companyName !== companyNameFieldText) {
			// clear and set as new company
			this.mEditableContactModel.company = null;
			this.mEditableContactModel.companyId = null;
			this.mEditableContactModel.companyName = companyNameFieldText;
		}
	};

	private onCompanyAutoCompleteFieldRef = (ref?: IAutoCompleteSearchFieldComponent) => {
		this.mCompanyAutoCompleteFieldRef = ref;
	};

	private onCompanyFieldChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			companyNameFieldText: e.target.value,
		});
		if (!e.target.value) {
			this.clearCompany();
		}
	};

	@action
	private clearCompany = () => {
		if (this.mCompanyAutoCompleteFieldRef) {
			this.mCompanyAutoCompleteFieldRef.clearInput();
		}
		this.mEditableContactModel.company = null;
		this.mEditableContactModel.companyId = null;
		this.mEditableContactModel.companyName = null;
		this.setState({
			companyNameFieldText: null,
		});
	};

	private onAddressChanged = (address: IAddress) => {
		this.mEditableContactModel.address = address;
	};

	private onKitFrequencyChanged = (frequency: number) => {
		this.setState({
			kitFrequency: frequency,
		});
	};

	private onVisibilityChanged = (visibility: string) => {
		const { contact, errorMessages, userSession } = this.props;
		if (visibility === 'admin') {
			// check to make sure we can...
			// TODO: doesn't account for shared => admin
			const canChangeVisibility =
				contact.creatorId === userSession.user.id || (userSession.user.groups || []).findIndex(x => x === 'admin') >= 0;
			if (!canChangeVisibility) {
				errorMessages.push({
					messages: ['You do not have permission to make this contact private.'],
				});
				return;
			}
		}
		this.mEditableContactModel.visibility = visibility;
	};

	private onBioChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		this.mEditableContactModel.bio = e.target.value;
	};

	private onRemovePhoneNumber = (_: IPhoneNumber, index: number) => {
		const phoneNumbers = [...(this.mEditableContactModel.phoneNumbers || [])];
		phoneNumbers.splice(index, 1);
		this.mEditableContactModel.phoneNumbers = phoneNumbers;
	};

	private onAddPhoneNumber = () => {
		const phoneNumbers = [...(this.mEditableContactModel.phoneNumbers || []), { label: null, value: null }];
		this.mEditableContactModel.phoneNumbers = phoneNumbers;
	};

	private onRequestRemoveTag = (tag: IAccountTag) => {
		const index = (this.mEditableContactModel.tags || []).indexOf(tag.tag);
		if (index >= 0) {
			const tags = [...(this.mEditableContactModel.tags || [])];
			tags.splice(index, 1);
			this.mEditableContactModel.tags = tags;
		}
	};

	private onRequestAddTag = (tag: IAccountTag) => {
		if (
			this.mEditableContactModel.tags?.length &&
			tag?.tag &&
			this.mEditableContactModel.tags.find(x => x.toLocaleLowerCase() === tag.tag.toLocaleLowerCase())
		) {
			return;
		}
		const tags = [...(this.mEditableContactModel.tags || []), tag.tag];
		this.mEditableContactModel.tags = tags;
	};

	private onKeyFactValueChanged = (keyFact: IKeyFact, _: number, value: string) => {
		// we can change this directly because "this.mEditableContactModel" is @observable
		keyFact.value = value;
	};

	private onEmailValueChanged = (emailAddress: EmailAddress) => (event: React.ChangeEvent<HTMLInputElement>) => {
		// we can change this directly because "this.mEditableContactModel" is @observable
		emailAddress.value = (event.target.value || '').trim();
	};

	private onPhoneNumberValueChanged = (phoneNumber: IPhoneNumber) => (value: string) => {
		// we can change this directly because "this.mEditableContactModel" is @observable
		phoneNumber.value = value;
	};

	private onPhoneNumberLabelChanged = (phoneNumber: IPhoneNumber) => (label: string) => {
		// we can change this directly because "this.mEditableContactModel" is @observable
		phoneNumber.label = label;
	};

	private onRemoveEmail = (_: EmailAddress, index: number) => {
		const emailAddresses = [...(this.mEditableContactModel.emailAddresses || [])];
		emailAddresses.splice(index, 1);
		this.mEditableContactModel.emailAddresses = emailAddresses;
	};

	private addEmailButtonClicked = () => {
		const emailAddress: EmailAddress & { uuid: string } = {
			uuid: uuidgen(),
			value: null,
		};
		this.mEditableContactModel.emailAddresses = [...(this.mEditableContactModel.emailAddresses || []), emailAddress];
	};

	private onRemoveKeyFact = (_: IKeyFact, index: number) => {
		const keyFactsCollection = [...(this.mEditableContactModel.keyFactsCollection || [])];
		keyFactsCollection.splice(index, 1);
		this.mEditableContactModel.keyFactsCollection = keyFactsCollection;
	};

	private onAddKeyFactButtonClicked = () => {
		const keyFact: IKeyFact = {
			value: null,
		};
		this.mEditableContactModel.keyFactsCollection = [...(this.mEditableContactModel.keyFactsCollection || []), keyFact];
	};

	private onPropertyInputChanged = (propertyName: keyof IContact) => (e: React.ChangeEvent<HTMLInputElement>) => {
		(this.mEditableContactModel as any)[propertyName] = e.target.value;
	};

	private onSaveClicked = async () => {
		const { onFinish, logApiError, logEvent, logInput, errorMessages, userSession, contact } = this.props;
		const { kitFrequency } = this.state;

		// remove some empty properties
		const contactModel = {
			...this.mEditableContactModel,
		};

		VmUtils.removeEmptyKvpFromObject(contactModel);

		// validate
		let errorMessage: string = null;
		const hasEmailAddress = !!contactModel.emailAddresses && contactModel.emailAddresses.length > 0;
		const hasFullName = !!contactModel.firstName && !!contactModel.lastName;
		if (!hasEmailAddress && !hasFullName) {
			errorMessage = 'You must either enter an email address or the first/last name for a contact.';
		}

		if (errorMessage) {
			errorMessages.push({
				messages: [errorMessage],
			});
			return;
		}

		if (contactModel.keyFactsCollection) {
			contactModel.keyFactsCollection = contactModel.keyFactsCollection.filter(x => !!x && !!x.value);
		}

		if (contactModel.tags) {
			contactModel.tags = contactModel.tags.filter(x => !!x);
		}

		if (contactModel.emailAddresses) {
			contactModel.emailAddresses = contactModel.emailAddresses.filter(x => !!x && !!x.value);
		}

		if (contactModel.phoneNumbers) {
			contactModel.phoneNumbers = contactModel.phoneNumbers.filter(x => !!x && !!x.value);
		}

		// check to see if we need to create the company
		if (!!contactModel.companyName && (!contactModel.company || !contactModel.company.id)) {
			try {
				const opResult = await this.createCompanyWithName(contactModel.companyName);
				contactModel.companyName = opResult.value.companyName;
				contactModel.companyId = opResult.value.id;
				contactModel.company = { id: opResult.value.id };
			} catch (_) {
				// error already handled by "createCompanyWithName"
				return;
			}
		}

		// this will also create a contact if the contactModel.id === null/undefined
		const promise = contact.update(contactModel);
		if (promise) {
			logInput('Save', 'Click');
			// we clone the kit vm because updating the contact will reset the kit vm on the contact vm
			const kitToDelete =
				kitFrequency < 0 && !!contact.keepInTouch.Id
					? new KeepInTouchViewModel(userSession, contact.keepInTouch.toJs())
					: null;
			promise
				.then(() => {
					const finish = () => {
						if (onFinish) {
							onFinish(false);
						}
					};

					// set/delete kit if needed
					if (kitToDelete) {
						const kitPromise = kitToDelete.delete();
						if (kitPromise) {
							logEvent('DeleteKit', { ...contact.keepInTouch.toJs() });
							kitPromise
								.then(() => {
									finish();
								})
								.catch((error: IOperationResultNoValue) => {
									logApiError('DeleteKit-Error', error);
									errorMessages.pushApiError(error);
									finish();
								});
							return;
						}
					} else if (kitFrequency > 0) {
						const kit: IKeepInTouch = {
							contactId: contact.id,
							frequency: kitFrequency,
						};
						const kitPromise = contact.keepInTouch.save(kit);
						if (kitPromise) {
							logEvent('CreateKit', { ...kit });
							kitPromise
								.then(() => {
									finish();
								})
								.catch((error: IOperationResultNoValue) => {
									logApiError('CreateKit-Error', error);
									errorMessages.pushApiError(error);
									finish();
								});
							return;
						}
					}

					finish();
				})
				.catch((error: IOperationResultNoValue) => {
					logApiError('UpdateContact-Error', error);
					errorMessages.pushApiError(error);
					if (onFinish) {
						onFinish(false);
					}
				});
		}
	};

	private onCancelClicked = () => {
		const { onFinish } = this.props;
		if (onFinish) {
			onFinish(true);
		}
	};
}

const EditContactInfoAsObserver = observer(_EditContactInfo);
const EditContactInfoWithContext = inject(
	ToasterViewModelKey,
	ErrorMessagesViewModelKey,
	UserSessionViewModelKey
)(EditContactInfoAsObserver);
export const EditContactInfo = withEventLogging(EditContactInfoWithContext, 'EditContactInfo');
