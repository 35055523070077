import { StyleSheet } from 'aphrodite';
import {
	antiFlashWhite,
	brandPrimaryText,
	brandSecondary,
	charmGray,
	emailActivityTintColor,
	gray3,
	titles,
	white,
} from '../../../styles/colors';
import { CssSelectors, Layouts, cardStyle, truncateTextStyle } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	body: {
		background: 'transparent !important',
		color: `${gray3} !important`,
		fontFamily: 'Sora !important',
		marginTop: 0,
		overflow: 'hidden',
	},
	button: {
		background: 'none',
		color: `${charmGray} !important`,
		maxWidth: 145,
		padding: '4px 16px',
		':focus': {
			color: brandPrimaryText,
		},
		':hover': {
			color: brandPrimaryText,
		},
	},
	card: {
		...cardStyle,
		border: 'none',
		borderRadius: 0,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		boxSizing: 'border-box',
		color: brandPrimaryText,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	columnCard: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		overflow: 'hidden',
		padding: 16,
	},
	socialContainer: {
		...CssSelectors.allChildren(
			{
				marginBottom: 20,
				marginLeft: 20,
			},
			'button'
		),
		...CssSelectors.allDescendants(
			{
				border: `1px dashed ${brandSecondary}`,
				borderRadius: 3,
				boxSizing: 'border-box',
				fontSize: 14,
				padding: '1px 5px',
			},
			'span[data-placeholder="true"]'
		),
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
				maxWidth: 145,
			},
			'button'
		),
	},
	container: {
		alignItems: 'stretch',
		display: 'flex',
		flexDirection: 'column',
		marginBottom: 14,
		padding: 0,
		position: 'relative',
		...CssSelectors.allChildren(
			{
				marginBottom: 20,
				marginLeft: 20,
			},
			'button'
		),
		...CssSelectors.allDescendants(
			{
				border: `1px dashed ${brandSecondary}`,
				borderRadius: 3,
				boxSizing: 'border-box',
				fontSize: 14,
				padding: '1px 5px',
			},
			'span[data-placeholder="true"]'
		),
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
				maxWidth: 145,
			},
			'button'
		),
	},
	containerPostShadow: {
		boxShadow: '0px 0px 6px rgba(139, 139, 139, 0.1)',
	},
	containerPost: {
		background: '#fff',
		border: '1px solid #F2F2F2',
		borderRadius: 6,
		boxSizing: 'border-box',
		marginLeft: 1,
		marginTop: 1,
		paddingLeft: 0,
		paddingRight: 0,
		paddingTop: 0,
	},
	flagHighlight: {
		borderBottom: 'none',
		borderLeft: 'none',
		height: 27,
		width: 27,
		zIndex: 5,
	},
	imageContainer: {
		alignItems: 'center',
		display: 'flex',
		height: 100,
		justifyContent: 'center',
		margin: 0,
		padding: 0,
		paddingRight: 3,
		paddingTop: 15,
		position: 'relative',
		width: 100,
		...CssSelectors.allChildren(
			{
				height: 100,
				left: 0,
				width: 100,
			},
			'img'
		),
		...CssSelectors.allChildren(
			{
				height: 100,
				left: 0,
				width: 100,
			},
			'video'
		),
	},
	imageFit: {
		objectFit: 'cover',
	},
	name: {
		borderTopLeftRadius: 9,
		borderTopRightRadius: 9,
		boxSizing: 'border-box',
		color: titles,
		display: 'flex',

		fontSize: 14,
		paddingTop: 10,
		width: '100%',
		...truncateTextStyle,
	},
	personalCards: {
		height: 'calc(100% + 50px)',
		margin: 0,
		padding: '0 10px 10px',
	},
	popoverContainer: {
		display: 'flex',
		height: 28,
		maxWidth: '100%',
		paddingTop: 10,
		...CssSelectors.allChildren(
			{
				display: 'flex',
				height: 25,
				overflow: 'hidden',
				textOverflow: 'ellipsis',
			},
			'div'
		),
	},
	postName: {
		paddingLeft: 0,
		paddingTop: 2,
		width: '100%',
	},
	reviewed: {
		color: '#89C947',
	},
	select: {
		':not(:last-child)': {
			marginBottom: 10,
		},
		marginRight: 10,
		maxWidth: '100%',
		width: 'auto',
	},
	selectContainer: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
	},
	selectedCard: {
		background: '#E8F3F9',
	},
	sideBarContainer: {
		alignItems: 'stretch',
		display: 'flex',
		flexDirection: 'column',
		marginBottom: 14,
		padding: 0,
		position: 'relative',
	},
	socialIcon: {
		borderBottom: 'none',
		borderLeft: 'none',
		zIndex: 3,
	},
	socialIconContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		paddingBottom: 2,
	},
	socialIconContainerFlag: {
		display: 'flex',
		justifyContent: 'flex-end',
		paddingBottom: 0,
	},
	socialPopoverContainer: {
		marginLeft: '2px',
		width: '120px',
	},
	summaryPadding: {
		display: 'flex',
		padding: 15,
		paddingRight: 5,
		paddingTop: 0,
	},
	templateCard: {
		color: '#3F3F3F',
		paddingBottom: 20,
		width: '100%',
		...CssSelectors.allChildren(
			{
				color: brandPrimaryText,
			},
			'.campaign-template-card-name'
		),
	},
	templateCardContainer: {
		width: '100%',
	},
	templateCardDragging: {
		boxShadow: '0 0 10px 0 rgba(139,139,139,0.20)',
	},
	templateCards: {
		...Layouts.verticalStack(10),
		boxSizing: 'border-box',
		height: '100%',
		marginTop: 4,
		minHeight: 120,
		overflow: 'auto',
		paddingBottom: 10,
		paddingRight: 10,
	},
	templateCardsWithIndustrySelector: {
		boxSizing: 'border-box',
		height: `calc(100% - ${84}px)`,
	},
	textContainer: {},
	textContainerSmall: {
		fontSize: 12,
		height: 50,
		lineHeight: '16px',
		overflow: 'hidden',
		...CssSelectors.allDescendants({
			fontSize: 12,
		}),
	},
	titleContainer: {
		display: 'flex',
		flexDirection: 'row-reverse',
	},

	trigger: {
		height: '40px',
		padding: 0,
		paddingRight: 15,
	},
	triggerLabel: {
		borderRight: '1px solid rgb(216,216,216)',
		color: '#D1d1d1',
		fontSize: 12,
		letterSpacing: 1.1,
		paddingLeft: 10,
		paddingRight: 10,
		textTransform: 'uppercase',
		width: 160,
	},
	triggerText: {
		color: titles,
		paddingLeft: 10,
		width: '80%',
	},
});

export const campaignTemplateCardStyleSheet = StyleSheet.create({
	actionsColMoreButton: {
		position: 'absolute',
		right: 5,
		top: 5,
	},
	body: {
		background: 'transparent !important',
		color: '#3F3F3F !important',
		fontFamily: 'Sora !important',
		fontSize: '14px !important',
	},

	button: {
		background: 'none',
		marginTop: 20,
		maxWidth: 145,
		width: 145,
	},
	buttonStack: {
		justifyContent: 'space-between',
	},
	cardText: {
		background: 'transparent !important',
		color: 'titles !important',
		fontFamily: 'Sora !important',
		fontSize: '14px !important',
		paddingTop: 12,
		...CssSelectors.allDescendants({
			background: 'transparent !important',
			color: 'black !important',
			fontFamily: 'Sora !important',
			fontSize: '14px !important',
			height: 28,
			margin: 0,
		}),
	},
	container: {
		alignItems: 'stretch',
		background: white,
		border: `1px solid ${antiFlashWhite}`,
		borderRadius: 6,
		boxShadow: '0px 0px 6px rgba(139, 139, 139, 0.100034)',
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		paddingBottom: 30,
		paddingLeft: 30,
		position: 'relative',
		...CssSelectors.allDescendants(
			{
				border: `1px dashed ${brandSecondary}`,
				borderRadius: 3,
				boxSizing: 'border-box',
				fontSize: 14,
				padding: '1px 5px',
			},
			'span[data-placeholder="true"]'
		),
	},
	smallContainer: {
		padding: 16,
	},
	featured: {
		background: 'linear-gradient(156.83deg, #F5FAFD 14.57%, #E8F3F9 42%, #E8F3F9 85.02%)',
	},
	flagHighlight: {
		marginTop: 50,
	},
	headerContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		paddingBottom: 2,
	},
	menu: {
		position: 'absolute',
		right: 5,
		top: 5,
		width: 30,
	},
	nameContainer: {
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		boxSizing: 'border-box',
		color: titles,
		display: 'flex',
		gap: 8,
		alignItems: 'center',
		fontSize: 16,
		overflow: 'hidden',
		paddingTop: 30,
		textOverflow: 'ellipsis',
		width: '100%',
		...CssSelectors.allChildren(
			{
				maxWidth: '100%',
			},
			'div'
		),
	},
	nameContainerMedium: {
		color: brandPrimaryText,
	},
	nameContainerSmall: {
		color: brandPrimaryText,
		fontSize: 14,
		paddingTop: 0,
	},
	newItem: {
		color: emailActivityTintColor,
	},
	noPaddingTop: {
		paddingTop: 0,
	},
	openLetter: {
		height: 82,
		position: 'absolute',
		right: -10,
		top: -10,
		transform: 'translate3d(0px, -10px, 0)',
	},
	popover: {
		borderBottomRightRadius: 0,
		boxSizing: 'border-box',
		color: '#4A4A4A',
		fontSize: 12,
		lineHeight: '18px',
		marginLeft: 14,
		maxWidth: 240,
		padding: '6px 6px 6px 6px',
	},
	popoverAnchor: {
		zIndex: 5,
		top: 0,
		right: 0,
	},
	popoverContainer: {
		marginRight: 25,
	},
	reviewed: {
		color: '#89C947',
		marginRight: 20,
	},
	reviewedStack: {
		marginTop: 20,
		...Layouts.horizontalStack(1),
	},
	sendNow: {
		height: 82,
		left: 45,
		position: 'absolute',
		top: -5,
		transform: 'translate3d(-52px, -15px, 0px)',
	},
	sharedBy: {
		fontSize: 11,
		fontStyle: 'italic',
	},
	textContainer: {
		minHeight: 64,
	},
	textContainerSmall: {
		minHeight: 0,
	},
	textContent: {
		color: '#3F3F3F !important',
		fontFamily: 'Sora !important',
		fontSize: '14px !important',
		marginBottom: 0,
		marginTop: 0,
		paddingBottom: 0,
		paddingRight: 20,
		paddingTop: 12,
	},
	textContentSmall: {
		fontSize: '12px !important',
		height: 'auto',
	},
});
