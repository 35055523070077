import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	strokeColor?: string;
}

export const GroupVisibilityIcon: React.FC<IProps> = props => {
	const { className, strokeColor } = props;
	return (
		<SvgIcon className={`group-visibility-icon ${className || ''}`} height={11} width={14}>
			<g fill='none' fillRule='evenodd' stroke={strokeColor || '#00AAE8'} strokeWidth='1.5' transform='translate(1 1)'>
				<path d='M8.72727273,9.6 L8.72727273,8.53333333 C8.72727273,7.35512587 7.75043945,6.4 6.54545455,6.4 L2.18181818,6.4 C0.976833273,6.4 1.21115239e-16,7.35512587 0,8.53333333 L0,9.6' />
				<ellipse cx='4.364' cy='2.133' rx='2.182' ry='2.133' />
				<path d='M12 9.6L12 8.53333333C11.9992583 7.56112946 11.326363 6.7123841 10.3636364 6.46933333M8.18181818.0693333333C9.14722627.311023887 9.82245996 1.16159528 9.82245996 2.136 9.82245996 3.11040472 9.14722627 3.96097611 8.18181818 4.20266667' />
			</g>
		</SvgIcon>
	);
};
