import { StyleSheet } from 'aphrodite';
import { brandPrimary, brandPrimaryText, titles } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

const clockIconSize = 18;
const clockIconRightMargin = 10;

export const styleSheet = StyleSheet.create({
	anchor: {
		color: brandPrimaryText,
		fontSize: '14px',
		letterSpacing: 0,
		lineHeight: '20px',
	},
	anchorButton: {
		alignItems: 'center',
		color: brandPrimary,
		display: 'flex',
		fontSize: '14px',
		letterSpacing: 0,
		lineHeight: '20px',
	},
	anchorContent: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
	},
	icon: {
		height: clockIconSize,
		marginRight: clockIconRightMargin,
		width: clockIconSize,
	},
	kitDescription: {
		alignItems: 'center',
		color: '#858585',
		display: 'flex',
		fontSize: '14px',
		letterSpacing: 0,
		lineHeight: '20px',
		width: 'calc(100% - 60px)',
	},
	kitDropdown: {
		...CssSelectors.allChildren(
			{
				marginTop: 5,
				width: 160,
			},
			'.dropdown-content'
		),
	},
	option: {
		':hover': {
			backgroundColor: '#e3e7e9',
		},
		cursor: 'pointer',
		padding: '10px 20px',
	},
	options: {
		backgroundColor: 'white',
		border: '1px solid #e3e7e9',
		boxShadow: '0px 2px 4px 0px #e3e7e9',
		color: titles,
		fontSize: '14px',
	},
});
