import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
	disabled?: boolean;
}

export const ExportIcon: React.FC<IProps> = props => {
	const { className, fillColor, disabled } = props;

	return (
		<SvgIcon
			className={`export-icon ${className || ''}`}
			height={16}
			width={20}
			style={{ opacity: disabled ? 0.5 : 1 }}
		>
			<g fill={fillColor || '#89C947'} fillRule='evenodd'>
				<path d='M15,1.5859 L13.586,2.9999 L15.586,4.9999 L10,4.9999 C7.794,4.9999 6,6.7939 6,8.9999 L6,9.9999 L8,9.9999 L8,8.9999 C8,7.8979 8.897,6.9999 10,6.9999 L15.586,6.9999 L13.586,8.9999 L15,10.4139 L19.414,5.9999 L15,1.5859 Z' />
				<polygon points='11 14 2 14 2 2 13 2 13 0 0 0 0 16 13 16 13 11 11 11' />
			</g>
		</SvgIcon>
	);
};
