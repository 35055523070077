import { brandPrimary, brandPrimaryHover, darkGrayFontColor, nameCircles } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

const editorToolbarHeight = 40;
const footerPadding = 20;

export const styleSheet = StyleSheet.create({
	actionItem: {
		':not(:first-child)': {
			marginTop: 15,
		},
	},
	actionItems: {
		padding: '0 20px',
	},
	addActionItemButton: {
		':hover': {
			color: '#4a4a4a',
		},
		alignItems: 'center',
		color: '#858585',
		display: 'flex',
		fontSize: 12,
		margin: -5,
		marginLeft: 30,
		padding: 5,
	},
	attachments: {
		marginTop: 10,
	},
	attendeesHeader: {
		fontSize: 12,
	},
	ccField: {
		paddingBottom: 10,
		paddingLeft: 0,
		paddingRight: 0,
	},
	editableNoteEditorBody: {
		borderTop: '1px solid #ccc',
		flexBasis: 0,
		marginTop: editorToolbarHeight,
		minHeight: `calc(100% - ${editorToolbarHeight + footerPadding * 2 + 40}px)`, // 40 refers to the height of the CTA in the footer
	},
	editor: {
		flexGrow: 1,
		minHeight: 60,
		...CssSelectors.allDescendants(
			{
				background: '#fff',
				position: 'fixed',
				transform: `translate(0, -${editorToolbarHeight}px)`,
				width: 'inhert',
				zIndex: 1,
			},
			'.tox-editor-header'
		),
	},
	emailHeader: {
		marginTop: 10,
	},
	mentionField: {
		borderBottom: '1px #f2f2f2 solid',
		borderTop: '1px #f2f2f2 solid',
		paddingBottom: 10,
		paddingTop: 10,
	},
	noteEditor: {
		display: 'block',
	},
	noteEditorBody: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		overflowY: 'auto',
		...CssSelectors.allChildren(
			{
				width: '100%',
			},
			'*'
		),
	},
	noteEditorCompactFooterSave: {
		width: '100%',
	},
	noteEditorFooter: {
		':first-child': {
			borderTop: `1px solid ${nameCircles}`,
			marginTop: 10,
		},
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		paddingTop: footerPadding,
	},
	noteEditorFooterItem: {
		':not(:first-child)': {
			marginLeft: 20,
		},
	},
	noteEditorFooterItemGroup: {
		alignItems: 'center',
		display: 'flex',
	},
	noteEditorFooterRow: {
		alignContent: 'stretch',
		flexDirection: 'row',
		flexGrow: 1,
		justifyContent: 'space-between',
	},
	noteEditorFooterSingleItem: {
		flexGrow: 1,
	},
	previewContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		paddingTop: 10,
		...CssSelectors.allChildren({
			fontSize: 14,
			margin: 0,
		}),
	},
	previewCta: {
		':focus': {
			color: brandPrimaryHover,
			outline: `1px dashed ${darkGrayFontColor}`,
			outlineOffset: -1,
		},
		':hover': {
			color: brandPrimaryHover,
		},
		color: brandPrimary,
		height: 22,
		padding: '0 2px',
	},
	previewShowLessContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
	},
	privacyToggle: {},
	readOnlyNoteActionItems: {
		marginTop: 20,
	},
	richContentContainer: {
		position: 'relative',
		zIndex: 0,
	},
	setFollowUpBanner: {
		flexGrow: 1,
		height: '100%',
	},
});
