import { SearchBase } from '../../../SearchBase';
import { styleSheet } from '../styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onClickToClear?: () => void;
	placeholder?: string;
	value: string;
}

export const SearchField: React.FC<IProps> = ({ onChange, onClickToClear, placeholder = '', value }) => {
	return (
		<div className={css(styleSheet.searchField)}>
			<SearchBase
				id='search-input'
				inputStyles={[styleSheet.field]}
				onChange={onChange}
				placeholder={placeholder}
				value={value}
			/>
			{value && onClickToClear ? (
				<button className={css(styleSheet.searchFieldClear)} onClick={onClickToClear}>
					Clear
				</button>
			) : null}
		</div>
	);
};
