import { grayIconFill } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
	styles?: StyleDeclarationValue[];
}

export const AutomationsIcon: React.FC<IProps> = props => {
	const { className, fillColor, styles } = props;
	return (
		<SvgIcon className={`automations-icon ${css(...(styles || []))} ${className || ''}`} height={20} width={20}>
			<path
				fill={fillColor || grayIconFill}
				fillRule='evenodd'
				d='M17.998,18 L14,18 L14,15 L17.998,15 L17.998,18 Z M6,18 L2,18 L2,15 L6,15 L6,18 Z M8,2 L12,2 L12,5 L8,5 L8,2 Z M18.998,13 L16.998,13 L16.998,12 C16.998,10.346 15.653,9 14,9 L11,9 L11,7 L13,7 C13.552,7 14,6.552 14,6 L14,1 C14,0.448 13.552,0 13,0 L7,0 C6.448,0 6,0.448 6,1 L6,6 C6,6.552 6.448,7 7,7 L9,7 L9,9 L6,9 C4.346,9 3,10.346 3,12 L3,13 L1,13 C0.448,13 0,13.448 0,14 L0,19 C0,19.552 0.448,20 1,20 L7,20 C7.552,20 8,19.552 8,19 L8,14 C8,13.448 7.552,13 7,13 L5,13 L5,12 C5,11.449 5.449,11 6,11 L14,11 C14.55,11 14.998,11.449 14.998,12 L14.998,13 L13,13 C12.448,13 12,13.448 12,14 L12,19 C12,19.552 12.448,20 13,20 L18.998,20 C19.55,20 19.998,19.552 19.998,19 L19.998,14 C19.998,13.448 19.55,13 18.998,13 L18.998,13 Z'
			/>
		</SvgIcon>
	);
};
