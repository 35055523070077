import { brandSecondary } from '../../styles/colors';
import { BreakPoints } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	bodyContainer: {
		margin: 'auto',
		maxWidth: 450,
		paddingLeft: 10,
		paddingRight: 10,
	},
	centered: {
		paddingLeft: 30,
		paddingRight: 30,
		textAlign: 'center',
	},
	confirmationGraphic: {
		height: 97,
		margin: '0px auto 30px auto',
		width: 97,
	},
	container: {
		position: 'relative',
		textAlign: 'center',
		width: '100%',
	},
	errorMessage: {
		color: '#b34755',
		textAlign: 'center',
	},
	graphic: {
		height: 114,
		margin: '0px auto 45px auto',
		width: 120,
	},
	hidden: {
		opacity: 0,
		pointerEvents: 'none',
		visibility: 'hidden',
	},
	input: {
		display: 'inline-block',
		marginBottom: 10,
		marginRight: 10,
		maxWidth: 300,
		width: '90%',
	},
	inputContainer: {
		minWidth: 430,
		[BreakPoints.forMaxWidth(500)]: {
			maxWidth: '450px',
			minWidth: 'unset',
		},
	},
	inputElement: {
		width: '95%',
	},
	logo: {
		margin: '0 auto 20px auto',
	},
	routeContainer: {
		alignItems: 'center',
		background: '#fff',
		display: 'flex',
		height: '100%',
		justifyContent: 'center',
	},
	saveButtonWrapper: {
		display: 'inline-block',
		marginLeft: 0,
		[BreakPoints.forMaxWidth(500)]: {
			display: 'block',
			margin: '0 auto',
			width: 65,
		},
	},
	text: {
		color: '#4a4a4a',
		fontSize: 14,
		padding: '0 0 20px 0',
		textAlign: 'left',
		[BreakPoints.forMaxWidth(500)]: {
			textAlign: 'center',
		},
	},
	title: {
		color: brandSecondary,
		fontSize: 18,
		marginBottom: 25,
		textAlign: 'center',
	},
});
