import { ErrorMessagesViewModelKey, IErrorMessageComponentProps } from '../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../models/Logging';
import { getFileSizeStringValue } from '../../../models/UiUtils';
import { AttachmentsToBeUploadedViewModel, FileAttachmentViewModel } from '../../../viewmodels/AppViewModels';
import { FileIcon } from '../svgs/icons/FileIcon';
import { NewAttachment } from './NewAttachment';
import { styleSheet } from './styles';
import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';

interface IAttachmentProps {
	attachment: FileAttachmentViewModel;
	className?: string;
	onClick: (attachment: FileAttachmentViewModel) => void;
}

const AttachmentSfc: React.FC<IAttachmentProps> = props => {
	const { attachment, onClick, className } = props;
	if (attachment.embedded) {
		return null;
	}

	const onAttachmentClicked = () => {
		if (onClick) {
			onClick(attachment);
		}
	};

	const fileSizeStringValue = getFileSizeStringValue(attachment.fileSize);

	return (
		<div
			className={`${css(styleSheet.currentAttachment, styleSheet.attachment)} attachment ${className || ''}`}
			key={attachment.id}
			onClick={onAttachmentClicked}
			title={attachment.fileName}
		>
			<FileIcon type={attachment.contentType} />
			<div className={`${css(styleSheet.fileName)}`}>{attachment.fileName}</div>
			<span className={css(styleSheet.attachmentSize)} title={fileSizeStringValue}>
				{fileSizeStringValue}
			</span>
		</div>
	);
};

const ObservableAttachment = observer(AttachmentSfc);
const Attachment = withEventLogging(ObservableAttachment, 'Attachment');

interface IProps extends IErrorMessageComponentProps, IEventLoggingComponentProps {
	attachments: FileAttachmentViewModel[];
	className?: string;
	disableOnClick?: boolean;
	newAttachments?: AttachmentsToBeUploadedViewModel<File>;
	readonly?: boolean;
}

class _Attachments extends React.Component<IProps> {
	public render() {
		const { className, attachments, disableOnClick } = this.props;
		return (
			<div className={`attachments ${css(styleSheet.currentAttachments)} ${className || ''}`}>
				<div className={css(styleSheet.attachmentsContainer)}>
					{attachments?.map(a => (
						// @ts-ignore
						<Attachment key={a.id} attachment={a} onClick={disableOnClick ? null : this.onClickAttachment} />
					))}
					{this.renderNewAttachments()}
				</div>
			</div>
		);
	}

	private renderNewAttachments() {
		const { newAttachments, readonly } = this.props;
		if (!newAttachments || !newAttachments.attachments) {
			return null;
		}

		return newAttachments.attachmentsWithMetadata.map(a => (
			<NewAttachment
				attachment={a.file}
				className={css(styleSheet.attachment)}
				error={!!a.exceedsMaxByteCount}
				key={a.file.name}
				// @ts-ignore
				onRemoveClick={!readonly && this.onRemoveNewAttachment}
			/>
		));
	}

	private onRemoveNewAttachment = (file: File) => {
		const { newAttachments } = this.props;
		if (newAttachments) {
			newAttachments.remove([file]);
		}
	};

	private onClickAttachment = (attachment: FileAttachmentViewModel) => {
		const { logApiError, logInput } = this.props;
		// @ts-ignore
		logInput('GetDownloadLink', 'Click');
		attachment
			.getDownloadInfo()
			.then(result => {
				window.open(result.url, '_blank');
			})
			.catch((error: Api.IOperationResultNoValue) => {
				// @ts-ignore
				logApiError('GetDownloadLink-Error', error);
			});
	};
}

const AttachmentsAsObserver = observer(_Attachments);
const AttachmentsWithContext = inject(ErrorMessagesViewModelKey)(AttachmentsAsObserver);
export const Attachments = withEventLogging(AttachmentsWithContext, 'Attachments');
