import { brandPrimary, brandPrimaryHover, darkGrayFontColor, navigation } from '../../../styles/colors';
import { BreakPoints, CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	avatar: {
		flexShrink: 0,
		height: 80,
		width: 80,
	},
	avatarOverride: {
		fontSize: 26,
		marginRight: 16,
	},
	bioTextArea: {
		minHeight: 120,
	},
	bulletListItemTextField: {
		flexGrow: 1,
		minWidth: 0,
	},
	button: {
		alignItems: 'center',
		color: brandPrimary,
		display: 'flex',
		fontSize: 14,
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	companyAutoComplete: {
		flexGrow: 1,
	},
	companyAutoCompleteAnchor: {
		flexGrow: 1,
	},
	companyAutoCompleteDropdown: {
		display: 'block',
	},
	compayClearButton: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
	},
	compayClearButtonIcon: {
		height: 18,
		width: 18,
	},
	container: {
		position: 'relative',
	},
	deletePicButton: {
		color: brandPrimaryHover,
		fontSize: 14,
	},
	emailMoreMenu: {
		marginLeft: 6,
	},
	emailTextField: {
		flexGrow: 1,
		maxWidth: '90%',
		[BreakPoints.forMaxWidth(300)]: {
			maxWidth: '85%',
		},
	},
	emailTextFieldFull: {
		flexGrow: 1,
	},
	fieldGroup: {
		...CssSelectors.allChildren(
			{
				marginTop: 20,
			},
			':not(:first-child)'
		),
	},
	footer: {
		alignItems: 'center',
		background: '#fff',
		boxSizing: 'border-box',
		display: 'flex',
		paddingBottom: 30,
		paddingTop: 30,
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	header: {
		display: 'block',
	},
	headerAvatarContainer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		marginBottom: 20,
	},
	headerAvatarContainerCompact: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		marginBottom: 20,
	},
	headerCompact: {
		display: 'block',
	},
	kitDropdown: {
		display: 'block',
	},
	listItemTextField: {
		flexGrow: 1,
		position: 'relative',
	},
	nameFields: {
		flexGrow: 1,
	},
	primaryText: {
		color: navigation,
		fontSize: 10,
		position: 'absolute',
		right: 40,
		[BreakPoints.forMaxWidth(300)]: {
			right: 50,
		},
		textTransform: 'uppercase',
		top: -15,
	},
	primaryTextFull: {
		color: navigation,
		fontSize: 10,
		position: 'absolute',
		right: 6,
		textTransform: 'uppercase',
		top: -15,
	},
	profilePlaceHolderBlurb: {
		color: '#858585',
		fontSize: 14,
		margin: '10px 0 7px',
	},
	saveCancelHeader: {
		alignItems: 'center',
		background: '#fff',
		boxSizing: 'border-box',
		display: 'flex',
		justifyContent: 'flex-end',
		marginBottom: 20,
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	title: {
		color: darkGrayFontColor,
		fontSize: 14,
		marginBottom: 30,
		textTransform: 'uppercase',
	},
});
