import { StyleSheet } from 'aphrodite';
import { CssSelectors, Layouts } from '../../../styles/styles';
import { brandPrimaryText, brandSecondary, hotPink, titles } from '../../../styles/colors';

export const styleSheet = StyleSheet.create({
	actionsColMoreButton: {
		position: 'absolute',
		right: 5,
		top: 5,
	},
	body: {
		background: 'transparent !important',
		color: '#3F3F3F !important',
		fontFamily: 'Sora !important',
		fontSize: '14px !important',
	},
	button: {
		background: 'none',
		marginTop: 20,
		maxWidth: 145,
		width: 145,
	},
	buttonStack: {
		justifyContent: 'space-between',
	},
	cardText: {
		background: 'transparent !important',
		color: 'titles !important',
		fontFamily: 'Sora !important',
		fontSize: '14px !important',
		paddingTop: 12,
		...CssSelectors.allDescendants({
			background: 'transparent !important',
			color: 'black !important',
			fontFamily: 'Sora !important',
			fontSize: '14px !important',
			height: 28,
			margin: 0,
		}),
	},
	container: {
		alignItems: 'stretch',
		background: '#fff',
		border: '1px solid #f2f2f2',
		borderRadius: 6,
		boxShadow: '0px 0px 6px rgba(139, 139, 139, 0.100034)',
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		paddingBottom: 30,
		paddingLeft: 30,
		position: 'relative',
		...CssSelectors.allDescendants(
			{
				border: `1px dashed ${brandSecondary}`,
				borderRadius: 3,
				boxSizing: 'border-box',
				fontSize: 14,
				padding: '1px 5px',
			},
			'span[data-placeholder="true"]'
		),
	},
	featured: {
		background: 'linear-gradient(156.83deg, #F5FAFD 14.57%, #E8F3F9 42%, #E8F3F9 85.02%)',
	},
	flagHighlight: {
		marginTop: 50,
	},
	headerContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		paddingBottom: 2,
	},
	menu: {
		position: 'absolute',
		right: 5,
		top: 5,
		width: 30,
	},
	nameContainer: {
		borderTopLeftRadius: 9,
		borderTopRightRadius: 9,
		boxSizing: 'border-box',
		color: titles,
		display: 'flex',
		gap: 8,
		alignItems: 'center',
		fontSize: 16,
		overflow: 'hidden',
		paddingTop: 30,
		textOverflow: 'ellipsis',
		width: '100%',
		...CssSelectors.allChildren(
			{
				maxWidth: '100%',
			},
			'div'
		),
	},
	nameContainerMedium: {
		color: brandPrimaryText,
	},
	nameContainerSmall: {
		color: brandPrimaryText,
		fontSize: 14,
		paddingTop: 12,
	},
	newItem: {
		color: hotPink,
	},
	noPaddingTop: {
		paddingTop: 0,
	},
	openLetter: {
		height: 82,
		position: 'absolute',
		right: -10,
		top: -10,
		transform: 'translate3d(0px, -10px, 0)',
	},
	popover: {
		borderBottomRightRadius: 0,
		boxSizing: 'border-box',
		color: '#4A4A4A',
		fontSize: 12,
		lineHeight: '18px',
		marginLeft: 14,
		maxWidth: 240,
		padding: '6px 6px 6px 6px',
	},
	popoverAnchor: {
		zIndex: 5,
	},
	popoverContainer: {
		marginRight: 25,
	},
	reviewed: {
		color: '#89C947',
		marginRight: 20,
	},
	reviewedStack: {
		marginTop: 20,
		...Layouts.horizontalStack(1),
	},
	sendNow: {
		height: 82,
		left: 45,
		position: 'absolute',
		top: -5,
		transform: 'translate3d(-52px, -15px, 0px)',
	},
	sharedBy: {
		fontSize: 11,
		fontStyle: 'italic',
	},
	textContainer: {
		minHeight: 64,
	},
	textContainerSmall: {
		minHeight: 0,
	},
	textContent: {
		color: '#3F3F3F !important',
		fontFamily: 'Sora !important',
		fontSize: '14px !important',
		marginBottom: 0,
		marginTop: 0,
		paddingBottom: 0,
		paddingRight: 20,
		paddingTop: 12,
	},
	textContentSmall: {
		fontSize: '12px !important',
		height: 'auto',
	},
});
