import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const PrivateVisibilityIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;
	return (
		<SvgIcon className={`private-visibility-icon ${className || ''}`} height={13} width={9}>
			<g fill={fillColor || '#D3000D'} fillRule='evenodd'>
				<path d='M5.46428571,9.32142857 C5.46428571,9.85371429 5.03228571,10.2857143 4.5,10.2857143 C3.96771429,10.2857143 3.53571429,9.85371429 3.53571429,9.32142857 C3.53571429,8.78914286 3.96771429,8.35714286 4.5,8.35714286 C5.03228571,8.35714286 5.46428571,8.78914286 5.46428571,9.32142857' />
				<path d='M1.28571429,11.5714286 L1.28571429,7.07142857 L7.71428571,7.07142857 L7.71557143,11.5714286 L1.28571429,11.5714286 Z M2.57142857,3.21428571 C2.57142857,2.151 3.43671429,1.28571429 4.5,1.28571429 C5.56328571,1.28571429 6.42857143,2.151 6.42857143,3.21428571 L6.42857143,5.78571429 L2.57142857,5.78571429 L2.57142857,3.21428571 Z M7.71428571,5.78571429 L7.71428571,3.21428571 C7.71428571,1.44192857 6.27235714,0 4.5,0 C2.72764286,0 1.28571429,1.44192857 1.28571429,3.21428571 L1.28571429,5.78571429 C0.576642857,5.78571429 0,6.36171429 0,7.07142857 L0,11.5714286 C0,12.2805 0.576642857,12.8571429 1.28571429,12.8571429 L7.71428571,12.8571429 C8.42335714,12.8571429 9,12.2805 9,11.5714286 L9,7.07142857 C9,6.36171429 8.42335714,5.78571429 7.71428571,5.78571429 L7.71428571,5.78571429 Z' />
			</g>
		</SvgIcon>
	);
};
