import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const LightningBoltIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;
	return (
		<SvgIcon className={`lightning-bolt-icon ${className || ''}`} height={32} width={22}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M21.333 13.333h-8V0L0 18.667h8V32l13.333-18.667z'
				fill={fillColor || '#00AAE8'}
			/>
		</SvgIcon>
	);
};
