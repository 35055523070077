import { borderColor, brandPrimary, brandPrimaryHover, grayIconFill, header } from '../../../../styles/colors';
import { CssSelectors } from '../../../../styles/styles';
import { StyleSheet } from 'aphrodite';

const navBarWidth = 72;
const leftWidth = 250;
const rightButtonWidth = 275;

export const styleSheet = StyleSheet.create({
	andMore: {
		alignItems: 'center',
		color: grayIconFill,
		display: 'flex',
		fontSize: 14,
		fontStyle: 'italic',
		height: 32,
		paddingLeft: 50,
		...CssSelectors.allChildren(
			{
				fontSize: 14,
			},
			'span'
		),
	},
	autocompleteContainer: {
		background: 'white',
		border: `1px solid ${borderColor}`,
		borderRadius: 3,
		marginLeft: 1,
		marginTop: 1,
		maxWidth: 640,
		padding: '20px 0',
		width: `calc(100vw - ${navBarWidth}px - ${leftWidth}px - ${rightButtonWidth}px)`,
	},
	container: {},
	highlight: {
		background: 'rgba(232, 243, 249, 0.5)',
	},
	inputAnchor: {
		maxWidth: 640,
	},
	inputContainer: {
		background: 'white',
		border: '1px solid #CCC',
		borderRadius: 3,
		display: 'flex',
		height: 40,
		maxWidth: 640,
		padding: 0,
		paddingLeft: 14,
		position: 'relative',

		...CssSelectors.allChildren(
			{
				'::placeholder': {
					color: header,
					fontSize: 16,
				},
			},
			'input'
		),
	},
	recentSearch: {
		width: '100%',
	},
	searchButton: {
		':active': {
			background: '#143C64',
		},

		':hover': {
			background: '#2B6CB0',
		},
		alignItems: 'center',
		background: brandPrimary,
		borderBottomRightRadius: 3,
		borderTopRightRadius: 3,
		cursor: 'pointer',
		display: 'flex',
		height: 40,
		justifyContent: 'center',
		position: 'absolute',
		right: -1,
		top: -1,
		width: 40,
	},
	searchIcon: {
		fill: 'white',
		height: 20,
		width: 20,
	},
	title: {
		':first-of-type': {
			marginTop: 0,
		},
		color: brandPrimaryHover,
		fontSize: 12,
		letterSpacing: 1.3,
		margin: '8px 30px',
		textTransform: 'uppercase',
	},
});
