import { PhoneNumberLabelDictionary, PhoneNumberLabels } from '../../../../models';
import { ISelectBoxOption, SelectBox } from '../../SelectBox';
import { TextInput } from '../../TextInput';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	label?: string;
	onLabelChanged?(label: string): void;
	onValueChanged?(value: string): void;
	rootElementType?: 'none' | 'div';
	value?: string;
}

interface IState {
	label?: string;
	value?: string;
}

export class EditEntityInfoPhoneNumber extends React.Component<IProps, IState> {
	public static defaultProps: Partial<IProps> = {
		rootElementType: 'div',
	};

	public readonly state: IState = {
		label: this.props.label,
		value: this.props.value,
	};

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		const nextState: IState = {};

		if (nextProps.value !== this.state.value) {
			nextState.value = nextProps.value;
		}

		if (nextProps.label !== this.state.label) {
			nextState.label = nextProps.label;
		}

		if (Object.keys(nextState).length > 0) {
			this.setState(nextState);
		}
	}

	public render() {
		const { className, rootElementType } = this.props;
		const { value, label } = this.state;
		const lowerLabel = label ? label.toLowerCase() : null;
		const hasDefaultLabel = !!lowerLabel && !!PhoneNumberLabelDictionary[lowerLabel];
		// if the number has a label and the label isn't one of the defaults, add it to the list
		const options =
			!!lowerLabel && !hasDefaultLabel
				? [{ title: label, value: lowerLabel }, ...PhoneNumberLabels]
				: PhoneNumberLabels;
		const content = (
			<>
				<SelectBox
					className={css(styleSheet.phoneNumberSelectBox)}
					onSelectionChanged={this.onPhoneNumberLabelChanged}
					options={options}
					optionClassName={css(styleSheet.phoneNumberSelectBoxOption)}
					placeholder={<span className={css(styleSheet.placeholder)}>Label...</span>}
					selectedOption={lowerLabel ? options.find(x => x.value === lowerLabel) : undefined}
					triggerClassName={css(styleSheet.phoneNumberSelectBoxTrigger)}
				/>
				<TextInput
					autoComplete='off'
					className={css(styleSheet.bulletListItemTextField)}
					inputId='edit-entity-phone-number-input'
					onChange={this.onPhoneNumberValueChanged}
					type='text'
					value={value || ''}
				/>
			</>
		);

		if (!!rootElementType && rootElementType !== 'none') {
			return React.createElement(
				rootElementType,
				`${css(styleSheet.container)} edit-entity-info-phone-number ${className || ''}`,
				content
			);
		}
		return content;
	}

	private onPhoneNumberValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { onValueChanged } = this.props;
		const value = e.target.value;
		if (onValueChanged) {
			onValueChanged(value);
		} else {
			this.setState({
				value,
			});
		}
	};

	private onPhoneNumberLabelChanged = (selectedOption: ISelectBoxOption<string>) => {
		const { onLabelChanged } = this.props;
		const label = selectedOption.title as string;
		if (onLabelChanged) {
			onLabelChanged(label);
		} else {
			this.setState({
				label,
			});
		}
	};
}
