import { FullscreenModalHeaderContext } from '@AppModels/.';
import { DeprecatedCloseButton } from '@WebComponents/DeprecatedCloseButton';
import { Portal } from '@WebComponents/Portal';
import { css } from 'aphrodite';
import * as React from 'react';
import { BackArrowIcon } from '../../svgs/icons/BackArrowIcon';
import { styleSheet } from './styles';

interface IProps {
	onRequestClose?(e: React.MouseEvent<HTMLElement>): void;
	onRequestBack?(e: React.MouseEvent<HTMLElement>): void;
}

export const FullscreenModalHeader: React.FC<IProps> = props => {
	return (
		<FullscreenModalHeaderContext.Consumer>
			{({
				headerTitlePortalDestinationId,
				onClose,
				onBack,
				headerRightAccessoryPortalDestinationId,
				headerLeftAccessoryPortalDestinationId,
			}) => {
				const onRequestClose = (e: React.MouseEvent<HTMLElement>) => {
					if (props.onRequestClose) {
						props.onRequestClose(e);
					}

					if (!e.defaultPrevented && !!onClose) {
						onClose();
					}
				};
				const onGoBack = (e: React.MouseEvent<HTMLElement>) => {
					if (props.onRequestBack) {
						props.onRequestBack(e);
					}

					if (!e.defaultPrevented && !!onBack) {
						onBack();
					}
				};
				return (
					<>
						{!!headerLeftAccessoryPortalDestinationId && (
							<Portal destination={headerLeftAccessoryPortalDestinationId}>
								<button onClick={onGoBack}>
									<BackArrowIcon fillColor='#fff' />
								</button>
							</Portal>
						)}
						{!!headerTitlePortalDestinationId && (
							<Portal destination={headerTitlePortalDestinationId}>
								<div className={css(styleSheet.container)}>{props.children}</div>
							</Portal>
						)}
						{!!headerRightAccessoryPortalDestinationId && (
							<Portal destination={headerRightAccessoryPortalDestinationId}>
								<DeprecatedCloseButton fillColor='#fff' onClick={onRequestClose} />
							</Portal>
						)}
					</>
				);
			}}
		</FullscreenModalHeaderContext.Consumer>
	);
};
