import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

interface IOtherFileProps extends IProps {
	foldFillColor?: string;
}

interface IFileIconProps extends IOtherFileProps {
	type: 'doc' | 'pdf' | 'image' | 'other';
}

const DocFileIcon: React.FC<IProps> = props => {
	const { className } = props;
	return (
		<SvgIcon className={`doc-file-icon ${className || ''}`} width={15} height={20}>
			<g fill='none' fillRule='evenodd'>
				<g transform='translate(0 .063)'>
					<mask id='docicon-b' fill='#fff'>
						<polygon id='docicon-a' points='0 .143 14.608 .143 14.608 19.794 0 19.794' />
					</mask>
					<path
						fill='#5380C0'
						d='M11.6185155,19.7938351 L2.99006186,19.7938351 C1.33872165,19.7938351 -4.12371134e-05,18.4550722 -4.12371134e-05,16.803732 L-4.12371134e-05,3.13342268 C-4.12371134e-05,1.48208247 1.33872165,0.143319588 2.99006186,0.143319588 L10.484701,0.143319588 L14.6086186,4.28579381 L14.6086186,16.803732 C14.6086186,18.4550722 13.2698557,19.7938351 11.6185155,19.7938351'
						mask='url(#docicon-b)'
					/>
				</g>
				<g transform='translate(10.31 .063)'>
					<mask id='docicon-d' fill='#fff'>
						<polygon id='docicon-c' points='.175 .143 4.299 .143 4.299 4.287 .175 4.287' />
					</mask>
					<path
						fill='#FFF'
						d='M4.29931959,4.28703093 L1.02385567,4.28703093 C0.559731959,4.28703093 0.183237113,3.91136082 0.182206186,3.44703093 L0.175402062,0.143319588'
						mask='url(#docicon-d)'
					/>
				</g>
				<polygon fill='#FFF' fillRule='nonzero' points='2.528 8.56 12.08 8.56 12.08 7.56 2.528 7.56' />
				<polygon fill='#FFF' fillRule='nonzero' points='2.528 10.783 12.08 10.783 12.08 9.783 2.528 9.783' />
				<polygon fill='#FFF' fillRule='nonzero' points='2.528 13.006 12.08 13.006 12.08 12.006 2.528 12.006' />
				<polygon fill='#FFF' fillRule='nonzero' points='2.528 15.23 7.304 15.23 7.304 14.23 2.528 14.23' />
			</g>
		</SvgIcon>
	);
};

const PdfFileIcon: React.FC<IProps> = props => {
	const { className } = props;
	return (
		<SvgIcon className={`pdf-file-icon ${className || ''}`} width={15} height={20}>
			<g fill='none' fillRule='evenodd'>
				<g transform='translate(0 .063)'>
					<mask id='pdficon-b' fill='#fff'>
						<polygon id='pdficon-a' points='0 .143 14.608 .143 14.608 19.794 0 19.794' />
					</mask>
					<path
						fill='#EE3E2C'
						d='M11.6184948,19.7938351 L2.99004124,19.7938351 C1.33870103,19.7938351 -6.18556701e-05,18.4550722 -6.18556701e-05,16.803732 L-6.18556701e-05,3.13342268 C-6.18556701e-05,1.48208247 1.33870103,0.143319588 2.99004124,0.143319588 L10.4846804,0.143319588 L14.6085979,4.28579381 L14.6085979,16.803732 C14.6085979,18.4550722 13.2698351,19.7938351 11.6184948,19.7938351'
						mask='url(#pdficon-b)'
					/>
				</g>
				<g transform='translate(10.31 .063)'>
					<mask id='pdficon-d' fill='#fff'>
						<polygon id='pdficon-c' points='.175 .143 4.299 .143 4.299 4.287 .175 4.287' />
					</mask>
					<path
						fill='#FFF'
						d='M4.29931959,4.28703093 L1.02406186,4.28703093 C0.559731959,4.28703093 0.183237113,3.91136082 0.182206186,3.44703093 L0.175402062,0.143319588'
						mask='url(#pdficon-d)'
					/>
				</g>
				<path
					fill='#FFF'
					d='M8.58239175,12.5660412 C7.39105155,12.7852165 6.58218557,13.0926392 5.42218557,13.5503711 C5.76692784,12.8794433 6.41909278,11.1676907 6.73270103,10.0388247 C7.1716701,10.9167629 7.70445361,11.7759381 8.58239175,12.5660412 M6.88321649,8.92892784 C6.67620619,8.19552577 6.38775258,6.56521649 6.8516701,6.03841237 C7.76094845,6.55882474 7.19661856,7.79408247 6.88321649,8.92892784 M7.41620619,6.3394433 C7.29703093,5.91284536 7.00857732,5.69985567 6.76404124,5.73119588 C6.46919588,5.76872165 6.15579381,5.94439175 6.03043299,6.22026804 C5.67909278,7.01037113 6.41290722,9.33676289 6.52569072,9.72542268 C5.81084536,11.8825361 3.36548454,16.1462474 2.16775258,16.4349072 C2.14280412,16.1526392 2.29331959,15.3373814 3.87331959,14.3217113 C3.95496907,14.2338763 4.04898969,14.1272784 4.09290722,14.0582062 C2.75723711,14.7103711 1.02651546,15.7575876 2.06754639,16.541299 C2.12383505,16.5852165 2.2116701,16.6229485 2.31208247,16.6604742 C3.10837113,16.9553196 4.22445361,15.9895464 5.34692784,13.8010928 C6.58218557,13.3936701 7.57909278,13.0862474 8.99002062,12.8730515 C10.5324948,13.920268 11.567134,14.1336701 12.2694021,13.8639794 C12.4638351,13.7887216 12.7710515,13.5441856 12.8588866,13.2241856 C12.2881649,13.9266598 10.952701,13.4375876 9.88672165,12.7666598 C10.8710515,12.6600619 11.8869278,12.5971753 12.3258969,12.7351134 C12.8838351,12.9107835 12.8650722,13.1866598 12.8588866,13.2241856 C12.9028041,13.0738763 12.9654845,12.8293402 12.8463093,12.6349072 C12.376,11.8699588 10.2188866,12.3151134 9.43517526,12.3967629 C8.19991753,11.6505773 7.34713402,10.3210928 7.00218557,9.36171134 C7.32197938,8.12645361 7.66692784,7.22975258 7.41620619,6.3394433'
				/>
			</g>
		</SvgIcon>
	);
};

const ImageFileIcon: React.FC<IProps> = props => {
	const { className } = props;
	return (
		<SvgIcon className={`image-file-icon ${className || ''}`} width={15} height={20}>
			<g fill='none' fillRule='evenodd'>
				<g transform='translate(0 .08)'>
					<mask id='imageicon-b' fill='#fff'>
						<polygon id='imageicon-a' points='0 .129 14.618 .129 14.618 19.792 0 19.792' />
					</mask>
					<path
						fill='#00AB54'
						d='M11.6260417,19.79175 L2.99208333,19.79175 C1.33958333,19.79175 0,18.4519583 0,16.7996667 L0,3.1205 C0,1.468 1.33958333,0.128416667 2.99208333,0.128416667 L10.4914583,0.128416667 L14.618125,4.27383333 L14.618125,16.7996667 C14.618125,18.4519583 13.2785417,19.79175 11.6260417,19.79175'
						mask='url(#imageicon-b)'
					/>
				</g>
				<path
					fill='#FFF'
					d='M14.6181042,4.3546875 L11.3406042,4.3546875 C10.8762292,4.3546875 10.4993542,3.97864583 10.4983125,3.51427083 L10.4914375,0.2084375'
				/>
				<polygon fill='#FFF' points='2.865 15.094 5.022 11.895 6.913 13.883 9.229 9.665 12.025 15.094' />
				<path
					fill='#FFF'
					d='M4.78227083,9.531875 C4.78227083,9.89958333 4.48414583,10.1975 4.11664583,10.1975 C3.7489375,10.1975 3.4508125,9.89958333 3.4508125,9.531875 C3.4508125,9.16416667 3.7489375,8.86625 4.11664583,8.86625 C4.48414583,8.86625 4.78227083,9.16416667 4.78227083,9.531875'
				/>
			</g>
		</SvgIcon>
	);
};

const OtherFileIcon: React.FC<IProps & IOtherFileProps> = props => {
	const { className, foldFillColor = '#fff' } = props;
	return (
		<SvgIcon className={`other-file-icon ${className || ''}`} width={15} height={20}>
			<g fill='none' fillRule='evenodd'>
				<g transform='translate(0 .08)'>
					<mask id='othericon-b' fill='#fff'>
						<polygon id='othericon-a' points='0 .129 14.618 .129 14.618 19.792 0 19.792' />
					</mask>
					<path
						fill='#00AAE8'
						d='M11.6260417,19.79175 L2.99208333,19.79175 C1.33958333,19.79175 0,18.4519583 0,16.7996667 L0,3.1205 C0,1.468 1.33958333,0.128416667 2.99208333,0.128416667 L10.4914583,0.128416667 L14.618125,4.27383333 L14.618125,16.7996667 C14.618125,18.4519583 13.2785417,19.79175 11.6260417,19.79175'
						mask='url(#othericon-b)'
					/>
				</g>
				<path
					fill={foldFillColor}
					d='M14.6181042,4.3546875 L11.3406042,4.3546875 C10.8762292,4.3546875 10.4993542,3.97864583 10.4983125,3.51427083 L10.4914375,0.2084375'
				/>
			</g>
		</SvgIcon>
	);
};

export const FileIcon: React.FC<IFileIconProps> = props => {
	switch (props.type) {
		case 'doc':
			return DocFileIcon(props);
		case 'pdf':
			return PdfFileIcon(props);
		case 'image':
			return ImageFileIcon(props);
		case 'other':
		default:
			return OtherFileIcon(props);
	}
};
