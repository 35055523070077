import {
	ActionItemViewModel,
	IOperationResultNoValue,
	NoteViewModel,
	RichContentComposerResult,
	RichContentReferenceMethod,
} from '@ViewModels';
import { css } from 'aphrodite';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { v4 as uuidgen } from 'uuid';
import * as AppState from '../../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import {
	convertRawRichTextContentStateToRichContentEditorState,
	getDefaultDateStringValue,
	getDisplayName,
} from '../../../../models/UiUtils';
import { actionItemTintColor, brandPrimary, warningDark } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { DeleteConfirmation } from '../../DeleteConfirmation';
import { MoreMenu, MoreMenuItem } from '../../MoreMenu';
import { ActionItemAssociatedNoteHeader } from '../../actionItems/ActionItemAssociatedNoteHeader';
import { ActionItemCheckbox } from '../../actionItems/ActionItemCheckbox';
import { RichContentDocumentEditor } from '../../richContent/RichContentDocumentEditor';
import { AssigneeIcon } from '../../svgs/icons/AssigneeIcon';
import { ClockIcon } from '../../svgs/icons/ClockIcon';
import { ColorCodedCard } from '../ColorCodedCard';
import { IFeedCardCallbackProps } from '../FeedCard';
import { FeedCardMentionedEntitiesHeader } from '../FeedCardMentionedEntitiesHeader';
import { styleSheet } from './styles';

interface IProps
	extends IFeedCardCallbackProps,
		AppState.IErrorMessageComponentProps,
		IEventLoggingComponentProps,
		AppState.IUserSessionComponentProps,
		AppState.IActionItemComposerComponentProps,
		AppState.ISingleEmailComposerComponentProps,
		AppState.INoteComposerComponentProps {
	actionItem: ActionItemViewModel;
	canToggleCompleted?: boolean;
	className?: string;
	hideHeaders?: boolean;
	hideRemindMeLaterOption?: boolean;
	moreMenuDisabled?: boolean;
	onCheckChanged?(checked: boolean): void;
	onClick?(e: React.MouseEvent<HTMLElement>): void;
	readonly?: boolean;
	sendMessageDisabled?: boolean;
	/** If true, will show the note composer modal without first navigating to /Notes. (Default === false) */
	showAssociatedNoteModalLocally?: boolean;
}

interface IState {
	associatedNote?: NoteViewModel;
	isComplete?: boolean;
	showDeleteConfirmation?: boolean;
}

class _ActionItemFeedCard extends React.Component<IProps, IState> {
	public readonly state: IState = {};
	// @ts-ignore
	private checkboxElement: HTMLElement;
	private uuid = uuidgen();
	// @ts-ignore
	private mMounted: boolean;

	public componentDidMount() {
		this.mMounted = true;
	}
	public componentWillUnmount() {
		this.mMounted = false;
	}

	public render() {
		const { className, hideHeaders } = this.props;
		const { showDeleteConfirmation } = this.state;
		return (
			<ColorCodedCard
				{...this.elementDataProps}
				className={`feed-card ${className || ''}`}
				footer={this.renderFooter()}
				header={this.renderHeader()}
				hideHeader={!!hideHeaders}
				indicatorColor={this.isOverdue ? warningDark : actionItemTintColor}
				onClick={this.onClick}
				styles={[styleSheet.container]}
				leftAccessory={this.renderCheckbox()}
			>
				{!hideHeaders && (
					<FeedCardMentionedEntitiesHeader
						className={css(styleSheet.header)}
						mentionedEntities={this.mentionedEntities}
						subtitle={this.renderSubtitle()}
					>
						{({ entityList, overflow }) => {
							return (
								<div className={css(styleSheet.title, baseStyleSheet.truncateText)}>
									{!!entityList && (
										<>
											{entityList}
											{!!overflow && (
												<>
													&nbsp;
													{overflow}
												</>
											)}
										</>
									)}
								</div>
							);
						}}
					</FeedCardMentionedEntitiesHeader>
				)}
				<div className={css(styleSheet.body, this.noHeaderNoFooter && styleSheet.bodyNoHeader)}>
					<div className={css(styleSheet.bodyContent)}>
						<RichContentDocumentEditor
							className={css(styleSheet.readOnlyContent)}
							// @ts-ignore
							contentState={this.readOnlyEditorState}
							readOnly={true}
						/>
					</div>
				</div>
				<DeleteConfirmation
					bodyText='Are you sure you want to delete this action item? Once deleted it will be gone forever.'
					isOpen={!!showDeleteConfirmation}
					onFinish={this.onDeleteConfirmationFinished}
				/>
			</ColorCodedCard>
		);
	}

	@computed
	private get noHeaderNoFooter() {
		const { actionItem } = this.props;
		return (
			!this.mentionedEntities.length &&
			!actionItem.dueDate &&
			(!actionItem.assignee || this.isSelfAssigned) &&
			!actionItem.associatedNotesReferencedEntities?.length
		);
	}

	private showDeleteConfirmation = () => {
		this.setState({
			showDeleteConfirmation: true,
		});
	};

	private onDeleteConfirmationFinished = (shouldDelete: boolean) => {
		const { actionItem, logInput, onRequestRemove, logApiError, errorMessages, onShowDeleteConfirmation } = this.props;

		if (shouldDelete) {
			const performDelete = () => {
				const promise = actionItem.delete();
				if (promise) {
					promise
						.then(() => {
							// @ts-ignore
							logInput('Delete', 'Click', {
								actionItem: { id: actionItem.id },
							});
							if (onRequestRemove) {
								onRequestRemove();
							}
						})
						.catch(error => {
							// @ts-ignore
							logApiError('Delete-Error', error);
							// @ts-ignore
							errorMessages.pushApiError(error);
						});
				}
			};

			if (onShowDeleteConfirmation) {
				onShowDeleteConfirmation(performDelete);
			} else {
				performDelete();
			}
		}
		this.setState({
			showDeleteConfirmation: false,
		});
	};

	private renderCheckbox() {
		const { actionItem } = this.props;

		return (
			<div
				className={css(
					styleSheet.checkboxContainer,
					actionItem.isCompleted && styleSheet.checkboxContainerCompleted,
					this.isOverdue && styleSheet.checkboxContainerOverdue
				)}
			>
				<div className={css(styleSheet.checkboxInner)}>
					<ActionItemCheckbox
						fillColor={this.isOverdue ? warningDark : undefined}
						checked={actionItem.isCompleted || false}
						disabled={!this.canToggleCompleted}
						id={`action-item-card-checkbox-${this.uuid}`}
						onChange={this.onCheckChanged}
						rootElementRef={this.onCheckboxRef}
					/>
				</div>
			</div>
		);
	}

	private renderHeader() {
		const { moreMenuDisabled, hideRemindMeLaterOption } = this.props;

		if (moreMenuDisabled) {
			return null;
		}

		return (
			<div className={css(styleSheet.moreMenuHeader)}>
				<MoreMenu>
					{moreMenuDisabled ? null : (
						<>
							<MoreMenuItem onClick={() => this.onMoreMenuItemClicked('edit')}>Edit</MoreMenuItem>
							{!hideRemindMeLaterOption ? (
								<MoreMenuItem onClick={() => this.onMoreMenuItemClicked('snooze')}>Remind me later</MoreMenuItem>
							) : null}
							<MoreMenuItem onClick={() => this.onMoreMenuItemClicked('delete')}>Delete</MoreMenuItem>
						</>
					)}
				</MoreMenu>
			</div>
		);
	}

	private renderSubtitle() {
		const { actionItem } = this.props;
		const { associatedNote } = this.state;
		if (actionItem.associatedNoteModel) {
			return (
				<ActionItemAssociatedNoteHeader
					actionItem={actionItem.toJs()}
					disabled={(!!associatedNote && !!associatedNote.isBusy) || !!actionItem.isBusy}
					onSeeNoteClicked={this.onSeeAssociatedNoteClicked}
				/>
			);
		}
	}

	@computed
	private get isOverdue() {
		const { actionItem } = this.props;
		return moment(actionItem.dueDate).isBefore(moment()) && !actionItem.isCompleted;
	}

	@computed
	private get isSelfAssigned() {
		const { actionItem, userSession } = this.props;
		return (
			!!actionItem.assignee &&
			!!actionItem.creator &&
			// @ts-ignore
			// @ts-ignore
			actionItem.creator.id === userSession.user.id &&
			actionItem.creator.id === actionItem.assignee.id
		);
	}

	private renderFooter() {
		const { actionItem, userSession, sendMessageDisabled } = this.props;
		const dueDate = !!actionItem.dueDate && (
			<div className={css(styleSheet.dueDate)}>
				<div className={css(styleSheet.footerOptionIcon)}>
					<ClockIcon fillColor={brandPrimary} className={css(styleSheet.dueDateIcon)} />
				</div>
				<div>
					<span>{this.isOverdue ? 'Overdue' : 'Due'}:&nbsp;</span>
					<span className={css(styleSheet.footerAccent)}>{getDefaultDateStringValue(actionItem.dueDate)}</span>
				</div>
			</div>
		);

		const assigneeNode = !!actionItem.assignee && !this.isSelfAssigned && (
			<div className={css(baseStyleSheet.truncateText)}>
				<div className={css(styleSheet.footerOptionIcon)}>
					<AssigneeIcon fillColor={brandPrimary} className={css(styleSheet.assigneeIcon)} />
				</div>
				<div className={css(baseStyleSheet.truncateText)}>
					{/* @ts-ignore */}
					{/* @ts-ignore */}
					{actionItem.assignee.id === userSession.user.id ? (
						// @ts-ignore
						<span>{`${getDisplayName(actionItem.creator, true)} assigned this to you`}</span>
					) : (
						<>
							<span>Assigned to:&nbsp;</span>
							<span className={css(styleSheet.footerAccent)}>{getDisplayName(actionItem.assignee, true)}</span>
						</>
					)}
				</div>
			</div>
		);
		const showSendEmailButton = !sendMessageDisabled && actionItem.referencedContactsForSendMessage?.length > 0;
		if (!!dueDate || !!assigneeNode || !!showSendEmailButton) {
			return (
				<div className={css(styleSheet.footer)}>
					<div className={css(baseStyleSheet.truncateText, styleSheet.footerLeft)}>
						{dueDate || null}
						{assigneeNode || null}
					</div>
					{!!showSendEmailButton && (
						<button
							className={css(baseStyleSheet.ctaButtonSmall, styleSheet.sendButton)}
							onClick={this.onSendMessageClicked}
						>
							<span>Send Email</span>
						</button>
					)}
				</div>
			);
		}
	}

	@computed
	private get elementDataProps() {
		return {};
	}

	@computed
	private get mentionedEntities() {
		const { actionItem } = this.props;
		return actionItem.mentionedEntities
			.filter(x => x.method === RichContentReferenceMethod.Explicit)
			.map(x => x.entity);
	}

	@computed
	private get readOnlyEditorState() {
		const { actionItem } = this.props;
		return actionItem
			? convertRawRichTextContentStateToRichContentEditorState(actionItem.rawContentState, actionItem.preview)
			: null;
	}

	@computed
	private get canToggleCompleted() {
		const { actionItem, canToggleCompleted, readonly } = this.props;
		return (!!canToggleCompleted || !readonly) && !!actionItem.id && !actionItem.isBusy;
	}

	private onMoreMenuItemClicked = (moreMenuItem: string) => {
		const { actionItem, logInput, logApiError, onRequestRemove, errorMessages, actionItemComposer } = this.props;
		switch (moreMenuItem) {
			case 'edit': {
				// @ts-ignore
				actionItemComposer.show(actionItem, this.onEditActionItemFinished);
				break;
			}
			case 'delete': {
				this.showDeleteConfirmation();
				break;
			}
			case 'snooze': {
				// update the due date and remove
				const promise = actionItem.snooze(7);
				if (promise) {
					promise
						.then(() => {
							// @ts-ignore
							logInput('Snooze', 'Click', {
								actionItem: { id: actionItem.id },
								numberOfDays: 7,
							});
							if (onRequestRemove) {
								onRequestRemove();
							}
						})
						.catch(error => {
							// @ts-ignore
							logApiError('Snooze-Error', error);
							// @ts-ignore
							errorMessages.pushApiError(error);
						});
				}
				break;
			}
			default: {
				break;
			}
		}
	};

	private onEditActionItemFinished = (_?: ActionItemViewModel, result?: RichContentComposerResult) => {
		const { onRequestRemove } = this.props;
		if (result === RichContentComposerResult.Delete && !!onRequestRemove) {
			onRequestRemove();
		}
	};

	private onSeeAssociatedNoteClicked = (e: React.MouseEvent<HTMLElement>) => {
		// prevent the click event from opening the edit action item modal, because we could be navigating away from the current 'page'
		e.stopPropagation();
		const {
			showAssociatedNoteModalLocally,
			actionItem,
			userSession,
			noteComposer,
			logApiError,
			logInput,
			errorMessages,
			logEvent,
		} = this.props;
		if (!!showAssociatedNoteModalLocally && !!actionItem.associatedNoteModel) {
			const { associatedNote } = this.state;
			// @ts-ignore
			let note: NoteViewModel = associatedNote;
			if (!associatedNote) {
				// @ts-ignore
				note = new NoteViewModel(userSession, actionItem.associatedNoteModel);
				this.setState({
					associatedNote: note,
				});
			}
			e.preventDefault();
			if (!note.isBusy) {
				const promise = note.load();
				if (promise) {
					// @ts-ignore
					logInput('SeeAssociatedNote', 'Click', { id: note.id });
					promise
						.then(() => {
							if (this.mMounted) {
								// @ts-ignore
								noteComposer.show(note, (updatedNote?: NoteViewModel, composerResult?: RichContentComposerResult) => {
									if (!!this.mMounted && !!composerResult) {
										const removeActionItem = () => {
											const { onRequestRemove } = this.props;
											if (onRequestRemove) {
												onRequestRemove();
											}
										};
										switch (composerResult) {
											case RichContentComposerResult.Delete: {
												removeActionItem();
												break;
											}
											case RichContentComposerResult.Edit: {
												if (!actionItem.isBusy) {
													// find the action item in in the note
													// @ts-ignore
													const updatedActionItem = updatedNote.actionItems.find(x => x.id === actionItem.id);
													if (updatedActionItem) {
														// would like to just use updatedActionItem to update the vm, but there will be missing props (e.g. associatedNote, referenced entities, etc.)
														// Need to reload the entire action item.
														const reloadPromis = actionItem.load();
														if (reloadPromis) {
															// @ts-ignore
															logEvent('Reload', { id: actionItem.id });
															reloadPromis.catch((error: IOperationResultNoValue) => {
																// @ts-ignore
																errorMessages.pushApiError(error);
																// @ts-ignore
																logApiError('Reload-Error', error);
															});
														}
													} else {
														// action item must have been removed from the note
														removeActionItem();
													}
												}
												break;
											}
											default: {
												break;
											}
										}
									}
								});
							}
						})
						.catch((error: IOperationResultNoValue) => {
							// @ts-ignore
							errorMessages.pushApiError(error);
							// @ts-ignore
							logApiError('SeeAssociatedNote-Error', error);
						});
				} else {
					this.setState({
						// @ts-ignore
						associatedNote: null,
					});
				}
			}
		}
	};

	private onSendMessageClicked = () => {
		const { logInput, singleEmailComposer, actionItem } = this.props;
		// @ts-ignore
		logInput('SendMessage', 'Click');

		// @ts-ignore
		singleEmailComposer.showForActionItem(actionItem, {
			onDismiss: this.onEmailModalDismissActionItem,
			onFinish: this.onEmailModalFinished,
		});
	};

	private onEmailModalDismissActionItem = () => {
		const { onRequestRemove } = this.props;
		if (onRequestRemove) {
			onRequestRemove();
		}
	};

	private onEmailModalFinished = (didSend: boolean) => {
		const { onRequestRemove } = this.props;
		if (!!didSend && !!onRequestRemove) {
			onRequestRemove();
		}
	};

	private onCheckboxRef = (ref: HTMLElement) => {
		this.checkboxElement = ref;
	};

	private onClick = (e: React.MouseEvent<HTMLElement>) => {
		if (!!this.canToggleCompleted && !!this.checkboxElement && this.checkboxElement.contains(e.target as HTMLElement)) {
			e.preventDefault();
			e.stopPropagation();
			this.toggleCheckChange();
			return;
		}

		const { onClick } = this.props;
		if (onClick) {
			onClick(e);
		}
	};

	private onCheckChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		e.stopPropagation();
		this.toggleCheckChange();
	};

	private toggleCheckChange = () => {
		const { actionItem, logApiError, logInput, onCheckChanged } = this.props;
		const isCompleted = !actionItem.isCompleted;
		const promise = actionItem.toggleComplete(isCompleted);
		if (promise) {
			const action = `Toggle${isCompleted ? 'Complete' : 'InProgress'}`;
			// @ts-ignore
			logInput(action, 'Click');
			this.setState({
				isComplete: isCompleted,
			});
			promise
				.then(() => {
					this.setState({
						// @ts-ignore
						isComplete: null,
					});
					if (onCheckChanged) {
						onCheckChanged(isCompleted);
					}
				})
				.catch((error: IOperationResultNoValue) => {
					this.setState({
						// @ts-ignore
						isComplete: null,
					});
					// @ts-ignore
					logApiError(`${action}-Error`, error);
				});
		}
	};
}

const ActionItemFeedCardAsObserver = observer(_ActionItemFeedCard);
const ActionItemFeedCardWithContext = inject(
	AppState.ErrorMessagesViewModelKey,
	AppState.UserSessionViewModelKey,
	AppState.ActionItemComposerViewModelKey,
	AppState.SingleEmailComposerKey,
	AppState.NoteComposerViewModelKey
)(ActionItemFeedCardAsObserver);
export const ActionItemFeedCard = withEventLogging(ActionItemFeedCardWithContext, 'ActionItemFeedCard');
