import { grayIconFill } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		color: grayIconFill,
		fontSize: 12,
		marginBottom: 10,
		marginTop: -10,
	},
});
