/** Separating into own colors file in case we want to rebrand COFFEE to something different that Lev branding. */
export const actionItemTintColor = '#89C947';
export const background = '#F9F9F9';
export const backgroundGreen = '#DBF2C3';
export const backgroundGreenDark = '#CDEDAB';
export const black = '#000000';
export const borderColor = '#E3E7E9';
export const borderColorLight = '#F2F2F2';
export const brandPrimary = '#00528C';
export const brandPrimaryDark = '#003256';
export const brandPrimaryHover = '#046CB6';
export const brandPrimaryLight = '#2EA8FF';
export const brandSecondary = '#00AAE8';
export const brandSecondaryHover = '#0DB7F5';
export const category = '#6F23A4';
export const darkBlue = '#035E9F';
export const destructive = '#D0021B';
export const error = '#B34755';
export const gold = '#F3CE0D';
export const goldDark = '#B89A00';
export const gray = '#D3DBDE';
export const grayDark = '#767676';
export const grayIconFill = '#A7ABAD';
export const green = '#89C947';
export const hotPink = '#C75DA6';
export const kitTintColor = '#F89143';
export const lightBlue = '#5FBBFC';
export const lightOrange = '#FBF4EF';
export const lightYellow = '#FEFBE7';
export const mention = '#E8F3F9';
export const mentionDark = '#CAE0EC';
export const nameCircles = '#D3DBDE';
export const orange = '#F89143';
export const orangeHover = '#EC8C41';
export const pink = '#FFC7CE';
export const tagBackgroundColor = '#D7EBF7';
export const titles = '#0D0F11';
export const white = '#FFFFFF';
export const yellow = '#F3CE0D';
export const yellowDark = '#CDAD07';
export const warningDark = '#F89143';
export const warningLight = '#FFECDD';
export const success = '#89C947';
export const navigation = '#A7ABAD';
