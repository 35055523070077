import { LocalNotificationObserver } from '../LocalNotificationObserver';
import { IAppAutoUpdaterEvent } from '@AppModels/.';
import { Topics } from '@AppModels//LocalNotificationTopics';
import { ILocalNotification } from '@AppModels//LocalNotifications';
import * as React from 'react';

export class AppAutoUpdaterRefreshBlocker extends React.Component {
	public render() {
		return (
			<LocalNotificationObserver
				onNotificationReceived={this.onAppWillRefreshNotificationReceived}
				topic={Topics.APP_REFRESH}
			/>
		);
	}

	private onAppWillRefreshNotificationReceived = (localNotification: ILocalNotification<IAppAutoUpdaterEvent>) => {
		if (localNotification.topic === Topics.APP_REFRESH) {
			localNotification?.info?.preventDefault();
		}
	};
}
