import { navigation } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const HistoryIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;
	return (
		<SvgIcon className={`history-icon ${className || ''}`} height={17} width={20}>
			<path
				fill={fillColor || navigation}
				d='M11.4035088,0.526315789 C7.04385965,0.526315789 3.50877193,4.06140351 3.50877193,8.42105263 L0.877192982,8.42105263 L4.28947368,11.8333333 L4.35087719,11.9561404 L7.89473684,8.42105263 L5.26315789,8.42105263 C5.26315789,5.02631579 8.00877193,2.28070175 11.4035088,2.28070175 C14.7982456,2.28070175 17.5438596,5.02631579 17.5438596,8.42105263 C17.5438596,11.8157895 14.7982456,14.5614035 11.4035088,14.5614035 C9.71052632,14.5614035 8.1754386,13.8684211 7.07017544,12.754386 L5.8245614,14 C7.25438596,15.4298246 9.21929825,16.3157895 11.4035088,16.3157895 C15.7631579,16.3157895 19.2982456,12.7807018 19.2982456,8.42105263 C19.2982456,4.06140351 15.7631579,0.526315789 11.4035088,0.526315789 Z M10.5263158,4.9122807 L10.5263158,9.29824561 L14.2807018,11.5263158 L14.9122807,10.4649123 L11.8421053,8.64035088 L11.8421053,4.9122807 L10.5263158,4.9122807 Z'
			/>
		</SvgIcon>
	);
};
