import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { baseStyleSheet } from '../../../../styles/styles';
import { styleSheet } from './styles';
import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';

interface IResultsProps {
	className?: string;
	highlightIndex: number;
	items: Api.ResourceAutoCompleteViewModel<Api.ResourceAutoCompleteModel>;
	noResultsFooterItem?: JSX.Element;
	onMoreClicked?(): void;
	pageSize: number;
	renderItem(
		value: Api.ResourceAutoCompleteModel,
		index: number,
		array: Api.ResourceAutoCompleteModel[]
	): React.ReactNode;
	search: string;
	shiftedBy: number;
	styles?: StyleDeclarationValue[];
	type: 'contact' | 'company';
}

export const ContactsAutocompleteResults: React.FC<IResultsProps> = observer(props => {
	const {
		className,
		search,
		styles,
		items,
		renderItem,
		onMoreClicked,
		highlightIndex,
		shiftedBy,
		pageSize,
		noResultsFooterItem = null,
	} = props;

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
			{items.isBusy && items.searchResults.length === 0 ? (
				<div>
					<LoadingSpinner />
				</div>
			) : items.totalCount === 0 ? (
				<>
					{noResultsFooterItem ? (
						noResultsFooterItem
					) : (
						<div className={css(styleSheet.andMore)}>
							No results matching
							<span className={css(styleSheet.boldItalic)}>&nbsp;{search}</span>
						</div>
					)}
				</>
			) : (
				items.searchResults?.map(renderItem)
			)}
			{items.totalCount - pageSize > 0 && onMoreClicked && (
				<div
					className={css(
						styleSheet.andMore,
						highlightIndex === items.searchResults.length + shiftedBy && styleSheet.highlight
					)}
				>
					<span>and {items.totalCount - pageSize} more... </span>
					<span className={css(baseStyleSheet.brandLink)} onClick={onMoreClicked}>
						view results
					</span>
				</div>
			)}
		</div>
	);
});
