import { navigation, titles } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	header: {
		color: navigation,
		fontSize: '12px',
		letterSpacing: 0,
	},
	inlineMention: {
		backgroundColor: '#D5E7F1',
		color: titles,
		cursor: 'pointer',
		fontSize: '14px',
		padding: '0 2px',
		textDecoration: 'none',
	},
	recipients: {
		marginTop: -10,
		...CssSelectors.allChildren({
			display: 'inline-block',
			marginTop: 10,
		}),
	},
});
