import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { useErrorMessages, useUserSession } from '../../../../models/hooks/appStateHooks';
import { baseStyleSheet } from '../../../styles/styles';
import { AutomationStepsBy } from '../../automation/AutomationSelector/AutomationStepsBy';
import { AutomationSteps } from '../../automation/AutomationSteps';
import { AutomationStartedGraphic } from '../../svgs/graphics/AutomationStartedGraphic';
import { AutomationsWorkflowGraphic } from '../../svgs/graphics/AutomationsWorkflowGraphic';
import { styleSheet } from './styles';

interface IAutomationConfirmationProps extends IEventLoggingComponentProps {
	contacts?: Api.ContactsViewModel;
	automationTemplate: Api.AutomationTemplateViewModel;
	className?: string;
	onAutomationConfirmed?: (result?: Api.ICreateAutomationRequest, cancel?: boolean) => void;
	styles?: StyleDeclarationValue[];
}

const _AutomationConfirmation = (props: IAutomationConfirmationProps) => {
	const { className, contacts, styles, automationTemplate, logEvent, logApiError } = props;
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const [selectedEmployee, setSelectedEmployee] = React.useState<Api.IUser | null>(null);
	const [selectedFrom, setSelectedFrom] = React.useState<Api.AutomationSelectionType>(
		Api.AutomationSelectionType.Myself
	);
	// @ts-ignore
	const [preview, setPreview] = React.useState<Api.IPreviewCreateAutomationResult | null>(undefined);
	const [isBusy, setIsBusy] = React.useState<boolean>(false);
	// @ts-ignore
	// @ts-ignore
	const isAdmin = userSession.account.isAdmin && userSession.account.features.automation.allowAdminToStartOnBehalf;

	React.useEffect(() => {
		getPreview();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onUserSelected = (selection: Api.IUser) => {
		setSelectedEmployee(selection);
	};

	const onAutomationSelectionComesFrom = (type: Api.AutomationSelectionType) => {
		setSelectedFrom(type);
	};

	const getCreateAutomationRequestForContacts = () => {
		const request: Api.ICreateAutomationRequest = {
			autoStart: true,
			// @ts-ignore
			// @ts-ignore
			excludeContactIds: contacts.excludedContacts.map(c => c.id),
			filter: contacts.selectedContacts.length === 0 ? contacts.filterRequest.filter : undefined,
			// @ts-ignore
			// @ts-ignore
			includeContactIds: contacts.selectedContacts.map(c => c.id),
			putOnHold: false,
		};

		if (isAdmin) {
			request.sendFromOptions = {
				mode:
					selectedFrom === Api.AutomationSelectionType.ContactOwners
						? Api.SendEmailFrom.ContactOwner
						: Api.SendEmailFrom.CurrentUser,
			};
			if (selectedFrom === Api.AutomationSelectionType.Myself) {
				request.sendFromOptions.mode = Api.SendEmailFrom.SelectedUser;
				request.sendFromOptions.selectedUser = userSession.user?.id;
			}
			if (selectedFrom === Api.AutomationSelectionType.Employee) {
				request.sendFromOptions.mode = Api.SendEmailFrom.SelectedUser;
				request.sendFromOptions.selectedUser = `${selectedEmployee?.id}`;
			}
		}
		return request;
	};
	const getPreview = () => {
		const request = getCreateAutomationRequestForContacts();
		const previewPromise = automationTemplate.previewStartForContacts(request);
		if (previewPromise) {
			// @ts-ignore
			logEvent('StartAutomationsWithPreview', { templateId: automationTemplate.id });
			previewPromise
				.then(opResult => {
					// @ts-ignore
					setPreview(opResult.value);
					setIsBusy(false);
				})
				.catch((error: Api.IOperationResultNoValue) => {
					// @ts-ignore
					logApiError('StartAutomationsWithPreview-Error', error);
					// @ts-ignore
					errorMessages.pushApiError(error);
				});
		}
	};

	const getRequest = () => {
		return getCreateAutomationRequestForContacts();
	};

	const onSkipClicked = () => {
		// @ts-ignore
		// @ts-ignore
		props.onAutomationConfirmed(null, true);
	};

	const onStartClicked = () => {
		if (!selectedEmployee && selectedFrom === Api.AutomationSelectionType.Employee) {
			return;
		}
		// @ts-ignore
		props.onAutomationConfirmed(getRequest(), false);
	};
	return (
		<div
			className={`${css(styleSheet.automationConfirmContainer, ...(styles || []))} automation-confirmation ${
				className || ''
			}`}
		>
			<AutomationConfirmationHeader automationTemplate={automationTemplate} />

			{isAdmin ? (
				<article>
					<h4 className={css(baseStyleSheet.truncateText, styleSheet.automationCardSubTitle)}>Automation steps by:</h4>
					<AutomationStepsBy
						// @ts-ignore
						userSession={userSession}
						// @ts-ignore
						preview={preview}
						isBusy={isBusy}
						onAutomationSelectionComesFrom={onAutomationSelectionComesFrom}
						// @ts-ignore
						onSelectedEmployee={onUserSelected}
					/>
				</article>
			) : null}
			<footer className={css(styleSheet.buttonContainer)}>
				<button
					className={css(baseStyleSheet.ctaButton)}
					onClick={onStartClicked}
					disabled={!selectedEmployee && selectedFrom === Api.AutomationSelectionType.Employee}
				>
					<span>Start Automations for Tagged Contacts</span>
				</button>
				<button className={css(baseStyleSheet.ctaButtonReverse)} onClick={onSkipClicked}>
					<span>Skip</span>
				</button>
			</footer>
		</div>
	);
};

const AutomationConfirmationAsObserver = observer(_AutomationConfirmation);
export const AutomationConfirmation = withEventLogging(AutomationConfirmationAsObserver, 'AutomationConfirmation');

export const AutomationConfirmationHeader = ({
	automationTemplate,
}: {
	automationTemplate: Api.AutomationTemplateViewModel<Api.UserSessionContext>;
}) => {
	return (
		<>
			<figure className={css(styleSheet.graphic)}>
				<AutomationsWorkflowGraphic secondaryStyle={true} />
			</figure>
			<h2 className={css(styleSheet.automationTitle)}>Looks like the tag you just added can trigger an automation.</h2>
			<article className={css(styleSheet.automation)}>
				<h3 className={css(styleSheet.automationName)}>{automationTemplate?.name}</h3>
				<div className={css(styleSheet.section)}>
					<div className={css(styleSheet.sectionLabel)}>Steps</div>
					<div className={css(baseStyleSheet.horizontalStack)}>
						<AutomationSteps automationTemplate={automationTemplate} maxStepsToDisplay={8} />
					</div>
				</div>
			</article>
		</>
	);
};

interface IAutomationStartedProps {
	className?: string;
	onAutomationConfirmClosed?: () => void;
	styles?: StyleDeclarationValue[];
}

class _AutomationStartedNotification extends React.Component<IAutomationStartedProps> {
	public render() {
		const { className, styles } = this.props;
		return (
			<div
				className={`${css(styleSheet.startedContainer, ...(styles || []))} automation-started-notification ${
					className || ''
				}`}
			>
				<div className={css(baseStyleSheet.flexCenter)}>
					<AutomationStartedGraphic />
				</div>
				<div className={css(styleSheet.startedTitle)}>Automation Started</div>
				<div className={css(styleSheet.startedMessage)}>
					If the first step of an automation is an email, we will start sending in 30 minutes.
				</div>
				<div className={css(baseStyleSheet.flexCenter)}>
					<button className={css(baseStyleSheet.ctaButton)} onClick={this.onDoneClick}>
						<span>Done</span>
					</button>
				</div>
			</div>
		);
	}

	private onDoneClick = () => {
		// @ts-ignore
		this.props.onAutomationConfirmClosed();
	};
}

const AutomationStartedNotificationAsObserver = observer(_AutomationStartedNotification);
export const AutomationStartedNotification = inject()(AutomationStartedNotificationAsObserver);
