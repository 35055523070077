import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { IModalContext, ModalChildComponentContextKey } from '../../../../models';
import { DeprecatedCloseButton } from '../../DeprecatedCloseButton';
import { ReferralRockWidget } from '../../Happiness/RefferalRockWidget';
import { asModalComponent } from '../../Modal';
import { ReferAFriendGraphic } from '../../svgs/graphics/ReferAFriendGraphic';
import { styleSheet } from './styles';

interface IProps extends IModalContext {
	className?: string;
}

const _ReferAFriend: React.FC<IProps> = ({ className, parentModal }) => {
	const closeButtonClick = () => {
		if (parentModal) {
			parentModal.onRequestClose(null, true);
		}
	};

	return (
		<div className={`${className ?? undefined}`}>
			<header className={css(styleSheet.header)}>
				<menu>
					<li>
						<DeprecatedCloseButton onClick={closeButtonClick} className={css(styleSheet.headerButton)} />
					</li>
				</menu>
			</header>
			<div className={`${css(styleSheet.container)} refer-a-friend`}>
				<p className={css(styleSheet.titleMessage)}>Share the Love of Lev!</p>
				<figure className={`${css(styleSheet.graphic)}`}>
					<ReferAFriendGraphic />
				</figure>
				<div>
					<ReferralRockWidget />
				</div>
			</div>
		</div>
	);
};

const ReferAFriendAsObserver = observer(_ReferAFriend);
const ReferAFriend = inject(ModalChildComponentContextKey)(ReferAFriendAsObserver);
export const ReferAFriendModal = asModalComponent(ReferAFriend, {
	className: css(styleSheet.removePadding),
});
