import { LoginViewModel } from '../../../viewmodels/AppViewModels';
import * as React from 'react';

export interface ILoginContext<TLoginViewModel extends LoginViewModel = LoginViewModel> {
	loginViewModel?: TLoginViewModel;
}

// @ts-ignore
export const LoginContext = React.createContext<ILoginContext>(null);

export const useLoginContext = <TLoginViewModel extends LoginViewModel = LoginViewModel>() => {
	const context = React.useContext(LoginContext) as ILoginContext<TLoginViewModel>;
	if (!context) {
		throw new Error(`Use of this component requires a "LoginContext" provider.`);
	}
	return context;
};

export interface ILoginChildComponentProps<TLoginViewModel extends LoginViewModel = LoginViewModel> {
	loginContext?: ILoginContext<TLoginViewModel>;
}

export function withLoginContext<T = any>(WrappedComponent: React.ComponentType<T>) {
	const sfc: React.FC<T & ILoginChildComponentProps> = props => {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const loginContext = React.useContext(LoginContext);

		// eslint-disable-next-line react-hooks/rules-of-hooks
		const [childProps, setChildProps] = React.useState<ILoginChildComponentProps>(
			loginContext
				? {
						loginContext,
					}
				: {}
		);
		// eslint-disable-next-line react-hooks/rules-of-hooks
		React.useEffect(() => {
			setChildProps({ loginContext });
		}, [loginContext]);

		return <WrappedComponent {...childProps} {...props} />;
	};
	return sfc;
}
