import { success } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const EditItemPenIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;
	return (
		<SvgIcon className={`edit-item-pen-icon ${className || ''}`} height={16} width={16}>
			<path
				d='M13.2637714,1.97615238 C13.8458667,2.55900952 13.8458667,3.57539048 13.2637714,4.15824762 L12.7159619,4.70681905 L10.5323429,2.5239619 L11.0816762,1.97615238 C11.6645333,1.39405714 12.6809143,1.39329524 13.2637714,1.97615238 L13.2637714,1.97615238 Z M1.81005714,13.4291048 L2.26339048,11.6203429 L3.61881905,12.9772952 L1.81005714,13.4291048 Z M9.45577143,3.60205714 L11.6371048,5.78339048 L5.10910476,12.3113905 L2.92777143,10.1300571 L9.45577143,3.60205714 Z M14.339581,0.898057143 C13.7605333,0.318247619 12.9902476,-0.000228571429 12.1704381,-0.000228571429 C11.3521524,-0.000228571429 10.5811048,0.318247619 10.0020571,0.898057143 L1.30948571,9.58986667 C1.30948571,9.58986667 1.30872381,9.59215238 1.3079619,9.59291429 C1.24624762,9.65539048 1.14034286,9.8199619 1.10910476,9.94415238 L0.0226285714,14.290819 C-0.0428952381,14.5498667 0.0340571429,14.8249143 0.223009524,15.0138667 L0.223771429,15.0146286 L0.225295238,15.0153905 C0.414247619,15.2043429 0.688533333,15.2812952 0.948342857,15.2165333 L5.29424762,14.1292952 C5.4184381,14.0980571 5.58377143,13.9921524 5.64548571,13.9304381 C5.64624762,13.9296762 5.64853333,13.9289143 5.64853333,13.9289143 L14.3411048,5.23634286 C14.9201524,4.65729524 15.2393905,3.88700952 15.2386286,3.0679619 C15.2386286,2.24815238 14.9201524,1.47862857 14.3411048,0.898819048 L14.339581,0.898057143 Z'
				fill={fillColor || success}
				fillRule='evenodd'
			/>
		</SvgIcon>
	);
};
