import * as React from 'react';

type FileSelectedCallback = (selection?: readonly [File, string]) => void;

export const useFileInput = () => {
	const fileInputRef = React.useRef<HTMLInputElement>(null);
	const onFinishRef = React.useRef<FileSelectedCallback>(null);

	const onFileInputChanged = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			// @ts-ignore
			const files = Array.from((e.target as HTMLInputElement).files) || [];
			const file = files[0];
			const finish = (result?: readonly [File, string]) => {
				// @ts-ignore
				// @ts-ignore
				fileInputRef.current.value = null;
				onFinishRef.current?.(result);
			};
			if (file) {
				const fileReader = new FileReader();
				fileReader.onloadend = () => {
					const result = [file, fileReader.result as string] as const;
					finish(result);
				};
				// @ts-ignore
				fileReader.onerror = () => finish(null);
				fileReader.readAsDataURL(file);
			} else {
				// @ts-ignore
				finish(null);
			}
		},
		[fileInputRef]
	);

	const click = React.useCallback((): Promise<readonly [File, string]> => {
		if (!fileInputRef.current) {
			// @ts-ignore
			return Promise.resolve<readonly [File, string]>(null);
		}
		return new Promise<readonly [File, string]>(resolve => {
			// @ts-ignore
			// @ts-ignore
			onFinishRef.current = selection => {
				resolve(selection);
				// @ts-ignore
				onFinishRef.current = null;
			};
			// @ts-ignore
			fileInputRef.current.click();
		});
	}, [fileInputRef, onFinishRef]);

	return [fileInputRef, onFileInputChanged, click] as const;
};

export const useInput = (initialInputValue: string) => {
	const [value, setValue] = React.useState(initialInputValue);
	const inputChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.value),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[value, setValue]
	);
	return [value, setValue, inputChange] as const;
};
