import { background, borderColor, navigation } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	actions: {
		display: 'flex',
		gap: 8,
	},
	container: {
		borderTop: `1px solid ${borderColor}`,
		marginTop: 20,
		paddingTop: 20,
		borderBottom: `1px solid ${borderColor}`,
		marginBottom: 20,
		paddingBottom: 20,
		display: 'flex',
		flexDirection: 'column',
		gap: 16,
	},
	label: {
		color: navigation,
		fontSize: 12,
		letterSpacing: 0,
	},
	customField: {
		width: '100%',
		':hover': {
			background,
		},
	},
	customFieldData: {
		display: 'flex',
		justifyContent: 'space-between',
		fontSize: 14,
		width: '100%',
		height: 20,
	},
});
