import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { GifsResult, GiphyFetch } from '@giphy/js-fetch-api';
import { IGif } from '@giphy/js-types';
import { Gif, Grid } from '@giphy/react-components';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Keyframes, animated } from 'react-spring-legacy/renderprops';
import { IModalContext, ModalChildComponentContextKey } from '../../../../models';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../models/AppState';
import { baseStyleSheet } from '../../../styles/styles';
import { LoadingSpinner } from '../../LoadingSpinner';
import { asModalComponent } from '../../Modal';
import { TextInput } from '../../TextInput';
import PoweredByGiphyImageUrl from './poweredByGiphy.png';
import { ContentWidth, GifsContainerHeight, styleSheet } from './styles';

interface IProps extends IEventLoggingComponentProps, IUserSessionComponentProps, IModalContext<IGif> {
	className?: string;
	styles?: StyleDeclarationValue[];
}

interface IState {
	isLoading?: boolean;
	pendingSearchTerm?: string;
	searchTerm?: string;
	selectedGif?: IGif;
	showNoResults?: boolean;
}

export const GifsContainer: React.ReactNode = Keyframes.Spring(async (next: any) => {
	await next({
		config: { friction: 30, tension: 400 },
		from: { height: 0, opacity: 0 },
		height: GifsContainerHeight,
		opacity: 0,
	});
	await next({
		config: { friction: 50, tension: 200 },
		from: { opacity: 0 },
		opacity: 1,
	});
});

class _GiphyChooser extends React.Component<IProps, IState> {
	public static ApiKey = 'M0Ub4yJe6gg9voTStfiVQp5PXwehWbcK';
	public readonly state: IState = {};
	private mGiphyFetch: GiphyFetch;
	constructor(props: IProps) {
		super(props);
		this.mGiphyFetch = new GiphyFetch(_GiphyChooser.ApiKey);
	}

	public render() {
		const { className, styles } = this.props;
		const { selectedGif } = this.state;
		return (
			<div className={`${css(styleSheet.container, ...(styles || []))} giphy-chooser ${className || ''}`}>
				<div className={css(styleSheet.header)}>
					<div className={css(baseStyleSheet.horizontalStack)}>
						<svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'>
							<g fill='none' fillRule='evenodd'>
								<rect width='39.859' height='40' fill='#121212' rx='5.189' />
								<g transform='translate(10.81 7.568)'>
									<rect width='2.678' height='13.383' fill='#93F' transform='translate(16.07 8.03)' />
									<rect width='18.749' height='2.677' fill='#0CF' transform='translate(0 21.412)' />
									<rect width='2.678' height='18.736' fill='#0F9' transform='translate(0 2.677)' />
									<rect width='10.714' height='2.677' fill='#FFF35C' />
									<polygon
										fill='#F66'
										points='5.357 5.353 5.357 2.677 2.678 2.677 2.678 0 0 0 0 2.69 0 2.703 0 5.366 0 5.366 0 8.03 2.678 8.03 5.357 8.03 5.357 8.03 8.035 8.03 8.035 5.353'
										transform='translate(10.714)'
									/>
									<polygon
										fill='#0F0F0F'
										points='2.678 0 2.678 2.677 0 2.677'
										opacity='.4'
										transform='translate(8.035)'
									/>
									<polygon fill='#0F0F0F' points='0 2.703 0 0 2.678 0' opacity='.4' transform='translate(16.07 8.03)' />
								</g>
							</g>
						</svg>
						<span>GIPHY</span>
					</div>
					<img className={css(styleSheet.headerPoweredByImage)} src={PoweredByGiphyImageUrl} />
				</div>
				<div>{this.renderSearch()}</div>
				<div className={css(baseStyleSheet.horizontalStack, styleSheet.footer)}>
					<button className={css(baseStyleSheet.ctaButton)} onClick={this.onCtaClicked(false)}>
						<span>{selectedGif ? 'Insert' : 'Search'}</span>
					</button>
					<button className={css(baseStyleSheet.ctaButtonReverse)} onClick={this.onCancelClicked}>
						<span>Cancel</span>
					</button>
				</div>
			</div>
		);
	}

	private renderSearch() {
		const { searchTerm, pendingSearchTerm, selectedGif, isLoading, showNoResults } = this.state;
		return (
			<div className={css(baseStyleSheet.verticalStack, styleSheet.search)}>
				<div>Search for a Giphy</div>
				<TextInput
					inputId='giphy-search-input'
					onChange={this.onSearchTermInputChanged}
					onKeyDown={this.onInputKeyDown}
					placeholder='Search for a Giphy'
					type='text'
					autoComplete='off'
					value={pendingSearchTerm || ''}
				/>
				{(!!searchTerm || selectedGif) && (
					// @ts-ignore
					<GifsContainer>
						{(style: React.CSSProperties) => {
							return (
								<animated.div className={css(styleSheet.gifs)} style={style}>
									{selectedGif ? (
										<Gif gif={selectedGif} noLink={true} width={ContentWidth} />
									) : (
										<div className={css(styleSheet.gifGrid)}>
											<Grid
												columns={3}
												fetchGifs={this.onFetchGifs}
												key={searchTerm}
												noLink={true}
												onGifClick={this.onGifClicked}
												useTransform={true}
												width={ContentWidth}
											/>
										</div>
									)}
									{!!showNoResults && (
										<div className={css(styleSheet.overlay, styleSheet.noResultsOverlay)}>No results</div>
									)}
								</animated.div>
							);
						}}
					</GifsContainer>
				)}
				{!!isLoading && <LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} type='large' />}
			</div>
		);
	}

	private onInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.keyCode === 13) {
			this.onCtaClicked(true)();
		}
	};

	private onCancelClicked = () => {
		// @ts-ignore
		this.props.parentModal?.onRequestClose(null, true);
	};

	private onCtaClicked =
		(returnToSearch = false) =>
		() => {
			const { selectedGif, pendingSearchTerm } = this.state;
			const { parentModal } = this.props;
			if (!!selectedGif && !returnToSearch) {
				parentModal?.onRequestClose(selectedGif, false);
			} else {
				this.setState({
					searchTerm: pendingSearchTerm,
					// @ts-ignore
					selectedGif: null,
				});
			}
		};

	private onGifClicked = (gif: IGif, e: React.SyntheticEvent<HTMLElement, Event>) => {
		e.preventDefault();
		this.setState({
			selectedGif: gif,
		});
	};

	private onSearchTermInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			pendingSearchTerm: e.target.value,
		});
	};

	private onFetchGifs = (offset: number) => {
		const { searchTerm } = this.state;
		const promise = this.mGiphyFetch.search(searchTerm || '', {
			limit: 10,
			offset,
		});
		if (offset === 0) {
			this.setState(
				{
					isLoading: true,
					showNoResults: false,
				},
				() => {
					const finished = (resultOrError?: GifsResult) => {
						this.setState({
							isLoading: false,
							showNoResults: offset === 0 && (!resultOrError?.data || resultOrError?.data?.length === 0),
						});
					};
					promise.then(finished).catch(finished);
				}
			);
		}
		return promise;
	};
}

const GiphyChooserAsObserver = observer(_GiphyChooser);
const GiphyChooserWithContext = inject(UserSessionViewModelKey, ModalChildComponentContextKey)(GiphyChooserAsObserver);
export const GiphyChooser = withEventLogging(GiphyChooserWithContext, 'GiphyChooser');
export const GiphyChooserModal = asModalComponent(GiphyChooser, {
	className: `giphy-chooser-modal`,
	shouldCloseOnOverlayClick: false,
	useDefaultHeader: true,
});
