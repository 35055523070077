import { brandPrimaryHover, brandSecondary, titles } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	automation: {
		border: '1px solid #F2F2F2',
		borderRadius: 3,
		boxShadow: '0px 0px 6px #E8F3F9',
		margin: '30px 0',
		padding: 20,
	},
	automationCardSubTitle: {
		color: titles,
	},
	automationConfirmContainer: {
		padding: '0 30px',
	},
	automationName: {
		color: brandPrimaryHover,
		fontSize: 18,
		margin: 0,
	},
	automationTitle: {
		color: titles,
		fontSize: 20,
		lineHeight: '30px',
		margin: 0,
		textAlign: 'center',
	},
	buttonContainer: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-evenly',
	},
	container: {
		position: 'relative',
		width: 460,
	},
	graphic: {
		display: 'flex',
		justifyContent: 'center',
	},
	header: {
		textAlign: 'right',
	},
	searchField: {
		marginBottom: 20,
		width: '100%',
	},
	section: {
		':not(:last-child)': {
			marginBottom: 20,
		},
	},
	sectionLabel: {
		color: '#AAAAAA',
		fontSize: 11,
		letterSpacing: 1.1,
		marginBottom: 7,
		marginTop: 10,
		textTransform: 'uppercase',
	},
	startedContainer: {
		color: titles,
		textAlign: 'center',
	},
	startedMessage: {
		fontSize: 14,
		margin: '20px 30px',
	},
	startedTitle: {
		fontSize: 24,
		margin: '20px 30px',
	},
	stepsOverflowLabel: {
		color: '#AAAAAA',
		fontSize: 11,
	},
	submitButton: {},
	taggingMessage: {
		alignItems: 'center',
		display: 'flex',
		...CssSelectors.allDescendants(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	title: {
		color: brandSecondary,
		fontSize: 18,
		lineHeight: '22px',
		marginBottom: 16,
	},
});
