import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
	disabled?: boolean;
}

export const DownloadIcon: React.FC<IProps> = props => {
	const { className, fillColor, disabled } = props;

	return (
		<SvgIcon
			className={`download-icon ${className || ''}`}
			height={22}
			width={20}
			style={{ opacity: disabled ? 0.5 : 1 }}
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10.0002 19.586L3.41419 13H5.00019C5.55219 13 6.00019 12.552 6.00019 12V8H11.0002C11.5522 8 12.0002 7.552 12.0002 7V2H14.0002V12C14.0002 12.552 14.4482 13 15.0002 13H16.5862L10.0002 19.586ZM10.0002 3.414V6H7.41419L10.0002 3.414ZM19.9242 11.617C19.7692 11.244 19.4042 11 19.0002 11H16.0002V1C16.0002 0.448 15.5522 0 15.0002 0H11.0002C10.7352 0 10.4812 0.106 10.2932 0.293L4.29319 6.293C4.10619 6.481 4.00019 6.735 4.00019 7V11H1.00019C0.596189 11 0.231189 11.244 0.0761893 11.617C-0.0788107 11.991 0.00718924 12.421 0.293189 12.707L9.29319 21.707C9.48819 21.902 9.74419 22 10.0002 22C10.2562 22 10.5122 21.902 10.7072 21.707L19.7072 12.707C19.9932 12.421 20.0792 11.991 19.9242 11.617Z'
				fill={fillColor || '#046CB6'}
			/>
		</SvgIcon>
	);
};
