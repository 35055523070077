import { useEffect, useRef } from 'react';

export const useImageLoader = (sourceUrls: string[], onComplete?: () => void) => {
	const completeRef = useRef(onComplete);
	completeRef.current = onComplete;
	useEffect(() => {
		let cancel = false;
		let timeOutHandle = setTimeout(() => {
			cancel = true;
		}, 20000);
		if (sourceUrls?.length === 0) {
			clearTimeout(timeOutHandle);
			// @ts-ignore
			timeOutHandle = null;
			completeRef.current?.();
		} else {
			const completed = Array.from<string>({ length: sourceUrls?.length || 0 });
			sourceUrls?.forEach((url, index) => {
				const image = new Image();
				const done = () => {
					if (!cancel) {
						completed[index] = url;
						if (completed.every(x => x !== null)) {
							completeRef.current?.();
						}
					}
				};
				image.onload = done;
				image.onerror = done;
				image.src = url;
			});
		}

		return () => {
			cancel = true;
			clearTimeout(timeOutHandle);
		};
	}, [sourceUrls]);
};
