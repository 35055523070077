import { SvgIcon } from '../../icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const ForgotPasswordGraphic: React.FC<IProps> = props => {
	const { className } = props;
	return (
		<SvgIcon className={`forgot-password-graphic ${className || ''}`} height={114} width={120}>
			<g fill='white'>
				<path d='M103.5 112.5H.5v1h103v-1z' stroke='#00AAE8' />
				<path d='M30 102h-9a5 5 0 000 10h9a5 5 0 000-10z' fill='#CAE0EC' />
				<path d='M30 103h-9a4 4 0 000 8h9a4 4 0 000-8z' stroke='#00AAE8' strokeWidth='2' />
				<path d='M83 102h-9a5 5 0 000 10h9a5 5 0 000-10z' fill='#CAE0EC' />
				<path d='M83 103h-9a4 4 0 000 8h9a4 4 0 000-8z' stroke='#00AAE8' strokeWidth='2' />
				<path d='M91 21H13a8 8 0 00-8 8v70a8 8 0 008 8h78a8 8 0 008-8V29a8 8 0 00-8-8z' fill='#E8F3F9' />
				<path
					d='M90 22H14a8 8 0 00-8 8v68a8 8 0 008 8h76a8 8 0 008-8V30a8 8 0 00-8-8z'
					stroke='#00AAE8'
					strokeWidth='2'
				/>
				<path d='M83 32H21a6 6 0 00-6 6v52a6 6 0 006 6h62a6 6 0 006-6V38a6 6 0 00-6-6z' fill='#E8F3F9' />
				<path
					d='M82 33H22a6 6 0 00-6 6v50a6 6 0 006 6h60a6 6 0 006-6V39a6 6 0 00-6-6z'
					stroke='#00AAE8'
					strokeWidth='2'
				/>
				<path d='M19 47a3 3 0 10-6 0v6a3 3 0 106 0v-6zm0 29a3 3 0 10-6 0v6a3 3 0 106 0v-6z' fill='#00AAE8' />
				<path
					d='M52 84c11.046 0 20-8.954 20-20s-8.954-20-20-20-20 8.954-20 20 8.954 20 20 20z'
					fill='#E8F3F9'
					stroke='#00AAE8'
					strokeWidth='2'
				/>
				<path d='M52 78c7.732 0 14-6.268 14-14s-6.268-14-14-14-14 6.268-14 14 6.268 14 14 14z' fill='#fff' />
				<path d='M52 65a4 4 0 100-8 4 4 0 000 8z' fill='#00AAE8' />
				<path d='M53 64h-2v8h2v-8z' fill='#00AAE8' />
				<path
					d='M98 43c11.598 0 21-9.402 21-21S109.598 1 98 1s-21 9.402-21 21 9.402 21 21 21z'
					fill='#fff'
					stroke='#00AAE8'
					strokeWidth='2'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M91 17.756C91.068 14.352 93.447 12 97.644 12c3.911 0 6.495 2.174 6.495 5.332 0 2.05-.998 3.486-2.926 4.621-1.819 1.053-2.324 1.723-2.324 3.035v.725h-3.623l-.028-.752c-.177-2.188.575-3.418 2.461-4.525 1.764-1.053 2.297-1.71 2.297-2.98 0-1.272-1.039-2.175-2.598-2.175-1.572 0-2.61.971-2.693 2.475H91zm6.22 14.56c-1.53 0-2.488-.888-2.488-2.324 0-1.449.957-2.338 2.489-2.338 1.531 0 2.474.889 2.474 2.338 0 1.436-.943 2.324-2.474 2.324z'
					fill='#00AAE8'
				/>
			</g>
		</SvgIcon>
	);
};
