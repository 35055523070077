import { SvgIcon } from '../../icons/SvgIcon';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	secondaryStyle?: boolean;
	styles?: StyleDeclarationValue[];
}

export const AutomationStartedGraphic: React.FC<IProps> = props => {
	const { className, styles } = props;
	return (
		<SvgIcon
			className={`automations-workflow-graphic ${className || ''} ${css(...(styles || []))}`}
			height={145}
			width={230}
		>
			<path
				d='M45.606 94.657l-1.278-.794c-.122-.076-.242-.157-.364-.234A12.167 12.167 0 0054.05 82.268a12.167 12.167 0 00-8.856-12.345l.063 5.013-2.754-5.454h-.034c-6.71-.311-12.403 4.875-12.718 11.587-.012.233 0 .461 0 .69-.957-1.093-1.829-2.266-2.404-3.55-1.164-2.6-1.187-5.546-1.095-9.127.183-7.094.49-14.245.888-21.372a12.164 12.164 0 0010.803-11.513c.016-.372.01-.738 0-1.104 0-.092 0-.181-.014-.273a11.896 11.896 0 00-.112-1.048c0-.057-.02-.112-.03-.167a12.116 12.116 0 00-.26-1.185c-.086-.311-.18-.61-.29-.919a5.605 5.605 0 00-.117-.297 10.908 10.908 0 00-.278-.656c-.053-.114-.104-.228-.159-.34-.016-.028-.026-.059-.043-.087a12.16 12.16 0 00-14.282-6.171l.081 6.525-2.723-5.391a12.11 12.11 0 00-5.802 13.113h-.024c.018.087.045.17.065.256a12.673 12.673 0 00.313 1.087c.05.15.1.3.156.448.084.215.177.425.27.634.056.122.104.248.163.368.155.314.323.621.504.92.063.106.134.204.204.308.13.203.26.406.406.595.087.12.18.236.272.352.135.169.27.335.407.496a10.918 10.918 0 00.768.797c.106.1.203.203.319.296.179.161.366.311.555.46.095.075.189.152.286.225.289.204.586.407.893.61.056.035.12.063.176.098.258.15.519.294.787.425.115.056.233.107.351.16.22.098.441.193.669.28l.406.143c.224.075.451.146.681.203.134.037.27.076.407.108.247.059.502.108.758.152l.048.01a772.44 772.44 0 00-.447 9.024 12.16 12.16 0 00-16.881-5.285l2.15 5.895-4.424-4.249A12.128 12.128 0 00.213 61.42a11.895 11.895 0 00.31 3.352 12.16 12.16 0 0022.366 3.313c0 .305-.02.61-.029.916-.081 3.16-.183 7.09 1.392 10.601 1.067 2.378 2.782 4.311 4.471 6.043a75.356 75.356 0 003.42 3.273 12.213 12.213 0 004.394 3.659 74.573 74.573 0 005.985 4.169l1.288.8c1.158.718 2.345 1.456 3.508 2.223-.204-.02-.407-.043-.61-.053a12.17 12.17 0 00-11.978 7.927l7.6 6.186-8.36-1.939a12.169 12.169 0 0017.454 10.944l-.564.701c-.374.465-.743.927-1.108 1.386-4.946 6.267-6.322 12.215-3.873 16.75l2.979-1.608c-2.384-4.415 1.201-10.066 3.55-13.042.358-.451.721-.905 1.09-1.362 3.16-3.927 6.74-8.379 6.653-13.838-.138-8.231-8.117-13.183-14.545-17.163z'
				fill='#F2F2F2'
			/>
			<path
				d='M118.944 144.506c57.689 0 104.455-2.503 104.455-5.59 0-3.088-46.766-5.59-104.455-5.59s-104.454 2.502-104.454 5.59c0 3.087 46.765 5.59 104.454 5.59z'
				fill='#3F3D56'
			/>
			<path
				opacity='.2'
				d='M118.741 141.66c51.628 0 93.481-2.002 93.481-4.472 0-2.47-41.853-4.472-93.481-4.472-51.628 0-93.48 2.002-93.48 4.472 0 2.47 41.852 4.472 93.48 4.472z'
				fill='#000'
			/>
			<path
				d='M207.749 28.582V136.02c0 .81-.656 1.466-1.465 1.466H31.599a1.463 1.463 0 01-1.464-1.466V28.582a1.464 1.464 0 011.464-1.465h174.679a1.466 1.466 0 011.471 1.465z'
				fill='#E8F3F9'
			/>
			<path
				d='M207.749 28.094v3.291H30.135v-3.29c0-.54.438-.978.978-.978H206.77c.54 0 .978.437.978.977z'
				fill='#CBCEDA'
			/>
			<path
				d='M33.387 30.166a1.016 1.016 0 100-2.033 1.016 1.016 0 000 2.033zm2.845 0a1.016 1.016 0 100-2.033 1.016 1.016 0 000 2.033zm2.845 0a1.016 1.016 0 100-2.033 1.016 1.016 0 000 2.033z'
				fill='#00AAE8'
			/>
			<path opacity='.2' fill='#046CB6' d='M91.916 42.057h54.056v2.846H91.916z' />
			<path
				fill='#fff'
				d='M47.005 70.921h41.66v48.785h-41.66zm51.008 0h41.66v48.785h-41.66zm92.668 48.786h-41.66V70.922h41.66z'
			/>
			<path
				d='M65.971 95.716c-.124.734-.85 1.196-1.534 1.482-.685.287-1.451.525-1.89 1.125-.592.813-.344 1.977.203 2.821.62.967 1.559 1.681 2.477 2.372.398.337.852.602 1.341.783.373.095.758.136 1.142.122a20.877 20.877 0 003.693-.332c1.563-.284 3.233-.853 4.064-2.211.675-1.112.632-2.5.567-3.802-.735-.063-1.394-.463-2.02-.851-.74-.46-1.538-.994-1.778-1.83-.252-.876.15-1.957-.449-2.642-.406-.464-1.095-.519-1.709-.535-.833-.021-1.666-.033-2.498-.034-.573 0-1.853-.24-2.324.142-.868.693.871 2.46.715 3.39z'
				fill='#A0616A'
			/>
			<path
				opacity='.1'
				d='M65.971 95.716c-.124.734-.85 1.196-1.534 1.482-.685.287-1.451.525-1.89 1.125-.592.813-.344 1.977.203 2.821.62.967 1.559 1.681 2.477 2.372.398.337.852.602 1.341.783.373.095.758.136 1.142.122a20.877 20.877 0 003.693-.332c1.563-.284 3.233-.853 4.064-2.211.675-1.112.632-2.5.567-3.802-.735-.063-1.394-.463-2.02-.851-.74-.46-1.538-.994-1.778-1.83-.252-.876.15-1.957-.449-2.642-.406-.464-1.095-.519-1.709-.535-.833-.021-1.666-.033-2.498-.034-.573 0-1.853-.24-2.324.142-.868.693.871 2.46.715 3.39z'
				fill='#000'
			/>
			<path
				d='M68.81 95.298c3.292 0 5.96-2.67 5.96-5.962a5.961 5.961 0 10-11.92 0 5.961 5.961 0 005.96 5.962z'
				fill='#A0616A'
			/>
			<path
				d='M81.265 103.805a2.568 2.568 0 01-.335 1.016 1.772 1.772 0 01-.183.236c-.335.368-.813.609-1.246.868-1.219.742-2.587 1.707-2.814 3.12a10.74 10.74 0 01-.307 1.301c-.285.967-.656 1.919-.874 2.886a6.562 6.562 0 00-.18 1.714c.006.18.022.36.048.538.193 1.371.896 2.6 1.723 3.769l.27.376.06.079H57.97c.033-.124.065-.246.096-.37a25.105 25.105 0 00.494-2.394c.11-.697.17-1.401.18-2.106 0-.61.135-1.285.645-1.626l.1-.374c.45-1.846.44-3.775-.03-5.617-.028-.113-.06-.227-.09-.341l-.045-.152a16.657 16.657 0 01-.431-1.649c-.12-.665-.147-1.372-.5-1.945-.354-.574-.97-.909-1.516-1.297a3.482 3.482 0 01-.717-.653 1.598 1.598 0 01-.387-.935 1.384 1.384 0 01.023-.305c.1-.419.315-.802.62-1.107 1.235-1.36 3.123-1.931 4.979-1.956.286 0 .57 0 .853.025.33.005.656.078.957.215.186.096.349.23.478.394.181.23.32.49.406.769.073.224.128.454.163.687.114.725.138 1.498.52 2.124.693 1.134 2.272 1.341 3.577 1.096a8.07 8.07 0 005.533-4.066c.122-.253.257-.5.407-.738a1.84 1.84 0 01.276-.327 11.34 11.34 0 014.952 2.236c.454.342.85.754 1.175 1.22.61.963.748 2.166.577 3.289z'
				fill='#F2F2F2'
			/>
			<path
				d='M81.875 116.08a5.727 5.727 0 01-.746 1.598c-.217.417-.51.79-.862 1.102-.72.567-1.719.555-2.641.502h-.074l-.457-.027-2.623-.156a14.716 14.716 0 01-2.073-.222 7.976 7.976 0 01-.545-.13c-1.016-.272-2.01-.679-3.06-.738-.305-.018-.61 0-.92 0h-.086c-.204 0-.408-.013-.61-.04-.333-.049-.66-.155-1-.171-.87-.045-1.638.478-2.38.953l-.203.126a9.185 9.185 0 01-1.682.838h-3.524a1.237 1.237 0 01-.323-.37l-.029-.055a1.853 1.853 0 010-1.567c.132-.282.305-.543.514-.773.231-.25.48-.484.744-.699 1.37-1.142 2.945-2.106 4.697-2.439.122-.023.246-.043.367-.059.877-.101 1.76-.13 2.642-.088l.828.017 3.464.071h.096c1.392.026 2.855.036 4.113-.519.16-.071.316-.151.467-.239a.882.882 0 00.324-.281.97.97 0 00.095-.528l-.012-1.83-.025-3.527c0-.866-.005-1.731-.016-2.595a1.783 1.783 0 01.498-1.478c.506-.636 1.74-2.883 2.614-2.92a.703.703 0 01.436.123c.744.496.748 2.376.767 3.01a71.322 71.322 0 001.107 10.637c.15.811.313 1.661.118 2.474z'
				fill='#A0616A'
			/>
			<path
				opacity='.1'
				d='M81.875 116.08a5.727 5.727 0 01-.746 1.598c-.217.417-.51.79-.862 1.102-.72.567-1.719.555-2.641.502h-.074l-.457-.027-2.623-.156a14.716 14.716 0 01-2.073-.222 7.976 7.976 0 01-.545-.13c-1.016-.272-2.01-.679-3.06-.738-.305-.018-.61 0-.92 0h-.086c-.204 0-.408-.013-.61-.04-.333-.049-.66-.155-1-.171-.87-.045-1.638.478-2.38.953l-.203.126a9.185 9.185 0 01-1.682.838h-3.524a1.237 1.237 0 01-.323-.37l-.029-.055a1.853 1.853 0 010-1.567c.132-.282.305-.543.514-.773.231-.25.48-.484.744-.699 1.37-1.142 2.945-2.106 4.697-2.439.122-.023.246-.043.367-.059.877-.101 1.76-.13 2.642-.088l.828.017 3.464.071h.096c1.392.026 2.855.036 4.113-.519.16-.071.316-.151.467-.239a.882.882 0 00.324-.281.97.97 0 00.095-.528l-.012-1.83-.025-3.527c0-.866-.005-1.731-.016-2.595a1.783 1.783 0 01.498-1.478c.506-.636 1.74-2.883 2.614-2.92a.703.703 0 01.436.123c.744.496.748 2.376.767 3.01a71.322 71.322 0 001.107 10.637c.15.811.313 1.661.118 2.474z'
				fill='#000'
			/>
			<path
				d='M64.884 79.882c-.516.077-1.087.215-1.368.654-.22.342-.203.775-.278 1.173a2.602 2.602 0 01-1 1.6c-.427.315-.943.492-1.384.787-.441.294-.831.776-.772 1.304.05.462.418.814.591 1.249.32.792-.057 1.677-.406 2.457-.35.78-.679 1.7-.279 2.454.425.794 1.52 1.095 1.83 1.939.383 1.016-.61 2.1-.482 3.183.11.89.987 1.527 1.875 1.657.425.06.897.018 1.22-.264.323-.283.424-.745.439-1.173.054-1.655-.886-3.173-1.16-4.808-.09-.538-.102-1.116.148-1.601.158-.307.406-.56.544-.879.275-.664-.036-1.467.258-2.124.167-.287.36-.56.575-.813.19-.274.315-.638.167-.937.525.334.945.81 1.21 1.374.383-.077.373-.902.75-.785.105.035.166.147.235.234.282.315.725.432 1.126.297.39-.14.734-.387.991-.713l.643.796c.119-.281.4-.46.705-.447.298.02.585.12.833.287 1.22.73 2.134 2.224 1.666 3.565-.203.573-.61 1.043-.882 1.588-.272.544-.341 1.276.094 1.699.09.09.203.159.28.256.32.376.118.95.214 1.423.168.872 1.197 1.22 2.062 1.423.61.138 1.336.242 1.762-.203.468-.488.258-1.287-.024-1.9-.283-.615-.61-1.324-.307-1.926.303-.602 1.19-.813 1.51-1.441.406-.799-.311-1.675-.598-2.525-.392-1.162.045-2.467-.276-3.659a5.106 5.106 0 00-.898-1.65c-.95-1.277-2.087-2.45-3.498-3.19-1.333-.699-2.676-.813-4.145-.636-1.32.167-2.648.078-3.971.275z'
				fill='#3C354C'
			/>
			<path
				d='M76.467 106.898a.492.492 0 01-.233-.323 90.874 90.874 0 01-1.268-3.832 1.487 1.487 0 01-.096-.713c.092-.302.284-.564.545-.742a8.127 8.127 0 012.225-1.305c.585-.23 1.449-.638 2.032-.266.724.461-.049 1.626-.313 2.236a23.17 23.17 0 01-1.22 2.439c-.137.244-1.487 2.579-1.672 2.506z'
				fill='#F2F2F2'
			/>
			<path
				d='M77.628 119.282a1.227 1.227 0 01-.264.345.777.777 0 01-.092.079h-3.534a9.117 9.117 0 01-1.683-.837l-.203-.126c-.742-.476-1.51-.998-2.38-.953-.339.016-.666.122-1 .17a4.605 4.605 0 01-.609.041h-.085c-.307 0-.61-.014-.92 0-1.052.059-2.043.465-3.061.738a8.624 8.624 0 01-.545.13c-.685.122-1.378.197-2.073.221l-3.08.183h-.074c-.92.053-1.918.065-2.642-.502a3.849 3.849 0 01-.861-1.101 5.727 5.727 0 01-.746-1.598c-.203-.813-.033-1.663.116-2.486a71.616 71.616 0 001.103-10.639c.019-.637.023-2.523.77-3.013a.648.648 0 01.283-.11c.05-.005.1-.005.15 0 .874.037 2.108 2.283 2.614 2.919.384.39.567.935.498 1.478 0 .835-.006 1.671-.017 2.509v.113c-.01 1.781-.02 3.561-.034 5.34a.972.972 0 00.095.529.585.585 0 00.106.128c.067.058.14.109.217.152 1.352.797 3.012.789 4.58.759h.096l3.465-.072.827-.016a16.305 16.305 0 012.642.088 6.7 6.7 0 01.368.059 9.551 9.551 0 013.719 1.679c.339.239.668.493.988.76.5.406.991.878 1.258 1.471.232.494.235 1.065.008 1.562z'
				fill='#A0616A'
			/>
			<path
				d='M59.407 106.898a.496.496 0 00.234-.323 91.163 91.163 0 001.268-3.832c.088-.226.12-.471.093-.713a1.387 1.387 0 00-.544-.742 8.077 8.077 0 00-2.224-1.305c-.585-.23-1.446-.638-2.032-.266-.723.461.047 1.626.313 2.236.358.836.765 1.651 1.22 2.439.138.244 1.487 2.579 1.672 2.506z'
				fill='#F2F2F2'
			/>
			<path
				d='M169.359 83.825c1.209-.295 2.148 1.708 3.365 1.47.669-.13 1.185-.923 1.862-.864.77.067 1.099 1.12 1.493 1.89.685 1.334 2.069 2.14 3.402 1.98.419-.553.339-1.402.126-2.094-.213-.69-.538-1.361-.547-2.097-.01-.974.525-2.051.041-2.846-.372-.61-1.132-.659-1.691-1.035-.437-.292-.743-.782-1.109-1.183a3.669 3.669 0 00-2.262-1.189c-.719-.081-1.443.073-2.154.228l-3.325.72a.924.924 0 00-.518.237 1.786 1.786 0 00-.268.681c-.315 1.033-1.303 1.517-2.11 2.09-.807.573-1.573 1.707-1.128 2.673.252.547.846.884.909 1.498.055.549-.342.998-.665 1.404-1.371 1.72 1.703 2.615 2.439 1.448.819-1.285.41-4.588 2.14-5.01z'
				fill='#3C354C'
			/>
			<path
				d='M167.601 94.641c-.289.376-.665.69-.896 1.104a2.537 2.537 0 00-.134 1.905c.208.62.5 1.207.867 1.748a10.21 10.21 0 001.524 2.033 3.708 3.708 0 002.236 1.109c.904.067 1.778-.307 2.597-.699.35-.139.668-.346.937-.61.196-.249.349-.528.455-.827l.705-1.69c.198-.41.335-.846.407-1.296.073-.701-.171-1.395-.407-2.06l-1.29-3.55a1.205 1.205 0 00-.309-.539 1.165 1.165 0 00-.476-.203 13.8 13.8 0 00-2.493-.407c-.626-.043-2.047-.264-2.593.09-.502.327-.293 1.16-.352 1.699a4.302 4.302 0 01-.778 2.193z'
				fill='#A0616A'
			/>
			<path
				opacity='.1'
				d='M167.601 94.641c-.289.376-.665.69-.896 1.104a2.537 2.537 0 00-.134 1.905c.208.62.5 1.207.867 1.748a10.21 10.21 0 001.524 2.033 3.708 3.708 0 002.236 1.109c.904.067 1.778-.307 2.597-.699.35-.139.668-.346.937-.61.196-.249.349-.528.455-.827l.705-1.69c.198-.41.335-.846.407-1.296.073-.701-.171-1.395-.407-2.06l-1.29-3.55a1.205 1.205 0 00-.309-.539 1.165 1.165 0 00-.476-.203 13.8 13.8 0 00-2.493-.407c-.626-.043-2.047-.264-2.593.09-.502.327-.293 1.16-.352 1.699a4.302 4.302 0 01-.778 2.193z'
				fill='#000'
			/>
			<path
				d='M171.824 93.806a5.63 5.63 0 005.629-5.631 5.63 5.63 0 10-11.258 0 5.63 5.63 0 005.629 5.63z'
				fill='#A0616A'
			/>
			<path
				d='M184.297 102.235c.013 1.553-.583 3.049-1.199 4.472-.615 1.423-1.398 2.828-1.999 4.289a97.492 97.492 0 00-2.89 8.507l-.059.203h-17.456c.796-3.049 1.479-6.098-.431-8.202a4.408 4.408 0 01-.175-.203c-1.25-1.506-1.811-3.561-3.296-4.842-.342-.294-.738-.555-.949-.955a1.728 1.728 0 01-.179-.793 5.287 5.287 0 01.045-.723c.146-1.322.335-2.708 1.132-3.773a5.55 5.55 0 011.29-1.2 10.297 10.297 0 013.599-1.607c1.455-.334 3.06-.383 4.247-1.291.25-.23.522-.435.813-.61l.081-.035a.845.845 0 01.876.094c.154.171.256.383.293.61l.648 2.366c.074.39.242.756.49 1.065.575.598 1.674.527 2.122 1.22.268.42.973-.069 1.28-.46a17.198 17.198 0 001.92-3.037c.18-.426.419-.825.712-1.185.209-.231.482-.396.784-.475.148-.037.302-.041.451-.013.439.09.76.452 1.059.785a23.768 23.768 0 006.247 4.97c.186.079.345.21.457.378a.773.773 0 01.077.327c.008.043.01.082.01.118zm-14.331-18.696c.941-.204 1.671 1.148 2.618.987.52-.085.923-.61 1.447-.579.599.045.855.752 1.162 1.269a2.81 2.81 0 002.642 1.331c.325-.372.264-.941.098-1.407-.167-.465-.421-.916-.427-1.408 0-.657.406-1.38.032-1.915-.288-.407-.88-.443-1.314-.695-.34-.204-.578-.527-.864-.795a3.004 3.004 0 00-1.758-.799 5.975 5.975 0 00-1.674.152l-2.585.484a.765.765 0 00-.407.159 1.12 1.12 0 00-.203.457c-.246.695-1.016 1.017-1.64 1.405-.624.388-1.219 1.148-.878 1.797.203.368.656.593.705 1.016.045.368-.264.67-.516.943-1.065 1.155 1.325 1.757 1.9.972.636-.87.317-3.09 1.662-3.374z'
				fill='#3C354C'
			/>
			<path
				d='M186.939 119.707h-4.753c.077-1.948-.715-3.926-.813-5.912-.036-.746.027-1.504-.124-2.236a4.826 4.826 0 00-.152-.563c-.12-.376-.266-.746-.38-1.124-.378-1.262-.364-2.604-.346-3.921.022-1.454.045-2.907.069-4.358 0-.378-.111-.935.232-1.094.774-.357 1.579.352 2.29.814.427.284.915.508 1.329.813.247.17.461.383.632.63.201.339.343.709.421 1.095a101.21 101.21 0 011.504 6.653c.203.972.339 1.957.406 2.948.124 2.081-.309 4.181-.315 6.255zm-29.523-2.265c-.204.496-.423 1.016-.393 1.557.019.243.074.481.163.707h-4.306a15.912 15.912 0 01-.488-2.236c-.203-1.471-.091-2.969.1-4.445.128-1.146.378-2.275.745-3.368.5-1.401 1.291-2.669 1.964-3.986.158-.307.302-.653.471-.958.362-.669.829-1.161 1.76-.711 2.315 1.116 3.308 5.025 2.664 7.301a.054.054 0 010 .018 11.22 11.22 0 01-.687 1.682 106.658 106.658 0 00-1.993 4.439z'
				fill='#3C354C'
			/>
			<path
				d='M119.694 80.98c.461.167.846.495 1.087.922.185.384.179.834.305 1.242.266.86 1.067 1.423 1.565 2.175.675 1.016.754 2.311.707 3.53-.047 1.22-.203 2.465.102 3.65.144.577.39 1.126.489 1.713.12.712.021 1.456.204 2.155.16.636.536 1.193.906 1.734l1.581 2.311a18.243 18.243 0 01-2.087-.628 3.74 3.74 0 001.128 1.705l-1.142-1.382a3.282 3.282 0 00-.852-.813c-.418-.238-.914-.275-1.386-.372-.904-.183-1.81-.646-2.253-1.462-.592-1.085-.204-2.449-.543-3.64-.242-.87-.839-1.588-1.25-2.389-.933-1.815-.876-3.955-1.168-5.976-.126-.858-.317-1.71-.321-2.577 0-.64.063-1.856.733-2.183.581-.275 1.654.055 2.195.284z'
				fill='#3F3D56'
			/>
			<path
				d='M120.391 95.52c.096 1.06.317 2.313 1.284 2.758.547.252 1.183.158 1.777.256 1.119.18 2.032 1.043 2.56 2.053.529 1.01.697 2.157.813 3.287.152 1.412.203 2.947-.575 4.138-.628.966-1.713 1.553-2.825 1.852a7.877 7.877 0 01-6.578-1.22c-.921-.668-1.681-1.53-2.42-2.392-1.612-1.876-3.234-3.933-3.565-6.385-.038-.278-.034-.61.203-.78a.853.853 0 01.519-.104l1.544.028c.392 0 .835 0 1.102-.294.266-.295.249-.665.239-1.017l-.118-4.482a.648.648 0 01.102-.453.598.598 0 01.356-.153c.851-.144 1.741 0 2.575-.231.792-.218 2.032-1.128 2.619-.193.492.782.313 2.429.388 3.331z'
				fill='#FFC1C7'
			/>
			<path
				opacity='.1'
				d='M120.391 95.52c.096 1.06.317 2.313 1.284 2.758.547.252 1.183.158 1.777.256 1.119.18 2.032 1.043 2.56 2.053.529 1.01.697 2.157.813 3.287.152 1.412.203 2.947-.575 4.138-.628.966-1.713 1.553-2.825 1.852a7.877 7.877 0 01-6.578-1.22c-.921-.668-1.681-1.53-2.42-2.392-1.612-1.876-3.234-3.933-3.565-6.385-.038-.278-.034-.61.203-.78a.853.853 0 01.519-.104l1.544.028c.392 0 .835 0 1.102-.294.266-.295.249-.665.239-1.017l-.118-4.482a.648.648 0 01.102-.453.598.598 0 01.356-.153c.851-.144 1.741 0 2.575-.231.792-.218 2.032-1.128 2.619-.193.492.782.313 2.429.388 3.331z'
				fill='#000'
			/>
			<path
				d='M116.502 95.229a5.904 5.904 0 005.903-5.905 5.904 5.904 0 10-11.807 0 5.904 5.904 0 005.904 5.905z'
				fill='#FFC1C7'
			/>
			<path
				d='M129.642 114.611a5.638 5.638 0 01-.169.713 16.76 16.76 0 01-.65 1.604 7.057 7.057 0 00-.411 1.191 9.41 9.41 0 00-.134.634c-.055.315-.096.634-.128.954h-15.406a9.632 9.632 0 00-.087-2.338 15.35 15.35 0 00-.952-3.1c-.981-2.516-2.078-5.082-2.682-7.706v-.01a15.318 15.318 0 01-.374-5.212c.012-.102.026-.203.041-.309.036-.264.086-.526.148-.785a4.32 4.32 0 01.982-1.987c.691-.745 1.859-1.112 2.755-.637.541.287.876.795 1.144 1.358.171.358.315.736.466 1.096a11.182 11.182 0 002.333 3.486c.189.203.388.384.593.563.205.179.384.329.585.484.653.502 1.439 1.138 2.291 1.16.493.015.997 0 1.508 0 .447 0 .898-.018 1.343 0 .953.019 2.032-.252 2.493-1.087.315-.571.262-1.269.204-1.919-.047-.508-.094-1.016-.143-1.527v-.079c-.032-.368-.067-.732-.101-1.1-.017-.176-.033-.355-.051-.532l-.037-.394c.17-.02.34-.036.512-.05.771-.06 1.557-.046 2.22.33.63.356 1.016.968 1.264 1.661.164.494.271 1.005.317 1.524.227 2.307-.141 4.635-.015 6.948.082 1.675.435 3.413.141 5.066z'
				fill='#00528C'
			/>
			<path
				d='M133.159 119.707H128.5a40.005 40.005 0 01-.088-1.588 50.029 50.029 0 01-.01-4.051 6.663 6.663 0 002.997.856c.427.477.77 1.021 1.017 1.612.138.324.254.657.347.998a.033.033 0 010 .014c.191.707.324 1.429.396 2.159z'
				fill='#FFC1C7'
			/>
			<path
				d='M132.751 116.529c-1.763.018-3.516.25-5.223.691-.225-1.113-.116-2.264 0-3.394l.754-7.767c.055-.575-.051-5.856 1.063-4.923.406.343.927 3.431 1.055 3.927.193.75.345 1.508.481 2.268.279 1.561.468 3.135.738 4.698.077.451.73 4.504 1.132 4.5z'
				fill='#046CB6'
			/>
			<path
				d='M118.751 81.815a1.011 1.011 0 00-.122-.53.882.882 0 00-.632-.334c-.851-.132-1.786.203-2.556-.179-.407-.203-.714-.61-1.12-.836-.959-.542-2.17 0-3.02.704a8.825 8.825 0 00-2.831 4.313c-.378 1.305-.53 2.832-1.611 3.66-.447.34-1.016.53-1.352.985-.252.35-.311.799-.357 1.22-.112.992-.204 2.008.055 2.971.203.718.579 1.368.812 2.074a5.506 5.506 0 01-1.499 5.728c.393.245.867.328 1.321.23-.009.208-.075.41-.191.583 1.381-.045 2.583-1.39 3.934-1.096 1.032.224 1.607 1.289 2.378 2.013.991.933 2.532 1.323 3.777.77 1.246-.553 2.002-2.126 1.474-3.382-.61-1.423-2.457-1.994-3.101-3.407-.498-1.096-.128-2.372-.236-3.57-.092-1.028-.535-1.985-.813-2.98-.278-.993-.366-2.13.203-2.996.598-.918 1.742-1.276 2.782-1.626 2.343-.756 2.795-1.945 2.705-4.315z'
				fill='#3F3D56'
			/>
			<path
				d='M117.3 103.044c.169-.813.336-1.701.039-2.488-.553-1.467-2.36-1.925-3.609-2.87a4.88 4.88 0 01-1.606-2.14c-.227-.596-.258-1.423.301-1.724.217-.102.458-.144.697-.122 1.947.045 3.731 1.104 5.225 2.356.868.73 1.693 1.565 2.125 2.612.307.742.407 1.551.494 2.348l.305 2.642c.201 1.515.319 3.04.352 4.568.002.876-.053 1.751-.165 2.62-.077.61-.203 1.252-.555 1.758-.674.941-1.971 1.153-3.129 1.185a5.453 5.453 0 01-1.307-.083c-1.924-.419-3.384-3.114-1.929-4.779.61-.707 1.549-.726 1.901-1.695.471-1.285.585-2.84.861-4.188z'
				fill='#FFC1C7'
			/>
			<path
				d='M115.737 103.508l1.518 2.482c.091.128.154.274.185.429.043.323-.203.609-.39.872-1.309 1.788-.975 4.248-.581 6.429a11.668 11.668 0 01-8.019-9.446c-.191-1.299-.5-3.437.652-4.283 2.776-2.037 5.333 1.391 6.635 3.517z'
				fill='#046CB6'
			/>
			<path
				d='M123.452 98.534c-.594-.098-1.22 0-1.777-.256-.967-.446-1.188-1.698-1.284-2.759 0-.097-.014-.203-.018-.319a8.761 8.761 0 011.124-.978 5.511 5.511 0 011.148-.506l1.073-.378c.098.285.176.578.231.874.12.712.021 1.456.204 2.155.16.636.536 1.193.906 1.734l1.581 2.311a16.723 16.723 0 01-.853-.226c-.547-.829-1.366-1.496-2.335-1.652z'
				fill='#3F3D56'
			/>
			<path
				d='M21.925 139.052c.61-1.12-.08-2.494-.871-3.492-.791-.998-1.75-2.033-1.73-3.312.03-1.829 1.971-2.909 3.522-3.88a17.092 17.092 0 003.162-2.543 4.62 4.62 0 00.971-1.301c.322-.716.313-1.529.293-2.311-.069-2.61-.198-5.218-.388-7.824'
				stroke='#3F3D56'
				strokeWidth='4'
			/>
			<path
				d='M29.832 114.247a2.847 2.847 0 00-1.422-2.338l-.638 1.264.02-1.53a2.878 2.878 0 00-.94-.114 2.846 2.846 0 102.983 2.718h-.003zm-5.086 19.308a2.845 2.845 0 11.138-2.297l-1.782 1.45 1.961-.454c0 .453-.11.899-.317 1.301zm-1.408-5.539a2.845 2.845 0 01-.905-5.596l-.016 1.175.646-1.278a2.84 2.84 0 012.99 2.725 2.845 2.845 0 01-2.715 2.974zm6.923-4.6a2.845 2.845 0 111.262-5.34l-.504 1.383 1.037-.996c.555.508.886 1.217.92 1.969a2.845 2.845 0 01-2.715 2.984z'
				fill='#00AAE8'
			/>
			<path
				opacity='.1'
				d='M29.448 115.007c-.658.071-1.298.276-1.959.317-.66.041-1.386-.116-1.804-.63-.224-.277-.337-.626-.526-.929a2.027 2.027 0 00-.72-.677 2.846 2.846 0 105.333 1.894 4.332 4.332 0 00-.323.025zm.813 8.409a2.843 2.843 0 01-2.713-4.065c.227.152.421.347.573.573.204.307.328.663.565.943.445.523 1.204.693 1.892.663.69-.031 1.354-.228 2.033-.291.095 0 .19-.014.288-.016a2.844 2.844 0 01-2.638 2.193zm-6.923 4.6A2.846 2.846 0 0120.602 124c.237.154.444.35.61.579.221.313.36.675.61.963.481.535 1.29.724 2.018.708.727-.017 1.388-.189 2.089-.244a2.846 2.846 0 01-2.591 2.01zm1.408 5.539a2.847 2.847 0 11-5.2-2.327c.235.154.446.342.626.559.272.329.45.705.764 1.016.583.573 1.524.813 2.363.832.483.002.967-.025 1.447-.08z'
				fill='#000'
			/>
			<path
				d='M17.623 137.761s2.252-.069 2.93-.553c.68-.484 3.455-1.059 3.63-.285.175.775 3.384 3.863.843 3.875-2.54.012-5.905-.395-6.584-.813-.679-.419-.819-2.224-.819-2.224z'
				fill='#656380'
			/>
			<path
				opacity='.2'
				d='M25.071 140.53c-2.542.02-5.905-.407-6.582-.814-.516-.315-.722-1.441-.79-1.961h-.076s.142 1.817.813 2.236c.67.419 4.042.827 6.584.813.734 0 .986-.266.972-.653-.096.228-.376.372-.92.379z'
				fill='#000'
			/>
			<path
				d='M210.056 136.283c1.075-1.992-.145-4.433-1.549-6.212-1.404-1.778-3.113-3.626-3.077-5.895.053-3.252 3.506-5.177 6.263-6.901a30.484 30.484 0 005.626-4.524c.678-.692 1.333-1.423 1.729-2.314.571-1.272.555-2.719.518-4.114-.12-4.643-.35-9.281-.689-13.916'
				stroke='#3F3D56'
				strokeWidth='4'
			/>
			<path
				d='M224.122 92.16A5.068 5.068 0 00221.59 88l-1.136 2.249.035-2.722a5.08 5.08 0 00-5.644 2.018 5.081 5.081 0 109.277 2.615zm-9.047 34.348a5.08 5.08 0 01-9.592-2.623 5.08 5.08 0 019.836-1.463l-3.168 2.58 3.485-.813a5.033 5.033 0 01-.561 2.319zm-2.498-9.855a5.08 5.08 0 01-1.609-9.96l-.027 2.092 1.149-2.275h.014a5.083 5.083 0 014.97 7.467 5.08 5.08 0 01-4.497 2.672v.004zm12.309-8.198a5.082 5.082 0 112.236-9.496l-.896 2.457 1.845-1.77a5.047 5.047 0 011.64 3.502 5.08 5.08 0 01-4.825 5.307z'
				fill='#046CB6'
			/>
			<path
				opacity='.1'
				d='M223.439 93.511c-1.17.128-2.308.492-3.485.565-1.176.073-2.465-.203-3.209-1.122-.406-.492-.609-1.114-.938-1.653a3.6 3.6 0 00-1.277-1.2 5.084 5.084 0 005.659 7.292 5.081 5.081 0 003.826-3.925 9.032 9.032 0 00-.576.043zm1.447 14.945a5.083 5.083 0 01-4.826-7.239c.401.27.746.615 1.016 1.016.362.547.579 1.177 1.016 1.677.789.931 2.14 1.234 3.363 1.179 1.224-.055 2.407-.406 3.628-.516.169-.014.339-.026.51-.03a5.08 5.08 0 01-4.707 3.913zm-12.309 8.197a5.08 5.08 0 01-4.877-7.145c.417.277.78.626 1.071 1.033.394.557.64 1.197 1.102 1.709.857.954 2.296 1.291 3.593 1.261 1.255-.029 2.465-.338 3.714-.433a5.078 5.078 0 01-4.603 3.575zm2.498 9.855a5.08 5.08 0 01-9.251-4.141c.418.274.794.609 1.114.992.482.59.801 1.257 1.358 1.805 1.038 1.017 2.713 1.438 4.202 1.476.861.006 1.721-.038 2.577-.132z'
				fill='#000'
			/>
			<path
				d='M202.404 133.986s4.004-.124 5.211-.984c1.207-.859 6.159-1.886 6.46-.508.301 1.378 6.017 6.859 1.496 6.893-4.522.035-10.505-.703-11.71-1.437-1.205-.734-1.457-3.964-1.457-3.964z'
				fill='#656380'
			/>
			<path
				opacity='.2'
				d='M215.652 138.912c-4.521.036-10.504-.704-11.709-1.437-.917-.559-1.283-2.566-1.407-3.491h-.132s.252 3.23 1.457 3.964 7.19 1.474 11.71 1.437c1.307-.01 1.756-.473 1.731-1.162-.181.422-.678.683-1.65.689z'
				fill='#000'
			/>
			<path
				d='M229 32.5c0 17.397-14.103 31.5-31.5 31.5S166 49.897 166 32.5 180.103 1 197.5 1 229 15.103 229 32.5z'
				fill='#89C947'
				stroke='#DBF2C3'
				strokeWidth='2'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M194.769 48.513l-13.143-13.14 5.746-5.745 6.36 6.36 13.462-18.847 6.61 4.72-19.035 26.652z'
				fill='#fff'
			/>
		</SvgIcon>
	);
};
