import { brandSecondary } from '../../styles/colors';
import { Layouts } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	activityListItem: {
		alignItems: 'flex-start',
		borderBottom: '1px solid #F4F8FA',
		display: 'flex',
		padding: '14px 25px',
	},
	activityListItemContent: {
		display: 'flex',
		flexBasis: 0,
		flexDirection: 'column',
		flexGrow: 1,
		maxWidth: '100%',
		...Layouts.verticalStack(14),
	},
	activityListItemContentCompact: {
		overflowX: 'auto',
		textAlign: 'left',
	},
	activityListItemContentDate: {
		flexGrow: 2,
	},
	activityListItemContentHeader: {
		alignItems: 'center',
		color: brandSecondary,
		display: 'flex',
		fontSize: 12,
		justifyContent: 'space-between',
	},
	activityListItemIcon: {
		marginRight: 14,
	},
	activityListItemIconCompact: {
		marginRight: 9,
	},
	compactItem: {
		padding: '10px 0px',
	},
});
