import * as ToggleGroup from '@radix-ui/react-toggle-group';
import { css } from 'aphrodite';
import { FoldedCardIcon } from '../../components/svgs/icons/FoldedCardIcon';
import { FoldedCardInsideIcon } from '../../components/svgs/icons/FoldedCardInsideIcon';
import { brandPrimary, navigation } from '../../styles/colors';
import { styleSheet } from './styles';

export const HwcTogglePreview = { image: 'image', text: 'text' } as const;
export type HwcTogglePreviewValues = (typeof HwcTogglePreview)[keyof typeof HwcTogglePreview];

export function HwcPreviewToggle({
	value,
	onValueChange,
}: {
	value: HwcTogglePreviewValues;
	onValueChange: (value: HwcTogglePreviewValues) => void;
}) {
	const handleValueChange = (val: HwcTogglePreviewValues) => {
		if (val) {
			onValueChange(val);
		}
	};
	return (
		<ToggleGroup.Root
			type='single'
			value={value}
			onValueChange={handleValueChange}
			className={css(styleSheet.previewToggleItemGroup)}
		>
			<ToggleGroup.Item
				value={HwcTogglePreview.image}
				aria-label='Image preview'
				className={css(
					styleSheet.previewToggleItem,
					value === HwcTogglePreview.image && styleSheet.previewToggleItemToggled
				)}
			>
				<FoldedCardIcon
					fillColor={value === HwcTogglePreview.image ? brandPrimary : navigation}
					fillColorSecondary={value === HwcTogglePreview.image ? '#F2F7FF' : '#FFF'}
				/>
				<span>Cover</span>
			</ToggleGroup.Item>
			<ToggleGroup.Item
				value={HwcTogglePreview.text}
				aria-label='Text preview'
				className={css(
					styleSheet.previewToggleItem,
					value === HwcTogglePreview.text && styleSheet.previewToggleItemToggled
				)}
			>
				<FoldedCardInsideIcon fillColor={value === HwcTogglePreview.text ? brandPrimary : navigation} />
				<span>Inside</span>
			</ToggleGroup.Item>
		</ToggleGroup.Root>
	);
}
