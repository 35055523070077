import { brandSecondary } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	brandLink: {
		color: brandSecondary,
		fontSize: 14,
		textDecoration: 'none',
	},
});
