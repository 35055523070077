import { error, errorLight, mention, mentionDark, titles } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { CSSProperties, StyleDeclaration, StyleDeclarationMap, StyleSheet } from 'aphrodite';

const tail: CSSProperties = {
	content: "' '",
	height: 0,
	position: 'absolute',
	width: 0,
};

export const bulletSize = 16;
export const bulletMarginRight = 10;
export const editButtonSize = 16;
export const editButtonMarginRight = 10;

export const styles: StyleDeclaration<{
	addPhoneMessage: CSSProperties | StyleDeclarationMap;
	addPhoneMessageImportant: CSSProperties | StyleDeclaration;
	bullet: CSSProperties | StyleDeclarationMap;
	closeDeleteConfirmationContainer: CSSProperties | StyleDeclarationMap;
	container: CSSProperties | StyleDeclarationMap;
	deleteButton: CSSProperties | StyleDeclarationMap;
	deleteItemConfirmationArrow: CSSProperties | StyleDeclarationMap;
	deleteItemConfirmationContainer: CSSProperties | StyleDeclarationMap;
	deleteItemConfirmationPopover: CSSProperties | StyleDeclarationMap;
	editButton: CSSProperties | StyleDeclarationMap;
	editItem: CSSProperties | StyleDeclarationMap;
	footer: CSSProperties | StyleDeclarationMap;
	item: CSSProperties | StyleDeclarationMap;
	itemEditing: CSSProperties | StyleDeclarationMap;
	itemToBeDeleted: CSSProperties | StyleDeclarationMap;
	itemWithHover: CSSProperties | StyleDeclarationMap;
	list: CSSProperties | StyleDeclarationMap;
	plusIcon: CSSProperties | StyleDeclarationMap;
}> = {
	addPhoneMessage: {
		':after': {
			...tail,
			borderRight: '14px solid transparent',
			borderTop: `11px solid ${mention}`,
			left: 6,
			top: 'calc(100% - 1px)',
		},
		':before': {
			...tail,
			borderRight: '15px solid transparent',
			borderTop: `12px solid ${mentionDark}`,
			left: 5,
			top: '100%',
		},
		background: mention,
		border: `1px solid ${mentionDark}`,
		borderRadius: 4,
		bottom: 'calc(100% + 10px)',
		minWidth: 280,
		padding: '15px 10px',
		position: 'absolute',
		transform: 'translate(0, -12px)',
	},
	addPhoneMessageImportant: {
		':after': {
			borderTop: `11px solid ${errorLight}`,
		},
		':before': {
			borderTop: `12px solid ${error}`,
		},
		background: errorLight,
		border: `1px solid ${error}`,
	},
	bullet: {
		alignItems: 'center',
		display: 'flex',
		flexShrink: 0,
		height: bulletSize,
		justifyContent: 'center',
		marginRight: bulletMarginRight,
		width: bulletSize,
	},
	closeDeleteConfirmationContainer: {
		height: 14,
		position: 'absolute',
		right: 8,
		top: 5,
		width: 14,
		...CssSelectors.allChildren({
			height: '100%',
			width: '100%',
		}),
	},
	container: {},
	deleteButton: {
		padding: '4px 8px 0',
	},
	deleteItemConfirmationArrow: {
		...CssSelectors.allChildren(
			{
				':after': {
					borderLeft: '25px solid transparent',
					borderTop: '11px solid #D3DDBE',
					content: "' '",
					left: -24,
					position: 'absolute',
					top: -10,
					zIndex: -1,
				},
				borderLeftWidth: '22px !important',
				borderRight: 'none !important',
				left: 'auto !important',
				right: 10,
				width: 0,
			},
			'div:first-child'
		),
	},
	deleteItemConfirmationContainer: {
		borderBottomRightRadius: 0,
		boxSizing: 'border-box',
		maxWidth: 310,
		padding: '15px 20px 20px',
		position: 'relative',
	},
	deleteItemConfirmationPopover: {},
	editButton: {
		padding: '4px 0 0',
	},
	editItem: {},
	footer: {
		alignItems: 'center',
		display: 'flex',
		height: 30,
		position: 'relative',
	},
	item: {
		':nth-child(1n) .entity-info-bullet-list-item-edit-button': {
			opacity: 0,
			pointerEvents: 'none',
		},

		':nth-child(1n) > .entity-info-bullet-list-item-bullet': {
			marginTop: 2,
		},
		alignItems: 'flex-start',
		color: titles,
		display: 'flex',
		fontSize: 14,
		listStyle: 'none',
		padding: '4px 0',
	},
	itemEditing: {
		':nth-child(1n) > .entity-info-bullet-list-item-bullet': {
			marginTop: 0,
		},
		alignItems: 'center',
	},
	itemToBeDeleted: {
		':nth-child(1n) .entity-info-bullet-list-item-edit-button': {
			opacity: 1,
			pointerEvents: 'all',
		},
		background: '#F9F9F9',
	},
	itemWithHover: {
		':hover': {
			':nth-child(1n) .entity-info-bullet-list-item-edit-button': {
				opacity: 1,
				pointerEvents: 'all',
			},
			background: '#F9F9F9',
		},
	},
	list: {
		listStyle: 'none',
	},
	plusIcon: {
		marginRight: 10,
	},
};

export const styleSheet = StyleSheet.create(styles);
