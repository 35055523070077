import { cardStyle, cardStyleBorderRadius } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

const indicatorWidth = 4;
export const horizontalBodyPaddingLeft = 26;
export const horizontalBodyPaddingRight = 10;

export const styleSheet = StyleSheet.create({
	body: {
		boxSizing: 'border-box',
		padding: `0 ${horizontalBodyPaddingRight}px 20px ${horizontalBodyPaddingLeft}px`,
	},
	bodyNoFooter: {
		padding: `0 ${horizontalBodyPaddingRight}px 10px ${horizontalBodyPaddingLeft}px`,
	},
	container: {
		...cardStyle,
		borderTopRightRadius: cardStyleBorderRadius,
		display: 'flex',
		overflow: 'visible',
		position: 'relative',
	},
	content: {
		marginLeft: indicatorWidth,
		width: `calc(100% - ${indicatorWidth}px)`,
	},
	flexChildRight: {
		flexGrow: 1,
	},
	flexContainer: {
		display: 'flex',
	},
	footer: {
		borderTop: '1px solid #F2F2F2',
		boxSizing: 'border-box',
		padding: `6px ${horizontalBodyPaddingRight}px 6px ${horizontalBodyPaddingLeft}px`,
	},
	header: {
		height: 20,
	},
	indicator: {
		borderRadius: `${cardStyleBorderRadius}px 0 0 ${cardStyleBorderRadius}px`,
		height: '100%',
		left: 0,
		position: 'absolute',
		top: 0,
		transition: 'background 0.1s',
		width: indicatorWidth,
	},
});
