import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	stroke?: string;
	strokeWidth?: string;
}

export const CompaniesIcon: React.FC<IProps> = ({ className, stroke = '#fff', strokeWidth = '2' }) => {
	const height = 18;
	const width = 20;
	return (
		<SvgIcon className={className} height={height} viewBox={`0 0 ${width} ${height}`} width={width}>
			<g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
				<g transform='translate(-56.000000, -220.000000)' stroke={stroke} strokeWidth={strokeWidth}>
					<g transform='translate(57.000000, 221.000000)'>
						<rect x='0' y='3.55555556' width='18' height='12.4444444' rx='1.77777778' />
						<path d='M12.6,16 L12.6,1.77777778 C12.6,0.795938223 11.7941125,0 10.8,0 L7.2,0 C6.20588745,0 5.4,0.795938223 5.4,1.77777778 L5.4,16' />
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
