import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	stroke?: string;
}

export const SettingsIcon: React.FC<IProps> = ({ className, stroke = '#fff' }) => {
	const height = 20;
	const width = 20;
	let path1 = 'M15.0545455,11.4545455 C14.8317165,11.9594373 14.938637,12.5491199 15.3245455,12.9436364 L15.3736364,';
	path1 += '12.9927273 C15.6809079,13.2996571 15.85356,13.7161485 15.85356,14.1504545 C15.85356,14.5847606 15.6809079,';
	path1 +=
		'15.0012519 15.3736364,15.3081818 C15.0667065,15.6154533 14.6502151,15.7881054 14.2159091,15.7881054 C13.781603,';
	path1 +=
		'15.7881054 13.3651117,15.6154533 13.0581818,15.3081818 L13.0090909,15.2590909 C12.6145745,14.8731825 12.0248919,';
	path1 +=
		'14.766262 11.52,14.9890909 C11.0254331,15.2010559 10.7039642,15.686474 10.7018182,16.2245455 L10.7018182,16.3636364 C10.7018182,';
	path1 +=
		'17.267375 9.96919323,18 9.06545455,18 C8.16171586,18 7.42909091,17.267375 7.42909091,16.3636364 L7.42909091,16.29 C7.41612813,';
	path1 +=
		'15.7358216 7.06571327,15.2458897 6.54545455,15.0545455 C6.04056267,14.8317165 5.45088006,14.938637 5.05636364,15.3245455 L5.00727273,';
	path1 +=
		'15.3736364 C4.70034285,15.6809079 4.2838515,15.85356 3.84954545,15.85356 C3.41523941,15.85356 2.99874806,15.6809079 2.69181818,15.3736364 C2.38454666,';
	path1 +=
		'15.0667065 2.21189456,14.6502151 2.21189456,14.2159091 C2.21189456,13.781603 2.38454666,13.3651117 2.69181818,13.0581818 L2.74090909,13.0090909 C3.12681754,';
	path1 +=
		'12.6145745 3.23373801,12.0248919 3.01090909,11.52 C2.79894413,11.0254331 2.31352603,10.7039642 1.77545455,10.7018182 L1.63636364,10.7018182 C0.732624955,';
	path1 +=
		'10.7018182 1.81672859e-16,9.96919323 0,9.06545455 C-9.08364293e-17,8.16171586 0.732624955,7.42909091 1.63636364,7.42909091 L1.71,7.42909091 C2.26417842,7.41612813 2.75411031,';
	path1 +=
		'7.06571327 2.94545455,6.54545455 C3.16828346,6.04056267 3.06136299,5.45088006 2.67545455,5.05636364 L2.62636364,5.00727273 C2.31909211,4.70034285 2.14644002,4.2838515 2.14644002,';
	path1 +=
		'3.84954545 C2.14644002,3.41523941 2.31909211,2.99874806 2.62636364,2.69181818 C2.93329351,2.38454666 3.34978487,2.21189456 3.78409091,2.21189456 C4.21839695,2.21189456 4.63488831,2.38454666 4.94181818,';
	path1 +=
		'2.69181818 L4.99090909,2.74090909 C5.38542551,3.12681754 5.97510812,3.23373801 6.48,3.01090909 L6.54545455,3.01090909 C7.04002141,2.79894413 7.36149035,2.31352603 7.36363636,';
	path1 +=
		'1.77545455 L7.36363636,1.63636364 C7.36363636,0.732624955 8.09626132,1.81672859e-16 9,0 C9.90373868,0 10.6363636,0.732624955 10.6363636,1.63636364 L10.6363636,1.71 C10.6385096,';
	path1 +=
		'2.24807148 10.9599786,2.73348959 11.4545455,2.94545455 C11.9594373,3.16828346 12.5491199,3.06136299 12.9436364,2.67545455 L12.9927273';
	path1 +=
		',2.62636364 C13.2996571,2.31909211 13.7161485,2.14644002 14.1504545,2.14644002 C14.5847606,2.14644002 15.0012519,2.31909211 15.3081818';
	path1 +=
		',2.62636364 C15.6154533,2.93329351 15.7881054,3.34978487 15.7881054,3.78409091 C15.7881054,4.21839695 15.6154533,4.63488831 15.3081818,';
	path1 +=
		'4.94181818 L15.2590909,4.99090909 C14.8731825,5.38542551 14.766262,5.97510812 14.9890909,6.48 L14.9890909,6.54545455 C15.2010559,7.04002141 15.686474,';
	path1 +=
		'7.36149035 16.2245455,7.36363636 L16.3636364,7.36363636 C17.267375,7.36363636 18,8.09626132 18,9 C18,9.90373868 17.267375,10.6363636 16.3636364,';
	path1 += '10.6363636 L16.29,10.6363636 C15.7519285,10.6385096 15.2665104,10.9599786 15.0545455,11.4545455 Z';

	return (
		<SvgIcon className={`settings-icon ${className}`} height={height} viewBox={`0 0 ${width} ${height}`} width={width}>
			<g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
				<g transform='translate(-57.000000, -363.000000)' stroke={stroke} strokeWidth='2'>
					<g transform='translate(58.000000, 364.000000)'>
						<circle cx='9' cy='9' r='2.45454545' />
						<path d={path1} />
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
