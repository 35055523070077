import { backgroundAlt, grayIconFill } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	button: {
		height: 14,
		margin: '0 8px 0 0',
	},
	msg: {
		background: backgroundAlt,
		border: `1px solid ${grayIconFill}`,
		borderRadius: 8,
		maxWidth: 300,
		padding: '10px 15px',
	},
	popover: {
		margin: '0 10px',
	},
});
