import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const SmileyIcon: React.FC<IProps> = ({ className, fillColor = '#A7ABAD' }) => {
	return (
		<SvgIcon className={`smiley-icon ${className || ''}`} height={16} width={16}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8 1.6C4.4712 1.6 1.6 4.4712 1.6 8C1.6 11.5288 4.4712 14.4 8 14.4C11.5288 14.4 14.4 11.5288 14.4 8C14.4 4.4712 11.5288 1.6 8 1.6ZM8 16C3.5888 16 0 12.4112 0 8C0 3.5888 3.5888 0 8 0C12.4112 0 16 3.5888 16 8C16 12.4112 12.4112 16 8 16Z'
				fill={fillColor}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M4.79995 7.99999C3.91755 7.99999 3.19995 7.28239 3.19995 6.39999C3.19995 5.51839 3.91755 4.79999 4.79995 4.79999C5.68235 4.79999 6.39995 5.51839 6.39995 6.39999C6.39995 7.28239 5.68235 7.99999 4.79995 7.99999Z'
				fill={fillColor}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11.2001 7.99999C10.3177 7.99999 9.6001 7.28239 9.6001 6.39999C9.6001 5.51839 10.3177 4.79999 11.2001 4.79999C12.0825 4.79999 12.8001 5.51839 12.8001 6.39999C12.8001 7.28239 12.0825 7.99999 11.2001 7.99999Z'
				fill={fillColor}
			/>
			<path
				d='M4.23431 9.83429C4.54673 9.52187 5.05327 9.52187 5.36569 9.83429C6.29378 10.7624 7.16896 11.2 8 11.2C8.83104 11.2 9.70622 10.7624 10.6343 9.83429C10.9467 9.52187 11.4533 9.52187 11.7657 9.83429C12.0781 10.1467 12.0781 10.6532 11.7657 10.9657C10.5604 12.1709 9.3023 12.8 8 12.8C6.6977 12.8 5.43955 12.1709 4.23431 10.9657C3.9219 10.6532 3.9219 10.1467 4.23431 9.83429Z'
				fill={fillColor}
			/>
		</SvgIcon>
	);
};
