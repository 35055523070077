import * as React from 'react';
import { SvgIcon } from '../SvgIcon';

interface IProps {
	className?: string;
}

export const TagExpandIcon: React.FC<IProps> = ({ className = '' }) => {
	return (
		<SvgIcon className={`tag-expand-icon ${className}`} height={43} width={42}>
			<circle cx='21' cy='21.5' r='20' fill='#E8F3F9' stroke='#00AAE8' strokeWidth='2' />
			<circle cx='20.889' cy='21.389' r='16' fill='#fff' />
			<rect x='13.333' y='15.166' width='15.333' height='5.333' rx='2.667' fill='#00AAE8' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M17.442 19.126c-.371.536-1.139.6-1.594.134l-.408-.418a.414.414 0 01.583-.587l.605.584 1.99-2.824a.402.402 0 01.659.46l-1.835 2.651z'
				fill='#F9F9F9'
			/>
			<rect x='13.333' y='21.834' width='15.333' height='5.333' rx='2.667' fill='#00AAE8' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M17.442 25.793c-.371.536-1.139.6-1.594.134l-.408-.418a.414.414 0 01.583-.587l.605.584 1.99-2.824a.402.402 0 01.659.46l-1.835 2.651z'
				fill='#F9F9F9'
			/>
		</SvgIcon>
	);
};
