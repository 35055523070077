import { ContactViewModel, IKeyFact, IPhoneNumber } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { contactIsFromEnabledIntegration, getContactSource } from '../../../../models/UiUtils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { destructive } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { DeleteConfirmation } from '../../DeleteConfirmation';
import { HelpTooltip } from '../../HelpTooltip';
import { LoadingSpinner } from '../../LoadingSpinner';
import { Toggle } from '../../Toggle';
import { IAddPhoneNotification } from '../../entities/EntityInfoBulletList';
import { ContactInfo } from '../ContactInfo';
import { EditContactInfo } from '../EditContactInfo';
import { styleSheet } from './styles';

interface IProps {
	addPhoneNotification?: IAddPhoneNotification;
	className?: string;
	compactLayout?: boolean;
	contact: ContactViewModel;
	disableCompanyLink?: boolean;
	hideEditButton?: boolean;
	showSendMessage?: boolean;
	showHwcActionButton?: boolean;
	initialEdit?: boolean;
	isLoading?: boolean;
	keyFactToHighlight?: IKeyFact;
	linkToProfile?: boolean;
	onContactEditButtonClicked?(contact: ContactViewModel): void;
	onContactUpdated?(contact: ContactViewModel): void;
	onDeleteConfirmation?(confirmDelete: boolean): void;
	onPhoneNumberAdded?(contact: ContactViewModel): void;
	onPhoneNumberEdited?(origPhone: IPhoneNumber, newPhone: IPhoneNumber): void;
	onShowRelationshipAnalysisButtonClicked?(): void;
	scrollToTopOffset?: number;
	styles?: StyleDeclarationValue[];
	shouldPollForRelationshipHealth?: boolean;
}

const _EditableContact = ({
	initialEdit,
	contact,
	className,
	scrollToTopOffset,
	compactLayout,
	isLoading,
	onContactUpdated,
	addPhoneNotification,
	disableCompanyLink,
	showSendMessage = true,
	showHwcActionButton = true,
	keyFactToHighlight,
	linkToProfile,
	onPhoneNumberAdded,
	onPhoneNumberEdited,
	onShowRelationshipAnalysisButtonClicked,
	hideEditButton,
	onContactEditButtonClicked,
	onDeleteConfirmation,
	shouldPollForRelationshipHealth,
}: IProps) => {
	const userSession = useUserSession();
	const [isEditing, setIsEditing] = React.useState(initialEdit);
	const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(false);
	const scrollToTopAnchorRef = React.useRef<HTMLSpanElement>(null);
	React.useEffect(() => {
		return () => {
			contact.dataOriginEnabled = false;
		};
	}, [contact]);
	const toggleDataOrigin = () => (contact.dataOriginEnabled = !contact.dataOriginEnabled);
	const renderDataOriginLink = () => {
		// @ts-ignore
		if (contactIsFromEnabledIntegration(contact, userSession.account.integrations)) {
			return (
				<div className={css(styleSheet.dataOriginLinkContainer)}>
					<button className={css(baseStyleSheet.brandLink, styleSheet.dataOriginLink)} onClick={toggleDataOrigin}>
						{/* @ts-ignore */}
						{`How does data come from ${getContactSource(contact, userSession.account.integrations)}?`}
					</button>
					<Toggle
						className={css(styleSheet.toggle)}
						id='data-origin-toggle'
						isOn={contact.dataOriginEnabled}
						onToggleCheckChanged={toggleDataOrigin}
						text=''
						uncheckedColor='#B1B4B6'
					/>
				</div>
			);
		}
		return null;
	};
	const onEditingFinished = () => {
		setIsEditing(false);
		onContactUpdated?.(contact);
		scrollToTopAnchorRef.current?.scrollIntoView({ behavior: 'smooth' });
	};
	const handleShowRelationshipAnalysisButtonClicked = () => {
		onShowRelationshipAnalysisButtonClicked?.();
	};
	const onEditButtonClicked = () => {
		setIsEditing(true);
		onContactEditButtonClicked?.(contact);
	};
	const onDeleteContactButtonClicked = () => {
		setShowDeleteConfirmation(true);
	};
	const handleDeleteConfirmation = (confirmDelete: boolean) => {
		setShowDeleteConfirmation(false);
		onDeleteConfirmation?.(confirmDelete);
	};
	return (
		<>
			<div className={`${css(styleSheet.container)} editable-contact ${className || ''}`}>
				<span
					style={{
						fontSize: 0,
						height: 0,
						position: 'absolute',
						top: scrollToTopOffset || 0,
					}}
					ref={scrollToTopAnchorRef}
				/>
				{contact?.isArchived ? (
					<div className={css(styleSheet.archivedContainer)}>
						<span className={css(styleSheet.archivedText)}>CONTACT ARCHIVED</span>
						<HelpTooltip className={css(styleSheet.archivedTooltip)} iconColor={destructive}>
							<div>Deleted or archived contacts will not be included in any sends.</div>
						</HelpTooltip>
					</div>
				) : null}
				{!isLoading ? (
					<div
						className={`editable-contact-inner ${css(compactLayout ? styleSheet.masterCompact : styleSheet.master)}`}
					>
						{renderDataOriginLink()}
						{isEditing ? (
							<EditContactInfo compactLayout={compactLayout} contact={contact} onFinish={onEditingFinished} />
						) : (
							<>
								<ContactInfo
									addPhoneNotification={addPhoneNotification}
									compactLayout={compactLayout}
									contact={contact}
									disableCompanyLink={disableCompanyLink}
									showSendMessage={showSendMessage}
									showHwcActionButton={showHwcActionButton}
									keyFactToHighlight={keyFactToHighlight}
									linkToProfile={linkToProfile}
									onPhoneNumberAdded={onPhoneNumberAdded}
									onPhoneNumberEdited={onPhoneNumberEdited}
									onShowRelationshipAnalysisButtonClicked={handleShowRelationshipAnalysisButtonClicked}
									shouldPollForRelationshipHealth={shouldPollForRelationshipHealth}
								/>
								{!hideEditButton && (
									<button className={`${css(styleSheet.editButton)}`} onClick={onEditButtonClicked}>
										<span>Edit</span>
									</button>
								)}
								{!(contact && contact.isBusy) && onDeleteConfirmation ? (
									<button
										className={`${css(baseStyleSheet.ctaButtonDestructive)}`}
										onClick={onDeleteContactButtonClicked}
									>
										<span>Delete Contact</span>
									</button>
								) : null}
								<DeleteConfirmation
									bodyText='Are you sure you want to delete this contact? Once deleted it will be gone forever.'
									isOpen={showDeleteConfirmation}
									onFinish={handleDeleteConfirmation}
								/>
							</>
						)}
					</div>
				) : null}
			</div>
			{contact.isFetchingIntegrationData ? (
				<LoadingSpinner
					className={css(baseStyleSheet.absoluteCenter, styleSheet.originDataLoadingSpinner)}
					type='large'
				/>
			) : null}
		</>
	);
};

export const EditableContact = observer(_EditableContact);
