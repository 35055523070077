import { StyleSheet } from 'aphrodite';
import { brandPrimaryText, label, navigation } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	checkboxContainer: {
		marginTop: 10,
	},
	checkboxText: {
		marginLeft: 10,
	},
	checkboxTextOnly: {
		marginLeft: 20,
		width: 400,
	},
	currentBalanceCopy: {
		color: navigation,
		fontSize: 12,
		...CssSelectors.allDescendants(
			{
				color: label,
			},
			'strong'
		),
	},
	label: {
		color: navigation,
		display: 'block',
		fontSize: 12,
		marginBottom: '0.5rem',
	},
	modalFooter: {
		alignItems: 'center',
		display: 'flex',
		marginTop: 20,
		...CssSelectors.allDescendants(
			{
				marginRight: 10,
			},
			':first-child'
		),
	},
	modalHeaderTitle: {
		boxSizing: 'border-box',
		color: brandPrimaryText,
		fontSize: 18,
	},
});
