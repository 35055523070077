import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	iframe: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		border: 0,
		boxSizing: 'border-box',
		padding: '0 35px',
		height: 444,
		backgroundColor: '#fff',
		width: 489,
		borderRadius: 12,
		marginBottom: -16,
		boxShadow: '0px 1px 2px rgba(167, 171, 173, 0.21)',
	},
	iframeWrap: {
		position: 'relative',
		width: '100%',
		height: 278,
		overflow: 'hidden',
		marginTop: 25,
		boxSizing: 'border-box',
	},
});
