import { navigation } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const BulletIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;
	return (
		<SvgIcon className={`bullet-icon ${className || ''}`} height={6} width={6}>
			<circle cx='3' cy='3' r='3' fill={fillColor || navigation} fillRule='evenodd' />
		</SvgIcon>
	);
};
