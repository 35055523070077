import { getDisplayName, getEntityDisplayName } from '../../../../models/UiUtils';
import { baseStyleSheet } from '../../../styles/styles';
import { Avatar } from '../../Avatar';
import { FullscreenModalNavLink } from '../../fullscreen/FullscreenModalNavLink';
import { styleSheet } from './styles';
import { IContact, IEntity, IPrincipal, getTypeForEntity } from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';

export interface IContactSearchListItemProps {
	avatarClassName?: string;
	canRedirectToProfilePage?: boolean;
	className?: string;
	entity?: IEntity;
	onClick?(e: React.MouseEvent<HTMLElement>): void;
	onKeyDown?: React.KeyboardEventHandler<HTMLElement>;
	onMouseDown?(e: React.MouseEvent<HTMLElement>): void;
	onMouseEnter?(e: React.MouseEvent<HTMLElement>): void;
	onMouseUp?(e: React.MouseEvent<HTMLElement>): void;
	renderEmail?: boolean;
	showTitle?: boolean;
	tabIndex?: number;
	user?: IPrincipal;
}

export const ContactSearchListItem: React.FC<IContactSearchListItemProps> = props => {
	const onLinkClicked = (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.stopPropagation();
	};

	const getContent = () => {
		if (props.user) {
			return (
				<>
					<Avatar user={props.user} className={`${css(styleSheet.avatar)} ${props.avatarClassName || ''}`} />
					<span className={css(styleSheet.details)}>
						<span className={`${css(styleSheet.name, baseStyleSheet.truncateText)} name`}>
							{getDisplayName(props.user)}
						</span>
						{!!props.renderEmail && (
							<span className={`${css(styleSheet.email)} email`}>
								{props.user?.primaryEmail?.value ? props.user.primaryEmail.value : undefined}
							</span>
						)}
					</span>
				</>
			);
		} else if (props.entity) {
			const contact = getTypeForEntity(props.entity) !== 'company' ? (props.entity as IContact) : null;
			const displayName = getEntityDisplayName(props.entity);
			return (
				<>
					<Avatar entity={props.entity} className={`${css(styleSheet.avatar)} ${props.avatarClassName || ''}`} />
					<span className={css(styleSheet.details)}>
						{props.canRedirectToProfilePage ? (
							<FullscreenModalNavLink
								className={css(styleSheet.name, baseStyleSheet.truncateText)}
								onClick={onLinkClicked}
								to={`/${contact ? 'people' : 'companies'}/${props.entity.id}`}
							>
								{displayName}
							</FullscreenModalNavLink>
						) : (
							<span className={css(styleSheet.name, baseStyleSheet.truncateText)}>{displayName}</span>
						)}
						{!!contact && props.showTitle && !!contact.jobTitle && (
							<span className={css(styleSheet.title, baseStyleSheet.truncateText)}>
								{`${contact.jobTitle}${contact.companyName ? ` at ${contact.companyName}` : ''}`}
							</span>
						)}
					</span>
				</>
			);
		}
	};

	return (
		<div
			className={`${css(styleSheet.listItem)} ${props.className || ''}`}
			onClick={props.onClick}
			onKeyDown={props.onKeyDown}
			onMouseDown={props.onMouseDown}
			onMouseEnter={props.onMouseEnter}
			onMouseUp={props.onMouseUp}
			tabIndex={props.tabIndex}
		>
			{getContent()}
		</div>
	);
};
