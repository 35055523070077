import { titles } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

const iconSize = 140;
const messageLeftMargin = 20;

export const styleSheet = StyleSheet.create({
	container: {
		alignItems: 'center',
		background: '#E8F3F9',
		boxSizing: 'border-box',
		display: 'flex',
		padding: 30,
	},
	icon: {
		'@media only screen and (max-width: 900px)': {
			display: 'none',
		},
		height: iconSize,
		width: iconSize,
	},
	message: {
		'@media only screen and (max-width: 900px)': {
			marginLeft: 0,
			width: '100%',
		},
		marginLeft: messageLeftMargin,
		width: `calc(100% - ${iconSize}px - ${messageLeftMargin}px)`,
	},
	messageBody: {
		color: '#858585',
		fontSize: 14,
		lineHeight: '22px',
		marginBottom: 10,
		marginTop: 10,
	},
	messageCompact: {
		marginLeft: 0,
		width: '100%',
	},
	messageContactButton: {
		textDecoration: 'none',
	},
	messageTitle: {
		color: titles,
		fontSize: 18,
		lineHeight: '22px',
	},
});
