import { IAddress } from '@ViewModels';
import { css } from 'aphrodite';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import shallowEqual from 'shallowequal';
import { TextInput } from '../../TextInput';
import { CountrySelector } from '../../ValueSelectors/CountrySelector';
import { INameValue } from '../../ValueSelectors/ValueSelector';
import { EntityInfoFieldLabel } from '../EntityInfoFieldLabel';
import { styleSheet } from './styles';

interface IProps {
	address?: IAddress;
	className?: string;
	onAddressChanged?(address: IAddress): void;
}

class _EditableEntityAddress extends React.Component<IProps> {
	// @ts-ignore
	@observable private mEditableAddress: IAddress;

	public UNSAFE_componentWillMount() {
		this.mEditableAddress = this.createEditableAddress(this.props.address);
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		if (nextProps.address !== this.props.address || !shallowEqual(this.props.address, nextProps.address)) {
			this.mEditableAddress = this.createEditableAddress(nextProps.address);
		}
	}

	public render() {
		const { className } = this.props;
		return (
			<div className={`${css(styleSheet.container)} editable-entity-address ${className || ''}`}>
				{this.renderInputField('Address Line 1', 'address1')}
				{this.renderInputField('Address Line 2', 'address2')}
				{this.renderInputField('City', 'city')}
				{this.renderInputField('State/Province', 'stateProvince')}
				{this.renderInputField('Zip/Postal Code', 'postalCode')}
				<div>
					<EntityInfoFieldLabel title='Country' />
					<CountrySelector
						className={css(styleSheet.country)}
						onSelectionChanged={this.onCountryChanged}
						selectedValue={this.mEditableAddress.country}
					/>
				</div>
			</div>
		);
	}

	private renderInputField(title: string, propertyName: keyof IAddress) {
		return (
			<div>
				<EntityInfoFieldLabel title={title} />
				<TextInput
					autoComplete='off'
					className={css((styleSheet as any)[propertyName])}
					inputId={`editable-entity-address-${propertyName}-input`}
					onChange={this.onTextInputValueChanged(propertyName)}
					type='text'
					value={this.mEditableAddress[propertyName] || ''}
				/>
			</div>
		);
	}

	private createEditableAddress = (address?: IAddress) => {
		const editableAddress: IAddress = {
			// @ts-ignore
			address1: null,
			// @ts-ignore
			address2: null,
			// @ts-ignore
			city: null,
			// @ts-ignore
			country: null,
			// @ts-ignore
			postalCode: null,
			// @ts-ignore
			stateProvince: null,
			...(address || {}),
		};

		return editableAddress;
	};

	private onCountryChanged = (country: INameValue) => {
		const { onAddressChanged } = this.props;
		const value = country.value !== 'None' ? country.value : null;
		if (onAddressChanged) {
			const address: IAddress = {
				...this.mEditableAddress,
				// @ts-ignore
				country: value,
			};
			onAddressChanged(address);
		} else {
			// @ts-ignore
			this.mEditableAddress.country = value;
		}
	};

	private onTextInputValueChanged = (propertyName: keyof IAddress) => (e: React.ChangeEvent<HTMLInputElement>) => {
		const { onAddressChanged } = this.props;
		if (onAddressChanged) {
			const address: IAddress = {
				...this.mEditableAddress,
			};
			address[propertyName] = e.target.value;
			onAddressChanged(address);
		} else {
			this.mEditableAddress[propertyName] = e.target.value;
		}
	};
}

export const EditableEntityAddress = observer(_EditableEntityAddress);
