import * as Api from '@ViewModels';

import { action, computed, observable } from 'mobx';
import { v4 as uuidgen } from 'uuid';
import { ISlotMachineReel } from '../models/slotMachines';
import { AchievementViewModel, AchievementsViewModel } from './achievements';
import { GameViewModel } from './games';

export class SlotMachineGameViewModel extends GameViewModel<Api.ISlotMachine> {
	public static CreateNewGame = async (userSession: Api.UserSessionContext, game: Partial<Api.ISlotMachine>) => {
		const opResult = await userSession.webServiceHelper.callWebServiceAsync<Api.ISlotMachine>(
			this.composeApiUrl({ urlPath: 'game/slot-machine' }),
			'POST',
			game
		);
		if (!opResult.success) {
			throw opResult;
		}

		return new SlotMachineGameViewModel(userSession, opResult.value);
	};

	constructor(userSession: Api.UserSessionContext, game?: Api.ISlotMachine) {
		super(userSession, game);
	}

	@computed
	public get description() {
		return this.mGame?.description;
	}

	@computed
	public get type() {
		return this.mGame?.type;
	}

	@computed
	public get config() {
		return this.mGame?.slotMachineConfig;
	}

	@action
	public update = async (game: Api.ISlotMachine) => {
		if (this.loading) {
			return;
		}

		this.loading = true;
		const opResult = await this.mUpdate(game);
		this.loading = false;

		if (!opResult.success) {
			throw opResult;
		}

		return opResult;
	};

	@action
	public updatePayLine = async (payLine: Api.ISlotMachinePayLine) => {
		const payTable = [...this.mGame.slotMachineConfig.payTable];
		const index = payTable.findIndex(x => x.symbol === payLine.symbol && x.symbolCount === payLine.symbolCount);
		if (index >= 0) {
			payTable.splice(index, 1, payLine);
			const game: Api.ISlotMachine = {
				...this.mGame,
				slotMachineConfig: {
					...this.mGame.slotMachineConfig,
					payTable,
				},
			};
			return this.update(game);
		}
	};

	public toJs = () => this.mGame;

	protected mUpdate = async (game: Api.ISlotMachine) => {
		const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ISlotMachine>(
			this.composeApiUrl({ urlPath: `game/slot-machine/${this.id}` }),
			'PUT',
			game
		);

		if (opResult.success) {
			// @ts-ignore
			this.mGame = opResult.value;
		}

		return opResult;
	};
}

export class SlotMachineAchievementViewModel extends AchievementViewModel<
	Api.IGameTokenRewardConfiguration,
	Api.ISlotMachine
> {
	// @ts-ignore
	@observable.ref protected mGame: SlotMachineGameViewModel;

	constructor(userSession: Api.UserSessionContext, achievement: Api.IAchievement<Api.IGameTokenRewardConfiguration>) {
		super(userSession, achievement);
		this.impersonate = this.impersonate.bind(this);
		this.initGame();
	}

	@computed
	public get gameId() {
		return this.mAchievement?.achievementConfiguration?.rewardConfiguration?.gameId;
	}

	@computed
	public get game() {
		return this.mGame;
	}

	@computed
	public get activePrizeCount() {
		return this.mGame?.config?.payTable?.reduce((count, x) => (x.enabled ? count + 1 : count), 0) || 0;
	}

	@action
	public load = async () => {
		if (!this.mGame || (this.mGame && this.mGame?.isLoaded) || this.loading) {
			return;
		}

		this.loading = true;
		const opResult = await this.mGame.load();
		this.loading = false;

		// @ts-ignore
		if (!opResult.success) {
			throw opResult;
		}

		this.loaded = true;
	};

	public canSaveConfiguration(config: Api.IAchievementConfiguration<Api.IGameTokenRewardConfiguration>) {
		return (
			super.canSaveConfiguration(config) && !!this.mAchievement?.achievementConfiguration?.rewardConfiguration?.gameId
		);
	}

	public setGame = async (
		game: SlotMachineGameViewModel,
		andEnable = this.mAchievement.achievementConfiguration?.enabled
	) => {
		if (this.busy) {
			return;
		}

		this.busy = true;
		const opResult = await this.mSetConfiguration({
			...this.configuration,
			achievementType: this.mAchievement.type,
			enabled: andEnable,
			rewardConfiguration: {
				...(this.configuration?.rewardConfiguration || {}),
				_type: 'GameTokenRewardConfiguration',
				// @ts-ignore
				gameId: game.id,
				gameType: Api.GameType.SlotMachine,
				rewardType: Api.AchievementRewardType.GameToken,
			},
		});
		await game.load();
		this.busy = false;

		if (!opResult.success) {
			throw opResult;
		}

		this.mGame = game;
		this.mAchievement = {
			...this.mAchievement,
			// @ts-ignore
			achievementConfiguration: opResult.value,
		};
	};

	public grantToken = async () => {
		const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync(
			this.composeApiUrl({ urlPath: `achievement/user-achievement` }),
			'POST',
			this.mAchievement.achievementConfiguration
		);

		if (!opResult.success) {
			throw opResult;
		}

		return opResult;
	};

	private initGame = () => {
		if (!this.gameId) {
			return;
		}
		this.mGame = new SlotMachineGameViewModel(this.mUserSession, {
			id: this.gameId,
		} as Api.ISlotMachine).impersonate(this.mImpersonationContext);
	};

	public impersonate(impersonationContext?: Api.IImpersonationContext) {
		super.impersonate(impersonationContext);
		this.mGame?.impersonate(impersonationContext);
		return this;
	}
}

export class SlotMachineAchievementsViewModel extends AchievementsViewModel<
	Api.IGameTokenRewardConfiguration,
	Api.IAchievementConfiguration<Api.IGameTokenRewardConfiguration>,
	SlotMachineAchievementViewModel
> {
	// @ts-ignore
	@observable.ref public selectedAchievement: SlotMachineAchievementViewModel;

	constructor(userSession: Api.UserSessionContext) {
		super(userSession);
	}

	@action
	public load = async () => {
		if (this.loading) {
			return;
		}

		this.loading = true;
		const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<
			Api.IAchievement<
				Api.IGameTokenRewardConfiguration,
				Api.IAchievementConfiguration<Api.IGameTokenRewardConfiguration>
			>[]
		>(this.composeApiUrl({ urlPath: 'achievement' }), 'GET');

		this.loading = false;

		if (!opResult.success) {
			throw opResult;
		}

		// @ts-ignore
		const achievements = opResult.value
			.filter(
				x =>
					!x.achievementConfiguration ||
					x.achievementConfiguration?.rewardConfiguration?.gameType === Api.GameType.SlotMachine
			)
			.map(x => this.createAchievement(x));
		await Promise.all(achievements.map(x => x.load()));
		this.mAchievements = achievements;
		this.loaded = true;
		return opResult;
	};

	protected createAchievement(
		model: Api.IAchievement<
			Api.IGameTokenRewardConfiguration,
			Api.IAchievementConfiguration<Api.IGameTokenRewardConfiguration>
		>
	) {
		return new SlotMachineAchievementViewModel(this.mUserSession, model).impersonate(this.impersonationContext);
	}
}

export class SlotMachineGameTokenViewModel extends Api.ViewModel {
	@observable.ref protected mGameToken: Api.IGameToken<Api.ISlotMachineSpin>;
	@observable.ref protected mGame: SlotMachineGameViewModel;

	constructor(
		userSession: Api.UserSessionContext,
		gameToken: Api.IGameToken<Api.ISlotMachineSpin>,
		game?: SlotMachineGameViewModel
	) {
		super(userSession);
		this.mGameToken = gameToken;
		this.mGame =
			game || new SlotMachineGameViewModel(this.mUserSession, { id: this.mGameToken.gameId } as Api.ISlotMachine);
	}

	@computed
	public get id() {
		return this.mGameToken?.id;
	}

	@computed
	public get game() {
		return this.mGame;
	}

	@computed
	public get gameName() {
		return this.mGameToken?.gameName;
	}

	@computed
	public get status() {
		return this.mGameToken?.status;
	}

	@computed
	public get gameOutcome() {
		return this.mGameToken?.gameOutcome;
	}

	@computed
	public get isLoaded() {
		return this.mGame?.isLoaded;
	}

	@action
	public load = () => {
		return this.mGame?.load();
	};

	public redeem = async () => {
		if (this.busy) {
			return;
		}

		this.busy = true;
		const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IGameToken<Api.ISlotMachineSpin>>(
			this.composeApiUrl({ queryParams: { token: this.id }, urlPath: `game/slot-machine/${this.game.id}/spin` }),
			'POST'
		);

		this.busy = false;
		if (!opResult.success) {
			throw opResult;
		}

		// @ts-ignore
		this.mGameToken = opResult.value;
		return opResult;
	};

	public toJs = () => this.mGameToken;
}

export class UserSlotMachinePrizesViewModel extends Api.ViewModel {
	// @ts-ignore
	@observable.ref mSlotMachines: { [key in Api.SlotMachineType]: SlotMachineViewModel };
	@observable.ref private mGameTokenPageCollectionController: Api.FilteredPageCollectionController<
		Api.IGameToken<Api.ISlotMachineSpin>,
		SlotMachineGameTokenViewModel,
		Api.IGameTokenFilter
	>;

	@observable.ref private mHistoryPageCollectionController: Api.FilteredPageCollectionController<
		Api.IPrizeLog<Api.IGameToken<Api.ISlotMachineSpin>>,
		Api.IPrizeLog<Api.IGameToken<Api.ISlotMachineSpin>>,
		Api.IGameTokenFilter
	>;
	// @ts-ignore
	protected mLoadingGames: Record<string, SlotMachineGameViewModel>;

	constructor(userSession: Api.UserSessionContext) {
		super(userSession);
		this.mCreateSlotMachineGameTokenViewModel = this.mCreateSlotMachineGameTokenViewModel.bind(this);

		this.mGameTokenPageCollectionController = new Api.FilteredPageCollectionController<
			Api.IGameToken<Api.ISlotMachineSpin>,
			SlotMachineGameTokenViewModel,
			Api.IGameTokenFilter
		>({
			apiPath: `game/token/filter`,
			client: userSession.webServiceHelper,
			transformer: this.mCreateSlotMachineGameTokenViewModel,
		});
		this.mHistoryPageCollectionController = new Api.FilteredPageCollectionController<
			Api.IPrizeLog<Api.IGameToken<Api.ISlotMachineSpin>>,
			Api.IPrizeLog<Api.IGameToken<Api.ISlotMachineSpin>>,
			Api.IGameTokenFilter
		>({
			apiPath: `game/prize-log/filter`,
			client: userSession.webServiceHelper,
			itemUniqueIdentifierPropertyPath: 'gameToken.id',
		});
	}

	@computed
	public get isBusy() {
		return (
			this.busy ||
			this.mGameTokenPageCollectionController.isFetching ||
			this.mHistoryPageCollectionController.isFetching
		);
	}

	@computed
	public get slotMachines() {
		return this.mSlotMachines;
	}

	@computed
	public get history() {
		return this.mHistoryPageCollectionController.fetchResults;
	}

	public load = async () => {
		if (this.loading) {
			return;
		}

		this.loading = true;
		this.mLoadingGames = {};

		// get tokens and history
		await Promise.all([this.getGameTokens(), this.getHistory()]);

		// load all of the game tokens
		await Promise.all(this.mGameTokenPageCollectionController.fetchResults?.map(x => x.load()));
		// @ts-ignore
		this.mLoadingGames = null;
		const gameTokens = this.mGameTokenPageCollectionController.fetchResults?.reduce(
			(res, x) => {
				const tokens = res[x.game.type] || [];
				tokens.push(x);
				res[x.game.type] = tokens;
				return res;
			},
			{} as { [key in Api.SlotMachineType]: SlotMachineGameTokenViewModel[] }
		);
		const machines = {} as { [key in Api.SlotMachineType]: SlotMachineViewModel };
		// @ts-ignore
		Object.keys(gameTokens).forEach((type: Api.SlotMachineType) => {
			machines[type] = new SlotMachineViewModel(this.mUserSession, type, gameTokens[type]);
		});
		this.mSlotMachines = machines;
		this.loading = false;
		this.loaded = true;
	};

	public getGameTokens = () => {
		return this.mGameTokenPageCollectionController.getNext(
			{
				criteria: [
					{
						property: Api.GameTokenFilterCriteriaProperty.GameType,
						value: Api.GameType.SlotMachine,
					},
					{
						property: Api.GameTokenFilterCriteriaProperty.Status,
						value: Api.GameTokenStatus.AvailableForUse,
					},
					{
						property: Api.GameTokenFilterCriteriaProperty.User,
						// @ts-ignore
						value: this.mUserSession.user.id,
					},
				],
				op: Api.FilterOperator.And,
			},
			100
		);
	};

	public getHistory = () => {
		return this.mHistoryPageCollectionController.getNext(
			{
				criteria: [
					{
						property: Api.GameTokenFilterCriteriaProperty.GameType,
						value: Api.GameType.SlotMachine,
					},
					{
						property: Api.GameTokenFilterCriteriaProperty.Status,
						value: Api.GameTokenStatus.Redeemed,
					},
					{
						property: Api.GameTokenFilterCriteriaProperty.User,
						// @ts-ignore
						value: this.mUserSession.user.id,
					},
				],
				op: Api.FilterOperator.And,
			},
			100
		);
	};

	protected mCreateSlotMachineGameTokenViewModel(tokenModel: Api.IGameToken<Api.ISlotMachineSpin>) {
		// reuse the same game for multiple tokens, if possible
		let game = this.mLoadingGames[tokenModel.gameId];
		if (!game) {
			game = new SlotMachineGameViewModel(this.mUserSession, { id: tokenModel.gameId } as Api.ISlotMachine);
			this.mLoadingGames[tokenModel.gameId] = game;
		}
		return new SlotMachineGameTokenViewModel(this.mUserSession, tokenModel, game);
	}
}

export class SlotMachineViewModel extends Api.ViewModel {
	@observable.ref protected mTokens: SlotMachineGameTokenViewModel[];
	// @ts-ignore
	@observable.ref protected mGame: SlotMachineGameViewModel;
	// @ts-ignore
	@observable.ref protected mReels: ISlotMachineReel[];
	// @ts-ignore
	@observable.ref protected mToken: SlotMachineGameTokenViewModel;
	@observable.ref protected mType: Api.SlotMachineType;

	constructor(userSession: Api.UserSessionContext, type: Api.SlotMachineType, tokens: SlotMachineGameTokenViewModel[]) {
		super(userSession);
		this.mType = type;
		this.mTokens = [...(tokens || [])];
		this.mSetToken(this.mTokens[0]);
	}

	@computed
	public get tokens() {
		return this.mTokens;
	}

	@computed
	public get type() {
		return this.mType;
	}

	/** Current token */
	@computed
	public get token() {
		return this.mToken;
	}

	/** Current game (related to this.token) */
	@computed
	public get game() {
		return this.mGame;
	}

	@computed
	public get reels() {
		return this.mReels;
	}

	/** Outcome for the current game/token */
	@computed
	public get spinOutcome() {
		return this.mToken?.gameOutcome;
	}

	/** Tokens without game outcomes. */
	@computed
	public get spinsRemaining() {
		return this.mTokens?.filter(x => !x.gameOutcome);
	}

	@action
	public nextToken = () => {
		const token = this.mTokens?.find(x => !x.gameOutcome);
		if (!token) {
			return;
		}
		this.mSetToken(token);
	};

	public spin = async () => {
		if (this.busy) {
			return;
		}

		this.busy = true;
		try {
			const opResult = await this.token.redeem();
			this.busy = false;
			return opResult;
		} catch (error) {
			this.busy = false;
			throw error;
		}
	};

	protected mSetToken = (token: SlotMachineGameTokenViewModel) => {
		this.mToken = token;
		this.mGame = token?.game;
		this.mReels = token?.game?.config?.reelConfiguration.map(reel => {
			return {
				id: uuidgen(),
				symbols: reel.reelSymbols.map((symbol, i) => ({ id: uuidgen(), index: i, value: symbol })),
			};
		});
	};
}
