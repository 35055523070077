import { StyleSheet } from 'aphrodite';
import { brandPrimary, titles } from '../../styles/colors';
import { BreakPoints, CssSelectors } from '../../styles/styles';

const avatarSize = 35;
export const styleSheet = StyleSheet.create({
	container: {
		position: 'relative',
		zIndex: 2,
	},
	chevron: {
		transition: 'all ease 300ms',
	},
	chevronUp: {
		transform: 'rotate3D(1, 0, 0, 180deg)',
	},
	dropdownAnchor: {
		alignItems: 'center',
		cursor: 'pointer',
		display: 'flex',
	},
	dropdownAnchorAvatar: {
		height: avatarSize,
		minWidth: avatarSize,
		width: avatarSize,
	},
	dropdownAnchorUserInfo: {
		marginLeft: 12,
		marginRight: 20,
		maxWidth: 200,
		[BreakPoints.tabletWidth]: {
			maxWidth: 100,
		},
	},
	dropdownAnchorUserInfoCompanyName: {
		color: '#858585',
		fontSize: 12,
	},
	dropdownAnchorUserInfoName: {
		color: '#4a4a4a',
		fontSize: 14,
	},
	dropdownContainer: {
		background: '#fff',
		border: '1px solid #BABABA',
		borderRadius: 3,
		boxShadow: '0 2px 6px 0 rgba(0,0,0,0.20)',
		color: titles,
		fontSize: 14,
		marginTop: 5,
		minWidth: 182,
		padding: '0 5px 5px 5px',
	},
	dropdownMenuItem: {
		':not(:first-child)': {
			margin: 0,
		},
		cursor: 'pointer',
		padding: '15px 30px',
		whiteSpace: 'nowrap',
	},
	dropdownMenuItemInvite: {
		...CssSelectors.allChildren(
			{
				border: `1px solid ${brandPrimary}`,
				borderRadius: 3,
				color: brandPrimary,
				fontSize: 12,
				fontWeight: 600,
				letterSpacing: '0px',
				padding: '5px 14px',
			},
			'.nav-bar-link-name'
		),
	},
});
