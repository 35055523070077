import { brandPrimary } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	button: {
		alignItems: 'center',
		color: brandPrimary,
		display: 'flex',
		fontSize: 14,
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	container: {
		...CssSelectors.allChildren(
			{
				marginTop: 20,
			},
			':not(:first-child)'
		),
	},
	listItem: {
		alignItems: 'center',
		display: 'flex',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	listItemIcon: {
		alignItems: 'center',
		display: 'flex',
		flexShrink: 0,
		height: 16,
		justifyContent: 'center',
		width: 16,
	},
	listItemTextField: {
		flexGrow: 1,
	},
	listItemTextInput: {
		height: 60,
		resize: 'none',
		width: '100%',
	},
});
