import { ActionItemAttachmentViewModel, IRichContentEditorState, IUser } from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { DefaultEditableActionItemContentCSS, SizeConstraint } from '../../../../models';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import { baseStyleSheet } from '../../../styles/styles';
import { DeprecatedCloseButton } from '../../DeprecatedCloseButton';
import { LoadingSpinner } from '../../LoadingSpinner';
import {
	IRichContentDocumentEditorConfig,
	RichContentDocumentEditor,
} from '../../richContent/RichContentDocumentEditor';
import { ActionItemCard } from '../ActionItemCard';
import { AssignDueDateButton } from '../AssignDueDateButton';
import { AssigneeButton } from '../AssigneeButton';
import { styleSheet } from './styles';

interface IProps extends IEventLoggingComponentProps, IUserSessionComponentProps {
	actionItemAttachment: ActionItemAttachmentViewModel;
	canToggleCompleted?: boolean;
	className?: string;
	onClick?(e: React.MouseEvent<HTMLElement>): void;
	onRequestRemove?(): void;
	onSendMessageClicked?(e: React.MouseEvent<HTMLElement>): void;
	readonly?: boolean;
	sendMessageDisabled?: boolean;
	sizeConstraint?: SizeConstraint;
}

interface IState {
	editorContentState?: IRichContentEditorState;
	hoveringOverInputs?: boolean;
}

const DefaultEditorConfig: IRichContentDocumentEditorConfig = {
	autoresizeContentBottomPadding: 0,
	autoresizeToFitContent: true,
	contentHorizontalPadding: 0,
	contentRawCss: DefaultEditableActionItemContentCSS,
	plugins: [], // none
	toolbar: false,
};

class _EmbeddedActionItem extends React.Component<IProps, IState> {
	public readonly state: IState = {};

	public UNSAFE_componentWillMount() {
		const { actionItemAttachment } = this.props;
		this.setState({
			editorContentState: actionItemAttachment.contentState,
		});
	}

	public render() {
		const {
			actionItemAttachment,
			canToggleCompleted,
			className,
			onClick,
			onRequestRemove,
			readonly,
			sendMessageDisabled,
			sizeConstraint,
		} = this.props;
		const { editorContentState } = this.state;
		return (
			<div
				className={`${css(
					styleSheet.container,
					!readonly ? styleSheet.containerWithRemoveButton : null
				)} embedded-action-item ${className || ''}`}
				onClick={onClick}
			>
				<ActionItemCard
					actionItem={actionItemAttachment.actionItem}
					assignee={actionItemAttachment.assignee}
					canToggleCompleted={canToggleCompleted}
					className={css(styleSheet.card)}
					dueDate={actionItemAttachment.dueDate}
					onRemoveAssigneeClicked={this.onRemoveAssigneeClicked}
					onRemoveDueDateClicked={this.onRemoveDueDateClicked}
					readonly={readonly}
					sizeConstraint={sizeConstraint}
				>
					<div className={css(styleSheet.content)}>
						<div
							className={`${css(
								styleSheet.info,
								readonly ? styleSheet.infoReadOnly : null
							)} embedded-action-item-content`}
						>
							<RichContentDocumentEditor
								autoFocus={!actionItemAttachment.actionItem.id}
								className={css(styleSheet.editor)}
								config={DefaultEditorConfig}
								contentPlaceholderText='Type action item...'
								contentState={editorContentState}
								disablePasteRichText={true}
								loadingPlaceholder={
									<LoadingSpinner className={`absolute-center ${css(styleSheet.loading)}`} type='small' />
								}
								onContentStateChanged={this.onContentChanged}
								readOnly={readonly}
							/>
						</div>
						{!readonly && this.renderRightAccessory()}
					</div>
					{!sendMessageDisabled && actionItemAttachment.actionItem.referencedContactsForSendMessage.length > 0 && (
						<button
							className={css(baseStyleSheet.ctaButtonSmall, styleSheet.sendButton)}
							onClick={this.onSendMessageClicked}
						>
							<span>Send Email</span>
						</button>
					)}
				</ActionItemCard>
				{!readonly && <DeprecatedCloseButton className={css(styleSheet.removeButton)} onClick={onRequestRemove} />}
			</div>
		);
	}

	private renderRightAccessory() {
		const { actionItemAttachment, readonly, userSession } = this.props;
		return (
			<div className={css(styleSheet.rightAccessory)}>
				<AssignDueDateButton
					dueDate={actionItemAttachment.dueDate}
					onDaySelected={this.onDueDateChanged}
					readOnly={readonly}
				/>
				<AssigneeButton
					assignee={actionItemAttachment.assignee}
					onAssigneeSelected={this.onAssigneeChanged}
					readOnly={readonly}
					userSession={userSession}
				/>
			</div>
		);
	}

	private onSendMessageClicked = (e: React.MouseEvent<HTMLElement>) => {
		const { logInput, onSendMessageClicked } = this.props;
		// @ts-ignore
		logInput('SendMessage', 'Click');
		if (onSendMessageClicked) {
			onSendMessageClicked(e);
		}
	};

	private onRemoveDueDateClicked = () => {
		// @ts-ignore
		this.onDueDateChanged(null);
	};

	private onRemoveAssigneeClicked = () => {
		// @ts-ignore
		this.onAssigneeChanged(null);
	};

	private onContentChanged = (contentState: IRichContentEditorState) => {
		this.setState(
			{
				editorContentState: contentState,
			},
			() => {
				this.props.actionItemAttachment.contentState = contentState;
			}
		);
	};

	private onDueDateChanged = (dueDate: Date) => {
		this.props.actionItemAttachment.dueDate = dueDate;
	};

	private onAssigneeChanged = (assignee: IUser) => {
		this.props.actionItemAttachment.assignee = assignee;
	};
}

const EmbeddedActionItemAsObserver = observer(_EmbeddedActionItem);
export const EmbeddedActionItemWithContext = inject(UserSessionViewModelKey)(EmbeddedActionItemAsObserver);
export const EmbeddedActionItem = withEventLogging(EmbeddedActionItemWithContext, 'EmbeddedActionItem');
