import { IAccount, IOperationResultNoValue, IUser } from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IModalContext, ModalChildComponentContextKey } from '../../../models';
import {
	IToasterComponentProps,
	IUserSessionComponentProps,
	ToasterViewModelKey,
	UserSessionViewModelKey,
} from '../../../models/AppState';
import { EventLogger } from '../../../models/Logging';
import { baseStyleSheet } from '../../styles/styles';
import { DeprecatedCloseButton } from '../DeprecatedCloseButton';
import { asModalComponent } from '../Modal';
import { InviteEmployeesGraphic } from '../svgs/graphics/InviteEmployeesGraphic';
import './styles.less';

interface IProps extends IToasterComponentProps, IModalContext, IUserSessionComponentProps {
	account: IAccount;
}

interface IState {
	emailAddress?: string;
	errorMessage?: string;
	showConfirmation?: boolean;
	showLoader?: boolean;
}

class _InviteEmployees extends React.Component<IProps, IState> {
	public state: IState = {
		// @ts-ignore
		emailAddress: null,
		// @ts-ignore
		errorMessage: null,
		showConfirmation: false,
		showLoader: false,
	};

	public componentDidMount() {
		EventLogger.logEvent({ action: 'Load', category: 'Invite-Employees' });
	}

	public render() {
		return (
			<div className='settings-inviteEmployees-container'>
				<div className='settings-inviteEmployees-container-header'>
					<DeprecatedCloseButton onClick={this.closeButtonClick} />
				</div>
				<div>
					<figure className='settings-inviteEmployees-graphic'>
						<InviteEmployeesGraphic />
					</figure>

					<div className='settings-inviteEmployeees-headline'>Invite Employee</div>
					<div className='settings-inviteEmployees-subHeadline'>
						Enter the email address of an employee in the box below to send them an invite:
					</div>
					<div className='settings-inviteEmployees-linkContainer'>
						<div
							className={
								!!this.state.errorMessage && this.state.errorMessage.length > 0
									? 'settings-inviteEmployees-errorContainer'
									: 'hide'
							}
						>
							{this.state.errorMessage || ''}
						</div>
						<div className={this.state.showLoader ? 'loaderSmall' : 'hide'} />
						<div
							className={
								!this.state.showLoader && !this.state.showConfirmation
									? 'settings-inviteEmployees-formContainer'
									: 'hide'
							}
						>
							<div className='contactInfoFieldContainerStandard'>
								<span className='settings-inviteEmployees-formLabel'>Email:</span>
								<input
									type='text'
									id='addEditContactLastName'
									className={css(baseStyleSheet.textFieldStandard)}
									value={this.state.emailAddress || ''}
									onChange={this.updateFormFieldValue}
									autoFocus={true}
								/>
							</div>
							<div className='settings-inviteEmployees-buttonContainer'>
								<span className={css(baseStyleSheet.ctaButton)} onClick={this.sendInviteClick}>
									Send Invite
								</span>
							</div>
						</div>
						<div
							className={
								!this.state.showLoader && this.state.showConfirmation
									? 'settings-inviteEmployees-confirmationContainer'
									: 'hide'
							}
						>
							<div>
								Success! We&apos;ve sent an email to {this.state.emailAddress}
								<br />
								to finish the setup.
							</div>
							<div className='settings-inviteEmployees-buttonContainer'>
								<span className={css(baseStyleSheet.ctaButton)} onClick={this.sendAnotherInviteClick}>
									Invite Another User
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	private closeButtonClick = () => {
		if (this.props.parentModal) {
			this.props.parentModal.onRequestClose(null, true);
		}
	};

	private sendInviteClick = () => {
		// Make sure email field is filled out
		if (!this.state.emailAddress || this.state.emailAddress.length < 1 || this.state.emailAddress.indexOf('@') === -1) {
			this.setState({ errorMessage: 'You must enter a valid email address.' });
		} else {
			// @ts-ignore
			this.setState({ errorMessage: null, showLoader: true });

			// Call a web service to initiate create of the user
			// @ts-ignore
			this.props.userSession.webServiceHelper.callWebServiceWithOperationResults<IUser>(
				'user/initiate-create?sendWelcomeEmail=true', // URL
				'POST', // Method
				{
					// @ts-ignore
					// @ts-ignore
					accountId: this.props.userSession.user.accountId,
					email: this.state.emailAddress,
				}, // Body (null if it's a GET operation)
				this.sendInviteSuccess,
				this.sendInviteError
			);
		}
	};

	private sendInviteSuccess = () => {
		this.setState({
			// @ts-ignore
			errorMessage: null,
			showConfirmation: true,
			showLoader: false,
		});

		EventLogger.logEvent({
			action: 'Send-Invite',
			category: 'Invite-Employees',
		});
	};

	private sendInviteError = (error: IOperationResultNoValue) => {
		// @ts-ignore
		const errorMessage: string = error.systemMessage;

		this.setState({ errorMessage, showConfirmation: false, showLoader: false });

		EventLogger.logEvent(
			{
				action: 'Send-Invite-Error',
				category: 'Invite-Employees',
			},
			{ ...error }
		);
	};

	private sendAnotherInviteClick = () => {
		this.setState({
			// @ts-ignore
			emailAddress: null,
			// @ts-ignore
			errorMessage: null,
			showConfirmation: false,
		});

		EventLogger.logEvent({
			action: 'Send-Another-Invite-Click',
			category: 'Invite-Employees',
		});
	};

	private updateFormFieldValue = (e: any) => {
		const emailAddress: string = e.target.value;
		this.setState({ emailAddress });
	};
}

const InviteEmployeesAsObserver = observer(_InviteEmployees);
export const InviteEmployees = inject(
	ToasterViewModelKey,
	UserSessionViewModelKey,
	ModalChildComponentContextKey
)(InviteEmployeesAsObserver);
export const InviteEmployeesModal = asModalComponent(InviteEmployees);
