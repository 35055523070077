import { css } from 'aphrodite';
import * as React from 'react';
import { styleSheet } from './styles';
import { baseStyleSheet } from '../../styles/styles';
import { CloseButton } from '../CloseButton';
import { LoadingSpinner } from '../LoadingSpinner';
import { Modal } from '../Modal';

export const DeleteConfirmation = ({
	onFinish,
	bodyText,
	destructiveButtonText,
	isOpen,
	showSpinner,
	title,
}: {
	onFinish(shouldDelete: boolean): void;
	bodyText?: React.ReactNode;
	destructiveButtonText?: string;
	isOpen?: boolean;
	showSpinner?: boolean;
	title?: string;
}) => {
	const confirmDelete = () => {
		onFinish(true);
	};

	const onClose = () => {
		onFinish(false);
	};

	return (
		<Modal isOpen={isOpen} onRequestClose={onClose}>
			<div>
				<div className={css(styleSheet.closeButtonContainer)}>
					<CloseButton onClick={onClose} />
				</div>
				<div className={css(styleSheet.graphic)} />
				<h2 className={css(styleSheet.title)}>{title ?? 'Confirmation'}</h2>
				<div className={css(styleSheet.body)}>
					{bodyText ?? 'Are you sure you want to delete? This operation cannot be reversed.'}
				</div>
				<footer className={css(styleSheet.buttonContainer)}>
					{showSpinner ? (
						<LoadingSpinner type='small' />
					) : (
						<>
							<button className={css(baseStyleSheet.ctaButtonDestructiveSolid)} onClick={confirmDelete}>
								{destructiveButtonText || 'Delete'}
							</button>
							<button className={css(baseStyleSheet.ctaButtonReverse)} onClick={onClose}>
								Cancel
							</button>
						</>
					)}
				</footer>
			</div>
		</Modal>
	);
};
