import { urgentDate, warningDark } from '../../../styles/colors';
import { DangerIcon } from '../../svgs/icons/DangerIcon';
import { styleSheet } from './styles';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { Spring } from 'react-spring-legacy/renderprops';

interface IProps {
	animate?: boolean;
	children: React.ReactNode;
	iconBackgroundColor?: string;
	iconBorderColor?: string;
	iconColor?: string;
	styles?: StyleDeclarationValue[];
	type?: 'warning' | 'error';
}

export const DangerMessage = ({
	animate = true,
	children,
	iconBackgroundColor,
	iconBorderColor,
	iconColor,
	styles,
	type = 'warning',
}: IProps) => {
	const renderMessage = (props?: { opacity: number }) => {
		return (
			<div
				className={`${css(
					styleSheet.container,
					type === 'error' ? styleSheet.errorMessage : styleSheet.warningMessage,
					...(styles || [])
				)} notification-message`}
				style={props as React.CSSProperties}
			>
				<div className={css(styleSheet.icon)}>
					<DangerIcon
						borderColor={iconBorderColor ?? '#fff'}
						backgroundColor={iconBackgroundColor ?? (type === 'error' ? urgentDate : warningDark)}
						foregroundColor={iconColor}
					/>
				</div>
				<div className={css(styleSheet.content)}>{children}</div>
			</div>
		);
	};

	return animate ? (
		<Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
			{renderMessage}
		</Spring>
	) : (
		renderMessage()
	);
};
