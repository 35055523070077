import { StyleSheet } from 'aphrodite';
import { brandPrimaryHover, mentionDark, navigation, toggleOff } from '../../../styles/colors';
import { CssSelectors, Layouts } from '../../../styles/styles';

const itemSpacing = 10;

export const styleSheet = StyleSheet.create({
	container: {},
	content: {
		display: 'flex',
		flexWrap: 'wrap',
		margin: `-${itemSpacing}px 0 0 -${itemSpacing}px`,
	},
	error: {
		color: toggleOff,
		fontSize: 12,
		marginBottom: 6,
	},
	fileSize: {
		color: navigation,
		flexShrink: 0,
	},
	item: {
		...Layouts.horizontalStack(4),
		alignItems: 'center',
		background: '#EDEDED',
		boxSizing: 'border-box',
		color: brandPrimaryHover,
		display: 'inline-flex',
		fontSize: 12,
		margin: `${itemSpacing}px 0 0 ${itemSpacing}px`,
		maxWidth: '80%',
		padding: '4px 10px',
		...CssSelectors.allChildren(
			{
				marginLeft: 6,
			},
			':last-child'
		),
	},
	itemError: {
		background: 'rgba(208,2,27,0.10)',
	},
	itemPending: {
		background: mentionDark,
	},
	itemRemove: {
		height: 'auto',
		width: 'auto',
	},
});
