import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

export const PostcardPostage = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<SvgIcon width='49' height='61' viewBox='0 0 49 61' fill='none' {...props}>
			<g fill='none' fillRule='evenodd' stroke='#D3DBDE' strokeWidth='2' transform='translate(1 1.874)'>
				<path fill='#FFF' d='M8 8h30v41H8z' />
				<path
					strokeLinecap='round'
					strokeLinejoin='round'
					d='M45.324 56.8c-1.615 0-2.924-1.611-2.924-3.6 0-1.987 1.309-3.598 2.924-3.598-1.615 0-2.924-1.612-2.924-3.6 0-1.987 1.309-3.599 2.924-3.599-1.615 0-2.924-1.611-2.924-3.6 0-1.987 1.309-3.599 2.924-3.599-1.615 0-2.924-1.611-2.924-3.599 0-1.988 1.309-3.6 2.924-3.6-1.615 0-2.924-1.61-2.924-3.598 0-1.988 1.309-3.6 2.924-3.6-1.615 0-2.924-1.611-2.924-3.599 0-1.988 1.309-3.6 2.924-3.6-1.615 0-2.924-1.61-2.924-3.599 0-1.987 1.309-3.599 2.924-3.599 1.48 0 .22-2.028-.827-.74-.952 1.172-2.495 1.172-3.446 0-.952-1.17-.952-3.07 0-4.24 1.046-1.29-.113-2.241-.113-.419 0 1.988-1.31 3.6-2.925 3.6-1.615 0-2.924-1.612-2.924-3.6 0 1.988-1.309 3.6-2.924 3.6s-2.924-1.612-2.924-3.6c0 1.988-1.31 3.6-2.924 3.6-1.615 0-2.924-1.612-2.924-3.6 0 1.988-1.31 3.6-2.924 3.6-1.615 0-2.924-1.612-2.924-3.6 0 1.988-1.31 3.6-2.924 3.6-1.615 0-2.925-1.612-2.925-3.6 0 1.988-1.309 3.6-2.924 3.6s-2.924-1.612-2.924-3.6c0 1.988-1.503 4.193-2.924 3.6C1.504 4.016 1.03 3.32.676 2.653.324 1.99.066.472.914.817 2.449 1.44 3.6 2.81 3.6 4.799c0 1.988-1.309 3.6-2.924 3.6 1.615 0 2.924 1.61 2.924 3.599 0 1.987-1.309 3.599-2.924 3.599 1.615 0 2.924 1.611 2.924 3.6 0 1.987-1.309 3.599-2.924 3.599 1.615 0 2.924 1.611 2.924 3.599 0 1.988-1.309 3.6-2.924 3.6 1.615 0 2.924 1.61 2.924 3.598 0 1.988-1.309 3.6-2.924 3.6 1.615 0 2.924 1.611 2.924 3.599 0 1.988-1.309 3.6-2.924 3.6 1.615 0 2.924 1.61 2.924 3.599 0 1.987-1.309 3.599-2.924 3.599-1.48 0-.22 2.028.827.74.952-1.172 2.495-1.172 3.446 0 .952 1.17.952 3.07 0 4.24-1.046 1.29.113 2.241.113.419 0-1.988 1.31-3.6 2.925-3.6 1.615 0 2.924 1.612 2.924 3.6 0-1.988 1.309-3.6 2.924-3.6s2.924 1.612 2.924 3.6c0-1.988 1.31-3.6 2.924-3.6 1.615 0 2.924 1.612 2.924 3.6 0-1.988 1.31-3.6 2.924-3.6 1.615 0 2.924 1.612 2.924 3.6 0-1.988 1.31-3.6 2.924-3.6 1.615 0 2.925 1.612 2.925 3.6 0-1.988 1.309-3.6 2.924-3.6s2.924 1.612 2.924 3.6c0-1.988 1.309-3.6 2.924-3.6S46 55.002 46 56.99'
				/>
				<circle cx='26.5' cy='17.5' r='4.5' fill='#FFF' />
				<path fill='#D3DBDE' fillOpacity='.402' d='M8 33.532 17.517 24l9.517 9.532 6.546-6.555L38 31.404V49H8' />
			</g>
		</SvgIcon>
	);
};
