import {
	AutoCompleteResultFilter,
	IEntity,
	IUser,
	ResourceAutoCompleteViewModel,
	ResourceAutoCompleteViewModelType,
} from '@ViewModels';
import { ClearFieldIcon } from '@WebComponents/svgs/icons/ClearFieldIcon';
import { SearchIcon } from '@WebComponents/svgs/icons/SearchIcon';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { v4 as uuidgen } from 'uuid';
import { bs } from '../../../styles/styles';
import {
	AutoCompleteSearchField,
	AutoCompleteSearchFieldAccessoryViewMode,
	IAutoCompleteSearchFieldComponent,
} from '../AutoCompleteSearchField';
import { styleSheet } from './styles';

export interface ISimpleAutoCompleteSearchFieldEvent<
	TSourceEvent extends Event | React.SyntheticEvent = Event | React.SyntheticEvent,
> {
	autocompleteType: ResourceAutoCompleteViewModelType;
	sourceEvent?: TSourceEvent;
	target?: IAutoCompleteSearchFieldComponent;
}

export interface ISimpleAutoCompleteSearchFieldItemSelectionEvent<TItem = IEntity | string | IUser>
	extends ISimpleAutoCompleteSearchFieldEvent {
	selection: TItem;
}

export type SimpleAutoCompleteSearchFieldEventHandler<
	TSourceEvent extends Event | React.SyntheticEvent = Event | React.SyntheticEvent,
	TEvent extends ISimpleAutoCompleteSearchFieldEvent<TSourceEvent> = ISimpleAutoCompleteSearchFieldEvent<TSourceEvent>,
> = (e: TEvent) => void;

interface IProps<TItem = IEntity | string | IUser> {
	disableAutocomplete?: boolean;
	disabled?: boolean;
	dropdownContentItemsStyles?: StyleDeclarationValue[];
	dropdownContentStyle?: StyleDeclarationValue;
	dropdownItemClassName?: string;
	dropdownStyle?: StyleDeclarationValue;
	id?: string;
	initialSearchQuery?: string;
	leftAccessory?: React.ReactNode;
	onBlur?: SimpleAutoCompleteSearchFieldEventHandler<React.FocusEvent<HTMLInputElement>>;
	onChange?: SimpleAutoCompleteSearchFieldEventHandler<React.ChangeEvent<HTMLInputElement>>;
	onClear?: SimpleAutoCompleteSearchFieldEventHandler<React.MouseEvent<HTMLElement>>;
	onCreateAutoCompleteViewModel?(
		type: ResourceAutoCompleteViewModelType,
		suggestedViewModel: ResourceAutoCompleteViewModel,
		autoCompleteFilter?: AutoCompleteResultFilter<any>
	): ResourceAutoCompleteViewModel;
	onFocus?: SimpleAutoCompleteSearchFieldEventHandler<React.FocusEvent<HTMLInputElement>>;
	onInputRef?: React.Ref<HTMLInputElement>;
	onItemSelected?: SimpleAutoCompleteSearchFieldEventHandler<
		any,
		ISimpleAutoCompleteSearchFieldItemSelectionEvent<TItem>
	>;
	onKeyDown?: SimpleAutoCompleteSearchFieldEventHandler<React.KeyboardEvent<HTMLInputElement>>;
	onOpenChanged?(isOpen: boolean): void;
	onRenderAnchorContent?(
		inputElement: React.ReactElement<any>,
		inputHasFocus: boolean,
		leftAccessory?: React.ReactNode,
		rightAccessory?: React.ReactNode
	): React.ReactNode;
	onRenderResultsFooter?(
		searchQuery: string,
		renderedItems: any[],
		totalCount: number,
		overflowItems?: any[]
	): React.ReactNode;
	onRenderRightAccessory?(callbacks: {
		onClearButtonClicked: (e?: React.MouseEvent<HTMLElement>) => void;
	}): React.ReactNode;
	onSearchFieldRef?(ref?: IAutoCompleteSearchFieldComponent): void;
	pageSize?: number;
	placeholder?: string;
	renderNoResultsItem?: boolean;
	resultsLimit?: number;
	style?: StyleDeclarationValue | StyleDeclarationValue[];
	type: ResourceAutoCompleteViewModelType;
	rightAccessoryViewMode?: AutoCompleteSearchFieldAccessoryViewMode;
}

export class SimpleAutoCompleteSearchField extends React.PureComponent<IProps> {
	private mId = uuidgen();
	// @ts-ignore
	private mSearchFieldRef: IAutoCompleteSearchFieldComponent;
	public render() {
		const {
			style,
			dropdownStyle,
			disableAutocomplete,
			disabled,
			dropdownContentStyle,
			id,
			leftAccessory,
			onBlur,
			onChange,
			onCreateAutoCompleteViewModel,
			onFocus,
			onItemSelected,
			onKeyDown,
			onOpenChanged,
			pageSize,
			resultsLimit,
			...restProps
		} = this.props;
		return (
			<AutoCompleteSearchField
				disableAutocomplete={!!disabled || !!disableAutocomplete}
				className={css(styleSheet.searchField, ...(Array.isArray(style) ? style : [style]))}
				dropdownClassName={css(styleSheet.searchFieldDropDown, dropdownStyle)}
				dropdownContentClassName={css(styleSheet.searchFieldDropDownContent, dropdownContentStyle)}
				inputId={id || this.mId}
				inputProps={{
					disabled,
					onBlur: this.executeWrappedInputCallback('onBlur'),
					onChange: this.executeWrappedInputCallback('onChange'),
					onFocus: this.executeWrappedInputCallback('onFocus'),
					onKeyDown: this.executeWrappedInputCallback('onKeyDown'),
				}}
				leftAccessory={leftAccessory || <SearchIcon className={css(styleSheet.searchFieldIcon)} />}
				onCreateAutoCompleteViewModel={onCreateAutoCompleteViewModel}
				onInnerRef={this.onSearchFieldRef}
				onItemSelected={this.onItemSelected}
				onOpenChanged={onOpenChanged}
				pageSize={pageSize}
				resultsLimit={resultsLimit}
				rightAccessory={this.renderSearchFieldRightAccessory()}
				rightAccessoryViewMode={AutoCompleteSearchFieldAccessoryViewMode.WhileEditing}
				{...restProps}
			/>
		);
	}

	private renderSearchFieldRightAccessory() {
		const { onRenderRightAccessory } = this.props;
		if (onRenderRightAccessory) {
			return onRenderRightAccessory({
				onClearButtonClicked: this.onClearButtonClicked,
			});
		}
		return (
			<button onMouseDown={this.onClearButtonClicked} className={css(bs.ml2)}>
				<ClearFieldIcon
					className={`autocomplete-search-field-clear-button-icon ${css(styleSheet.searchFieldClearIcon)}`}
				/>
			</button>
		);
	}

	private onSearchFieldRef = (ref?: IAutoCompleteSearchFieldComponent) => {
		// @ts-ignore
		this.mSearchFieldRef = ref;
		const { onSearchFieldRef } = this.props;
		if (onSearchFieldRef) {
			onSearchFieldRef(ref);
		}
	};

	private executeWrappedInputCallback =
		(callbackName: keyof IProps) =>
		<T extends React.SyntheticEvent | Event>(sourceEvent: T) => {
			const { type: autocompleteType } = this.props;
			const callback: SimpleAutoCompleteSearchFieldEventHandler<T> = this.props[
				callbackName
			] as SimpleAutoCompleteSearchFieldEventHandler<T>;
			if (callback) {
				callback({
					autocompleteType,
					sourceEvent,
					target: this.mSearchFieldRef,
				});
			}
		};

	private onClearButtonClicked = (sourceEvent?: React.MouseEvent<HTMLElement>) => {
		// @ts-ignore
		this.executeWrappedInputCallback('onClear')(sourceEvent);

		if (!sourceEvent || (!!sourceEvent && !sourceEvent.defaultPrevented)) {
			if (sourceEvent) {
				sourceEvent.stopPropagation();
				sourceEvent.preventDefault();
			}
			if (this.mSearchFieldRef) {
				this.mSearchFieldRef.clearInput();
			}
		}
	};

	private onItemSelected = (
		selection: IEntity | string | IUser,
		autocompleteType: ResourceAutoCompleteViewModelType
	) => {
		const { onItemSelected } = this.props;
		if (onItemSelected) {
			onItemSelected({
				autocompleteType,
				selection,
				target: this.mSearchFieldRef,
			});
		}
	};
}
