import { css } from 'aphrodite';
import * as React from 'react';
import { withRouter } from 'react-router';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { NavIcon, NavIconType } from '../svgs/icons/NavIcon';
import { styleSheet } from './styles';

export interface INavBarLink {
	icon?: React.ReactNode | ((isActive: boolean) => React.ReactNode);
	linkName?: string;
	/** Accepts either a path as a string or a list of paths as strings the first one will be used as the link path */
	path?: string | string[];
}

interface IProps extends INavBarLink {
	accessory?: JSX.Element;
	className?: string;
	compactLayout?: boolean;
	onLinkClick?(e: React.MouseEvent<HTMLElement>): void;
}

interface IState {
	isActive?: boolean;
}

const outlinedLinkNames = new Set<string>([NavIconType.Surveys, NavIconType.ScheduleMeeting]);

class _NavBarLink extends React.Component<IProps & RouteComponentProps<any>, IState> {
	public state: IState = {
		isActive: false,
	};

	public componentDidMount() {
		// @ts-ignore
		this.setState({ isActive: this.getActiveStatus(this.props.path) });
	}

	public componentDidUpdate(prevProps: IProps & RouteComponentProps<any>) {
		const { path, location } = this.props;
		if (prevProps.location.pathname !== location.pathname) {
			// @ts-ignore
			this.setState({ isActive: this.getActiveStatus(path) });
		}
	}

	public render() {
		const { accessory, compactLayout, location, linkName, className, path, icon } = this.props;
		const { isActive } = this.state;
		// @ts-ignore
		const outlinedLinkName = outlinedLinkNames.has(linkName);
		return (
			<NavLink
				activeClassName={`${css(styleSheet.linkActive)}`}
				className={`${css(styleSheet.link)} ${className || ''}`}
				location={location}
				onClick={this.linkClick}
				onMouseEnter={this.setActive}
				onMouseLeave={this.setInactive}
				title={linkName ? linkName : undefined}
				// @ts-ignore
				to={typeof path === 'object' ? path[0] : path}
			>
				{!!linkName && (
					<div className={css(styleSheet.icon)}>
						{icon ? (
							typeof icon === 'function' ? (
								// @ts-ignore
								icon(isActive)
							) : (
								icon
							)
						) : (
							<NavIcon isActive={isActive} iconName={linkName} />
						)}
					</div>
				)}
				{!compactLayout && (
					<span className={css(styleSheet.linkName, outlinedLinkName && styleSheet.linkNameOutlined)}>{linkName}</span>
				)}
				{!!accessory && (
					<div className={css(compactLayout ? styleSheet.compactFlag : styleSheet.flag)}>{accessory}</div>
				)}
			</NavLink>
		);
	}

	private setActive = () => {
		this.setState({ isActive: true });
	};

	private setInactive = () => {
		// Set inactive, unless this is the currently active path
		// @ts-ignore
		this.setState({ isActive: this.getActiveStatus(this.props.path) });
	};

	private linkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
		if (this.props.onLinkClick) {
			this.props.onLinkClick(e);
		}
	};

	private isActivePath = (pathToCheck: string) => {
		return new RegExp(`^${pathToCheck}`).test(this.props.location.pathname);
	};

	private getActiveStatus = (path: string | string[]): boolean => {
		return typeof path === 'object' ? path.some(x => this.isActivePath(x)) : this.isActivePath(path);
	};
}

export const NavBarLink = withRouter(_NavBarLink);
