import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ErrorMessageViewModel } from '../../../../viewmodels/AppViewModels';
import { ErrorMessageModal } from '../ErrorMessageModal';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	errorMessageViewModel: ErrorMessageViewModel;
	modalContentStyles?: StyleDeclarationValue[];
	styles?: StyleDeclarationValue[];
}

class _ErrorMessagesProvider extends React.Component<IProps> {
	public render() {
		const { className, errorMessageViewModel, styles, modalContentStyles } = this.props;
		return (
			<ErrorMessageModal
				modalProps={{
					className: `${css(styleSheet.modal, ...(styles || []))} ${className || ''}`,
					isOpen: !!errorMessageViewModel.currentErrorMessage,
					onRequestClose: this.onClose,
				}}
				styles={[styleSheet.modalContent, ...(modalContentStyles || [])]}
				title={errorMessageViewModel.currentErrorMessage?.title || 'Error'}
			>
				{!!errorMessageViewModel.currentErrorMessage && (
					<div className={css(styleSheet.messages)}>
						{/* @ts-ignore */}
						{errorMessageViewModel.currentErrorMessage.messages.map((msg, i) => {
							return (
								<div className={css(styleSheet.message)} key={i}>
									{msg}
								</div>
							);
						})}
					</div>
				)}
			</ErrorMessageModal>
		);
	}

	private onClose = () => {
		const { errorMessageViewModel } = this.props;
		if (errorMessageViewModel.currentErrorMessage?.onClose) {
			errorMessageViewModel.currentErrorMessage.onClose();
		}
		errorMessageViewModel.dismiss();
	};
}

export const ErrorMessagesProvider = observer(_ErrorMessagesProvider);
