import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../models/AppState';
import { getPrincipalInitials } from '../../../models/UiUtils';
import { EntityViewModel } from '../../../viewmodels/AppViewModels';
import KitIndicatorIconUrl from '../../assets/icon_keepintouch.svg';
import { baseStyleSheet } from '../../styles/styles';
import { AssigneeIcon } from '../svgs/icons/AssigneeIcon';
import { styleSheet } from './styles';
import { ICompany, IEntity, IPrincipal, getTypeForEntity } from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';

export interface IAvatarProps extends IUserSessionComponentProps {
	avatarImageUrl?: string;
	children?: React.ReactChild;
	className?: string;
	entity?: IEntity;
	entityVm?: EntityViewModel<IEntity>;
	hasKeepInTouch?: boolean;
	onClick?(e: React.MouseEvent<HTMLElement>): void;
	style?: React.CSSProperties;
	user?: IPrincipal;
	icon?: React.ReactNode;
}

interface IState {
	avatarImageUrl?: string;
	entity?: IEntity;
	entityVm?: EntityViewModel<IEntity>;
	failedAvatarUrl?: string;
	imageLoaded?: boolean;
	user?: IPrincipal;
}

class _Avatar extends React.Component<IAvatarProps, IState> {
	public state: IState = {
		avatarImageUrl: this.props.avatarImageUrl,
		entity: this.props.entity,
		entityVm: this.props.entityVm,
		user: this.props.user,
	};

	public UNSAFE_componentWillReceiveProps(nextProps: IAvatarProps) {
		const nextState: IState = {};

		if (nextProps.avatarImageUrl !== this.state.avatarImageUrl) {
			nextState.avatarImageUrl = nextProps.avatarImageUrl;
		}

		if (nextProps.entity !== this.state.entity) {
			nextState.entity = nextProps.entity;
		}

		if (nextProps.user !== this.state.user) {
			nextState.user = nextProps.user;
		}

		if (nextProps.entityVm !== this.state.entityVm) {
			nextState.entityVm = nextProps.entityVm;
		}

		if (Object.keys(nextState).length > 0) {
			this.setState(nextState);
		}
	}

	public render() {
		const { className, onClick, hasKeepInTouch, style, icon } = this.props;

		return (
			<div
				className={`avatar ${css(styleSheet.avatar)} ${className || ''}`}
				onClick={onClick}
				style={style || undefined}
			>
				<div className={`avatar-content ${css(styleSheet.avatarContent)}`}>{this.onRenderContent()}</div>
				{!!hasKeepInTouch && (
					<img className={`avatar-kit-imge ${css(styleSheet.avatarKit)}`} src={KitIndicatorIconUrl} />
				)}
				{icon && <span className={css(styleSheet.icon)}>{icon}</span>}
			</div>
		);
	}

	private onRenderContent() {
		const { children } = this.props;
		const { failedAvatarUrl, user, entityVm } = this.state;
		if (children) {
			return children;
		}

		// @ts-ignore
		let principal: IPrincipal = user;
		let company: ICompany;
		// @ts-ignore
		const entity: IEntity = this.state.entity ? this.state.entity : entityVm ? entityVm.toJs() : null;
		let avatarImageUrl = this.state.avatarImageUrl || (principal ? principal.profilePic : null);

		if (!principal && !!entity) {
			if (getTypeForEntity(entity) === 'company') {
				company = entity as ICompany;
			} else {
				// principal/entity is a contact
				principal = entity as IPrincipal;
				avatarImageUrl = principal.profilePic;
			}
		}

		// Check to see if avatarImageUrl exists and we've saved it as a failed load... if so, skip it and render letters
		if (!!avatarImageUrl && avatarImageUrl !== failedAvatarUrl) {
			// render the letters below the image
			return (
				<img
					className={`avatar-image ${css(styleSheet.avatarImage)}`}
					onError={this.onImageFailedToLoad(avatarImageUrl)}
					onLoad={this.onImageLoaded}
					src={avatarImageUrl}
				/>
			);
		} else if (user || entity) {
			// @ts-ignore
			return this.onRenderLetters(company, principal);
		} else {
			return (
				<AssigneeIcon
					className={css(baseStyleSheet.absoluteCenter, styleSheet.noEntityAvatarContent)}
					fillColor='#fff'
				/>
			);
		}
	}

	private onImageLoaded = () => {
		this.setState({
			imageLoaded: true,
		});
	};

	private onImageFailedToLoad = (failedAvatarUrl: string) => () => {
		this.setState({
			failedAvatarUrl,
		});
	};

	private onRenderLetters(company?: ICompany, principal?: IPrincipal) {
		let letters: string;
		if (company) {
			letters = (company.companyName || '')
				.split(' ')
				.reduce((prev, curr, i) => (i < 2 ? `${prev}${curr.substring(0, 1)}` : prev), '');
		} else if (principal) {
			letters = getPrincipalInitials(principal);
		}

		// @ts-ignore
		if (letters) {
			return <span className='absolute-center'>{letters}</span>;
		}
	}
}

const AvatarAsObserver = observer(_Avatar);
export const Avatar = inject(UserSessionViewModelKey)(AvatarAsObserver);

export const UserAvatar: React.FC<IAvatarProps & { id: string }> = inject(UserSessionViewModelKey)(props => {
	const { id, userSession, ...restProps } = props;
	// @ts-ignore
	const url = `${process.env.API_URL}/user/${id}/profilePic?accountId=${userSession.account.id}`;
	return <Avatar {...restProps} userSession={userSession} avatarImageUrl={url} />;
});

export const ContactAvatar: React.FC<IAvatarProps & { id: string }> = inject(UserSessionViewModelKey)(props => {
	const { id, userSession, ...restProps } = props;
	// @ts-ignore
	const url = `${process.env.API_URL}/contact/${id}/profilePic?accountId=${userSession.account.id}`;
	return <Avatar {...restProps} userSession={userSession} avatarImageUrl={url} />;
});
