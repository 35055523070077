import { StyleSheet } from 'aphrodite';
import { brandPrimaryText, titles } from '../../../styles/colors';

export const styleSheet = StyleSheet.create({
	autoLink: {
		':hover': {
			textDecoration: 'underline',
		},
		color: brandPrimaryText,
		textDecoration: 'none',
	},
	container: {
		color: titles,
		fontSize: 14,
	},
});
