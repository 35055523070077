import { SvgIcon } from '../../icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const CityRightGraphic: React.FunctionComponent<IProps> = props => {
	const { className } = props;
	return (
		<SvgIcon
			className={className}
			width='370'
			height='351'
			viewBox='0 0 370 351'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<rect opacity='0.509184' x='129' y='93.5781' width='139' height='257' fill='url(#paint0_linear_0_408)' />
			<path
				opacity='0.411229'
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10 30.5781L119 31.0518V92.8062H129V350.578H0V91.6834H10V30.5781Z'
				fill='url(#paint1_linear_0_408)'
			/>
			<rect width='36' height='4' transform='matrix(1 0 0 -1 20 4.57812)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 20 48.5781)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 60 118.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 145 118.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 145 128.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 145 138.578)' fill='#F89143' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 145 188.578)' fill='#F89143' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 155 188.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 175 188.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 185 188.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 195 188.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 205 188.578)' fill='#F89143' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 155 138.578)' fill='#F89143' />
			<rect width='14' height='4' transform='matrix(1 0 0 -1 155 128.578)' fill='#00AAE8' />
			<rect width='14' height='4' transform='matrix(1 0 0 -1 145 228.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 145 258.578)' fill='#00AAE8' />
			<rect width='14' height='4' transform='matrix(1 0 0 -1 165 228.578)' fill='#F89143' />
			<rect width='14' height='4' transform='matrix(1 0 0 -1 175 128.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 155 118.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 165 118.578)' fill='#00AAE8' />
			<rect width='34' height='4' transform='matrix(1 0 0 -1 175 118.578)' fill='#00AAE8' />
			<rect width='34' height='4' transform='matrix(1 0 0 -1 215 118.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 60 178.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 50 178.578)' fill='#00AAE8' />
			<rect width='14' height='4' transform='matrix(1 0 0 -1 60 138.578)' fill='#F89143' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 80 138.578)' fill='#F89143' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 50 138.578)' fill='#F89143' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 80 208.578)' fill='#F89143' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 80 238.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 90 238.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 70 118.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 80 118.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 90 118.578)' fill='#F89143' />
			<rect opacity='0.427763' width='34' height='4' transform='matrix(1 0 0 -1 20 68.5781)' fill='#00AAE8' />
			<rect width='14' height='4' transform='matrix(1 0 0 -1 90 78.5781)' fill='#F89143' />
			<rect width='14' height='4' transform='matrix(1 0 0 -1 90 88.5781)' fill='#F89143' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 30 48.5781)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 40 48.5781)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 50 48.5781)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 60 48.5781)' fill='#F89143' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 70 48.5781)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 70 68.5781)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 90 68.5781)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 100 68.5781)' fill='#F89143' />
			<rect width='36' height='4' transform='matrix(1 0 0 -1 70 4.57812)' fill='#00AAE8' />
			<rect opacity='0.174197' x='20' y='5' width='36' height='26' fill='#00AAE8' />
			<rect opacity='0.174197' x='70' y='5' width='36' height='26' fill='#00AAE8' />
			<rect opacity='0.411229' x='225' y='241.578' width='58' height='105' fill='url(#paint2_linear_0_408)' />
			<rect opacity='0.276064' width='4' height='4' transform='matrix(1 0 0 -1 231 255.578)' fill='#00AAE8' />
			<rect opacity='0.276064' width='4' height='4' transform='matrix(1 0 0 -1 241 255.578)' fill='#00AAE8' />
			<rect opacity='0.276064' width='4' height='4' transform='matrix(1 0 0 -1 231 295.578)' fill='#00AAE8' />
			<rect opacity='0.276064' width='4' height='4' transform='matrix(1 0 0 -1 251 315.578)' fill='#00AAE8' />
			<rect x='264' y='302.578' width='87' height='44' fill='url(#paint3_linear_0_408)' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M253.036 143L351 194.201V299H264.427L253.036 143Z'
				fill='url(#paint4_linear_0_408)'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M253.036 135L351 186.201V190L253 139L253.036 135Z'
				fill='#00AAE8'
			/>
			<rect width='4' height='4' transform='matrix(1 0 0 -1 264 212.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 274 212.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 284 212.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 294 212.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 304 212.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 314 212.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 324 212.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 264 222.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 274 222.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 274 232.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 284 232.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 284 222.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 294 222.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 294 232.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 304 222.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 314 222.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 324 222.578)' fill='#00AAE8' />
			<circle cx='342' cy='319' r='28' fill='url(#paint5_linear_0_408)' />
			<rect x='66' y='324.578' width='111' height='22' fill='#08426B' />
			<rect x='186' y='291.578' width='46' height='55' fill='#08426B' />
			<rect opacity='0.672863' x='187' y='299' width='44' height='4' fill='#046CB6' />
			<rect opacity='0.672863' x='187' y='307' width='44' height='4' fill='#046CB6' />
			<rect opacity='0.672863' x='187' y='315' width='44' height='4' fill='#046CB6' />
			<rect opacity='0.672863' x='187' y='323' width='44' height='4' fill='#046CB6' />
			<rect opacity='0.672863' x='187' y='331' width='44' height='4' fill='#046CB6' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M177 314C177 314 172 308 160.342 304.93C148.685 301.86 145 304.93 145 304.93V325L177 324.674V314Z'
				fill='#063B60'
			/>
			<path d='M151 303C151 303 158.192 304 166 307C173.808 310 180 316 180 316' stroke='#00AAE8' />
			<path d='M151 310C151 310 158.192 311 166 314C173.808 317 180 323 180 323' stroke='#00AAE8' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M135 294C135 294 136.6 291 140.6 289C144.6 287 151 287 151 287V324.578H135V294Z'
				fill='#046CB6'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M66 314C66 314 81 306 101 302C121 298 139 299 139 299V325L66 324.578V314Z'
				fill='#063B60'
			/>
			<path d='M139 299C139 299 113 299 96 303C79 307 62 315 62 315' stroke='#00AAE8' />
			<rect x='131' y='316' width='4' height='6' fill='#00AAE8' />
			<rect x='126' y='316' width='4' height='6' fill='#00AAE8' />
			<rect x='121' y='316' width='4' height='6' fill='#00AAE8' />
			<rect x='116' y='316' width='4' height='6' fill='#00AAE8' />
			<rect x='111' y='316' width='4' height='6' fill='#00AAE8' />
			<rect x='106' y='316' width='4' height='6' fill='#00AAE8' />
			<rect x='101' y='316' width='4' height='6' fill='#00AAE8' />
			<rect x='96' y='316' width='4' height='6' fill='#00AAE8' />
			<rect x='91' y='316' width='4' height='6' fill='#00AAE8' />
			<rect x='86' y='316' width='4' height='6' fill='#00AAE8' />
			<rect x='96' y='336' width='4' height='4' fill='#F89143' />
			<rect x='96' y='336' width='4' height='4' fill='#F89143' />
			<rect x='102' y='336' width='4' height='4' fill='#F89143' />
			<rect x='121' y='336' width='4' height='4' fill='#F89143' />
			<rect x='121' y='342' width='4' height='4' fill='#F89143' />
			<rect x='102' y='342' width='4' height='4' fill='#F89143' />
			<rect x='96' y='342' width='4' height='4' fill='#F89143' />
			<rect x='81' y='316' width='4' height='6' fill='#00AAE8' />
			<rect x='131' y='310' width='4' height='5' fill='#00AAE8' />
			<rect x='126' y='310' width='4' height='5' fill='#00AAE8' />
			<rect x='121' y='310' width='4' height='5' fill='#00AAE8' />
			<rect x='116' y='310' width='4' height='5' fill='#00AAE8' />
			<rect x='111' y='310' width='4' height='5' fill='#00AAE8' />
			<rect x='106' y='310' width='4' height='5' fill='#00AAE8' />
			<rect x='101' y='310' width='4' height='5' fill='#00AAE8' />
			<rect x='96' y='310' width='4' height='5' fill='#00AAE8' />
			<rect x='91' y='310' width='4' height='5' fill='#00AAE8' />
			<rect x='86' y='310' width='4' height='5' fill='#00AAE8' />
			<rect x='76' y='316' width='4' height='6' fill='#00AAE8' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M82 310.833C83 310 85 310 85 310V315H81C81 315 81 313.333 81 312.5C81 311.667 81 311.667 82 310.833Z'
				fill='#00AAE8'
			/>
			<rect x='71' y='316' width='4' height='6' fill='#00AAE8' />
			<defs>
				<linearGradient
					id='paint0_linear_0_408'
					x1='21.7349'
					y1='94.5798'
					x2='21.7349'
					y2='748.229'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#003D66' />
					<stop offset='1' stopColor='#004C7F' />
				</linearGradient>
				<linearGradient
					id='paint1_linear_0_408'
					x1='0.2514'
					y1='31.8254'
					x2='0.2514'
					y2='350.578'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#003D66' />
					<stop offset='1' stopColor='#004C7F' />
				</linearGradient>
				<linearGradient
					id='paint2_linear_0_408'
					x1='225.113'
					y1='241.987'
					x2='225.113'
					y2='346.578'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#003D66' />
					<stop offset='1' stopColor='#004C7F' />
				</linearGradient>
				<linearGradient
					id='paint3_linear_0_408'
					x1='264.17'
					y1='302.75'
					x2='264.17'
					y2='346.578'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#003D66' />
					<stop offset='1' stopColor='#004C7F' />
				</linearGradient>
				<linearGradient
					id='paint4_linear_0_408'
					x1='177.438'
					y1='143.608'
					x2='177.438'
					y2='540.376'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#003D66' />
					<stop offset='1' stopColor='#004C7F' />
				</linearGradient>
				<linearGradient
					id='paint5_linear_0_408'
					x1='305.492'
					y1='315.921'
					x2='339.076'
					y2='363.792'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#004C81' />
					<stop offset='1' stopColor='#00416C' />
				</linearGradient>
			</defs>
		</SvgIcon>
	);
};
