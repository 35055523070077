import { SvgIcon } from '../../icons/SvgIcon';
import { StyleDeclarationValue } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const MoonAndStarsGraphic: React.FunctionComponent<IProps> = props => {
	const { className } = props;
	return (
		<SvgIcon
			className={className}
			width='1029'
			height='355'
			viewBox='0 0 1029 355'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<circle cx='390' cy='118' r='1' fill='white' />
			<circle cx='124' cy='354' r='1' fill='white' />
			<circle opacity='0.126211' cx='427' cy='66' r='1' fill='white' />
			<circle opacity='0.543005' cx='521' cy='150' r='1' fill='white' />
			<circle opacity='0.164948' cx='570' cy='198' r='1' fill='white' />
			<circle opacity='0.316045' cx='892' cy='47' r='1' fill='white' />
			<circle opacity='0.774184' cx='452' cy='177' r='1' fill='white' />
			<circle opacity='0.774184' cx='98' cy='264' r='1' fill='white' />
			<circle cx='620' cy='68' r='1' fill='white' />
			<circle opacity='0.126211' cx='730' cy='148' r='1' fill='white' />
			<circle opacity='0.543005' cx='760' cy='68' r='1' fill='white' />
			<circle opacity='0.543005' cx='800' cy='148' r='1' fill='white' />
			<circle opacity='0.246626' cx='961' cy='207' r='1' fill='white' />
			<circle opacity='0.543005' cx='1028' cy='178' r='1' fill='white' />
			<circle opacity='0.774184' cx='882' cy='107' r='1' fill='white' />
			<circle opacity='0.774184' cx='703' cy='188' r='1' fill='white' />
			<circle opacity='0.774184' cx='311.5' cy='206.5' r='0.5' fill='white' />
			<circle opacity='0.514396' cx='282' cy='166' r='1' fill='white' />
			<circle opacity='0.168642' cx='292' r='292' fill='#046CB6' />
			<circle opacity='0.168642' cx='291.5' cy='-0.5' r='134.5' fill='#046CB6' />
			<circle opacity='0.317804' cx='292.5' cy='0.5' r='97.5' fill='#046CB6' />
			<circle opacity='0.0993193' cx='292' r='63' fill='#0095FF' />
			<circle cx='292' r='23' fill='white' />
		</SvgIcon>
	);
};
