import { SvgIcon } from '../../../components/svgs/icons/SvgIcon';
import { nameCircles } from '../../../styles/colors';
import * as React from 'react';

export function HwSampleW4(props: React.SVGProps<SVGSVGElement>) {
	return (
		<SvgIcon {...props} viewBox='0 0 18 14'>
			<path
				fill={nameCircles}
				fillRule='evenodd'
				d='M1.713 11.708c.07.394.345.76.558 1.118.408.687 1.185.831 1.69.274.558-.618 1.163-1.189 1.611-1.92.657-1.07 1.424-2.075 2.173-3.086.133-.179.407-.253.657-.4.293.557.566 1.073.836 1.59.393.754.938 1.39 1.562 1.957.91.828 1.984.891 3.058.48a3.897 3.897 0 0 0 2.271-2.235c.22-.542.428-1.107.513-1.68.295-1.994.547-3.995.387-6.018-.039-.5-.12-.999-.22-1.49-.026-.12-.193-.278-.313-.296-.135-.02-.305.075-.434.16-.137.091-.249.224-.362.348-.628.69-.64.698-.108 1.682.166.306.263.61.254.95-.03 1.09-.016 2.185-.114 3.27-.068.743-.274 1.478-.453 2.21-.265 1.076-1.01 1.703-2.024 2.05a1.564 1.564 0 0 1-1.22-.06c-.543-.257-1.035-.59-1.34-1.108A18.111 18.111 0 0 1 9.66 7.498c-.294-.684-.499-1.406-.728-2.069-.452-.146-.704.045-.908.332-1.119 1.573-2.225 3.155-3.36 4.716-.293.401-.673.74-1.02 1.098-.145.148-.539.034-.635-.18-.134-.305-.308-.604-.373-.923-.379-1.89-.726-3.787-1.097-5.679a1.906 1.906 0 0 0-.27-.683c-.07-.102-.294-.172-.423-.142-.127.03-.263.183-.319.315-.06.147-.056.331-.045.496.151 2.355.822 4.62 1.232 6.93Z'
				clipRule='evenodd'
			/>
		</SvgIcon>
	);
}
