import { StyleSheet } from 'aphrodite';
import {
	bulletMarginRight,
	bulletSize,
	editButtonMarginRight,
	editButtonSize,
} from '../../../components/entities/EntityInfoBulletList/styles';
import { titles } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	dataOriginContainer: {
		width: `calc(100% - ${bulletSize}px - ${bulletMarginRight}px - ${editButtonSize}px - ${editButtonMarginRight}px)`,
	},
	pluginEnv: {
		maxWidth: 300,
	},
	factText: {
		display: 'flex',
		flexWrap: 'wrap',
		overflowWrap: 'break-word',
		width: '100%',
		wordBreak: 'break-word',
		wordWrap: 'break-word',
		...CssSelectors.allChildren(
			{
				marginRight: 4,
			},
			':first-child'
		),
	},
	item: {
		':nth-child(1n) .entity-info-bullet-list-item-edit-button': {
			opacity: 0,
			pointerEvents: 'none',
		},

		':nth-child(1n) > .entity-info-bullet-list-item-bullet': {
			marginTop: 2,
		},
		alignItems: 'flex-start',
		color: titles,
		display: 'flex',
		fontSize: 14,
		listStyle: 'none',
		padding: '4px 0',
	},
	itemWithHover: {
		':hover': {
			':nth-child(1n) .entity-info-bullet-list-item-edit-button': {
				opacity: 1,
				pointerEvents: 'all',
			},
			background: '#F9F9F9',
		},
	},
	itemToBeDeleted: {
		':nth-child(1n) .entity-info-bullet-list-item-edit-button': {
			opacity: 1,
			pointerEvents: 'all',
		},
		background: '#F9F9F9',
	},
	itemEditing: {
		':nth-child(1n) > .entity-info-bullet-list-item-bullet': {
			marginTop: 0,
		},
		alignItems: 'center',
	},
	deleteItemConfirmationArrow: {
		...CssSelectors.allChildren(
			{
				':after': {
					borderLeft: '25px solid transparent',
					borderTop: '11px solid #D3DDBE',
					content: "' '",
					left: -24,
					position: 'absolute',
					top: -10,
					zIndex: -1,
				},
				borderLeftWidth: '22px !important',
				borderRight: 'none !important',
				left: 'auto !important',
				right: 10,
				width: 0,
			},
			'div:first-child'
		),
	},
	deleteItemConfirmationContainer: {
		borderBottomRightRadius: 0,
		maxWidth: 310,
	},
	closeDeleteConfirmationContainer: {
		height: 14,
		position: 'absolute',
		right: 8,
		top: 5,
		width: 14,
		...CssSelectors.allChildren({
			height: '100%',
			width: '100%',
		}),
	},
	policySelector: {
		...CssSelectors.allDescendants(
			{
				display: 'none',
			},
			'.policy-selector-trigger-label'
		),
	},
});
