import { brandPrimary, brandSecondary } from '../../styles/colors';
import { baseStyleSheet } from '../../styles/styles';
import { DisclosureIcon } from '../svgs/icons/DisclosureIcon';
import { styleSheet } from './styles';
import { TinyColor } from '@ctrl/tinycolor';
import { StyleDeclaration, css } from 'aphrodite';
import outy, { IOutyHandle } from 'outy';
import * as React from 'react';

export enum CompoundButtonType {
	CtaPrimary = 'CtaPrimary',
	CtaReverse = 'CtaReverse',
	CtaSecondary = 'CtaSecondary',
}

interface IProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
	buttonTitle?: React.ReactNode;
	contentClassName?: string;
	openDirection?: 'up' | 'down';
	optionsTriggerClassName?: string;
	optionsTriggerDividerClassName?: string;
	optionsTriggerHoverText?: string;
	popupContentClassName?: string;
	kind: CompoundButtonType;
	styleDeclaration?: StyleDeclaration;
}

const getStyleForType = (type: CompoundButtonType) => {
	switch (type) {
		case CompoundButtonType.CtaSecondary: {
			return styleSheet.typeSecondary;
		}
		case CompoundButtonType.CtaReverse: {
			return styleSheet.typeReverse;
		}
		default: {
			return null;
		}
	}
};

const getPrimaryColorForType = (type: CompoundButtonType) => {
	switch (type) {
		case CompoundButtonType.CtaSecondary: {
			return brandSecondary;
		}
		case CompoundButtonType.CtaReverse:
		case CompoundButtonType.CtaPrimary: {
			return brandPrimary;
		}
		default: {
			return '#000000';
		}
	}
};

const getOptionsTriggerItconColorForType = (type: CompoundButtonType) => {
	switch (type) {
		case CompoundButtonType.CtaReverse: {
			return '#8E8E8E';
		}
		default: {
			return '#FFF';
		}
	}
};

interface IState {
	showingOptions?: boolean;
}

export class CompoundButton extends React.PureComponent<IProps, IState> {
	// @ts-ignore
	private outsideClickHandle: IOutyHandle;
	// @ts-ignore
	private componentRootElement: HTMLSpanElement;
	constructor(props: IProps) {
		super(props);
		this.state = {};
	}

	public render() {
		const {
			buttonTitle,
			children,
			className,
			contentClassName,
			disabled,
			openDirection,
			optionsTriggerClassName,
			optionsTriggerDividerClassName,
			optionsTriggerHoverText,
			popupContentClassName,
			kind: type,
			styleDeclaration,
			...restProps
		} = this.props;
		const { showingOptions } = this.state;
		const primaryColor = new TinyColor(getPrimaryColorForType(type));
		const darkColor = primaryColor.darken(8).toHexString();
		const lightColor = primaryColor.lighten(8).toHexString();
		const buttonStyle = getStyleForType(type);
		return (
			<span className={css(styleSheet.container, styleDeclaration)} ref={this.onRef}>
				<button
					{...restProps}
					className={`${css(styleSheet.button, buttonStyle)} compound-button-content ${contentClassName || ''}`}
					disabled={disabled}
				>
					{buttonTitle}
				</button>
				<span
					className={`compound-button-divider ${css(styleSheet.divider, disabled ? baseStyleSheet.disabled : null)}`}
					style={{
						background: darkColor,
						borderRight: `1px solid ${lightColor}`,
					}}
				/>
				<button
					className={`compound-button-options-trigger ${css(styleSheet.optionsTrigger, buttonStyle)} ${
						optionsTriggerClassName || ''
					}`}
					disabled={disabled}
					onClick={this.toggleDisplayOfOptions(!showingOptions)}
					title={optionsTriggerHoverText}
				>
					<DisclosureIcon
						className={css(styleSheet.optionsTriggerIcon)}
						fillColor={getOptionsTriggerItconColorForType(type)}
					/>
				</button>
				{!!showingOptions && (
					<span
						className={`compound-button-popup-content ${css(
							styleSheet.popupContent,
							openDirection === 'down' ? styleSheet.popupContentDown : null
						)} ${popupContentClassName || ''}`}
						onClick={this.toggleDisplayOfOptions(false)}
					>
						{children}
					</span>
				)}
			</span>
		);
	}

	private toggleDisplayOfOptions = (showingOptions: boolean) => () => {
		this.setState({
			showingOptions,
		});
	};

	private onRef = (ref: HTMLDivElement) => {
		this.componentRootElement = ref;
		if (ref) {
			this.registerClickOutside();
		} else {
			this.unRegisterClickOutside();
		}
	};

	private registerClickOutside = () => {
		this.unRegisterClickOutside();
		if (this.componentRootElement) {
			this.outsideClickHandle = outy([this.componentRootElement], ['click', 'touchstart'], this.onClickOutside);
		}
	};

	private unRegisterClickOutside = () => {
		if (this.outsideClickHandle) {
			this.outsideClickHandle.remove();
			// @ts-ignore
			this.outsideClickHandle = null;
		}
	};

	private onClickOutside = () => {
		this.toggleDisplayOfOptions(false)();
	};
}
