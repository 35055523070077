import * as React from 'react';
import { SvgIcon } from '../SvgIcon';

interface IProps {
	className?: string;
	fill?: string;
	height?: number;
	width?: number;
}

export const ExpiryIcon: React.FC<IProps> = props => {
	const { className, fill = '#046CB6', height = 16, width = 12 } = props;
	return (
		<SvgIcon
			className={`expandIcon ${className ?? ''}`}
			width={width}
			height={height}
			viewBox='0 0 12 16'
			fill={fill}
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9.42857 3.379C9.42857 3.57625 9.3445 3.76975 9.19836 3.90925L5.5 7.4395L1.80164 3.90925C1.6555 3.76975 1.57143 3.57625 1.57143 3.379V2.5H9.42857V3.379ZM9.42857 13.6218V14.5H1.57143V13.6218C1.57143 13.4215 1.65314 13.2333 1.80164 13.0915L5.5 9.5605L9.19836 13.0915C9.34686 13.2333 9.42857 13.4215 9.42857 13.6218V13.6218ZM11 3.379V1.75C11 1.336 10.648 1 10.2143 1H0.785714C0.352 1 0 1.336 0 1.75V3.379C0 3.9715 0.251429 4.5505 0.690643 4.96975L4.389 8.5L0.690643 12.031C0.245143 12.4563 0 13.0202 0 13.6217V15.25C0 15.664 0.352 16 0.785714 16H10.2143C10.648 16 11 15.664 11 15.25V13.6217C11 13.0202 10.7549 12.4563 10.3094 12.031L6.611 8.5L10.3094 4.96975C10.7486 4.5505 11 3.9715 11 3.379V3.379Z'
				fill={fill}
			/>
			<path fillRule='evenodd' clipRule='evenodd' d='M3 14L5.5 12L8 14H3Z' fill={fill} />
		</SvgIcon>
	);
};
