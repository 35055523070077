import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Industry } from '../../../../extViewmodels';
import { useInput } from '../../../../models/hooks/inputHooks';
import { baseStyleSheet } from '../../../styles/styles';
import { SearchBase } from '../../SearchBase';
import { styleSheet } from './styles';

interface IProps {
	industry: Industry;
	onChange?(value: string, industry: Industry): void;
	styles?: StyleDeclarationValue[];
}

const Delay = 300;

const CampaignSearchInputFieldBase: React.FC<IProps> = ({ industry, onChange, styles = [] }) => {
	const [inputValue, setInputValue, onInputChange] = useInput('');
	// @ts-ignore
	const [searchTimeout, setSearchTimeout] = useState<number>(null);

	useEffect(() => {
		clearTimeout(searchTimeout);
		// @ts-ignore
		setSearchTimeout(null);

		setSearchTimeout(
			window.setTimeout(() => {
				onChange?.(inputValue, industry);
			}, Delay)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputValue, onChange]);

	const onClearClick = () => {
		setInputValue('');
	};

	return (
		<div className={css(styleSheet.container, ...styles)}>
			<SearchBase
				id='content-calendar-campaign-search'
				inputStyles={[styleSheet.input]}
				onChange={onInputChange}
				placeholder='Search campaigns'
				value={inputValue}
			/>
			{!!inputValue && (
				<button className={css(baseStyleSheet.brandLink, ...[styles])} onClick={onClearClick}>
					Clear search
				</button>
			)}
		</div>
	);
};

export const CampaignSearchInputField = observer(CampaignSearchInputFieldBase);
