import { StyleSheet } from 'aphrodite';
import { darkGrayFontColor, navigation } from '../../../../../styles/colors';

export const styleSheet = StyleSheet.create({
	contactHistory: {
		minHeight: 320,
		overflow: 'hidden',
		position: 'relative',
		textAlign: 'center',
	},
	contactHistoryItem: {
		fontSize: 14,
	},
	contactHistoryItemBody: {
		color: darkGrayFontColor,
		hyphens: 'auto',
		overflowWrap: 'break-word',
		wordBreak: 'break-word',
		wordWrap: 'break-word',
	},
	contactHistoryItemHeader: {
		[':nth-child(1n) + *']: {
			marginTop: 10,
		},
		color: navigation,
	},
	contactHistoryList: {
		flexGrow: 1,
		textAlign: 'left',
	},
});
