import { titles } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const ContentWidth = 460;
export const GifsContainerHeight = 320;

export const styleSheet = StyleSheet.create({
	container: {
		minWidth: 500,
		width: ContentWidth,
	},
	footer: {
		marginTop: 30,
	},
	gifGrid: {
		willChange: 'transform, opacity',
	},
	gifs: {
		display: 'flex',
		height: GifsContainerHeight,
		overflowY: 'auto',
		position: 'relative',
	},
	header: {
		color: titles,
		display: 'flex',
		fontSize: 18,
		justifyContent: 'space-between',
	},
	headerPoweredByImage: {
		height: 30,
		marginRight: 20,
		width: 'auto',
	},
	noResultsOverlay: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
	},
	overlay: {
		background: '#fff',
		height: '100%',
		left: 0,
		position: 'absolute',
		top: 0,
		width: '100%',
	},
	search: {
		color: '#4A4A4A',
		fontSize: 14,
		marginTop: 24,
		position: 'relative',
	},
});
