import { SvgIcon } from '../../../components/svgs/icons/SvgIcon';
import { nameCircles } from '../../../styles/colors';
import * as React from 'react';

export function HwSampleW1({ ...props }: React.SVGProps<SVGSVGElement>) {
	return (
		<SvgIcon {...props} viewBox='0 0 18 12'>
			<path
				fill={nameCircles}
				fillRule='evenodd'
				d='M16.816.365c-.08-.176-.512-.303-.656-.166-.112.107-.267.273-.25.393.166 1.247.367 2.489.553 3.733.211 1.419.303 2.84.105 4.268-.049.353-.27.603-.59.76-.544.267-1.1.377-1.709.235-.758-.176-1.414-.513-1.969-1.06a8.88 8.88 0 0 1-1.75-2.448c-.333-.678-.63-1.373-.96-2.053-.069-.142-.199-.26-.32-.368-.05-.046-.149-.04-.226-.052-.203-.034-.316.056-.377.255-.194.644.3 1.34-.144 1.959-.392.546-.784 1.093-1.208 1.613a43.253 43.253 0 0 1-1.653 1.9c-.224.244-.513.429-.778.63-.273.209-.653.112-.869-.174-.67-.887-1.035-1.926-1.397-2.95-.56-1.58-1.015-3.2-1.523-4.8a4.858 4.858 0 0 0-.368-.918C.668 1.018.434.942.304.969.181.995-.004 1.175 0 1.28c.016.499.017 1.017.155 1.49.426 1.449.867 2.897 1.39 4.313.435 1.179.975 2.32 1.508 3.46.287.617.986.886 1.715.759.513-.09.971-.3 1.31-.695.818-.958 1.62-1.931 2.424-2.901.262-.315.476-.682.922-.8.655.306.863.999 1.273 1.516.69.87 1.495 1.596 2.487 2.09 1.127.562 2.262.523 3.376-.076.553-.298.88-.772 1.024-1.365.078-.324.107-.664.126-.998.15-2.54-.244-5.023-.765-7.494-.016-.077-.094-.14-.129-.215'
				clipRule='evenodd'
			/>
		</SvgIcon>
	);
}
