import {
	AttachmentsViewModel,
	EmailMessageViewModel,
	IFileAttachment,
	IFormField,
	IFormResponse,
	IImpersonationContext,
	IOperationResultNoValue,
	ResourceSelectorId,
	UserSessionContext,
	VmUtils,
	asApiError,
} from '@ViewModels';
import * as tinymce from 'tinymce';
import {
	IRichContentDocumentEditorImageOptions,
	IRichContentDocumentEditorPlaceholder,
	disableFileAttachmentCommand,
	enableFileAttachmentCommand,
	levInsertGiphyCommand,
	levPlaceholderPopoverCloseCommand,
	levPlaceholderPopoverCommand,
} from '../../../../models';
import { AppState, ErrorMessagesViewModelKey } from '../../../../models/AppState';
import { IEmailComposerContext } from '../../../../models/Email';
import {
	SenderEmailPlaceholder,
	SenderFirstNamePlaceholder,
	SenderFullNamePlaceholder,
	SenderPhonePlaceholder,
	Token,
} from '../../../../models/Token';
import { CONTACT_CUSTOM_FIELDS_QUERY_KEY } from '../../../../queries';
import { reactQueryClient } from '../../../ReactQueryProvider';
import { linkifySurveyLinks, parseElement } from './pastingParserUtils';
import PluginCss from './placeholders.tinymce.css';

const returnError = (editor: tinymce.Editor, ui: boolean, error: IOperationResultNoValue) => {
	editor.execCommand('levError', ui, { error, source: 'insert-image' });
};
export interface ITinyMcePlugin {
	onInit?(
		userSession: UserSessionContext,
		editor: tinymce.Editor,
		impersonationContext?: IImpersonationContext
	): (() => void) | void;
}

export interface ITinyMceToolbarPlugin extends ITinyMcePlugin {
	name?: string;
}

export type ToggleFormat = 'bold' | 'italic' | 'underline';
export enum TinyMCECommand {
	FontName = 'FontName',
	FontSize = 'FontSize',
	mceApplyTextcolor = 'mceApplyTextcolor',
	mceToggleFormat = 'mceToggleFormat',
}

export const parsePastedContent = (content: string): string => {
	const div = document.createElement('div');
	div.insertAdjacentHTML('afterbegin', content);

	Array.from(div.children as HTMLCollectionOf<HTMLElement>).forEach(el => {
		parseElement(el, 1);
	});

	return div.innerHTML;
};

export const SurveyLinksPlugin: ITinyMcePlugin & {
	onContentPastePreProcess: (content: string) => string;
} = {
	onContentPastePreProcess: (content: string) => {
		return linkifySurveyLinks(content);
	},
	onInit: () => {
		tinymce.default.PluginManager.add('surveylinks', function () {
			// Noop
		});
	},
};

export const GiphyToolbarPlugin: ITinyMceToolbarPlugin = {
	onInit: (_: UserSessionContext, editor: tinymce.Editor) => {
		// add the icon
		// name must be lowercase!!
		editor.ui.registry.addIcon(
			'giphy',
			'<svg xmlns="http://www.w3.org/2000/svg" width="16" height="21" viewBox="0 0 16 21"><g fill="none" fill-rule="evenodd"><rect width="9.143" height="2.286" fill="#FCF73E"/><rect width="2.286" height="16.085" y="2.286" fill="#18FF91"/><path fill="#FC6161" d="M13.7989418,7.02645503 L11.5132275,7.02645503 L9.14285714,7.02645503 L9.14285714,0 L11.5132275,0 L11.5132275,2.28571429 L13.7989418,2.28571429 L13.7989418,4.57142857 L16,4.57142857 L16,7.02645503 L13.7989418,7.02645503 Z"/><rect width="2.286" height="11.344" x="13.714" y="7.026" fill="#9D00FF"/><rect width="16" height="2.286" y="18.37" fill="#2ECBFF"/><polygon fill="#979426" points="6.906 .049 9.192 2.237 6.906 2.237" transform="rotate(-90 8.05 1.143)"/><polygon fill="#5E009D" points="13.708 7.033 15.993 9.305 13.708 9.305" transform="rotate(90 14.85 8.17)"/></g></svg>'
		);

		// add command
		editor.addCommand(levInsertGiphyCommand, VmUtils.Noop);

		editor.ui.registry.addButton('giphy', {
			icon: 'giphy',
			onAction: () => {
				editor.execCommand(levInsertGiphyCommand);
			},
			tooltip: 'Insert a Giphy Image',
		});
	},
};

export const FileAttachmentToolbarPlugin: ITinyMceToolbarPlugin = {
	onInit: (_: UserSessionContext, editor: tinymce.Editor) => {
		// add the icon
		// name must be lowercase!!
		editor.ui.registry.addIcon(
			'file-attachement',
			'<svg xmlns="http://www.w3.org/2000/svg" width="12" height="18" viewBox="0 0 12 18" fill="#757575"><path d="M4.28571429,18 C1.80257143,18 0,16.0174286 0,13.2857143 L0,6 C0,2.57914286 2.39485714,0 5.57142857,0 C8.748,0 11.1428571,2.57914286 11.1428571,6 L11.1428571,12 L9.42857143,12 L9.42857143,6 C9.42857143,3.51685714 7.806,1.71428571 5.57142857,1.71428571 C3.33685714,1.71428571 1.71428571,3.51685714 1.71428571,6 L1.71428571,13.2857143 C1.71428571,14.7797143 2.50971429,16.2857143 4.28571429,16.2857143 C6.06171429,16.2857143 6.85714286,14.7797143 6.85714286,13.2857143 L6.85714286,6.85714286 C6.85714286,6.34114286 6.732,5.14285714 5.57142857,5.14285714 C4.41085714,5.14285714 4.28571429,6.34114286 4.28571429,6.85714286 L4.28571429,12.8571429 L2.57142857,12.8571429 L2.57142857,6.85714286 C2.57142857,4.806 3.77742857,3.42857143 5.57142857,3.42857143 C7.36542857,3.42857143 8.57142857,4.806 8.57142857,6.85714286 L8.57142857,13.2857143 C8.57142857,16.0174286 6.76885714,18 4.28571429,18" fill-rule="evenodd"></path></svg>'
		);

		// add command
		editor.addCommand('levAddFileAttachement', VmUtils.Noop);
		editor.addCommand(disableFileAttachmentCommand, VmUtils.Noop);
		editor.addCommand(enableFileAttachmentCommand, VmUtils.Noop);
		editor.ui.registry.addButton('file-attachments', {
			icon: 'file-attachement',
			onAction: () => {
				editor.execCommand('levAddFileAttachement');
			},
			// The onSetup function MUST return a function expression that handles the editor being unloaded
			// https://www.tiny.cloud/docs/ui-components/typesoftoolbarbuttons/#basicbuttonexampleandexplanation
			onSetup: button => {
				const commandCallback = (e: { command?: string }) => {
					if (e.command === disableFileAttachmentCommand) {
						button.setDisabled(true);
					} else if (e.command === enableFileAttachmentCommand) {
						button.setDisabled(false);
					}
				};

				editor.on('ExecCommand', commandCallback);

				return () => editor.off('ExecCommand', commandCallback);
			},
			tooltip: 'Add a file attachment',
		});
	},
};

export interface IInsertImageToolbarPlugin extends ITinyMceToolbarPlugin {
	onInit: (
		userSession: UserSessionContext,
		editor: tinymce.Editor,
		impersonationContext?: IImpersonationContext,
		imageOptions?: IRichContentDocumentEditorImageOptions
	) => any;
}

export const InsertImageToolbarPlugin: IInsertImageToolbarPlugin = {
	onInit: (
		userSession: UserSessionContext,
		editor: tinymce.Editor,
		impersonationContext?: IImpersonationContext,
		imageOptions?: IRichContentDocumentEditorImageOptions
	) => {
		// @ts-ignore
		let input: HTMLInputElement = null;
		editor.ui.registry.addButton('insert-image', {
			icon: 'image',
			onAction: () => {
				input = insertImage(userSession, input, editor, impersonationContext, imageOptions);
			},
			tooltip: 'Insert image',
		});
		return () => {
			// dispose input
			input?.parentNode?.removeChild?.(input);
			// @ts-ignore
			input = null;
		};
	},
};

export const InsertMenuToolbarPlugin: IInsertImageToolbarPlugin = {
	onInit: (
		userSession: UserSessionContext,
		editor: tinymce.Editor,
		impersonationContext?: IImpersonationContext,
		imageOptions?: IRichContentDocumentEditorImageOptions
	) => {
		// @ts-ignore
		let input: HTMLInputElement = null;
		let isFileAttachmentDisabled = false;

		editor.addCommand('levAddFileAttachement', VmUtils.Noop);
		editor.addCommand(disableFileAttachmentCommand, VmUtils.Noop);
		editor.addCommand(enableFileAttachmentCommand, VmUtils.Noop);

		editor.ui.registry.addMenuButton('insertmenu', {
			fetch: callback => {
				const items: tinymce.Ui.Menu.MenuItemSpec[] = [
					{
						icon: 'link',
						onAction: () => {
							editor.execCommand('mceLink');
						},
						text: 'Hyperlink',
						type: 'menuitem',
					},
					{
						icon: 'image',
						onAction: () => {
							input = insertImage(userSession, input, editor, impersonationContext, imageOptions);
						},
						text: 'Image',
						type: 'menuitem',
					},
					{
						icon: 'giphy',
						onAction: () => {
							editor.execCommand(levInsertGiphyCommand);
						},
						text: 'Giphy',
						type: 'menuitem',
					},
					{
						icon: 'file-attachement',
						onAction: () => {
							editor.execCommand('levAddFileAttachement');
						},
						onSetup: button => {
							button.setDisabled(isFileAttachmentDisabled);
							return VmUtils.Noop;
						},
						text: 'File',
						type: 'menuitem',
					},
				];
				callback(items);
			},
			onSetup: () => {
				const commandCallback = (e: { command?: string }) => {
					if (e.command === disableFileAttachmentCommand) {
						isFileAttachmentDisabled = true;
					} else if (e.command === enableFileAttachmentCommand) {
						isFileAttachmentDisabled = false;
					}
				};

				editor.on('ExecCommand', commandCallback);
				return () => editor.off('ExecCommand', commandCallback);
			},
			text: 'Insert',
		});

		return () => {
			// dispose input
			input?.parentNode?.removeChild?.(input);
			// @ts-ignore
			input = null;
		};
	},
};

export const InsertMenuSignatureToolbarPlugin: IInsertImageToolbarPlugin = {
	onInit: (
		userSession: UserSessionContext,
		editor: tinymce.Editor,
		impersonationContext?: IImpersonationContext,
		imageOptions?: IRichContentDocumentEditorImageOptions
	) => {
		// @ts-ignore
		let input: HTMLInputElement = null;

		editor.ui.registry.addMenuButton('insertsigmenu', {
			fetch: callback => {
				const items: tinymce.Ui.Menu.MenuItemSpec[] = [
					{
						icon: 'link',
						onAction: () => {
							editor.execCommand('mceLink');
						},
						text: 'Hyperlink',
						type: 'menuitem',
					},
					{
						icon: 'image',
						onAction: () => {
							input = insertImage(userSession, input, editor, impersonationContext, imageOptions);
						},
						text: 'Image',
						type: 'menuitem',
					},
					{
						icon: 'giphy',
						onAction: () => {
							editor.execCommand(levInsertGiphyCommand);
						},
						text: 'Giphy',
						type: 'menuitem',
					},
				];
				callback(items);
			},
			text: 'Insert',
		});

		return () => {
			// dispose input
			input?.parentNode?.removeChild?.(input);
			// @ts-ignore
			input = null;
		};
	},
};

export const ListMenuToolbarPlugin: ITinyMcePlugin = {
	onInit: (_: UserSessionContext, editor: tinymce.Editor) => {
		editor.ui.registry.addMenuButton('listmenu', {
			fetch: callback => {
				const items: tinymce.Ui.Menu.MenuItemSpec[] = [
					{
						icon: 'unordered-list',
						onAction: () => {
							editor.execCommand('InsertUnorderedList');
						},
						text: 'Bullet',
						type: 'menuitem',
					},
					{
						icon: 'ordered-list',
						onAction: () => {
							editor.execCommand('InsertOrderedList');
						},
						text: 'Number',
						type: 'menuitem',
					},
				];
				callback(items);
			},
			icon: 'unordered-list',
		});
	},
};

export const AlignmentMenuToolbarPlugin: ITinyMcePlugin = {
	onInit: (_: UserSessionContext, editor: tinymce.Editor) => {
		editor.ui.registry.addMenuButton('alignmentmenu', {
			fetch: callback => {
				const items: tinymce.Ui.Menu.MenuItemSpec[] = [
					{
						icon: 'align-left',
						onAction: () => {
							editor.execCommand('JustifyLeft');
						},
						text: 'Left',
						type: 'menuitem',
					},
					{
						icon: 'align-right',
						onAction: () => {
							editor.execCommand('JustifyRight');
						},
						text: 'Right',
						type: 'menuitem',
					},
					{
						icon: 'align-center',
						onAction: () => {
							editor.execCommand('JustifyCenter');
						},
						text: 'Center',
						type: 'menuitem',
					},
					{
						icon: 'align-justify',
						onAction: () => {
							editor.execCommand('JustifyFull');
						},
						text: 'Justify',
						type: 'menuitem',
					},
					{
						icon: 'align-none',
						onAction: () => {
							editor.execCommand('JustifyNone');
						},
						text: 'Remove',
						type: 'menuitem',
					},
				];
				callback(items);
			},
			icon: 'align-left',
		});
	},
};

export interface IMergeFieldMenuToolbarPlugin extends ITinyMcePlugin {
	onInitWithRenewalFlowContext?(
		userSession: UserSessionContext,
		editor: tinymce.Editor,
		renewalFlowContext?: IEmailComposerContext,
		impersonationContext?: IImpersonationContext
	): (() => void) | void;
}

export const MergeFieldMenuToolbarPlugin: IMergeFieldMenuToolbarPlugin = {
	onInitWithRenewalFlowContext: (
		userSession: UserSessionContext,
		editor: tinymce.Editor,
		renewalFlowContext: IEmailComposerContext
	) => {
		editor.ui.registry.addMenuButton('mergefieldmenu', {
			fetch: callback => {
				const customFields = reactQueryClient.getQueryData<IFormResponse<string>>([
					CONTACT_CUSTOM_FIELDS_QUERY_KEY,
				])?.fields;
				const mergeTags: tinymce.Ui.Menu.NestedMenuItemSpec[] = [
					{
						getSubmenuItems: (): tinymce.Ui.Menu.MenuItemSpec[] => {
							const subMenu: (tinymce.Ui.Menu.MenuItemSpec | null)[] = [
								{
									onAction: () => {
										editor.insertContent('{{first name}}');
									},
									text: 'First Name',
									type: 'menuitem',
								},
								{
									onAction: () => {
										editor.insertContent('{{lastName}}');
									},
									text: 'Last Name',
									type: 'menuitem',
								},
								{
									onAction: () => {
										editor.insertContent('{{companyName}}');
									},
									text: 'Company Name',
									type: 'menuitem',
								},
								{
									onAction: () => {
										editor.insertContent('{{city}}');
									},
									text: 'City',
									type: 'menuitem',
								},
								{
									onAction: () => {
										editor.insertContent('{{state}}');
									},
									text: 'State/Province',
									type: 'menuitem',
								},
								renewalFlowContext?.flow === ResourceSelectorId.PolicyRenew
									? ({
											onAction: () => {
												editor.insertContent('{{policies}}');
											},
											text: 'Policy/Carrier/Expiration',
											type: 'menuitem',
										} as tinymce.Ui.Menu.MenuItemSpec)
									: null,
								{
									onAction: () => {
										editor.insertContent('{{bio}}');
									},
									text: 'Misc',
									type: 'menuitem',
								},
							];

							const custom =
								customFields?.map(x => {
									return {
										onAction: () => {
											editor.insertContent(`{{${x.label}}}`);
										},
										text: x.label,
										type: 'menuitem',
									} as tinymce.Ui.Menu.MenuItemSpec;
								}) ?? [];
							return (custom?.length ? subMenu.concat(custom) : subMenu).filter(x => !!x);
						},
						text: 'Contact',
						type: 'nestedmenuitem',
					},
					{
						getSubmenuItems: () => {
							const subMenu: tinymce.Ui.Menu.MenuItemSpec[] = [
								{
									onAction: () => {
										editor.insertContent(SenderFirstNamePlaceholder.symbol);
									},
									text: 'First Name',
									type: 'menuitem',
								},
								{
									onAction: () => {
										editor.insertContent(SenderFullNamePlaceholder.symbol);
									},
									text: 'Full Name',
									type: 'menuitem',
								},
								{
									onAction: () => {
										editor.insertContent(SenderEmailPlaceholder.symbol);
									},
									text: 'Email',
									type: 'menuitem',
								},
								{
									onAction: () => {
										editor.insertContent(SenderPhonePlaceholder.symbol);
									},
									text: 'Phone Number',
									type: 'menuitem',
								},
							];
							return subMenu;
						},
						text: 'Sender',
						type: 'nestedmenuitem',
					},
					// @ts-ignore
					userSession?.account?.features?.meetingScheduler?.enabled
						? {
								getSubmenuItems: () => {
									const subMenu: tinymce.Ui.Menu.MenuItemSpec[] = [
										{
											onAction: () => {
												editor.insertContent('{{meeting homepage link}}');
											},
											text: 'Meeting Link',
											type: 'menuitem',
										},
									];
									return subMenu;
								},
								text: 'Meetings',
								type: 'nestedmenuitem',
							}
						: null,
				];
				callback(mergeTags.filter(x => !!x));
			},
			text: 'Merge Fields',
		});
	},
};

export const PlaceholdersPlugin: ITinyMcePlugin & {
	getCustomFieldPlaceholders: (customFields?: IFormField<string>[]) => IRichContentDocumentEditorPlaceholder[];
} = {
	getCustomFieldPlaceholders: (customFields?: IFormField<string>[]) => {
		const customFieldPlaceholders: IRichContentDocumentEditorPlaceholder[] =
			customFields?.map(x => {
				return {
					additionalSymbols: [`{{${x.label}}}`],
					symbol: `{{${x.name}}}`,
					text: x.label,
					value: `contact.customProperties.${x.name}`,
					onClickCommand: levPlaceholderPopoverCommand,
					onCloseCommand: levPlaceholderPopoverCloseCommand,
				};
			}) ?? [];
		return customFieldPlaceholders;
	},
	onInit: (_: UserSessionContext, editor: tinymce.Editor) => {
		const placeholders: IRichContentDocumentEditorPlaceholder[] = editor.getParam('placeholders', []);
		const onLoad = () => {
			// It appears as though we don't have a ref to "editor.dom" yet. We need to setTimeout to await for it.
			setTimeout(() => {
				editor.dom.loadCSS(PluginCss);
				placeholders?.forEach(x => {
					if (x.onClickCommand) {
						editor.addCommand(x.onClickCommand, VmUtils.Noop);
					}
					if (x.onCloseCommand) {
						editor.addCommand(x.onCloseCommand, VmUtils.Noop);
					}
				});
			}, 1);
		};

		const onEnter = (e: MouseEvent) => {
			const node = e.target as Node;
			if (editor.dom.is(node, 'span[data-placeholder="true"]')) {
				const dataValue = editor.dom.getAttrib(node, 'data-placeholder-value');
				const placeholder = placeholders?.find(x => x.value === dataValue);
				if (placeholder?.onClickCommand) {
					editor.execCommand(placeholder.onClickCommand, true, {
						documentBounds: editor.dom.getRoot()?.getBoundingClientRect(),
						placeholderValue: placeholder.value,
						sourceEvent: e,
					});
				}
			}
		};

		const onLeave = (e: MouseEvent) => {
			const node = e.target as Node;
			if (editor.dom.is(node, 'span[data-placeholder="true"]')) {
				const dataValue = editor.dom.getAttrib(node, 'data-placeholder-value');
				const placeholder = placeholders?.find(x => x.value === dataValue);
				if (placeholder?.onCloseCommand) {
					editor.execCommand(placeholder.onCloseCommand, true, {
						sourceEvent: e,
					});
				}
			}
		};

		const onBeforeSetContent = (e: { content?: string }) => {
			if (!!e?.content && placeholders?.length > 0) {
				try {
					const stripped = Token.cleanHtmlString(e.content, placeholders);
					e.content = stripped;

					placeholders.forEach(placeholder => {
						const [newContent] = Token.replaceContent(e.content, placeholder);
						if (newContent) {
							e.content = newContent;
						}
					});
				} catch (err) {
					const message = (err as Error)?.message;
					if (typeof message === 'string') {
						AppState[ErrorMessagesViewModelKey].push({ messages: [message] });
					}
				}
			}
		};

		const onBeforeExecCommand = (e: { command: TinyMCECommand; target: tinymce.Editor; value: ToggleFormat }) => {
			let style: [string, string];
			let toggle: ToggleFormat;

			switch (e.command) {
				case TinyMCECommand.FontName:
					style = ['fontFamily', e.value];
					break;
				case TinyMCECommand.FontSize:
					style = ['fontSize', e.value];
					break;
				case TinyMCECommand.mceApplyTextcolor:
					style = ['color', e.value];
					break;
				case TinyMCECommand.mceToggleFormat:
					toggle = e.value;
					break;
				default:
					break;
			}

			if (style || toggle) {
				const { contentWindow: win, contentDocument: doc } = e.target.iframeElement;
				const content = win?.getSelection()?.getRangeAt(0)?.cloneContents();

				if (content) {
					// @ts-ignore
					Array.from(content.querySelectorAll('[data-placeholder="true"]')).forEach((clone: HTMLElement) => {
						// @ts-ignore
						const el = doc?.querySelector<HTMLElement>(`[data-id="${clone.dataset.id}"]`);
						if (el) {
							if (style) {
								// @ts-ignore
								el.style[style[0]] = style[1];
							}

							switch (toggle) {
								case 'bold': {
									const regex = /(bold|700)/g;
									el.style.fontWeight = regex.test(win.getComputedStyle(el).fontWeight) ? '400' : 'bold';
									break;
								}
								case 'italic': {
									el.style.fontStyle = win.getComputedStyle(el).fontStyle.includes('italic') ? 'normal' : 'italic';
									break;
								}
								case 'underline': {
									el.style.textDecoration = win.getComputedStyle(el).textDecoration.includes('underline')
										? 'none'
										: 'underline';
									break;
								}
								default: {
									break;
								}
							}

							// resolves an issue with legacy token formatting not being updated in sent emails
							el.dataset.mceStyle = el.style.cssText;
						}
					});
				}
			}
		};

		tinymce.default.PluginManager.add('placeholders', function (ed: tinymce.Editor) {
			ed.on('load', onLoad);
			ed.on('beforeSetContent', onBeforeSetContent);
			ed.on('mouseover', onEnter);
			ed.on('mouseout', onLeave);
			ed.on('beforeExecCommand', onBeforeExecCommand);
		});
		return () => {
			editor.off('load', onLoad);
			editor.off('beforeSetContent', onBeforeSetContent);
			editor.off('mouseover', onEnter);
			editor.off('mouseout', onLeave);
			editor.off('beforeExecCommand', onBeforeExecCommand);
		};
	},
};

async function getMaxImageSizeForInsert(editor: tinymce.Editor, imageFile: File) {
	return new Promise<[File, string]>((resolve, reject) => {
		const fileReader = new FileReader();
		fileReader.onloadend = () => {
			resolve([imageFile, fileReader.result as string]);
		};
		fileReader.onerror = (ev: ProgressEvent<FileReader>) => {
			reject(ev.target?.error || new Error('Unknown FileReader error.'));
		};
		fileReader.readAsDataURL(imageFile);
	}).then(async result => {
		return new Promise<[File, string, { width: number; height: number }]>((resolve, reject) => {
			if (result) {
				const image = new Image();
				image.onload = () => {
					const body = editor.getBody();
					let width = image.width;
					let height = image.height;
					const padding = body?.style
						? [body?.style?.paddingLeft || '', body?.style?.paddingRight || ''].reduce(
								(value, x) => value + parseInt(x.replace('px', '') || '0', 10),
								0
							)
						: 0;
					const maxInitialWidth = body.clientWidth - padding;
					if (width > maxInitialWidth) {
						// resize the image to fit the width of the body el.
						width = maxInitialWidth;
						height = (width * height) / image.width;
					}
					resolve([imageFile, result[1], { height, width }]);
				};
				image.onerror = () => {
					reject(new Error('Unable to read image attributes.'));
				};
				image.src = result[1];
			} else {
				reject(new Error('Unable to read image.'));
			}
		});
	});
}

function insertImage(
	userSession: UserSessionContext,
	input: HTMLInputElement,
	editor: tinymce.Editor,
	impersonationContext?: IImpersonationContext,
	imageOptions?: IRichContentDocumentEditorImageOptions
) {
	if (!input) {
		const doc = editor.getDoc();
		input = doc.createElement('input');
		input.type = 'file';
		input.setAttribute('style', `position:fixed;top:-5000px;left:-5000px;opacity:0;display:none;z-index:-1;`);
		input.accept = '.png,.jpg,.jpeg,.gif,.bmp';
		doc.body.appendChild(input);
		input.onclick = (e: MouseEvent) => {
			// clear previosuly selected files
			// @ts-ignore
			(e.target as HTMLInputElement).value = null;
		};

		const attachments = new AttachmentsViewModel(userSession).impersonate(impersonationContext);
		input.onchange = async e => {
			/**
			 * Basic implementation based on: src:
			 * https://github.com/tinymce/tinymce/blob/43712beb09f7e6fa4bfdbdee402f4d816015ea33/src/plugins/quickbars/main/ts/insert/Actions.ts
			 */
			const targetInput = e.target as HTMLInputElement;
			// @ts-ignore
			const files = Array.from(targetInput.files) || [];

			const file = files[0];
			if (file) {
				if (file.size >= EmailMessageViewModel.MaxByteCount) {
					returnError(editor, true, {
						systemCode: 400,
						systemMessage: 'Image file size exceeds max message size.',
					});
					return;
				}

				try {
					// @ts-ignore
					let fileAttachment: IFileAttachment = null;
					if (!imageOptions?.base64EmbedOnInsert) {
						const opResult = await attachments.uploadImages([file]);
						// @ts-ignore
						if (opResult.succeeded?.length !== 1) {
							throw opResult;
						}
						// @ts-ignore
						fileAttachment = opResult.succeeded[0];
					}

					const loadedImageAndAttribs = await getMaxImageSizeForInsert(editor, file);
					const elAttribs: Record<string, any> = {
						height: loadedImageAndAttribs[2].height,
						src: fileAttachment?.url || loadedImageAndAttribs[1],
						width: loadedImageAndAttribs[2].width,
					};
					if (fileAttachment) {
						elAttribs['data-id'] = fileAttachment.id;
					}
					editor.insertContent(editor.dom.createHTML('img', elAttribs));
				} catch (err) {
					const error = asApiError(err);
					returnError(editor, true, error);
				}
			}

			// @ts-ignore
			targetInput.value = null;
		};
	}
	input.click();
	return input;
}
