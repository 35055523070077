import { getEntityDisplayName } from '../../../../models/UiUtils';
import { baseStyleSheet } from '../../../styles/styles';
import { styleSheet } from './styles';
import { IActionItem } from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

interface IProps {
	actionItem: IActionItem;
	className?: string;
	disabled?: boolean;
	onSeeNoteClicked?(e: React.MouseEvent<HTMLAnchorElement>): void;
}

export const ActionItemAssociatedNoteHeader: React.FC<IProps> = props => {
	if (props.actionItem.associatedNote) {
		const referencedEntities = props.actionItem.associatedNote.referencedEntities;
		if (referencedEntities) {
			const entityReferences = [...(referencedEntities.contacts || []), ...(referencedEntities.companies || [])];
			let content = '';

			if (entityReferences.length === 0) {
				return null;
			}

			if (entityReferences.length === 1) {
				content = `Reminder from note on ${getEntityDisplayName(entityReferences[0].entity)}.`;
			} else {
				const othersText = entityReferences.length === 2 ? '1 other' : `${entityReferences.length - 1} others`;
				content = `Reminder from note on ${getEntityDisplayName(entityReferences[0].entity)} and ${othersText}.`;
			}

			const seeNoteLink = (
				<NavLink
					className={css(baseStyleSheet.brandLink, styleSheet.link, props.disabled && baseStyleSheet.disabled)}
					onClickCapture={props.onSeeNoteClicked}
					to={`/notes/${props.actionItem.associatedNote.id}`}
				>
					<span>See note</span>
				</NavLink>
			);

			return (
				<div className={`${css(styleSheet.metaData)} ${props.className || ''}`}>
					{content}&nbsp;{seeNoteLink}
				</div>
			);
		}
	}

	return null;
};
