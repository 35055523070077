import { css } from 'aphrodite';
import { useHistory } from 'react-router';
import { GiftingSuccessGraphic } from '../../components/svgs/graphics/GiftingSuccessGraphic';
import { baseStyleSheet } from '../../styles/styles';
import { styleSheet } from './styles';

export function HwcOrderSuccessPage() {
	const history = useHistory();
	return (
		<>
			<div className={css(styleSheet.successStepContainer)}>
				<GiftingSuccessGraphic />
				<h3 className={css(styleSheet.successStepHeadline)}>Your order is complete</h3>
				<button
					className={css(baseStyleSheet.ctaButton, styleSheet.successStepCtaBtn)}
					onClick={() => history.push(`/reporting/handwritten-cards`)}
				>
					Go to Reporting
				</button>
			</div>
		</>
	);
}
