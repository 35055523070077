import * as React from 'react';
import { navigation } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';

export const TextingIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
	fill = navigation,
	width = 21,
	height = 18,
	...props
}) => {
	return (
		<SvgIcon height={height} width={width} {...props}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M3.29301 16H11C15.411 16 19 12.86 19 9C19 5.14 15.411 2 11 2C6.58901 2 3.00001 5.14 3.00001 9C3.00001 10.537 3.56801 12 4.64201 13.232C4.97801 13.618 4.96901 14.195 4.62101 14.57L3.29301 16ZM11 18H1.00001C0.60201 18 0.24201 17.764 0.0830102 17.4C-0.0759898 17.035 -0.0029898 16.611 0.26701 16.32L2.57301 13.836C1.54101 12.392 1.00001 10.735 1.00001 9C1.00001 4.038 5.48601 0 11 0C16.514 0 21 4.038 21 9C21 13.962 16.514 18 11 18Z'
				fill={fill}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12 9C12 9.552 11.552 10 11 10C10.448 10 10 9.552 10 9C10 8.448 10.448 8 11 8C11.552 8 12 8.448 12 9Z'
				fill={fill}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8 9C8 9.552 7.552 10 7 10C6.448 10 6 9.552 6 9C6 8.448 6.448 8 7 8C7.552 8 8 8.448 8 9Z'
				fill={fill}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M16 9C16 9.552 15.552 10 15 10C14.448 10 14 9.552 14 9C14 8.448 14.448 8 15 8C15.552 8 16 8.448 16 9Z'
				fill={fill}
			/>
		</SvgIcon>
	);
};
