import { useToggle } from './useToggle';
import * as React from 'react';

type CleanupCallback = void | (() => void | undefined) | Promise<any>;

/**
 * Provides basic utilities for working with a modal to save you a few lines of code. Uses an object return for clarity
 * in case of multiple modals
 *
 * @example
 * 	```ts
 * 	const mergeModal = useModal(false, () => {
 * 	    	contacts.selectedContacts.clear();
 * 	 	return () => console.log('cleanup or reset state');
 * 	}, [contacts]);
 * 	...
 * 	<button
 * 			onClick={ mergeModal.setIsOpen(true) }
 * 	>
 * 	...
 * 	<MergeContactsWizard
 * 	    	contacts={ contacts }
 * 	    	onFinish={ mergeModal.onRequestClose }
 * 	/>
 * 	```;
 *
 * @param initialIsOpen Modal open on mount
 * @param onSuccess F(x) that returns a f(x) or void... called when the modal action != cancel The return will be called
 *   any time. Use it to clean up local state selections, etc.
 * @param deps For the request close useCallback
 */
export const useModal = <T>(
	initialIsOpen: boolean,
	onSuccess: (result?: T) => CleanupCallback,
	deps: React.DependencyList = [],
	useDefaultHeader?: boolean,
	shouldCloseOnOverlayClick?: boolean
) => {
	const [isOpen, setIsOpen, setIsOpenLambda] = useToggle(initialIsOpen);

	const onRequestClose = React.useCallback((result?: T, cancel?: boolean) => {
		// @ts-ignore
		let onCleanup: CleanupCallback = null;
		if (!cancel) {
			onCleanup = onSuccess(result);
		}

		setIsOpen(false);
		if (typeof onCleanup === 'function') {
			onCleanup();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);

	const onRequestCloseLambda = React.useCallback(
		(result?: T, cancel?: boolean) => () => onRequestClose(result, cancel),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		deps
	);

	return {
		isOpen,
		onRequestClose,
		onRequestCloseLambda,
		setIsOpen: setIsOpenLambda,
		shouldCloseOnOverlayClick,
		useDefaultHeader,
	};
};
