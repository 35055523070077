import { SvgIcon } from '../../../components/svgs/icons/SvgIcon';
import { nameCircles } from '../../../styles/colors';
import * as React from 'react';

export function HwSampleW2(props: React.SVGProps<SVGSVGElement>) {
	return (
		<SvgIcon {...props} viewBox='0 0 21 13'>
			<path
				fill={nameCircles}
				fillRule='evenodd'
				d='M19.029 3.682c-.925-.85-1.848-1.702-2.609-2.71-.042-.056-.14-.083-.217-.097-.081-.016-.199-.034-.245.01-.111.104-.267.244-.265.368.005.238.061.498.174.708.406.751 1.063 1.286 1.674 1.861.487.46 1.003.907 1.401 1.438.579.771.811 1.651.424 2.606-.126.31-.207.644-.368.934-.478.86-1.216 1.39-2.194 1.507-.835.102-1.666.068-2.493-.167-1.705-.484-3.303-1.198-4.833-2.085-.411-.238-.776-.642-1.451-.498-.501.592-.602 1.464-.996 2.19-.486.894-1.02 1.515-1.807 1.977-1.13-.926-1.832-2.125-2.321-3.497-.857-2.4-1.372-4.83-.944-7.389C1.993.634 1.863.5 1.687.515c-.153.013-.338.033-.446.124-.406.343-.585.83-.607 1.334C.605 2.64.605 3.32.702 3.976c.296 1.996.854 3.916 1.604 5.8.422 1.061 1.062 1.883 1.862 2.631.686.641 1.836.598 2.493-.107.628-.674 1.241-1.355 1.485-2.294.1-.385.303-.772.845-.936.683.354 1.438.717 2.164 1.13 1.66.938 3.454 1.177 5.331 1.202 1.861.026 2.965-.883 3.676-2.5.826-1.88.617-3.328-.781-4.86a5.697 5.697 0 0 0-.352-.36Z'
				clipRule='evenodd'
			/>
		</SvgIcon>
	);
}
