import * as Api from '@ViewModels';
import { ObservablePageCollectionController } from '@ViewModels';
import { action, computed, observable } from 'mobx';
import { SlotMachineGameViewModel } from './slotMachines';

export class GameViewModel<TGameType extends Api.IGame = Api.IGame> extends Api.ViewModel {
	@observable.ref protected mGame: TGameType;

	constructor(userSession: Api.UserSessionContext, game?: TGameType) {
		super(userSession);
		// @ts-ignore
		this.mGame = game;
	}

	@computed
	public get id() {
		return this.mGame.id;
	}

	@computed
	public get name() {
		return this.mGame.name;
	}

	@action
	public load = async () => {
		if (this.loading) {
			return;
		}

		this.loading = true;
		const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<TGameType>(
			this.composeApiUrl({ urlPath: `game/${this.id}` }),
			'GET'
		);

		this.loading = false;

		if (!opResult.success) {
			throw opResult;
		}

		// @ts-ignore
		this.mGame = opResult.value;
		this.loaded = true;
		return opResult;
	};

	public toJs = () => {
		return this.mGame;
	};
}

export class GameTemplatesViewModel<TGameType extends Api.IGame = Api.IGame> extends Api.ViewModel {
	@observable.ref private mGameTemplatesPageCollectionController: ObservablePageCollectionController<
		TGameType,
		GameViewModel<TGameType>
	>;

	protected static mCreateGameTemplate = async <T extends Api.IGame = Api.IGame>(
		userSession: Api.UserSessionContext,
		path: string,
		template: Api.IGameTemplate<T>
	) => {
		const opResult = await userSession.webServiceHelper.callWebServiceAsync<Api.IGameTemplate<T>>(
			path,
			'POST',
			template
		);

		if (!opResult.success) {
			throw opResult;
		}

		return opResult;
	};

	public static CreateSlotMachineTemplate = async (
		userSession: Api.UserSessionContext,
		template: Api.IGameTemplate<Api.ISlotMachine>
	) => {
		return GameTemplatesViewModel.mCreateGameTemplate(userSession, 'game/slot-machine/template', template);
	};

	constructor(userSession: Api.UserSessionContext) {
		super(userSession);
		this.mGameTemplatesPageCollectionController = new ObservablePageCollectionController({
			apiPath: `game/template`,
			client: userSession.webServiceHelper,
			transformer: this.mCreateGame,
		});
	}

	@computed
	public get provider() {
		return this.mGameTemplatesPageCollectionController;
	}

	protected mCreateGame = (gameTemplate: Api.IGameTemplate<TGameType>) => {
		switch (gameTemplate.game._type) {
			case 'SlotMachine': {
				return new SlotMachineGameViewModel(this.mUserSession, gameTemplate.game as any as Api.ISlotMachine);
			}
			default: {
				return new GameViewModel<TGameType>(this.mUserSession, gameTemplate.game);
			}
		}
	};
}
