import { SvgIcon } from '../../icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const CityLeftGraphic: React.FunctionComponent<IProps> = props => {
	const { className } = props;
	return (
		<SvgIcon
			width='330'
			height='347'
			viewBox='0 0 330 347'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className={className}
		>
			<rect opacity='0.411229' x='79' y='89.5781' width='64' height='257' fill='url(#paint0_linear_0_348)' />
			<rect x='261' y='179.578' width='69' height='167' fill='url(#paint1_linear_0_348)' />
			<rect x='123' y='241.578' width='153' height='105' fill='url(#paint2_linear_0_348)' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M0.890625 301.2L90.499 301.496L101 346.705L9.99148 347L0.890625 301.2Z'
				fill='url(#paint3_linear_0_348)'
			/>
			<rect x='122' y='98.5781' width='139' height='143' fill='url(#paint4_linear_0_348)' />
			<rect width='56' height='4' transform='matrix(1 0 0 -1 83 86.5781)' fill='#00AAE8' />
			<rect width='88.2083' height='4' transform='matrix(1 0 0 -1 1.5907 301)' fill='#00AAE8' />
			<rect opacity='0.457066' width='26' height='4' transform='matrix(1 0 0 -1 153 114.578)' fill='#00AAE8' />
			<rect width='26' height='4' transform='matrix(1 0 0 -1 183 114.578)' fill='#F89143' />
			<rect width='26' height='4' transform='matrix(1 0 0 -1 143 257.578)' fill='#F89143' />
			<rect width='26' height='4' transform='matrix(1 0 0 -1 174 257.578)' fill='#F89143' />
			<rect opacity='0.533787' width='26' height='4' transform='matrix(1 0 0 -1 204 257.578)' fill='#F89143' />
			<rect opacity='0.533787' width='26' height='4' transform='matrix(1 0 0 -1 234 257.578)' fill='#F89143' />
			<rect width='16' height='4' transform='matrix(1 0 0 -1 143 134.578)' fill='#00AAE8' />
			<rect width='16' height='4' transform='matrix(1 0 0 -1 162 134.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 143 154.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 143 194.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 150 194.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 157 194.578)' fill='#00AAE8' />
			<rect width='16' height='4' transform='matrix(1 0 0 -1 150 154.578)' fill='#00AAE8' />
			<rect width='16' height='4' transform='matrix(1 0 0 -1 143 294.578)' fill='#00AAE8' />
			<rect width='16' height='4' transform='matrix(1 0 0 -1 163 294.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 183 294.578)' fill='#00AAE8' />
			<rect width='8' height='4' transform='matrix(1 0 0 -1 181 134.578)' fill='#F89143' />
			<rect width='8' height='4' transform='matrix(1 0 0 -1 231 154.578)' fill='#F89143' />
			<rect width='8' height='4' transform='matrix(1 0 0 -1 243 154.578)' fill='#F89143' />
			<rect opacity='0.6767' width='4' height='4' transform='matrix(1 0 0 -1 192 134.578)' fill='#F89143' />
			<rect width='6' height='4' transform='matrix(1 0 0 -1 213 114.578)' fill='#F89143' />
			<rect width='6' height='4' transform='matrix(1 0 0 -1 223 114.578)' fill='#F89143' />
			<rect width='4' height='26' transform='matrix(1 0 0 -1 109 26.5781)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 83 102.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 90 102.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 97 102.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 83 109.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 90 109.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 97 109.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 83 116.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 90 116.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 90 179.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 97 179.578)' fill='#F89143' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 83 186.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 90 186.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 90 236.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 96 236.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 96 296.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 97 149.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 83 156.578)' fill='#00AAE8' />
			<rect width='4' height='4' transform='matrix(1 0 0 -1 90 156.578)' fill='#00AAE8' />
			<path fillRule='evenodd' clipRule='evenodd' d='M111 15.5781L143 82.5781H79L111 15.5781Z' fill='#1872B1' />
			<rect opacity='0.574734' width='14' height='4' transform='matrix(1 0 0 -1 269 198.578)' fill='#F89143' />
			<rect opacity='0.574734' width='14' height='4' transform='matrix(1 0 0 -1 289 198.578)' fill='#F89143' />
			<rect opacity='0.574734' width='4' height='4' transform='matrix(1 0 0 -1 309 198.578)' fill='#F89143' />
			<rect opacity='0.574734' width='4' height='4' transform='matrix(1 0 0 -1 289 218.578)' fill='#F89143' />
			<rect opacity='0.574734' width='4' height='4' transform='matrix(1 0 0 -1 289 228.578)' fill='#00AAE8' />
			<rect opacity='0.574734' width='4' height='4' transform='matrix(1 0 0 -1 289 238.578)' fill='#00AAE8' />
			<rect opacity='0.574734' width='4' height='4' transform='matrix(1 0 0 -1 299 258.578)' fill='#00AAE8' />
			<rect opacity='0.574734' width='14' height='4' transform='matrix(1 0 0 -1 309 258.578)' fill='#00AAE8' />
			<rect opacity='0.574734' width='4' height='4' transform='matrix(1 0 0 -1 299 238.578)' fill='#00AAE8' />
			<rect opacity='0.574734' width='4' height='4' transform='matrix(1 0 0 -1 309 238.578)' fill='#00AAE8' />

			<defs>
				<linearGradient
					id='paint0_linear_0_348'
					x1='79.1247'
					y1='90.5798'
					x2='79.1247'
					y2='346.578'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#003D66' />
					<stop offset='1' stopColor='#004C7F' />
				</linearGradient>
				<linearGradient
					id='paint1_linear_0_348'
					x1='261.134'
					y1='180.229'
					x2='261.134'
					y2='346.578'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#003D66' />
					<stop offset='1' stopColor='#004C7F' />
				</linearGradient>
				<linearGradient
					id='paint2_linear_0_348'
					x1='-6.81314'
					y1='241.987'
					x2='-6.81314'
					y2='525.162'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#003D66' />
					<stop offset='1' stopColor='#004C7F' />
				</linearGradient>
				<linearGradient
					id='paint3_linear_0_348'
					x1='-84.0474'
					y1='301.379'
					x2='-84.0474'
					y2='424.896'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#003D66' />
					<stop offset='1' stopColor='#004C7F' />
				</linearGradient>
				<linearGradient
					id='paint4_linear_0_348'
					x1='14.7349'
					y1='99.1355'
					x2='14.7349'
					y2='462.839'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#003D66' />
					<stop offset='1' stopColor='#004C7F' />
				</linearGradient>
			</defs>
		</SvgIcon>
	);
};
