import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fill?: string;
}

export const DevToolsIcon: React.FC<IProps> = ({ className, fill = '#fff' }) => {
	const height = 20;
	const width = 20;
	return (
		<SvgIcon className={className} height={height} viewBox='0 0 700 700' width={width} fill={fill}>
			<path d='M629.32 288.83v243.79l-558.91 1.234v-245.03H43.035v245.03a26.535 26.535 0 0 0 8.21 18.805A26.533 26.533 0 0 0 70.41 560h559.73a26.563 26.563 0 0 0 18.633-7.633 26.538 26.538 0 0 0 7.922-18.512v-245.03z' />
			<path d='M684.34 154.68a27.36 27.36 0 0 0-8.02-19.355 27.366 27.366 0 0 0-19.359-8.02H43.032a27.363 27.363 0 0 0-19.358 8.02 27.364 27.364 0 0 0-8.02 19.355v134.15h668.69zm-641.31-.41h613.52v106.77l-613.52.004zM244.39 42.434a1.37 1.37 0 0 1 1.371-1.367h207.93c.363 0 .711.144.969.398.254.258.398.606.398.969v57.082h41.066V42.434a42.417 42.417 0 0 0-12.43-30.004A42.435 42.435 0 0 0 453.69 0H245.76a42.43 42.43 0 0 0-42.434 42.434v57.082h41.066zM239.19 409.29l61.734 24.777v22.449l-82.133-34.359v-25.188l82.133-34.359v22.039zM312.15 490.32l59.406-171.65h24.23l-59.684 171.65zM460.81 407.78l-61.871-24.5v-22.586l82.133 34.359v25.734l-82.133 34.496v-22.727z' />
		</SvgIcon>
	);
};
