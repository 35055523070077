import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { useTemplate } from '../../../queries';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { bs } from '../../styles/styles';
import { HwVariationsDisclaimer } from './HwVariationsDisclaimer';
import { HwcPreviewToggle, HwcTogglePreviewValues } from './HwcPreviewToggle';
import { HwcTextPreview } from './HwcTextPreview';
import { styleSheet } from './styles';

interface Props {
	value: HwcTogglePreviewValues;
	templateId: string;
	handwritingStyle: Api.HandwritingStyle;
	signature: string;
	postcardText: string;
	onValueChange: (value: HwcTogglePreviewValues) => void;
}

export function HwcFrontBackPreview({
	templateId,
	value,
	handwritingStyle,
	signature,
	postcardText,
	onValueChange,
}: Props) {
	const templateQuery = useTemplate<Api.IHandwrittenCardTemplate>({ templateId });
	return (
		<div className={css(bs.flex, bs.flexCol, bs.gap4, bs.itemsCenter)}>
			{value === 'image' ? (
				<>
					{templateQuery.data ? (
						<img
							src={templateQuery.data.image.publicUrl}
							alt='Template image preview'
							className={css(styleSheet.previewImage, bs.block, bs.dropShadowMd)}
						/>
					) : (
						<LoadingSpinner />
					)}
				</>
			) : null}
			{value === 'text' ? (
				<HwcTextPreview handwritingStyle={handwritingStyle} postcardText={postcardText} signature={signature} />
			) : null}
			<HwcPreviewToggle value={value} onValueChange={onValueChange} />
			<HwVariationsDisclaimer />
		</div>
	);
}
