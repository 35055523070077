import { StyleDeclarationValue, css } from 'aphrodite';
import moment from 'moment';
import { getDefaultDateStringValue, getDefaultTimeStringValue } from '../../../../models/UiUtils';
import { bs } from '../../../styles/styles';
import { PopoverType, TinyPopover } from '../../TinyPopover';
import { ExpiryIcon } from '../../svgs/icons/ExpiryIcon';
import { styleSheet } from './styles';

interface IProps {
	expirationDate: moment.MomentInput;
	styles?: StyleDeclarationValue[];
}

export function CampaignExpiryHint(props: IProps) {
	const { expirationDate, styles = [] } = props;
	const dateStringValue = getDefaultDateStringValue(expirationDate);
	const timeStringValue = getDefaultTimeStringValue(expirationDate);
	return (
		<TinyPopover
			align='end'
			anchor={<ExpiryIcon />}
			anchorStyles={[bs.flex]}
			className={styles?.length ? css(...styles) : undefined}
			dismissOnOutsideAction={true}
			placement={['right', 'bottom']}
			toggleOnHover={true}
			type={PopoverType.background}
		>
			<div className={css(styleSheet.popover)}>
				{'This is a time-sensitive campaign that will stop sending on '}
				<time dateTime={dateStringValue}>{dateStringValue}</time>
				{' at '}
				<time dateTime={timeStringValue}>{timeStringValue}</time>.
			</div>
		</TinyPopover>
	);
}
