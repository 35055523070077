import { CssSelectors, Layouts } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

const searchContainerHeight = 62;
const itemSpacer = 20;
export const styleSheet = StyleSheet.create({
	campaignSearch: {
		marginRight: 10,
		minWidth: 200,
		...CssSelectors.allChildren(
			{
				marginTop: 5,
			},
			'button'
		),
	},
	campaignSelector: {
		height: `calc(100% - ${searchContainerHeight + itemSpacer}px)`,
		marginTop: 10,
	},
	cardContainer: {
		height: 'calc(100% - 114px)',
	},
	container: {
		...Layouts.verticalStack(itemSpacer),
	},
	newCampaignCta: {
		height: 40,
		marginLeft: 24,
	},
	searchContainer: {
		display: 'flex',
		height: searchContainerHeight,
		justifyContent: 'space-between',
		marginBottom: itemSpacer,
		paddingRight: 10,
	},
	searchContainerNoButton: {
		...CssSelectors.allChildren(
			{
				width: '100%',
			},
			'div'
		),
	},
});
