import { CSSProperties, StyleSheet } from 'aphrodite';
import { CssSelectors, DefaultBorder } from '../../styles/styles';

export const AppBarHeight = 96;

export const AppBarCssProperties: CSSProperties = {
	background: '#fff',
	boxSizing: 'border-box',
	height: AppBarHeight,
	minHeight: AppBarHeight,
	zIndex: 5,
};

export const styleSheet = StyleSheet.create({
	appBar: {
		...AppBarCssProperties,
		borderBottom: DefaultBorder,
	},
	appBarContent: {
		alignItems: 'center',
		boxSizing: 'border-box',
		display: 'flex',
		height: '100%',
		justifyContent: 'space-between',
		position: 'relative',
	},
	helpLink: {
		alignItems: 'center',
		display: 'flex',
	},
	helpText: {
		fill: '#fff',
		fontSize: 14,
	},
	navItem: {
		flex: 1,
	},
	rightAccessoryContainer: {
		alignItems: 'center',
		display: 'flex',
		zIndex: 2,
		...CssSelectors.allChildren(
			{
				marginLeft: 20,
			},
			':not(:first-child)'
		),
	},
	toaster: {
		left: '50%',
		position: 'absolute',
		top: 17,
		transform: 'translateX(-50%)',
	},
	userProfileNav: {},
});
