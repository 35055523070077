import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { toggleOff, toggleOn } from '../../styles/colors';
import './styles.less';

export interface IToggleProps {
	checkedColor?: string;
	className?: string;
	disabled?: boolean;
	id: string;
	isOn?: boolean;
	onToggleCheckChanged?(checked: boolean): void;
	square?: boolean;
	text?: string;
	textStyles?: StyleDeclarationValue[];
	uncheckedColor?: string;
}

interface IState {
	isOn?: boolean;
}

export class Toggle extends React.PureComponent<IToggleProps, IState> {
	public state: IState = {};

	public UNSAFE_componentWillReceiveProps(nextProps: IToggleProps) {
		if (this.state.isOn !== nextProps.isOn) {
			this.setState({ isOn: nextProps.isOn });
		}
	}

	public render() {
		const {
			checkedColor = toggleOn,
			isOn,
			onToggleCheckChanged,
			uncheckedColor = toggleOff,
			textStyles = [],
		} = this.props;
		const isChecked = onToggleCheckChanged ? isOn : this.state.isOn;
		return (
			<label
				className={`toggle ${this.props.square ? '' : 'round'} ${this.props.className || ''}`}
				htmlFor={this.props.id}
				// @ts-ignore
				onClick={!this.props.disabled ? this.toggle : null}
			>
				<input
					className='toggle-input'
					disabled={this.props.disabled}
					id={this.props.id}
					type='checkbox'
					onChange={this.onCheckChanged}
					checked={isChecked || false}
				/>
				<div
					className={`toggle-track ${this.props.disabled ? 'toggle-disabled' : ''}`}
					style={{ backgroundColor: isChecked ? checkedColor : uncheckedColor }}
				>
					<div className='toggle-thumb' />
				</div>
				{!!this.props.text && <span className={`toggle-text ${css(...textStyles)}`}>{this.props.text}</span>}
			</label>
		);
	}

	private toggle = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		e.stopPropagation();
		const { isOn, onToggleCheckChanged } = this.props;
		const checked = onToggleCheckChanged ? isOn : this.state.isOn;
		this.setIsOn(!checked);
	};

	private onCheckChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setIsOn(e.target.checked);
	};

	private setIsOn = (isOn: boolean) => {
		const { onToggleCheckChanged } = this.props;
		if (onToggleCheckChanged) {
			onToggleCheckChanged(isOn);
		} else {
			this.setState({ isOn });
		}
	};
}
