import { SvgIcon } from '../../icons/SvgIcon';

export const InviteEmployeesGraphic = () => {
	return (
		<SvgIcon viewBox='0 0 163 174' height={163} width={174}>
			<g fill='none' fillRule='evenodd' transform='translate(1)'>
				<g transform='translate(41 35)'>
					<path
						d='m1.4392624 38.8006147 44-36.6666667c1.4833838-1.23615318 3.6380914-1.23615318 5.1214752 0l44 36.6666667c.9119704.7599753 1.4392624 1.8857657 1.4392624 3.0728851v52.1265002c0 2.209139-1.790861 4-4 4h-88c-2.209139 0-4-1.790861-4-4v-52.1265002c0-1.1871194.52729204-2.3129098 1.4392624-3.0728851z'
						fill='#cae0ec'
						fillRule='evenodd'
					/>
					<path
						d='m2.0794468 39.5688359c-.68397777.5699815-1.0794468 1.4143244-1.0794468 2.3046639v52.1265002c0 1.6568542 1.34314575 3 3 3h88c1.6568542 0 3-1.3431458 3-3v-52.1265002c0-.8903395-.395469-1.7346824-1.0794468-2.3046639l-44-36.66666662c-1.1125379-.92711488-2.7285685-.92711488-3.8411064 0z'
						stroke='#00aae8'
						strokeWidth='2'
					/>
					<path d='m11 11h72v65h-72z' fill='#fff' fillRule='evenodd' />
					<g stroke='#00aae8' strokeWidth='2'>
						<path d='m12 12h70v63h-70z' />
						<path d='m1 44v49c0 2.209139 1.790861 4 4 4h66z' fill='#e8f3f9' />
						<path
							d='m25.010448 44v49c0 2.209139 1.790861 4 4 4h66z'
							fill='#e8f3f9'
							transform='matrix(-1 0 0 1 120.020896 0)'
						/>
					</g>
					<rect fill='#d8d8d8' fillRule='evenodd' height='2' rx='1' width='33' x='31' y='36' />
					<rect height='1' rx='.5' stroke='#00aae8' width='32' x='31.5' y='36.5' />
					<rect fill='#d8d8d8' fillRule='evenodd' height='2' rx='1' width='33' x='31' y='44' />
					<rect height='1' rx='.5' stroke='#00aae8' width='32' x='31.5' y='44.5' />
					<rect fill='#d8d8d8' fillRule='evenodd' height='2' rx='1' width='26' x='31' y='52' />
					<rect height='1' rx='.5' stroke='#00aae8' width='25' x='31.5' y='52.5' />
					<rect fill='#d8d8d8' fillRule='evenodd' height='2' rx='1' width='12' x='31' y='26' />
					<rect height='1' rx='.5' stroke='#00aae8' width='11' x='31.5' y='26.5' />
					<path d='m31 68-23 28 30-23z' fill='#cae0ec' />
					<path d='m81 68-23 28 30-23z' fill='#cae0ec' transform='matrix(-1 0 0 1 146 0)' />
					<path
						d='m50.6487912 62.3407922 40.3512088 35.6592078h-86l40.3512088-35.6592078c1.512821-1.3369115 3.7847614-1.3369115 5.2975824 0z'
						fill='#fff'
						fillRule='evenodd'
					/>
					<path
						d='m7.64170176 97h80.71659644l-38.3717048-33.9098787c-1.1346157-1.0026837-2.8385711-1.0026837-3.9731868 0z'
						stroke='#00aae8'
						strokeWidth='2'
					/>
					<path
						d='m50.374119 69.7267601 35.625881 26.2732399h-76l35.625881-26.2732399c1.4115742-1.0410024 3.3366638-1.0410024 4.748238 0z'
						fill='#e8f3f9'
					/>
				</g>
				<ellipse cx='2.766355' cy='67.583333' fill='#e8f3f9' rx='2.766355' ry='2.770833' stroke='#00aae8' />
				<ellipse cx='169.925234' cy='28.088542' fill='#e8f3f9' rx='2.074766' ry='2.078125' stroke='#00aae8' />
				<ellipse cx='141.719626' cy='13.854167' fill='#e8f3f9' rx='1.383178' ry='1.385417' stroke='#00aae8' />
				<g fill='#00aae8'>
					<ellipse cx='126.504673' cy='1.385417' rx='1.383178' ry='1.385417' />
					<ellipse cx='8.906542' cy='90.744792' rx='2.074766' ry='2.078125' />
					<path d='m21.0654206 60.9583333c.7639078 0 1.3831775-.6202721 1.3831775-1.3854166s-.6192697-1.3854167-1.3831775-1.3854167c-.7639079 0-1.3831776.6202722-1.3831776 1.3854167s.6192697 1.3854166 1.3831776 1.3854166z' />
					<g transform='translate(145.551402 1.385417)'>
						<rect height='1.385417' rx='.692708' width='4.149533' y='1.385417' />
						<rect
							height='1.385417'
							rx='.692708'
							transform='matrix(0 1 -1 0 4.152891 .003359)'
							width='4.149533'
							y='1.385417'
						/>
					</g>
					<g transform='translate(153.850467 41.5625)'>
						<rect height='1.385417' rx='.692708' width='4.149533' y='1.385417' />
						<rect
							height='1.385417'
							rx='.692708'
							transform='matrix(0 1 -1 0 4.152891 .003359)'
							width='4.149533'
							y='1.385417'
						/>
					</g>
				</g>
				<ellipse cx='89' cy='157' fill='#e8f3f9' rx='48' ry='6' />
				<g stroke='#00aae8' strokeWidth='2'>
					<circle cx='44.5' cy='85.5' fill='#fff' r='25.5' />
					<g strokeLinecap='round' strokeLinejoin='round' transform='translate(34.162162 73.783784)'>
						<path d='m20.04914 22.5135135v-2.4504504c0-2.7066929-2.2440764-4.9009009-5.012285-4.9009009h-10.02456999c-2.76820857 0-5.01228501 2.194208-5.01228501 4.9009009v2.4504504' />
						<ellipse cx='10.525799' cy='4.900901' rx='5.012285' ry='4.900901' />
					</g>
				</g>
				<g stroke='#00aae8' strokeWidth='2'>
					<circle cx='114.5' cy='35.5' fill='#fff' r='25.5' />
					<g strokeLinecap='round' strokeLinejoin='round' transform='translate(104.162162 23.783784)'>
						<path d='m20.04914 22.5135135v-2.4504504c0-2.7066929-2.2440764-4.9009009-5.012285-4.9009009h-10.02456999c-2.76820857 0-5.01228501 2.194208-5.01228501 4.9009009v2.4504504' />
						<ellipse cx='10.525799' cy='4.900901' rx='5.012285' ry='4.900901' />
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
