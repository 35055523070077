import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	selected?: boolean;
}

export const FutureMeetingIcon: React.FC<IProps> = props => {
	const { className, selected } = props;
	if (selected) {
		return (
			<SvgIcon className={`future-meeting-icon ${className || ''}`} width={20} height={19}>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M10 2.98244C6.14 2.98244 3 6.10408 3 9.94151C3 13.7789 6.14 16.9006 10 16.9006C13.86 16.9006 17 13.7789 17 9.94151C17 6.10408 13.86 2.98244 10 2.98244M10 18.8889C5.038 18.8889 1 14.8755 1 9.94151C1 5.00753 5.038 0.994141 10 0.994141C14.962 0.994141 19 5.00753 19 9.94151C19 14.8755 14.962 18.8889 10 18.8889'
					fill='#89C947'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M19.1575 4.97076C19.6773 4.44337 20 3.72198 20 2.92455C20 1.30922 18.6838 0 17.0577 0C16.2564 0 15.5306 0.320726 15 0.839346L19.1575 4.97076Z'
					fill='#89C947'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M0.842487 4.97076C0.322676 4.44337 0 3.72198 0 2.92455C0 1.30922 1.3162 0 2.94233 0C3.74362 0 4.4694 0.320726 5 0.839346L0.842487 4.97076Z'
					fill='#89C947'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M12.498 13.9181L9.31124 10.8353C9.11154 10.6421 9 10.3811 9 10.1088V4.97076H11.1245V9.68337L14 12.4651L12.498 13.9181Z'
					fill='#046CB6'
				/>
			</SvgIcon>
		);
	}
	return (
		<SvgIcon className={`future-meeting-icon ${className || ''}`} width={20} height={19}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10 2.98246C6.14 2.98246 3 6.10409 3 9.94152C3 13.7789 6.14 16.9006 10 16.9006C13.86 16.9006 17 13.7789 17 9.94152C17 6.10409 13.86 2.98246 10 2.98246M10 18.8889C5.038 18.8889 1 14.8755 1 9.94152C1 5.00754 5.038 0.994152 10 0.994152C14.962 0.994152 19 5.00754 19 9.94152C19 14.8755 14.962 18.8889 10 18.8889'
				fill='#F89143'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M19.1575 4.97076C19.6773 4.44337 20 3.72198 20 2.92455C20 1.30922 18.6838 0 17.0577 0C16.2564 0 15.5306 0.320726 15 0.839346L19.1575 4.97076Z'
				fill='#F89143'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M0.842487 4.97076C0.322676 4.44337 0 3.72198 0 2.92455C0 1.30922 1.3162 0 2.94233 0C3.74362 0 4.4694 0.320726 5 0.839346L0.842487 4.97076Z'
				fill='#F89143'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12.498 13.9181L9.31124 10.8353C9.11154 10.6421 9 10.3811 9 10.1088V4.97076H11.1245V9.68336L14 12.4651L12.498 13.9181Z'
				fill='#D0021B'
			/>
		</SvgIcon>
	);
};
