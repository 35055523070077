import { StyleSheet } from 'aphrodite';
import {
	background,
	borderColor,
	brandPrimary,
	brandPrimaryText,
	brandSecondary,
	destructive,
	destructiveHover,
	error,
	grayIconFill,
	header,
	midGrey,
	nameCircles,
	navigation,
	simplyNotedBlue,
	titles,
	white,
} from '../../styles/colors';
import { CssSelectors, baseStyleSheet } from '../../styles/styles';

export const styleSheet = StyleSheet.create({
	actionsToolbar: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'flex-end',
		...CssSelectors.allChildren(
			{
				marginLeft: '1rem',
			},
			':not(:first-child)'
		),
	},
	addresslineCol: {
		flex: 1,
	},
	addresslineGroup: {
		display: 'flex',
		padding: '0.5rem 0',
		...CssSelectors.allChildren(
			{
				marginLeft: '0.5rem',
			},
			':not(:first-child)'
		),
	},
	balanceContainer: {
		color: header,
		margin: '0 0 0.5rem',
		padding: 0,
	},
	balanceText: {
		color: titles,
		fontSize: '1rem',
	},
	cardInfoHeader: {
		color: navigation,
		fontSize: 11,
	},
	cardInfoItem: {
		alignItems: 'center',
		display: 'flex',
		textTransform: 'uppercase',
		...CssSelectors.allChildren(
			{
				marginLeft: '2rem',
			},
			':not(:first-child)'
		),
	},
	cardInfoList: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		margin: '4.5rem auto 4.5rem',
		...CssSelectors.allChildren(
			{
				marginTop: '2rem',
			},
			':not(:first-child)'
		),
	},
	cardInfoText: {
		color: titles,
		fontSize: 14,
	},
	cardPreviewText: {
		color: navigation,
		margin: 0,
		padding: 0,
		textTransform: 'uppercase',
	},
	configDrawer: {
		boxSizing: 'border-box',
		display: 'flex',
		flex: '0 0 351px',
		flexDirection: 'column',
		minHeight: '100%',
		overflowY: 'auto',
		padding: '2rem',
	},
	configDrawerAddressHeader: {
		alignItems: 'center',
		display: 'flex',
		marginBottom: '0.5rem',
		...CssSelectors.allChildren(
			{
				marginLeft: '0.5rem',
			},
			':not(:first-child)'
		),
	},
	configDrawerAddressHeaderTitle: {
		flex: 1,
	},
	configDrawerAddressInput: {
		minHeight: 120,
	},
	configDrawerCharCount: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	configDrawerCharCountOver: {
		color: 'red',
	},
	configDrawerHeader: {
		color: brandPrimaryText,
		fontSize: 28,
		fontWeight: 'normal',
		lineHeight: '38px',
		margin: '0 0 1.5rem 0',
		padding: 0,
	},
	configDrawerHeaderRecipient: {
		marginBottom: '0.5rem',
	},
	configDrawerMessage: {
		color: brandSecondary,
		fontSize: '1rem',
		margin: 0,
		padding: 0,
	},
	configDrawerSection: {
		marginBottom: '1rem',
	},
	configDrawerSelectSig: {
		color: navigation,
		fontSize: '0.75rem',
		margin: '0 0 0.5rem 0',
		padding: 0,
		textTransform: 'uppercase',
	},
	configDrawerSubtitle: {
		color: navigation,
		display: 'flex',
		fontSize: '0.75rem',
	},
	configDrawerSubtitleRecipient: {
		margin: 0,
		padding: 0,
	},
	configDrawerThirdHeader: {
		display: 'block',
		fontSize: '1rem',
	},
	customOutlineButton: {
		color: midGrey,
		minWidth: 90,
		padding: '10px 20px',
	},
	divider: {
		background: nameCircles,
		flex: '0 0 1px',
	},
	dividerMargin: {
		margin: '1.5rem 0 1.5rem',
	},
	errorContactLoad: {
		color: error,
		margin: 0,
		padding: 0,
	},
	formErrorLine: {
		color: error,
		fontSize: '0.75rem',
	},
	hwVariationsDisclaimerLetter: {
		alignItems: 'center',
		backgroundColor: 'white',
		display: 'flex',
		justifyContent: 'center',
		padding: '0.25rem',
	},
	hwVariationsDisclaimerLetters: {
		display: 'flex',
		gap: '0.25rem',
		justifyContent: 'center',
		marginTop: '0.5rem !important',
	},
	hwVariationsDisclaimerText: {
		color: navigation,
		fontSize: '0.75rem',
		fontStyle: 'italic',
		margin: '1.5rem 0 0 !important',
	},
	label: {
		color: navigation,
		fontSize: 12,
		letterSpacing: 0,
	},
	labelRequired: {
		'::after': {
			color: destructiveHover,
			content: '"*"',
			display: 'inline',
			marginLeft: '0.25rem',
		},
	},
	mainPreview: {
		aspectRatio: '105 / 76',
		background: 'white',
		boxSizing: 'border-box',
		display: 'flex',
		filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
		flexDirection: 'column',
		maxWidth: 525,
		overflow: 'hidden',
		width: 525,
	},
	mainPreviewContainer: {
		background,
		boxSizing: 'border-box',
		display: 'flex',
		flex: '1 1 849px',
		flexDirection: 'column',
		minHeight: '100%',
		padding: '1rem 1.5rem',
	},
	mainPreviewContainer2: {
		alignItems: 'center',
		boxSizing: 'border-box',
		display: 'flex',
		flex: 1,
		justifyContent: 'center',
	},
	mainPreviewImage: {
		aspectRatio: '1 / 1',
		height: '100%',
		objectFit: 'cover',
		width: '100%',
	},
	mainPreviewModeImage: {
		alignItems: 'center',
	},
	mainPreviewModeText: {
		color: simplyNotedBlue,
		maxHeight: '100%',
		padding: '2.5rem',
		whiteSpace: 'pre-line',
	},
	mainPreviewSignature: {
		margin: '0 0 0 50%',
		padding: '2.5rem 0 0',
	},
	mainPreviewTextContent: {
		margin: 0,
		padding: 0,
	},
	mb16: {
		marginBottom: '1rem',
	},
	mb40: {
		marginBottom: '2.5rem',
	},
	mb56: {
		marginBottom: '3.5rem',
	},
	mb8: {
		marginBottom: '0.5rem',
	},
	modalFooter: {
		display: 'flex',
		marginTop: 30,
		...CssSelectors.allDescendants(
			{
				marginRight: 10,
			},
			'button'
		),
	},
	modalHeader: {
		marginBottom: 30,
	},
	modalHeaderName: {
		boxSizing: 'border-box',
		color: brandPrimaryText,
		fontSize: 24,
		margin: 0,
		width: '100%',
	},
	mt32: {
		marginTop: '2rem',
	},
	mt40: {
		marginTop: '2.5rem',
	},
	outlineButton: {
		boxSizing: 'border-box',
		color: brandPrimary,
		height: '2rem',
		width: 80,
	},
	personalizeButton: {
		marginTop: '4rem',
	},
	previewImage: {
		aspectRatio: '105 / 76',
		width: 525,
	},
	previewToggleItem: {
		alignItems: 'center',
		borderRadius: 8,
		color: grayIconFill,
		display: 'flex',
		fontSize: '0.625rem',
		padding: '8px 20px',
		...CssSelectors.allChildren(
			{
				marginLeft: '0.5rem',
			},
			':not(:first-child)'
		),
	},
	previewToggleItemGroup: {
		background: borderColor,
		borderRadius: 10,
		display: 'flex',
		padding: 5,
	},
	previewToggleItemToggled: {
		background: white,
		color: brandSecondary,
	},
	previewWrapper: {
		alignItems: 'center',
		display: 'flex',
		flex: '0 1 532px',
		flexDirection: 'column',
		...CssSelectors.allChildren(
			{
				marginTop: '1rem',
			},
			':not(:first-child)'
		),
	},
	recipientAddressModal: {
		width: 560,
	},
	requiredIndicator: {
		color: destructive,
		fontSize: 18,
	},
	requiredLabel: {
		color: destructive,
		fontSize: 11,
	},
	root: {
		display: 'flex',
		height: '100%',
	},
	saveAsDropdownCaret: {
		alignItems: 'center',
		borderLeftColor: brandPrimary,
		borderLeftStyle: 'solid',
		borderLeftWidth: 1,
		display: 'flex',
		height: '100%',
		justifyContent: 'center',
		padding: '0 6px',
	},
	selectRecipientContactAvatarFallback: {
		alignItems: 'center',
		backgroundColor: nameCircles,
		color: 'white',
		display: 'flex',
		fontSize: '1rem',
		height: '100%',
		justifyContent: 'center',
		lineHeight: 1,
		width: '100%',
	},
	selectRecipientContactAvatarImage: {
		borderRadius: 'inherit',
		height: '100%',
		objectFit: 'cover',
		width: '100%',
	},
	selectRecipientContactAvatarRoot: {
		alignItems: 'center',
		backgroundColor: nameCircles,
		borderRadius: '100%',
		display: 'inline-flex',
		height: 60,
		justifyContent: 'center',
		marginLeft: '0.5rem',
		minWidth: 60,
		overflow: 'hidden',
		userSelect: 'none',
		verticalAlign: 'middle',
		width: 60,
	},
	selectRecipientContactCompany: {
		color: header,
		fontSize: '0.875rem',
	},
	selectRecipientContactName: {
		color: titles,
		fontSize: '1.125rem',
	},
	selectRecipientContactNameContainer: {
		marginLeft: '1rem',
		overflow: 'hidden',
		...CssSelectors.allChildren(
			{
				marginTop: '0.125rem',
			},
			':not(:first-child)'
		),
	},
	selectRecipientContactPreview: {
		alignItems: 'center',
		display: 'flex',
	},
	selectRecipientContactPreviewAvatar: {
		marginLeft: '0.5rem',
		minWidth: 60,
	},
	selectRecipientContactResults: {
		maxWidth: 287,
	},
	selectRecipientContactSpinnerContainer: {
		alignItems: 'center',
		display: 'flex',
	},
	selectRecipientToSend: {
		fontSize: '1.125rem',
		margin: 0,
		padding: 0,
	},
	successStepContainer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		paddingTop: '6rem',
		width: '100%',
	},
	successStepCtaBtn: {
		marginTop: '2rem',
		width: 291,
	},
	successStepHeadline: {
		color: brandPrimaryText,
		fontSize: '1.125rem',
		marginTop: '1.5rem',
	},
	templateSaveModal: {
		':nth-child(1n) > .modal-overlay': {
			':nth-child(1n) > .modal-overlay-content': {
				width: 540,
			},
		},
	},
	total: {
		color: titles,
		...baseStyleSheet.fontBold,
		fontSize: '1.125rem',
		marginRight: '0.5rem',
	},
});
