import { StyleSheet } from 'aphrodite';
import { background, spanishBlue, success, successTint } from '../../../styles/colors';
import { CssSelectors, baseStyleSheet } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	homeIcon: {
		marginLeft: '0.25rem',
	},
	popoverContent: {
		background: successTint,
		border: `1px solid ${success}`,
		borderRadius: 3,
		fontSize: '0.75rem',
		maxWidth: 300,
		padding: '8px 12px',
		...CssSelectors.allChildren(
			{
				...baseStyleSheet.fontBold,
			},
			'strong'
		),
	},
	spouse: {
		color: spanishBlue,
		fontSize: 14,
		marginLeft: '0.25rem',
		textDecoration: 'none',
	},
	root: {
		':hover': {
			background,
		},
	},
});
