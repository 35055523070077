import { header, success, successTint } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	circle: {
		alignItems: 'center',
		background: success,
		border: `3px solid ${successTint}`,
		borderRadius: '50%',
		display: 'flex',
		height: 14,
		justifyContent: 'center',
		marginRight: 3,
		width: 14,
	},
	container: {
		color: header,
		display: 'flex',
		fontSize: '14px',
	},
	xMarkIcon: {
		height: 20,
		marginLeft: 10,
		width: 20,
	},
});
