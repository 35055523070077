import { IEntity, IPhoneNumber, TextMessageCapability } from '@ViewModels';
import { css } from 'aphrodite';
import { action, observable } from 'mobx';
import { inject, Observer, observer } from 'mobx-react';
import * as React from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { canViewTexting } from '../../../../extViewmodels/Utils';
import { PhoneNumberLabelDictionary, PhoneNumberLabels } from '../../../../models';

import {
	ErrorMessagesViewModelKey,
	FullScreenModalViewModelKey,
	IErrorMessageComponentProps,
	IFullscreenModalComponentProps,
	ITextMessagingComponentProps,
	IUserSessionComponentProps,
	TextMessagingViewModelKey,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import { baseStyleSheet } from '../../../styles/styles';
import { EntityInfoBulletList, IAddPhoneNotification } from '../../entities/EntityInfoBulletList';
import { EntityInfoFieldLabel } from '../../entities/EntityInfoFieldLabel';
import { ISelectBoxOption, SelectBox } from '../../SelectBox';
import { CanSMS } from '../../texting/CanSMS';
import { TextInput } from '../../TextInput';
import { styleSheet } from './styles';

interface IProps
	extends IUserSessionComponentProps,
		ITextMessagingComponentProps,
		IErrorMessageComponentProps,
		IEventLoggingComponentProps,
		RouteComponentProps<any>,
		IFullscreenModalComponentProps {
	addPhoneNotification?: IAddPhoneNotification;
	className?: string;
	disabled?: boolean;
	onRequestAddPhoneNumber?(phoneNumber: IPhoneNumber): void;
	onRequestRemovePhoneNumber?(phoneNumber: IPhoneNumber, index: number): void;
	onRequestUpdatePhoneNumber?(phoneNumber: IPhoneNumber, index: number): void;
	phoneNumbers: IPhoneNumber[];
	entity?: IEntity;
}

interface IState {
	disabled?: boolean;
	editItemIndex?: number;
	phoneNumbers?: IPhoneNumber[];
	showEditNewItemInput?: boolean;
	visibleLabels?: string[];
}

class _EntityInfoPhoneNumbers extends React.Component<IProps, IState> {
	// @ts-ignore
	@observable public mEditablePhoneNumber: IPhoneNumber;

	public readonly state: IState = {
		editItemIndex: -1,
	};

	public UNSAFE_componentWillMount() {
		const nextState = this.getNextStateWithProps(this.props);
		if (nextState) {
			this.setState(nextState);
		}
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		const nextState = this.getNextStateWithProps(nextProps);
		if (nextState) {
			this.setState(nextState);
		}
	}

	public render() {
		const { className, addPhoneNotification } = this.props;
		const { editItemIndex, showEditNewItemInput, phoneNumbers, disabled } = this.state;

		return (
			<div className={`${styleSheet.container} entity-info-phone-numbers ${className || ''}`}>
				<EntityInfoBulletList
					addButtonContent='Add phone number'
					deleteConfirmationContent='Do you want to delete this phone number?'
					disabled={disabled}
					editItemIndex={editItemIndex}
					itemClassName={css(styleSheet.item)}
					itemEditingClassName={css(styleSheet.itemEditing)}
					items={phoneNumbers}
					newItemFooterClassName={css(styleSheet.newItemFooter)}
					newItemFooterEditingClassName={css(styleSheet.newItemFooterEditing)}
					onAddNewButtonClicked={this.onAddNewButtonClicked}
					onItemEditButtonClicked={this.onItemEditButtonClicked}
					onRemoveItem={this.onRemovePhoneNumber}
					onRenderItem={this.onRenderPhoneNumber}
					onRenderItemEditor={this.onRenderEditor}
					addPhoneNotification={addPhoneNotification}
					showEditNewItemInput={showEditNewItemInput}
				/>
			</div>
		);
	}

	private onSendTextClicked = (phoneNumber: IPhoneNumber) => async () => {
		const { textMessaging, errorMessages, logApiError, history, fullscreenModal, entity } = this.props;
		try {
			// @ts-ignore
			if (!textMessaging.phoneNumberOrder) {
				history.push(`/texting`);
				return;
			}
			// @ts-ignore
			const conversation = await textMessaging.createConversation([phoneNumber.value]);
			// @ts-ignore
			conversation.updateRecipients({
				...entity,
				selectedPhoneNumber: phoneNumber,
			});
			// @ts-ignore
			fullscreenModal.dismissModal();
			// @ts-ignore
			// @ts-ignore
			history.push(`/texting/${textMessaging.id}/${conversation.id}`);
		} catch (err) {
			// @ts-ignore
			// @ts-ignore
			errorMessages.pushApiError(err);
			// @ts-ignore
			// @ts-ignore
			logApiError('CreateConversationFromContact-Error', err);
		}
	};

	private onRenderPhoneNumber = (
		phoneNumber: IPhoneNumber,
		index: number,
		___?: React.ReactNode,
		editButton?: React.ReactNode
	) => {
		const { visibleLabels } = this.state;
		const { userSession, textMessaging } = this.props;
		return (
			<>
				<div className={css(styleSheet.phoneNumber)}>
					{/* @ts-ignore */}
					<EntityInfoFieldLabel title={visibleLabels[index]} />
					<div className={css(baseStyleSheet.horizontalStack)}>
						<div className={css(baseStyleSheet.verticalStack)}>
							<span className={css(styleSheet.phoneNumberText)}>
								{/* @ts-ignore */}
								{phoneNumber?.metadata?.standard.includes(')') && phoneNumber?.metadata?.standard?.length === 14
									? phoneNumber.metadata.standard
									: phoneNumber.value}
							</span>
							<CanSMS phoneNumber={phoneNumber} />
						</div>
						{/* @ts-ignore */}
						{canViewTexting(userSession) && textMessaging && phoneNumber.canSMS !== TextMessageCapability.No && (
							<button
								className={css(baseStyleSheet.ctaButtonSmall, styleSheet.sendTextButton)}
								disabled={
									phoneNumber.label?.toLocaleLowerCase() === 'fax' ||
									phoneNumber.label?.toLocaleLowerCase() === 'office'
								}
								onClick={this.onSendTextClicked(phoneNumber)}
							>
								<span>Send Text</span>
							</button>
						)}
					</div>
					<div className={css(styleSheet.editButton)}>{editButton}</div>
				</div>
			</>
		);
	};

	private onRenderEditor = (_: IPhoneNumber, removeButton?: React.ReactNode) => {
		return (
			<Observer>
				{() => {
					const { disabled } = this.state;
					const phoneNumber = this.mEditablePhoneNumber;
					const lowerLabel = phoneNumber.label ? phoneNumber.label.toLowerCase() : null;
					const hasDefaultLabel = !!lowerLabel && !!PhoneNumberLabelDictionary[lowerLabel];

					// if the number has a label and the label isn't one of the defaults, add it to the list
					const options =
						!!lowerLabel && !hasDefaultLabel
							? [{ title: phoneNumber.label, value: lowerLabel }, ...PhoneNumberLabels]
							: PhoneNumberLabels;
					return (
						<>
							{removeButton}
							<div className={css(styleSheet.phoneNumberEditor)}>
								<div className={css(styleSheet.phoneNumberEditorRow)}>
									<SelectBox
										className={css(styleSheet.phoneNumberSelectBox)}
										onSelectionChanged={this.onPhoneNumberLabelChanged}
										options={options}
										optionClassName={css(styleSheet.phoneNumberSelectBoxOption)}
										placeholder={<span className={css(styleSheet.placeholder)}>Label...</span>}
										selectedOption={lowerLabel ? options.find(x => x.value === lowerLabel) : undefined}
										triggerClassName={css(styleSheet.phoneNumberSelectBoxTrigger)}
									/>
								</div>
								<div className={css(styleSheet.phoneNumberEditorRow)}>
									<TextInput
										autoComplete='off'
										className={css(styleSheet.bulletListItemTextField)}
										inputClassName={css(styleSheet.bulletListItemTextFieldInput)}
										inputId='edit-entity-phone-number-input'
										onChange={this.onPhoneNumberValueChanged}
										type='text'
										value={phoneNumber.value || ''}
									/>
									<button
										className={css(baseStyleSheet.ctaButtonSmall)}
										disabled={!!disabled}
										onClick={this.onSaveButtonClicked}
									>
										<span>Save</span>
									</button>
									<button className='brand-link' disabled={!!disabled} onClick={this.onCancelButtonClicked}>
										<span>Cancel</span>
									</button>
								</div>
							</div>
						</>
					);
				}}
			</Observer>
		);
	};

	private getNextStateWithProps = (nextProps: IProps) => {
		const nextState: IState = {};

		if (!this.state.phoneNumbers || nextProps.phoneNumbers !== this.props.phoneNumbers) {
			const phoneNumbers = [...(nextProps.phoneNumbers || [])];
			let runningIndex = 0;
			const visibleLabels = phoneNumbers.map(x => {
				if (!x.label) {
					runningIndex++;
					return `Phone ${runningIndex}`;
				}
				return x.label;
			});

			nextState.phoneNumbers = phoneNumbers;
			nextState.visibleLabels = visibleLabels;
		}

		if (this.state.disabled !== nextProps.disabled) {
			nextState.disabled = nextProps.disabled;
		}

		return Object.keys(nextState).length > 0 ? nextState : null;
	};

	@action
	private onAddNewButtonClicked = () => {
		this.mEditablePhoneNumber = {
			// @ts-ignore
			label: null,
			// @ts-ignore
			value: null,
		};

		this.setState({
			editItemIndex: -1,
			showEditNewItemInput: true,
		});
	};

	private onCancelButtonClicked = () => {
		this.setState(
			{
				editItemIndex: -1,
				showEditNewItemInput: false,
			},
			() => {
				// @ts-ignore
				this.mEditablePhoneNumber = null;
			}
		);
	};

	@action
	private onRemovePhoneNumber = (phoneNumber: IPhoneNumber) => {
		const { onRequestRemovePhoneNumber } = this.props;
		const { phoneNumbers } = this.state;

		if (onRequestRemovePhoneNumber) {
			// @ts-ignore
			onRequestRemovePhoneNumber(phoneNumber, phoneNumbers.indexOf(phoneNumber));
		} else {
			// @ts-ignore
			const phoneNumbersToSave = [...phoneNumbers, this.mEditablePhoneNumber];

			this.setState({
				phoneNumbers: phoneNumbersToSave,
			});
		}

		// @ts-ignore
		this.mEditablePhoneNumber = null;
		this.setState({
			editItemIndex: -1,
			showEditNewItemInput: false,
		});
	};

	@action
	private onSaveButtonClicked = () => {
		const { onRequestAddPhoneNumber, onRequestUpdatePhoneNumber } = this.props;
		const { editItemIndex, phoneNumbers } = this.state;
		const nextState: IState = {
			editItemIndex: -1,
			showEditNewItemInput: false,
		};

		// @ts-ignore
		if (editItemIndex >= 0) {
			// editing existing
			if (onRequestUpdatePhoneNumber) {
				// @ts-ignore
				onRequestUpdatePhoneNumber({ ...this.mEditablePhoneNumber }, editItemIndex);
			} else {
				// @ts-ignore
				const phoneNumbersToSave = [...phoneNumbers];
				// @ts-ignore
				phoneNumbersToSave.splice(editItemIndex, 1, {
					...this.mEditablePhoneNumber,
				});
				nextState.phoneNumbers = phoneNumbersToSave;
			}
		} else {
			if (onRequestAddPhoneNumber) {
				onRequestAddPhoneNumber({ ...this.mEditablePhoneNumber });
			} else {
				// @ts-ignore
				nextState.phoneNumbers = [...phoneNumbers, this.mEditablePhoneNumber];
			}
		}

		// @ts-ignore
		this.mEditablePhoneNumber = null;
		this.setState(nextState);
	};

	private onPhoneNumberLabelChanged = (selectedOption: ISelectBoxOption<string>) => {
		this.mEditablePhoneNumber.label = selectedOption.title as string;
	};

	private onPhoneNumberValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.mEditablePhoneNumber.value = e.target.value;
	};

	@action
	private onItemEditButtonClicked = (phoneNumber: IPhoneNumber, index: number) => {
		this.mEditablePhoneNumber = {
			// @ts-ignore
			label: null,
			// @ts-ignore
			value: null,
			...phoneNumber,
		};
		this.setState({
			editItemIndex: index,
			showEditNewItemInput: false,
		});
	};
}

const EntityInfoPhoneNumbersAsObserver = observer(_EntityInfoPhoneNumbers);
const EntityInfoPhoneNumbersWithLogging = withEventLogging(EntityInfoPhoneNumbersAsObserver);
const EntityInfoPhoneNumbersWithRouter = withRouter(EntityInfoPhoneNumbersWithLogging);
export const EntityInfoPhoneNumbers = inject(
	UserSessionViewModelKey,
	TextMessagingViewModelKey,
	ErrorMessagesViewModelKey,
	FullScreenModalViewModelKey
)(EntityInfoPhoneNumbersWithRouter);
