import { StyleSheet } from 'aphrodite';
import { grayIconFill } from '../../../../styles/colors';

export const styleSheet = StyleSheet.create({
	companiesIcon: {
		marginTop: 4,
	},
	company: {
		color: grayIconFill,
		fontSize: 11,
		fontStyle: 'italic',
		marginLeft: 10,
	},
	container: {
		':hover': {
			background: 'rgba(232, 243, 249, 0.5)',
		},
		alignItems: 'center',
		boxSizing: 'border-box',
		cursor: 'pointer',
		display: 'flex',
		padding: '5px 50px',
	},
	highlight: {
		background: 'rgba(232, 243, 249, 0.5)',
	},
	icon: {
		position: 'relative',
	},
	name: {
		marginLeft: 10,
	},
});
