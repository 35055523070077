import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fill?: string;
}

export const InviteTeammatesIcon: React.FC<IProps> = ({ className, fill = '#fff' }) => {
	const height = 20;
	const width = 22;
	return (
		<SvgIcon className={className} height={height} viewBox={`0 0 ${width} ${height}`} width={width}>
			<g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
				<g transform='translate(-970.000000, -103.000000)' fill={fill}>
					<g transform='translate(970.000000, 103.000000)'>
						<path d='M8,6 C6.897,6 6,6.896 6,8 C6,9.103 6.897,10 8,10 C9.103,10 10,9.103 10,8 C10,6.896 9.103,6 8,6 M8,12 C5.794,12 4,10.206 4,8 C4,5.794 5.794,4 8,4 C10.206,4 12,5.794 12,8 C12,10.206 10.206,12 8,12' />
						<path d='M2.1587,18 L13.8417,18 C13.2437,16.192 11.0077,15 7.9997,15 C4.9917,15 2.7567,16.192 2.1587,18 L2.1587,18 Z M15.9997,20 L-0.0003,20 L-0.0003,19 C-0.0003,15.467 3.2897,13 7.9997,13 C12.7107,13 15.9997,15.467 15.9997,19 L15.9997,20 Z' />
						<polygon points='22 3 19 3 19 0 17 0 17 3 14 3 14 5 17 5 17 8 19 8 19 5 22 5' />
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
