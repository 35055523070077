import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { LoginErrorArea } from '../../../viewmodels/AppViewModels';
import { Popover, PopoverType } from '../../components/Popover';
import { LoginFormErrorIcon } from '../../components/svgs/icons/LoginFormErrorIcon';
import { baseStyleSheet } from '../../styles/styles';
import { InputField } from './InputField';
import { useLoginContext } from './context';
import { styleSheet } from './styles';

export interface ILoginFormFieldProps {
	inputFieldClassName?: string;
	labelStyles?: StyleDeclarationValue[];
	onKeyDown?(e: React.KeyboardEvent<HTMLInputElement>): void;
}

export const LoginEmailField: React.FC<ILoginFormFieldProps> = observer(
	({ labelStyles = [], inputFieldClassName, onKeyDown }) => {
		const { loginViewModel } = useLoginContext();
		const onEmailChanged = React.useCallback(
			(e: React.ChangeEvent<HTMLInputElement>) => {
				if (!loginViewModel) {
					return;
				}
				loginViewModel.email = e.target.value;
			},
			[loginViewModel]
		);
		return (
			<>
				<span className={css(styleSheet.formFieldLabel, ...labelStyles)}>Email</span>
				<InputField
					autoComplete='off'
					className={inputFieldClassName}
					id='login-email'
					onChange={onEmailChanged}
					onKeyDown={onKeyDown}
					type='email'
					value={loginViewModel?.email || ''}
				>
					<input type='text' className={css(baseStyleSheet.hidden)} />
					<input type='password' className={css(baseStyleSheet.hidden)} />
					{!!loginViewModel?.hasErrorMessage('email') && <LoginFormErrorIcon className={css(styleSheet.errorImage)} />}
				</InputField>
			</>
		);
	}
);

export const LoginPasswordField: React.FC<ILoginFormFieldProps> = observer(
	({ inputFieldClassName, labelStyles = [], onKeyDown }) => {
		const { loginViewModel } = useLoginContext();
		const onPasswordChanged = React.useCallback(
			(e: React.ChangeEvent<HTMLInputElement>) => {
				if (!loginViewModel) {
					return;
				}
				loginViewModel.password = e.target.value;
			},
			[loginViewModel]
		);
		return (
			<>
				<span className={css(styleSheet.formFieldLabel, ...labelStyles)}>Password</span>
				<InputField
					autoComplete='password'
					className={inputFieldClassName}
					id='login-password'
					onChange={onPasswordChanged}
					type='password'
					onKeyDown={onKeyDown}
					value={loginViewModel?.password || ''}
				>
					{!!loginViewModel?.hasErrorMessage('password') && (
						<LoginFormErrorIcon className={css(styleSheet.errorImage)} />
					)}
				</InputField>
			</>
		);
	}
);

export const LoginPhoneNumberField: React.FC<ILoginFormFieldProps> = observer(
	({ inputFieldClassName, labelStyles = [], onKeyDown }) => {
		const { loginViewModel } = useLoginContext();
		const onPhoneNumberChanged = React.useCallback(
			(e: React.ChangeEvent<HTMLInputElement>) => {
				if (!loginViewModel) {
					return;
				}
				loginViewModel.phoneNumber = e.target.value;
			},
			[loginViewModel]
		);
		return (
			<>
				<span className={css(styleSheet.formFieldMessage, ...labelStyles)}>
					For added security, please enter your mobile phone number. We&apos;ll text you when you log in from new
					devices.
				</span>
				<InputField
					autoComplete='phone'
					autoFocus={true}
					className={inputFieldClassName}
					id='login-phone'
					onChange={onPhoneNumberChanged}
					onKeyDown={onKeyDown}
					type='text'
					value={loginViewModel?.phoneNumber || ''}
				/>
			</>
		);
	}
);

export const Login2FAFields: React.FC<ILoginFormFieldProps> = observer(props => {
	const { loginViewModel } = useLoginContext();
	const { inputFieldClassName, labelStyles = [], onKeyDown } = props;

	const onHandleKeyDown = React.useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
			onKeyDown?.(e);
			if (!e.defaultPrevented && e.key === 'Enter' && loginViewModel) {
				loginViewModel.submit();
			}
		},
		[loginViewModel, onKeyDown]
	);

	const onChallengeCodeChanged = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (loginViewModel) {
				loginViewModel.challengeCode = e.target.value;
			}
		},
		[loginViewModel]
	);

	const resendCode = React.useCallback(() => {
		if (loginViewModel) {
			loginViewModel.challengeCode = undefined;
			loginViewModel.submit();
		}
	}, [loginViewModel]);

	if (loginViewModel?.needsPhone) {
		return <LoginPhoneNumberField {...props} />;
	}

	// render code field
	if (loginViewModel?.challenging) {
		return (
			<>
				<span className={css(styleSheet.formFieldMessage)}>
					{loginViewModel?.challengeCodeSystemMessage}
					<span
						onClick={resendCode}
						className={css(styleSheet.resend, baseStyleSheet.brandSecondaryLink, ...labelStyles)}
					>
						Resend code
					</span>
				</span>
				<InputField
					autoComplete='off'
					autoFocus={true}
					id='login-code'
					className={inputFieldClassName}
					onChange={onChallengeCodeChanged}
					onKeyDown={onHandleKeyDown}
					type='text'
					value={loginViewModel?.challengeCode || ''}
				/>
			</>
		);
	}

	return null;
});

export interface IFormFieldErrorMessagePopoverProps {
	fieldName: LoginErrorArea;
}

export const FormFieldErrorMessagePopover: React.FC<IFormFieldErrorMessagePopoverProps> = observer(
	({ children, fieldName }) => {
		const { loginViewModel } = useLoginContext();
		return (
			<Popover
				className={css(styleSheet.formFieldPopover)}
				anchor={children}
				isOpen={loginViewModel?.hasErrorMessage(fieldName)}
				type={PopoverType.error}
				contentClassName='login-error-popover'
				preferredPlacement='right'
			>
				<span>{loginViewModel?.getErrorMessage(fieldName)}</span>
			</Popover>
		);
	}
);
