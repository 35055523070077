import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../models/Logging';
import { ISelectBoxOption, SelectBox } from '../SelectBox';
import { PrivateVisibilityIcon } from '../svgs/icons/PrivateVisibilityIcon';
import { PublicVisibilityIcon } from '../svgs/icons/PublicVisibilityIcon';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';

export type ResourceVisibility = 'all' | 'admin' | string;

type VisibilityDropdownOption = ISelectBoxOption<ResourceVisibility> & {
	stringValue: string;
};

const VisibilityOptions: VisibilityDropdownOption[] = [
	{
		stringValue: 'all employees',
		title: (
			<div className={css(styleSheet.option)}>
				<PublicVisibilityIcon />
				<div className={css(styleSheet.optionTitle)}>all employees</div>
			</div>
		),
		value: 'all',
	},
	{
		stringValue: 'private',
		title: (
			<div className={css(styleSheet.option)}>
				<PrivateVisibilityIcon />
				<div className={css(styleSheet.optionTitle)}>private</div>
			</div>
		),
		value: 'admin',
	},
];

const AdminVisibilityOption = VisibilityOptions.find(x => x.value === 'admin');

interface IProps extends IUserSessionComponentProps, IEventLoggingComponentProps {
	className?: string;
	disabled?: boolean;
	onVisibilityChanged?(visibility: ResourceVisibility): void;
	visibility: ResourceVisibility;
}

interface IState {
	selectedOption?: VisibilityDropdownOption;
}

class _VisibilityDropdown extends React.Component<IProps, IState> {
	public readonly state: IState = {};

	public UNSAFE_componentWillMount() {
		const visibility = this.props.visibility === 'all' ? 'all' : 'admin';
		this.setState({
			selectedOption: VisibilityOptions.find(x => x.value === visibility),
		});
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		if (!!this.state.selectedOption && nextProps.visibility !== this.state.selectedOption.value) {
			const selectedOption = VisibilityOptions.find(x => x.value === nextProps.visibility) || AdminVisibilityOption;
			this.setState({
				selectedOption,
			});
		}
	}

	public render() {
		const { className, disabled } = this.props;
		const { selectedOption } = this.state;
		return (
			<div className={`${css(styleSheet.container)} visibility-dropdown ${className || ''}`}>
				<SelectBox
					className={css(styleSheet.selectBox)}
					disabled={disabled}
					menuClassName={css(styleSheet.menu)}
					onRenderSelectedOptionTitle={this.onRenderSelectedOptionTitle}
					onSelectionChanged={this.onSelectionChanged}
					options={VisibilityOptions}
					selectedOption={selectedOption}
					triggerClassName={css(styleSheet.pill)}
				/>
			</div>
		);
	}

	private onSelectionChanged = (selectedOption: VisibilityDropdownOption) => {
		const { onVisibilityChanged } = this.props;
		if (onVisibilityChanged) {
			// @ts-ignore
			onVisibilityChanged(selectedOption.value);
		} else {
			this.setState({
				selectedOption,
			});
		}
	};

	/** Selected option title transformer */
	private onRenderSelectedOptionTitle = (selectedOption: VisibilityDropdownOption) => {
		return (
			<div className={css(styleSheet.visibility)}>
				{selectedOption.value === 'all' ? <PublicVisibilityIcon /> : <PrivateVisibilityIcon />}
				<div className={css(styleSheet.visibilityTitle)}>{`Visibility: ${selectedOption.stringValue}`}</div>
			</div>
		);
	};
}

const VisibilityDropdownAsObserver = observer(_VisibilityDropdown);
const VisibilityDropdownWithContext = inject(UserSessionViewModelKey)(VisibilityDropdownAsObserver);
export const VisibilityDropdown = withEventLogging(VisibilityDropdownWithContext, 'VisibilityDropdown');
