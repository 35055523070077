import { StyleSheet } from 'aphrodite';
import { background, brandPrimary, brandPrimaryHover, darkGrayFontColor, titles } from '../../styles/colors';
import { CssSelectors, absoluteCenterStyle, truncateTextStyle } from '../../styles/styles';

export const selectBoxHeight = 40;

export const styleSheet = StyleSheet.create({
	blueOption: {
		':hover:not(.select-option-hover-prevented)': {
			background: brandPrimaryHover,
		},
		background: brandPrimary,
		color: 'white',
	},
	checkmark: {
		border: `1px solid ${darkGrayFontColor}`,
		borderRadius: 6,
		display: 'inline-block',
		height: 14,
		marginRight: 10,
		position: 'relative',
		width: 14,
	},
	container: {
		background: '#fff',
		border: '1px solid #d8d8d8',
		borderRadius: 6,
		boxSizing: 'border-box',
		fontSize: 14,
		maxWidth: 300,
		width: '100%',
	},
	containerOpen: {
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
	},
	icon: {
		display: 'inline-block',
		height: 20,
		marginRight: 10,
		position: 'relative',
		width: 20,
	},
	iconWrapper: {
		height: 'auto',
		maxHeight: 20,
		maxWidth: 20,
		overflow: 'hidden',
		width: 'auto',
		...absoluteCenterStyle,
	},
	infoIcon: {
		marginLeft: 4,
		marginTop: 5,
	},
	infoMessage: {
		background,
		borderRadius: 6,
		boxShadow: '0 0 3px 1px rgba(0,0,0,0.1)',
		color: titles,
		fontSize: 12,
		maxWidth: 300,
		padding: 10,
		transform: 'translate3d(0,2px,0)',
	},
	option: {
		':hover:not(.select-option-hover-prevented)': {
			backgroundColor: brandPrimaryHover,
			cursor: 'pointer',
		},
		alignItems: 'center',
		boxSizing: 'border-box',
		color: darkGrayFontColor,
		display: 'flex',
		flexDirection: 'row',
		height: '100%',
		justifyContent: 'space-between',
		padding: '0 15px',
	},
	optionContainer: {
		background: '#fff',
		boxSizing: 'border-box',
		fontSize: 14,
		height: selectBoxHeight,
		width: '100%',
	},
	optionInner: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		overflow: 'hidden',
		width: '100%',
	},
	optionsContainer: {
		height: 'auto',
		overflowY: 'auto',
		width: '100%',
	},
	popover: {
		':not(.Popover-above)': {
			borderBottomLeftRadius: 6,
			borderBottomRightRadius: 6,
		},
		background: '#fff',
		border: '1px solid #d8d8d8',
		borderTopLeftRadius: 6,
		borderTopRightRadius: 6,
		boxSizing: 'border-box',
		overflow: 'hidden',
		width: 'auto',
		...CssSelectors.allDescendants(
			{
				// required to overwrite popover styles
				width: '100%',
			},
			'.popover-content'
		),
		...CssSelectors.allDescendants(
			{
				// required to overwrite popover styles
				border: 'none !important',
				borderRadius: '6px !important',
				boxShadow: 'none !important',
			},
			':not(.select-checkmark):not(.select-icon):not(.select-option):not(svg)'
		),
	},
	selected: {
		':not(.select-trigger)': {
			background: '#f0f0f0',
		},
	},
	selectedBlue: {
		':not(.select-trigger)': {
			background: '#f0f0f0',
		},
	},
	selectText: {
		...truncateTextStyle,
		maxWidth: '100%',
	},
	trigger: {
		maxWidth: 'calc(100% - 20px)',
	},
	triggerCaret: {
		flexShrink: 0,
		transform: 'rotateZ(90deg)',
	},
	triggerContainer: {
		...truncateTextStyle,
		':hover': {
			cursor: 'pointer',
		},
		alignItems: 'center',
		boxSizing: 'border-box',
		color: darkGrayFontColor,
		display: 'flex',
		height: selectBoxHeight,
		justifyContent: 'space-between',
		padding: '0 15px',
		width: '100%',
		fontSize: 14,
	},
});
