import { EnvironmentKey, IEnvironmentComponentProps } from '../../../models/AppState';
import './styles.less';
import { inject } from 'mobx-react';
import * as React from 'react';
import ReactPopover, { PopoverPlacement } from 'react-popover';
import { v4 as uuidgen } from 'uuid';

export interface IPopoverContext {
	registerCloseLock?(): () => void;
}

export const PopoverContext = React.createContext<IPopoverContext>({});

export interface IParentPopoverProps {
	preventClose?: boolean;
}

export const ParentPopover: React.FC<IParentPopoverProps> = props => {
	const closeLockRef = React.useRef<() => void>(null);
	const ctx = React.useContext(PopoverContext);
	React.useEffect(() => {
		closeLockRef.current?.();
		if (!!ctx?.registerCloseLock && props.preventClose) {
			// @ts-ignore
			closeLockRef.current = ctx.registerCloseLock();
		}
		return () => {
			closeLockRef.current?.();
		};
	}, [props.preventClose, ctx]);
	return null;
};

interface IProps extends IEnvironmentComponentProps {
	anchor: React.ReactNode;
	children: React.ReactNode;
	className?: string;
	contentClassName?: string;
	dismissOnClickOutside?: boolean;
	isOpen?: boolean;
	onRequestClose?(): void;
	place?: PopoverPlacement;
	preferredPlacement?: PopoverPlacement;
	tipSize?: number;
	type?: PopoverType;
}

interface IState {
	isOpen?: boolean;
	popoverContext?: IPopoverContext;
}

export enum PopoverType {
	custom = 0,
	error,
	white,
	emailGuide,
	blue,
	gray,
}

const getPopperClassNameForType = (type: PopoverType) => {
	switch (type) {
		case PopoverType.error: {
			return 'popover-content-error';
		}
		case PopoverType.white: {
			return 'popover-content-white';
		}
		case PopoverType.blue: {
			return 'popover-content-blue';
		}
		case PopoverType.emailGuide: {
			return 'popover-content-email-guide';
		}
		case PopoverType.gray: {
			return 'popover-content-gray';
		}
		default: {
			return '';
		}
	}
};

class _Popover extends React.Component<IProps, IState> {
	public static defaultProps: IProps = {
		anchor: null,
		children: null,
		type: PopoverType.custom,
	};

	public static getDerivedStateFromProps(props: IProps, state: IState) {
		const nextState: IState = {};

		if (state.isOpen !== props.isOpen) {
			nextState.isOpen = props.isOpen;
		}

		return Object.keys(nextState).length > 0 ? nextState : null;
	}

	private mCloseLocks: string[];

	constructor(props: IProps) {
		super(props);
		this.mCloseLocks = [];
		this.state = {
			isOpen: props.isOpen,
			popoverContext: {
				registerCloseLock: this.registerCloseLock,
			},
		};
	}

	public render() {
		// @ts-ignore
		const popperClassName = getPopperClassNameForType(this.props.type);
		// @ts-ignore
		const constraintClass = this.props.environment.appType === 'plugin' ? 'popover-content-plugin' : '';
		return (
			// @ts-ignore
			<ReactPopover
				onOuterAction={this.props.dismissOnClickOutside ? this.onOuterAction : undefined}
				className={`${this.props.className || ''} ${popperClassName}`}
				body={
					<div className={`popover-content ${constraintClass} ${this.props.contentClassName || ''}`}>
						{/* @ts-ignore */}
						<PopoverContext.Provider value={this.state.popoverContext}>{this.props.children}</PopoverContext.Provider>
					</div>
				}
				isOpen={this.state.isOpen}
				place={this.props.place || null}
				preferPlace={this.props.preferredPlacement || null}
				tipSize={this.props.tipSize}
			>
				{this.props.anchor}
			</ReactPopover>
		);
	}

	private registerCloseLock = () => {
		const lockId = uuidgen();
		this.mCloseLocks.push(lockId);
		return () => {
			this.mCloseLocks = this.mCloseLocks.filter(x => x !== lockId);
		};
	};

	private onOuterAction = () => {
		if (this.mCloseLocks.length > 0) {
			return;
		}
		if (this.props.onRequestClose) {
			this.props.onRequestClose();
		} else {
			this.setState({
				isOpen: false,
			});
		}
	};
}

// @ts-ignore
export const Popover = inject(EnvironmentKey)(_Popover);
