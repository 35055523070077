import { StyleSheet } from 'aphrodite';
import { brandPrimaryText, header, titles } from '../../../styles/colors';
import { CssSelectors, cardStyle } from '../../../styles/styles';

const contentPaddingX = 20;

export const styleSheet = StyleSheet.create({
	automationCard: {
		...cardStyle,
		boxSizing: 'border-box',
		padding: '15px 20px',
		position: 'relative',
	},
	automationCardLabel: {
		margin: '12px 0 8px 0',
	},
	automationCardSelectButton: {
		color: brandPrimaryText,
	},
	automationCardSteps: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
	},
	automationCardSubTitle: {
		color: header,
		fontSize: 16,
		margin: '0 0 16px',
	},
	automationCardTitle: {
		color: brandPrimaryText,
		fontSize: 18,
		margin: 0,
	},
	body: {
		boxSizing: 'border-box',
		maxHeight: 495,
		overflowY: 'auto',
		padding: `25px ${contentPaddingX}px 26px ${contentPaddingX}px`,
		width: 500,
	},
	bodyOverflowVisible: {
		overflowY: 'visible',
	},
	buttonContainer: {
		display: 'flex',
		...CssSelectors.allChildren(
			{
				marginLeft: '0.5rem',
			},
			':not(:first-child)'
		),
	},
	contactOwners: {
		margin: 0,
	},
	container: {
		fontSize: 14,
	},
	ctaButton: {
		marginRight: 10,
	},
	formControl: {
		display: 'flex',
		margin: '0 0 20px',
	},
	formControlAdjusted: {
		margin: '0 0 10px',
	},
	header: {
		color: brandPrimaryText,
		fontSize: 24,
		margin: '0 0 8px',
	},
	modal: {
		...CssSelectors.allDescendants(
			{
				padding: 0,
			},
			'.modal-overlay-content'
		),
	},
	searchFieldWrap: {
		marginLeft: 10,
		width: 'calc(100% - 155px)',
	},
	selectedAutomation: {
		borderBottom: '1px solid #ccc',
		marginBottom: '32px',
		paddingBottom: '32px',
	},
	title: {
		color: titles,
		fontSize: 18,
		marginBottom: 20,
		marginTop: 0,
	},
});
