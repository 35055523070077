import * as React from 'react';
import { grayIconFill } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';

interface IProps {
	className?: string;
	fill?: string;
	onMouseEnter?: React.MouseEventHandler<SVGSVGElement>;
	onMouseLeave?: React.MouseEventHandler<SVGSVGElement>;
}

export const ReferAFriendIcon: React.FC<IProps> = props => {
	const { className, fill } = props;
	return (
		<SvgIcon className={`refer-a-friend-icon ${className || ''}`} height={22} width={20}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M17 13c.827 0 1.5.673 1.5 1.5S17.827 16 17 16s-1.5-.673-1.5-1.5.673-1.5 1.5-1.5Zm2.371 4.058A3.479 3.479 0 0 0 20.5 14.5c0-1.93-1.57-3.5-3.5-3.5s-3.5 1.57-3.5 3.5c0 1.013.439 1.918 1.129 2.558C13.151 17.593 12 18.62 12 20h2c0-.598 1.413-1.357 3-1.357s3 .759 3 1.357h2c0-1.38-1.151-2.407-2.629-2.942ZM5 13c.827 0 1.5.673 1.5 1.5S5.827 16 5 16s-1.5-.673-1.5-1.5S4.173 13 5 13Zm2.371 4.058A3.479 3.479 0 0 0 8.5 14.5C8.5 12.57 6.93 11 5 11s-3.5 1.57-3.5 3.5c0 1.013.439 1.918 1.129 2.558C1.151 17.593 0 18.62 0 20h2c0-.598 1.413-1.357 3-1.357S8 19.402 8 20h2c0-1.38-1.151-2.407-2.629-2.942ZM11 2c.827 0 1.5.673 1.5 1.5S11.827 5 11 5s-1.5-.673-1.5-1.5S10.173 2 11 2Zm3 7h2c0-1.38-1.151-2.407-2.629-2.942A3.479 3.479 0 0 0 14.5 3.5C14.5 1.57 12.93 0 11 0S7.5 1.57 7.5 3.5c0 1.013.439 1.918 1.129 2.558C7.151 6.593 6 7.62 6 9h2c0-.598 1.413-1.357 3-1.357S14 8.402 14 9Z'
				fill={fill || grayIconFill}
			/>
		</SvgIcon>
	);
};
