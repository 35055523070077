import * as Api from '@ViewModels';
import moment from 'moment';
import { CampaignType } from '../../../../models/AdminModels';
import { ITemplateCard } from '../../../../viewmodels/AppViewModels';

export interface ICalendarDropTargetComponentProps {
	droppableType: string;
	onRequestDroppableId?(moment: moment.Moment): string;
}

export interface ICampaignCalendarEventOptions {
	hideApprovalCtas?: boolean;
	showScheduledFor?: boolean;
}

export interface ICampaignCalendarTimeSpanOptions {
	eventOptions?: ICampaignCalendarEventOptions;
	groupEventsByGroupId?: boolean;
}

export interface ICampaignCalendarOptions {
	timeSpanOptions?: ICampaignCalendarTimeSpanOptions;
}

export interface ICalendarTimeSpan {
	blogReports?: Api.IBlogReportView[];
	campaigns?: Api.CampaignViewModel[];
	campaignsByGroupId?: Api.IDictionary<Api.CampaignViewModel[]>;
	moment: moment.Moment;
	socialCampaigns?: Api.SocialMediaPostReportViewModel[];
	title?: string;
	unitOfTime: moment.unitOfTime.Base;
}

export interface ICampaignCalendarEventPlaceholder<T = ITemplateCard> {
	campaignType: CampaignType;
	completedDate?: string;
	dataContext?: T;
	moment: moment.Moment;
	schedule?: Api.IScheduledSend;
	status: Api.EmailSendStatus | Api.PostStatus | Api.BlogStatus;
	subject?: string;
}

export type EventDataType =
	| Api.CampaignViewModel[]
	| Api.CampaignViewModel
	| Api.SocialMediaPostReportViewModel
	| Api.IBlogReportView;

export const SelectedPostStatuses = [
	Api.PostStatus.PartiallySucceeded,
	Api.PostStatus.Pending,
	Api.PostStatus.Scheduled,
	Api.PostStatus.Started,
	Api.PostStatus.Succeeded,
];

export const DefaultCampaignCalendarBlogStatuses = [
	Api.BlogStatus.Pending,
	Api.BlogStatus.Queued,
	Api.BlogStatus.Completed,
];

export const getSuggestionCampaignType = (suggestion: Api.IContentCalendarSuggestion) => {
	switch (suggestion?.templateReference?.templateType) {
		case Api.TemplateType.SocialMediaPost:
			return CampaignType.Social;
		case Api.TemplateType.Blog:
			return CampaignType.Blog;
		default:
			return CampaignType.Email;
	}
};
