import { ForgotPassword } from '../../../components/ForgotPassword';
import { asModalComponent } from '../../../components/Modal';

const ForgotPasswordModalBase = () => {
	return <ForgotPassword hideLogo={true} />;
};

export const ForgotPasswordModal = asModalComponent(ForgotPasswordModalBase, {
	useDefaultHeader: true,
});
