import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { useEventLogging } from '../../../../models/Logging';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { useReportsContactByEntityQuery } from '../../../../queries';
import { LoadingSpinner } from '../../LoadingSpinner';
import { ISelectOption, Select } from '../../Select';
import { styleSheet } from './styles';

const TimespanOptions: ISelectOption<number>[] = [180, 90, 60, 30].map<ISelectOption<number>>(x => {
	return {
		dataContext: x,
		id: `timespan-option-${x}`,
		text: `${x} days`,
	};
});

export function ContactAnalysisHeader({ contactId }: { contactId: string }) {
	const userSession = useUserSession();
	const { logInput } = useEventLogging('ContactAnalysisHeader');
	const [selectedTimespanInDays, setSelectedTimespanInDays] = React.useState(TimespanOptions[0].dataContext);
	const reportsContactByEntityQuery = useReportsContactByEntityQuery({
		endDate: moment().endOf('day').toISOString(),
		entityId: contactId,
		startDate: moment().subtract(selectedTimespanInDays, 'days').startOf('day').toISOString(),
	});

	const onTimeSpanChanged = (option: ISelectOption<number>) => {
		if (option.dataContext !== selectedTimespanInDays) {
			logInput('SetTimeSpan', 'Click', {
				timeSpan: option.dataContext,
			});
			setSelectedTimespanInDays(option.dataContext);
		}
	};

	const renderActivityMetric = (title: string, resourceActivity: Api.IResourceActivity | null) => {
		return (
			<div className={css(styleSheet.statsTableMetric)}>
				<div className={css(styleSheet.statsTableMetricTitle)}>{title}</div>
				<div className={css(styleSheet.statsTableMetricValue)}>
					{!resourceActivity ? <span>&nbsp;</span> : resourceActivity.count}
				</div>
			</div>
		);
	};
	const renderStatsTable = () => {
		return (
			<div className={css(styleSheet.statsTable)}>
				<div className={css(styleSheet.statsTableContent)}>
					{renderActivityMetric('notes', reportsContactByEntityQuery.data?.currentPeriod?.notes)}
					{renderActivityMetric('actions', reportsContactByEntityQuery.data?.currentPeriod?.actionItems)}
					{userSession.user.emailProviderFeatures?.email?.canViewHistoryByContact
						? renderActivityMetric('emails', reportsContactByEntityQuery.data?.currentPeriod?.emailsSent)
						: null}
					{userSession.user.emailProviderFeatures?.calendar?.canViewHistoryAll
						? renderActivityMetric('meetings', reportsContactByEntityQuery.data?.currentPeriod?.meetings)
						: null}
				</div>
			</div>
		);
	};
	return (
		<div className={css(styleSheet.container)}>
			<div className={css(styleSheet.title)}>
				<span className={css(styleSheet.titleText)}>Overview</span>
				<div className={css(styleSheet.timeSpanFilter)}>
					<span>Showing:</span>
					<Select
						selectedOption={TimespanOptions.find(x => x.dataContext === selectedTimespanInDays)}
						onOptionClick={onTimeSpanChanged}
						options={TimespanOptions}
						optionStyles={[styleSheet.timeSpanSelectOption]}
						styles={[styleSheet.timeSpanFilterSelect]}
					/>
				</div>
			</div>
			{renderStatsTable()}
			{reportsContactByEntityQuery.isLoading ? (
				<LoadingSpinner type='large' className={`absolute-center ${css(styleSheet.loading)}`} />
			) : null}
		</div>
	);
}
