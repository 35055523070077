import { brandSecondary } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';

const initialWidth = 11;
const initialHeight = 11;

export const PlusIcon = ({
	className,
	fillColor,
	height = initialHeight,
	width = initialWidth,
}: {
	className?: string;
	fillColor?: string;
	height?: number;
	width?: number;
}) => {
	return (
		<SvgIcon
			className={`plus-icon ${className || ''}`}
			height={height}
			width={width}
			viewBox={`0 0 ${initialWidth} ${initialHeight}`}
		>
			<path
				fill={fillColor || brandSecondary}
				fillRule='evenodd'
				d='M6.5,4.5 L11,4.5 L11,6.5 L6.5,6.5 L6.5,11 L4.5,11 L4.5,6.5 L0,6.5 L0,4.5 L4.5,4.5 L4.5,0 L6.5,0 L6.5,4.5 Z'
			/>
		</SvgIcon>
	);
};
