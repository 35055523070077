import { css } from 'aphrodite';
import React, { useEffect } from 'react';
import { getDisplayName } from '../../../../models/UiUtils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { LoadingSpinner } from '../../LoadingSpinner';
import { styleSheet } from './styles';

export const ReferralRockWidget = () => {
	const [loading, setLoading] = React.useState(true);
	const userSession = useUserSession();

	useEffect(() => {
		if (typeof window != 'undefined') {
			const windowObj = window as any;
			if (windowObj?.referralJS) {
				windowObj.referralJS.access = {
					targetId: 'rRockWidgetContainer',
					parameters: {
						view: 'iframe',
						programIdentifier: `${process.env.PROGRAM_IDENTIFIER}`,
						email: userSession?.user?.email,
						fullName: getDisplayName(userSession.user),
					},
				};
			}
		}
	}, [userSession.user]);

	return (
		<div className={css(styleSheet.iframeWrap)}>
			<iframe
				className={css(styleSheet.iframe)}
				name='rRockWidgetContainer'
				src='about:blank'
				id='rRockWidgetContainer'
				onLoad={() => setLoading(false)}
				style={{ display: loading ? 'none' : 'block' }}
			/>
			{loading ? <LoadingSpinner type='large' /> : null}
		</div>
	);
};
