import {
	IActionItem,
	IEmailMessageSendContext,
	IEntity,
	INote,
	IOperationResultNoValue,
	RichContentContextSource,
} from '@ViewModels';
import { css } from 'aphrodite';
import equal from 'fast-deep-equal';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import Waypoint from 'react-waypoint';
import { IAutomationInfo, RichContentSupportedTypes, SizeConstraint } from '../../../../models';
import * as AppState from '../../../../models/AppState';
import { Topics } from '../../../../models/LocalNotificationTopics';
import { ILocalNotification } from '../../../../models/LocalNotifications';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import * as AppViewModels from '../../../../viewmodels/AppViewModels';
import { baseStyleSheet } from '../../../styles/styles';
import { ActivityListItem } from '../../ActivityListItem';
import { ConfirmationDialog, IConfirmationDialogOption } from '../../ConfirmationDialog';
import { DeprecatedMoreMenu } from '../../DeprecatedMoreMenu';
import { LocalNotificationObserver } from '../../LocalNotificationObserver';
import { IMoreMenuItem } from '../../MoreMenu';
import { Placeholder } from '../../Placeholder';
import { Portal } from '../../Portal';
import { ActionItem } from '../../actionItems/ActionItem';
import { NoteEditor } from '../../notes/NoteEditor';
import { RichContentCreatorHeader } from '../../richContent/RichContentCreatorHeader';
import { CheckmarkIcon } from '../../svgs/icons/CheckmarkIcon';
import { NotesPlaceholderIcon } from '../../svgs/icons/NotesPlaceholderIcon';
import { TextingIcon } from '../../svgs/icons/TextingIcon';
import { WarningIcon } from '../../svgs/icons/WarningIcon';
import { styleSheet } from './styles';

interface IProps<
	TEntity extends IEntity = IEntity,
	TEntityViewModel extends AppViewModels.EntityViewModel<TEntity> = AppViewModels.EntityViewModel<TEntity>,
> extends IEventLoggingComponentProps,
		AppState.IUserSessionComponentProps,
		AppState.INoteComposerComponentProps,
		AppState.IActionItemComposerComponentProps,
		AppState.ISingleEmailComposerComponentProps,
		AppState.IErrorMessageComponentProps {
	className?: string;
	entity: TEntityViewModel;
	hideActionItems?: boolean;
	onEntityClicked?(entity: AppViewModels.EntityViewModel<IEntity>, e: React.MouseEvent<HTMLElement>): void;
	onMoreMenuOptionClicked?(
		richContent: AppViewModels.RichContentViewModel,
		option: 'edit' | 'delete',
		e: React.MouseEvent<HTMLElement>
	): void;
	readonly?: boolean;
	scrollToBottomWaypointPortalId?: string;
	sendMessageDisabled?: boolean;
	sizeConstraint?: SizeConstraint;
	supportedTypes?: RichContentSupportedTypes[];
}

interface IState<
	TEntity extends IEntity = IEntity,
	TEntityViewModel extends AppViewModels.EntityViewModel<TEntity> = AppViewModels.EntityViewModel<TEntity>,
> {
	entity?: TEntityViewModel;
	showDeletingNoteModal?: AppViewModels.RichContentViewModel;
}

export class _EntityRichContent<
	TEntity extends IEntity = IEntity,
	TEntityViewModel extends AppViewModels.EntityViewModel<TEntity> = AppViewModels.EntityViewModel<TEntity>,
> extends React.Component<IProps<TEntity, TEntityViewModel>, IState<TEntity, TEntityViewModel>> {
	// @ts-ignore
	private mMounted: boolean;
	private MenuItems: IMoreMenuItem<string>[] = [
		{
			name: 'Edit',
			representedObject: 'edit',
		},
		{
			name: 'Delete',
			representedObject: 'delete',
		},
	];
	public static defaultProps: Partial<IProps> = {
		supportedTypes: ['ActionItem', 'Note', 'EmailNote'],
	};

	public readonly state: IState<TEntity, TEntityViewModel> = {
		// @ts-ignore
		showDeletingNoteModal: null,
	};

	public componentDidMount() {
		const nextState = this.getNextStateWithProps(this.props);
		if (nextState) {
			this.setState(nextState, this.loadMoreContent);
		}
		this.mMounted = true;
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IProps<TEntity, TEntityViewModel>) {
		const nextState = this.getNextStateWithProps(nextProps);
		if (nextState) {
			this.setState(nextState, this.loadMoreContent);
		}
	}

	// @ts-ignore
	public componentDidUpdate(prevProps: IProps) {
		if (!equal(prevProps, this.props)) {
			this.loadMoreContent();
		}
	}

	public componentWillUnmount() {
		this.mMounted = false;
	}

	public render() {
		const { className, sizeConstraint } = this.props;
		const { entity, showDeletingNoteModal } = this.state;
		const isEmpty = !entity || !entity.isValid || entity.richContent?.length === 0;
		const isLoading =
			!!entity &&
			!entity.richContentViewModel.controller.hasFetchedFirstPage &&
			entity.richContentViewModel.controller.isFetching;
		return (
			<ul className={`${css(styleSheet.container)} entity-rich-content ${className || ''}`}>
				{!!isEmpty || !!isLoading ? (
					<Placeholder
						isEmpty={!!isEmpty && !isLoading}
						isLoading={isLoading}
						loaderType={sizeConstraint === 'compact' ? 'small' : 'large'}
						message={"You haven't added any notes or reminders yet"}
						placeholderIcon={<NotesPlaceholderIcon />}
					/>
				) : (
					!!entity &&
					entity.richContent.map(x => {
						return x instanceof AppViewModels.NoteViewModel
							? this.renderNote(x)
							: this.renderActionItem(x as AppViewModels.ActionItemViewModel);
					})
				)}
				{!!entity && this.renderBottomWaypoint()}
				<LocalNotificationObserver
					topic={Topics.CREATE_ACTION_ITEM}
					onNotificationReceived={this.onCreateOrUpdateNotificationReceived}
				/>
				<LocalNotificationObserver
					topic={Topics.EDIT_ACTION_ITEM}
					onNotificationReceived={this.onCreateOrUpdateNotificationReceived}
				/>
				<LocalNotificationObserver
					topic={Topics.CREATE_NOTE}
					onNotificationReceived={this.onNoteCreatedNotificationReceived}
				/>
				<LocalNotificationObserver topic={Topics.SEND_EMAIL} onNotificationReceived={this.onEmailsSent} />
				<LocalNotificationObserver topic={Topics.CREATE_AUTOMATION} onNotificationReceived={this.onAutomationEdited} />
				<LocalNotificationObserver topic={Topics.DELETE_AUTOMATION} onNotificationReceived={this.onAutomationEdited} />
				<ConfirmationDialog
					icon={<WarningIcon />}
					modalProps={{
						isOpen: !!showDeletingNoteModal,
						onRequestClose: this.onDeleteNoteConfirmationDialogRequestClose,
					}}
					options={[
						{
							isDestructive: true,
							representedObject: true,
							title: 'Delete',
						},
						{
							isCancel: true,
							representedObject: false,
							title: 'Cancel',
						},
					]}
					title='Are you sure you want to delete this note?'
				/>
			</ul>
		);
	}

	private onAutomationEdited = (notification?: ILocalNotification<IAutomationInfo>) => {
		const { entity } = this.props;
		if (
			!!entity?.id &&
			notification?.info?.contact?.id === entity.id &&
			notification?.info?.automation?.steps?.some(x => x.step?._type === 'ActionItemAutomationStep')
		) {
			// reload
			entity.richContentViewModel.controller.reset();
			this.loadMoreContent();
		}
	};

	private onEmailsSent = (notification: ILocalNotification<AppViewModels.EmailMessageViewModel>) => {
		const { entity } = this.props;
		if (!!entity && entity instanceof AppViewModels.ContactViewModel) {
			// @ts-ignore
			// @ts-ignore
			if (!!notification.info.options && !!notification.info.options.saveAsNote) {
				let scheduleReload = false;
				if (notification.info instanceof AppViewModels.EmailMessageViewModel) {
					const entityEmailAddresses = (entity.emailAddresses || []).map(x => x.value).filter(x => !!x);
					const emailMessage = notification.info;
					if (emailMessage.contactsToAdd.length > 0) {
						const recipientIds = emailMessage.contactsToAdd
							.filter(x => !emailMessage.contactsToOmit.has(x))
							.map(x => x.id);
						scheduleReload = recipientIds.indexOf(entity.id) >= 0;
					} else if (entityEmailAddresses.length > 0) {
						const addresses = (emailMessage.contactsToAdd.toArray() || [])
							.map(y => {
								const emailAddress = emailMessage.getPreferredEmailAddressForContact(y);
								return !!emailAddress && !!emailAddress.value ? emailAddress.value : null;
							})
							.filter(z => !!z);
						const matchingEmail = entityEmailAddresses.find(x => {
							return !!addresses.find(y => y === x);
						});
						scheduleReload = !!matchingEmail;
					}
				}

				if (scheduleReload) {
					// defer a reload
					setTimeout(() => {
						if (this.mMounted) {
							entity.richContentViewModel.controller.reset();
							this.loadMoreContent();
						}
					}, 1000);
				}
			}
		}
	};

	private getNextStateWithProps = (nextProps: IProps<TEntity, TEntityViewModel>) => {
		const nextState: IState<TEntity, TEntityViewModel> = {};

		if (
			this.state.entity !== nextProps.entity ||
			(!!this.state.entity && !!nextProps.entity && this.state.entity.id !== nextProps.entity.id)
		) {
			nextState.entity = nextProps.entity;
			this.loadMoreContent();
		}

		return Object.keys(nextState).length > 0 ? nextState : null;
	};

	private onNoteCreatedNotificationReceived = (notification: ILocalNotification<INote>) => {
		const { userSession } = this.props;
		const { entity } = this.state;
		// @ts-ignore
		// @ts-ignore
		if (entity.isValid && !entity.isFetchingRichContent) {
			const noteModel = notification.info;
			// @ts-ignore
			if (noteModel.referencedEntities) {
				// @ts-ignore
				// @ts-ignore
				// @ts-ignore
				// @ts-ignore
				const entityIds = [...noteModel.referencedEntities.contacts, ...noteModel.referencedEntities.companies].map(
					x => x.entity.id
				);
				// @ts-ignore
				if (entityIds.indexOf(entity.id) >= 0) {
					// entity was mentioned...
					// @ts-ignore
					const note = new AppViewModels.NoteViewModel(userSession, noteModel);
					// convert to vm and add it
					// @ts-ignore
					// @ts-ignore
					entity.richContent.splice(0, 0, note);
				}
			}
		}
	};

	private onCreateOrUpdateNotificationReceived = (notification: ILocalNotification<IActionItem>) => {
		const { userSession } = this.props;
		const { entity } = this.state;
		const actionItemModel = notification.info;

		const entityIds = [
			// @ts-ignore
			// @ts-ignore
			// @ts-ignore
			...actionItemModel.referencedEntities.contacts,
			// @ts-ignore
			// @ts-ignore
			// @ts-ignore
			...actionItemModel.referencedEntities.companies,
		].map(x => x.entity.id);
		// @ts-ignore
		if (entityIds.indexOf(entity.id) >= 0) {
			let index = -1;
			// @ts-ignore
			const existingActionItem = entity.richContent.find((x, i) => {
				// @ts-ignore
				const found = x.id === actionItemModel.id;
				// @ts-ignore
				index = i;
				return found;
			}) as AppViewModels.ActionItemViewModel;
			// @ts-ignore
			const actionItem = new AppViewModels.ActionItemViewModel(userSession, actionItemModel);
			if (!!existingActionItem && index >= 0) {
				// replace existing
				// @ts-ignore
				entity.richContent.splice(index, 1, actionItem);
			} else {
				// convert to vm and add it
				// @ts-ignore
				entity.richContent.splice(0, 0, actionItem);
			}
		}
	};

	private renderBottomWaypoint() {
		const { scrollToBottomWaypointPortalId } = this.props;
		const waypoint = <Waypoint bottomOffset='-200px' onEnter={this.loadMoreContent} />;
		if (scrollToBottomWaypointPortalId) {
			return <Portal destination={scrollToBottomWaypointPortalId}>{waypoint}</Portal>;
		}

		return waypoint;
	}

	private loadMoreContent = () => {
		const { supportedTypes, logApiError, logEvent } = this.props;
		const { entity } = this.state;
		if (!!entity && !entity.richContentViewModel.controller.isFetching) {
			const promise = entity.richContentViewModel.controller.getNext({
				typeOf: supportedTypes,
			});

			if (promise) {
				// @ts-ignore
				logEvent('RichContentLoad', { id: entity.id, supportedTypes });
				promise.catch((error: IOperationResultNoValue) => {
					// @ts-ignore
					logApiError('RichContentLoad-Error', error);
				});
			}
		}
	};

	private renderNote(note: AppViewModels.NoteViewModel) {
		const { hideActionItems, userSession, sendMessageDisabled, sizeConstraint, onEntityClicked } = this.props;
		return (
			<ActivityListItem
				className={css(styleSheet.item)}
				// @ts-ignore
				date={note.lastModifiedDateText}
				// @ts-ignore
				dateAccessory={this.renderRichContentDateAccessory(note)}
				key={note.id}
				// @ts-ignore
				middleAccessory={this.renderRichContentViewed(note)}
				// @ts-ignore
				rightAccessory={this.renderRichContentMoreMenu(note)}
				sizeConstraint={this.props.sizeConstraint}
			>
				<NoteEditor
					bodyClassName={css(styleSheet.noteEditorBody)}
					canChangeActionItemCompletedStateForReadOnly={true}
					className={css(styleSheet.noteEditor)}
					hideActionItems={hideActionItems}
					isCollapsible={note.context?.source === RichContentContextSource.EmailMessageSend}
					mentionFieldClassName={css(styleSheet.mentionField)}
					note={note}
					onActionItemSendMessageClicked={this.onNoteActionItemSendMessageClicked}
					onEntityClicked={onEntityClicked}
					readOnly={true}
					sendMessageDisabled={sendMessageDisabled}
					sizeConstraint={sizeConstraint}
					textEditorClassName={css(styleSheet.noteEditor)}
					userSession={userSession}
				/>
			</ActivityListItem>
		);
	}

	private renderRichContentViewed(richContent: AppViewModels.RichContentViewModel) {
		if (richContent?.context?.source === RichContentContextSource.EmailMessageSend) {
			const openDate = (richContent?.context as IEmailMessageSendContext)?.openDate;

			return openDate ? (
				<div className={css(styleSheet.viewedOn)}>
					<span>
						<CheckmarkIcon className={css(baseStyleSheet.absoluteCenter)} fillColor='#fff' />
					</span>
					Viewed on {moment(openDate).format('MM/DD/YYYY')}
				</div>
			) : (
				<div className={css(styleSheet.viewedOn)}>Email not viewed</div>
			);
		}

		return null;
	}

	private renderRichContentMoreMenu(richContent: AppViewModels.RichContentViewModel) {
		const { readonly } = this.props;
		if (!readonly) {
			// config menu items
			let menuItems = [...this.MenuItems];
			if (!richContent.canEdit) {
				menuItems = menuItems.filter(x => x.representedObject !== 'edit');
			}

			if (!richContent.canDelete) {
				menuItems = menuItems.filter(x => x.representedObject !== 'delete');
			}

			if (menuItems.length > 0) {
				return (
					<DeprecatedMoreMenu
						menuButtonClassName={`${
							richContent?.context?.source === RichContentContextSource.EmailMessageSend && css(styleSheet.moreMenu)
						} entity-notes-list-header-more-menu`}
						menuItems={menuItems}
						onMenuItemClicked={this.onMoreMenuItemClicked(richContent)}
					/>
				);
			}
		}

		return null;
	}

	private renderRichContentDateAccessory(richContent: AppViewModels.RichContentViewModel) {
		const { sizeConstraint } = this.props;
		return (
			<>
				&nbsp; &nbsp;
				<RichContentCreatorHeader namePrefix='by ' richContent={richContent} sizeConstraint={sizeConstraint} />
				<span className={css(styleSheet.textingIcon)}>
					{richContent?.context?.source === RichContentContextSource.TextMessage && (
						<TextingIcon className={css(styleSheet.textingIconSvg)} />
					)}
				</span>
			</>
		);
	}

	private renderActionItem(actionItem: AppViewModels.ActionItemViewModel) {
		const { sizeConstraint, sendMessageDisabled, onEntityClicked } = this.props;
		return (
			<ActivityListItem
				className={css(styleSheet.item)}
				// @ts-ignore
				date={actionItem.lastModifiedDateText}
				dateAccessory={this.renderRichContentDateAccessory(actionItem)}
				key={actionItem.id}
				rightAccessory={this.renderRichContentMoreMenu(actionItem)}
				sizeConstraint={sizeConstraint}
			>
				<ActionItem
					actionItem={actionItem}
					canToggleCompleted={true}
					key={actionItem.id}
					onEntityClicked={onEntityClicked}
					onSendMessageClicked={this.onSendMessageClicked(actionItem)}
					rightAccessory={this.renderActionItemRightAccessory()}
					sendMessageDisabled={sendMessageDisabled}
					sizeConstraint={sizeConstraint}
				/>
			</ActivityListItem>
		);
	}

	private onSendMessageClicked =
		(actionItem: AppViewModels.ActionItemViewModel) => (e: React.MouseEvent<HTMLElement>) => {
			e.stopPropagation();
			e.preventDefault();
			const { logInput, singleEmailComposer } = this.props;

			if (actionItem.isKeepInTouchActionItem) {
				const keepInTouchReferenceModel = actionItem.keepInTouchReference.toJs();
				// @ts-ignore
				logInput('KeepInTouchActionItemSendMessage', 'Click', {
					kitRef: {
						...keepInTouchReferenceModel,
						// @ts-ignore
						contact: { id: keepInTouchReferenceModel.contact.id },
					},
				});
			} else {
				// @ts-ignore
				logInput('ActionItemSendMessage', 'Click', {
					actionItem: {
						id: actionItem.id,
					},
					recipients: (actionItem.referencedContactsForSendMessage || []).map(x => x.id),
				});
			}
			// @ts-ignore
			singleEmailComposer.showForActionItem(actionItem);
		};

	private renderActionItemRightAccessory() {
		return <div className={css(styleSheet.actionItemRightAccessory)} />;
	}

	private onDeleteNoteConfirmationDialogRequestClose = (
		result?: IConfirmationDialogOption<boolean>,
		canceled?: boolean
	) => {
		const { showDeletingNoteModal, entity } = this.state;
		const { logInput, logApiError, errorMessages } = this.props;
		if (!!result && !canceled && !!showDeletingNoteModal) {
			// @ts-ignore
			logInput('MoreMenuDeleteNote', 'Click', { id: showDeletingNoteModal.id });
			// @ts-ignore
			entity.deleteRichContent(showDeletingNoteModal)?.catch(error => {
				// @ts-ignore
				logApiError('NoteDelete-Error', error);
				// @ts-ignore
				errorMessages.pushApiError(error);
			});
		}

		// @ts-ignore
		this.setState({ showDeletingNoteModal: null });
	};

	private onMoreMenuItemClicked =
		(richContent: AppViewModels.RichContentViewModel) =>
		(menuItem: IMoreMenuItem, e: React.MouseEvent<HTMLElement>) => {
			const { noteComposer, actionItemComposer, onMoreMenuOptionClicked, logInput } = this.props;
			const { entity } = this.state;

			if (onMoreMenuOptionClicked) {
				onMoreMenuOptionClicked(richContent, menuItem.representedObject, e);
			}

			if (e.defaultPrevented) {
				e.stopPropagation();
				return;
			}

			e.preventDefault();
			e.stopPropagation();

			const getAction = (name: string) =>
				`MoreMenu${name}${richContent instanceof AppViewModels.NoteViewModel ? 'Note' : 'ActionItem'}`;
			if (menuItem.representedObject === 'delete') {
				if (richContent instanceof AppViewModels.NoteViewModel) {
					// @ts-ignore
					this.setState({ showDeletingNoteModal: richContent });
				} else {
					// @ts-ignore
					logInput(getAction('Delete'), 'Click', { id: richContent.id });
					// @ts-ignore
					entity.deleteRichContent(richContent);
				}
			} else if (menuItem.representedObject === 'edit') {
				// @ts-ignore
				logInput(getAction('Edit'), 'Click', { id: richContent.id });
				if (richContent instanceof AppViewModels.NoteViewModel) {
					// @ts-ignore
					noteComposer.show(richContent);
				} else if (richContent instanceof AppViewModels.ActionItemViewModel) {
					// @ts-ignore
					actionItemComposer.show(
						richContent,
						(
							editedActionItem?: AppViewModels.ActionItemViewModel,
							result?: AppViewModels.RichContentComposerResult
						) => {
							if (!!editedActionItem && result === AppViewModels.RichContentComposerResult.Delete) {
								// @ts-ignore
								entity.deleteRichContent(editedActionItem);
							}
						}
					);
				}
			}
		};

	private onNoteActionItemSendMessageClicked = (
		actionItem: AppViewModels.ActionItemViewModel,
		e: React.MouseEvent<HTMLElement>
	) => {
		this.onSendMessageClicked(actionItem)(e);
	};
}

const EntityRichContentAsObserver = observer(_EntityRichContent);
export const EntityRichContentWithContext = inject(
	AppState.ActionItemComposerViewModelKey,
	AppState.NoteComposerViewModelKey,
	AppState.SingleEmailComposerKey,
	AppState.UserSessionViewModelKey,
	AppState.ErrorMessagesViewModelKey
)(EntityRichContentAsObserver);
export const EntityRichContent = withEventLogging(EntityRichContentWithContext, 'EntityRichContent');
