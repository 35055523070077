import { StyleSheet } from 'aphrodite';
import { error, nameCircles, success } from '../../../styles/colors';

export const styleSheet = StyleSheet.create({
	backgroundSvg: {
		bottom: -10,
		left: '50%',
		pointerEvents: 'none',
		position: 'absolute',
		transform: 'translate(-50%, 0)',
	},
	container: {
		backgroundImage: 'linear-gradient(-90deg, #00AAE8 3%, #7CC9FF 100%)',
		borderRadius: 3,
		overflow: 'hidden',
		padding: '14px 30px',
		position: 'relative',
		zIndex: 0,
	},
	lastInteraction: {
		alignItems: 'center',
		color: '#fff',
		display: 'flex',
		fontSize: 12,
		fontWeight: 600,
		justifyContent: 'space-between',
		lineHeight: '22px',
		position: 'relative',
		zIndex: 3,
	},
	lastInteractionLabel: {
		letterSpacing: '1.2px',
		textTransform: 'uppercase',
	},
	lastInteractionTimeSpan: {},
	plugin: {
		':hover': {
			cursor: 'pointer',
		},
		padding: '8px 20px 6px',
	},
	status: {
		alignItems: 'center',
		color: '#fff',
		display: 'flex',
		fontSize: 12,
		fontWeight: 600,
		justifyContent: 'space-between',
		lineHeight: '22px',
		marginTop: 3,
		position: 'relative',
		zIndex: 3,
	},
	statusIndicator: {
		alignItems: 'center',
		display: 'flex',
	},
	statusIndicatorDot: {
		alignItems: 'center',
		border: '2px solid #fff',
		borderRadius: 8,
		boxSizing: 'border-box',
		display: 'inline-flex',
		flexShrink: 0,
		height: 16,
		justifyContent: 'center',
		marginLeft: 8,
		position: 'relative',
		width: 16,
	},
	statusIndicatorDotHealthy: {
		background: success,
	},
	statusIndicatorDotNeutral: {
		background: nameCircles,
	},
	statusIndicatorDotUnhealthy: {
		background: error,
	},
});
