import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { baseStyleSheet } from '../../styles/styles';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	isSetup?: boolean;
	styles?: StyleDeclarationValue[];
}

export const CanSpamUrl = 'https://www.ftc.gov/business-guidance/resources/can-spam-act-compliance-guide-business';

export const CanSpamLink: React.FC<IProps> = props => {
	const { className, isSetup, styles } = props;
	return (
		<span className={`${css(...(styles || []))} can-spam-link ${className || ''}`}>
			<a
				href={CanSpamUrl}
				target='_blank'
				className={css(isSetup ? styleSheet.brandLink : baseStyleSheet.brandLink)}
				rel='noreferrer'
			>
				CAN-SPAM Act
			</a>
		</span>
	);
};
