import { altTabLight, background, brandPrimary, nameCircles, titles, warningLight } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { ActionItemCheckboxSize } from '../../actionItems/ActionItemCheckbox/styles';
import { FeedCardAvatarSize } from '../FeedCardAvatars/styles';
import { StyleSheet } from 'aphrodite';

export const ActionItemCheckboxRightMargin = 20;
export const horizontalBodyPaddingLeft = 26;
export const horizontalBodyPaddingRight = 10;

export const styleSheet = StyleSheet.create({
	assigneeIcon: {
		height: 15,
		width: 14,
	},
	body: {
		alignItems: 'flex-start',
		display: 'flex',
		marginTop: 16,
	},
	bodyContent: {
		width: `calc(100% - ${ActionItemCheckboxSize + ActionItemCheckboxRightMargin}px)`,
	},
	bodyNoHeader: {
		marginBottom: 16,
	},
	checkboxContainer: {
		alignItems: 'center',
		background,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		width: 46,
	},
	checkboxContainerCompleted: {
		background: altTabLight,
	},
	checkboxContainerOverdue: {
		background: warningLight,
	},
	checkboxInner: {
		background: 'white',
		height: 18,
	},
	container: {},
	contentClassName: {
		paddingLeft: 40,
	},
	dueDate: {
		flexShrink: 0,
	},
	dueDateIcon: {
		height: 17,
		width: 18,
	},
	footer: {
		alignItems: 'center',
		color: '#858585',
		display: 'flex',
		fontSize: 12,
		height: 34,
		justifyContent: 'space-between',
		...CssSelectors.allChildren(
			{
				marginLeft: 30,
			},
			':not(:first-child)'
		),
	},
	footerAccent: {
		color: brandPrimary,
	},
	footerLeft: {
		alignItems: 'center',
		display: 'flex',
		...CssSelectors.allChildren(
			{
				':not(:first-child)': {
					marginLeft: 30,
				},
				...CssSelectors.allChildren(
					{
						marginLeft: 10,
					},
					':not(:first-child)'
				),
				alignItems: 'center',
				display: 'flex',
			},
			'*'
		),
	},
	footerOptionIcon: {
		alignItems: 'center',
		display: 'flex',
		height: 20,
		justifyContent: 'center',
		width: 20,
	},
	footerRight: {},
	header: {
		color: titles,
		fontSize: 16,
		maxWidth: '100%',
		minWidth: 0,
		paddingLeft: 5,
	},
	headerIcon: {
		alignItems: 'center',
		background: nameCircles,
		borderRadius: '50%',
		color: '#fff',
		display: 'flex',
		fontSize: 14,
		height: FeedCardAvatarSize,
		justifyContent: 'center',
		width: FeedCardAvatarSize,
	},
	moreMenuHeader: {
		alignItems: 'center',
		boxSizing: 'border-box',
		display: 'flex',
		justifyContent: 'flex-end',
		padding: 10,
	},
	readOnlyContent: {
		...CssSelectors.allChildren(
			{
				paddingTop: 0,
			},
			':first-child'
		),
	},
	sendButton: {
		flexShrink: 0,
	},
	title: {
		alignItems: 'center',
		display: 'flex',
	},
});
