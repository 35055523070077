import { AutomationTemplateViewModel, IAccountTag, IOperationResultNoValue } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import {
	EnvironmentKey,
	IEnvironmentComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import { tagAlertDurationStringValue } from '../../../../models/UiUtils';
import {
	EntityViewModel,
	ICreateAutomationRequest,
	IEntity,
	TagAlertViewModel,
} from '../../../../viewmodels/AppViewModels';
import { brandPrimary, tagBackgroundColor } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { Popover, PopoverType } from '../../Popover';
import { AutomationPopoverPrompt } from '../../automation/AutomationPopoverPrompt';
import { AutomationIcon } from '../../svgs/icons/AutomationIcon';
import { ClockAlertIcon } from '../../svgs/icons/ClockAlertIcon';
import { RemoveTagIcon } from '../../svgs/icons/RemoveTagIcon';
import { TagCheckIcon } from '../../svgs/icons/TagCheckIcon';
import { AutomationFromModal } from '../AutomationFromModal';
import { styleSheet } from './styles';

interface IProps extends IUserSessionComponentProps, IEnvironmentComponentProps, IEventLoggingComponentProps {
	automation?: AutomationTemplateViewModel;
	className?: string;
	disabled?: boolean;
	entity?: EntityViewModel<IEntity>;
	isSelected?: boolean;
	leftAccessory?: JSX.Element;
	onClick?(e: React.MouseEvent<HTMLElement>): void;
	onRemoveButtonClicked?(tagValue: string): void;
	onStartAutomationButtonClicked?(result?: ICreateAutomationRequest): void;
	showRemoveButton?: boolean;
	showSelectionIndicator?: boolean;
	style?: React.CSSProperties;
	styles?: StyleDeclarationValue[];
	tagAlert?: TagAlertViewModel;
	tagValue?: string | IAccountTag;
}

interface IState {
	hoveringOverTagAlertIcon?: boolean;
	isSelected?: boolean;
	showingTagAlertInfo?: boolean;
	showingAutomationInfo?: boolean;
	showAutomationFromModal?: boolean;
}

class _Tag extends React.Component<IProps> {
	private btn: HTMLDivElement = null;
	public readonly state: IState = {
		isSelected: this.props.isSelected,
		showingAutomationInfo: false,
	};

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		const nextState: IState = {};

		if (this.state.isSelected !== nextProps.isSelected) {
			nextState.isSelected = nextProps.isSelected;
		}

		if (Object.keys(nextState).length > 0) {
			this.setState(nextState);
		}
	}

	public componentDidMount() {
		this.toggleAutomationPopover(true)();
	}

	public render() {
		const {
			className,
			disabled,
			leftAccessory,
			tagValue,
			tagAlert,
			showRemoveButton,
			style,
			onClick,
			showSelectionIndicator,
			styles: propsStyles,
		} = this.props;
		const { isSelected } = this.state;
		const styles: StyleDeclarationValue[] = [
			styleSheet.container,
			!!isSelected && !!showSelectionIndicator ? styleSheet.containerSelected : null,
			!!showSelectionIndicator || !!tagAlert ? styleSheet.containerWithLeftAsset : null,
			...(propsStyles || []),
		];
		const tagText = typeof tagValue === 'string' ? tagValue : tagValue?.tag;
		const onkeyDownHandler = (e: React.KeyboardEvent) => {
			if (e.keyCode === 13 || e.keyCode === 32 || e.key === 'Enter') {
				e.preventDefault();
				this.btn.click();
			}
		};
		return (
			<div
				className={`tag ${css(styles)} ${tagAlert ? 'tag-with-alert' : ''} ${className || ''} ${
					disabled ? 'disabled' : ''
				}`}
				style={style}
			>
				<div
					role='button'
					onClick={onClick}
					ref={node => (this.btn = node)}
					tabIndex={0}
					onKeyDown={onkeyDownHandler}
					className={`${css(styleSheet.tagButton)}`}
					style={{ cursor: disabled ? 'default' : 'pointer' }}
				>
					{leftAccessory}
					<div className={css(styleSheet.content, baseStyleSheet.truncateText)}>
						{this.renderTagInfoIcon()}
						{this.renderAutomationIcon()}
						<div className={css(styleSheet.tagValue, baseStyleSheet.truncateText)} title={tagText}>
							{tagText}
						</div>
					</div>
				</div>
				{!!showRemoveButton && (
					<button
						className={`tag-remove-button ${css(styleSheet.removeButton)}`}
						disabled={disabled}
						onClick={this.onRemoveButtonClicked}
					>
						<RemoveTagIcon />
					</button>
				)}
			</div>
		);
	}

	private renderAutomationIcon() {
		const { tagValue, automation, entity } = this.props;
		const { showAutomationFromModal, showingAutomationInfo } = this.state;
		if (
			typeof tagValue === 'string' ||
			!tagValue?.automationTemplateId ||
			!this.props.userSession?.account?.features?.automation?.enabled
		) {
			return null;
		}
		return (
			<div onClick={e => e.stopPropagation()}>
				<AutomationPopoverPrompt
					anchor={
						<AutomationIcon
							className={`automation-icon ${css(styleSheet.alertIcon)}`}
							onMouseEnter={this.toggleAutomationPopover(true)}
						/>
					}
					automationTemplate={automation}
					isOpen={!!showingAutomationInfo}
					onCloseClicked={this.toggleAutomationPopover(false)}
					onCtaClicked={this.onStartAutomation}
					preferredPlacement='above'
				/>
				<AutomationFromModal
					contactId={entity?.id}
					automation={automation}
					modalProps={{
						isOpen: showAutomationFromModal,
						onRequestClose: this.onAutomationFromModalRequestClose,
					}}
				/>
			</div>
		);
	}

	private onStartAutomation = () => {
		/**
		 * @Note Based on discussion in https://github.com/Real-Magic/Levitate/issues/7233 exclude plugin from being able to
		 * choose sender.
		 */
		if (!this.props.userSession?.isAdmin || this.props.environment.appType === 'plugin') {
			this.startAutomation();
			return;
		}

		this.setState({ showAutomationFromModal: true, showingAutomationInfo: false });
	};

	private onAutomationFromModalRequestClose = (result?: ICreateAutomationRequest, cancel?: boolean) => {
		const { onStartAutomationButtonClicked } = this.props;
		if (cancel) {
			this.setState({ showAutomationFromModal: false });
			return;
		}

		onStartAutomationButtonClicked(result);
		this.setState({ showAutomationFromModal: false });
	};

	private startAutomation = () => {
		const { onStartAutomationButtonClicked } = this.props;
		this.toggleAutomationPopover(false)();
		if (onStartAutomationButtonClicked) {
			onStartAutomationButtonClicked();
		}
	};

	private toggleAutomationPopover = (isOpen: boolean) => () => {
		if (this.props.onStartAutomationButtonClicked) {
			this.setState({ showAutomationFromModal: false, showingAutomationInfo: isOpen });
		}
	};

	private renderTagInfoIcon() {
		const { tagAlert, userSession, showSelectionIndicator } = this.props;
		const { showingTagAlertInfo, isSelected } = this.state;
		if (!!showSelectionIndicator && !tagAlert) {
			return (
				<TagCheckIcon
					checkFillColor={isSelected ? brandPrimary : tagBackgroundColor}
					className={css(styleSheet.selectedIndicator)}
				/>
			);
		}

		if (!tagAlert) {
			return null;
		}
		const anchor = (
			<ClockAlertIcon
				className={`tag-alert-icon ${css(styleSheet.alertIcon)}`}
				onMouseEnter={this.toggleTagAlertHover(true)}
				onMouseLeave={this.toggleTagAlertHover(false)}
			/>
		);

		const target = tagAlert.interactionFilter.accountWide
			? 'anyone in the company'
			: !!tagAlert.interactionFilter.user && tagAlert.interactionFilter.user.id === userSession.user.id
				? 'me'
				: 'contact owner';
		const duration = tagAlertDurationStringValue(tagAlert);
		return (
			<Popover anchor={anchor} isOpen={!!showingTagAlertInfo} type={PopoverType.white} className='tag-alert-popover'>
				<span className={`tag-alert-popover-message ${css(styleSheet.tagAlertPopoverMessage)}`}>
					{`No communication from ${target} in at least ${duration}`}
				</span>
			</Popover>
		);
	}

	private toggleTagAlertHover = (hover: boolean) => () => {
		const { tagAlert } = this.props;
		const nextState: IState = {
			hoveringOverTagAlertIcon: hover,
		};

		if (tagAlert) {
			if (tagAlert.isLoaded) {
				// show pop-up
				nextState.showingTagAlertInfo = true;
				this.props.logInput('ShowTagAlertInfo', 'Direct', {
					tagAlert: { id: tagAlert.id },
				});
			} else {
				const promise = tagAlert.load();
				if (promise) {
					promise
						.then(() => {
							if (this.state.hoveringOverTagAlertIcon) {
								// show pop-up
								this.setState({
									showingTagAlertInfo: true,
								});
								this.props.logInput('ShowTagAlertInfo', 'Direct', {
									tagAlert: { id: tagAlert.id },
								});
							}
						})
						.catch((error: IOperationResultNoValue) => {
							if (error.systemCode !== 404) {
								this.props.logEvent('TagAlertLoad-Error', { ...error });
							}
						});
				}
			}
		}

		if (!hover) {
			nextState.showingTagAlertInfo = false;
		}
		this.setState(nextState);
	};

	private onRemoveButtonClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		const { onRemoveButtonClicked, tagValue } = this.props;
		const text = typeof tagValue === 'string' ? tagValue : tagValue.tag;
		if (onRemoveButtonClicked) {
			onRemoveButtonClicked(text);
		}
	};
}

const TagAsObserver = observer(_Tag);
const TagWithContext = inject(EnvironmentKey, UserSessionViewModelKey)(TagAsObserver);
export const Tag = withEventLogging(TagWithContext, 'Tag');
