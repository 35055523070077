import {
	IQuickAddEntityComponentProps,
	IUserSessionComponentProps,
	QuickAddEntityViewModelKey,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import MentionsAddCompanyIconUrl from '../../../assets/addCompanyIcon.svg';
import MentionsAddContactIconUrl from '../../../assets/addContactIcon.svg';
import { baseStyleSheet } from '../../../styles/styles';
import { Avatar } from '../../Avatar';
import { LoadingSpinner } from '../../LoadingSpinner';
import { Popover, PopoverType } from '../../Popover';
import { styleSheet } from './styles';
import { CompanyViewModel, ContactViewModel, EntityViewModel, IContact, IEntity } from '@ViewModels';
import { css } from 'aphrodite';
import { inject } from 'mobx-react';
import * as React from 'react';
import { PopoverPlacement } from 'react-popover';

type SyntheticKeyboardEvent = React.KeyboardEvent<any>;

export interface IEditorKeyboardEventListener {
	handleReturn?(e: React.KeyboardEvent<HTMLElement>): void;
	onDownArrow?(e: SyntheticKeyboardEvent): void;
	onEscape?(e: SyntheticKeyboardEvent): void;
	onLeftArrow?(e: SyntheticKeyboardEvent): void;
	onRightArrow?(e: SyntheticKeyboardEvent): void;
	onTab?(e: SyntheticKeyboardEvent): void;
	onUpArrow?(e: SyntheticKeyboardEvent): void;
}

interface IProps extends IQuickAddEntityComponentProps, IUserSessionComponentProps {
	addKeyboardEventListener(listener: IEditorKeyboardEventListener): void;
	anchor?: React.ReactNode;
	autoCloseOnClickOutside?: boolean;
	className?: string;
	entities?: EntityViewModel<IEntity>[];
	isOpen: boolean;
	isSearching?: boolean;
	onAddCompanyClicked?(): void;
	onAddContactClicked?(): void;
	onEntitySelected?(entity: EntityViewModel<IEntity>): void;
	onMouseDown?(e: React.MouseEvent<HTMLElement>): void;
	onMouseUp?(e: React.MouseEvent<HTMLElement>): void;
	onRequestClose?(): void;
	preferredPlacement?: PopoverPlacement;
	removeKeyboardEventListener(listener: IEditorKeyboardEventListener): void;
}

interface IState {
	entities?: EntityViewModel<IEntity>[];
	highlightedItemId?: string;
	isOpen?: boolean;
}

const AddContactMentionSuggestionId = 'AddContactMentionSuggestion';
const AddCompanyMentionSuggestionId = 'AddCompanyMentionSuggestion';

class _MentionSuggestionsPopover extends React.Component<IProps, IState> implements IEditorKeyboardEventListener {
	public state: IState = {
		isOpen: this.props.isOpen,
	};

	public UNSAFE_componentWillMount() {
		const entities = this.props.entities || [];
		this.setState({
			entities,
			// @ts-ignore
			highlightedItemId: entities.length > 0 ? entities[0].id : null,
		});
	}

	public componentWillUnmount() {
		this.props.removeKeyboardEventListener(this);
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		if (this.state.entities !== nextProps.entities) {
			// update the entity list and auto-highlight the first entity
			const entities = nextProps.entities || [];
			this.setState({
				entities,
				// @ts-ignore
				highlightedItemId: entities.length > 0 ? entities[0].id : null,
				isOpen: nextProps.isOpen,
			});
		} else if (this.state.isOpen !== nextProps.isOpen) {
			this.setState({
				isOpen: nextProps.isOpen,
			});
		}

		if (nextProps.isOpen) {
			this.props.addKeyboardEventListener(this);
		} else {
			this.props.removeKeyboardEventListener(this);
		}
	}

	public render() {
		const { isSearching, onRequestClose, preferredPlacement, className = '' } = this.props;
		const { entities, highlightedItemId, isOpen } = this.state;
		return (
			<span className={className}>
				<Popover
					type={PopoverType.white}
					preferredPlacement={preferredPlacement || 'below'}
					dismissOnClickOutside={true}
					anchor={this.props.anchor || <span className={css(styleSheet.suggestionAnchor)} />}
					isOpen={isOpen}
					onRequestClose={onRequestClose}
				>
					<div
						className={css(styleSheet.suggestionBody)}
						onMouseDown={this.props.onMouseDown}
						onMouseUp={this.props.onMouseUp}
					>
						{isSearching && (
							<span className={css(styleSheet.suggestionLoading)}>
								<LoadingSpinner type='small' />
							</span>
						)}
						{!!entities &&
							entities.map(entityVm => {
								return this.getEntityVmRow(entityVm);
							})}
						<button
							className={css(
								styleSheet.suggestionListItem,
								styleSheet.suggestionAddEntityItem,
								highlightedItemId === AddContactMentionSuggestionId && styleSheet.suggestionFocused
							)}
							onClick={this.onAddContactClicked}
						>
							<span className={css(styleSheet.suggestionAddEntityItemIcon)}>
								<img src={MentionsAddContactIconUrl} />
							</span>
							<span className={css(styleSheet.suggestionAddEntityItemTitle)}>Create contact</span>
						</button>
						<button
							className={css(
								styleSheet.suggestionListItem,
								styleSheet.suggestionAddEntityItem,
								highlightedItemId === AddCompanyMentionSuggestionId && styleSheet.suggestionFocused
							)}
							onClick={this.onAddCompanyClicked}
						>
							<span className={css(styleSheet.suggestionAddEntityItemIcon)}>
								<img src={MentionsAddCompanyIconUrl} />
							</span>
							<span className={css(styleSheet.suggestionAddEntityItemTitle)}>Create company</span>
						</button>
					</div>
				</Popover>
				{this.props.children}
			</span>
		);
	}

	public handleReturn = (e: React.KeyboardEvent<HTMLElement>) => {
		e.preventDefault();
		e.stopPropagation();

		if (this.state.highlightedItemId === AddContactMentionSuggestionId) {
			this.onAddContactClicked();
		} else if (this.state.highlightedItemId === AddCompanyMentionSuggestionId) {
			this.onAddCompanyClicked();
		} else {
			if (this.state.entities) {
				const entityVm = this.state.entities.find(x => x.id === this.state.highlightedItemId);
				if (entityVm) {
					this.onItemClicked(entityVm)();
				}
			}
		}
	};

	public onDownArrow = (e: React.KeyboardEvent<HTMLElement>) => {
		e.preventDefault();
		e.stopPropagation();

		const ids = this.entityIds;
		const index = ids.indexOf(this.state.highlightedItemId) + 1;
		const nextId = ids[index];
		if (nextId) {
			this.setState({
				highlightedItemId: nextId,
			});
		}
	};

	public onUpArrow = (e: React.KeyboardEvent<HTMLElement>) => {
		e.preventDefault();
		e.stopPropagation();

		const ids = this.entityIds;
		const index = ids.indexOf(this.state.highlightedItemId) - 1;
		const nextId = ids[index];
		if (nextId) {
			this.setState({
				highlightedItemId: nextId,
			});
		}
	};

	private get entityIds() {
		return (this.state.entities || [])
			.concat()
			.map(x => x.id)
			.concat([AddContactMentionSuggestionId, AddCompanyMentionSuggestionId]);
	}

	private onAddCompanyClicked = () => {
		if (this.props.quickAddEntity) {
			this.props.quickAddEntity.show({
				onComplete: (error, entity) => {
					if (!error && !!entity && this.props.onEntitySelected) {
						// @ts-ignore
						this.props.onEntitySelected(new CompanyViewModel(this.props.userSession, entity));
					}
				},
				type: 'company',
			});

			if (this.props.onRequestClose) {
				this.props.onRequestClose();
			}
		}

		if (this.props.onAddCompanyClicked) {
			this.props.onAddCompanyClicked();
		}
	};

	private onAddContactClicked = () => {
		if (this.props.quickAddEntity) {
			this.props.quickAddEntity.show({
				onComplete: (error, entity) => {
					if (!error && !!entity && this.props.onEntitySelected) {
						// @ts-ignore
						// @ts-ignore
						this.props.onEntitySelected(new ContactViewModel(this.props.userSession, entity as IContact));
					}
				},
				type: 'contact',
			});

			if (this.props.onRequestClose) {
				this.props.onRequestClose();
			}
		}

		if (this.props.onAddContactClicked) {
			// @ts-ignore
			this.props.onAddCompanyClicked();
		}
	};

	private onItemClicked = (entityVm: EntityViewModel<IEntity>) => () => {
		if (!entityVm.isValid) {
			// show creation w/partial entity... can only assume entity is contact
			// @ts-ignore
			this.props.quickAddEntity.show({
				entity: entityVm.toJs(),
				onComplete: (error, savedEntity) => {
					if (!error && !!savedEntity) {
						// update the vm
						entityVm.setEntity(savedEntity);

						if (this.props.onEntitySelected) {
							this.props.onEntitySelected(entityVm);
						}
					}
				},
				type: 'contact',
			});

			if (this.props.onRequestClose) {
				this.props.onRequestClose();
			}
			return;
		}

		if (this.props.onEntitySelected) {
			this.props.onEntitySelected(entityVm);
		}
	};

	private getEntityVmRow = (entityVm: EntityViewModel<IEntity>) => {
		return (
			<div
				key={entityVm.id}
				className={css(
					styleSheet.suggestionListItem,
					this.state.highlightedItemId === entityVm.id && styleSheet.suggestionFocused
				)}
				onClick={this.onItemClicked(entityVm)}
			>
				<Avatar entityVm={entityVm} className={css(styleSheet.suggestionListItemAvatar)} />
				<span className={css(styleSheet.suggestionListItemName, baseStyleSheet.truncateText)}>{entityVm.name}</span>
			</div>
		);
	};
}

export const MentionSuggestionsPopover = inject(
	QuickAddEntityViewModelKey,
	UserSessionViewModelKey
)(_MentionSuggestionsPopover);
