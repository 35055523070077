import { EntityViewModel, IEntity, getTypeForEntity } from '@ViewModels';
import { css } from 'aphrodite';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ILocationState } from '../../../../models';
import { baseStyleSheet } from '../../../styles/styles';
import { FullscreenModalNavLink } from '../../fullscreen/FullscreenModalNavLink';
import { IFeedCardCallbackProps } from '../FeedCard';
import { FeedCardAvatars } from '../FeedCardAvatars';
import { styleSheet } from './styles';

interface IProps extends IFeedCardCallbackProps {
	children(elements: { entityList?: React.ReactNode; overflow?: React.ReactNode }): React.ReactNode;
	className?: string;
	icon?: React.ReactNode;
	mentionedEntities: EntityViewModel[];
	subtitle?: React.ReactNode;
}

class _FeedCardMentionedEntitiesHeader extends React.Component<IProps> {
	public render() {
		const { className, subtitle, icon } = this.props;
		const showingAvatars = this.visibleReferencedEntities.length > 0 || !!icon;
		return (
			<div className={`${css(styleSheet.container)} feed-card-mentioned-entities-header ${className || ''}`}>
				{!!showingAvatars &&
					(icon || (
						<FeedCardAvatars
							className={css(styleSheet.avatars)}
							entities={this.visibleReferencedEntities}
							overflowEntities={this.overflowReferencedEntities}
						/>
					))}
				<div
					className={css(
						styleSheet.title,
						baseStyleSheet.truncateText,
						showingAvatars ? styleSheet.titleWithAvatars : null
					)}
				>
					<div className={css(styleSheet.titleDescription, baseStyleSheet.truncateText)}>{this.renderTitle()}</div>
					{subtitle}
				</div>
			</div>
		);
	}

	@computed
	private get visibleReferencedEntities() {
		const { mentionedEntities } = this.props;
		return (mentionedEntities || []).slice(0, 2);
	}

	@computed
	private get overflowReferencedEntities() {
		const { mentionedEntities } = this.props;
		return (mentionedEntities || []).slice(2);
	}

	private renderTitle() {
		const { children } = this.props;
		const entityList =
			this.visibleReferencedEntities.length > 0 ? (
				<div className={css(styleSheet.titleEntityList, baseStyleSheet.truncateText)}>
					{this.visibleReferencedEntities.map((x, i) => {
						const isLastWithoutOverflow =
							i === this.visibleReferencedEntities.length - 1 && this.overflowReferencedEntities.length <= 0;
						const locationState: ILocationState<EntityViewModel, IEntity> = {
							viewModel: x,
						};
						return (
							<React.Fragment key={x.id}>
								{i > 0 && (
									<span>
										{!!isLastWithoutOverflow && <span>&nbsp;</span>}
										{isLastWithoutOverflow ? 'and' : ','}
										&nbsp;
									</span>
								)}
								<FullscreenModalNavLink
									className={css(styleSheet.titleEntityName, baseStyleSheet.truncateText)}
									title={x.name}
									to={{
										pathname: `/${getTypeForEntity(x.toJs()) === 'company' ? 'companies' : 'people'}/${x.id}`,
										state: locationState,
									}}
								>
									<span>{x.name}</span>
								</FullscreenModalNavLink>
							</React.Fragment>
						);
					})}
				</div>
			) : null;
		const overflow =
			this.overflowReferencedEntities.length > 0 ? (
				<>
					<span key={this.overflowReferencedEntities.length}>and &nbsp;</span>
					<span>
						{`${this.overflowReferencedEntities.length} other${this.overflowReferencedEntities.length > 1 ? 's' : ''}`}
					</span>
				</>
			) : null;
		return children ? children({ entityList, overflow }) : null;
	}
}

export const FeedCardMentionedEntitiesHeader = observer(_FeedCardMentionedEntitiesHeader);
