import { borderColor, mention } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

const popoverContentPadding = 20;

export const styleSheet = StyleSheet.create({
	field: {
		alignItems: 'center',
		borderBottom: '1px #F2F2F2 solid',
		display: 'flex',
		margin: `0 ${popoverContentPadding}px`,
		padding: '8px 0',
		...CssSelectors.allChildren(
			{
				marginLeft: 15,
			},
			':not(:first-child)'
		),
	},
	fieldInput: {
		'::placeholder': {
			color: '#8E8E8E',
		},
		':focus': {
			outline: 'none',
		},
		border: 'none',
		flexGrow: 1,
		fontSize: '12px',
	},
	highlighted: {
		backgroundColor: mention,
	},
	item: {
		':first-child': {
			marginTop: 10,
		},
		':focus': {
			backgroundColor: borderColor,
			outline: 'none',
		},
		':hover': {
			backgroundColor: borderColor,
		},
		cursor: 'pointer',
		padding: `5px ${popoverContentPadding}`,
	},
	list: {
		height: 220,
		overflowY: 'auto',
	},
});
