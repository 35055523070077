import { ContactViewModel } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { brandPrimaryHover } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { BulletIcon } from '../../svgs/icons/BulletIcon';
import { styleSheet } from './styles';

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	contact?: ContactViewModel;
	isBirthdayTemplate?: boolean;
	onFinishClicked?(e: React.MouseEvent<HTMLElement>): void;
	styles?: StyleDeclarationValue[];
}

export const FirstNamePlaceholderInfo: React.FC<IProps> = ({
	className,
	onFinishClicked,
	contact,
	isBirthdayTemplate,
	styles,
	...restProps
}) => {
	// if its an a birthday template then check if we have a name from the keyFactsCollection
	// else use the contact firstNameForSalutation
	const contactName =
		isBirthdayTemplate && contact?.namesFromKeyFactsCollection?.length > 0
			? contact?.namesFromKeyFactsCollection?.length > 1
				? `${contact?.namesFromKeyFactsCollection[0]}  & ${contact?.namesFromKeyFactsCollection[1]}`
				: contact?.namesFromKeyFactsCollection[0]
			: contact?.firstNameForSalutation;

	const onMouseDown = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		e.stopPropagation();
	};
	return (
		<div
			{...restProps}
			className={`${css(
				styleSheet.firstNamePlaceholderPopoverContent,
				...(styles || [])
			)} first-name-placeholder-info ${className || ''}`}
			onMouseDown={onMouseDown}
		>
			<div className={css(styleSheet.firstNamePlaceholderPopoverContentHeader)}>Smart Salutation</div>
			<div className={css(baseStyleSheet.verticalStack, styleSheet.firstNamePlaceholderPopoverContentBody)}>
				{contact ? (
					<>
						{contact.isRecognizedFirstName || contactName ? (
							<div>
								We will replace this first name with <span className={css(baseStyleSheet.fontBold)}>{contactName}</span>{' '}
								when this email is sent
							</div>
						) : (
							<div>
								{
									'Based on our AI, it doesn\'t seem like we have a proper first name. We will replace "Hi first name" with "Good morning," or another time of the day, depending on when the email is sent.'
								}
							</div>
						)}
					</>
				) : (
					<>
						<div>
							If first name is lacking or not satisfactory we will replace &quot;Hi first name&quot; with one of the
							following, depending on time of send:
						</div>
						<ul>
							<li className={css(baseStyleSheet.horizontalStack)}>
								<BulletIcon fillColor={brandPrimaryHover} />
								<span>Good morning,</span>
							</li>
							<li className={css(baseStyleSheet.horizontalStack)}>
								<BulletIcon fillColor={brandPrimaryHover} />
								<span>Good afternoon,</span>
							</li>
							<li className={css(baseStyleSheet.horizontalStack)}>
								<BulletIcon fillColor={brandPrimaryHover} />
								<span>Good evening,</span>
							</li>
						</ul>
					</>
				)}
			</div>
		</div>
	);
};
