import { StyleSheet } from 'aphrodite';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	root: {
		'::after': {
			background: 'linear-gradient(180deg, #DDDDDD 0%, #FFFFFF 100%)',
			border: '1px solid #eee',
			content: "''",
			display: 'block',
			height: '85%',
			left: 0,
			position: 'absolute',
			top: 0,
			transform: 'skewX(7deg)',
			transformOrigin: 'top left',
			width: '100%',
			zIndex: 0,
		},
		alignItems: 'center',
		aspectRatio: 1.3,
		boxSizing: 'border-box',
		display: 'flex',
		justifyContent: 'center',
		marginBottom: 20,
		overflow: 'visible',
		position: 'relative',
		width: '12rem',
		...CssSelectors.allChildren(
			{
				background: 'white',
				border: '1px solid #eee',
				boxSizing: 'border-box',
				display: 'block',
				height: '100%',
				position: 'relative',
				width: '100%',
				zIndex: 1,
			},
			'img'
		),
	},
});
