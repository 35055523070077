import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../models/AppState';
import AddCompanyIconUrl from '../../../assets/addCompanyIcon.svg';
import AddContactIconUrl from '../../../assets/addContactIcon.svg';
import './styles.less';
import { ICompany, IContact, IEntity, QuickAddEntityViewModel } from '@ViewModels';
import { inject, observer } from 'mobx-react';
import * as React from 'react';

interface IProps extends IUserSessionComponentProps {
	className?: string;
	fragment?: string;
	onEntityCreated?(entityModel: IEntity, type: 'contact' | 'company'): void;
	quickAddEntityVM: QuickAddEntityViewModel;
	type?: 'contact' | 'company' | 'entity';
}

interface IState {
	fragment?: string;
}

class _AddEntityButtons extends React.Component<IProps, IState> {
	public static defaultProps: Partial<IProps> = {
		type: 'entity',
	};
	public readonly state: IState = {};

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		if (this.state.fragment !== nextProps.fragment) {
			this.setState({
				fragment: nextProps.fragment,
			});
		}
	}

	public render() {
		const { className, type } = this.props;
		return (
			<div className={`add-entity-buttons ${className || ''}`}>
				{!!type && (type === 'contact' || type === 'entity') && (
					<button onMouseDown={this.onButtonClicked('contact')}>
						<span className='add-entity-button-icon'>
							<img src={AddContactIconUrl} />
						</span>
						<span className='add-entity-button-title'>Add a person</span>
					</button>
				)}
				{!!type && (type === 'company' || type === 'entity') && (
					<button onMouseDown={this.onButtonClicked('company')}>
						<span className='add-entity-button-icon'>
							<img src={AddCompanyIconUrl} />
						</span>
						<span className='add-entity-button-title'>Add a company</span>
					</button>
				)}
			</div>
		);
	}

	private onButtonClicked = (type: 'company' | 'contact') => (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();

		const { quickAddEntityVM, onEntityCreated, fragment } = this.props;
		// @ts-ignore
		let entityToCreate: IEntity = null;
		if (fragment) {
			if (type === 'company') {
				const company: ICompany = {
					companyName: fragment,
				};
				entityToCreate = company;
			} else {
				const contact: IContact = {
					firstName: fragment,
				};
				entityToCreate = contact;
			}
		}

		quickAddEntityVM.show({
			entity: entityToCreate,
			onComplete: (error, entity) => {
				if (!!onEntityCreated && !error) {
					// @ts-ignore
					onEntityCreated(entity, type);
				}
			},
			type,
		});
	};
}

const AddEntityButtonsAsObserver = observer(_AddEntityButtons);
export const AddEntityButtons = inject(UserSessionViewModelKey)(AddEntityButtonsAsObserver);
