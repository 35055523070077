import * as React from 'react';
import { navigation } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';

export const FoldedCardIcon = ({
	height = 21,
	width = 38,
	fillColor = navigation,
	fillColorSecondary = '#fff',
	...rest
}: React.SVGProps<SVGSVGElement> & { fillColor?: string; fillColorSecondary?: string }) => {
	return (
		<SvgIcon height={height} width={width} {...rest}>
			<path
				fill='#fff'
				fillRule='evenodd'
				stroke={fillColor}
				strokeWidth='2'
				d='M31.032 1.567a1 1 0 0 1 1.754.32l4.11 13.828A1 1 0 0 1 35.938 17h-16.66L31.032 1.567Z'
				clipRule='evenodd'
			/>
			<mask id='a' fill='#fff'>
				<rect width='33' height='21' rx='1' />
			</mask>
			<rect
				width='33'
				height='21'
				fill={fillColorSecondary}
				stroke={fillColor}
				strokeWidth='4'
				style={{ mask: 'url(#a)' }}
				rx='1'
			/>
			<path
				fill={fillColor}
				fillRule='evenodd'
				d='M8.755 15.098a1 1 0 0 1-.823-1.566l1.304-1.896a1 1 0 0 1 1.605-.058l1.894 2.369a1 1 0 0 0 1.65-.13l4.03-7.082a1 1 0 0 1 1.673-.1l5.085 6.869a1 1 0 0 1-.804 1.595H8.755Z'
				clipRule='evenodd'
			/>
		</SvgIcon>
	);
};
