import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { PlusIcon } from '../../svgs/icons/PlusIcon';
import { styleSheet } from './styles';

interface IProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const AddTagButton: React.FC<IProps> = props => {
	const { className, styles, ...restProps } = props;
	return (
		<button
			{...restProps}
			className={`${css(styleSheet.container, ...(styles || []))} add-tag-button ${className || ''}`}
		>
			<PlusIcon />
			<span>Add Tag</span>
		</button>
	);
};
