import { header } from '../../../web/styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	nextMeetingHeader: {
		borderBottom: '1px #f2f2f2 solid',
		color: header,
		display: 'flex',
		fontSize: 12,
		justifyContent: 'space-between',
		marginBottom: 16,
		padding: 16,
	},
});
