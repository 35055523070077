import { CheckmarkIcon } from '../../svgs/icons/CheckmarkIcon';
import { XMarkIcon } from '../../svgs/icons/XMarkIcon';
import { styleSheet } from './styles';
import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';

interface IProps {
	className?: string;
	phoneNumber: Api.IPhoneNumber;
}

export const CanSMS: React.FC<IProps> = observer(({ className = '', phoneNumber }) => {
	if (
		phoneNumber.canSMS !== Api.TextMessageCapability.Yes &&
		phoneNumber.canSendAutomatedSMS !== Api.TextMessageCapability.OptOut
	) {
		return null;
	}

	return (
		<div className={`${css(styleSheet.container)} ${className}`}>
			{phoneNumber.canSMS === Api.TextMessageCapability.Yes && (
				<>
					<div className={css(styleSheet.circle)}>
						<CheckmarkIcon fillColor='#fff' />
					</div>
					SMS
				</>
			)}
			{phoneNumber.canSendAutomatedSMS === Api.TextMessageCapability.OptOut && (
				<>
					<XMarkIcon className={css(styleSheet.xMarkIcon)} />
					Unsubscribed
				</>
			)}
		</div>
	);
});
