import { StyleSheet } from 'aphrodite';
import { brandPrimaryText, destructive } from '../../../styles/colors';
import {
	bulletMarginRight,
	bulletSize,
	editButtonMarginRight,
	editButtonSize,
} from '../../entities/EntityInfoBulletList/styles';

export const styleSheet = StyleSheet.create({
	container: {},
	dataOriginContainer: {
		paddingLeft: 8,
		width: `calc(100% - ${bulletSize}px - ${bulletMarginRight}px - ${editButtonSize}px - ${editButtonMarginRight}px)`,
	},
	email: {
		display: 'flex',
		flexWrap: 'wrap',
		overflowWrap: 'break-word',
		width: '100%',
		wordBreak: 'break-word',
		wordWrap: 'break-word',
	},
	emailLink: {
		color: brandPrimaryText,
		textDecoration: 'none',
	},
	emailWithEdit: {
		width: '100%',
	},
	infoMessage: {
		background: '#FEF3F5',
		border: `1px solid ${destructive}`,
		borderRadius: 5,
		color: '#0D0F11',
		fontFamily: 'Sora !important',
		fontSize: 14,
		padding: 10,
		width: 230,
	},
});
