import * as Api from '@ViewModels';
import * as React from 'react';
import { useContact } from '../../../../queries';

export const hwcRecipientFromContact = (contact: Api.IContact) => {
	return {
		address: contact.address ?? {},
		company: contact.companyName,
		contactId: contact.id,
		firstName: contact.firstName,
		lastName: contact.lastName,
	} as Api.IHandwrittenCardRecipient;
};

export function useRecipient({ contactId }: { contactId?: string }) {
	// @ts-ignore
	const contactQuery = useContact({ contactId, enabled: !!contactId, refetchOnWindowFocus: false }); // refetchonWindowFocus = false to avoid refetching query every time the user switches to and from the text editor which is an embedded window
	// @ts-ignore
	const [hwcRecipient, setHwcRecipient] = React.useState<Api.IHandwrittenCardRecipient>(() => {
		return contactQuery.data ? hwcRecipientFromContact(contactQuery.data) : null;
	});
	const [prevContactData, setPrevContactData] = React.useState(contactQuery.data);
	if (prevContactData !== contactQuery.data) {
		setPrevContactData(contactQuery.data);
		// @ts-ignore
		setHwcRecipient(contactQuery.data ? hwcRecipientFromContact(contactQuery.data) : null);
	}
	const handleRecipientChange = (recipient: Api.IHandwrittenCardRecipient) => {
		setHwcRecipient(recipient);
	};

	return {
		contactQuery,
		handleRecipientChange,
		hwcRecipient,
	};
}
