import { IEntity } from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { SizeConstraint } from '../../../../models';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import { ContactViewModel, EntityViewModel } from '../../../../viewmodels/AppViewModels';
import { ContactHistory } from '../../../containers/People/Contact/Activity/History';
import { ContactAnalysisHeader } from '../ContactAnalysisHeader';
import { EntityAnalysisUpgradeHeader } from '../EntityAnalysisUpgradeHeader';
import { styleSheet } from './styles';

interface IProps<TEntity extends IEntity, TEntityViewModel extends EntityViewModel<TEntity>>
	extends IEventLoggingComponentProps,
		IUserSessionComponentProps {
	className?: string;
	entity: TEntityViewModel;
	scrollToBottomWaypointPortalId?: string;
	sizeConstraint?: SizeConstraint;
}

class _EntityAnalysis<
	TEntity extends IEntity,
	TEntityViewModel extends EntityViewModel<TEntity>,
> extends React.Component<IProps<TEntity, TEntityViewModel>> {
	public render() {
		const { className, entity, userSession, loggingCategory, sizeConstraint } = this.props;
		// @ts-ignore
		// @ts-ignore
		// @ts-ignore
		const canShowEntityAnalysisHeader = userSession.account.planDetails.planId > 0;
		return (
			<div className={`${css(styleSheet.container)} entity-analysis ${className || ''}`}>
				{canShowEntityAnalysisHeader ? (
					// @ts-ignore
					<ContactAnalysisHeader contactId={entity.id} />
				) : (
					<EntityAnalysisUpgradeHeader
						className={css(
							styleSheet.upgradeHeader,
							sizeConstraint === 'compact' ? styleSheet.upgradeHeaderCompact : null
						)}
						loggingCategory={loggingCategory}
						sizeConstraint={sizeConstraint}
					/>
				)}
				{this.renderHistory()}
			</div>
		);
	}

	private renderHistory = () => {
		const { entity, userSession, loggingCategory, scrollToBottomWaypointPortalId } = this.props;
		const canViewContactHistory =
			// @ts-ignore
			// @ts-ignore
			// @ts-ignore
			// @ts-ignore
			!!userSession.user.emailProviderFeatures.calendar.canViewHistoryByContact ||
			// @ts-ignore
			// @ts-ignore
			// @ts-ignore
			// @ts-ignore
			!!userSession.user.emailProviderFeatures.email.canViewHistoryByContact;
		if (!canViewContactHistory) {
			return null;
		}

		return (
			<div className={css(styleSheet.history)}>
				<div className={css(styleSheet.historyHeader)}>History:</div>
				{entity instanceof ContactViewModel ? (
					<ContactHistory
						className={css(styleSheet.historyItems)}
						// @ts-ignore
						contactId={entity.id}
						contactName={entity?.name}
						itemClassName={css(styleSheet.historyItem)}
						loggingCategory={loggingCategory}
						scrollToBottomWaypointPortalId={scrollToBottomWaypointPortalId}
					/>
				) : null}
			</div>
		);
	};
}

const EntityAnalysisAsObserver = observer(_EntityAnalysis);
const EntityAnalysisWithContext = inject(UserSessionViewModelKey)(EntityAnalysisAsObserver);
export const EntityAnalysis = withEventLogging(EntityAnalysisWithContext, 'EntityAnalysis');
