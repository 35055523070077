import { PersistentStorageManager } from './Storage';
import { Industry, TemplatesViewModel, UserSessionContext } from '@ViewModels';

export const HiddenDefaultMessageTemplateKey = 'hiddenDefaultMessageTemplateKeys';

export const SupportedIndustries = [
	Industry.Financial,
	Industry.Insurance,
	Industry.Others,
	Industry.RealEstate,
	Industry.Mortgage,
	Industry.Legal,
];

export const loadHiddenDefaultMessageTemplateKeys = async () =>
	(await PersistentStorageManager.local.getObject<string[]>(HiddenDefaultMessageTemplateKey)) || [];

export const hideDefaultMessageTemplate = async (keyOrId: string) => {
	const hiddenDefaultMessageTemplateKeys = new Set(
		(await PersistentStorageManager.local.getObject<string[]>(HiddenDefaultMessageTemplateKey)) || []
	);
	hiddenDefaultMessageTemplateKeys.add(keyOrId);
	return PersistentStorageManager.local.setObject(
		HiddenDefaultMessageTemplateKey,
		Array.from(hiddenDefaultMessageTemplateKeys)
	);
};

export const loadDefaultMessageTemplates = (userSession: UserSessionContext) => {
	return loadDefaultTemplatesForIndustry(userSession);
};

export const loadDefaultTemplatesForIndustry = async (userSession: UserSessionContext, industry?: Industry) => {
	try {
		const hiddenDefaultMessageTemplateKeys = new Set(await loadHiddenDefaultMessageTemplateKeys());
		const templates = (await TemplatesViewModel.GetIndustryTemplates(userSession, industry))?.value || [];
		// @ts-ignore
		return templates.filter(x => !hiddenDefaultMessageTemplateKeys.has(x.id));
	} catch {
		return [];
	}
};
