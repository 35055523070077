import { navigation } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
	className?: string;
	fillColor?: string;
	strokeWidth?: string;
	width?: number;
	height?: number;
}

export const AssigneeIcon: React.FC<IProps> = props => {
	const { className, fillColor, strokeWidth, width = 14, height = 15, ...restProps } = props;
	return (
		<SvgIcon
			height={height}
			width={width}
			viewBox='0 0 14 15'
			{...restProps}
			className={`assignee-icon ${className || ''}`}
		>
			<g
				fill='none'
				fillRule='evenodd'
				stroke={fillColor || navigation}
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth={strokeWidth ?? '2'}
				transform='translate(1 1)'
			>
				<path d='M12,13 L12,11.6666667 C12,10.1939073 10.6568542,9 9,9 L3,9 C1.34314575,9 1.66533454e-16,10.1939073 0,11.6666667 L0,13' />
				<circle cx='6' cy='3' r='3' />
			</g>
		</SvgIcon>
	);
};
