import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const SendNow: React.FC<IProps> = props => {
	const { className } = props;
	return (
		<SvgIcon className={`send-now-icon ${className || ''}`} height={89} width={168}>
			<mask id='a' maskUnits='userSpaceOnUse' x='0' y='0' width='168' height='89'>
				<path transform='matrix(-1 0 0 1 168 0)' fill='#fff' d='M0 0h168v89H0z' />
			</mask>
			<g mask='url(#a)'>
				<path
					d='M-36.22 5.27h89.197a23.68 23.68 0 018.896 1.981c9.067 4.016 14.345 10.32 15.835 24.299v32.296l23.635-.25c4.753 0 6.491 7.468 6.491 12.516H-51.82V21.837c0-9.15 6.975-16.567 15.6-16.567z'
					fill='url(#Group_17__paint0_linear)'
				/>
				<path d='M49.432 8.726h-88.355c-5.978 0-10.824 4.846-10.824 10.824v53.798h99.18V8.726z' fill='#00AAE8' />
				<path
					d='M51.679 8.726c-13.265 0-24.018 10.732-24.018 23.971v28.919c0 6.48 5.263 11.732 11.754 11.732h24.527c6.491 0 11.754-5.253 11.754-11.732V32.697c0-13.239-10.753-23.971-24.017-23.971z'
					fill='#008ABC'
				/>
				<path
					opacity='.2'
					d='M51.679 8.726c-13.265 0-24.018 10.732-24.018 23.971v28.919c0 6.48 5.263 11.732 11.754 11.732h24.527c6.491 0 11.754-5.253 11.754-11.732V32.697c0-13.239-10.753-23.971-24.017-23.971z'
					fill='#00AAE8'
				/>
				<path d='M-12.08.086H7.619v32.83h-8.94V14.804H-12.08V.085z' fill='url(#Group_17__paint1_linear)' />
				<path d='M-.697.086H15.89v30.065H9.67V13.564H-.697V.086z' fill='#F3CE0D' />
				<path
					d='M77.891 41.176l-2.465-20.248-32.957 4.01 2.465 20.248 32.957-4.01z'
					fill='url(#Group_17__paint2_linear)'
				/>
				<path d='M77.383 40.377l-2.3-18.897-31.84 3.875 2.3 18.896 31.84-3.874z' fill='#FAF8E4' />
				<g opacity='.6'>
					<path d='M70.254 26.51l4.041-.49-.364-2.977-4.041.49.364 2.977z' fill='#00AAE8' />
					<path
						d='M56.172 34.093l15.517-1.88-.118-.966-15.517 1.88.117.966zm.255 2.093l11.314-1.371-.079-.643-11.314 1.371.079.643z'
						fill='#6C63FF'
					/>
				</g>
				<path
					d='M64.463 45.63l-2.466-20.248-32.957 4.01 2.466 20.248 32.957-4.01z'
					fill='url(#Group_17__paint3_linear)'
				/>
				<path d='M63.955 44.833l-2.301-18.896-31.84 3.874 2.301 18.897 31.84-3.875z' fill='#FAF8E4' />
				<g opacity='.6'>
					<path d='M56.632 30.938l4.06-.49-.366-2.978-4.06.49.366 2.978z' fill='#00AAE8' />
					<path
						d='M42.49 38.521l15.584-1.88-.119-.966-15.584 1.88.119.966zm.261 2.093l11.363-1.372-.078-.642-11.364 1.37.08.644z'
						fill='#6C63FF'
					/>
				</g>
				<path
					d='M93.871 54.732L90.53 27.285l-44.671 5.436L49.2 60.168l44.671-5.436z'
					fill='url(#Group_17__paint4_linear)'
				/>
				<path d='M93.18 53.647L90.063 28.03l-43.158 5.252 3.119 25.616 43.158-5.251z' fill='#fff' />
				<g opacity='.6'>
					<path d='M83.457 34.943l5.48-.664-.494-4.035-5.48.664.494 4.035z' fill='#00AAE8' />
					<path
						d='M64.363 45.225l21.043-2.55-.16-1.308-21.043 2.549.16 1.309zm.345 2.835l15.344-1.86-.107-.873L64.6 47.185l.107.874z'
						fill='#D3DBDE'
					/>
				</g>
				<path
					d='M75.668 60.77l-3.342-27.447-44.671 5.435 3.341 27.447 44.672-5.436z'
					fill='url(#Group_17__paint5_linear)'
				/>
				<path d='M74.98 59.689l-3.118-25.617-43.158 5.252 3.119 25.617 43.158-5.252z' fill='#fff' />
				<g opacity='.6'>
					<path d='M65.317 41.086l5.463-.663-.492-4.035-5.463.664.492 4.034z' fill='#00AAE8' />
					<path
						d='M46.285 51.368L67.26 48.82l-.16-1.309-20.975 2.55.16 1.308zm.344 2.835l15.294-1.858-.106-.874-15.295 1.859.107.873z'
						fill='#D3DBDE'
					/>
				</g>
				<path
					opacity='.2'
					d='M52.024 8.726a23.98 23.98 0 019.158 1.81 24.04 24.04 0 00-20.398 6.776 23.947 23.947 0 00-7.035 16.95V72.31h-5.742V32.697c0-13.239 10.753-23.971 24.017-23.971z'
					fill='#000'
				/>
				<path d='M96.392 65.054h-68.73v8.294h77.062c0-4.58-3.73-8.294-8.332-8.294z' fill='#008ABC' />
				<path opacity='.2' d='M96.392 65.054h-68.73v8.294h77.062c0-4.58-3.73-8.294-8.332-8.294z' fill='#00AAE8' />
			</g>
			<defs>
				<linearGradient
					id='Group_17__paint0_linear'
					x1='107.845'
					y1='76.115'
					x2='-51.82'
					y2='76.115'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='gray' stopOpacity='.25' />
					<stop offset='.54' stopColor='gray' stopOpacity='.12' />
					<stop offset='1' stopColor='gray' stopOpacity='.1' />
				</linearGradient>
				<linearGradient
					id='Group_17__paint1_linear'
					x1='-12.079'
					y1='32.916'
					x2='-12.079'
					y2='.086'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='gray' stopOpacity='.25' />
					<stop offset='.54' stopColor='gray' stopOpacity='.12' />
					<stop offset='1' stopColor='gray' stopOpacity='.1' />
				</linearGradient>
				<linearGradient
					id='Group_17__paint2_linear'
					x1='44.935'
					y1='45.188'
					x2='42.47'
					y2='24.938'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='gray' stopOpacity='.25' />
					<stop offset='.54' stopColor='gray' stopOpacity='.12' />
					<stop offset='1' stopColor='gray' stopOpacity='.1' />
				</linearGradient>
				<linearGradient
					id='Group_17__paint3_linear'
					x1='31.506'
					y1='49.642'
					x2='29.042'
					y2='29.392'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='gray' stopOpacity='.25' />
					<stop offset='.54' stopColor='gray' stopOpacity='.12' />
					<stop offset='1' stopColor='gray' stopOpacity='.1' />
				</linearGradient>
				<linearGradient
					id='Group_17__paint4_linear'
					x1='49.199'
					y1='60.169'
					x2='45.858'
					y2='32.721'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='gray' stopOpacity='.25' />
					<stop offset='.54' stopColor='gray' stopOpacity='.12' />
					<stop offset='1' stopColor='gray' stopOpacity='.1' />
				</linearGradient>
				<linearGradient
					id='Group_17__paint5_linear'
					x1='30.996'
					y1='66.207'
					x2='27.655'
					y2='38.758'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='gray' stopOpacity='.25' />
					<stop offset='.54' stopColor='gray' stopOpacity='.12' />
					<stop offset='1' stopColor='gray' stopOpacity='.1' />
				</linearGradient>
			</defs>
		</SvgIcon>
	);
};
