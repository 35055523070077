import { brandSecondary, destructive, error, header } from '../../../styles/colors';
import { BreakPoints, CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	body: {
		marginTop: 20,
	},
	canSpamText: {
		color: header,
		fontSize: 14,
		margin: '18px 0',
	},
	container: {
		position: 'relative',
		width: 800,
		[BreakPoints.tabletWidth]: {
			width: 700,
		},
		[BreakPoints.tabletCompactWidth]: {
			width: 600,
		},
	},
	defaultCheckbox: {
		marginTop: 16,
	},
	deleteButton: {
		alignItems: 'center',
		display: 'flex',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	deleteButtonText: {
		color: destructive,
		fontSize: 12,
	},
	documentEditor: {
		background: '#fff',
		border: '1px solid #ccc',
		height: 'auto',
		...CssSelectors.allChildren(
			{
				padding: 20,
			},
			'.rich-content-document-editor-readonly'
		),
	},
	documentEditorModal: {
		background: '#f9f9f9',
		height: 300,
	},
	error: {
		background: error,
		borderRadius: 3,
		bottom: 0,
		boxShadow: '0 1px 2px 0 rgba(162,162,162,0.50)',
		boxSizing: 'border-box',
		color: '#fff',
		display: 'flex',
		flexGrow: 1,
		fontSize: 14,
		fontWeight: 600,
		letterSpacing: 0,
		padding: '10px 20px',
	},
	footer: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: 20,
	},
	footerLeft: {
		alignItems: 'center',
		display: 'flex',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	header: {
		textAlign: 'right',
	},
	nameField: {
		marginTop: 16,
	},
	signatureEmailMessage: {
		color: '#4A4A4A',
		fontSize: 14,
		marginTop: 15,
	},
	signatureEmailMessageLink: {},
	title: {
		color: brandSecondary,
		fontSize: 18,
		lineHeight: '22px',
	},
});
