import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {},
	stepIcon: {
		marginRight: 5,
	},
	stepsOverflowLabel: {
		color: '#AAAAAA',
		fontSize: 11,
	},
});
