import { nameCircles } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	avatar: {
		display: 'inline-flex',
		fontSize: '16px',
		fontWeight: 600,
		position: 'relative',
	},
	avatarContent: {
		alignItems: 'center',
		backgroundColor: nameCircles,
		border: '1px solid #fff',
		borderRadius: '50%',
		boxSizing: 'border-box',
		color: '#fff',
		display: 'flex',
		height: '100%',
		justifyContent: 'center',
		overflow: 'hidden',
		position: 'relative',
		textAlign: 'center',
		textTransform: 'uppercase',
		width: '100%',
	},
	avatarImage: {
		backgroundColor: nameCircles,
		height: '100%',
		width: '100%',
	},
	avatarKit: {
		backgroundColor: 'white',
		borderRadius: '50%',
		bottom: 0,
		height: '17px',
		position: 'absolute',
		right: 0,
		width: '18px',
	},
	icon: {
		':nth-child(n)': {
			background: 'none',
			position: 'absolute',
			right: '-4px',
			top: '-6px',
		},
	},
	noEntityAvatarContent: {
		height: 20,
		width: 20,
	},
});
