import { baseStyleSheet } from '../../../styles/styles';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

export interface IChipCallbacks {
	onRemoveClicked?(e: React.MouseEvent<HTMLElement>): void;
}

interface IProps extends IChipCallbacks, React.AllHTMLAttributes<HTMLDivElement> {
	className?: string;
	removeIcon?: React.ReactNode;
	thumbnail?: React.ReactNode;
}

export const Chip: React.FC<IProps> = props => {
	const { className = '', children, removeIcon, thumbnail, onRemoveClicked, ...restProps } = props;
	const onRemoveButtonClicked = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		e.stopPropagation();
		if (onRemoveClicked) {
			onRemoveClicked(e);
		}
	};
	return (
		<div className={`${css(styleSheet.chip)} ${className}`} {...restProps}>
			{!!thumbnail && <div className={css(styleSheet.accessory, styleSheet.thumb)}>{thumbnail}</div>}
			<div className={css(styleSheet.label, baseStyleSheet.truncateText)}>{children}</div>
			{!!removeIcon && (
				<button className={css(styleSheet.accessory, styleSheet.remove)} onClick={onRemoveButtonClicked}>
					{removeIcon}
				</button>
			)}
		</div>
	);
};
