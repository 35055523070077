import * as React from 'react';
import { SvgIcon } from '../SvgIcon';

const initialHeight = 16;
const initialWidth = 21;

export const DataboardsIcon = ({
	className,
	width = initialWidth,
	height = initialHeight,
	fill = '#fff',
	...rest
}: React.SVGProps<SVGSVGElement>) => {
	return (
		<SvgIcon
			className={`opportunities-icon ${className}`}
			height={height}
			viewBox={`0 0 ${initialWidth} ${initialHeight}`}
			width={width}
			{...rest}
		>
			<g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
				<g transform='translate(-36.000100, -491.488665)' fill={fill}>
					<g id='Fill-636-+-Fill-637-+-Fill-638' transform='translate(36.000100, 491.488665)'>
						<path d='M18.997,14 L2,14 L2,2 L19,2 L18.997,14 Z M2,0 C0.897,0 0,0.897 0,2 L0,14 C0,15.103 0.897,16 2,16 L19,16 C20.103,16 21,15.103 21,14 L21,2 C21,0.897 20.103,0 19,0 L2,0 Z' />
						<rect fillRule='nonzero' x='1.9999' y='4' width='17' height='3' />
						<rect fillRule='nonzero' x='4.9999' y='11' width='2' height='1' />
						<rect fillRule='nonzero' x='7.9999' y='11' width='2' height='1' />
						<rect fillRule='nonzero' x='10.9999' y='11' width='2' height='1' />
						<rect fillRule='nonzero' x='13.9999' y='11' width='2' height='1' />
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
