import * as Api from '@ViewModels';
import produce from 'immer';
import { action, computed, observable, runInAction } from 'mobx';
import moment from 'moment';
import { stringify as toQueryString } from 'query-string';
import { createContext } from 'react';
import { Design } from 'react-email-editor';
import { ISubmittedValues } from '../admin/components/CreateCustomDashboardCard';
import { EnhancementType, Privacy, UserRole } from '../admin/containers/account/accountTabs/Settings/options';
import { TCustomSettings } from '../admin/containers/account/accountTabs/Settings/presentation';
import { GhostCalendarIndustryOptions } from '../admin/containers/templates/GhostCalendar/models';
import { UserRoleName } from '../admin/utils';
import { SlotMachineAchievementViewModel, SlotMachineAchievementsViewModel } from '../aida/viewModels/slotMachines';
import { DefaultSupportedIndustries, IEditEmailTemplateContent } from '../models';
import * as AdminModels from '../models/AdminModels';
import { FirstNamePlaceholder } from '../models/Token';
import {
	convertRawRichTextContentStateToRichContentEditorState,
	getCategoryPathByCampaignType,
	getProviderTypesFromPostTargest,
	getSubverticalOptions,
	getValidAllowLevitateImpersonationUntil,
	mapSocialMediaTypesToPostTargets,
} from '../models/UiUtils';
import { ISelectOption } from '../web/components/Select';
import { success } from '../web/styles/colors';
import {
	AppImportContactsViewModel,
	ComposeEmailViewModel,
	ContentCalendarSuggestionAll,
	DefaultBulkSendExcludedFilterCriteria,
	ETextingFeatureProvider,
	EmailWorkloadViewModel,
	IContentCalendarSuggestion,
	ICredential,
	UserSessionContext,
} from './AppViewModels';
import { HtmlNewsletterViewModel, IHtmlNewsletter } from './HtmlNewletterViewModels';

export interface IAccountDetailsContext {
	account: Api.IAccount;
	accountOperations: AccountOperationsViewModel;
}

export interface IAccountDetailsSettingsContext {
	onAddCustomCard?(values: ISubmittedValues): void;
	onAddCustomField?(): void;
	onAdminSendOnBehalfContactOwnerCriteriaChanged?(option: ISelectOption<Api.ContactOwnerCriteria>): void;
	onBlogEnabledChanged?(checked: boolean): void;
	onClassificationEmailsChanged?(checked: boolean): void;
	onComplianceEnabledChanged?(checked: boolean): void;
	onComplianceWaitTimeChanged?(selectedOptions: ISelectOption<Api.IMeetingDuration>): void;
	onConnectionTypesEnabledChanged?(checked: boolean): void;
	onCreateContactsFromCalendarChanged?(checked: boolean): void;
	onCsmViewMyCampaignsEnabledChanged?(checked: boolean): void;
	onDefaultContactsVisibilityChanged?(option: ISelectOption<Privacy>): void;
	onDefaultUserRoleChanged?(options: ISelectOption<UserRole>): void;
	onDeleteRenewalKeyFactsChanged?(checked: boolean): void;
	onDisableMobileAppsChanged?(checked: boolean): void;
	onHideCompaniesChanged?(checked: boolean): void;
	onHideEmailCampaignSignatureChanged?(checked: boolean): void;
	onShowAllContactsByDefaultChanged?(checked: boolean): void;
	onEditHtmlNewsletterFooter?(): void;
	onEnableCcInCampaignsChanged?(checked: boolean): void;
	onEnableContentGenerationChanged?(checked: boolean): void;
	onEnablePublicAPIChanged?(checked: boolean): void;
	onEnableSocialMediaChanged?(checked: boolean): void;
	onEnableSurveysChanged?(checked: boolean): void;
	onEnableTranslationChanged?(checked: boolean): void;
	onHandwrittenCardsEnabledChanged?(checked: boolean): void;
	onHouseholdsEnabledChanged?(checked: boolean): void;
	onHouseholdsSendToHouseholdByDefaultChanged?(checked: boolean): void;
	onHtmlNewsletterChanged?(checked: boolean): void;
	onHtmlNewsletterSendPrefChanged?(checked: boolean): void;
	onInternalMeetingsChanged?(checked: boolean): void;
	onLookForLeadsChanged?(checked: boolean): void;
	onMeetingCompanyNameRequiredChanged?(checked: boolean): void;
	onMeetingSchedulerEnabled?(checked: boolean): void;
	onDataBoardOpportunitiesEnabledChanged?(checked: boolean): void;
	onDataBoardDonationsEnabledChanged?(checked: boolean): void;
	onDataBoardPoliciesEnabledChanged?(checked: boolean): void;
	onRequiredRoleForFullLevitateUI?(options: ISelectOption<UserRole>): void;
	onRequiredRoleToViewAllOpportunities?(options: ISelectOption<UserRole>): void;
	onResetAccountClicked?(): void;
	onSaveForAnalysisChanged?(): void;
	onSendEmailForNewLeadChanged?(checked: boolean): void;
	onSendOnBehalfEnabled?(checked: boolean): void;
	onSendOnBehalfWaitTimeChanged?(selectedOptions: ISelectOption<Api.IMeetingDuration>): void;
	onSentMailClickTrackingChanged?(checked: boolean): void;
	onSentMailOpenTrackingChanged?(checked: boolean): void;
	onSetShowInternalSendOption?(checked: boolean): void;
	onSetAllowStartAutomationsOnHold?(checked: boolean): void;
	onSetEnhancementOption?(selectedOptions: ISelectOption<EnhancementType>): void;
	onSetFinancialReviewOption?(option: ISelectOption<number>): void;
	onSetFinancialReviewOptionChange?(checked: boolean): void;
	onSetMaximumGroupAutomationContacts?(option: ISelectOption<number>): void;
	onSetMaximumGroupAutomationContactsChanged?(checked: boolean): void;
	onSetMaximumGroupSendContacts?(option: ISelectOption<number>): void;
	onSetMaximumGroupSendContactsChanged?(checked: boolean): void;
	onSetMaximumHTMLNewsletterContacts?(option: ISelectOption<number>): void;
	onSetNoteViewerCanEditEnabledChanged?(checked: boolean): void;
	onSetRenewalReminderDaysOption?(option: ISelectOption<number>): void;
	onSetRenewalReminderDaysOptionChange?(checked: boolean): void;
	onSetRenewalSuppressMonthsOption?(option: ISelectOption<number>): void;
	onSetRenewalSuppressMonthsOptionChange?(checked: boolean): void;
	onSetShowDuplicateContacts?(checked: boolean): void;
	onSetShowUpcomingBirthdaysToOption?(options: ISelectOption<Api.UpcomingKeyDateDisplayScope>): void;
	onToggleAccountPreferenceBoolean?(path: BooleanKeys<Api.IAccountPreferences>, checked: boolean): void;
	onSetSuppressContactsRemovedFromResourceSends?(checked: boolean): void;
	onSetSurveyDefaultReviewLink?(url?: string): void;
	onSetSurveyNewReviewsEmailNotification?(enabled: boolean): void;
	onSetTurning65MonthsOption?(option: ISelectOption<number>): void;
	onSetTurning65MonthsOptionChange?(checked: boolean): void;
	onSetTurning72MonthsOption?(option: ISelectOption<number>): void;
	onSetTurning72MonthsOptionChange?(checked: boolean): void;
	onSetTurning73MonthsOption?(option: ISelectOption<number>): void;
	onSetTurning73MonthsOptionChange?(checked: boolean): void;
	onSetTurningXXMonthsOption?(option: ISelectOption<number>): void;
	onSetTurningXXMonthsOptionChange?(checked: boolean): void;
	onSuppressCustomCardOptionChange?(card: TCustomSettings): void;
	onTextingChanged?(checked: boolean): void;
	onTextingDailySendLimitChanged?(interval: number): void;
	onTextingHourlySendLimitChanged?(interval: number): void;
	onTextingProviderChanged?(option: ISelectOption<ETextingFeatureProvider>): void;
	onTextingSecondsBetweenSendsChanged?(seconds: number): void;
	onTextingRequireArchivingChanged?(checked: boolean): void;
	onTurningXXInputChanged?(value: number): void;
	onTimelineChanged?(checked: boolean): void;
	onTwoFactorAuthChanged?(checked: boolean): void;
	onWebsitesChanged?(checked: boolean): void;
	onWebsitesDudaSiteIdChanged?(value: string): void;
	onWebsitesWebsiteUrlChanged?(value: string): void;
	onUpdateCustomCard?(values: ISubmittedValues, card: TCustomSettings): void;
	onUpdateCustomCardDays?(value: ISelectOption<number>, card: TCustomSettings): void;
	setStartOnBehalfTriggerEnabled?(enabled: boolean): void;
	setTagAutomationTriggerEnabled?(enabled: boolean): void;
	setManualAutomationTriggerEnabled?(enabled: boolean): void;
	setTextingCampaignAutomationTriggerEnabled?(enabled: boolean): void;
	toggleAutomations?(checked: boolean): void;
}

// @ts-ignore
export const AccountDetailsContext = createContext<IAccountDetailsContext>(null);
// @ts-ignore
export const AccountDetailsSettingsContext = createContext<IAccountDetailsSettingsContext>(null);

export enum AdminPermission {
	/**
	 * Do not use All
	 *
	 * @deprecated
	 */
	All = 'All',
	AnnotateAccount = 'AnnotateAccount',
	AssignSuccessManager = 'AssignSuccessManager',
	CancelAutomations = 'CancelAutomations',
	CreateAndDeleteSnapshots = 'CreateAndDeleteSnapshots',
	CreateAutomationTemplateOnBehalf = 'CreateAutomationTemplateOnBehalf',
	CreateCampaigns = 'CreateCampaigns',
	Deny = 'Deny',
	ExportActiveUserData = 'ExportActiveUserData',
	InviteUsers = 'InviteUsers',
	ManageTemplates = 'ManageTemplates',
	ModifyAccount = 'ModifyAccount',
	ModifyUser = 'ModifyUser',
	OnlyForDevelopers = 'OnlyForDevelopers',
	ReadAccount = 'ReadAccount',
	ReadCampaigns = 'ReadCampaigns',
	ReadUser = 'ReadUser',
	ResetAccount = 'ResetAccount',
	ResetPassword = 'ResetPassword',
	RevertImport = 'RevertImport',
	SupportLevel3 = 'SupportLevel3',
	ViewErrorDetail = 'ViewErrorDetail',
	ViewUserErrors = 'ViewUserErrors',
}

export class AdminUserSessionContext extends Api.UserSessionContext {
	@observable.ref protected mPermissions: AdminPermission[];
	@observable.ref protected mPermissionsLoadingPromise: Promise<Api.IOperationResult<AdminPermission[]>>;

	@computed
	public get isLoadingPermissions() {
		return !!this.mPermissionsLoadingPromise;
	}

	@computed
	public get permissions() {
		return this.mPermissions
			? this.mPermissions.reduce<Record<string, boolean>>((result, x) => {
					result[x] = true;
					return result;
				}, {})
			: {};
	}

	public loadPermissions = () => {
		if (!this.mPermissionsLoadingPromise) {
			const promise = new Promise<Api.IOperationResult<AdminPermission[]>>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<string[]>) => {
					if (opResult.success) {
						this.mPermissions = opResult.value as AdminPermission[];
						this.mPermissionsLoadingPromise = null;
						resolve(opResult as Api.IOperationResult<AdminPermission[]>);
					} else {
						reject(opResult);
					}
				});
				this.webServiceHelper.callWebServiceWithOperationResults<string[]>(
					'admin/userOperations/permissions',
					'GET',
					null,
					onFinish,
					onFinish
				);
			});
			promise.catch(() => {
				// eat this error
				this.mPermissionsLoadingPromise = null;
				this.mPermissions = [];
			});
			this.mPermissionsLoadingPromise = promise;
		}

		return this.mPermissionsLoadingPromise;
	};

	public hasPermission = (permission: AdminPermission) => {
		return !!this.permissions[permission];
	};

	protected setUserSession(userSession?: Api.IUserSession) {
		super.setUserSession(userSession);
		if (userSession) {
			this.loadPermissions();
		} else {
			this.mPermissionsLoadingPromise = null;
			this.mPermissions = [];
		}
	}
}

export enum CancellationCategory {
	NeverGotStarted,
	DidntSeeValue,
	UseCase,
	Issues,
	Adoption,
	Price,
	Competitor,
	Concerns,
	Product,
	BusinessChanges,
}

type CancellationReasonRequest = {
	cancellationMessage: string;
	cancellationCategory: CancellationCategory;
};

export class AccountOperationsViewModel extends Api.ViewModel {
	@observable.ref protected mAccount: Api.IAccount;
	@observable.ref protected mDeleteKeyFactsPromise: Promise<Api.IOperationResult<Api.IOperationResultNoValue>>;
	@observable.ref
	protected mEmailProviderDefaultsPromise: Promise<Api.IEmailProviderConfiguration>;
	@observable.ref protected mInternalId: string;
	@observable.ref protected mInternalIdPromise: Promise<Api.IOperationResult<string>>;
	@observable.ref protected mLoadAccountPromise: Promise<Api.IAccount>;
	@observable.ref protected mSaveAccountPromise: Promise<Api.IAccount>;
	@observable.ref protected mSavingAccount: boolean;
	@observable.ref protected mBillingUpdatePromise: Promise<Api.IOperationResult<Api.IAccount>>;

	constructor(userSession: AdminUserSessionContext, account?: Api.IAccount) {
		super(userSession);
		this.mAccount = account;
	}

	@computed
	public get isLoadingBilling() {
		return !!this.mBillingUpdatePromise;
	}

	// load pdf statement
	@action
	public loadPdfStatement = () => {
		this.impersonate({ account: { id: this.userSession.account.id } });
		return new Promise((resolve, reject) => {
			this.userSession.webServiceHelper.callWebServiceRawResponse(
				this.composeApiUrl({ urlPath: `account/${this.account.id}/statement/pdf` }),
				'GET',
				null,
				response => {
					response.blob().then(blob => {
						const url = window.URL.createObjectURL(blob);
						const a = document.createElement('a');
						a.href = url;
						a.download = `Latest_Statement_${this.mAccount.companyName}_${new Date().toISOString()}.pdf`;
						// support older browsers
						document.body.appendChild(a);
						a.click();
						a.remove();
					});
					resolve(undefined);
				},
				error => reject(error)
			);
		});
	};

	// update billing status (dev only)
	@action
	public updateBilling = () => this.callBillingUpdate(`admin/account/${this.account.id}/updateBilling`, 'PUT');

	// update trial extension
	@action
	public updateTrialExtension = (trialExtension: Api.ITrialExtensionSettings) =>
		this.callBillingUpdate(`admin/account/${this.account.id}/trialExtension`, 'PUT', trialExtension.durationInDays);

	// discount
	@action
	public discount = (percent: Api.AccountDiscountPercent) =>
		this.callBillingUpdate(`admin/account/${this.account.id}/discount/${percent}`, 'PUT');

	// discount
	@action
	public removeDiscount = () => this.callBillingUpdate(`admin/account/${this.account.id}/discount`, 'DELETE');

	// cancel now
	@action
	public cancelImmediately = (cancellationReasonRequest: CancellationReasonRequest) =>
		this.callBillingUpdate(`admin/account/${this.account.id}/cancel/now/v2`, 'POST', cancellationReasonRequest);

	// cancel later
	@action
	public cancelLater = (cancellationReasonRequest: CancellationReasonRequest) =>
		this.callBillingUpdate(`admin/account/${this.account.id}/cancel/pending/v2`, 'PATCH', cancellationReasonRequest);

	// remove cancel later
	@action
	public removePendingCancellation = (reason: string) =>
		this.callBillingUpdate(`admin/account/${this.account.id}/cancel/remove`, 'DELETE', reason);

	@action
	public updateCancellationStatus = (cancellationReasonRequest: CancellationReasonRequest) =>
		this.callBillingUpdate(`admin/account/${this.account.id}/cancel/reason/v2`, 'PUT', cancellationReasonRequest);

	@action
	public unsubscribeContactsByEmail = (emails: string[]) => {
		return new Promise<Api.IBulkOperationResult<string>>((resolve, reject) => {
			return this.mUserSession.webServiceHelper.callWebServiceWithBulkOperationResult<string>(
				`impersonate/${this.account.id}/contact/unsubscribe`,
				'POST',
				{ emails },
				ok => resolve(ok),
				err => reject(Api.asApiError(err))
			);
		});
	};

	@action
	public grantTemporaryAccess = async () => {
		const value: Api.IAccount = await this.mUserSession.webServiceHelper.callAsync<Api.IAccount>(
			`impersonate/${this.account.id}/account/grantLevitateTemporaryAccess`,
			'PUT',
			null
		);
		this.mAccount = value;
		return value;
	};

	@action
	private callBillingUpdate = async (route: string, method: Api.HTTPMethod, body?: any) => {
		if (!this.mBillingUpdatePromise) {
			try {
				const value = await this.mUserSession.webServiceHelper.callAsync<Api.IAccount>(route, method, body);
				this.mAccount = value;
				return value;
			} finally {
				runInAction(() => {
					this.mBillingUpdatePromise = null;
				});
			}
		}
		return null;
	};

	@computed
	public get isBusy() {
		return this.loading || this.busy || this.isSaving;
	}

	@computed
	public get account() {
		return this.mAccount;
	}

	@computed
	public get isGettingEmailSendLimits() {
		return !!this.mEmailProviderDefaultsPromise;
	}

	@computed
	public get isDeletingKeyFacts() {
		return !!this.mDeleteKeyFactsPromise;
	}

	@computed
	public get isLoaded() {
		return !this.account || !this.account.id || !!this.account.creationDate;
	}

	@computed
	public get isSaving() {
		return !!this.mSaveAccountPromise || !!this.mSavingAccount;
	}

	@computed
	public get isLoading() {
		return !!this.mLoadAccountPromise;
	}

	@computed
	public get hasPlanDetails() {
		return this.mAccount && this.mAccount.planDetails;
	}

	@computed
	public get hasAccountAdditionalInfo() {
		return this.mAccount && this.mAccount.additionalInfo;
	}

	@computed
	public get hasAccountPreferences() {
		return this.mAccount && this.mAccount.preferences;
	}

	@computed
	public get hasAccountPreferencesDefaults() {
		return this.hasAccountPreferences && this.mAccount.preferences.defaults;
	}

	@computed
	public get accountName() {
		return this.account?.companyName;
	}

	@computed
	public get accountStatus() {
		return !this.account?.activationStatus ? 'UNKNOWN' : this.account.activationStatus;
	}

	@computed
	public get accountStatusDescription() {
		if (!this.account?.activationStatus) {
			return 'Unknown';
		}
		return `${this.account.activationStatus.charAt(0).toLocaleUpperCase()}${this.account.activationStatus
			.slice(1)
			.toLocaleLowerCase()}`;
	}

	@computed get billingType() {
		return this.account?.planDetails?.billingType;
	}

	@computed
	public get billingTypeDescription() {
		if (!this.account?.planDetails?.billingType) {
			return 'Unknown';
		}
		return `${this.account.planDetails.billingType.charAt(0).toLocaleUpperCase()}${this.account.planDetails.billingType
			.slice(1)
			.toLocaleLowerCase()}`;
	}

	@computed
	public get isCancelable() {
		return this.billingType === Api.BillingType.Trial || this.billingType === Api.BillingType.Comp;
	}

	@computed
	public get isPaid() {
		return this.billingType === Api.BillingType.Paid || this.billingType === Api.BillingType.Comp;
	}

	@computed
	public get isTrial() {
		return this.billingType === Api.BillingType.Trial;
	}

	@computed
	public get creationDateDescription() {
		const creationDate: Date = this.account?.creationDate;
		return creationDate ? moment(creationDate).format('MM/DD/YYYY') : 'Unknown';
	}

	@computed
	public get renewalOrExpireDateDescription() {
		const renewalDate: Date = this?.account?.planDetails?.renewalDate;
		const trialExpirationDate: Date = this?.account?.planDetails?.trialExpirationDate;
		const billingType: string = this?.account?.planDetails?.billingType;

		if (billingType === Api.BillingType.Paid) {
			return moment(renewalDate).format('MM/DD/YYYY');
		} else if (billingType === Api.BillingType.Trial) {
			return moment(trialExpirationDate).format('MM/DD/YYYY');
		} else {
			return 'N/A'; // Comps
		}
	}

	@computed
	public get lastCsmMeetingDate() {
		const lastCsmMeeting: Date = this.account?.additionalInfo?.lastCsmMeeting;

		if (!lastCsmMeeting) {
			return 'Unknown';
		}

		return moment(lastCsmMeeting).format('MM/DD/YYYY');
	}

	@computed
	public get nextMeeting() {
		const nextMeeting = this.account?.additionalInfo?.nextMeeting;

		if (!nextMeeting) {
			return 'Not Set';
		}

		const momentNextMeeting = moment(nextMeeting).utc();
		if (momentNextMeeting.isSame(moment().startOf('day'), 'd')) {
			return 'Today';
		} else if (momentNextMeeting.isSame(moment().add(1, 'days').startOf('day'), 'd')) {
			return 'Tomorrow';
		}

		return momentNextMeeting.format('MM/DD/YYYY');
	}

	@computed
	public get billingCycleDescription() {
		const billingType: string = this?.account?.planDetails?.billingType;
		const billingCycle = this?.account?.planDetails?.billingCycle;
		return billingType === Api.BillingType.Paid && billingCycle
			? this?.account?.planDetails?.billingCycle.toString() + ' months' || 'N/A'
			: 'N/A';
	}

	@computed
	public get industryDescription() {
		const industry: string = this.account?.additionalInfo?.industry;
		return industry || 'Unknown';
	}

	@computed
	public get soldByDescription() {
		const salesRepName: string = this.account?.additionalInfo?.salesRepName;
		return salesRepName || 'Unknown';
	}

	@computed
	public get successManagerDescription() {
		const customerSuccessName: string = this.account?.additionalInfo?.customerSuccessName;
		return customerSuccessName || 'Unknown';
	}

	@computed
	public get stageDescription() {
		const stage: string = this.account?.additionalInfo?.stage;
		return stage || 'Unknown';
	}

	@computed
	public get brokerDealer() {
		return this.account?.additionalInfo?.brokerDealer || 'Unknown';
	}

	@computed get systemOfRecord() {
		return this.account?.additionalInfo.systemOfRecord || 'Unknown';
	}

	@computed
	public get defaultUserRole(): Api.UserRole {
		return this.hasAccountPreferencesDefaults && this.mAccount.preferences.defaults.userRole
			? this.mAccount.preferences.defaults.userRole
			: 'user';
	}

	@computed
	public get accountFlagged() {
		return this.account?.additionalInfo?.accountFlagged;
	}

	@computed
	public get allowLevitateImpersonationUntil() {
		return getValidAllowLevitateImpersonationUntil(this.account);
	}

	public resetAccount = async (account: Api.IAccount) => {
		if (!this.isBusy && account?.id) {
			return this.mUserSession.webServiceHelper.callAsync<Api.IAccount>(`admin/account/${account.id}/reset`, 'POST');
		}
	};

	public resetAccountAndIntegration = async (account: Api.IAccount) => {
		if (!this.isBusy && account?.id) {
			return this.mUserSession.webServiceHelper.callAsync<Api.IAccount>(
				`admin/account/${account.id}/reset?includeIntegrations=true`,
				'POST'
			);
		}
	};

	public deactivateAccount = async (account: Api.IAccount) => {
		if (!this.isBusy) {
			const value = await this.mUserSession.webServiceHelper.callAsync<Api.IAccount>(
				`admin/account/${account.id}/DeactivateNow`,
				'POST'
			);
			this.mAccount = value;
			return value;
		}
	};

	public getInitialMeetingTeam = async (account: Api.IAccount) => {
		if (!this.isBusy && account?.id) {
			const value = await this.mUserSession.webServiceHelper.callAsync<Api.IAccountTeam>(
				`admin/Scheduler/${account.id}/InitialMeetingTeam`,
				'GET'
			);
			return value;
		}
	};

	public getScheduleMeetingUrl = async (account: Api.IAccount, team: string) => {
		if (!this.isBusy && account?.id) {
			const value = await this.mUserSession.webServiceHelper.callAsync<Api.IInitiateSchedulerResponse>(
				`admin/Scheduler/${account.id}/${team}`,
				'POST'
			);
			return value;
		}
	};

	public getScheduleMeetingShortcut = async (account: Api.IAccount, team: string) => {
		if (!this.isBusy && account?.id) {
			const value = await this.mUserSession.webServiceHelper.callAsync<Api.IInitiateSchedulerResponse>(
				`admin/Scheduler/${account.id}/${team}/shortcut`,
				'POST'
			);
			return value;
		}
	};

	public getTeamsByAccountId = (account?: Api.IAccount) => {
		const mAccount = account || this.mAccount;
		if (!this.isBusy && mAccount.id) {
			const promise = new Promise<Api.IAccountTeam[]>((resolve, reject) => {
				this.mUserSession.webServiceHelper
					.callWebServiceAsync<Api.IAccountTeam[]>(`admin/Scheduler/${mAccount.id}/Teams`, 'GET')
					.then(opResult => {
						resolve(opResult as Api.IAccountTeam[]);
					})
					.catch(e => {
						reject(e);
					});
			});
			return promise;
		}
		return null;
	};

	public getDefaultEmailSendLimits = (provider: Api.EmailProviderType): Promise<Api.IEmailProviderConfiguration> => {
		if (!this.isGettingEmailSendLimits) {
			this.mEmailProviderDefaultsPromise = new Promise<Api.IEmailProviderConfiguration>((resolve, reject) => {
				this.mUserSession.webServiceHelper
					.callWebServiceAsync<Api.IEmailProviderConfiguration>(
						`admin/email/defaultConfiguration?provider=${provider}`,
						'GET'
					)
					.then((opResult: Api.IOperationResult<Api.IEmailProviderConfiguration>) => {
						this.mEmailProviderDefaultsPromise = null;

						if (opResult.success) {
							resolve(opResult.value);
						} else {
							reject(opResult);
						}
					});
			});
		}

		return this.mEmailProviderDefaultsPromise;
	};

	public deleteKeyFacts = (keyFactKind: Api.KeyDateKind) => {
		if (!this.isDeletingKeyFacts) {
			this.mDeleteKeyFactsPromise = new Promise<Api.IOperationResult<Api.IOperationResultNoValue>>(
				(resolve, reject) => {
					this.mUserSession.webServiceHelper
						.callAsync<Api.IOperationResult<Api.IOperationResultNoValue>>(
							`impersonate/${this.mAccount.id}/Contact/KeyFact?keyFactKind=${keyFactKind}`,
							'DELETE'
						)
						.then(() => {
							resolve(undefined);
						})
						.catch(error => {
							reject(error);
						})
						.finally(() => {
							this.mDeleteKeyFactsPromise = null;
						});
				}
			);
			return this.mDeleteKeyFactsPromise;
		}

		return null;
	};

	@action
	public setAccountLogo = async (file: Api.IFileAttachmentWithURL) => {
		this.mAccount = {
			...this.mAccount,
			preferences: {
				...this.mAccount.preferences,
				logo: file,
			},
		};
	};

	public saveAccount = async (account: Api.IAccount) => {
		if (this.isBusy) {
			return null;
		}

		this.mSavingAccount = true;
		try {
			const value = await this.mUserSession.webServiceHelper.callAsync<Api.IAccount>(
				`admin/account/${account.id}`,
				'PUT',
				account
			);
			this.mAccount = value;
			return value;
		} catch (error) {
			throw Api.asApiError(error);
		} finally {
			this.mSavingAccount = false;
		}
	};

	public saveMainAccountId = async (account: Api.IAccount) => {
		if (this.isBusy) {
			return null;
		}

		this.mSavingAccount = true;
		try {
			const value = await this.mUserSession.webServiceHelper.callAsync<Api.IAccount>(
				`admin/account/${account.id}/mainAccountId/?mainAccountId=${account.mainAccountId ?? ''}`,
				'PATCH'
			);
			this.mAccount = value;
			return value;
		} catch (error) {
			throw Api.asApiError(error);
		} finally {
			this.mSavingAccount = false;
		}
	};

	public takeSnapshot = async () => {
		if (!this.isBusy) {
			const value = await this.mUserSession.webServiceHelper.callAsync<AdminModels.IAccountSnapshot>(
				`admin/accountSnapshot/${this.account.id}`,
				'POST',
				null
			);
			return value;
		}
	};

	public load = (account?: Api.IAccount) => {
		const mAccount = account || this.mAccount;
		if (!this.isBusy && mAccount.id) {
			const promise = new Promise<Api.IAccount>((resolve, reject) => {
				this.mUserSession.webServiceHelper
					.callAsync<Api.IAccount>(`admin/account/${mAccount.id}`, 'GET')
					.then((value: Api.IAccount) => {
						this.mLoadAccountPromise = null;
						this.mAccount = value;
						resolve(value);
					})
					.catch(e => {
						reject(e);
					});
			});
			this.mLoadAccountPromise = promise;
			return promise;
		}

		return null;
	};

	public getAccountById = async (accountId: string) => {
		if (this.isBusy) {
			return null;
		}

		try {
			const value = await this.mUserSession.webServiceHelper.callAsync<Api.IAccount>(
				`admin/account/${accountId}`,
				'GET'
			);
			return value;
		} catch (error) {
			throw Api.asApiError(error);
		}
	};

	/**
	 * This method allows a user to self-register. The only requirement is that the email address is not already being
	 * used and the user's email address matches the EmailDomain field on the Account. Requires: @param user.accountId ||
	 *
	 * @param this.account.id
	 *
	 *   || @param account
	 */
	public createUser = async (user: Partial<Api.IUser>, sendWelcomeEmail = false, account?: Api.IAccount) => {
		const accountId = user?.accountId || account?.id || this.mAccount?.id;
		if (accountId) {
			const value = await this.mUserSession.webServiceHelper.callAsync<Api.IUser>(
				`user/register?sendWelcomeEmail=${sendWelcomeEmail}`,
				'POST',
				{
					...user,
					accountId,
					email: user.primaryEmail.value,
				}
			);
			return value;
		}
	};

	@action
	public loadInternalCompanyId = () => {
		if (this.mInternalId) {
			return Promise.resolve<Api.IOperationResult<string>>({
				success: true,
				systemCode: 200,
				value: this.mInternalId,
			});
		}
		if (!this.mInternalIdPromise) {
			this.mInternalIdPromise = new Promise<Api.IOperationResult<string>>((resolve, reject) => {
				const onFinish = (opResult: Api.IOperationResult<string>) => {
					runInAction(() => {
						if (opResult.success) {
							this.mInternalId = opResult.value;
							this.mInternalIdPromise = null;
							resolve(opResult);
						} else {
							this.mInternalIdPromise = null;
							reject(opResult);
						}
					});
				};
				this.userSession.webServiceHelper.callWebServiceWithOperationResults<string>(
					`admin/account/${this.mAccount.id}/internalCompanyId`,
					'GET',
					null,
					onFinish,
					onFinish
				);
			});
			return this.mInternalIdPromise;
		}
	};

	public updateAccountNote = async (
		account: Api.IAccount,
		type: AdminModels.InternalMeetingNoteType,
		note: Api.INote,
		formData?: FormData
	) => {
		if (!this.isBusy) {
			try {
				// Only update the Sales Notes in place.
				const value = await (note.id && type === AdminModels.InternalMeetingNoteType.Sales
					? this.mUserSession.webServiceHelper.callAsync<Api.INote>(
							`note/${note.id}`,
							'PUT',
							formData ? formData : note
						)
					: this.mUserSession.webServiceHelper.callAsync<Api.INote>(
							`admin/account/${account.id}/additionalInfo/note/${type}`,
							'PUT',
							formData ? formData : note
						));
				this.mAccount = {
					...this.mAccount,
					additionalInfo: {
						...(this.mAccount.additionalInfo || {}),
						summaryNoteId: value.id,
					},
				};
				return value;
			} finally {
				this.mSavingAccount = false;
			}
		}
	};

	public addAccountMeetingNote = async (
		account: Api.IAccount,
		customerMeeting: AdminModels.ICustomerMeeting,
		formData?: FormData
	) => {
		if (!this.isBusy) {
			this.mSavingAccount = true;
			delete customerMeeting.note.id;

			try {
				const value = await this.mUserSession.webServiceHelper.callAsync<Api.INote>(
					`admin/account/${account.id}/meeting`,
					'POST',
					formData ? formData : customerMeeting
				);
				this.mAccount = {
					...this.mAccount,
					additionalInfo: {
						...(this.mAccount.additionalInfo || {}),
						csmNoteId: value.id,
					},
				};
				return value;
			} finally {
				this.mSaveAccountPromise = null;
				this.mSavingAccount = false;
			}
		}
	};

	public updateShareFileFolderLink = (account: Api.IAccount, folderLink: string) => {
		if (!this.isBusy) {
			const promise = new Promise<Api.IAccount>((resolve, reject) => {
				this.mUserSession.webServiceHelper
					.callAsync<Api.IAccount>(
						`admin/account/${account.id}/additionalInfo/folderLink?folderLink=${encodeURIComponent(folderLink) || ''}`,
						'PUT'
					)
					.then((value: Api.IAccount) => {
						this.mAccount = value;
						resolve(value);
					})
					.catch(e => {
						reject(e);
					})
					.finally(() => {
						this.mSaveAccountPromise = null;
					});
			});
			this.mSaveAccountPromise = promise;
			return promise;
		}
	};

	public updateNextMeeting = (nextMeeting: Date) => {
		if (!this.isBusy) {
			const promise = new Promise<Api.IAccount>((resolve, reject) => {
				this.mUserSession.webServiceHelper
					.callAsync<Api.IAccount>(
						`admin/account/${this.account.id}/additionalInfo/nextMeeting?nextMeeting=${
							encodeURIComponent(moment(nextMeeting).format('MM/DD/YYYY')) || ''
						}`,
						'PUT'
					)
					.then((value: Api.IAccount) => {
						this.mAccount = value;
						resolve(value);
					})
					.catch(e => {
						reject(e);
					})
					.finally(() => {
						this.mSaveAccountPromise = null;
					});
			});
			this.mSaveAccountPromise = promise;
			return promise;
		}

		return null;
	};

	public updateAccountFlagged = () => {
		if (!this.isBusy) {
			const updatedFlagged = !this.account?.additionalInfo?.accountFlagged;
			const promise = new Promise<Api.IAccount>((resolve, reject) => {
				this.mUserSession.webServiceHelper
					.callAsync<Api.IAccount>(
						`admin/account/${this.account.id}/additionalInfo/accountFlagged?accountFlagged=${encodeURIComponent(
							updatedFlagged
						)}`,
						'PUT'
					)
					.then((value: Api.IAccount) => {
						this.mAccount = value;
						resolve(value);
					})
					.catch(error => {
						reject(error);
					})
					.finally(() => {
						this.mSaveAccountPromise = null;
					});
			});
			this.mSaveAccountPromise = promise;
			return promise;
		}

		return null;
	};

	@action
	public updateAutomationFeatures(features: Api.IAutomationFeatures) {
		return this.updateAccountFeatures('automation', features);
	}

	@action
	public updateConnectionTypesFeature(features: Api.IConnectionTypesFeature) {
		return this.updateAccountFeatures('connectionTypes', features);
	}

	@action
	public async updateHtmlNewsletterFeatures(features: Api.IHtmlNewsletterFeatures) {
		return this.updateAccountFeatures('htmlNewsletter', features);
	}

	@action
	public async updateMeetingSchedulerFeatures(features: Api.IMeetingSchedulerFeature) {
		return this.updateAccountFeatures('meetingScheduler', features);
	}

	@action
	public async updateTextingFeatures(features: Api.ITextingFeature) {
		return this.updateAccountFeatures('texting', features);
	}

	@action
	public async updateHandwrittenFeatures(features: Api.IHandwrittenCardsFeature) {
		return this.updateAccountFeatures('handwrittenCards', features);
	}

	@action
	public async updateHouseholdsFeature(features: Api.IHouseholdsFeature) {
		return this.updateAccountFeatures('households', features);
	}

	@action
	public async updateTimelineFeatures(features: Api.IFeature) {
		return this.updateAccountFeatures('timeline', features);
	}

	@action
	public async updateSurveyFeatures(features: Api.ISurveysFeature) {
		return this.updateAccountFeatures('surveys', features);
	}

	@action
	public async updateSocialMediaFeatures(features: Api.ISocialMediaFeature) {
		return this.updateAccountFeatures('socialMedia', features);
	}

	@action
	public async updateTranslationFeatures(features: Api.ITranslationFeature) {
		return this.updateAccountFeatures('translation', features);
	}

	@action
	public async updateContentGenerationFeatures(features: Api.IContentGenerationFeature) {
		return this.updateAccountFeatures('contentGeneration', features);
	}

	@action
	public async updatePublicAPIFeatures(features: Api.IPublicAPIFeature) {
		return this.updateAccountFeatures('publicApi', features);
	}

	@action
	private async updateAccountFeatures(path: keyof Api.IAccountFeatures, feature: Api.IFeature) {
		this.impersonate({ account: { id: this.mAccount.id } });
		if (!this.isBusy) {
			this.busy = true;
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IFeature>(
				this.composeApiUrl({ urlPath: `account/features/${path}` }),
				'PUT',
				feature
			);
			if (opResult.success) {
				produce(this.mAccount, (draft = {}) => {
					draft.features = draft.features || {};
					// FOLLOWUP: Resolve
					// @ts-ignore
					draft.features[path] = opResult.value;
				});
			}
			this.busy = false;
			return opResult;
		}
	}

	@action
	public async updateClickTracking(shouldTrack: boolean) {
		return this.toggleAccountPreference('clickTrackingEnabled', shouldTrack, 'shouldTrack');
	}

	@action
	public async updateCsmViewMyCampaignsEnabled(canView: boolean) {
		return this.toggleAccountPreference('csmViewMyCampaignsEnabled', canView, 'canView');
	}

	@action
	public async updateComplianceEnabled(enabled: boolean) {
		return this.toggleAccountPreference('compliance', enabled, 'enabled');
	}

	@action
	public async updateComplianceWaitTime(duration: string) {
		this.impersonate({ account: { id: this.mAccount.id } });
		if (!this.isBusy) {
			this.busy = true;
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IAccount>(
				this.composeApiUrl({ urlPath: `account/preferences/waitTime/${duration}` }),
				'PUT'
			);
			if (opResult.success) {
				this.mAccount = opResult.value;
			}
			this.busy = false;
			return opResult;
		}
	}

	@action
	public toggleTwoFactorAuth = async (enabled: boolean) => {
		this.impersonate({ account: { id: this.mAccount.id } });
		if (!this.isBusy) {
			this.busy = true;
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IAccount>(
				this.composeApiUrl({ queryParams: { enabled }, urlPath: `account/requireSecondAuthFactor` }),
				'PUT'
			);
			this.busy = false;
			if (opResult.success) {
				this.mAccount = opResult.value;
			} else {
				throw opResult;
			}
			return opResult;
		}
	};

	@action
	public updateBlogEnabled = async (enabled: boolean) => {
		this.impersonate({ account: { id: this.mAccount.id } });
		if (!this.isBusy) {
			this.busy = true;
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IAccount>(
				this.composeApiUrl({ queryParams: { enabled }, urlPath: `account/features/blog` }),
				'PUT'
			);
			this.busy = false;
			if (opResult.success) {
				this.mAccount = opResult.value;
			} else {
				throw opResult;
			}
			return opResult;
		}
	};

	@action
	public updateBlogFeatures = async ({
		domain,
		enabled,
		allowedOrigins = [],
		dudaSiteId,
	}: Partial<Api.IBlogFeature>) => {
		this.impersonate({ account: { id: this.mAccount.id } });
		if (!this.isBusy) {
			this.busy = true;
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IAccount>(
				this.composeApiUrl({ urlPath: `account/features/blogfeature` }),
				'PUT',
				{ ...(this.mAccount?.features?.blogFeature || {}), domain, enabled, allowedOrigins, dudaSiteId }
			);
			this.busy = false;
			if (opResult.success) {
				this.mAccount = opResult.value;
			} else {
				throw opResult;
			}
			return opResult;
		}
	};

	@action
	public updateWebsitesFeature = async ({ enabled, websiteUrl, dudaSiteId }: Partial<Api.IWebsitesFeature>) => {
		this.impersonate({ account: { id: this.mAccount.id } });
		if (!this.isBusy) {
			this.busy = true;
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IAccount>(
				this.composeApiUrl({ urlPath: `account/features/websites` }),
				'PUT',
				{ ...(this.mAccount?.features?.websites || {}), dudaSiteId, enabled, websiteUrl }
			);
			this.busy = false;
			if (opResult.success) {
				this.mAccount = opResult.value;
			} else {
				throw opResult;
			}
			return opResult;
		}
	};

	@action
	public async updateDisableMobileApps(value: boolean) {
		return this.toggleAccountPreference('disableMobileApps', value, 'value');
	}

	@action
	public async updateHideCompanies(value: boolean) {
		return this.toggleAccountPreference('hideCompanies', value, 'value');
	}

	@action
	public async updateHideEmailCampaignSignature(value: boolean) {
		return this.toggleAccountPreference('hideEmailCampaignSignature', value, 'value');
	}

	@action
	public async updatShowAllContactsByDefault(value: boolean) {
		return this.toggleAccountPreference('showAllContactsByDefault', value, 'value');
	}

	@action
	public async updateAnyUserEditingNotes(canEdit: boolean) {
		return this.toggleAccountPreference('noteViewerCanEditEnabled', canEdit, 'canEdit');
	}

	@action
	public async toggleAccountPreference(path: string, enabled: boolean, paramName = 'enabled') {
		this.impersonate({ account: { id: this.mAccount.id } });
		if (!this.isBusy) {
			this.busy = true;
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IAccount>(
				this.composeApiUrl({ urlPath: `account/preferences/${path}?${paramName}=${enabled}` }),
				'PUT'
			);
			if (opResult.success) {
				this.mAccount = opResult.value;
			}
			this.busy = false;
			return opResult;
		}
	}

	@action
	public async updateAccountPreferenceString(path: string, value: string, paramName = 'value') {
		this.impersonate({ account: { id: this.mAccount.id } });
		if (!this.isBusy) {
			this.busy = true;
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IAccount>(
				this.composeApiUrl({ urlPath: `account/preferences/${path}?${paramName}=${value}` }),
				'PUT'
			);
			if (opResult.success) {
				this.mAccount = opResult.value;
			}
			this.busy = false;
			return opResult;
		}
	}

	@action
	public async toggleSaveForAnalysis() {
		this.impersonate({ account: { id: this.mAccount.id } });
		if (!this.isBusy) {
			this.busy = true;
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IEmailScanFeatures>(
				this.composeApiUrl({ urlPath: `account/features/emailScan/toggleSaveForAnalysis` }),
				'PATCH'
			);
			if (opResult.success) {
				produce(this.mAccount, (draft = {}) => {
					draft.features = draft.features || {};
					draft.features.emailScan = opResult.value;
				});
			}
			this.busy = false;
			return opResult;
		}
	}
}

export class AdminUserViewModel extends Api.UserViewModel {
	constructor(userSession: AdminUserSessionContext, user: Api.IUser, impersonationContext?: Api.IImpersonationContext) {
		super(userSession, user);
		this.mImpersonationContext = impersonationContext;
	}

	@action
	public load(forceReload = false) {
		if (this.isLoaded && !forceReload) {
			return Promise.resolve(this.mUser);
		}

		if (!this.loadingPromise) {
			this.loading = true;
			this.loadingPromise = new Promise<Api.IUser>((resolve, reject) => {
				const onFinish = (result: Api.IOperationResult<Api.IUser>) => {
					runInAction(() => {
						this.loading = false;
						this.loadingPromise = null;
						if (result.success) {
							this.mSetUser(result.value);
							this.loaded = true;
							resolve(result.value);
						} else {
							reject(result);
						}
					});
				};
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IUser>(
					`${this.mImpersonationContext ? `impersonate/${this.mImpersonationContext.account.id}/` : ''}user/${
						this.mUser.id
					}`,
					'GET',
					null,
					onFinish,
					onFinish
				);
			});
		}

		return this.loadingPromise;
	}
}

export class AccountUserViewModel extends Api.UserViewModel {
	@observable protected mPhoneNumberOrders: Api.ITelephonyConfiguration[];
	constructor(userSession: Api.UserSessionContext, user: Api.IUserWithStats | Api.IUser) {
		super(userSession, user);
	}

	@computed
	public get userWithStats() {
		return this.mUser as Api.IUserWithStats;
	}

	@computed
	public get userRoleDescription() {
		return this.role ? UserRoleName[this.role] : this.groups.findIndex(x => x === 'admin') >= 0 ? 'Admin' : 'Employee';
	}

	@computed
	public get primaryEmailDomain() {
		return this.mUser?.primaryEmail?.value?.split('@')[1];
	}

	@computed
	public get emailCalendarTokenExists() {
		return this.mUser?.emailCalendarTokenExists;
	}

	@computed
	public get reconnectRequired() {
		return this.mUser?.reconnectRequired;
	}

	@computed
	public get textingPhoneNumber() {
		return this.mPhoneNumberOrders?.[0];
	}
	public set textingPhoneNumber(value: Api.ITelephonyConfiguration) {
		this.mPhoneNumberOrders[0] = value;
	}

	@action
	public updateUserStatus = (path: AdminModels.UserStatusAction) => {
		if (!this.isBusy) {
			this.busy = true;
			const promise = new Promise<Api.IUserWithStats>((resolve, reject) => {
				const onFinish = (opResult: Api.IOperationResult<Api.IUserWithStats>) => {
					runInAction(() => {
						if (opResult.success) {
							this.mUser = opResult.value;
							this.busy = false;
							resolve(opResult.value);
						} else {
							this.busy = false;
							reject(opResult);
						}
					});
				};
				this.userSession.webServiceHelper.callWebServiceWithOperationResults<Api.IUserWithStats>(
					`admin/userOperations/${this.id}/${path}`,
					'POST',
					null,
					onFinish,
					onFinish
				);
			});
			return promise;
		}
	};

	@action
	public loadUserStats = () => {
		if (!this.isBusy) {
			this.busy = true;
			const promise = new Promise<Api.IUserStats>((resolve, reject) => {
				const onFinish = (opResult: Api.IOperationResult<Api.IUserStats>) => {
					runInAction(() => {
						if (opResult.success) {
							const userStats: Api.IUserWithStats = {
								...(this.mUser || {}),
								stats: opResult.value,
							};
							this.mSetUser(userStats);
							this.busy = false;
							resolve(opResult.value);
						} else {
							this.busy = false;
							reject(opResult);
						}
					});
				};
				this.userSession.webServiceHelper.callWebServiceWithOperationResults<Api.IUserStats>(
					`admin/userOperations/${this.id}/stats`,
					'GET',
					null,
					onFinish,
					onFinish
				);
			});
			return promise;
		}
	};

	@action
	public updateTrackingPixelPollingValue = (prefs: Api.IUserPreferences, enabled: boolean) => {
		if (!this.isBusy) {
			this.busy = true;
			const newPreferences = produce(prefs, (draft = {}) => {
				draft.clients = draft?.clients || {};
				draft.clients.outlookClient = draft.clients.outlookClient || {};
				draft.clients.outlookClient.trackingPixelPollingEnabled = enabled;
			});
			const promise = new Promise<Api.IUser>((resolve, reject) => {
				const onFinish = (opResult: Api.IOperationResult<Api.IUser>) => {
					runInAction(() => {
						if (opResult.success) {
							this.mUser = {
								...this.userWithStats,
								...opResult.value,
							};
							this.busy = false;
							resolve(opResult.value);
						} else {
							this.busy = false;
							reject(opResult);
						}
					});
				};
				this.userSession.webServiceHelper.callWebServiceWithOperationResults<Api.IUser>(
					`admin/userOperations/${this.id}/userPreferences`,
					'PUT',
					newPreferences,
					onFinish,
					onFinish
				);
			});
			return promise;
		}
	};

	@action
	public updateEmailConnectionOptOutValue = (optout: boolean) => {
		if (!this.isBusy) {
			this.busy = true;

			const promise = new Promise<Api.IUser>((resolve, reject) => {
				const onFinish = (opResult: Api.IOperationResult<Api.IUser>) => {
					runInAction(() => {
						if (opResult.success) {
							this.mUser = {
								...this.userWithStats,
								...opResult.value,
							};
							this.busy = false;
							resolve(opResult.value);
						} else {
							this.busy = false;
							reject(opResult);
						}
					});
				};
				const url = this.composeApiUrl({
					queryParams: { value: optout },
					urlPath: 'user/userPreferences/emailConnectionOptOut',
				});
				this.userSession.webServiceHelper.callWebServiceWithOperationResults<Api.IUser>(
					url,
					'PUT',
					null,
					onFinish,
					onFinish
				);
			});
			return promise;
		}
	};

	@action
	public updateUserPreferencesAsAdmin = (newPreferences: Api.IUserPreferences) => {
		if (!this.isBusy) {
			this.busy = true;
			const promise = new Promise<Api.IUser>((resolve, reject) => {
				const onFinish = (opResult: Api.IOperationResult<Api.IUser>) => {
					runInAction(() => {
						if (opResult.success) {
							this.mUser = {
								...this.userWithStats,
								...opResult.value,
							};
							this.busy = false;
							resolve(opResult.value);
						} else {
							this.busy = false;
							reject(opResult);
						}
					});
				};
				this.userSession.webServiceHelper.callWebServiceWithOperationResults<Api.IUser>(
					`admin/userOperations/${this.id}/userPreferences`,
					'PUT',
					newPreferences,
					onFinish,
					onFinish
				);
			});
			return promise;
		}
	};

	@action
	public updateRole = (newRole: Api.UserRole) => {
		if (!this.isBusy) {
			this.busy = true;
			const promise = new Promise<Api.IUserStats>((resolve, reject) => {
				const onFinish = (opResult: Api.IOperationResult<Api.IUserStats>) => {
					runInAction(() => {
						if (opResult.success) {
							this.mUser = {
								...this.userWithStats,
								...opResult.value,
							};
							this.busy = false;
							resolve(opResult.value);
						} else {
							this.busy = false;
							reject(opResult);
						}
					});
				};
				this.userSession.webServiceHelper.callWebServiceWithOperationResults<Api.IUserStats>(
					`admin/userOperations/${this.id}/setRole?role=${newRole}`,
					'POST',
					null,
					onFinish,
					onFinish
				);
			});
			return promise;
		}
	};

	@action
	public getErrors = () => {
		const promise = new Promise<AdminModels.IPlatformError[]>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<AdminModels.IPlatformError[]>) => {
				runInAction(() => {
					if (opResult.success) {
						this.busy = false;
						resolve(opResult.value);
					} else {
						this.busy = false;
						reject(opResult);
					}
				});
			};
			this.userSession.webServiceHelper.callWebServiceWithOperationResults<AdminModels.IPlatformError[]>(
				`admin/userOperations/${this.id}/recentErrors?days=30`,
				'GET',
				null,
				onFinish,
				onFinish
			);
		});
		return promise;
	};

	@action
	public sendPasswordReset = () => {
		const promise = new Promise<Api.IOperationResultNoValue>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResultNoValue) => {
				runInAction(() => {
					if (opResult.success) {
						this.busy = false;
						resolve(opResult);
					} else {
						this.busy = false;
						reject(opResult);
					}
				});
			};
			this.userSession.webServiceHelper.callWebServiceWithOperationResults(
				`admin/userOperations/${this.id}/sendResetPasswordEmail`,
				'POST',
				null,
				onFinish,
				onFinish
			);
		});
		return promise;
	};

	@action
	public deleteAdmin = () => {
		const promise = new Promise<Api.IOperationResult<void>>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<void>) => {
				runInAction(() => {
					if (opResult.success) {
						this.busy = false;
						resolve(opResult);
					} else {
						this.busy = false;
						reject(opResult);
					}
				});
			};
			this.userSession.webServiceHelper.callWebServiceWithOperationResults<void>(
				`admin/UserOperations/${this.id}`,
				'DELETE',
				null,
				onFinish,
				onFinish
			);
		});
		return promise;
	};

	@action
	public changeEmail = (email: string, reason: string) => {
		const promise = new Promise<Api.IOperationResultNoValue>((resolve, reject) => {
			const data = { newEmail: email, reason };
			const onFinish = (opResult: Api.IOperationResultNoValue) => {
				runInAction(() => {
					if (opResult.success) {
						this.busy = false;
						resolve(opResult);
					} else {
						this.busy = false;
						reject(opResult);
					}
				});
			};
			this.userSession.webServiceHelper.callWebServiceWithOperationResults<Api.IOperationResultNoValue>(
				this.composeApiUrl({ urlPath: 'user/changeEmail' }),
				'POST',
				data,
				onFinish,
				onFinish
			);
		});
		return promise;
	};

	@action
	public getWelcomeEmailLink = async () => {
		try {
			const result = await this.userSession.webServiceHelper.callWebServiceAsync<Api.IWelcomeEmail>(
				`admin/userOperations/welcomeUrl?userId=${this.id}`,
				'POST'
			);
			if (result.success) {
				return result.value;
			} else {
				throw Api.asApiError(result);
			}
		} catch (err) {
			throw Api.asApiError(err);
		}
	};

	@action
	public getUserPhone = async () => {
		const result = await this.userSession.webServiceHelper.callWebServiceAsync<Api.ITelephonyConfiguration[]>(
			this.composeApiUrl({ urlPath: 'PhoneNumber/user' }),
			'GET'
		);
		if (result.success) {
			this.mPhoneNumberOrders = result.value;
			return result.value;
		} else {
			throw Api.asApiError(result);
		}
	};

	@action
	public getLoginAsCredentials = () => {
		const promise = new Promise<Api.IOperationResult<ICredential>>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<ICredential>) => {
				if (opResult.success) {
					this.busy = false;
					resolve(opResult);
				} else {
					this.busy = false;
					reject(opResult);
				}
			};
			this.userSession.webServiceHelper.callWebServiceWithOperationResults(
				`admin/account/${this.accountId}/CreateImpersonationToken?userId=${this.id}`,
				'POST',
				null,
				onFinish,
				onFinish
			);
		});
		return promise;
	};

	@action
	public getProfileInternalContactId = async (userId: string) => {
		const promise = new Promise<Api.IOperationResult<string>>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<string>) => {
				if (opResult.success) {
					this.busy = false;
					resolve(opResult);
				} else {
					this.busy = false;
					reject(opResult);
				}
			};
			this.userSession.webServiceHelper.callWebServiceWithOperationResults(
				`admin/userOperations/${userId}/internalContactId`,
				'GET',
				null,
				onFinish,
				onFinish
			);
		});
		return promise;
	};
}

export class AccountUsersViewModel extends Api.ViewModel {
	@observable.ref private mAccount: Api.IAccount;
	@observable.ref
	private usersPageCollectionController: Api.BaseObservablePageCollectionController<
		Api.IUserWithStats,
		AccountUserViewModel
	>;
	@observable.ref protected mAdmins: AccountUserViewModel[];

	constructor(userSession: Api.UserSessionContext, account: Api.IAccount) {
		super(userSession);
		this.mAccount = account;
		this.mAdmins = [];
		this.usersPageCollectionController = new Api.BaseObservablePageCollectionController<
			Api.IUserWithStats,
			AccountUserViewModel
		>({
			apiPath: `admin/userOperations/byAccount`,
			client: userSession.webServiceHelper,
			transformer: this.mCreateAccountUserViewModel,
		});
	}

	private mCreateAccountUserViewModel = (user: Api.IUserWithStats) => {
		return new AccountUserViewModel(this.mUserSession, user);
	};

	@computed
	public get admins() {
		return this.mAdmins;
	}

	@action
	public loadAdmins = async () => {
		try {
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IUser[]>(
				`impersonate/${this.mAccount?.id}/user/admins`,
				'GET'
			);
			if (!success) {
				throw opResult;
			}
			this.mAdmins = opResult.value.map(x => new AccountUserViewModel(this.mUserSession, x));
			return this.mAdmins;
		} catch (error) {
			throw Api.asApiError(error);
		}
	};

	@action
	public loadUsers = () => {
		return this.usersPageCollectionController.getNext(
			{
				accountId: this.mAccount.id,
				expand: 'Stats',
			},
			100
		);
	};

	@action
	public reset = () => {
		this.usersPageCollectionController.reset();
	};

	@computed
	public get accountUsers(): Api.ObservableCollection<AccountUserViewModel> {
		return this.usersPageCollectionController.fetchResults;
	}

	@computed
	public get totalNumberOfResults() {
		return this.usersPageCollectionController.totalCount;
	}

	@computed
	public get isLoading() {
		return this.usersPageCollectionController.isFetching;
	}

	@action
	public resendWelcome = (userId: string) => {
		const promise = new Promise<Api.IUserWithStats>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<Api.IUserWithStats>) => {
				if (opResult.success) {
					resolve(opResult.value);
				} else {
					reject(opResult);
				}
			};
			this.userSession.webServiceHelper.callWebServiceWithOperationResults(
				`admin/userOperations/resendWelcomeEmail?userId=${userId}`, // URL
				'POST',
				null,
				onFinish,
				onFinish
			);
		});
		return promise;
	};
}

export enum AccountFilterCriteriaProperty {
	All,
	Name,
	SuccessManager,
	SalesRep,
	BillingType,
	AccountType,
	ActiveUseStatus,
	ActivationStatus,
	Industry,
	Email,
	RenewalMonth,
	CancellationDate,
	BillingCycle,
	NoSale,
	Flagged,
	SubscriptionLength,
	CreationMonth,
	NoStart,
	Websites,
}

export type IAccountFilterCriteria = Api.IFilterCriteria<AccountFilterCriteriaProperty>;

export type IAccountsFilterRequest = IAccountFilterCriteria;

export interface IAccountsExportsApiRequest {
	filter?: IAccountsFilterRequest;
	useSnapshots?: boolean;
}

export class AccountsAdminViewModel extends Api.ViewModel {
	@observable.ref private mLastSuccessfulRequest: IAccountsFilterRequest;
	@observable.ref private nextRequest: IAccountsFilterRequest;
	@observable.ref private aggregateActiveUse: AdminModels.IAggregateActiveUse;
	private accountsPageCollectionController: Api.FilteredPageCollectionController<
		Api.IAccount,
		AccountAdminViewModel,
		IAccountsFilterRequest
	>;
	private deletedAccountsPageCollectionController: Api.FilteredPageCollectionController<
		Api.IAccount,
		AccountAdminViewModel,
		IAccountsFilterRequest
	>;
	public static SearchCriteriaProperties: AccountFilterCriteriaProperty[] = [
		AccountFilterCriteriaProperty.All,
		AccountFilterCriteriaProperty.Name,
		AccountFilterCriteriaProperty.SuccessManager,
		AccountFilterCriteriaProperty.SalesRep,
		AccountFilterCriteriaProperty.BillingType,
		AccountFilterCriteriaProperty.AccountType,
		AccountFilterCriteriaProperty.ActiveUseStatus,
		AccountFilterCriteriaProperty.ActivationStatus,
		AccountFilterCriteriaProperty.Industry,
		AccountFilterCriteriaProperty.Email,
		AccountFilterCriteriaProperty.RenewalMonth,
		AccountFilterCriteriaProperty.SubscriptionLength,
		AccountFilterCriteriaProperty.CreationMonth,
	];

	public static getAllByIds = (userSession: Api.UserSessionContext, accountIds: string[]) => {
		const promise = new Promise<AccountAdminViewModel[]>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<Api.IAccount[]>) => {
				if (opResult.success) {
					const accounts = opResult.value.map(x => new AccountAdminViewModel(userSession, x));
					resolve(accounts);
				} else {
					reject(opResult);
				}
			};
			userSession.webServiceHelper.callWebServiceWithOperationResults<Api.IAccount[]>(
				'account/byIds',
				'POST',
				accountIds,
				onFinish,
				onFinish
			);
		});
		return promise;
	};

	constructor(userSession: Api.UserSessionContext) {
		super(userSession);
		this.accountsPageCollectionController = new Api.FilteredPageCollectionController<
			Api.IAccount,
			AccountAdminViewModel,
			IAccountsFilterRequest
		>({
			apiPath: 'admin/account/filter',
			client: userSession.webServiceHelper,
			transformer: this.mCreateAccountViewModel,
		});
		this.deletedAccountsPageCollectionController = new Api.FilteredPageCollectionController<
			Api.IAccount,
			AccountAdminViewModel,
			IAccountsFilterRequest
		>({
			apiPath: 'admin/account/filter',
			client: userSession.webServiceHelper,
			transformer: this.mCreateAccountViewModel,
		});
	}

	@action
	public getFilteredAccounts(criteriaRequest?: IAccountsFilterRequest) {
		return this.accountsPageCollectionController.getNext(criteriaRequest, 25, {
			sort: 'asc',
			sortBy: 'planDetails.renewalDate',
		});
	}

	@action
	public getDeletedFilteredAccounts(criteriaRequest?: IAccountsFilterRequest) {
		return this.deletedAccountsPageCollectionController.getNext(criteriaRequest, 50, {
			deleted: true,
			sort: 'asc',
			sortBy: 'planDetails.renewalDate',
		});
	}

	private exportFilteredAccounts(criteriaRequest: IAccountsExportsApiRequest) {
		const promise = new Promise<Api.ISystemJob>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<Api.ISystemJob>) => {
				if (opResult.success) {
					resolve(opResult.value);
				} else {
					reject(opResult);
				}
			};
			this.userSession.webServiceHelper.callWebServiceWithOperationResults<Api.ISystemJob>(
				'admin/account/export',
				'POST',
				criteriaRequest,
				onFinish,
				onFinish
			);
		});
		return promise;
	}

	@action
	public exportFullReport() {
		const filter: IAccountFilterCriteria = {
			criteria: [],
			op: Api.FilterOperator.Or,
		};
		const request: IAccountsExportsApiRequest = {
			filter,
			useSnapshots: false,
		};
		const promise = new Promise<Api.ISystemJob>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<Api.ISystemJob>) => {
				if (opResult.success) {
					resolve(opResult.value);
				} else {
					reject(opResult);
				}
			};
			this.userSession.webServiceHelper.callWebServiceWithOperationResults<Api.ISystemJob>(
				'admin/account/export',
				'POST',
				request,
				onFinish,
				onFinish
			);
		});
		return promise;
	}

	@computed
	public get activeUse() {
		return this.aggregateActiveUse;
	}

	@computed
	public get filterRequest() {
		return this.mLastSuccessfulRequest;
	}

	@computed
	public get isFetchingResults() {
		return this.accountsPageCollectionController.isFetching || this.deletedAccountsPageCollectionController.isFetching;
	}

	@computed
	public get fetchedFirstPage() {
		return (
			this.accountsPageCollectionController.hasFetchedFirstPage ||
			this.deletedAccountsPageCollectionController.hasFetchedFirstPage
		);
	}

	@computed
	public get isSearching() {
		const accountsFilterRequest = this.nextRequest || this.mLastSuccessfulRequest;
		if (accountsFilterRequest) {
			return (
				accountsFilterRequest.criteria &&
				accountsFilterRequest.criteria.find(
					x => x.property && x.value && AccountsAdminViewModel.SearchCriteriaProperties.indexOf(x.property) >= 0
				)
			);
		}
		return false;
	}

	@computed
	public get isBusy() {
		return (
			this.accountsPageCollectionController.isFetching ||
			this.deletedAccountsPageCollectionController.isFetching ||
			this.busy ||
			this.loading
		);
	}

	@computed
	public get totalNumberOfResults() {
		return this.accountsPageCollectionController.totalCount;
	}

	@computed
	public get fetchResults() {
		return this.accountsPageCollectionController.fetchResults;
	}

	@computed
	public get deletedFetchResults() {
		return this.deletedAccountsPageCollectionController.fetchResults;
	}

	@action
	public reset = () => {
		this.busy = false;
		this.accountsPageCollectionController.reset();
		this.deletedAccountsPageCollectionController.reset();
		this.loading = false;
		this.mLastSuccessfulRequest = null;
		this.nextRequest = null;
	};

	@action
	public getAccounts = async (
		filter?: IAccountsFilterRequest,
		sortDescriptor?: Api.ISortDecriptor,
		pageSize?: number,
		params?: any
	) => {
		if (!this.isBusy) {
			this.busy = true;
			const promise = new Promise<
				[
					Api.IPageCollectionControllerFetchResult<Api.IAccount[]>,
					Api.IOperationResult<AdminModels.IAggregateActiveUse>,
				]
			>((resolve, reject) => {
				// merge params with sortDescriptor
				const computedParams = {
					...(sortDescriptor || {}),
					...(params || {}),
				};

				const accountsPromise = this.accountsPageCollectionController.getNext(filter, pageSize, computedParams);

				const activeUsePromise = this.userSession.webServiceHelper.callWebServiceAsync<AdminModels.IAggregateActiveUse>(
					'admin/account/stats',
					'POST',
					filter
				);

				Promise.all([accountsPromise, activeUsePromise])
					.then(opResults => {
						runInAction(() => {
							if (opResults[0]) {
								this.mLastSuccessfulRequest = filter;
								this.nextRequest = null;
							}
							if (opResults[1]) {
								this.aggregateActiveUse = opResults[1].value || null;
							}
							this.busy = false;
							resolve([opResults[0], opResults[1]]);
						});
					})
					.catch(e => {
						this.busy = false;
						reject(Api.asApiError(e));
					});
			});
			return promise;
		}
	};

	public exportAccounts(criteria?: IAccountFilterCriteria[], useSnapshots = false) {
		const criteriaObject: IAccountsExportsApiRequest = {
			filter: { criteria: [...(criteria || [])], op: Api.FilterOperator.And },
			useSnapshots,
		};
		return this.exportFilteredAccounts(criteriaObject);
	}

	private mCreateAccountViewModel = (account: Api.IAccount) => {
		return new AccountAdminViewModel(this.mUserSession, account);
	};
}

export class AccountAdminViewModel extends Api.AccountViewModel {
	@observable.ref private mUpdatePdfSentPromise: Promise<Api.IOperationResult<Api.IAccount>>;
	@observable.ref private mUpdateRenewalPromise: Promise<Api.IOperationResult<Api.IAccount>>;
	@observable.ref private mUpdateRenewalDiscountPercentagePromise: Promise<Api.IOperationResult<Api.IAccount>>;
	@observable.ref private mUpdateSendEmailFeaturesPromise: Promise<Api.IOperationResult<Api.ISendEmailFeatures>>;
	@observable.ref private mUpdateStagePromise: Promise<Api.IOperationResult<Api.IAccount>>;
	@observable.ref private mUpdateNextMeetingPromise: Promise<Api.IOperationResult<Api.IAccount>>;

	public static Create = (userSession: Api.UserSessionContext, accountModel: Api.IAccount) => {
		const promise = new Promise<AccountAdminViewModel>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<Api.IAccount>) => {
				if (opResult.success) {
					const account = new AccountAdminViewModel(userSession, opResult.value);
					resolve(account);
				} else {
					reject(opResult);
				}
			};
			userSession.webServiceHelper.callWebServiceWithOperationResults<Api.IAccount>(
				'account',
				'POST',
				accountModel,
				onFinish,
				onFinish
			);
		});
		return promise;
	};

	constructor(userSession: Api.UserSessionContext, account: Api.IAccount) {
		super(userSession, account);
		this.mAccount = account;
	}

	@computed
	protected get account() {
		return this.mAccount;
	}

	@action
	public updateRenewalProbability = (renewalProbability: number) => {
		if (!this.mUpdateRenewalPromise) {
			this.mUpdateRenewalPromise = new Promise<Api.IOperationResult<Api.IAccount>>((resolve, reject) => {
				const onFinish = (opResult: Api.IOperationResult<Api.IAccount>) => {
					runInAction(() => {
						if (opResult.success) {
							this.mAccount = opResult.value;
							this.mUpdateRenewalPromise = null;
							resolve(opResult);
						} else {
							this.mUpdateRenewalPromise = null;
							reject(opResult);
						}
					});
				};
				this.userSession.webServiceHelper.callWebServiceWithOperationResults<Api.IAccount>(
					`admin/account/${this.id}/additionalInfo/renewalProbability?renewalProbability=${renewalProbability}`,
					'PATCH',
					null,
					onFinish,
					onFinish
				);
			});
			return this.mUpdateRenewalPromise;
		}
	};

	@action
	public updateRenewalDiscountPercentage = (renewalDiscountPercentage: number) => {
		if (!this.mUpdateRenewalDiscountPercentagePromise) {
			this.mUpdateRenewalDiscountPercentagePromise = new Promise<Api.IOperationResult<Api.IAccount>>(
				(resolve, reject) => {
					const onFinish = (opResult: Api.IOperationResult<Api.IAccount>) => {
						runInAction(() => {
							if (opResult.success) {
								this.mAccount = opResult.value;
								this.mUpdateRenewalDiscountPercentagePromise = null;
								resolve(opResult);
							} else {
								this.mUpdateRenewalDiscountPercentagePromise = null;
								reject(opResult);
							}
						});
					};
					this.userSession.webServiceHelper.callWebServiceWithOperationResults<Api.IAccount>(
						`admin/account/${this.id}/additionalInfo/renewalDiscountPercentage?value=${renewalDiscountPercentage}`,
						'PATCH',
						null,
						onFinish,
						onFinish
					);
				}
			);
			return this.mUpdateRenewalDiscountPercentagePromise;
		}
	};

	@action
	public updateStage = (stage: string) => {
		if (!this.mUpdateStagePromise) {
			this.mUpdateStagePromise = new Promise<Api.IOperationResult<Api.IAccount>>((resolve, reject) => {
				const onFinish = (opResult: Api.IOperationResult<Api.IAccount>) => {
					runInAction(() => {
						if (opResult.success) {
							this.mAccount = opResult.value;
							this.mUpdateStagePromise = null;
							resolve(opResult);
						} else {
							this.mUpdateStagePromise = null;
							reject(opResult);
						}
					});
				};
				this.userSession.webServiceHelper.callWebServiceWithOperationResults<Api.IAccount>(
					`admin/account/${this.id}/additionalInfo/stage?stage=${encodeURIComponent(stage)}`,
					'PUT',
					null,
					onFinish,
					onFinish
				);
			});
			return this.mUpdateStagePromise;
		}
	};

	@action
	public updateSendEmailFeatures = (sendEmailFeatures: Partial<Api.ISendEmailFeatures>) => {
		if (!this.mUpdateSendEmailFeaturesPromise) {
			this.mUpdateSendEmailFeaturesPromise = new Promise<Api.IOperationResult<Api.ISendEmailFeatures>>(
				(resolve, reject) => {
					const onFinish = (opResult: Api.IOperationResult<Api.ISendEmailFeatures>) => {
						runInAction(() => {
							if (opResult.success) {
								this.account.features.sendEmail = opResult.value;
								this.mUpdateSendEmailFeaturesPromise = null;
								resolve(opResult);
							} else {
								this.mUpdateSendEmailFeaturesPromise = null;
								reject(opResult);
							}
						});
					};
					this.userSession.webServiceHelper.callWebServiceWithOperationResults<Api.ISendEmailFeatures>(
						`impersonate/${this.id}/Account/features/sendEmail`,
						'PUT',
						sendEmailFeatures,
						onFinish,
						onFinish
					);
				}
			);
			return this.mUpdateSendEmailFeaturesPromise;
		}
	};

	@action
	public updateRenewalPdfSent = (sendDate: Date) => {
		if (!this.mUpdatePdfSentPromise) {
			this.mUpdatePdfSentPromise = new Promise<Api.IOperationResult<Api.IAccount>>((resolve, reject) => {
				const onFinish = (opResult: Api.IOperationResult<Api.IAccount>) => {
					runInAction(() => {
						if (opResult.success) {
							this.mAccount = opResult.value;
							this.mUpdatePdfSentPromise = null;
							resolve(opResult);
						} else {
							this.mUpdatePdfSentPromise = null;
							reject(opResult);
						}
					});
				};
				this.userSession.webServiceHelper.callWebServiceWithOperationResults<Api.IAccount>(
					`admin/account/${this.id}/additionalInfo/renewalPdfSent${
						sendDate ? `?renewalPdfSent=${encodeURIComponent(moment(sendDate).format('MM/DD/YYYY'))}` : ''
					}`,
					'PUT',
					null,
					onFinish,
					onFinish
				);
			});
			return this.mUpdatePdfSentPromise;
		}
	};

	public updateNextMeeting = (nextMeeting: Date) => {
		if (!this.mUpdateNextMeetingPromise) {
			this.mUpdateNextMeetingPromise = new Promise<Api.IOperationResult<Api.IAccount>>((resolve, reject) => {
				const onFinish = (opResult: Api.IOperationResult<Api.IAccount>) => {
					runInAction(() => {
						if (opResult.success) {
							this.mAccount = opResult.value;
							this.mUpdateNextMeetingPromise = null;
							resolve(opResult);
						} else {
							this.mUpdateNextMeetingPromise = null;
							reject(opResult);
						}
					});
				};
				this.userSession.webServiceHelper.callWebServiceWithOperationResults<Api.IAccount>(
					`admin/account/${this.account.id}/additionalInfo/nextMeeting?nextMeeting=${
						encodeURIComponent(moment(nextMeeting).format('MM/DD/YYYY')) || ''
					}`,
					'PUT',
					null,
					onFinish,
					onFinish
				);
			});
			return this.mUpdateNextMeetingPromise;
		}
	};

	@computed
	public get isUpdatingSendEmailFeatures() {
		return !!this.mUpdateSendEmailFeaturesPromise;
	}

	@computed
	public get isUpdatingStage() {
		return !!this.mUpdateStagePromise;
	}

	@computed
	public get isUpdatingRenewalProbability() {
		return !!this.mUpdateRenewalPromise;
	}

	@computed
	public get isUpdatingRenewalDiscountPercentage() {
		return !!this.mUpdateRenewalDiscountPercentagePromise;
	}

	@computed
	public get isUpdatingRenewalPdfSent() {
		return !!this.mUpdatePdfSentPromise;
	}

	@computed
	public get isUpdatingNextMeeting() {
		return !!this.mUpdateNextMeetingPromise;
	}
}

export enum AccountTagUsage {
	Policy = 'Policy',
}

export class DataRefreshViewModel extends AppImportContactsViewModel {
	@observable.ref private mAccount: AccountOperationsViewModel;
	@observable.ref public accountUsers: AccountUsersViewModel;
	@observable.ref public selectedUser: AccountUserViewModel;
	@observable public clearRenewalKeyFacts = true;
	@observable public contactVisibility: boolean;
	@observable.ref
	public tagsToDelete: Api.ObservableCollection<Api.TagViewModel>;
	@observable.ref public policyTags: Api.ObservableCollection<Api.TagViewModel>;
	@observable.ref public users: Api.ObservableCollection<Api.UserViewModel>;
	@observable.ref public userToUploadTo: Api.UserViewModel;
	@observable public shareImports = false;

	constructor(userSession: Api.UserSessionContext, account: AccountOperationsViewModel) {
		super(userSession);
		this.mAccount = account;
		this.accountUsers = new AccountUsersViewModel(userSession, account.account);
		this.tagsToDelete = new Api.ObservableCollection<Api.TagViewModel>([], 'id');
	}

	protected getUrl(jobId?: string) {
		let url = `admin/contactOperations/${this.mAccount.account.id}/${this.selectedUser.id}/importFromFile`;
		if (!jobId) {
			url = url + `?deleteRenewalKeyFacts=${encodeURIComponent(this.clearRenewalKeyFacts)}`;
		}
		return !jobId ? url : `${url}/${jobId}`;
	}

	@computed
	public get isReadyToImport() {
		return !!this.selectedUser;
	}

	@computed
	public get account() {
		return this.mAccount;
	}
	@action
	public importFromFile(file: File): Promise<Api.IOperationResult<Api.IImportContactPreview>> {
		if (this.isBusy) {
			return Promise.resolve(undefined);
		}

		this.busy = true;

		return new Promise<Api.IOperationResult<Api.IImportContactPreview>>((resolve, reject) => {
			const data = new FormData();
			data.append('file', file);

			const tags = Array.from(new Set(this.tagsToDelete.map(x => x.name)));
			data.append('refreshRequest', JSON.stringify({ accountTagNamesToDelete: tags }));

			const onFinish = action((opResult: Api.IOperationResult<Api.IImportContactPreview>) => {
				this.busy = false;
				if (opResult.success) {
					this.mFile = file;
					if (this.mSetPreview(opResult.value)) {
						resolve(opResult);
					} else {
						reject({
							success: false,
							systemCode: 500,
							systemMessage:
								'Please make sure the spreadsheet you uploaded has a header row. If it does, please make sure at least one header is something we can recognize, like "Name" or "Email".',
						});
					}
				} else {
					reject(opResult);
				}
			});

			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IImportContactPreview>(
				this.getUrl(),
				'POST',
				data,
				onFinish,
				onFinish
			);
		});
	}

	@action
	public loadPolicyTags = async () => {
		if (!this.isBusy) {
			this.busy = true;
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IAccountTag[]>(
				`impersonate/${encodeURIComponent(this.mAccount.account.id)}/tag/byUsage?usage=${encodeURIComponent(
					AccountTagUsage.Policy
				)}`,
				'GET'
			);

			this.busy = false;
			if (opResult.success) {
				this.policyTags = new Api.ObservableCollection(
					opResult.value.map(x => new Api.TagViewModel(this.mUserSession, x)),
					'id'
				);
			} else {
				throw Api.asApiError(opResult);
			}
		}
	};
}

export class AccountImportsViewModel extends Api.ViewModel {
	@observable.ref private mAccount: Api.IAccount;
	@observable.ref protected mLoadImportsPromise: Promise<Api.IOperationResult<Api.IImportJob[]>>;
	@observable.ref protected mRevertImportsPromise: Promise<Api.IOperationResult<AdminModels.IRevertImportResult>>;
	@observable.ref private imports: Api.IImportJob[];
	private url: string;
	private mRevertFailedExpectedCount: number;

	constructor(userSession: Api.UserSessionContext, account: Api.IAccount) {
		super(userSession);
		this.mAccount = account;
		this.mLoadImportsPromise = null;
		this.url = 'admin/importOperations';
		this.imports = [];
	}

	@computed
	public get account() {
		return this.mAccount;
	}

	@action
	public loadImports = (showReverted = false) => {
		if (!this.mLoadImportsPromise) {
			this.mLoadImportsPromise = new Promise<Api.IOperationResult<Api.IImportJob[]>>((resolve, reject) => {
				const onFinish = (opResult: Api.IOperationResult<Api.IImportJob[]>) => {
					if (opResult.success) {
						this.imports = opResult.value;
						this.mLoadImportsPromise = null;
						resolve(opResult);
					} else {
						this.mLoadImportsPromise = null;
						reject(opResult);
					}
				};
				this.userSession.webServiceHelper.callWebServiceWithOperationResults<Api.IImportJob[]>(
					`${this.url}/byAccount?accountId=${encodeURIComponent(this.mAccount.id)}&showReverted=${encodeURIComponent(
						showReverted
					)}`,
					'GET',
					null,
					onFinish,
					onFinish
				);
			});
			return this.mLoadImportsPromise;
		}
	};

	@action
	public loadImportsByUser = (userId: string, showReverted = false) => {
		if (!this.mLoadImportsPromise) {
			this.mLoadImportsPromise = new Promise<Api.IOperationResult<Api.IImportJob[]>>((resolve, reject) => {
				const onFinish = (opResult: Api.IOperationResult<Api.IImportJob[]>) => {
					if (opResult.success) {
						this.imports = opResult.value;
						this.mLoadImportsPromise = null;
						resolve(opResult);
					} else {
						this.mLoadImportsPromise = null;
						reject(opResult);
					}
				};
				this.userSession.webServiceHelper.callWebServiceWithOperationResults<Api.IImportJob[]>(
					`${this.url}/byUser?accountId=${this.mAccount.id}&userId=${userId}&showReverted=${encodeURIComponent(
						showReverted
					)}`,
					'GET',
					null,
					onFinish,
					onFinish
				);
			});
			return this.mLoadImportsPromise;
		}
	};

	@action
	public revertImport = (systemJobId: string) => {
		this.mRevertFailedExpectedCount = null;
		if (!this.mRevertImportsPromise) {
			this.mRevertImportsPromise = new Promise<Api.IOperationResult<AdminModels.IRevertImportResult>>(
				(resolve, reject) => {
					const onFinish = (opResult: Api.IOperationResult<AdminModels.IRevertImportResult>) => {
						if (opResult.success) {
							this.filterRemovedExport(systemJobId);
							this.mRevertImportsPromise = null;
							resolve(opResult);
						} else {
							try {
								if (opResult.systemMessage.includes('Total documents exceed expected count')) {
									// Get the number to the left of the >
									const regex = new RegExp(/: \d* > \d*/gm);
									const matches = regex.exec(opResult.systemMessage);
									const count = parseInt(matches[0].split(' ')[1], 10);
									this.mRevertFailedExpectedCount = count;
								}
								this.mRevertImportsPromise = null;
								reject(opResult);
							} catch (e) {
								// console.error('Unable to parse the system message');
							}
						}
					};
					this.userSession.webServiceHelper.callWebServiceWithOperationResults<AdminModels.IRevertImportResult>(
						`${this.url}/${systemJobId}/revert?accountId=${this.mAccount.id}`,
						'POST',
						null,
						onFinish,
						onFinish
					);
				}
			);
			return this.mRevertImportsPromise;
		}
	};

	@action
	public forceRevertImport = (systemJobId: string) => {
		if (!this.mRevertImportsPromise) {
			this.mRevertImportsPromise = new Promise<Api.IOperationResult<AdminModels.IRevertImportResult>>(
				(resolve, reject) => {
					const onFinish = (opResult: Api.IOperationResult<AdminModels.IRevertImportResult>) => {
						if (opResult.success) {
							this.filterRemovedExport(systemJobId);
							this.mRevertImportsPromise = null;
							resolve(opResult);
						} else {
							this.mRevertImportsPromise = null;
							reject(opResult);
						}
					};
					this.userSession.webServiceHelper.callWebServiceWithOperationResults<AdminModels.IRevertImportResult>(
						`${this.url}/${systemJobId}/forceRevert?accountId=${this.mAccount.id}&expectedCount=${this.mRevertFailedExpectedCount}`,
						'POST',
						null,
						onFinish,
						onFinish
					);
				}
			);
			return this.mRevertImportsPromise;
		}
	};

	@action
	public downloadImportFile = (systemJobId: string) => {
		return this.userSession.webServiceHelper.callAsync<string>(
			`${this.url}/${systemJobId}/downloadUrl?accountId=${this.mAccount.id}`,
			'GET'
		);
	};

	@action
	public filterRemovedExport = (systemJobId: string) => {
		this.imports = this.imports.filter(importJob => importJob.id !== systemJobId);
	};

	@computed
	public get accountImports() {
		return this.imports;
	}

	@computed
	public get totalNumberOfResults() {
		return this.imports.length;
	}

	@computed
	public get isLoading() {
		return !!this.mLoadImportsPromise;
	}

	@computed
	public get isReverting() {
		return !!this.mRevertImportsPromise;
	}
}

/** For creating a review and approve campaign */
export class CreateCampaignApprovalViewModel extends Api.ViewModel {
	@observable.ref protected mContactsFilterRequest: Api.IContactsFilterRequest;
	@observable.ref
	protected mContactsPageCollectionController: Api.FilteredPageCollectionController<
		Api.IContact,
		Api.ContactViewModel,
		Api.IContactsFilterRequest
	>;
	@observable.ref protected mLoadingSuggestedTagsPromise: Promise<Api.IOperationResult<string[]>>;
	@observable.ref protected mNote: string;
	@observable.ref protected mSendDate: Date;
	@observable.ref protected mSuggestedTags: string[];
	@observable.ref
	protected mUsers: Api.ObservableCollection<AccountUserViewModel>;
	@observable.ref public account: Api.IAccount;
	@observable.ref public emailContent: IEditEmailTemplateContent;
	@observable
	protected mSelectedOptionOverride: Api.ContactFilterCriteriaProperty;
	@observable public files: Api.AttachmentsToBeUploadedViewModel<File> =
		new Api.AttachmentsToBeUploadedViewModel<File>();

	protected mSelectedUsers: Api.ObservableCollection<AccountUserViewModel>;
	public readonly bulkEmailComposer: ComposeEmailViewModel;

	constructor(userSession: AdminUserSessionContext, account?: Api.IAccount) {
		super(userSession);
		this.account = account;
		this.bulkEmailComposer = new ComposeEmailViewModel(userSession);
		this.mContactsPageCollectionController = new Api.FilteredPageCollectionController({
			apiPath: 'contact/filter',
			client: userSession.webServiceHelper,
			transformer: this.mCreateContactViewModel,
		});
		this.mSelectedUsers = new Api.ObservableCollection<AccountUserViewModel>([], 'id');
		this.mUsers = new Api.ObservableCollection<AccountUserViewModel>([], 'id');
		this.mSuggestedTags = [];
		this.mContactsFilterRequest = {
			criteria: [{ property: Api.ContactFilterCriteriaProperty.All }, ...Api.DefaultBulkSendExcludedFilterCriteria],
		};
		this.bulkEmailComposer.showForBulkContactsFilterRequest({
			bulkFilterRequest: { contactFilterRequest: this.mContactsFilterRequest },
		});
		this.mNote = `Hi ${FirstNamePlaceholder.symbol},\n\r\n\rPlease click on the "Review & Approve" button below to get started. You may edit the content once you are there.`;
		this.mSelectedOptionOverride = null;
		if (this.account?.id) {
			this.impersonate({ account: this.account });
		}
	}

	@computed
	public get hasAnyContacts() {
		return this.bulkEmailComposer?.emailMessage?.contactEmailApproximation?.total > 0;
	}

	@computed
	public get defaultSelectedFilterOption() {
		return this.mSelectedOptionOverride !== null
			? this.mSelectedOptionOverride
			: this.selectedUsers.length === 1
				? Api.ContactFilterCriteriaProperty.OwnedBy
				: Api.ContactFilterCriteriaProperty.All;
	}

	@action
	public setSelectedFilterOptionOverride(option: Api.ContactFilterCriteriaProperty) {
		this.mSelectedOptionOverride = option;
	}

	@computed
	public get baseApiPath() {
		return this.composeApiUrl({ urlPath: 'contact/filter' });
	}

	@computed
	public get userSelectionMode() {
		const type: AdminModels.BulkEmailApprovalUsersSelectMode = this.mSelectedUsers.length <= 1 ? 'one' : 'many';
		return type;
	}

	@computed
	public get allUsersSelected() {
		return this.mUsers.length === this.mSelectedUsers.length;
	}

	@computed
	public get contactsFilterRequest() {
		return this.mContactsFilterRequest;
	}

	@computed
	public get totalNumberOfContacts() {
		return this.mContactsPageCollectionController.totalCount;
	}

	@computed
	public get contacts() {
		return this.mContactsPageCollectionController;
	}

	@computed
	public get users() {
		return this.mUsers;
	}

	@computed
	public get selectedUsers() {
		return this.mSelectedUsers;
	}

	@computed
	public get isLoadingSuggestedTags() {
		return !!this.mLoadingSuggestedTagsPromise;
	}

	@computed
	public get isBusy() {
		return (
			this.busy || this.loading || this.isLoadingSuggestedTags || this.mContactsPageCollectionController?.isFetching
		);
	}

	@action
	public refreshContacts() {
		return new Promise<Api.IOperationResult<string[]>>((resolve, reject) => {
			const sorted = Api.VmUtils.sortContactFilterCriteria(this.contactsFilterRequest?.criteria);
			const filterRequest: Api.IContactsFilterRequest = {
				criteria: [
					...sorted.filters.filter(x => x.property !== Api.ContactFilterCriteriaProperty.OwnedBy),
					...sorted.searches,
				],
			};

			this.getContacts(filterRequest)
				?.then(() => {
					resolve(undefined);
				})
				?.catch((error: Api.IOperationResultNoValue) => {
					reject(error);
				});
		});
	}

	@action
	public reset = () => {
		this.bulkEmailComposer.reset();
		this.mContactsPageCollectionController.reset();
		this.mSelectedUsers.clear();
		this.mSuggestedTags = [];
		this.mUsers.clear();
		this.mContactsFilterRequest = {
			criteria: [{ property: Api.ContactFilterCriteriaProperty.All }, ...Api.DefaultBulkSendExcludedFilterCriteria],
		};
	};

	@action
	public setInitialTag = (tag: string) => {
		this.mContactsFilterRequest = {
			criteria: [
				{
					property: Api.ContactFilterCriteriaProperty.All,
				},
				{
					property: Api.ContactFilterCriteriaProperty.Tag,
					value: tag,
				},
				...Api.DefaultBulkSendExcludedFilterCriteria,
			],
		};
		this.mContactsPageCollectionController.reset();
		this.mSelectedUsers.clear();
		this.mUsers.clear();
	};

	@action
	public loadSuggestedTags() {
		if (!this.isBusy) {
			this.mLoadingSuggestedTagsPromise = new Promise<Api.IOperationResult<string[]>>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<string[]>) => {
					this.mLoadingSuggestedTagsPromise = null;
					if (opResult.success) {
						this.mSuggestedTags = opResult.value;
						resolve(opResult);
					} else {
						reject(opResult);
					}
				});
				this.userSession.webServiceHelper.callWebServiceWithOperationResults<string[]>(
					this.composeApiUrl({ urlPath: 'email/suggestedTags' }),
					'GET',
					null,
					onFinish,
					onFinish
				);
			});
		}
		return this.mLoadingSuggestedTagsPromise;
	}

	public getOwnersByTags = (filterRequest = this.mContactsFilterRequest) => {
		return new Promise<AccountUserViewModel[]>((resolve, reject) => {
			const onFinish = action((opResult: Api.IOperationResult<Api.IPagedCollection<Api.IUserWithStats>>) => {
				this.mLoadingSuggestedTagsPromise = null;
				if (opResult.success) {
					this.mUsers.setItems(
						opResult.value.values.map(x => new AccountUserViewModel(this.userSession as AdminUserSessionContext, x))
					);
					resolve(this.mUsers.toArray());
				} else {
					reject(opResult);
				}
			});
			this.userSession.webServiceHelper.callWebServiceWithOperationResults<Api.IPagedCollection<Api.IUserWithStats>>(
				this.composeApiUrl({ urlPath: 'user/ownersByFilter' }),
				'POST',
				filterRequest,
				onFinish,
				onFinish
			);
		});
	};

	@action
	public getContacts = (filterRequest = this.mContactsFilterRequest) => {
		const apiParams: Api.IDictionary<string> = {};
		if (this.selectedUsers?.length === 1) {
			apiParams.impUserId = this.selectedUsers.getByIndex(0).id;
		}
		const promise = this.mContactsPageCollectionController?.getNext(filterRequest, 25, apiParams);
		if (promise) {
			this.mContactsFilterRequest = filterRequest;
		}
		return promise;
	};

	@action
	public setContactFilterRequest = (filterRequest: Api.IContactsFilterRequest) => {
		this.mContactsFilterRequest = filterRequest;
		return Promise.all([this.getOwnersByTags(), this.getContacts()]);
	};

	@computed
	public get suggestedTags() {
		return this.mSuggestedTags;
	}

	@computed
	public get note() {
		return this.mNote;
	}

	public setNote(note: string) {
		this.mNote = note;
	}

	public get sendDate() {
		return this.mSendDate;
	}

	@action
	public setSendDate(sendDate: Date) {
		this.mSendDate = sendDate;
	}

	public impersonate(impersonationContext?: Api.IImpersonationContext) {
		super.impersonate(impersonationContext);
		this.mContactsPageCollectionController?.impersonate(impersonationContext);
		this.bulkEmailComposer?.impersonate(this.mImpersonationContext);
		return this;
	}

	@action
	public sendForApproval = () => {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<Api.CampaignViewModel[]>((resolve, reject) => {
				const composedFilter: Api.IContactFilterCriteria = {
					...this.mContactsFilterRequest,
					criteria: [
						...(this.userSelectionMode === 'one'
							? this.mContactsFilterRequest.criteria
							: this.mContactsFilterRequest.criteria.filter(
									x => x.property !== Api.ContactFilterCriteriaProperty.OwnedBy
								)),
						{
							op: Api.FilterOperator.Not,
							property: Api.ContactFilterCriteriaProperty.WithoutEmailAddresses,
						},
						...Array.from(Api.DefaultBulkSendExcludedTags).map(
							x =>
								({
									op: Api.FilterOperator.Not,
									property: Api.ContactFilterCriteriaProperty.Tag,
									value: x,
								}) as Api.IContactFilterCriteria
						),
					],
				};
				if (this.userSelectionMode === 'many') {
					composedFilter.criteria.push({
						property: Api.ContactFilterCriteriaProperty.OwnedBy,
					});
				}
				const approvalRequest: AdminModels.ICampaignEmailRequest = {
					approvalContent: Api.createRawRichTextContentStateWithText(this.mNote),
					contactsFilter: composedFilter,
					content: this.emailContent.editorState.getRawRichTextContent(),
					startDate: this.sendDate.toISOString(),
					subject: this.emailContent.subject,
					templateReference: this.emailContent?.template
						? {
								isCustomized: false,
								isSystemTemplate: true,
								name: this.emailContent.template?.name,
								templateId: this.emailContent.template?.id,
							}
						: null,
					userIds: this.selectedUsers.map(x => x.id),
				};
				const formData = new FormData();
				formData.append('value', JSON.stringify(approvalRequest));
				if (this.files?.count > 0) {
					this.files.attachments.forEach(x => formData.append('files', x));
				}

				const impersonationContext: Api.IImpersonationContext = {
					account: this.account,
				};
				const onFinish = action((opResult: Api.IOperationResult<Api.ICampaign[]>) => {
					if (opResult.success) {
						const campaigns = opResult.value.map(x => {
							return new Api.CampaignViewModel(this.userSession, x).impersonate(impersonationContext);
						});
						resolve(campaigns);
					} else {
						this.busy = false;
						reject(opResult);
					}
				});
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.ICampaign[]>(
					this.composeApiUrl({ urlPath: 'email/campaign' }),
					'POST',
					formData,
					onFinish,
					onFinish
				);
			});
		}
	};

	private mCreateContactViewModel = (contact: Api.IContact) => {
		return new Api.ContactViewModel(this.mUserSession, contact).impersonate(this.mImpersonationContext);
	};
}

export class ContentCalendarCreateCampaignApprovalViewModel extends CreateCampaignApprovalViewModel {
	@observable.ref private mAdmins: Api.UserViewModel[];

	constructor(userSession: AdminUserSessionContext, account?: Api.IAccount) {
		super(userSession, account);
		this.mAdmins = [];
	}

	@computed
	public get admins() {
		return this.mAdmins || [];
	}

	@computed
	public get defaultAdmin() {
		return this.admins[0];
	}

	@action
	public loadAdmins = async () => {
		try {
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IUser[]>(
				`impersonate/${this.account?.id}/user/admins`,
				'GET'
			);
			if (!success) {
				throw opResult;
			}
			this.mAdmins = opResult.value.map(x => new AccountUserViewModel(this.mUserSession, x));
			return this.mAdmins;
		} catch (error) {
			throw Api.asApiError(error);
		}
	};

	@action
	public createApproval = (sendFromUser?: Api.IUser) => {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<Api.CampaignViewModel>((resolve, reject) => {
				// FOLLOWUP: Resolve
				// @ts-ignore
				const composedFilter: Api.IContactFilterCriteria = this.bulkEmailComposer.emailMessage
					.hasUserSelectedContactFilterSearchCriteria
					? {
							criteria: [
								...(
									this.bulkEmailComposer.emailMessage.contactsFilterRequest?.contactFilterRequest?.criteria || []
								).filter(x => {
									return (
										!(
											x.op === Api.FilterOperator.Not &&
											x.property === Api.ContactFilterCriteriaProperty.WithoutEmailAddresses
										) &&
										!(
											x.op === Api.FilterOperator.Not &&
											x.property === Api.ContactFilterCriteriaProperty.Tag &&
											Api.DefaultBulkSendExcludedTags.has(x.value)
										)
									);
								}),
								{
									op: Api.FilterOperator.Not,
									property: Api.ContactFilterCriteriaProperty.WithoutEmailAddresses,
								},
								...Array.from(Api.DefaultBulkSendExcludedTags).map(x => ({
									op: Api.FilterOperator.Not,
									property: Api.ContactFilterCriteriaProperty.Tag,
									value: x,
								})),
							],
						}
					: { criteria: null };

				const emailMessageModel = this.bulkEmailComposer.emailMessage.toJs();
				emailMessageModel.options = {
					...(emailMessageModel.options || {}),
					sendWithCompliance: this.account.preferences.complianceSettings?.enabled,
				};
				if (sendFromUser) {
					emailMessageModel.options.sendEmailFrom = Api.SendEmailFrom.SelectedUser;
					emailMessageModel.options.sendEmailFromUser =
						sendFromUser instanceof Api.UserViewModel
							? (sendFromUser as Api.UserViewModel).toJs() // .toJs required to remove circular structure (ie. object refering to itself)...without calling this, will get `Converting circular structure to JSON` error
							: sendFromUser;
					emailMessageModel.options.sendEmailFromUserId = sendFromUser.id;
				} else {
					emailMessageModel.options.sendEmailFrom = Api.SendEmailFrom.ContactOwner;
				}
				emailMessageModel.contactsFilterRequest = {
					contactFilterRequest: composedFilter,
				};

				const formData = new FormData();
				formData.append('value', JSON.stringify(emailMessageModel));

				if (this.bulkEmailComposer.emailMessage.attachments?.count) {
					this.bulkEmailComposer.emailMessage.attachments?.attachments?.forEach(x => formData.append('files', x));
				}

				const impUserId = sendFromUser ? sendFromUser.id : undefined;
				const onFinish = action(async (opResult: Api.IOperationResult<Api.ISendEmailResponse>) => {
					if (opResult.success) {
						try {
							const campaign = new Api.CampaignViewModel(this.mUserSession, {
								id: opResult.value.id,
							}).impersonate(
								impUserId && this.mImpersonationContext?.account?.id
									? {
											account: this.mImpersonationContext?.account,
											user: { id: impUserId },
										}
									: this.mImpersonationContext
							);
							await campaign.load();
							this.busy = false;
							resolve(campaign);
						} catch (error) {
							this.busy = false;
							reject(error);
						}
					} else {
						this.busy = false;
						reject(opResult);
					}
				});
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.ISendEmailResponse>(
					this.composeApiUrl({ queryParams: { impUserId }, urlPath: 'email' }),
					'POST',
					formData,
					onFinish,
					onFinish
				);
			});
		}
	};
}

export class AccountCampaignViewModel extends Api.CampaignViewModel {
	@observable private mResendingApprovalRequest: boolean;

	@computed
	public get isBusy() {
		return this.busy || this.loading || this.mLoadingDefaultMessageContent || this.mResendingApprovalRequest;
	}
}
export class SocialAccountCampaignViewModel extends Api.SocialMediaPostViewModel {
	@observable private mResendingApprovalRequest: boolean;

	@computed
	public get isBusy() {
		return this.busy || this.loading || this.mResendingApprovalRequest;
	}

	@computed
	public get isResendingApprovalRequest() {
		return this.mResendingApprovalRequest;
	}

	public sendApprovalRequest = () => {
		if (this.status === Api.PostStatus.Pending) {
			this.mResendingApprovalRequest = true;
			return new Promise<boolean>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<Api.ISocialMediaPost[]>) => {
					this.mResendingApprovalRequest = false;
					if (opResult.success) {
						resolve(true);
					} else {
						reject(opResult);
					}
				});
				this.userSession.webServiceHelper.callWebServiceWithOperationResults<Api.ISocialMediaPost[]>(
					this.composeApiUrl({ urlPath: `social/post/${this.id}/approvalrequest` }),
					'POST',
					null,
					onFinish,
					onFinish
				);
			});
		}
	};

	public sendComplianceApprovalRequest = (sendWithComplianceEmail?: string) => {
		if (this.status === Api.PostStatus.Pending) {
			this.mResendingApprovalRequest = true;
			return new Promise<boolean>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<Api.ISocialMediaPost[]>) => {
					this.mResendingApprovalRequest = false;
					if (opResult.success) {
						resolve(true);
					} else {
						reject(opResult);
					}
				});
				this.userSession.webServiceHelper.callWebServiceWithOperationResults<Api.ISocialMediaPost[]>(
					this.composeApiUrl({
						queryParams: { sendWithComplianceEmail },
						urlPath: `social/post/${this.id}/sendCompliance`,
					}),
					'POST',
					null,
					onFinish,
					onFinish
				);
			});
		}
	};
}

export class AdminReportingViewModel extends Api.ViewModel {
	@observable.ref protected mActiveUseSummary: AdminModels.IActiveUseSummary;
	@observable.ref protected mActiveUseSummaryPromise: Promise<Api.IOperationResult<AdminModels.IActiveUseSummary>>;
	@observable.ref
	protected mAccountChangeSummary: AdminModels.IActiveUseDailySummaryByAccount[];
	@observable.ref protected mAccountChangeSummaryPromise: Promise<
		Api.IOperationResult<AdminModels.IActiveUseDailySummaryByAccount[]>
	>;
	@observable.ref
	protected mRenewalByMonth: AdminModels.IMonthlyRecurringRevenueSummary;
	@observable.ref protected mRenewalByMonthPromise: Promise<
		Api.IOperationResult<AdminModels.IMonthlyRecurringRevenueSummary>
	>;

	@observable.ref protected mCurrentMonthPercent: string;
	@observable.ref protected mNextMonthPercent: string;

	constructor(userSession: Api.UserSessionContext) {
		super(userSession);
	}

	@action
	public loadAll = async () => {
		if (!this.isBusy) {
			this.busy = true;
			const promise = new Promise<
				[
					Api.IPageCollectionControllerFetchResult<Api.IAccount[]>,
					Api.IOperationResult<AdminModels.IAggregateActiveUse>,
				]
			>((resolve, reject) => {
				const criteria: IAccountFilterCriteria = {
					criteria: [
						{
							property: AccountFilterCriteriaProperty.RenewalMonth,
							value: moment().add(1, 'month').format('YYYY-MM'),
						},
					],
					op: Api.FilterOperator.And,
				};
				const nextMonthPromise = this.loadRenewalByMonth(criteria);
				const dailyUsePromise = this.loadActiveUseSummary();

				Promise.all([nextMonthPromise, dailyUsePromise])
					.then(fetchResults => {
						runInAction(() => {
							if (fetchResults[0]) {
								this.mNextMonthPercent = fetchResults[0]?.value?.totalRenewalPercent
									? fetchResults[0].value.totalRenewalPercent.toString() + '%'
									: '??%';
							}
							if (fetchResults[1]) {
								this.mActiveUseSummary = fetchResults[1].value;
							}
							this.busy = false;
							resolve(undefined);
						});
					})
					.catch(e => {
						this.busy = false;
						reject(Api.asApiError(e));
					});
			});
			return promise;
		}
	};

	@action
	public loadActiveUseSummary = () => {
		if (!this.mActiveUseSummaryPromise) {
			this.mActiveUseSummaryPromise = new Promise<Api.IOperationResult<AdminModels.IActiveUseSummary>>(
				(resolve, reject) => {
					const onFinish = (opResult: Api.IOperationResult<AdminModels.IActiveUseSummary>) => {
						runInAction(() => {
							if (opResult.success) {
								this.mActiveUseSummary = opResult.value;
								this.mActiveUseSummaryPromise = null;
								resolve(opResult);
							} else {
								this.mActiveUseSummaryPromise = null;
								reject(opResult);
							}
						});
					};
					this.userSession.webServiceHelper.callWebServiceWithOperationResults<AdminModels.IActiveUseSummary>(
						'admin/account/stats/summary',
						'POST',
						{},
						onFinish,
						onFinish
					);
				}
			);
		}
		return this.mActiveUseSummaryPromise;
	};

	@computed
	public get currentMonthPercent() {
		return this.mCurrentMonthPercent;
	}

	@computed
	public get nextMonthPercent() {
		return this.mNextMonthPercent;
	}

	@computed
	public get activeUseSummary() {
		return this.mActiveUseSummary;
	}

	@computed
	public get isLoadingActiveUseSummary() {
		return !!this.mActiveUseSummaryPromise;
	}

	@action
	public loadAccountsChanged = () => {
		if (!this.mAccountChangeSummaryPromise) {
			this.mAccountChangeSummaryPromise = new Promise<
				Api.IOperationResult<AdminModels.IActiveUseDailySummaryByAccount[]>
			>((resolve, reject) => {
				const onFinish = (opResult: Api.IOperationResult<AdminModels.IActiveUseDailySummaryByAccount[]>) => {
					runInAction(() => {
						if (opResult.success) {
							this.mAccountChangeSummary = opResult.value;
							this.mAccountChangeSummaryPromise = null;
							resolve(opResult);
						} else {
							this.mAccountChangeSummaryPromise = null;
							reject(opResult);
						}
					});
				};
				this.userSession.webServiceHelper.callWebServiceWithOperationResults<
					AdminModels.IActiveUseDailySummaryByAccount[]
				>('admin/account/activeUseStatusChanged', 'GET', null, onFinish, onFinish);
			});
		}
		return this.mAccountChangeSummaryPromise;
	};

	@computed
	public get accountsChanged() {
		return this.mAccountChangeSummary;
	}

	@computed
	public get isLoadingAccountsChanged() {
		return !!this.mAccountChangeSummaryPromise;
	}

	@action
	public loadRenewalByMonth = (filter: IAccountFilterCriteria) => {
		if (!this.mRenewalByMonthPromise) {
			this.mRenewalByMonthPromise = new Promise<Api.IOperationResult<AdminModels.IMonthlyRecurringRevenueSummary>>(
				(resolve, reject) => {
					const onFinish = (opResult: Api.IOperationResult<AdminModels.IMonthlyRecurringRevenueSummary>) => {
						runInAction(() => {
							if (opResult.success) {
								this.mRenewalByMonth = opResult.value;
								this.mRenewalByMonthPromise = null;
								resolve(opResult);
							} else {
								this.mRenewalByMonthPromise = null;
								reject(opResult);
							}
						});
					};
					this.userSession.webServiceHelper.callWebServiceWithOperationResults<AdminModels.IMonthlyRecurringRevenueSummary>(
						`admin/account/renewalProbabilityByMonth`,
						'POST',
						filter,
						onFinish,
						onFinish
					);
				}
			);
		}
		return this.mRenewalByMonthPromise;
	};

	@computed
	public get renewalByMonth() {
		return this.mRenewalByMonth;
	}

	@computed
	public get isLoadingRenewalByMonth() {
		return !!this.mRenewalByMonthPromise;
	}

	private asPercentage(val: number) {
		return val >= 0 ? val.toLocaleString() + '%' : '0%';
	}

	@computed
	public get validDailyActiveUse() {
		return this.asPercentage(this.mActiveUseSummary?.overall?.activeAccountsPercentage);
	}

	@computed
	public get validGreenActiveUse() {
		return this.asPercentage(this.mActiveUseSummary?.overall?.greenAccountsPercentage);
	}

	@computed
	public get validGrayActiveUse() {
		const overall = this.mActiveUseSummary?.overall;
		if (overall?.grayAccounts >= 0 && overall?.totalAccounts > 0) {
			return parseInt(((overall?.grayAccounts / overall?.totalAccounts) * 100).toLocaleString(), 10) + '%' || '??%';
		}
		return '??%';
	}

	@computed
	public get validYellowActiveUse() {
		return this.asPercentage(this.mActiveUseSummary?.overall?.yellowAccountsPercentage);
	}

	@computed
	public get validOrangeActiveUse() {
		return this.asPercentage(this.mActiveUseSummary?.overall?.orangeAccountsPercentage);
	}

	@computed
	public get validNewRedActiveUse() {
		return this.asPercentage(this.mActiveUseSummary?.overall?.changedToRedAccountsPercentage);
	}

	@computed
	public get validInsuranceActiveUse() {
		const insurance = this.mActiveUseSummary?.byIndustry?.find(
			industry => industry.industry.toLocaleLowerCase() === 'insurance'
		);
		return this.asPercentage(insurance?.activeAccountsPercentage);
	}

	@computed
	public get validRealEstateActiveUse() {
		const realEstate = this.mActiveUseSummary?.byIndustry?.find(
			industry => industry.industry.toLocaleLowerCase() === 'real estate'
		);
		return this.asPercentage(realEstate?.activeAccountsPercentage);
	}

	@computed
	public get validFinancialActiveUse() {
		const financial = this.mActiveUseSummary?.byIndustry?.find(
			industry => industry.industry.toLocaleLowerCase() === 'financial'
		);
		return this.asPercentage(financial?.activeAccountsPercentage);
	}

	@computed
	public get validMortgageActiveUse() {
		const mortgage = this.mActiveUseSummary?.byIndustry?.find(
			industry => industry.industry.toLocaleLowerCase() === 'mortgage'
		);
		return this.asPercentage(mortgage?.activeAccountsPercentage);
	}

	@computed
	public get validLegalActiveUse() {
		const legal = this.mActiveUseSummary?.byIndustry?.find(
			industry => industry.industry.toLocaleLowerCase() === 'legal'
		);
		return this.asPercentage(legal?.activeAccountsPercentage);
	}

	/** Renewal data */
	@computed
	public get validRenewalByMonth() {
		return this.asPercentage(this.renewalByMonth?.totalRenewalPercent);
	}

	@computed
	public get validRenewalByMonthInsurance() {
		const insurance = this.mRenewalByMonth?.byIndustry?.find(
			industry => industry.industry.toLocaleLowerCase() === 'insurance'
		);
		return this.asPercentage(insurance?.percentage);
	}

	@computed
	public get validRenewalDollarsByMonthInsurance() {
		const insurance = this.renewalByMonth?.noSaleByIndustry?.find(
			industry => industry.industry.toLocaleLowerCase() === 'insurance'
		);
		return this.asPercentage(insurance?.amount);
	}

	@computed
	public get validRenewalByMonthRealEstate() {
		const realEstate = this.mRenewalByMonth?.byIndustry?.find(
			industry => industry.industry.toLocaleLowerCase() === 'real estate'
		);
		return this.asPercentage(realEstate?.percentage);
	}

	@computed
	public get validRenewalByMonthFinancial() {
		const financial = this.mRenewalByMonth?.byIndustry?.find(
			industry => industry.industry.toLocaleLowerCase() === 'financial'
		);
		return this.asPercentage(financial?.percentage);
	}

	@computed
	public get validRenewalByMonthMortgage() {
		const mortgage = this.mRenewalByMonth?.byIndustry?.find(
			industry => industry.industry.toLocaleLowerCase() === 'mortgage'
		);
		return this.asPercentage(mortgage?.percentage);
	}

	@computed
	public get validRenewalByMonthLegal() {
		const legal = this.mRenewalByMonth?.byIndustry?.find(industry => industry.industry.toLocaleLowerCase() === 'legal');
		return this.asPercentage(legal?.percentage);
	}

	@computed
	public get validRenewalByMonthNonProfit() {
		const nonProfit = this.mRenewalByMonth?.byIndustry?.find(
			industry => industry.industry.toLocaleLowerCase() === 'non-profit'
		);
		return this.asPercentage(nonProfit?.percentage);
	}

	@computed
	public get validRenewalByMonthAccounting() {
		const accounting = this.mRenewalByMonth?.byIndustry?.find(
			industry => industry.industry.toLocaleLowerCase() === 'accounting'
		);
		return this.asPercentage(accounting?.percentage);
	}

	/** No sale data */
	@computed
	public get noSaleTotal() {
		return this.asDollars(this.renewalByMonth?.noSaleTotal);
	}

	@computed
	public get noSaleInsurance() {
		const insurance = this.mRenewalByMonth?.noSaleByIndustry?.find(
			industry => industry.industry.toLocaleLowerCase() === 'insurance'
		);
		return this.asDollars(insurance?.amount);
	}

	@computed
	public get noSaleRealEstate() {
		const realEstate = this.mRenewalByMonth?.noSaleByIndustry?.find(
			industry => industry.industry.toLocaleLowerCase() === 'real estate'
		);
		return this.asDollars(realEstate?.amount);
	}

	@computed
	public get noSaleFinancial() {
		const financial = this.mRenewalByMonth?.noSaleByIndustry?.find(
			industry => industry.industry.toLocaleLowerCase() === 'financial'
		);
		return this.asDollars(financial?.amount);
	}

	@computed
	public get noSaleMortgage() {
		const mortgage = this.mRenewalByMonth?.noSaleByIndustry?.find(
			industry => industry.industry.toLocaleLowerCase() === 'mortgage'
		);
		return this.asDollars(mortgage?.amount);
	}

	@computed
	public get noSaleLegal() {
		const legal = this.mRenewalByMonth?.noSaleByIndustry?.find(
			industry => industry.industry.toLocaleLowerCase() === 'legal'
		);
		return this.asDollars(legal?.amount);
	}

	@computed
	public get noSaleNonProfit() {
		const nonProfit = this.mRenewalByMonth?.noSaleByIndustry?.find(
			industry => industry.industry.toLocaleLowerCase() === 'non-profit'
		);
		return this.asDollars(nonProfit?.amount);
	}

	private asDollars(val: number) {
		return val > 0 ? '$' + val.toLocaleString() : '$0';
	}

	@computed
	public get churn() {
		return this.asDollars(this.mRenewalByMonth?.projectedChurn);
	}

	@computed
	public get totalMRR() {
		return this.asDollars(this.mRenewalByMonth?.totalMRR);
	}
}

export class TemplateCategoriesViewModel extends Api.ViewModel {
	public readonly industry: Api.Industry;
	public readonly campaignType: AdminModels.CampaignType;
	protected readonly mCategoriesApiPathComponent: string;
	@observable.ref
	private mCategories: Api.ObservableCollection<TemplateCategoryViewModel>;
	constructor(userSession: AdminUserSessionContext, industry: Api.Industry, campaignType: AdminModels.CampaignType) {
		super(userSession);
		this.industry = industry;
		this.campaignType = campaignType;
		this.mCategoriesApiPathComponent = 'categories';
		if (campaignType === AdminModels.CampaignType.Social) {
			this.mCategoriesApiPathComponent = 'socialCategories';
		}
		if (campaignType === AdminModels.CampaignType.Blog) {
			this.mCategoriesApiPathComponent = 'blogCategories';
		}
	}

	@computed
	public get categories() {
		return this.mCategories;
	}

	@action
	public async load() {
		if (!this.isBusy) {
			this.busy = true;
			const opResult: Api.IOperationResult<Api.ITemplateCategory[]> =
				await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ITemplateCategory[]>(
					`admin/template/${encodeURIComponent(this.industry)}/${this.mCategoriesApiPathComponent}`,
					'GET'
				);

			this.busy = false;
			if (opResult.success) {
				this.mCategories = this.transformCategories(opResult.value);
				this.loaded = true;
			} else {
				throw Api.asApiError(opResult);
			}
		}
	}

	/**
	 * @NOTE This general call to delete category should hit both Email and Social
	 * @param categoryName {string} - the name of the category to delete
	 * @param campaignType {AdminModels.CampaignType} - the type of campaign
	 */
	@action
	public async delete(categoryName: string, campaignType: AdminModels.CampaignType) {
		if (!this.isBusy) {
			let categoriesType = '';
			switch (campaignType) {
				case AdminModels.CampaignType.Social:
				case AdminModels.CampaignType.Email:
					categoriesType = 'categories';
					break;
				case AdminModels.CampaignType.Blog:
					categoriesType = 'blogCategories';
					break;
				default:
					break;
			}
			this.busy = true;
			const opResult: Api.IOperationResult<Api.ITemplateCategory[]> =
				await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ITemplateCategory[]>(
					`admin/template/${encodeURIComponent(this.industry)}/${categoriesType}/${encodeURIComponent(categoryName)}`,
					'DELETE'
				);

			this.busy = false;
			if (opResult.success) {
				this.mCategories = new Api.ObservableCollection(this.mCategories.filter(x => x.name !== categoryName));
			} else {
				throw Api.asApiError(opResult);
			}
		}
	}

	// This create call should hit both email and social
	@action
	public async create(name: string) {
		if (!this.isBusy) {
			this.busy = true;
			const newCategory: Api.ITemplateCategory = {
				name,
				templates: [],
				total: 0,
				type: Api.TemplateCategoryType.Default,
			};

			const opResult: Api.IOperationResult<Api.ITemplateCategory> =
				await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ITemplateCategory>(
					`admin/template/${encodeURIComponent(this.industry)}/${this.mCategoriesApiPathComponent}`,
					'POST',
					newCategory
				);

			this.busy = false;
			if (opResult.success) {
				// splice into the second place in the list (after featured)
				this.mCategories.splice(
					1,
					0,
					new TemplateCategoryViewModel(this.mUserSession as AdminUserSessionContext, opResult.value, this.industry)
				);
				this.loaded = true;
			} else {
				throw Api.asApiError(opResult);
			}
		}
	}

	// Need a separate call for reordering categories for the social display
	@action
	public async reorderCategories(category: TemplateCategoryViewModel, toIndex: number) {
		if ((!category && !this.mCategories.has(category)) || this.isBusy) {
			return;
		}
		const fromIndex = this.mCategories.indexOf(category);
		if (fromIndex === toIndex) {
			return;
		}

		this.mCategories.splice(fromIndex, 1);
		this.mCategories.splice(toIndex, 0, category);
		const names = this.mCategories.map(x => x.name);
		const opResult: Api.IOperationResult<Api.ITemplateCategory[]> =
			await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ITemplateCategory[]>(
				`admin/template/${this.industry}/${this.mCategoriesApiPathComponent}/order`,
				'PUT',
				names
			);

		if (opResult.success) {
			this.mCategories = this.transformCategories(opResult.value);
		} else {
			// undo
			this.mCategories.splice(toIndex, 1);
			this.mCategories.splice(fromIndex, 0, category);
		}
	}

	private transformCategories(
		categories: Api.ITemplateCategory[]
	): Api.ObservableCollection<TemplateCategoryViewModel> {
		return new Api.ObservableCollection(
			categories.map(x => new TemplateCategoryViewModel(this.userSession as AdminUserSessionContext, x, this.industry)),
			'name'
		);
	}
}

export class TemplateCategoryViewModel extends Api.ViewModel {
	@observable.ref private mTemplateCategory: Api.ITemplateCategory;
	@observable.ref
	private mTemplates: Api.ObservableCollection<TemplateWithStatusesViewModel>;
	@observable private isExpandedUI: boolean;
	public readonly industry: Api.Industry;

	constructor(userSession: AdminUserSessionContext, category: Api.ITemplateCategory, industry: Api.Industry) {
		super(userSession);
		this.industry = industry;
		this.mTemplateCategory = category;
		this.mTemplates = null;
		this.isExpandedUI = false;
	}

	@action
	public setExpanded(expanded: boolean) {
		this.isExpandedUI = expanded;
	}

	@computed
	public get numberOfUnpublishedTemplates() {
		return this.mTemplateCategory.templates.filter(t => t.status === Api.TemplateStatus.Draft)?.length;
	}

	public isUnpublishedTemplate(templateId: string) {
		if (this.mTemplates) {
			const sysTemplate = this.mTemplates.find(x => x.templateReference.template.id === templateId);
			return sysTemplate.templateReference.industries[this.industry] === Api.TemplateStatus.Draft;
		}
		return this.mTemplateCategory.templates.find(t => t.templateId === templateId)?.status === Api.TemplateStatus.Draft;
	}

	@computed
	public get isExpanded() {
		return this.isExpandedUI;
	}

	@computed
	public get total() {
		return this.isLoaded ? this.mTemplates?.length : this.mTemplateCategory.total;
	}

	@action
	public setTotal(count: number) {
		this.mTemplateCategory.total = count;
	}

	@computed
	public get name() {
		return this.mTemplateCategory.name;
	}

	@computed
	public get type() {
		return this.mTemplateCategory.type;
	}

	@computed
	public get systemTemplates() {
		return this.mTemplates;
	}

	@action
	public async load(campaignType?: AdminModels.CampaignType) {
		const categoriesType = getCategoryPathByCampaignType(campaignType);

		if (!this.isBusy) {
			this.busy = true;
			try {
				const templates = await this.mUserSession.webServiceHelper.callAsync<AdminModels.ISystemEmailTemplate[]>(
					`admin/template/${encodeURIComponent(this.industry)}/${categoriesType}/${encodeURIComponent(
						this.name
					)}/templates`,
					'GET'
				);
				this.mTemplates = this.transformTemplates(templates);
			} finally {
				this.busy = false;
				this.loaded = true;
			}
		}
	}

	@action
	public async reorder(
		templates: TemplateWithStatusesViewModel[],
		toIndex: number,
		campaignType: AdminModels.CampaignType
	) {
		let templateType = '';
		switch (campaignType) {
			case AdminModels.CampaignType.Social:
				templateType = 'socialTemplates';
				break;
			case AdminModels.CampaignType.Blog:
				templateType = 'blogTemplates';
				break;
			case AdminModels.CampaignType.Email:
				templateType = 'templates';
				break;
			default:
				templateType = 'templates';
		}

		const templateToInsertBefore = this.mTemplates.getByIndex(toIndex);

		templates.forEach(template => {
			const fromIndex = this.mTemplates.indexOf(template);
			this.mTemplates.splice(fromIndex, 1);
		});

		// The previous toIndex was referencing prior to removing the above templates, find where it is again
		const newToIndex = this.mTemplates.indexOf(templateToInsertBefore);

		if (newToIndex === -1) {
			throw Api.asApiError({ systemMessage: 'Do not insert at a template being moved' });
			return;
		}

		this.mTemplates.splice(newToIndex, 0, ...templates);

		const ids = this.mTemplates.map(x => {
			return x.templateReference.template.id;
		});
		const opResult: Api.IOperationResultNoValue =
			await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IOperationResultNoValue>(
				`admin/template/${this.industry}/${this.mTemplateCategory.name}/${templateType}/order`,
				'PUT',
				ids
			);

		if (!opResult.success) {
			throw Api.asApiError({
				systemMessage: 'Unable to rearrange templates (make sure to refresh before trying again)',
			});
		}
	}

	@action
	public async unfeature(templateId: string, campaignType: AdminModels.CampaignType) {
		const categoriesType = getCategoryPathByCampaignType(campaignType);
		if (!this.isBusy) {
			const opResult =
				await this.mUserSession.webServiceHelper.callWebServiceAsync<AdminModels.ISystemEmailTemplateWithStatuses>(
					`admin/template/industry/${categoriesType}/${encodeURIComponent(templateId)}/statuses`,
					'DELETE',
					[{ category: this.name, industry: this.industry }]
				);

			if (opResult.success) {
				this.mTemplateCategory.total -= 1;
				if (this.mTemplateCategory.total === 0) {
					this.isExpandedUI = false;
				}
				this.mTemplates = new Api.ObservableCollection(
					this.mTemplates.filter(x => x.templateReference.template.id !== templateId)
				);
			} else {
				throw Api.asApiError(opResult);
			}
		}
	}

	@computed
	public get isBusy() {
		return this.busy || this.loading;
	}

	@action
	public async feature(templateId: string, campaignType: AdminModels.CampaignType) {
		const categoriesType = getCategoryPathByCampaignType(campaignType);
		if (!this.isBusy) {
			const opResult =
				await this.mUserSession.webServiceHelper.callWebServiceAsync<AdminModels.ISystemEmailTemplateWithStatuses>(
					`admin/template/industry/${categoriesType}/${encodeURIComponent(templateId)}/statuses`,
					'POST',
					[
						{
							category: 'Featured',
							industry: this.industry,
							status: 'Published',
						},
					]
				);

			if (opResult.success) {
				return opResult.value;
			} else {
				throw Api.asApiError(opResult);
			}
		}
	}

	@action
	public async deleteTemplate(templateId: string) {
		if (!this.isBusy) {
			this.busy = true;
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IOperationResultNoValue>(
				`admin/template/${encodeURIComponent(templateId)}`,
				'DELETE'
			);

			this.busy = false;
			if (opResult.success) {
				return opResult.value;
			} else {
				throw Api.asApiError(opResult);
			}
		}
	}

	@action
	public async addStatuses(
		templateId: string,
		statuses: Api.ITemplateStatus[],
		campaignType: AdminModels.CampaignType,
		isCombo: boolean
	) {
		const categoriesType = getCategoryPathByCampaignType(campaignType, isCombo);
		if (!this.isBusy) {
			this.busy = true;
			this.isExpandedUI = false;
			const opResult =
				await this.mUserSession.webServiceHelper.callWebServiceAsync<AdminModels.ISystemEmailTemplateWithStatuses>(
					`admin/template/industry/${categoriesType}/${encodeURIComponent(templateId)}/statuses`,
					'POST',
					statuses
				);

			await this.load(campaignType);
			this.busy = false;
			this.setTotal(this.total + statuses.length);
			this.isExpandedUI = true;

			if (opResult.success) {
				return opResult.value;
			} else {
				throw Api.asApiError(opResult);
			}
		}
	}

	@action
	public async removeStatuses(
		templateId: string,
		statuses: Api.ITemplateStatus[],
		campaignType: AdminModels.CampaignType,
		isCombo: boolean
	) {
		const categoriesType = getCategoryPathByCampaignType(campaignType, isCombo);
		if (!this.isBusy) {
			this.busy = true;
			this.isExpandedUI = false;
			const opResult =
				await this.mUserSession.webServiceHelper.callWebServiceAsync<AdminModels.ISystemEmailTemplateWithStatuses>(
					`admin/template/industry/${categoriesType}/${encodeURIComponent(templateId)}/statuses`,
					'DELETE',
					statuses
				);

			await this.load(campaignType);
			this.busy = false;
			this.setTotal(this.total - statuses.filter(x => x.industry === this.industry)?.length);

			if (opResult.success || opResult.systemMessage === 'Template not found in any category or industry') {
				return opResult.value;
			} else {
				throw Api.asApiError(opResult);
			}
		}
	}

	private transformTemplates(templates: AdminModels.ISystemEmailTemplate[]) {
		return new Api.ObservableCollection(
			templates.map(
				x => new TemplateWithStatusesViewModel(this.userSession as AdminUserSessionContext, x, this.industry)
			)
		);
	}
}

export class TemplateWithStatusesViewModel extends Api.ViewModel {
	public readonly industry: string;
	@observable.ref
	private mTemplateReference: AdminModels.ISystemEmailTemplate<Api.ITemplate | Api.IBlogTemplate>;
	constructor(
		userSession: AdminUserSessionContext,
		template: AdminModels.ISystemEmailTemplate<Api.ITemplate | Api.IBlogTemplate>,
		industry: Api.Industry
	) {
		super(userSession);
		this.industry = industry;
		this.mTemplateReference = template;
	}

	@computed
	public get templateReference() {
		return this.mTemplateReference;
	}

	@computed
	public get refName() {
		return this.mTemplateReference?.template?.name ?? '';
	}

	@computed
	public get refIndustries() {
		return Object.keys(this.templateReference.industries).join(', ').replace('_Others_', 'Others') ?? '';
	}

	@computed
	public get refCreated() {
		return moment(this.mTemplateReference?.template?.creationDate).format('MM/DD/YYYY') ?? '';
	}
}

export class TemplateEditViewModel extends Api.ViewModel {
	@observable.ref protected mTemplate: Api.ITemplate;
	@observable.ref protected mStatuses: Api.ITemplateStatus[];
	@observable.ref
	protected mSocialMedia: Api.IEmbeddedSocialMediaTemplateContent;
	constructor(userSession: AdminUserSessionContext, template?: Api.ITemplate) {
		super(userSession);
		this.setTemplate = this.setTemplate.bind(this);
		this.setTemplate(template);
	}

	@computed
	public get template() {
		return this.mTemplate;
	}

	@computed
	public get id() {
		return this.mTemplate?.id;
	}

	@computed
	public get statuses() {
		return this.mStatuses;
	}

	@computed
	public get socialMedia() {
		return this.mSocialMedia;
	}
	private getCategoryNamesPathByCampaignType(campaignType: AdminModels.CampaignType, isCombo?: boolean) {
		let categoriesType = '';
		switch (campaignType) {
			case AdminModels.CampaignType.Social:
				categoriesType = 'socialCategoryNames';
				break;
			case AdminModels.CampaignType.Blog:
				categoriesType = 'blogCategoryNames';
				break;
			case AdminModels.CampaignType.Email:
				categoriesType = 'categoryNames';
				break;
			default:
				categoriesType = 'categoryNames';
				break;
		}
		if (campaignType === AdminModels.CampaignType.Social && isCombo) {
			categoriesType = 'socialCategoryNames';
		}
		return categoriesType;
	}

	@action
	public async loadCategories(campaignType: AdminModels.CampaignType) {
		const categoryNames = this.getCategoryNamesPathByCampaignType(campaignType);
		const opResult: Api.IOperationResult<AdminModels.ITemplatesIndustryWithCategories[]> =
			await this.mUserSession.webServiceHelper.callWebServiceAsync<AdminModels.ITemplatesIndustryWithCategories[]>(
				`admin/template/${categoryNames}`,
				'GET'
			);

		if (opResult.success) {
			return opResult.value;
		} else {
			throw Api.asApiError(opResult);
		}
	}

	@action
	public async load(templateId: string = this.mTemplate.id, campaignType?: AdminModels.CampaignType) {
		const categoriesType = getCategoryPathByCampaignType(campaignType);
		if (!this.busy) {
			this.busy = true;
			const opResult =
				await this.mUserSession.webServiceHelper.callWebServiceAsync<AdminModels.ISystemEmailTemplateWithStatuses>(
					`admin/template/industry/${categoriesType}/templates/${templateId}`,
					'GET'
				);

			this.busy = false;
			if (opResult.success) {
				this.setTemplate(opResult.value.template);
				this.mStatuses = opResult.value.statuses;
				this.mSocialMedia = opResult.value.socialMedia;
				this.loaded = true;
				return opResult.value;
			} else {
				throw Api.asApiError(opResult);
			}
		}
	}

	@action
	public async deleteAttachments(templateId: string = this.mTemplate.id, attachments: Api.IFileAttachment[]) {
		if (!this.busy) {
			this.busy = true;
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ITemplate>(
				`admin/template/industry/categories/templates/${templateId}/attachments`,
				'DELETE',
				attachments?.map(x => x.id)
			);

			this.busy = false;
			if (opResult.success) {
				if (templateId === this.mTemplate.id) {
					this.setTemplate(opResult.value);
					this.mSocialMedia = opResult.value.socialMedia;
				} else if (templateId === this.mSocialMedia?.templateId) {
					this.mSocialMedia = {
						...this.mSocialMedia,
						attachments: opResult.value.attachments,
						content: opResult.value.content,
					};
				}
				return opResult.value;
			} else {
				throw Api.asApiError(opResult);
			}
		}
	}

	@action
	public async create(
		systemEmailTemplate: AdminModels.ISystemEmailTemplateWithStatuses = this.toJs(),
		campaignType?: AdminModels.CampaignType
	) {
		const categoriesType = getCategoryPathByCampaignType(campaignType);
		if (!this.busy) {
			this.busy = true;
			const opResult =
				await this.mUserSession.webServiceHelper.callWebServiceAsync<AdminModels.ISystemEmailTemplateWithStatuses>(
					`admin/template/industry/${categoriesType}/templates`,
					'POST',
					systemEmailTemplate
				);

			this.busy = false;
			if (opResult.success) {
				this.mStatuses = systemEmailTemplate.statuses; // not returned in response.
				this.setTemplate(opResult.value.template);
				return this.mTemplate;
			} else {
				throw Api.asApiError(opResult);
			}
		}
	}

	@action
	public async edit(
		template: Api.ITemplate,
		statusesToRemove: Api.ITemplateStatus[],
		statusesToAdd: Api.ITemplateStatus[],
		statusesToUpdate: Api.ITemplateStatus[],
		campaignType?: AdminModels.CampaignType,
		socialMedia?: Api.IEmbeddedSocialMediaTemplateContent
	) {
		if (!this.busy) {
			this.busy = true;
			let updatedTemplate: Api.ITemplate = null;
			let updatedStatuses: Api.ITemplateStatus[] = null;
			let updatedSocialMedia: Api.IEmbeddedSocialMediaTemplateContent = null;
			const categoriesType = getCategoryPathByCampaignType(campaignType);

			const finish = () => {
				this.busy = false;
				if (updatedTemplate) {
					this.setTemplate(updatedTemplate);
				}
				if (updatedStatuses) {
					this.mStatuses = updatedStatuses;
				}
				if (updatedSocialMedia) {
					this.mSocialMedia = updatedSocialMedia;
				}
			};
			const throwError = (operationResult: Api.IOperationResultNoValue) => {
				finish();
				throw Api.asApiError(operationResult);
			};

			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<AdminModels.ISystemEmailTemplate>(
				`admin/template/industry/${categoriesType}/templates/${template.id}`, // update this path per PR
				'PUT',
				{
					socialMedia,
					template,
				}
			);

			if (!opResult.success) {
				throwError(opResult);
			}

			updatedTemplate = opResult.value.template;
			updatedSocialMedia = opResult.value.socialMedia;

			if (statusesToRemove.length) {
				// remove statuses first
				const removeStatusResult =
					await this.mUserSession.webServiceHelper.callWebServiceAsync<AdminModels.ISystemEmailTemplateWithStatuses>(
						`admin/template/industry/${categoriesType}/${template.id}/statuses`,
						'DELETE',
						statusesToRemove
					);
				if (removeStatusResult.success) {
					updatedTemplate = removeStatusResult.value.template;
					updatedStatuses = removeStatusResult.value.statuses;
				} else {
					throwError(removeStatusResult);
				}
			}

			if (statusesToUpdate.length) {
				// update existing (publish)
				const updateStatusResult =
					await this.mUserSession.webServiceHelper.callWebServiceAsync<AdminModels.ISystemEmailTemplateWithStatuses>(
						`admin/template/industry/${categoriesType}/${template.id}/statuses`,
						'PUT',
						statusesToUpdate
					);
				if (updateStatusResult.success) {
					updatedTemplate = updateStatusResult.value.template;
					updatedSocialMedia = updateStatusResult.value.socialMedia;
					updatedStatuses = updateStatusResult.value.statuses;
				} else {
					throwError(updateStatusResult);
				}
			}

			if (statusesToAdd.length) {
				// then add desired statuses
				const statusResult =
					await this.mUserSession.webServiceHelper.callWebServiceAsync<AdminModels.ISystemEmailTemplateWithStatuses>(
						`admin/template/industry/${categoriesType}/${template.id}/statuses`,
						'POST',
						statusesToAdd
					);

				if (statusResult.success) {
					updatedStatuses = statusResult.value.statuses;
					updatedSocialMedia = statusResult.value.socialMedia;
					updatedTemplate = statusResult.value.template;
				} else {
					throwError(statusResult);
				}
			}

			finish();
		}
	}

	public toJs = (): AdminModels.ISystemEmailTemplateWithStatuses => {
		return {
			socialMedia: this.mSocialMedia,
			statuses: this.mStatuses,
			template: this.mTemplate,
		};
	};

	public getSystemTemplate = (): AdminModels.ISystemEmailTemplate => {
		return {
			industries: {},
			socialMedia: this.socialMedia,
			template: this.mTemplate,
		};
	};

	protected setTemplate(template?: Api.ITemplate) {
		this.mTemplate = template
			? this.mTemplate
				? {
						...(this.mTemplate || {}),
						...template, // template might not have content, but this.mTemplate might have this filled
					}
				: template
			: template;
	}
}

export class IndustryHtmlNewsletterViewModel extends TemplateEditViewModel implements IHtmlNewsletter {
	public static HtmlNewslettersCategoryName = 'HTML Newsletters';
	public readonly files: Api.AttachmentsViewModel;

	public static getDefaultTemplateStatuses() {
		return DefaultSupportedIndustries.map<Api.ITemplateStatus>(x => {
			return {
				category: IndustryHtmlNewsletterViewModel.HtmlNewslettersCategoryName,
				industry: x,
				status: Api.TemplateStatus.Draft,
			};
		});
	}

	public static async createWithDefaultBlankTemplate(
		userSession: AdminUserSessionContext,
		systemTemplate: AdminModels.ISystemEmailTemplateWithStatuses = {}
	) {
		const template: Api.ITemplate = {
			name: 'Untitled Newsletter',
			...(systemTemplate.template || {}),
			scope: Api.TemplateScope.Industry,
			templateType: Api.TemplateType.HtmlNewsletter,
		};
		const sysTemplate: AdminModels.ISystemEmailTemplateWithStatuses = {
			...systemTemplate,
			statuses: IndustryHtmlNewsletterViewModel.getDefaultTemplateStatuses(),
			template: {
				...HtmlNewsletterViewModel.createDefaultBlankTemplateModel(template),
				...template,
			},
		};
		const result = new IndustryHtmlNewsletterViewModel(userSession, sysTemplate);

		await result.create(sysTemplate, AdminModels.CampaignType.Email);
		return result;
	}

	constructor(userSession: AdminUserSessionContext, systemTemplate?: AdminModels.ISystemEmailTemplateWithStatuses) {
		super(userSession, systemTemplate?.template);
		this.files = new Api.AttachmentsViewModel(userSession);
	}

	@computed
	public get name() {
		return this.mTemplate.name;
	}

	@computed
	public get industries() {
		return this.statuses.map(x => x.industry);
	}

	@computed
	public get design() {
		return this.template?.content ? JSON.parse(this.template.content.source) : null;
	}

	@computed
	public get canEdit() {
		return !!(this.mUserSession as AdminUserSessionContext).permissions[AdminPermission.ManageTemplates];
	}

	public save = async (template: Api.ITemplate) => {
		await this.edit(
			{
				...(this.mTemplate || {}),
				...template,
			},
			[],
			[],
			[]
		);
		return this.mTemplate;
	};

	public saveAsNewTemplate = async (template: Api.ITemplate, statuses = this.mStatuses): Promise<Api.ITemplate> => {
		const draftStatuses = statuses
			? statuses.map(x => {
					const templateStatus = { ...x };
					templateStatus.status = Api.TemplateStatus.Draft;
					return templateStatus;
				})
			: IndustryHtmlNewsletterViewModel.getDefaultTemplateStatuses();
		await this.create({
			statuses: draftStatuses,
			template,
		});
		return this.mTemplate;
	};

	public sendTestEmail = () => {
		return HtmlNewsletterViewModel.prototype.sendTestEmail.call(this);
	};

	public setScope = async (): Promise<void> => {
		throw Api.asApiError('Not implemented');
	};

	public updateContent = async (design: Design, htmlStringValue: string): Promise<Api.ITemplate> => {
		const content: Api.IRawRichTextContentState = {
			document: htmlStringValue,
			documentVersion: 1,
			source: JSON.stringify(design),
			sourceFormat: Api.ContentSourceFormat.UnlayerHtmlNewsletter,
		};
		await this.edit(
			{
				...this.mTemplate,
				content,
			},
			[],
			[],
			[]
		);
		return this.mTemplate;
	};

	public updateName = async (name: string): Promise<Api.ITemplate> => {
		await this.edit(
			{
				...this.mTemplate,
				name: name.trim(),
			},
			[],
			[],
			[]
		);

		return this.mTemplate;
	};
}

export abstract class IndustryTemplateViewModel<
	TTemplateModel extends Api.IBaseResourceModel = Api.IBaseResourceModel,
	TSystemTemplate extends
		AdminModels.IBaseSystemTemplate<TTemplateModel> = AdminModels.IBaseSystemTemplate<TTemplateModel>,
> extends Api.ViewModel {
	@observable.ref protected mModel: TSystemTemplate;
	@observable.ref protected mIndustries: Api.Industry[];

	constructor(userSession: AdminUserSessionContext, template: TSystemTemplate, industries?: Api.Industry[]) {
		super(userSession);
		this.mIndustries = industries || (Object.keys(template?.industries || {}) as Api.Industry[]);
		this.mSetTemplate = this.mSetTemplate.bind(this);
		this.mSetTemplate(template);
	}

	@computed
	public get isBusy() {
		return this.busy || this.loading;
	}

	@computed
	// eslint-disable-next-line @typescript-eslint/class-literal-property-style
	public get name() {
		return '';
	}

	@computed
	public get id() {
		return this.mModel?.template.id;
	}

	@computed
	public get creationDate() {
		return this.mModel?.template.creationDate;
	}

	@computed
	public get lastModifiedDate() {
		return this.mModel?.template.lastModifiedDate;
	}

	@computed
	public get industries() {
		return this.mIndustries;
	}

	@computed
	// eslint-disable-next-line @typescript-eslint/class-literal-property-style
	public get canDelete() {
		return true;
	}

	@action
	public setSystemTemplate = (template: TSystemTemplate) => {
		this.mSetTemplate(template);
	};

	@action
	public update = (template: TTemplateModel) => {
		return this.executeRequest(template, 'PUT');
	};

	@action
	public delete = () => {
		return this.executeRequest(null, 'DELETE');
	};

	@action
	public archive = (industry: Api.Industry) => {
		return this.executeRequest(this.mModel?.template, 'PUT', 'archive', {
			industry,
		});
	};

	@action
	public publish = (industry: Api.Industry) => {
		return this.executeRequest(this.mModel?.template, 'PUT', 'publish', {
			industry,
		});
	};

	@action
	public duplicate = (name?: string) => {
		return this.executeRequest(this.mModel?.template, 'POST', 'clone', name ? { name } : undefined, false);
	};

	@action
	public load() {
		return this.executeRequest(null, 'GET');
	}

	public getStatusForIndustry = (industry: Api.Industry) => {
		return this.mModel?.industries ? this.mModel.industries[industry] : null;
	};

	public toJs = () => {
		return this.mModel;
	};

	public toTemplate = () => {
		return this.mModel?.template;
	};

	protected abstract getBaseApiPath(method: Api.HTTPMethod): string;

	protected executeRequest = (
		template: TTemplateModel,
		method: Api.HTTPMethod,
		path?: string,
		params?: Api.IDictionary<string>,
		updateModelWithResult = true
	) => {
		if (!this.isBusy) {
			this.busy = true;
			const promise = new Promise<TSystemTemplate>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<TSystemTemplate>) => {
					this.busy = false;
					if (opResult.success) {
						if (method !== 'DELETE') {
							if (updateModelWithResult) {
								this.mSetTemplate(opResult.value);
								resolve(this.mModel);
								return;
							}
						}
						resolve(opResult.value);
					} else {
						reject(opResult);
					}
				});
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<TSystemTemplate>(
					`${this.getBaseApiPath(method)}/${this.id}${path ? `/${path}` : ''}${
						params ? `?${toQueryString(params)}` : ''
					}`,
					method,
					method !== 'PUT' ? null : template,
					onFinish,
					onFinish
				);
			});
			return promise;
		}
	};

	protected mSetTemplate(templateModel: TSystemTemplate) {
		this.mModel = templateModel;
		this.mIndustries = templateModel?.industries
			? (Object.keys(templateModel?.industries || {}) as Api.Industry[])
			: [];
	}
}

export class IndustryAutomationTemplateViewModel extends IndustryTemplateViewModel<
	Api.IAutomationTemplate,
	AdminModels.ISystemAutomationTemplate
> {
	public static Create = async (
		userSession: AdminUserSessionContext,
		name?: string,
		trigger?: Api.IAutomationTrigger
	) => {
		const createOpResult = await userSession.webServiceHelper.callWebServiceAsync<Api.IAutomationTemplate>(
			`automationTemplate?name=${encodeURIComponent(
				name || Api.VmUtils.Automations.getDefaultNameForNewAutomationTemplate(trigger)
			)}`,
			'POST'
		);

		if (!createOpResult.success) {
			throw createOpResult;
		}

		let templateModel = createOpResult.value;

		// add the trigger
		if (trigger) {
			const template = new Api.AutomationTemplateViewModel(userSession, createOpResult.value);
			try {
				await template.addTrigger(trigger);
				templateModel = template.toJs();
			} catch (err) {
				try {
					await template.delete();
				} catch (delErr) {
					// eat this
				}
				throw err;
			}
		}

		return IndustryAutomationTemplateViewModel.fromAutomationTemplate(userSession, templateModel);
	};

	public static fromAutomationTemplate = async (
		userSession: AdminUserSessionContext,
		template: Api.IAutomationTemplate | Api.AutomationTemplateViewModel
	) => {
		if (template) {
			const addToAllIndustriesOpResult =
				await userSession.webServiceHelper.callWebServiceAsync<AdminModels.ISystemAutomationTemplate>(
					`admin/AutomationTemplate/${template.id}/Industry`,
					'PUT',
					DefaultSupportedIndustries
				);
			if (!addToAllIndustriesOpResult.success) {
				throw addToAllIndustriesOpResult;
			}

			return new IndustryAutomationTemplateViewModel(userSession, addToAllIndustriesOpResult.value);
		}
	};

	@observable.ref
	protected mAutomationTemplate: AdminAutomationTemplateViewModel;

	@computed
	public get id() {
		return this.mModel?.template?.id;
	}

	@computed
	public get isLoaded() {
		return !!this.mModel?.template?.creationDate;
	}

	@computed
	public get name() {
		return this.mAutomationTemplate?.name;
	}

	@computed
	public get automationTemplate() {
		return this.mAutomationTemplate;
	}

	@computed
	public get canDelete() {
		return !!this.industries?.every(x => this.mModel?.industries[x] === Api.TemplateStatus.Draft);
	}

	@action
	public publish = async (industry: Api.Industry) => {
		await this.mEnsurePublishedAutomationTemplate();
		return this.executeRequest(null, 'PUT', 'publish', { industry });
	};

	@action
	public archive = async (industry: Api.Industry) => {
		await this.mEnsurePublishedAutomationTemplate();
		return this.executeRequest(null, 'PUT', 'archive', { industry });
	};

	@action
	public setIndustries = async (industries: Api.Industry[], status?: Api.TemplateStatus) => {
		if (this.isBusy) {
			return null;
		}

		this.busy = true;
		if (status === Api.TemplateStatus.Published) {
			await this.mEnsurePublishedAutomationTemplate();
		}
		const promise = new Promise<Api.IOperationResult<AdminModels.ISystemAutomationTemplate>>((resolve, reject) => {
			const onFinish = action((opResult: Api.IOperationResult<AdminModels.ISystemAutomationTemplate>) => {
				this.busy = false;
				if (opResult.success) {
					this.mSetTemplate(opResult.value);
					resolve(opResult);
				} else {
					reject(opResult);
				}
			});
			this.userSession.webServiceHelper.callWebServiceWithOperationResults<AdminModels.ISystemAutomationTemplate>(
				`${this.getBaseApiPath('PUT')}/${this.id}/Industry${status ? `?status=${encodeURIComponent(status)}` : ''}`,
				'PUT',
				industries,
				onFinish,
				onFinish
			);
		});
		return promise;
	};

	protected getBaseApiPath(method: Api.HTTPMethod) {
		if (method === 'DELETE') {
			return 'AutomationTemplate';
		}
		return 'admin/AutomationTemplate';
	}

	protected mSetTemplate(systemTemplate: AdminModels.ISystemAutomationTemplate) {
		super.mSetTemplate(systemTemplate);
		this.mAutomationTemplate = new AdminAutomationTemplateViewModel(
			this.userSession as AdminUserSessionContext,
			systemTemplate.template
		);
	}

	protected mEnsurePublishedAutomationTemplate = (): Promise<Api.IOperationResultNoValue> => {
		if (this.mAutomationTemplate?.status === Api.TemplateStatus.Draft || this.mAutomationTemplate?.hasDraftVersion) {
			return this.mAutomationTemplate.commitDraft();
		}

		return Promise.resolve({ success: true, systemCode: 200 });
	};
}

export class AdminAutomationTemplatesViewModel extends Api.AutomationTemplatesViewModel<AdminUserSessionContext> {
	@computed
	public get canCreate() {
		return this.userSession.hasPermission(AdminPermission.CreateAutomationTemplateOnBehalf);
	}

	protected composeApiUrl({ queryParams, urlPath }: { urlPath: string; queryParams?: Api.IDictionary<any> }) {
		let targetUrlPath = urlPath;

		// rewrite url
		if (urlPath?.toLocaleLowerCase() === 'automationtemplate/trigger') {
			targetUrlPath = 'admin/automationTemplate/trigger';
		}

		return super.composeApiUrl({ queryParams, urlPath: targetUrlPath });
	}
}

export class AdminAutomationTemplateViewModel extends Api.AutomationTemplateViewModel<AdminUserSessionContext> {
	@computed
	public get canEdit() {
		return (
			this.mModel?.status !== Api.TemplateStatus.Archived &&
			(this.mUserSession as AdminUserSessionContext).hasPermission(AdminPermission.ManageTemplates)
		);
	}
}

export abstract class IndustryTemplatesViewModel<
	TTemplate = any,
	TSystemTemplate extends AdminModels.IBaseSystemTemplate<TTemplate> = AdminModels.IBaseSystemTemplate<TTemplate>,
	TTemplateViewModel extends IndustryTemplateViewModel<TTemplate, TSystemTemplate> = IndustryTemplateViewModel<
		TTemplate,
		TSystemTemplate
	>,
> extends Api.ViewModel {
	@observable.ref protected mLoadingPromise: Promise<TSystemTemplate[]>;
	protected mArchived: Api.ObservableCollection<TTemplateViewModel>;
	protected mDrafts: Api.ObservableCollection<TTemplateViewModel>;
	protected mPublished: Api.ObservableCollection<TTemplateViewModel>;
	public readonly industry: Api.Industry;

	constructor(userSession: AdminUserSessionContext, industry: Api.Industry) {
		super(userSession);
		this.industry = industry;
		this.mArchived = new Api.ObservableCollection(null, 'id');
		this.mDrafts = new Api.ObservableCollection(null, 'id');
		this.mPublished = new Api.ObservableCollection(null, 'id');
	}

	@computed
	public get drafts() {
		return this.mDrafts;
	}

	@computed
	public get published() {
		return this.mPublished;
	}

	@computed
	public get archived() {
		return this.mArchived;
	}

	@computed
	public get isBusy() {
		return this.busy || this.loading || !!this.mLoadingPromise;
	}

	@computed
	public get isLoading() {
		return this.loading || !!this.mLoadingPromise;
	}

	@action
	public load() {
		if (!this.mLoadingPromise) {
			this.mLoadingPromise = new Promise<TSystemTemplate[]>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<TSystemTemplate[]>) => {
					this.mLoadingPromise = null;
					if (opResult.success) {
						this.mArchived.clear();
						this.mDrafts.clear();
						this.mPublished.clear();

						// sort
						opResult.value.forEach(x => {
							const status = x.industries[this.industry];
							const industriesList = Object.keys(x.industries) as Api.Industry[];
							if (status) {
								switch (status) {
									case Api.TemplateStatus.Draft: {
										this.mDrafts.add(this.createIndustryTemplate(x, industriesList) as any);
										break;
									}
									case Api.TemplateStatus.Archived: {
										this.mArchived.add(this.createIndustryTemplate(x, industriesList) as any);
										break;
									}
									case Api.TemplateStatus.Published: {
										this.mPublished.add(this.createIndustryTemplate(x, industriesList) as any);
										break;
									}
									default: {
										break;
									}
								}
							}
						});
						resolve(opResult.value);
					} else {
						reject(opResult);
					}
				});
				this.userSession.webServiceHelper.callWebServiceWithOperationResults<TSystemTemplate[]>(
					`${this.getBaseApiPath('GET')}/industry/${this.industry}`,
					'GET',
					null,
					onFinish,
					onFinish
				);
			});
		}
		return this.mLoadingPromise;
	}

	@action
	public editTemplate = (template: TTemplateViewModel, templateModel: TTemplate) => {
		if (!this.isBusy) {
			const promise = template.update(templateModel);
			if (promise) {
				this.busy = true;
				promise
					.then(() => {
						this.busy = false;
					})
					.catch(() => {
						this.busy = false;
					});
			}
			return promise;
		}
	};

	@action
	public deleteTemplate = (template: TTemplateViewModel) => {
		if (!this.isBusy) {
			const promise = template.delete();
			if (promise) {
				this.busy = true;
				promise
					.then(() => {
						runInAction(() => {
							this.busy = false;
							const itemsToRemove = [template];
							this.mDrafts.removeItems(itemsToRemove);
							this.mPublished.removeItems(itemsToRemove);
							this.mArchived.removeItems(itemsToRemove);
						});
					})
					.catch(() => {
						this.busy = false;
					});
			}
			return promise;
		}
	};

	@action
	public archiveTemplate = (template: TTemplateViewModel, industry: Api.Industry) => {
		if (!this.isBusy) {
			const promise = template.archive(industry);
			if (promise) {
				this.busy = true;
				promise
					.then(() => {
						runInAction(() => {
							this.busy = false;
							if (this.mDrafts.has(template) || this.mPublished.has(template)) {
								const itemsToRemove = [template];
								this.mDrafts.removeItems(itemsToRemove);
								this.mPublished.removeItems(itemsToRemove);
								this.mArchived.add(template);
							}
						});
					})
					.catch(() => {
						this.busy = false;
					});
			}
			return promise;
		}
	};

	@action
	public publishTemplate = (template: TTemplateViewModel, industry: Api.Industry) => {
		if (!this.isBusy) {
			const promise = template.publish(industry);
			if (promise) {
				this.busy = true;
				promise
					.then(() => {
						runInAction(() => {
							this.busy = false;
							if (this.mDrafts.has(template) || this.mArchived.has(template)) {
								const itemsToRemove = [template];
								this.mDrafts.removeItems(itemsToRemove);
								this.mArchived.removeItems(itemsToRemove);
								this.mPublished.add(template);
							}
						});
					})
					.catch(() => {
						this.busy = false;
					});
			}
			return promise;
		}
	};

	@action
	public reorderPublishedTemplate = (template: TTemplateViewModel, industry: Api.Industry, toIndex: number) => {
		if (!template && !this.mPublished.has(template)) {
			return null;
		}
		const fromIndex = this.mPublished.indexOf(template);
		if (fromIndex === toIndex) {
			return null;
		}
		if (!this.isBusy) {
			const promise = new Promise<Api.IOperationResultNoValue>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<Api.IOperationResultNoValue>) => {
					this.busy = false;
					if (opResult.success) {
						resolve(opResult);
					} else {
						// undo
						runInAction(() => {
							this.mPublished.splice(toIndex, 1);
							this.mPublished.splice(fromIndex, 0, template);
							reject(opResult);
						});
					}
				});

				runInAction(() => {
					this.mPublished.splice(fromIndex, 1);
					this.mPublished.splice(toIndex, 0, template);

					const ids = this.mPublished.map(x => x.id);
					this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IOperationResultNoValue>(
						`${this.getBaseApiPath('PUT')}/industry/${industry}`,
						'PUT',
						ids,
						onFinish,
						onFinish
					);
				});
			});
			return promise;
		}
	};

	@action
	public reset = () => {
		this.busy = false;
		this.mArchived.clear();
		this.mDrafts.clear();
		this.mPublished.clear();
		this.mLoadingPromise = null;
	};

	protected abstract getBaseApiPath(method: Api.HTTPMethod): string;
	protected abstract createIndustryTemplate(
		systemTemplate: TSystemTemplate,
		industries?: Api.Industry[]
	): IndustryTemplateViewModel;
}

export class IndustryAutomationTemplatesViewModel extends IndustryTemplatesViewModel<
	Api.IAutomationTemplate,
	AdminModels.ISystemAutomationTemplate,
	IndustryAutomationTemplateViewModel
> {
	@action
	public duplicateTemplate = (template: IndustryAutomationTemplateViewModel, name?: string) => {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<IndustryAutomationTemplateViewModel>((resolve, reject) => {
				template
					.duplicate(name)
					.then(model => {
						const clone = this.createIndustryTemplate(model);
						runInAction(() => {
							this.busy = false;
							this.mDrafts.add(clone);
							resolve(clone);
						});
					})
					.catch(error => {
						this.busy = false;
						reject(error);
					});
			});
		}
	};

	protected getBaseApiPath() {
		return 'admin/AutomationTemplate';
	}

	// FOLLOWUP: Resolve
	// @ts-ignore
	protected createIndustryTemplate(systemTemplate: AdminModels.ISystemAutomationTemplate, industries?: Api.Industry[]) {
		return new IndustryAutomationTemplateViewModel(
			this.mUserSession as AdminUserSessionContext,
			systemTemplate,
			industries
		);
	}
}

export class AccountSnapshotsViewModel extends Api.ViewModel {
	@observable.ref
	protected mAccountSnapshotsByMonth: AccountSnapshotViewModel[];
	@observable.ref protected mAccountSnapshotsByMonthPromise: Promise<
		Api.IOperationResult<AdminModels.IAccountSnapshot[]>
	>;
	@observable.ref
	protected mAccountSnapshotsSummaryByMonth: AdminModels.IMonthlyRecurringRevenueSummary;
	@observable.ref protected mAccountSnapshotsSummaryByMonthPromise: Promise<
		Api.IOperationResult<AdminModels.IMonthlyRecurringRevenueSummary>
	>;
	@observable.ref protected mAccountSnapshotDeletePromise: Promise<Api.IOperationResult<void>>;
	@observable.ref protected mFetchedAny: boolean;

	constructor(userSession: Api.UserSessionContext) {
		super(userSession);
		this.mFetchedAny = false;
	}

	/** @param yearMonth In the format of YYYY-MM */
	@action
	public loadAccountSnapshotsByMonth = (filter: IAccountFilterCriteria) => {
		if (!this.mAccountSnapshotsByMonthPromise) {
			this.mAccountSnapshotsByMonthPromise = new Promise<Api.IOperationResult<AdminModels.IAccountSnapshot[]>>(
				(resolve, reject) => {
					const onFinish = (opResult: Api.IOperationResult<AdminModels.IAccountSnapshot[]>) => {
						runInAction(() => {
							if (opResult.success) {
								this.mAccountSnapshotsByMonth = this.transform(opResult.value);
								this.mAccountSnapshotsByMonthPromise = null;
								this.mFetchedAny = true;
								resolve(opResult);
							} else {
								this.mAccountSnapshotsByMonthPromise = null;
								this.mFetchedAny = true;
								reject(opResult);
							}
						});
					};
					this.userSession.webServiceHelper.callWebServiceWithOperationResults<AdminModels.IAccountSnapshot[]>(
						`admin/AccountSnapshot/byMonth`,
						'POST',
						filter,
						onFinish,
						onFinish
					);
				}
			);
		}
		return this.mAccountSnapshotsByMonthPromise;
	};

	@action
	public loadAccountSnapshotSummaryByMonth = (filter: IAccountFilterCriteria) => {
		if (!this.mAccountSnapshotsSummaryByMonthPromise) {
			this.mAccountSnapshotsSummaryByMonthPromise = new Promise<
				Api.IOperationResult<AdminModels.IMonthlyRecurringRevenueSummary>
			>((resolve, reject) => {
				const onFinish = (opResult: Api.IOperationResult<AdminModels.IMonthlyRecurringRevenueSummary>) => {
					runInAction(() => {
						if (opResult.success) {
							this.mAccountSnapshotsSummaryByMonth = opResult.value;
							this.mAccountSnapshotsSummaryByMonthPromise = null;
							resolve(opResult);
						} else {
							this.mAccountSnapshotsSummaryByMonthPromise = null;
							reject(opResult);
						}
					});
				};
				this.userSession.webServiceHelper.callWebServiceWithOperationResults<AdminModels.IMonthlyRecurringRevenueSummary>(
					`admin/AccountSnapshot/byMonth/renewalSummary`,
					'POST',
					filter,
					onFinish,
					onFinish
				);
			});
		}
		return this.mAccountSnapshotsSummaryByMonthPromise;
	};

	@action
	public deleteSnapshot = (snapshotId: string) => {
		if (!this.mAccountSnapshotDeletePromise) {
			this.mAccountSnapshotDeletePromise = new Promise<Api.IOperationResult<void>>((resolve, reject) => {
				const onFinish = (opResult: Api.IOperationResult<void>) => {
					runInAction(() => {
						if (opResult.success) {
							this.mAccountSnapshotsByMonth = this.mAccountSnapshotsByMonth.filter(x => x.id !== snapshotId);
							this.mAccountSnapshotDeletePromise = null;
							resolve(opResult);
						} else {
							this.mAccountSnapshotDeletePromise = null;
							reject(opResult);
						}
					});
				};
				this.userSession.webServiceHelper.callWebServiceWithOperationResults<void>(
					`admin/AccountSnapshot/${snapshotId}`,
					'DELETE',
					null,
					onFinish,
					onFinish
				);
			});
		}
		return this.mAccountSnapshotDeletePromise;
	};

	@computed
	public get isLoading() {
		return !!this.mAccountSnapshotsByMonthPromise || !!this.mAccountSnapshotsSummaryByMonthPromise;
	}

	@computed
	public get accountSnapshots() {
		return this.mAccountSnapshotsByMonth;
	}

	@computed
	public get summary() {
		return this.mAccountSnapshotsSummaryByMonth;
	}

	@computed
	public get fetchedFirstPage() {
		return this.mFetchedAny;
	}

	@computed
	public get cohortChurn() {
		return '$' + (this.mAccountSnapshotsSummaryByMonth?.projectedChurn ?? '??');
	}

	@computed
	public get chargifyChurn() {
		return '$' + (this.mAccountSnapshotsSummaryByMonth?.actualChurn ?? '??');
	}

	@computed
	public get actualPercentage() {
		return this.mAccountSnapshotsSummaryByMonth?.totalMRR > 0
			? (
					(1 - this.mAccountSnapshotsSummaryByMonth?.actualChurn / this.mAccountSnapshotsSummaryByMonth?.totalMRR) *
					100
				).toFixed(0) + '%'
			: '0%';
	}

	@computed
	public get total() {
		return '$' + (this.mAccountSnapshotsSummaryByMonth?.totalMRR ?? '??');
	}

	private transform(snapshots: AdminModels.IAccountSnapshot[]) {
		return snapshots.map(x => new AccountSnapshotViewModel(this.userSession, x));
	}
}

export class AccountSnapshotViewModel extends Api.ViewModel {
	@observable.ref protected mAccountSnapshot: AdminModels.IAccountSnapshot;
	@observable.ref protected mAccountSnapshotsRenewalPromise: Promise<
		Api.IOperationResult<AdminModels.IAccountSnapshot>
	>;
	@observable.ref protected mAccountSnapshotsRenewalDiscountPromise: Promise<
		Api.IOperationResult<AdminModels.IAccountSnapshot>
	>;

	constructor(userSession: Api.UserSessionContext, snapshot: AdminModels.IAccountSnapshot) {
		super(userSession);
		this.mAccountSnapshot = snapshot;
	}

	@action
	public updateRenewalProbability = (renewalProbability: number) => {
		if (!this.mAccountSnapshotsRenewalPromise) {
			this.mAccountSnapshotsRenewalPromise = new Promise<Api.IOperationResult<AdminModels.IAccountSnapshot>>(
				(resolve, reject) => {
					const onFinish = (opResult: Api.IOperationResult<AdminModels.IAccountSnapshot>) => {
						runInAction(() => {
							if (opResult.success) {
								this.mAccountSnapshot = opResult.value;
								this.mAccountSnapshotsRenewalPromise = null;
								resolve(opResult);
							} else {
								this.mAccountSnapshotsRenewalPromise = null;
								reject(opResult);
							}
						});
					};
					this.userSession.webServiceHelper.callWebServiceWithOperationResults<AdminModels.IAccountSnapshot>(
						`admin/accountSnapshot/${this.mAccountSnapshot.id}/renewalProbability?renewalProbability=${renewalProbability}`,
						'PATCH',
						null,
						onFinish,
						onFinish
					);
				}
			);
		}
		return this.mAccountSnapshotsRenewalPromise;
	};

	@action
	public updateRenewalDiscountPercentage = (renewalDiscountPercentage: number) => {
		if (!this.mAccountSnapshotsRenewalDiscountPromise) {
			this.mAccountSnapshotsRenewalDiscountPromise = new Promise<Api.IOperationResult<AdminModels.IAccountSnapshot>>(
				(resolve, reject) => {
					const onFinish = (opResult: Api.IOperationResult<AdminModels.IAccountSnapshot>) => {
						runInAction(() => {
							if (opResult.success) {
								this.mAccountSnapshot = opResult.value;
								this.mAccountSnapshotsRenewalDiscountPromise = null;
								resolve(opResult);
							} else {
								this.mAccountSnapshotsRenewalDiscountPromise = null;
								reject(opResult);
							}
						});
					};
					this.userSession.webServiceHelper.callWebServiceWithOperationResults<AdminModels.IAccountSnapshot>(
						`admin/accountSnapshot/${this.mAccountSnapshot.id}/renewalDiscountPercentage?value=${renewalDiscountPercentage}`,
						'PATCH',
						null,
						onFinish,
						onFinish
					);
				}
			);
		}
		return this.mAccountSnapshotsRenewalDiscountPromise;
	};

	@computed
	public get isLoading() {
		return !!this.mAccountSnapshotsRenewalPromise || !!this.mAccountSnapshotsRenewalDiscountPromise;
	}

	@computed
	public get canEdit() {
		const session = this.userSession as AdminUserSessionContext;
		if (session?.hasPermission(AdminPermission.OnlyForDevelopers)) {
			return true;
		}

		const renewal = moment(this.mAccountSnapshot?.account?.planDetails?.renewalDate);
		return renewal.isValid() ? moment().diff(renewal, 'days') <= 30 : false;
	}

	public toJs() {
		return this.mAccountSnapshot;
	}

	@computed
	public get accountModel() {
		return this.mAccountSnapshot?.account;
	}

	@computed
	public get id() {
		return this.mAccountSnapshot?.id;
	}

	@computed
	public get accountDeleted() {
		return this.mAccountSnapshot?.accountDeleted;
	}

	@computed
	public get accountCancelled() {
		return this.mAccountSnapshot?.accountCancelled;
	}

	@computed
	public get pendingCancellation() {
		return this.mAccountSnapshot?.pendingCancellation;
	}

	@computed
	public get updatedRenewalProbability() {
		return this.mAccountSnapshot?.updatedRenewalProbability;
	}

	@computed
	public get updatedRenewalDiscountPercentage() {
		return this.mAccountSnapshot?.updatedRenewalDiscountPercentage;
	}

	@computed
	public get updatedWillRenewPrediction() {
		return this.mAccountSnapshot.updatedWillRenewPrediction;
	}

	@computed
	public get currentSummaryNoteId() {
		return this.mAccountSnapshot?.currentSummaryNoteId;
	}

	@computed
	public get currentCsmNoteId() {
		return this.mAccountSnapshot?.currentCsmNoteId;
	}

	@computed
	public get renewalPdfSent() {
		return this.mAccountSnapshot?.renewalPdfSent;
	}
}

export class BulkEmailHistoricalReportViewModel extends Api.ViewModel {
	@observable.ref private mCampaign: Api.ICampaign;
	@observable.ref private mReport: AdminModels.IBulkEmailHistoricalReport;
	@observable.ref protected mReportPromise: Promise<Api.IOperationResult<AdminModels.IBulkEmailHistoricalReport>>;

	constructor(userSession: UserSessionContext, campaign: Api.ICampaign = null) {
		super(userSession);
		this.mCampaign = campaign;
	}

	@action
	public getBulkEmailReportForCampaign = () => {
		if (!this.mReportPromise && this.mCampaign) {
			this.mReportPromise = new Promise<Api.IOperationResult<AdminModels.IBulkEmailHistoricalReport>>(
				(resolve, reject) => {
					const onFinish = (opResult: Api.IOperationResult<AdminModels.IBulkEmailHistoricalReport>) => {
						runInAction(() => {
							if (opResult.success) {
								this.mReport = opResult.value;
								this.mReportPromise = null;
								resolve(opResult);
							} else {
								this.mReportPromise = null;
								reject(opResult);
							}
						});
					};
					this.userSession.webServiceHelper.callWebServiceWithOperationResults<AdminModels.IBulkEmailHistoricalReport>(
						`admin/bulkEmailHistorical/stats/${AdminModels.AggregationIdType.Campaign}/${this.mCampaign.id}?userId=${this.mCampaign.creator?.id}`,
						'GET',
						null,
						onFinish,
						onFinish
					);
				}
			);
		}
		return this.mReportPromise;
	};

	@action
	public getBulkEmailReport = (type: AdminModels.AggregationIdType, id: string, start: Date, end: Date) => {
		if (!this.mReportPromise) {
			this.mReportPromise = new Promise<Api.IOperationResult<AdminModels.IBulkEmailHistoricalReport>>(
				(resolve, reject) => {
					const onFinish = (opResult: Api.IOperationResult<AdminModels.IBulkEmailHistoricalReport>) => {
						runInAction(() => {
							if (opResult.success) {
								this.mReport = opResult.value;
								this.mReportPromise = null;
								resolve(opResult);
							} else {
								this.mReportPromise = null;
								reject(opResult);
							}
						});
					};
					this.userSession.webServiceHelper.callWebServiceWithOperationResults<AdminModels.IBulkEmailHistoricalReport>(
						`admin/bulkEmailHistorical/stats/${type}/${id}?start=${encodeURIComponent(
							start.toISOString()
						)}&end=${encodeURIComponent(end.toISOString())}`,
						'GET',
						null,
						onFinish,
						onFinish
					);
				}
			);
		}
		return this.mReportPromise;
	};

	public toJs = () => {
		return this.mReport;
	};

	@computed
	public get report() {
		return this.mReport;
	}

	@computed
	public get isLoading() {
		return !!this.mReportPromise;
	}

	@computed
	public get isBusy() {
		return !!this.mReportPromise;
	}

	@computed
	public get chartData(): AdminModels.IBulkEmailHistoryDataPoint[] {
		return (this.mReport?.blocks || []).map<AdminModels.IBulkEmailHistoryDataPoint>(x => {
			return {
				ErrorMessages: x.errorMessages,
				Failures: x.failures,
				Hour: moment(x.block).local().format('M-D-YY @ hA'),
				Successes: x.successes,
			};
		});
	}
}

export class AccountDetailsNoteViewModel extends Api.NoteViewModel {
	protected mAccountOperations: AccountOperationsViewModel;
	protected mNoteType: AdminModels.InternalMeetingNoteType;

	constructor(
		accountOperations: AccountOperationsViewModel,
		noteType: AdminModels.InternalMeetingNoteType,
		note?: Api.INote
	) {
		super(accountOperations.userSession, note);
		this.mAccountOperations = accountOperations;
		this.mNoteType = noteType;
	}

	@action
	public save = (note: Api.INote, newAttachments?: Api.AttachmentsToBeUploadedViewModel) => {
		if (this.saving || this.savePromise) {
			return;
		}

		this.saving = true;
		const promise = new Promise<Api.INote>((resolve, reject) => {
			const updateExistingNote = !!this.note && !!this.note.id;
			const noteToSave = { ...note };
			if (updateExistingNote) {
				noteToSave.id = this.note.id;
			}

			const generateFormDataOrNull = (request: any) => {
				let formData: FormData = null;
				if (newAttachments && newAttachments.count > 0) {
					formData = new FormData();
					formData.append('request', JSON.stringify(request));
					newAttachments.attachments.forEach(x => {
						if (!(x instanceof File) && (x as File).name) {
							// is a Blob that has been given a name
							// need to send name as additional arg
							formData.append('files', x, (x as File).name);
						} else {
							formData.append('files', x);
						}
					});
				}
				return formData;
			};

			const meetingContext = note?.context as Api.IMeetingContext;
			const generateMeetingPromise = () => {
				const meetingRequest = {
					durationInMinutes: meetingContext.durationInMinutes,
					meetingStatus: meetingContext.meetingStatus,
					meetingTags: meetingContext.meetingTags,
					meetingType: meetingContext.meetingType,
					metWith: this.mNoteType,
					note,
				};

				return this.mAccountOperations.addAccountMeetingNote(
					this.mAccountOperations.account,
					meetingRequest,
					generateFormDataOrNull(meetingRequest)
				);
			};
			const savePromise =
				meetingContext?.source === Api.RichContentContextSource.Meeting
					? generateMeetingPromise()
					: this.mAccountOperations.updateAccountNote(
							this.mAccountOperations.account,
							this.mNoteType,
							note,
							generateFormDataOrNull(noteToSave)
						);
			savePromise
				?.then(value => {
					runInAction(() => {
						this.saving = false;
						this.savePromise = null;
						this.mSetRichContent(value);
						resolve(value);
					});
				})
				?.catch((error: Api.IOperationResultNoValue) => {
					runInAction(() => {
						this.saving = false;
						this.savePromise = null;
						reject(error);
					});
				});
		});

		this.savePromise = promise;
		return promise;
	};
}

export class AutoDiscoverEmailViewModel extends Api.ViewModel {
	@observable public email: string;
	@observable public errorMessage = '';
	@observable.ref
	private mEmailProviderConfiguration: Api.IEmailProviderConfiguration;

	constructor(userSession: UserSessionContext, initialDomain?: string) {
		super(userSession);
		this.email = initialDomain ? `test@${initialDomain}` : '';
	}

	@computed
	public get emailProviderConfiguration() {
		return this.mEmailProviderConfiguration;
	}

	@action
	public loadSuggestedEmailProvider() {
		if (!this.busy) {
			this.busy = true;
			this.errorMessage = '';
			return new Promise<Api.IOperationResult<Api.IEmailProviderConfiguration>>((resolve, reject) => {
				const onFinish = (opResult: Api.IOperationResult<Api.IEmailProviderConfiguration>) => {
					runInAction(() => {
						if (opResult.success) {
							this.mEmailProviderConfiguration = opResult.value;
							this.busy = false;
							resolve(opResult);
						} else {
							this.busy = false;
							this.errorMessage = opResult.systemMessage;
							reject(opResult);
						}
					});
				};
				this.userSession.webServiceHelper.callWebServiceWithOperationResults<Api.IEmailProviderConfiguration>(
					`admin/account/suggestedEmailProvider?email=${this.email}`,
					'POST',
					{},
					onFinish,
					onFinish
				);
			});
		}
		return null;
	}
}

export class PhoneNumberInfoViewModel extends Api.ViewModel {
	@observable public phoneNumber: string;
	@observable public errorMessage = '';
	@observable.ref
	private mPhoneNumberInfo: Api.IPhoneNumberInfo;

	constructor(userSession: UserSessionContext, initialPhoneNumber?: string) {
		super(userSession);
		this.phoneNumber = initialPhoneNumber ? initialPhoneNumber : '';
	}

	@computed
	public get phoneNumberInfo() {
		return this.mPhoneNumberInfo;
	}

	@action
	public loadPhoneNumberInfo() {
		if (!this.busy) {
			this.busy = true;
			this.errorMessage = '';
			return new Promise<Api.IOperationResult<Api.IPhoneNumberInfo>>((resolve, reject) => {
				const onFinish = (opResult: Api.IOperationResult<Api.IPhoneNumberInfo>) => {
					runInAction(() => {
						if (opResult.success) {
							this.mPhoneNumberInfo = opResult.value;
							this.busy = false;
							resolve(opResult);
						} else {
							this.busy = false;
							this.errorMessage = opResult.systemMessage;
							reject(opResult);
						}
					});
				};
				this.userSession.webServiceHelper.callWebServiceWithOperationResults<Api.IPhoneNumberInfo>(
					`admin/phoneNumber/portability?phoneNumber=${this.phoneNumber}`,
					'POST',
					{},
					onFinish,
					onFinish
				);
			});
		}
		return null;
	}
}

export class ImpersonationContextViewModel implements Api.IImpersonationContext {
	@observable.ref public account: Api.IAccount;
	@observable.ref public user: Api.IUser;

	constructor(impersonationContext?: Api.IImpersonationContext) {
		this.update(impersonationContext);
	}

	@computed
	public get isValid() {
		return !!this.account?.id;
	}

	public update = (impersonationContext?: Api.IImpersonationContext) => {
		this.account = impersonationContext?.account || null;
		this.user = impersonationContext?.user || null;
		return this;
	};

	public updateUser = (user?: Api.IUser) => {
		return this.update({
			user,
			account: this.account,
		});
	};

	public applyImpersonation = (...brokers: Api.ImpersonationBroker[]) => {
		if (this.isValid && brokers) {
			brokers?.forEach(x => x && x.impersonate(this));
		}
	};

	@action
	public reset = () => {
		this.account = null;
		this.user = null;
		return this;
	};

	public toJs = (): Api.IImpersonationContext => {
		return {
			account: this.account instanceof Api.AccountViewModel ? this.account.toJs() : this.account,
			user: this.user instanceof Api.UserViewModel ? this.user.toJs() : this.user,
		};
	};
}

export class AdminEmailWorkloadViewModel extends EmailWorkloadViewModel {
	public getAccountWorkloadForDateRange = (startDate: Date, endDate: Date, timeZone?: string) => {
		return new Promise<Api.IOperationResult<Api.IDayLoad[]>>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<Api.IDayLoad[]>) => {
				if (opResult.success) {
					resolve(opResult);
				} else {
					reject(opResult);
				}
			};

			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IDayLoad[]>(
				this.composeApiUrl({
					queryParams: {
						endDate: endDate.toISOString(),
						startDate: startDate.toISOString(),
						timeZone,
					},
					urlPath: 'email/account/forecast',
				}),
				'GET',
				null,
				onFinish,
				onFinish
			);
		});
	};
}

export class QuickSightDashboardViewModel extends Api.ViewModel {
	@observable private dashboardId: string;
	@observable private mAccountId: string;
	@observable private mQueryParams: Record<string, string> = {};
	@observable private embedUrl: string;

	constructor(
		userSession: UserSessionContext,
		dashboardId: string,
		accountId?: string,
		params?: Record<string, string>
	) {
		super(userSession);
		this.dashboardId = dashboardId;
		this.mAccountId = accountId;
		this.mQueryParams = params || {};
	}

	public static SalesKPIsId = '522356de-2eaf-4528-bb4b-3d202a8e0368';
	public static SuccessKPIsId = '51e5d947-d3ea-4b67-8b5d-458e8eab927d';
	public static CustomerMapId = 'b9df82ab-92de-420a-b533-cc1e35d08d57';
	public static ActiveUseId = 'f6bce626-ec26-44ff-a819-b8f7df53ef58';
	public static AIDARealMagicBookedByOverviewId = '07171337-0fd3-4239-8053-059e3d6ae303';
	public static AIDARealMagicDialsId = 'b008789e-9bd9-4965-ac52-f5075eb1b160?#';
	public static AIDARealMagicProspectingKPIsId = '52d89621-092a-4b2a-8300-063a0dba8c3f';
	public static AIDARealMagicCloserKPIsId = '23224f29-42ed-45cb-8859-64c051bc8235';
	public static AIDARealMagicCircleOfMagicId = '89750f52-add8-44bb-bcb4-1bb4730cb376';
	public static AIDACustomersDialsId = 'aceb1a60-6517-4394-b2df-581eba076bbe';
	public static CSMAccountDetailsId = '495a82d7-a917-477b-a97e-a061a4c28fcb';
	public static BookOfBusinessHealthId = '3b2216fe-565e-4743-bd11-3596d2f8aa9b';
	public static MonthlyRenewalId = '02e771a5-2d62-48b8-b81e-6f34bd58c2a1';
	public static DonationsId = '75ea1064-b186-4444-935c-5b9d70d60d82'; // Unreferenced on FE

	@computed
	public get quickSightUrl() {
		return this.embedUrl;
	}

	@computed
	public get accountId() {
		return this.mAccountId;
	}

	@computed
	public get queryParams() {
		return this.mQueryParams || {};
	}

	@action
	public load = async () => {
		const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<string>(
			this.composeApiUrl({ urlPath: `admin/dashboard/quicksight/${this.dashboardId}` }),
			'GET'
		);

		if (opResult.success) {
			this.embedUrl = opResult.value;
		} else {
			throw Api.asApiError(opResult);
		}
	};

	@action
	public loadAida = async () => {
		this.embedUrl = await this.getExternalEmbedUrl();
	};

	@action
	public getExternalEmbedUrl = async () => {
		const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<string>(
			this.composeApiUrl({ urlPath: `aida/dashboard/quicksight/${this.dashboardId}` }),
			'GET'
		);

		if (opResult.success) {
			return opResult.value;
		} else {
			throw Api.asApiError(opResult);
		}
	};
}

export class GhostCalendarViewModel extends Api.ViewModel {
	@observable private mSuggestions: IContentCalendarSuggestion[];
	constructor(userSession: UserSessionContext) {
		super(userSession);
	}

	@computed
	public get suggestions() {
		return this.mSuggestions;
	}

	@action
	public templateIsSuggestion = (templateId: string) => {
		const isSuggestion = this.mSuggestions.find(x => x.templateReference.templateId === templateId);
		return isSuggestion;
	};

	@action
	public loadAllSuggestions = async (
		startDate: Date,
		endDate: Date,
		request: Api.IContentCalendarSuggestionRequest,
		campaignType: AdminModels.CampaignType
	) => {
		let suggestions: Api.IContentCalendarSuggestion[] = [];
		if (request.industries.length > 0 && request.subverticals.length > 0) {
			const monthKeys = Array.from(Array(moment(endDate).diff(startDate, 'months')).keys());
			const startMoment = moment(startDate).startOf('month');
			for (let i = 0; i < monthKeys.length; i++) {
				const nextMoment = i > 0 ? moment(startMoment).add(i, 'month').startOf('month') : startMoment;
				const results = await this.getByMonth(nextMoment.toDate(), request);
				if (results?.length) {
					suggestions = [...suggestions, ...results];
				}
			}
		}
		if (campaignType === AdminModels.CampaignType.Social) {
			suggestions = suggestions.filter(x => x.templateReference.templateType === Api.TemplateType.SocialMediaPost);
		} else if (campaignType === AdminModels.CampaignType.Blog) {
			suggestions = suggestions.filter(x => x.templateReference.templateType === Api.TemplateType.Blog);
		} else if (campaignType === AdminModels.CampaignType.Email) {
			suggestions = suggestions.filter(x => x.templateReference.templateType === Api.TemplateType.Email);
		}
		this.mSuggestions = suggestions;
	};

	@action
	private getByMonth = async (month: Date, request: Api.IContentCalendarSuggestionRequest) => {
		const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IContentCalendarSuggestion[]>(
			this.composeApiUrl({
				queryParams: {
					month: month.toISOString(),
				},
				urlPath: 'contentCalendarSuggestion/filter/admin',
			}),
			'POST',
			request
		);

		if (opResult.success) {
			return opResult.value;
		} else {
			throw Api.asApiError(opResult);
		}
	};
}

export class CreateGhostCalendarSuggestionViewModel extends Api.ViewModel {
	@observable public accountAges: Api.ContentCalendarSuggestionAccountAge[] = [];
	@observable public completeByDays = 30;
	@observable public expirationDate?: string;
	@observable public industries: string[] = [];
	@observable public sendAsap = true;
	@observable public startDate: moment.Moment;
	// @observable public expirationDate?: moment.Moment;
	@observable public subverticals: string[] = [];
	@observable public tags: string[] = [];
	@observable public targets: Api.IPostTarget[] = [];
	@observable public template: Api.ITemplate;
	@observable public templateReference: Api.ITemplateReference;
	@observable public suggestion: Api.IContentCalendarSuggestion;

	// Create new suggestion
	public static instanceForCreate = (
		userSession: UserSessionContext,
		template: Api.ITemplate,
		industries?: Api.Industry[],
		subverticals?: string[],
		accountAges?: Api.ContentCalendarSuggestionAccountAge[],
		date?: moment.Moment,
		targets?: Api.IPostTarget[]
	) => {
		const vm = new CreateGhostCalendarSuggestionViewModel(userSession);
		vm.template = template;
		vm.industries = industries || [];
		vm.subverticals = subverticals || [];
		vm.accountAges = accountAges || [];
		vm.targets = targets || [];
		vm.expirationDate = template?.schedule?.expirationDate ? template.schedule.expirationDate : null;
		vm.startDate = moment(date).set('hours', 9);
		return vm;
	};
	// Edit existing suggestion
	public static instanceForEdit = (userSession: UserSessionContext, suggestion: Api.IContentCalendarSuggestion) => {
		const vm = new CreateGhostCalendarSuggestionViewModel(userSession);
		vm.accountAges = suggestion.accountAges;
		vm.completeByDays = suggestion.minimumDurationInDays || 30;
		vm.expirationDate = suggestion.schedule.expirationDate;
		vm.industries = suggestion.industries.includes(ContentCalendarSuggestionAll)
			? DefaultSupportedIndustries
			: suggestion.industries;
		vm.startDate = moment(suggestion.schedule?.startDate);
		vm.subverticals = suggestion.subverticals.includes(ContentCalendarSuggestionAll) ? [] : suggestion.subverticals;
		vm.tags = suggestion.filter?.criteria?.length ? suggestion.filter.criteria.map(x => x.value) : [];
		vm.targets = mapSocialMediaTypesToPostTargets(suggestion.targets);
		vm.templateReference = suggestion.templateReference;
		vm.suggestion = suggestion;
		vm.sendAsap = !suggestion.minimumDurationInDays;
		return vm;
	};

	constructor(userSession: UserSessionContext) {
		super(userSession);
	}

	@computed
	public get isEditing() {
		return !!this.suggestion;
	}

	@computed
	public get canEdit() {
		return (
			!this.impersonationContext?.account ||
			(this.impersonationContext?.account &&
				(this.userSession?.user?.role === 'admin' || this.userSession?.user?.role === 'superAdmin'))
		);
	}

	@computed
	public get isAllIndustriesSelected() {
		return this.industries.length === GhostCalendarIndustryOptions.length;
	}

	@computed
	public get subverticalOptions() {
		if (this.industries?.length === 1) {
			return getSubverticalOptions(this.industries[0] as Api.Industry);
		}
		return [];
	}

	@computed
	public get templateContent() {
		if (this.template?.content) {
			return convertRawRichTextContentStateToRichContentEditorState(this.template?.content);
		}
		return null;
	}

	public toJs = () => {
		const industries =
			this.industries.length === 0 || this.industries.length === DefaultSupportedIndustries.length
				? [ContentCalendarSuggestionAll]
				: this.industries;
		const subverticals =
			this.subverticals.length === 0 || this.subverticals.length === this.subverticalOptions.length
				? [ContentCalendarSuggestionAll]
				: this.subverticals;
		let filter: Api.IContactFilterCriteria = {};
		if (this.tags?.length) {
			filter = {
				criteria: [
					{
						criteria: [
							...this.tags.map(value => {
								return {
									property: Api.ContactFilterCriteriaProperty.Tag,
									value,
								};
							}),
						],
						op: Api.FilterOperator.Or,
					},
					...DefaultBulkSendExcludedFilterCriteria,
				],
			};
		}
		const suggestion: Api.IContentCalendarSuggestion = {
			accountAges: this.accountAges,
			archived: false,
			filter,
			industries,
			minimumDurationInDays: this.sendAsap ? null : this.completeByDays,
			schedule: {
				expirationDate: this?.expirationDate?.toString(),
				startDate: this.startDate.toISOString(),
			},
			subverticals,
			targets: getProviderTypesFromPostTargest(this.targets),
			templateReference: {
				isCustomized: false,
				isSystemTemplate: true,
				name: this.template?.name,
				templateId: this.template?.id,
			},
		};
		return suggestion;
	};

	@action
	public create = () => this.crudRequest('POST', this.toJs());

	@action
	public update = () => this.crudRequest('PUT', { ...this.suggestion, ...this.toJs() });

	@action
	public delete = () => this.crudRequest('DELETE', this.suggestion);

	private crudRequest = (method: 'POST' | 'PUT' | 'DELETE', suggestion: Api.IContentCalendarSuggestion) => {
		this.busy = true;
		return new Promise<Api.IContentCalendarSuggestion>((resolve, reject) => {
			const onFinish = (result: Api.IOperationResult<Api.IContentCalendarSuggestion>) => {
				this.busy = false;
				if (result.success) {
					resolve(result.value);
				} else {
					reject(result);
				}
			};

			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IContentCalendarSuggestion>(
				this.composeApiUrl({ urlPath: `contentCalendarSuggestion${method === 'POST' ? '' : `/${suggestion.id}`}` }),
				method,
				suggestion,
				onFinish,
				onFinish
			);
		});
	};
}

export class AdminCoffeeSlotMachineViewModel extends Api.ViewModel {
	@observable.ref protected mAchievments: SlotMachineAchievementsViewModel;
	constructor(userSession: AdminUserSessionContext) {
		super(userSession);
		this.impersonate = this.impersonate.bind(this);
		this.mAchievments = new SlotMachineAchievementsViewModel(userSession);
	}

	@computed
	public get achievments() {
		return this.mAchievments?.fetchResults;
	}

	@action
	public load = async () => {
		if (this.loading) {
			return;
		}

		this.loading = true;
		await this.mAchievments.load();
		this.loading = false;
	};

	@action
	public grantSlotMachineSpin = async (
		user: AccountUserViewModel,
		achievement: SlotMachineAchievementViewModel,
		count = 1
	) => {
		const impContextBefore = this.mImpersonationContext;
		this.impersonate({ account: { id: user.accountId }, user });

		const opResults = await Promise.all(
			Array.from({ length: count }).map(() =>
				this.mUserSession.webServiceHelper.callWebServiceAsync(
					this.composeApiUrl({ urlPath: `achievement/user-achievement` }),
					'POST',
					achievement.configuration
				)
			)
		);

		this.impersonate(impContextBefore);

		const badResult = opResults.find(x => !x.success);
		if (badResult) {
			throw badResult;
		}
	};

	public impersonate(impersonationContext?: Api.IImpersonationContext) {
		super.impersonate(impersonationContext);
		this.mAchievments.impersonate(impersonationContext);
		return this;
	}
}
