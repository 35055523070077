import { brandPrimaryHover } from '../../../styles/colors';
import { ActionItemCheckboxSize } from '../ActionItemCheckbox/styles';
import { StyleSheet } from 'aphrodite';

const tintColorIndicatorWidth = 4;
const contentPadding = 20;

export const ActionItemCheckboxRightMargin = 20;

export const styleSheet = StyleSheet.create({
	assigneeIcon: {
		height: 15,
		width: 14,
	},
	body: {
		alignItems: 'flex-start',
		boxSizing: 'border-box',
		display: 'flex',
		padding: contentPadding,
	},
	bodyContent: {
		boxSizing: 'border-box',
		width: `calc(100% - ${ActionItemCheckboxSize + ActionItemCheckboxRightMargin}px)`,
	},
	checkbox: {
		marginRight: ActionItemCheckboxRightMargin,
		width: ActionItemCheckboxSize,
	},
	container: {
		':hover': {
			border: '1px solid #CAE0EC',
		},
		background: '#fff',
		border: '1px solid #F2F2F2',
		borderRadius: 3,
		boxShadow: '0 0 6px 0 rgba(139,139,139,0.10)',
		boxSizing: 'border-box',
		display: 'flex',
		overflow: 'hidden',
		textAlign: 'left',
	},
	content: {
		width: `calc(100% - ${tintColorIndicatorWidth}px)`,
	},
	dueDateIcon: {
		height: 17,
		width: 18,
	},
	footer: {
		borderTop: '1px solid #f2f2f2',
		boxSizing: 'border-box',
		padding: `${contentPadding / 2}px ${contentPadding}px`,
	},
	footerOption: {
		':not(:first-of-type)': {
			marginTop: 10,
		},
		alignItems: 'center',
		display: 'flex',
		fontSize: 12,
		justifyContent: 'space-between',
		overflow: 'hidden',
	},
	footerOptionIcon: {
		alignItems: 'center',
		display: 'flex',
		height: 20,
		justifyContent: 'center',
		width: 20,
	},
	footerOptionLeft: {
		alignItems: 'center',
		display: 'flex',
	},
	footerOptionLeftRemoveButton: {
		color: brandPrimaryHover,
		cursor: 'pointer',
		margin: -5,
		padding: 5,
	},
	footerOptionLeftTitle: {
		color: brandPrimaryHover,
		maxWidth: 400,
	},
	footerOptionLeftTitleCompact: {
		maxWidth: 200,
	},
	footerOptionLeftTitleLabel: {
		color: '#8E8E8E',
		marginLeft: 10,
	},
	header: {
		boxSizing: 'border-box',
		padding: `${contentPadding}px ${contentPadding}px 0 ${contentPadding}px`,
	},
	tintIndicator: {
		flexShrink: 0,
		transition: 'all 0.2s',
		width: tintColorIndicatorWidth,
	},
});
