import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

export const PostcardSpec = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<SvgIcon width='50' height='39' viewBox='0 0 50 39' fill='none' {...props}>
			<path fill='#fff' stroke='#D3DBDE' strokeWidth='2' d='M49 1.874v36H1v-36h48Z' />
			<path
				fill='#D3DBDE'
				d='M43.265 7.573a1 1 0 0 0-.793-1.17l-8.839-1.699a1 1 0 1 0-.377 1.964l7.856 1.51-1.51 7.856a1 1 0 0 0 1.965.377l1.698-8.838ZM7.198 30.307a1 1 0 0 0 .794 1.17l8.838 1.698a1 1 0 0 0 .377-1.964l-7.856-1.509 1.51-7.856a1 1 0 1 0-1.965-.378l-1.698 8.839Zm34.524-23.75L7.619 29.666l1.122 1.656 34.103-23.11-1.122-1.657Z'
			/>
		</SvgIcon>
	);
};
