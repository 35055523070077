import { css } from 'aphrodite';
import Picker, { IEmojiData, IEmojiPickerProps, SkinTones } from 'emoji-picker-react';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { PopoverAlign, PopoverPosition } from 'react-tiny-popover';
import { v4 as uuidgen } from 'uuid';
import { EmojiDefaultSkinToneKey } from '../../../../models';
import { PersistentStorageManager } from '../../../../models/Storage';
import { PopoverType, TinyPopover } from '../../TinyPopover';
import { SmileyIcon } from '../../svgs/icons/SmileyIcon';
import { styleSheet } from './styles';

interface IProps extends Partial<IEmojiPickerProps> {
	onRenderPopoverAnchorButton?(onClick: () => void): React.ReactNode;
	popoverAlign?: Exclude<PopoverAlign, 'custom'>;
	popoverDismissOnOutsideAction?: boolean;
	popoverPlacement?: Exclude<PopoverPosition, 'custom'>[];
	popoverType?: PopoverType;
}

const defaultPopoverPlacement: Exclude<PopoverPosition, 'custom'>[] = ['top'];

export const EmojiPickerButton: React.FC<IProps> = props => {
	const {
		onRenderPopoverAnchorButton,
		onEmojiClick: propsOnEmojiClicked,
		popoverAlign = 'start',
		popoverDismissOnOutsideAction = true,
		popoverPlacement,
		popoverType = PopoverType.white,
		...restProps
	} = props;
	const portalRootElementRef = React.useRef<HTMLElement>(document.createElement('div'));

	// root element of the popover content (only mounted when the popover is visible/open)
	const popoverContentElementRef = React.useRef<HTMLDivElement>(null);

	// static element in <body> that hides the portal and its content (the emoji picker) when the popover is closed
	const [hiddenRootElement] = React.useState<HTMLElement>(() => {
		// insert hidden div
		const div = document.createElement('div');
		div.setAttribute('class', css(styleSheet.emojiPopoverPortalRoot));
		div.setAttribute('id', uuidgen());
		document.body.appendChild(div);

		// starts out hidden
		div.appendChild(portalRootElementRef.current);

		return div;
	});

	const [showEmojiPicker, setShowEmojiPicker] = React.useState(false);
	const [emojiSkinTone, setEmojiSkinTone] = React.useState<SkinTones>(
		(PersistentStorageManager.local.get(EmojiDefaultSkinToneKey) as SkinTones) ?? null
	);

	const button = React.useMemo(() => {
		// allow the consumer to provide their own button... or render the default
		const externalButton = onRenderPopoverAnchorButton?.(() => setShowEmojiPicker(!showEmojiPicker));
		return (
			externalButton || (
				<button onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
					<SmileyIcon />
				</button>
			)
		);
	}, [onRenderPopoverAnchorButton, showEmojiPicker]);

	const onEmojiClicked = React.useCallback(
		(_: React.MouseEvent<Element, MouseEvent>, data: IEmojiData) => {
			propsOnEmojiClicked?.(_, data);
			setShowEmojiPicker(false);
			if (data.activeSkinTone !== emojiSkinTone && data.activeSkinTone !== undefined) {
				setEmojiSkinTone(data.activeSkinTone);
				PersistentStorageManager.local.set(EmojiDefaultSkinToneKey, data.activeSkinTone);
			}
		},
		[emojiSkinTone, propsOnEmojiClicked]
	);

	const picker = React.useMemo(() => {
		return <Picker skinTone={emojiSkinTone} {...restProps} onEmojiClick={onEmojiClicked} />;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [emojiSkinTone, onEmojiClicked, ...Object.values(restProps || {})]);

	React.useEffect(() => {
		return () => {
			if (hiddenRootElement) {
				document.body.removeChild(hiddenRootElement);
			}
		};
	}, [hiddenRootElement]);

	React.useEffect(() => {
		if (showEmojiPicker) {
			// move the portal root to the popover
			popoverContentElementRef.current?.appendChild(portalRootElementRef.current);
		} else {
			// move the portal root to the hidden div in <body>
			hiddenRootElement?.appendChild(portalRootElementRef.current);
		}
	}, [showEmojiPicker, hiddenRootElement]);

	return (
		<>
			{ReactDOM.createPortal(picker, portalRootElementRef.current)}
			<TinyPopover
				align={popoverAlign}
				anchor={button}
				dismissOnOutsideAction={popoverDismissOnOutsideAction}
				isOpen={showEmojiPicker}
				onRequestClose={() => setShowEmojiPicker(false)}
				placement={popoverPlacement || defaultPopoverPlacement}
				type={popoverType}
			>
				<div ref={popoverContentElementRef} />
			</TinyPopover>
		</>
	);
};
