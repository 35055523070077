import { StyleSheet } from 'aphrodite';

const popoverContentPadding = '20px';

export const styleSheet = StyleSheet.create({
	closeButton: {
		padding: 2,
	},
	header: {
		alignItems: 'center',
		borderBottom: '1px solid #F2F2F2',
		color: '#8E8E8E',
		display: 'flex',
		fontSize: '12px',
		height: 30,
		margin: `0 ${popoverContentPadding} 10px ${popoverContentPadding}`,
		padding: '5px 0',
	},
	headerTitle: {
		flexGrow: 1,
		margin: '0 10px',
	},
	leftAccessory: {
		alignItems: 'center',
		display: 'flex',
		flexGrow: 1,
	},
	pluginHeader: {
		margin: '0 5px 5px',
	},
});
