import { css } from 'aphrodite';
import * as React from 'react';
import Popover from 'react-popover';
import { brandSecondary, grayIconFill } from '../../styles/colors';
import { styleSheet } from './styles';
import './styles.less';

interface IProps<T = any> {
	animateDots?: boolean;
	className?: string;
	disabled?: boolean;
	menuButtonClassName?: string;
	menuItems: IMoreMenuItem<T>[];
	onMenuItemClicked?(menuItem: IMoreMenuItem<T>, e: React.MouseEvent<HTMLElement>): void;
}

interface IState {
	isOpen?: boolean;
	isHovering?: boolean;
	isPressed?: boolean;
}

export interface IMoreMenuItem<T = any> {
	key?: string;
	name: string;
	representedObject?: T;
}

export class DeprecatedMoreMenu extends React.PureComponent<IProps, IState> {
	public state: IState = {};
	private onTriggerMouseLeaveTimeout: any;
	// @ts-ignore
	private mounted: boolean;

	public componentDidMount() {
		this.mounted = true;
	}

	public componentWillUnmount() {
		this.mounted = false;
		this.clearTimout();
	}

	public render() {
		const { animateDots, menuItems, disabled } = this.props;
		const { isHovering, isOpen } = this.state;
		const content = (
			<div className='more-menu-content' onMouseEnter={this.onHover(true)} onMouseLeave={this.onHover(false)}>
				<ul>
					{menuItems
						.filter(x => !!x)
						.map((item, i) => {
							return (
								<li
									key={item.key ?? i}
									onClick={this.onMenuItemClicked(item)}
									onMouseDown={() => this.setState({ isPressed: true })}
									onMouseUp={() => this.setState({ isPressed: false })}
									tabIndex={0}
									role='button'
									aria-label={item.name}
									aria-pressed={this.state.isPressed}
									className='more-menu-content-item'
								>
									{item.name}
								</li>
							);
						})}
				</ul>
			</div>
		);
		return (
			<Popover
				className={`more-menu ${this.props.className || ''}`}
				body={content}
				preferPlace='below'
				isOpen={isOpen && !disabled}
				tipSize={0.1}
			>
				<button
					className={`more-menu-trigger ${this.props.menuButtonClassName || ''}`}
					disabled={disabled}
					tabIndex={0}
					onMouseEnter={this.onTargetHover(true)}
					onMouseLeave={this.onTargetHover(false)}
					onFocus={this.onTargetHover(true)}
					onBlur={this.onTargetHover(false)}
				>
					<svg xmlns='http://www.w3.org/2000/svg' width='18' height='12' viewBox='0 0 18 4'>
						<g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
							<g fill={`${isHovering ? brandSecondary : grayIconFill}`} className='more-menu-trigger-dots'>
								<path
									className={css(styleSheet.dot0, animateDots ? styleSheet.jump : null)}
									d='M4,2 C4,3.106 3.104,4 2,4 C0.895,4 0,3.106 0,2 C0,0.895 0.895,0 2,0 C3.104,0 4,0.895 4,2'
								/>
								<path
									className={css(styleSheet.dot1, animateDots ? styleSheet.jump : null)}
									d='M11,2 C11,3.106 10.104,4 9,4 C7.895,4 7,3.106 7,2 C7,0.895 7.895,0 9,0 C10.104,0 11,0.895 11,2'
								/>
								<path
									className={css(styleSheet.dot2, animateDots ? styleSheet.jump : null)}
									d='M18,2 C18,3.106 17.104,4 16,4 C14.895,4 14,3.106 14,2 C14,0.895 14.895,0 16,0 C17.104,0 18,0.895 18,2'
								/>
							</g>
						</g>
					</svg>
				</button>
			</Popover>
		);
	}

	private clearTimout = () => {
		if (this.onTriggerMouseLeaveTimeout) {
			clearTimeout(this.onTriggerMouseLeaveTimeout);
			this.onTriggerMouseLeaveTimeout = null;
		}
	};

	private onMenuItemClicked = (menuItem: IMoreMenuItem<any>) => (e: React.MouseEvent<HTMLElement>) => {
		this.clearTimout();
		this.setState({
			isHovering: false,
			isOpen: false,
		});
		if (this.props.onMenuItemClicked) {
			this.props.onMenuItemClicked(menuItem, e);
		}
	};

	private onHover = (hoverOn: boolean) => () => {
		this.clearTimout();
		const nextState: IState = { isHovering: hoverOn };
		if (this.state.isOpen && !hoverOn) {
			nextState.isOpen = false;
		}
		this.setState(nextState);
	};

	private onTargetHover = (hoverOn: boolean) => () => {
		if (!this.state.isOpen && hoverOn) {
			this.setState({ isOpen: true });
		} else {
			// delay the closing of the menu in case the user is moving from the trigger to the menu with the mouse.
			setTimeout(() => {
				if (!!this.mounted && this.state.isOpen && !this.state.isHovering && !hoverOn) {
					this.setState({
						isOpen: false,
					});
					this.clearTimout();
				}
			}, 75);
		}
	};
}
