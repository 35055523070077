import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fill?: string;
}

export const IntegrationIcon: React.FC<IProps> = ({ className, fill = '#fff' }) => {
	const height = 18;
	const width = 18;
	return (
		<SvgIcon
			className={`${className || ''} integration-icon`}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			width={width}
		>
			<path
				fill={fill}
				fillRule='evenodd'
				d='M6 6L13.586 6 11.293 8.293 12.707 9.707 16.707 5.707C17.098 5.316 17.098 4.684 16.707 4.293L12.707.293 11.293 1.707 13.586 4 6 4C2.691 4 0 6.691 0 10L2 10C2 7.794 3.794 6 6 6M18 10C18 12.206 16.206 14 14 14L6.414 14 8.707 11.707 7.293 10.293 3.293 14.293C2.902 14.684 2.902 15.316 3.293 15.707L7.293 19.707 8.707 18.293 6.414 16 14 16C17.309 16 20 13.309 20 10L18 10z'
			/>
		</SvgIcon>
	);
};
