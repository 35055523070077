import { brandPrimaryHover, grayIconFill } from '../../../../styles/colors';
import { CssSelectors, baseStyleSheet } from '../../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	andMore: {
		alignItems: 'center',
		color: grayIconFill,
		display: 'flex',
		fontSize: 14,
		fontStyle: 'italic',
		height: 32,
		paddingLeft: 50,
		...CssSelectors.allChildren(
			{
				fontSize: 14,
			},
			'span'
		),
	},
	boldItalic: {
		...baseStyleSheet.fontBold,
		fontSize: 16,
		fontStyle: 'italic',
	},
	container: {},
	highlight: {
		background: 'rgba(232, 243, 249, 0.5)',
	},
	title: {
		color: brandPrimaryHover,
		fontSize: 12,
		letterSpacing: 1.3,
		margin: '8px 30px',
		textTransform: 'uppercase',
	},
});
