import * as Api from '@ViewModels';
import { CampaignType } from '../../../models/AdminModels';
import { getSuggestionCampaignType } from '../../components/campaigns/CampaignCalendar/models';
import { ISelectOption } from '../../components/Select';

export const SupportedCampaignTypes = [CampaignType.Email, CampaignType.Social, CampaignType.Blog];

export enum CalendarType {
	Quarterly = 'Quarter',
	Month = 'Month',
	Weekly = 'Week',
}

export const CalendarViewGroupOptions: ISelectOption<CalendarType>[] = Object.values(CalendarType).map(
	calendarType => ({
		dataContext: calendarType,
		id: `calendar-view-group-option-${calendarType}`,
		text: calendarType,
	})
);

export const CalendarAccountAgeOptions: ISelectOption<Api.ContentCalendarSuggestionAccountAge>[] = [
	{
		dataContext: null,
		id: 'account-age-option-all',
		text: 'Suggestions for all users',
	},
	{
		dataContext: Api.ContentCalendarSuggestionAccountAge.New,
		id: 'account-age-option-new',
		text: 'Suggestions for new users',
	},
];

export function isSuggestionInCampaignTypes(x: Api.IContentCalendarSuggestion, campaignTypes: CampaignType[]) {
	const type = getSuggestionCampaignType(x);
	return campaignTypes?.includes(type);
}
