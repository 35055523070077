import { SvgIcon } from '../../../../../web/components/svgs/icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const TagNarrowIcon: React.FC<IProps> = ({ className = '' }) => {
	return (
		<SvgIcon className={`tag-narrow-icon ${className}`} height={43} width={42}>
			<circle cx='21' cy='21.5' r='20' fill='#E8F3F9' stroke='#00AAE8' strokeWidth='2' />
			<circle cx='20.889' cy='21.389' r='16' fill='#fff' />
			<rect x='10.667' y='15.834' width='15.333' height='5.333' rx='2.667' fill='#00AAE8' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M14.775 19.793c-.37.536-1.138.6-1.594.134l-.408-.418a.414.414 0 01.583-.587l.605.584 1.99-2.824a.402.402 0 01.658.46l-1.834 2.651z'
				fill='#F9F9F9'
			/>
			<rect x='15.333' y='22.5' width='15.333' height='5.333' rx='2.667' fill='#EEC6CB' />
			<path fill='#D0021B' d='M17.333 24.5H22v1.333h-4.667z' />
		</SvgIcon>
	);
};
