import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	addEntityButtons: {
		borderTop: '1px #F2F2F2 solid',
		background: '#FFFFFF',
	},
	dropdownContent: {
		background: '#FFFFFF',
		border: '1px solid #F3F3F3',
		borderRadius: '6px',
		boxShadow: '0 0 6px 0 rgba(139,139,139,0.10)',
		boxSizing: 'border-box',
		minWidth: '100%',
	},
	general: {
		fontSize: '14px',
		padding: '10px 20px',
	},
	highlighted: {
		backgroundColor: '#e8f3f9',
	},
	info: {
		cursor: 'text',
		fontSize: 14,
		padding: '10px 20px',
	},
	infoButton: {
		alignItems: 'center',
		boxSizing: 'border-box',
		display: 'flex',
		fontSize: '14px',
		padding: '10px 20px',
		textAlign: 'left',
	},
	item: {
		':focus': {
			outline: 'none',
		},
		':hover': {
			backgroundColor: '#E3E7E9',
		},
		cursor: 'pointer',
	},
	tagWrap: {
		fontSize: '14px',
		padding: '4px 20px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
	},
	tagStyles: {
		display: 'inline-flex',
	},
	textInput: {
		alignItems: 'center',
		display: 'flex',
	},
});
