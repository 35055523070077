import { StyleSheet } from 'aphrodite';
import { brandPrimary, navigation } from '../../styles/colors';
import { CssSelectors } from '../../styles/styles';

const link = {
	color: navigation,
	textDecoration: 'none',
};

export const styleSheet = StyleSheet.create({
	compactFlag: {
		position: 'absolute',
		transform: 'translate3d(22px, -9px, 0)',
	},
	container: {},
	flag: {
		marginLeft: 10,
	},
	icon: {
		display: 'flex',
		height: 20,
		justifyContent: 'center',
		textAlign: 'center',
		verticalAlign: 'middle',
		width: 22,
	},
	link: {
		...link,
		':active': { ...link },
		':hover': {
			...link,
			color: brandPrimary,
			...CssSelectors.allChildren({
				color: brandPrimary,
			}),
		},
		':visited': { ...link },
		alignItems: 'center',
		display: 'flex',
		height: 22,
	},
	linkActive: {
		...link,
		color: brandPrimary,
	},
	linkName: {
		marginLeft: 15,
	},
	linkNameOutlined: {
		border: `1px solid ${brandPrimary}`,
		borderRadius: 3,
		padding: '4px 10px',
	},
});
