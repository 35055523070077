import { darkGrayFontColor } from '../../../styles/colors';
import { baseStyles } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

const inputWidth = 130;

export const styleSheet = StyleSheet.create({
	bulletListItemTextField: {
		flexGrow: 1,
		minWidth: 0,
	},
	bulletListItemTextFieldInput: {
		width: '100%',
	},
	container: {},
	phoneNumberSelectBox: {
		flexShrink: 0,
		width: inputWidth,
	},
	phoneNumberSelectBoxOption: {
		padding: 10,
	},
	phoneNumberSelectBoxTrigger: {
		...baseStyles.textField,
	},
	placeholder: {
		color: darkGrayFontColor,
	},
});
