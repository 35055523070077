import { CssSelectors } from '../../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	icon: {
		margin: 0,
		...CssSelectors.allChildren({
			height: 'auto',
			width: '100%',
		}),
	},
});
