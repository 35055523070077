import { ILocalNotification, addObserver, removeObserver } from '../../../models/LocalNotifications';
import * as React from 'react';

interface IProps {
	onNotificationReceived?(notification?: ILocalNotification<any>): void;
	topic: string;
}

interface IState {
	lastNotification?: ILocalNotification<any>;
}

export class LocalNotificationObserver extends React.Component<IProps> {
	public state: IState = {};

	public UNSAFE_componentWillMount() {
		addObserver(this.props.topic, this.onNotificationReceived);
	}

	public componentWillUnmount() {
		removeObserver(this.onNotificationReceived);
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		if (
			nextProps.topic !== this.props.topic ||
			nextProps.onNotificationReceived !== this.props.onNotificationReceived
		) {
			if (
				!!this.state.lastNotification &&
				!!nextProps.onNotificationReceived &&
				nextProps.onNotificationReceived !== this.props.onNotificationReceived
			) {
				nextProps.onNotificationReceived(this.state.lastNotification);
			}

			removeObserver(this.onNotificationReceived);
			addObserver(nextProps.topic, this.onNotificationReceived);

			this.setState({
				lastNotification: null,
			});
		}
	}

	public render(): JSX.Element {
		// @ts-ignore
		return null;
	}

	private onNotificationReceived = (_: string, notification?: ILocalNotification<any>) => {
		if (this.props.onNotificationReceived) {
			this.props.onNotificationReceived(notification);
		} else {
			this.setState({
				lastNotification: notification,
			});
		}
	};
}
