import { ActionItemViewModel, IOperationResultNoValue, IUser } from '@ViewModels';
import { css } from 'aphrodite';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { v4 as uuidgen } from 'uuid';
import { SizeConstraint } from '../../../../models';
import {
	IThemedComponentProps,
	IUserSessionComponentProps,
	ThemeKey,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import { getDefaultDateStringValue, getDisplayName } from '../../../../models/UiUtils';
import { brandPrimary } from '../../../styles/colors';
import { AssigneeIcon } from '../../svgs/icons/AssigneeIcon';
import { ClockIcon } from '../../svgs/icons/ClockIcon';
import { ActionItemCheckbox } from '../ActionItemCheckbox';
import { styleSheet } from './styles';

interface IProps extends IThemedComponentProps, IEventLoggingComponentProps, IUserSessionComponentProps {
	actionItem: ActionItemViewModel;
	assignee?: IUser;
	bodyClassName?: string;
	canToggleCompleted?: boolean;
	className?: string;
	dueDate?: Date;
	footer?: React.ReactNode;
	header?: React.ReactNode;
	onCheckChanged?(): void;
	onClick?(e: React.MouseEvent<HTMLElement>): void;
	onRemoveAssigneeClicked?(e: React.MouseEvent<HTMLElement>): void;
	onRemoveDueDateClicked?(e: React.MouseEvent<HTMLElement>): void;
	readonly?: boolean;
	sizeConstraint?: SizeConstraint;
}

interface IState {
	assignee?: IUser;
	dueDate?: Date;
	isComplete?: boolean;
}

export class _ActionItemCard extends React.Component<IProps, IState> {
	private checkboxElement: HTMLElement;
	private uuid = uuidgen();
	private checkboxId: string;
	public static defaultProps: Partial<IProps> = {
		sizeConstraint: 'normal',
	};
	public readonly state: IState = {};

	public UNSAFE_componentWillMount() {
		this.checkboxId = `action-item-card-checkbox-${this.uuid}`;
		const nextState = this.getNextStateWithProps(this.props);
		if (nextState) {
			this.setState(nextState);
		}
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		const nextState = this.getNextStateWithProps(nextProps);
		if (nextState) {
			this.setState(nextState);
		}
	}

	public render() {
		const { className, actionItem, theme, header, footer, children, bodyClassName } = this.props;
		const { isComplete } = this.state;
		const dueDateOption = this.renderDueDateOption();
		const assigneeOption = this.renderAssigneeOption();
		return (
			<div className={`${css(styleSheet.container)} action-item-card ${className || ''}`} onClick={this.onClick}>
				<div
					className={css(styleSheet.tintIndicator)}
					style={{
						background: actionItem.isKeepInTouchActionItem
							? theme.actionItems.kitTintColor
							: theme.actionItems.tintColor,
					}}
				/>
				<div className={css(styleSheet.content)}>
					{!!header && <div className={css(styleSheet.header)}>{header}</div>}
					<div className={`action-item-card-body ${css(styleSheet.body)} ${bodyClassName || ''}`}>
						<div className={css(styleSheet.checkbox)}>
							{!actionItem.isSuggestedKeepInTouchActionItem && (
								<ActionItemCheckbox
									rootElementRef={this.onCheckboxRef}
									id={this.checkboxId}
									disabled={!this.canToggleCompleted}
									checked={
										isComplete !== null && isComplete !== undefined ? isComplete : !!actionItem.isCompleted || false
									}
									onChange={this.onCheckChanged}
								/>
							)}
						</div>
						<div className={css(styleSheet.bodyContent)}>{children}</div>
					</div>
					{(!!footer || !!dueDateOption || !!assigneeOption) && (
						<div className={css(styleSheet.footer)}>
							{dueDateOption}
							{assigneeOption}
							{footer}
						</div>
					)}
				</div>
			</div>
		);
	}

	private renderDueDateOption() {
		const { sizeConstraint, onRemoveDueDateClicked } = this.props;
		const { dueDate } = this.state;
		if (dueDate) {
			const icon = <ClockIcon fillColor={brandPrimary} className={css(styleSheet.dueDateIcon)} />;
			return sizeConstraint === 'normal'
				? this.renderOption(
						0,
						'Due date',
						getDefaultDateStringValue(dueDate),
						'Remove due date',
						onRemoveDueDateClicked,
						icon
					)
				: this.renderOption(0, '', getDefaultDateStringValue(dueDate), 'Remove', onRemoveDueDateClicked, icon);
		}
		return null;
	}

	private renderAssigneeOption() {
		const { sizeConstraint, onRemoveAssigneeClicked, actionItem, userSession } = this.props;
		const { assignee } = this.state;
		if (assignee) {
			const assignedLabel = sizeConstraint === 'normal' ? 'Assigned to' : '';
			const removeAssigneeLabel = sizeConstraint === 'normal' ? 'Remove assignee' : 'Remove';
			const creator = actionItem.creator;
			const isAssignedToSelf = assignee.id === userSession.user.id;
			const isSelfAssigned = creator && creator.id === this.props.userSession.user.id && creator.id === assignee.id;
			if (actionItem.id) {
				if (isSelfAssigned) {
					return null;
				}

				if (isAssignedToSelf) {
					return this.renderOption(
						1,
						`${getDisplayName(actionItem.creator)} assigned this to you`,
						'',
						removeAssigneeLabel,
						onRemoveAssigneeClicked
					);
				}

				return this.renderOption(
					1,
					assignedLabel,
					getDisplayName(assignee),
					removeAssigneeLabel,
					onRemoveAssigneeClicked,
					<AssigneeIcon fillColor={brandPrimary} className={css(styleSheet.assigneeIcon)} />
				);
			}

			return this.renderOption(
				1,
				assignedLabel,
				getDisplayName(assignee),
				removeAssigneeLabel,
				onRemoveAssigneeClicked,
				<AssigneeIcon fillColor={brandPrimary} className={css(styleSheet.assigneeIcon)} />
			);
		}
		return null;
	}

	@computed
	private get canToggleCompleted() {
		const { actionItem, canToggleCompleted, readonly } = this.props;
		return (!!canToggleCompleted || !readonly) && !!actionItem.id && !actionItem.isBusy;
	}

	private getNextStateWithProps = (nextProps: IProps) => {
		const { assignee, dueDate } = this.state;
		const nextState: IState = {};

		if (nextProps.assignee !== assignee) {
			nextState.assignee = nextProps.assignee;
		}

		if (dueDate !== nextProps.dueDate) {
			nextState.dueDate = nextProps.dueDate;
		}

		return Object.keys(nextState).length > 0 ? nextState : null;
	};

	private onCheckboxRef = (ref: HTMLElement) => {
		this.checkboxElement = ref;
	};

	private onCheckChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		e.stopPropagation();
		this.toggleCheckChange();
	};

	private toggleCheckChange = () => {
		const { actionItem, logApiError, logInput, onCheckChanged } = this.props;
		const isCompleted = !actionItem.isCompleted;
		const promise = actionItem.toggleComplete(isCompleted);
		if (promise) {
			const action = `Toggle${isCompleted ? 'Complete' : 'InProgress'}`;
			logInput(action, 'Click');
			this.setState({
				isComplete: isCompleted,
			});
			promise
				.then(() => {
					this.setState({
						isComplete: null,
					});
					if (onCheckChanged) {
						onCheckChanged();
					}
				})
				.catch((error: IOperationResultNoValue) => {
					this.setState({
						isComplete: null,
					});
					logApiError(`${action}-Error`, error);
				});
		}
	};

	private onClick = (e: React.MouseEvent<HTMLElement>) => {
		if (!!this.canToggleCompleted && !!this.checkboxElement && this.checkboxElement.contains(e.target as HTMLElement)) {
			e.preventDefault();
			e.stopPropagation();
			this.toggleCheckChange();
			return;
		}

		const { onClick } = this.props;
		if (onClick) {
			onClick(e);
		}
	};

	private renderOption(
		key: number | string,
		titlelabel: string,
		title: string,
		removeButtonLabel: string,
		removeButtonOnClick: (e?: React.MouseEvent<HTMLElement>) => void,
		icon?: React.ReactNode
	) {
		const { readonly, sizeConstraint } = this.props;
		return (
			<div className={css(styleSheet.footerOption)} key={key}>
				<span className={css(styleSheet.footerOptionLeft)}>
					{!!icon && <div className={css(styleSheet.footerOptionIcon)}>{icon}</div>}
					{!!titlelabel && (
						<span className={css(styleSheet.footerOptionLeftTitleLabel)}>{`${titlelabel}${title ? ':' : ''}`}</span>
					)}
					<span
						className={`${css(
							styleSheet.footerOptionLeftTitle,
							sizeConstraint === 'compact' ? styleSheet.footerOptionLeftTitleCompact : null
						)} truncate-text`}
					>
						&nbsp; &nbsp;
						{title}
					</span>
				</span>
				{!readonly && (
					<button onClick={removeButtonOnClick} className={css(styleSheet.footerOptionLeftRemoveButton)}>
						<span>{removeButtonLabel}</span>
					</button>
				)}
			</div>
		);
	}
}

const ActionItemCardAsObserver = observer(_ActionItemCard);
const ActionItemCardWithContext = inject(ThemeKey, UserSessionViewModelKey)(ActionItemCardAsObserver);
export const ActionItemCard = withEventLogging(ActionItemCardWithContext, 'ActionItemCard');
