import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	backgroundCircleFillColor?: string;
	className?: string;
}

export const SendNowIcon: React.FC<IProps> = props => {
	const { className, backgroundCircleFillColor } = props;
	return (
		<SvgIcon className={`send-now-icon ${className || ''}`} height={160} width={160}>
			<defs>
				<linearGradient id='b-c' x1='50%' x2='50%' y1='100%' y2='0%'>
					<stop offset='0%' stopColor='gray' stopOpacity='.25' />
					<stop offset='54%' stopColor='gray' stopOpacity='.12' />
					<stop offset='100%' stopColor='gray' stopOpacity='.1' />
				</linearGradient>
				<linearGradient id='b-d' x1='-.007%' y1='50%' y2='50%'>
					<stop offset='0%' stopColor='gray' stopOpacity='.25' />
					<stop offset='54%' stopColor='gray' stopOpacity='.12' />
					<stop offset='100%' stopColor='gray' stopOpacity='.1' />
				</linearGradient>
				<linearGradient id='b-e' x1='0%' y1='49.996%' y2='49.996%'>
					<stop offset='0%' stopColor='gray' stopOpacity='.25' />
					<stop offset='54%' stopColor='gray' stopOpacity='.12' />
					<stop offset='100%' stopColor='gray' stopOpacity='.1' />
				</linearGradient>
				<linearGradient id='b-f' x1='0%' y1='50%' y2='50%'>
					<stop offset='0%' stopColor='gray' stopOpacity='.25' />
					<stop offset='54%' stopColor='gray' stopOpacity='.12' />
					<stop offset='100%' stopColor='gray' stopOpacity='.1' />
				</linearGradient>
				<linearGradient id='b-g' x1='0%' y1='50%' y2='50%'>
					<stop offset='0%' stopColor='gray' stopOpacity='.25' />
					<stop offset='54%' stopColor='gray' stopOpacity='.12' />
					<stop offset='100%' stopColor='gray' stopOpacity='.1' />
				</linearGradient>
			</defs>
			<g fill='none' fillRule='evenodd'>
				<mask id='send-now-icon-mask' fill='#fff' maskUnits='userSpaceOnUse' maskContentUnits='userSpaceOnUse'>
					<circle cx='80' cy='80' r='80' fill='#fff' />
				</mask>
				<circle cx='80' cy='80' r='80' fill={backgroundCircleFillColor || '#E8F3F9'} />
				<g mask='url(#send-now-icon-mask)'>
					<g transform='translate(4.8 52)'>
						<rect width='19.698' height='41.123' x='78.611' y='73.002' fill='url(#b-c)' fillRule='nonzero' />
						<rect width='16.587' height='39.05' x='80.339' y='73.348' fill='#ECEFF1' fillRule='nonzero' />
						<path
							fill='url(#b-d)'
							fillRule='nonzero'
							d='M144.219725,5.2699784 L55.023382,5.2699784 C51.957354,5.34817527 48.9357555,6.02112967 46.1266349,7.25141472 C37.0595643,11.2674803 31.7821939,17.5708415 30.2918623,31.5499412 L30.2918623,63.8460234 L6.65680085,63.5960166 C1.90370776,63.5960166 0.16587473,71.0643028 0.16587473,76.112311 L159.820302,76.112311 L159.820302,21.8369137 C159.820302,12.6877313 152.845019,5.2699784 144.219725,5.2699784 Z'
						/>
						<path
							fill='#046CB6'
							fillRule='nonzero'
							d='M58.5676026,8.72570194 L146.923248,8.72570194 C152.900969,8.72570194 157.746868,13.5719701 157.746868,19.5501448 L157.746868,73.3477322 L58.5676026,73.3477322 L58.5676026,8.72570194 Z'
						/>
						<path
							fill='#00528C'
							fillRule='nonzero'
							d='M56.3213823,8.72570194 C69.585759,8.72570194 80.3386609,19.4582559 80.3386609,32.6975322 L80.3386609,61.6159307 C80.3386609,68.0952257 75.0761962,73.3477322 68.584617,73.3477322 L44.0581476,73.3477322 C37.5665684,73.3477322 32.3041037,68.0952257 32.3041037,61.6159307 L32.3041037,32.6975322 C32.3041037,19.4582559 43.0570056,8.72570194 56.3213823,8.72570194 Z'
						/>
						<path
							fill='#00528C'
							fillRule='nonzero'
							d='M56.3213823,8.72570194 C69.585759,8.72570194 80.3386609,19.4582559 80.3386609,32.6975322 L80.3386609,61.6159307 C80.3386609,68.0952257 75.0761962,73.3477322 68.584617,73.3477322 L44.0581476,73.3477322 C37.5665684,73.3477322 32.3041037,68.0952257 32.3041037,61.6159307 L32.3041037,32.6975322 C32.3041037,19.4582559 43.0570056,8.72570194 56.3213823,8.72570194 Z'
							opacity='.2'
						/>
						<polygon
							fill='url(#b-c)'
							fillRule='nonzero'
							points='120.079 .086 107.769 .086 100.382 .086 100.382 14.803 100.382 32.916 109.323 32.916 109.323 14.803 120.079 14.803'
						/>
						<polygon
							fill='#F3CE0D'
							fillRule='nonzero'
							points='118.697 .086 108.33 .086 102.11 .086 102.11 13.564 102.11 30.151 108.33 30.151 108.33 13.564 118.697 13.564'
						/>
						<polygon
							fill='url(#b-e)'
							fillRule='nonzero'
							points='37.621 16.457 58.018 16.457 58.02 49.657 37.622 49.657'
							transform='rotate(-83.06 47.82 33.057)'
						/>
						<polygon
							fill='#FAF8E4'
							fillRule='nonzero'
							points='38.169 16.828 57.204 16.829 57.206 48.903 38.17 48.902'
							transform='rotate(-83.06 47.687 32.866)'
						/>
						<g opacity='.6' transform='translate(33.686 22.894)'>
							<polygon
								fill='#00AAE8'
								fillRule='nonzero'
								points='.186 .383 4.258 .381 4.256 3.381 .184 3.383'
								transform='rotate(-173.06 2.221 1.882)'
							/>
							<polygon
								fill='#6C63FF'
								fillRule='nonzero'
								points='2.627 9.294 18.258 9.286 18.258 10.258 2.627 10.266'
								transform='rotate(-173.06 10.443 9.776)'
							/>
							<polygon
								fill='#6C63FF'
								fillRule='nonzero'
								points='6.571 11.964 17.968 11.958 17.968 12.606 6.57 12.612'
								transform='rotate(-173.06 12.27 12.285)'
							/>
						</g>
						<polygon
							fill='url(#b-e)'
							fillRule='nonzero'
							points='51.049 20.911 71.447 20.911 71.448 54.111 51.05 54.111'
							transform='rotate(-83.06 61.249 37.511)'
						/>
						<polygon
							fill='#FAF8E4'
							fillRule='nonzero'
							points='51.597 21.285 70.633 21.285 70.634 53.36 51.598 53.359'
							transform='rotate(-83.06 61.115 37.322)'
						/>
						<g opacity='.6' transform='translate(47.164 27.387)'>
							<polygon
								fill='#00AAE8'
								fillRule='nonzero'
								points='.314 .32 4.403 .315 4.4 3.315 .311 3.319'
								transform='rotate(-173.06 2.357 1.817)'
							/>
							<polygon
								fill='#6C63FF'
								fillRule='nonzero'
								points='2.766 9.233 18.463 9.217 18.462 10.19 2.765 10.206'
								transform='rotate(-173.06 10.614 9.711)'
							/>
							<polygon
								fill='#6C63FF'
								fillRule='nonzero'
								points='6.72 11.902 18.166 11.89 18.165 12.538 6.72 12.55'
								transform='rotate(-173.06 12.443 12.22)'
							/>
						</g>
						<polygon
							fill='url(#b-f)'
							fillRule='nonzero'
							points='24.31 21.226 51.959 21.227 51.961 66.228 24.312 66.227'
							transform='rotate(-83.06 38.136 43.727)'
						/>
						<polygon
							fill='#FFF'
							fillRule='nonzero'
							points='25.054 21.726 50.86 21.726 50.861 65.203 25.056 65.202'
							transform='rotate(-83.06 37.958 43.464)'
						/>
						<g opacity='.6' transform='translate(18.827 30.151)'>
							<polygon
								fill='#00AAE8'
								fillRule='nonzero'
								points='.464 .411 5.984 .408 5.982 4.473 .462 4.476'
								transform='rotate(-173.06 3.223 2.442)'
							/>
							<polygon
								fill='#D3DBDE'
								fillRule='nonzero'
								points='3.77 12.491 24.967 12.479 24.967 13.798 3.77 13.81'
								transform='rotate(-173.06 14.369 13.145)'
							/>
							<polygon
								fill='#D3DBDE'
								fillRule='nonzero'
								points='9.119 16.107 24.575 16.098 24.575 16.978 9.119 16.987'
								transform='rotate(-173.06 16.847 16.542)'
							/>
						</g>
						<polygon
							fill='url(#b-g)'
							fillRule='nonzero'
							points='42.513 27.263 70.162 27.264 70.164 72.265 42.515 72.264'
							transform='rotate(-83.06 56.339 49.764)'
						/>
						<polygon
							fill='#FFF'
							fillRule='nonzero'
							points='43.254 27.768 69.06 27.769 69.062 71.245 43.256 71.244'
							transform='rotate(-83.06 56.158 49.506)'
						/>
						<g opacity='.6' transform='translate(37.142 36.371)'>
							<polygon
								fill='#00AAE8'
								fillRule='nonzero'
								points='.304 .334 5.807 .333 5.806 4.397 .304 4.398'
								transform='rotate(-173.06 3.055 2.366)'
							/>
							<polygon
								fill='#D3DBDE'
								fillRule='nonzero'
								points='3.6 12.411 24.73 12.407 24.73 13.725 3.6 13.729'
								transform='rotate(-173.06 14.165 13.068)'
							/>
							<polygon
								fill='#D3DBDE'
								fillRule='nonzero'
								points='8.932 16.027 24.339 16.024 24.339 16.904 8.931 16.907'
								transform='rotate(-173.06 16.635 16.466)'
							/>
						</g>
						<path
							fill='#000'
							fillRule='nonzero'
							d='M55.9756433,8.72570943 C52.833759,8.72322746 49.7221113,9.33803013 46.8181425,10.5350582 C47.9492796,10.373084 49.0904914,10.2912812 50.2332105,10.290264 C56.603033,10.290264 62.7119642,12.815795 67.2161089,17.311269 C71.7202536,21.806743 74.2506558,27.9039142 74.2506558,34.2614745 L74.2506558,72.3110151 L79.9930886,72.3110151 L79.9930886,32.69692 C79.9930886,19.457986 69.240112,8.72570943 55.9756433,8.72570943 Z'
							opacity='.2'
						/>
						<path
							fill='#046CB6'
							fillRule='nonzero'
							d='M11.6081276,65.0539957 L80.3386609,65.0539957 L80.3386609,73.3477322 L3.27602592,73.3477322 C3.27602592,68.767228 7.00643489,65.0539957 11.6081276,65.0539957 Z'
						/>
						<path
							fill='#046CB6'
							fillRule='nonzero'
							d='M11.6081276,65.0539957 L80.3386609,65.0539957 L80.3386609,73.3477322 L3.27602592,73.3477322 C3.27602592,68.767228 7.00643489,65.0539957 11.6081276,65.0539957 Z'
							opacity='.2'
						/>
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
