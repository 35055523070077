import { HwSampleW1 } from './HwSampleLetters/HwSampleW1';
import { HwSampleW2 } from './HwSampleLetters/HwSampleW2';
import { HwSampleW3 } from './HwSampleLetters/HwSampleW3';
import { HwSampleW4 } from './HwSampleLetters/HwSampleW4';
import { HwSampleW5 } from './HwSampleLetters/HwSampleW5';
import { styleSheet } from './styles';
import { css } from 'aphrodite';

export function HwVariationsDisclaimer() {
	return (
		<>
			<p className={css(styleSheet.hwVariationsDisclaimerText)}>
				Actual handwriting will have variations for each letter, see example.
			</p>
			<div className={css(styleSheet.hwVariationsDisclaimerLetters)}>
				<div className={css(styleSheet.hwVariationsDisclaimerLetter)}>
					<HwSampleW1 width={24} />
				</div>
				<div className={css(styleSheet.hwVariationsDisclaimerLetter)}>
					<HwSampleW2 width={24} />
				</div>
				<div className={css(styleSheet.hwVariationsDisclaimerLetter)}>
					<HwSampleW3 width={24} />
				</div>
				<div className={css(styleSheet.hwVariationsDisclaimerLetter)}>
					<HwSampleW4 width={24} />
				</div>
				<div className={css(styleSheet.hwVariationsDisclaimerLetter)}>
					<HwSampleW5 width={24} />
				</div>
			</div>
		</>
	);
}
