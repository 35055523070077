import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { useGifting } from '../../../../queries';

export function useCurrentUserGiftingBalance({ totalRecipients = 1 }: { totalRecipients?: number } = {}) {
	const userSession = useUserSession();
	const giftingQuery = useGifting();
	const currentBalance = giftingQuery.data != null ? giftingQuery.data.availableBalance : null;
	const currencyCostPerCard = userSession?.account?.features?.handwrittenCards?.currencyCostPerCard;
	const balanceAfterSend =
		currentBalance != null && currencyCostPerCard != null
			? currentBalance - currencyCostPerCard * totalRecipients
			: null;
	const cardsAvailable =
		currencyCostPerCard != null && currentBalance != null ? Math.floor(currentBalance / currencyCostPerCard) : null;
	const totalCost = currencyCostPerCard != null ? currencyCostPerCard * totalRecipients : null;
	const currentBalanceFormatted =
		currentBalance != null
			? new Intl.NumberFormat('en-US', { currency: 'USD', style: 'currency' }).format(currentBalance)
			: '';
	return {
		balanceAfterSend,
		cardsAvailable,
		currentBalance,
		currentBalanceFormatted,
		giftingQuery,
		isLoading: giftingQuery.isLoading,
		totalCost,
	};
}
