import { navigation } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const TagIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;
	return (
		<SvgIcon className={`search-icon ${className || ''}`} height={18} width={17}>
			<g fill={fillColor || navigation} fillRule='evenodd'>
				<path d='M1.54545455,8.689425 L9.27272727,16.189425 L13.5891818,11.999925 L5.86190909,4.499925 L1.54545455,4.499925 L1.54545455,8.689425 Z M9.27272727,17.999925 C9.07490909,17.999925 8.87709091,17.926425 8.72640909,17.780175 L0.226409091,9.530175 C0.0819090909,9.389925 0,9.198675 0,8.999925 L0,3.749925 C0,3.335925 0.346181818,2.999925 0.772727273,2.999925 L6.18181818,2.999925 C6.38659091,2.999925 6.58286364,3.079425 6.72813636,3.219675 L15.2281364,11.469675 C15.5302727,11.762925 15.5302727,12.236925 15.2281364,12.530175 L9.81904545,17.780175 C9.66836364,17.926425 9.47054545,17.999925 9.27272727,17.999925 L9.27272727,17.999925 Z' />
				<path d='M5.40909091 6.75C5.40909091 7.57725 4.71672727 8.25 3.86363636 8.25 3.009 8.25 2.31818182 7.57725 2.31818182 6.75 2.31818182 5.92125 3.009 5.25 3.86363636 5.25 4.71672727 5.25 5.40909091 5.92125 5.40909091 6.75M15.6809545 9.53025L7.40736364 1.5 2.31818182 1.5 2.31818182 0 7.72727273 0C7.93204545 0 8.12831818.07875 8.27359091.21975L16.7735909 8.46975 15.6809545 9.53025z' />
			</g>
		</SvgIcon>
	);
};
