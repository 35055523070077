import { brandSecondary, destructive, titles } from '../../../../styles/colors';
import { CssSelectors, CtaButtons } from '../../../../styles/styles';
import { StyleSheet } from 'aphrodite';

const MessageTemplateSelectHeaderHeight = 22;
const MessageTemplateSelectHeaderBottomMargin = 48;

export const styleSheet = StyleSheet.create({
	body: {
		display: 'flex',
		height: `calc(100% - ${MessageTemplateSelectHeaderBottomMargin + MessageTemplateSelectHeaderHeight + 20}px)`,
	},
	box: {
		border: '1px solid #D3DBDE',
		boxShadow: '0 0 6px 0 rgba(139,139,139,0.10)',
		boxSizing: 'border-box',
	},
	campaignSearch: {
		height: '100%',
	},
	column: {
		':not(:first-child)': {
			marginLeft: 30,
		},
		height: '100%',
		minWidth: 0,
		width: 'calc(100% - 30px)',
	},
	container: {
		height: '100%',
	},
	createNewMessageButton: {
		alignItems: 'center',
		color: brandSecondary,
		display: 'flex',
		flexDirection: 'column',
		fontSize: 18,
		justifyContent: 'center',
		textAlign: 'center',
		...CssSelectors.allChildren(
			{
				marginTop: 13,
			},
			':not(:first-child)'
		),
	},
	createNewMessageColumn: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
	},
	deleteButton: {
		alignItems: 'center',
		color: destructive,
		display: 'flex',
		fontSize: 12,
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	header: {
		color: titles,
		fontSize: 18,
		height: MessageTemplateSelectHeaderHeight,
		marginBottom: MessageTemplateSelectHeaderBottomMargin,
		textAlign: 'center',
	},
	modal: {
		':nth-child(1n) > .modal-overlay': {
			':nth-child(1n) > .modal-overlay-content': {
				height: '80vh',
				width: 640,
			},
		},
	},
	selectTemplateButton: {
		...CtaButtons.ctaButtonSmall,
	},
	singleColumn: {
		width: '100%',
	},
});
