import { error } from '../../styles/colors';
import { CSSProperties, StyleSheet } from 'aphrodite';

export const InputErrorStyle: CSSProperties = {
	background: error,
	borderRadius: 3,
	bottom: 0,
	boxShadow: '0 1px 2px 0 rgba(162,162,162,0.50)',
	boxSizing: 'border-box',
	color: '#FFFFFF',
	display: 'flex',
	flexGrow: 1,
	fontSize: 14,
	fontWeight: 600,
	letterSpacing: 0,
	padding: '10px 20px',
};

export const styleSheet = StyleSheet.create({
	message: {
		...InputErrorStyle,
	},
});
