import { IRange } from '@ViewModels';
import { css } from 'aphrodite';
import { inject } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { IModalContext, ModalChildComponentContextKey } from '../../../models';
import { EnvironmentKey, IEnvironmentComponentProps } from '../../../models/AppState';
import { EventLogger } from '../../../models/Logging';
import { baseStyleSheet } from '../../styles/styles';
import { DayPicker } from '../DayPicker';
import { DeprecatedCloseButton } from '../DeprecatedCloseButton';
import { asModalComponent } from '../Modal';
import { CalendarDateIcon } from '../svgs/icons/CalendarDateIcon';
import './styles.less';

interface IProps extends IModalContext<IRange<Date>>, IEnvironmentComponentProps {
	allowPastDates?: boolean;
	className?: string;
	onRangeChange?(range: IRange<Date>): void;
	range?: IRange<Date>;
	showClearButton?: boolean;
}

interface IState {
	range?: IRange<Date>;
}

class _DateRangePicker extends React.Component<IProps, IState> {
	public readonly state: IState = {};

	public UNSAFE_componentWillMount() {
		this.setState({
			range: this.props.range || {
				end: moment().startOf('day').toDate(),
				start: moment().endOf('day').toDate(),
			},
		});
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		if (!!nextProps.range && nextProps.range !== this.state.range) {
			this.setState({
				range: nextProps.range,
			});
		}
	}

	public render() {
		const { className, showClearButton } = this.props;
		const { range } = this.state;
		return (
			<div className={`date-range-picker ${className || ''}`}>
				<div className='date-range-picker-header'>
					<DeprecatedCloseButton onClick={this.onCancel} />
				</div>
				<div className='date-range-picker-title'>
					<span>Date Range</span>
				</div>
				<div className='date-range-picker-body'>
					{this.renderCalendar(true)}
					{this.renderCalendar(false)}
				</div>
				<div className='date-range-picker-footer'>
					<button
						className={`${css(baseStyleSheet.ctaButton)} date-range-picker-footer-submit-button`}
						onClick={this.onSubmit}
					>
						<span>Okay</span>
					</button>
					{showClearButton && range && (
						<button
							className={`${css(baseStyleSheet.ctaButton)} date-range-picker-footer-submit-button`}
							style={{
								backgroundColor: '#CCCCCC',
								color: '#000000',
								marginLeft: 10,
							}}
							onClick={this.onClear}
						>
							<span>Clear</span>
						</button>
					)}
				</div>
			</div>
		);
	}

	private renderCalendar(start: boolean) {
		const { allowPastDates, environment } = this.props;
		const { range } = this.state;
		return (
			<div className='date-range-picker-calendar'>
				<div className='date-range-picker-calendar-day-picker-header'>
					<span className='date-range-picker-calendar-title'>{`${start ? 'From' : 'To'}`}</span>
					<CalendarDateIcon className='date-range-picker-calendar-date-icon' opacity={0.6} />
					<span className='date-range-picker-calendar-date'>
						{/* @ts-ignore */}
						{/* @ts-ignore */}
						{`${moment(start ? range.start : range.end).format('MM/DD')}`}
					</span>
				</div>
				<DayPicker
					allowPastDates={allowPastDates}
					className='date-range-picker-calendar-day-picker'
					environment={environment}
					// @ts-ignore
					// @ts-ignore
					selectedDays={start ? range.start : range.end}
					onDayClick={this.onRangeChanged(start)}
				/>
			</div>
		);
	}

	private onSubmit = () => {
		EventLogger.logInput('DateRangePicker', 'Okay', 'Click');
		const { parentModal } = this.props;
		if (parentModal) {
			parentModal.onRequestClose(this.state.range, false);
		}
	};

	private onRangeChanged = (start: boolean) => (selectedDay: Date) => {
		const { onRangeChange } = this.props;
		const nextRange = {
			...this.state.range,
		};

		if (start) {
			nextRange.start = moment(selectedDay).startOf('day').toDate();
			// @ts-ignore
			if (selectedDay > nextRange.end) {
				nextRange.end = moment(selectedDay).endOf('day').toDate();
			}
		} else {
			nextRange.end = moment(selectedDay).endOf('day').toDate();
			// @ts-ignore
			if (selectedDay < nextRange.start) {
				nextRange.start = moment(selectedDay).startOf('day').toDate();
			}
		}

		if (onRangeChange) {
			onRangeChange(nextRange);
		} else {
			this.setState({
				range: nextRange,
			});
		}
	};

	private onCancel = () => {
		EventLogger.logInput('DateRangePicker', 'Close', 'Click');
		const { parentModal } = this.props;
		if (parentModal) {
			parentModal.onRequestClose(this.state.range, true);
		}
	};

	private onClear = () => {
		EventLogger.logInput('DateRangePicker', 'Close', 'Click');
		const { parentModal } = this.props;
		if (parentModal) {
			// @ts-ignore
			parentModal.onRequestClose(null, true);
		}
	};
}

const DateRangePickerWithContext = inject(EnvironmentKey, ModalChildComponentContextKey)(_DateRangePicker);
export const DateRangePickerModal = asModalComponent(DateRangePickerWithContext, {
	className: 'date-range-picker-modal',
});
