import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import { TagsListWithTagsEditor } from '../../../components/entities/tags/TagsEditor';
import { TagIcon } from '../../../components/svgs/icons/TagIcon';
import { bs } from '../../../styles/styles';
import { DataOriginContainer } from '../../DataOriginContainer';
import { useContactTagsUtils } from '../useContactTagsUtils';

interface Props {
	contactVm: Api.ContactViewModel;
}

function _ContactInfoTags({ contactVm }: Props) {
	const { tags, onRequestAddTag, onRequestRemoveTag } = useContactTagsUtils(contactVm);
	return (
		<DataOriginContainer className={css(bs.mt5, bs.wFull)} contact={contactVm} dataType='Tags' iconPlacement='bottom'>
			<div className={css(bs.flex, bs.justifyStart)}>
				<TagIcon className={css(bs.flexShrink0, bs.mr4, bs.mt3, bs.w4)} />
				<TagsListWithTagsEditor
					tagType={Api.ResourceAutoCompleteViewModelType.AccountTag}
					className={css(bs.flex1)}
					disabled={!!contactVm.isBusy}
					entity={contactVm}
					onRequestAddTag={onRequestAddTag}
					onRequestRemoveTag={onRequestRemoveTag}
					tags={tags || contactVm?.tags?.map((tag: string) => ({ tag })) || []}
				/>
			</div>
		</DataOriginContainer>
	);
}

export const ContactInfoTags = observer(_ContactInfoTags);
