import { grayIconFill } from '../../../styles/colors';
import { StyleDeclarationValue, StyleSheet, css } from 'aphrodite';
import * as React from 'react';

export const styleSheet = StyleSheet.create({
	container: {
		alignItems: 'center',
		color: grayIconFill,
		display: 'flex',
		fontSize: 14,
		fontStyle: 'italic',
		height: 32,
		paddingLeft: 30,
	},
});

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const ContactsNoRecentSearches: React.FC<IProps> = props => {
	const { className, styles } = props;
	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
			You have not made any searches recently...
		</div>
	);
};
