import * as Api from '@ViewModels';
import { Modal } from '../../components/Modal';
import { TextInput } from '../../components/TextInput';
import { baseStyleSheet } from '../../styles/styles';
import { styleSheet } from './styles';
// eslint-disable-next-line import/no-internal-modules
import { yupResolver } from '@hookform/resolvers/yup';
import { css } from 'aphrodite';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { getDisplayName } from '../../../models/UiUtils';

const validationSchema = yup.object({
	address1: yup.string().required('Address line 1 is required'),
	address2: yup.string(),
	city: yup.string().required('City is required'),
	salutationName: yup.string(),
	addresseeName: yup.string(),
	postalCode: yup.string().required('Postal Code is required'),
	stateProvince: yup.string().required('State/Province is required'),
});

interface IFormData {
	addresseeName?: string;
	address1: string;
	address2?: string;
	city: string;
	salutationName?: string;
	postalCode: string;
	stateProvince: string;
}

export const addresseeNameFromHwcRecipient = (hwcRecipient: Api.IHandwrittenCardRecipient) => {
	return (
		hwcRecipient.address?.name ??
		hwcRecipient.addressName ??
		getDisplayName({ firstName: hwcRecipient.firstName, lastName: hwcRecipient.lastName })
	);
};

export const RecipientAddressModal = ({
	initialData,
	isOpen,
	onSave,
	onCancel,
}: {
	initialData?: IFormData;
	onSave: (hwcRecipient: IFormData) => void;
	onCancel: () => void;
	isOpen: boolean;
}) => {
	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		defaultValues: initialData,
		resolver: yupResolver(validationSchema),
	});

	return (
		<Modal shouldCloseOnOverlayClick={false} useDefaultHeader isOpen={isOpen} onRequestClose={onCancel}>
			<form onSubmit={handleSubmit(onSave)}>
				<div className={css(styleSheet.modalHeader)}>
					<h1 className={css(styleSheet.modalHeaderName)}>Recipient Information</h1>
				</div>
				<div className={css(styleSheet.addresslineGroup)}>
					<div className={css(styleSheet.addresslineCol)}>
						<label className={css(styleSheet.label)} htmlFor='address-modal-salutation-input'>
							Salutation Name (used on the inside of the card)
						</label>
						<Controller
							name='salutationName'
							control={control}
							render={({ field: { ref, ...fieldProps } }) => (
								<TextInput inputId='address-modal-salutation-input' type='text' inputRef={ref} {...fieldProps} />
							)}
						/>
					</div>
				</div>
				<div className={css(styleSheet.addresslineGroup)}>
					<div className={css(styleSheet.addresslineCol)}>
						<label className={css(styleSheet.label)} htmlFor='address-modal-addressee-input'>
							Addressee (used on the envelope)
						</label>
						<Controller
							name='addresseeName'
							control={control}
							render={({ field: { ref, ...fieldProps } }) => (
								<TextInput inputId='address-modal-addressee-input' type='text' inputRef={ref} {...fieldProps} />
							)}
						/>
					</div>
				</div>
				<label className={css(styleSheet.label, styleSheet.labelRequired)} htmlFor='address-modal-address1-input'>
					Address Line 1
				</label>
				<Controller
					name='address1'
					control={control}
					render={({ field: { ref, ...fieldProps } }) => (
						<TextInput inputId='address-modal-address1-input' type='text' inputRef={ref} {...fieldProps} />
					)}
				/>
				{errors.address1 != null ? (
					<p role='alert' className={css(styleSheet.formErrorLine)}>
						{errors.address1.message}
					</p>
				) : null}
				<label className={css(styleSheet.label)} htmlFor='address-modal-address2-input'>
					Address Line 2
				</label>
				<Controller
					name='address2'
					control={control}
					render={({ field: { ref, ...fieldProps } }) => (
						<TextInput inputId='address-modal-address2-input' type='text' inputRef={ref} {...fieldProps} />
					)}
				/>
				<div className={css(styleSheet.addresslineGroup)}>
					<div className={css(styleSheet.addresslineCol)}>
						<label className={css(styleSheet.label, styleSheet.labelRequired)} htmlFor='address-modal-city-input'>
							City
						</label>
						<Controller
							name='city'
							control={control}
							render={({ field: { ref, ...fieldProps } }) => (
								<TextInput inputId='address-modal-city-input' type='text' inputRef={ref} {...fieldProps} />
							)}
						/>
						{errors.city != null ? (
							<p role='alert' className={css(styleSheet.formErrorLine)}>
								{errors.city.message}
							</p>
						) : null}
					</div>
					<div className={css(styleSheet.addresslineCol)}>
						<label
							className={css(styleSheet.label, styleSheet.labelRequired)}
							htmlFor='address-modal-stateProvince-input'
						>
							State/Province
						</label>
						<Controller
							name='stateProvince'
							control={control}
							rules={{ required: true }}
							render={({ field: { ref, ...fieldProps } }) => (
								<TextInput inputId='address-modal-stateProvince-input' type='text' inputRef={ref} {...fieldProps} />
							)}
						/>
						{errors.stateProvince != null ? (
							<p role='alert' className={css(styleSheet.formErrorLine)}>
								{errors.stateProvince.message}
							</p>
						) : null}
					</div>
					<div className={css(styleSheet.addresslineCol)}>
						<label className={css(styleSheet.label, styleSheet.labelRequired)} htmlFor='address-modal-postalCode-input'>
							Zip/Postal Code
						</label>
						<Controller
							name='postalCode'
							control={control}
							rules={{ required: true }}
							render={({ field: { ref, ...fieldProps } }) => (
								<TextInput inputId='address-modal-postalCode-input' type='text' inputRef={ref} {...fieldProps} />
							)}
						/>
						{errors.postalCode != null ? (
							<p role='alert' className={css(styleSheet.formErrorLine)}>
								{errors.postalCode.message}
							</p>
						) : null}
					</div>
				</div>
				<div className={css(styleSheet.modalFooter)}>
					<button className={css(baseStyleSheet.ctaButton)} type='submit'>
						Save
					</button>
					<button
						className={css(baseStyleSheet.outlineButtonContainer, styleSheet.customOutlineButton)}
						onClick={onCancel}
					>
						<span>Cancel</span>
					</button>
				</div>
			</form>
		</Modal>
	);
};
