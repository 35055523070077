import { StyleSheet } from 'aphrodite';
import { titles } from '../../../styles/colors';
import { Layouts } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	nestedOption: {
		paddingLeft: 32,
	},
	option: {
		...Layouts.horizontalStack(),
		':not(.select-trigger)': {
			background: 'transparent',
		},
		alignItems: 'center',
		boxSizing: 'border-box',
		height: '100%',
		justifyContent: 'flex-start',
		padding: '0 8px',
		width: '100%',
	},
	optionCheckbox: {
		pointerEvents: 'none',
	},
	select: {
		maxWidth: '100%',
	},
	trigger: {
		height: '40px',
		padding: 0,
		paddingRight: 15,
	},
	triggerLabel: {
		borderRight: '1px solid #d8d8d8',
		color: '#d1d1d1',
		fontSize: 12,
		height: '100%',
		letterSpacing: 1.1,
		paddingLeft: 10,
		paddingRight: 10,
		paddingTop: 24,
		textTransform: 'uppercase',
	},
	triggerText: {
		color: titles,
		paddingLeft: 10,
		width: '80%',
	},
	triggerTextNoneSelected: {
		opacity: 0.5,
	},
});
