import { CSSProperties, StyleSheet } from 'aphrodite';
import {
	brandPrimary,
	brandPrimaryActive,
	brandPrimaryHover,
	brandPrimaryText,
	darkGrayFontColor,
	titles,
} from '../../../styles/colors';
import { CssSelectors, baseStyles } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	avatar: {
		display: 'flex',
		flexShrink: 0,
		height: 80,
		width: 80,
	},
	avatarOverride: {
		...CssSelectors.allChildren(
			{
				fontSize: 26,
			},
			'span'
		),
	},
	basicContactInfo: {
		textAlign: 'center',
	},
	companyLink: {
		flexShrink: 0,
		maxWidth: '40%',
		textDecoration: 'none',
	},
	connectionQuestionButton: {
		color: brandPrimaryText,
		fontSize: 12,
		marginTop: 12,
	},
	dataOriginName: {
		maxWidth: '100%',
	},
	header: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		width: '100%',
	},
	headerInfo: {
		boxSizing: 'border-box',
		marginTop: 3,
		width: '100%',
	},
	headerInfoName: {
		color: titles,
		display: 'block',
		// accounts for padding across data origin wrapper
		maxWidth: '100%',
		...(baseStyles.truncateText as CSSProperties),
	},
	headerInfoTitle: {
		color: darkGrayFontColor,
		display: 'flex',
		fontSize: 14,
		justifyContent: 'center',
		width: '100%',
		...(baseStyles.truncateText as CSSProperties),
	},
	link: {
		':focus': {
			textDecoration: 'underline',
		},
		':hover': {
			textDecoration: 'underline',
		},
		textDecoration: 'none',
	},
	quickActionButton: {
		':active': {
			background: brandPrimaryActive,
		},
		':hover': {
			background: brandPrimaryHover,
		},
		alignItems: 'center',
		background: brandPrimary,
		borderRadius: '50%',
		cursor: 'pointer',
		display: 'flex',
		height: 40,
		justifyContent: 'center',
		width: 40,
	},
	quickActionButtonFb: {
		backgroundImage: "url('../../../assets/facebook@2x.png')",
	},
	quickActionButtonLinkedin: {
		backgroundImage: "url('../../../assets/linkedin@2x.png')",
	},
	quickActionButtons: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		...CssSelectors.allChildren(
			{
				marginLeft: '0.5rem',
			},
			':not(:first-child)'
		),
	},
	quickActionButtonsCompact: {
		marginTop: 10,
	},
	quickActionButtonSocial: {
		backgroundPosition: 'left center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 42,
		borderRadius: '50%',
		display: 'inline-block',
		height: 42,
		width: 42,
	},
	quickActionButtonTwitter: {
		backgroundImage: "url('../../../assets/twitter@2x.png')",
	},
	reachability: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		marginTop: 20,
		...CssSelectors.allChildren(
			{
				marginTop: '0.5rem',
			},
			':not(:first-child)'
		),
	},
	reachabilityCompact: {
		flexDirection: 'column',
		justifyContent: 'center',
	},
	visibility: {
		alignItems: 'center',
		display: 'flex',
		flexGrow: 1,
		justifyContent: 'center',
	},
});
