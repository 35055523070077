import { urgentDate } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
	className?: string;
	fillColor?: string;
}

export const ClockAlertIcon: React.FC<IProps> = props => {
	const { className, fillColor, ...restProps } = props;
	return (
		<SvgIcon {...restProps} className={`clock-alert-icon ${className || ''}`} height={14} width={14}>
			<g fill={fillColor || urgentDate} fillRule='evenodd'>
				<path d='M7,2 C4.243,2 2,4.243 2,7 C2,9.757 4.243,12 7,12 C9.757,12 12,9.757 12,7 C12,4.243 9.757,2 7,2 M7,14 C3.14,14 0,10.86 0,7 C0,3.14 3.14,0 7,0 C10.86,0 14,3.14 14,7 C14,10.86 10.86,14 7,14' />
				<polygon points='10 8 6 8 6 3 8 3 8 6 10 6' />
			</g>
		</SvgIcon>
	);
};
