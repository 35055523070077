import { StyleSheet } from 'aphrodite';
import { brandPrimaryText } from '../../../styles/colors';

export const styleSheet = StyleSheet.create({
	avatars: {},
	container: {
		alignItems: 'center',
		display: 'flex',
	},
	title: {
		flexGrow: 1,
	},
	titleDescription: {
		alignItems: 'center',
		display: 'flex',
		flexGrow: 1,
	},
	titleEntityList: {
		alignItems: 'center',
		display: 'flex',
	},
	titleEntityName: {
		':active': {
			textDecoration: 'none',
		},
		':focus': {
			textDecoration: 'none',
		},
		':hover': {
			textDecoration: 'underline',
		},
		color: brandPrimaryText,
		maxWidth: 200,
		textDecoration: 'none',
	},
	titleWithAvatars: {
		marginLeft: 12,
	},
});
