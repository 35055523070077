import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	groupIcon: {
		height: 11,
		width: 14,
	},
	icon: {
		height: 13,
		width: 9,
	},
});
