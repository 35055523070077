import { styleSheet } from './styles';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';

export interface IColorCodedCardProps {
	bodyClassName?: string;
	contentClassName?: string;
	footer?: React.ReactNode;
	header?: React.ReactNode;
	hideHeader?: boolean;
	leftAccessory?: React.ReactNode;
	indicatorColor?: string;
	styles?: StyleDeclarationValue[];
}

export class ColorCodedCard extends React.PureComponent<
	IColorCodedCardProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> {
	public render() {
		const {
			className,
			children,
			footer,
			indicatorColor,
			header,
			hideHeader,
			styles,
			bodyClassName,
			contentClassName,
			leftAccessory,
			...restProps
		} = this.props;
		return (
			<div
				{...restProps}
				className={`${css(styleSheet.container, ...(styles || []))} color-coded-card ${className || ''}`}
			>
				<div
					className={`color-coded-card-indicator ${css(styleSheet.indicator)}`}
					style={indicatorColor ? { background: indicatorColor } : undefined}
				/>
				<div className={`${css(styleSheet.content)} ${contentClassName || ''}`}>
					{leftAccessory ? (
						<div className={css(styleSheet.flexContainer)}>
							{leftAccessory}
							<div className={css(styleSheet.flexChildRight)}>{this.mainContent()}</div>
						</div>
					) : (
						this.mainContent()
					)}
				</div>
			</div>
		);
	}

	private mainContent() {
		const { children, footer, header, hideHeader, bodyClassName } = this.props;
		return (
			<>
				{!hideHeader && <div className={css(styleSheet.header)}>{header}</div>}
				<div className={`${css(styleSheet.body, !footer ? styleSheet.bodyNoFooter : null)} ${bodyClassName || ''}`}>
					{children}
				</div>
				{!!footer && <div className={css(styleSheet.footer)}>{footer}</div>}
			</>
		);
	}
}
