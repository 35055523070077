import { styleSheet } from '../styles';
import { css } from 'aphrodite';
import * as React from 'react';

export const InputField: React.FunctionComponent<
	React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
> = props => {
	const { children, className, ...restProps } = props;
	return (
		<div className={`${css(styleSheet.formField)} ${className || ''}`}>
			<input className={css(styleSheet.input)} {...restProps} />
			{children}
		</div>
	);
};
