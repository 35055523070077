import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const PublicVisibilityIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;
	return (
		<SvgIcon className={`public-visibility-icon ${className || ''}`} height={13} width={9}>
			<g fill={fillColor || '#89C947'} fillRule='evenodd'>
				<path d='M5.46428571,9.23684211 C5.46428571,9.80336842 5.03228571,10.2631579 4.5,10.2631579 C3.96771429,10.2631579 3.53571429,9.80336842 3.53571429,9.23684211 C3.53571429,8.67031579 3.96771429,8.21052632 4.5,8.21052632 C5.03228571,8.21052632 5.46428571,8.67031579 5.46428571,9.23684211' />
				<path d='M1.28571429,11.6315789 L1.28571429,6.84210526 L7.71428571,6.84210526 L7.71492857,11.6315789 L1.28571429,11.6315789 Z M7.71428571,5.47368421 L2.57142857,5.47368421 L2.57142857,3.42105263 C2.57142857,2.28936842 3.43671429,1.36842105 4.5,1.36842105 C5.56328571,1.36842105 6.42857143,2.28936842 6.42857143,3.42105263 L6.42857143,4.10526316 L7.71428571,4.10526316 L7.71428571,3.42105263 C7.71428571,1.53468421 6.27235714,0 4.5,0 C2.72764286,0 1.28571429,1.53468421 1.28571429,3.42105263 L1.28571429,5.47368421 C0.576642857,5.47368421 0,6.08810526 0,6.84210526 L0,11.6315789 C0,12.3862632 0.576642857,13 1.28571429,13 L7.71428571,13 C8.42335714,13 9,12.3862632 9,11.6315789 L9,6.84210526 C9,6.08810526 8.42335714,5.47368421 7.71428571,5.47368421 L7.71428571,5.47368421 Z' />
			</g>
		</SvgIcon>
	);
};
