import { navigation } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	avatar: {
		height: 35,
		marginRight: 10,
		width: 35,
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
		width: 'calc(100% - 50px)',
	},
	email: {
		color: navigation,
		fontSize: '12px',
	},
	listItem: {
		alignItems: 'center',
		display: 'flex',
		padding: '10px 20px',
	},
	name: {
		color: '#000000',
		fontSize: '14px',
		textDecoration: 'none',
	},
	title: {
		color: navigation,
		fontSize: '12px',
		letterSpacing: 0,
	},
});
