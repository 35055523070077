import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '../../../models/AppState';
import { AdminPermission } from '../../../viewmodels/AdminViewModels';
import { LoadingSpinner } from '../../../web/components/LoadingSpinner';
import { asAdminUserSession } from '../../utils';

interface IProps extends IUserSessionComponentProps, IErrorMessageComponentProps {
	elseChildren?: React.ReactNode;
	hideLoading?: boolean;
	permission: AdminPermission;
	or?: AdminPermission | AdminPermission[];
}

class _ConditionalAccess extends React.Component<IProps> {
	private permissions: AdminPermission[];

	constructor(props: IProps) {
		super(props);

		this.permissions = [props.permission];

		if (props.or) {
			if (Array.isArray(props.or)) {
				this.permissions.push(...props.or);
			} else {
				this.permissions.push(props.or);
			}
		}
	}

	public render() {
		const { elseChildren, hideLoading } = this.props;

		if (!this.adminUserSession) {
			return null;
		}

		if (!!this.adminUserSession.isLoading || !!this.adminUserSession.isLoadingPermissions) {
			if (hideLoading) {
				return null;
			}

			return <LoadingSpinner type='tiny' />;
		}

		let hasPermission = false;
		for (const perm of this.permissions) {
			if (this.adminUserSession.hasPermission(perm)) {
				hasPermission = true;
				break;
			}
		}

		if (!hasPermission) {
			return elseChildren || <></>;
		}

		return <>{this.props.children}</>;
	}

	@computed
	private get adminUserSession() {
		// @ts-ignore
		return asAdminUserSession(this.props.userSession);
	}
}

const ObserverConditionalAccess = observer(_ConditionalAccess);
export const ConditionalAccess = inject(UserSessionViewModelKey, ErrorMessagesViewModelKey)(ObserverConditionalAccess);
