import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	checkFillColor?: string;
	className?: string;
	fillColor?: string;
}

export const TagCheckIcon: React.FC<IProps> = props => {
	const { className, checkFillColor, fillColor } = props;
	return (
		<SvgIcon height={24} width={24} className={`tag-check-icon ${className || ''}`}>
			<g fill='none' fillRule='evenodd'>
				<circle cx='12' cy='12' r='12' fill={fillColor || '#fff'} />
				<polygon
					fill={checkFillColor || '#fff'}
					points='10.901 18 6 12.974 8.143 10.776 10.515 13.209 15.535 6 18 7.805'
				/>
			</g>
		</SvgIcon>
	);
};
