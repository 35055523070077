import * as React from 'react';
import { SvgIcon } from '../SvgIcon';

export const HomeIcon = ({
	className,
	height = 20,
	width = 18,
	stroke = '#fff',
	...rest
}: React.SVGProps<SVGSVGElement>) => {
	return (
		<SvgIcon className={className} height={height} viewBox='0 0 18 20' width={width} {...rest}>
			<g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
				<g transform='translate(-29.000000, -170.000000)' stroke={stroke} strokeWidth='2'>
					<g transform='translate(30.000000, 171.000000)'>
						<path d='M0,6.3 L8,0 L16,6.3 L16,16.2 C16,17.1941125 15.2040618,18 14.2222222,18 L1.77777778,18 C0.795938223,18 0,17.1941125 0,16.2 L0,6.3 Z' />
						<polyline points='5.33333333 18 5.33333333 9 10.6666667 9 10.6666667 18' />
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
