import { css } from 'aphrodite';
import { computed } from 'mobx';
import { IReactComponent, observer } from 'mobx-react';
import * as React from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { LoginViewModel, UserSessionContext } from '../../../viewmodels/AppViewModels';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { Popover, PopoverType } from '../../components/Popover';
import { CityLeftGraphic } from '../../components/svgs/graphics/CityLeftGraphic';
import { CityRightGraphic } from '../../components/svgs/graphics/CityRightGraphic';
import { MoonAndStarsGraphic } from '../../components/svgs/graphics/MoonAndStarsGraphic';
import { LogoIcon2023 } from '../../components/svgs/icons/LogoIcon2023';
import { baseStyleSheet } from '../../styles/styles';
import { ForgotPasswordModal } from './ForgotPasswordModal';
import { ILoginContext, LoginContext } from './context';
import {
	FormFieldErrorMessagePopover,
	IFormFieldErrorMessagePopoverProps,
	ILoginFormFieldProps,
	Login2FAFields,
	LoginEmailField,
	LoginPasswordField,
	LoginPhoneNumberField,
} from './presentation';
import { pluginStyleSheet, styleSheet } from './styles';

export interface ILoginProps {
	autoRedirectIfAuthenticated?: boolean;
	className?: string;
	colorScheme?: string;
	loginMode?: 'standard' | 'plugin' | 'admin';
	loginVM?: LoginViewModel;
	showRememberMe?: boolean;
	showSignupButton?: boolean;
	userSession?: UserSessionContext;
}

export interface ILoginComponent {
	EmailField: React.FC<ILoginFormFieldProps>;
	FormFieldErrorMessagePopover: React.FC<IFormFieldErrorMessagePopoverProps>;
	PasswordField: React.FC<ILoginFormFieldProps>;
	PhoneNumberField: React.FC<ILoginFormFieldProps>;
	TwoFactorFields: React.FC<ILoginFormFieldProps>;
}

interface IState {
	redirect?: boolean;
	showForgotPasswordDialog?: boolean;
	loginVM?: LoginViewModel;
}

class _Login extends React.Component<
	ILoginProps & RouteComponentProps<any, any, { from?: Location; search?: string }>,
	IState
> {
	private mLoginContext: ILoginContext;
	public static defaultProps: ILoginProps = {
		autoRedirectIfAuthenticated: true,
		loginMode: 'standard',
		showRememberMe: true,
		showSignupButton: true,
	};

	constructor(props: ILoginProps & RouteComponentProps<any, any, { from?: Location; search?: string }>) {
		super(props);
		this.mLoginContext = {
			loginViewModel: props.loginVM || this.getDefaultViewModel(),
		};
		this.state = {
			loginVM: this.mLoginContext.loginViewModel,
			// @ts-ignore
			redirect: !!props.autoRedirectIfAuthenticated && !!props.userSession.isAuthenticated,
			showForgotPasswordDialog: false,
		};
	}

	public readonly state: IState = {};

	private getDefaultViewModel() {
		// @ts-ignore
		return new LoginViewModel(this.props.userSession, () => this.setState({ redirect: true }));
	}

	@computed
	private get isPluginMode() {
		const { loginMode } = this.props;
		return loginMode === 'plugin';
	}

	@computed
	private get isStandardMode() {
		const { loginMode } = this.props;
		return loginMode === 'standard' || loginMode === 'admin';
	}

	@computed
	private get showingTwoFA() {
		const { loginVM } = this.state;
		return loginVM?.needsPhone || loginVM?.challenging;
	}

	public render() {
		const { userSession } = this.props;
		const { redirect } = this.state;

		// @ts-ignore
		if (!!redirect && userSession.isAuthenticated) {
			return this.renderRedirect();
		}

		return (
			<LoginContext.Provider value={this.mLoginContext}>
				{this.isStandardMode ? (
					this.getStandardContent()
				) : this.isPluginMode ? (
					this.getPluginContent()
				) : (
					<div>No matching login mode found.</div>
				)}
			</LoginContext.Provider>
		);
	}

	private renderRedirect() {
		const { location, loginMode } = this.props;
		let pathname = '/dashboard';
		let search: string;
		if (this.isPluginMode) {
			pathname = '/plugin';
		} else if (loginMode === 'admin') {
			pathname = '/accounts';
		}

		const fromLocation = location.state?.from;
		if (fromLocation) {
			pathname = fromLocation.pathname;
			if (fromLocation.search) {
				search = fromLocation.search;
			}
		}

		return (
			<Redirect
				to={{
					pathname,
					// @ts-ignore
					search: search || location.state?.search,
					state: { from: location },
				}}
			/>
		);
	}

	private getPluginContent() {
		const { loginVM } = this.state;
		return (
			<div className={css(styleSheet.pluginContainer)}>
				{loginVM?.isBusy ? (
					<LoadingSpinner type='large' className={css(styleSheet.loader)} />
				) : (
					<div className={css(styleSheet.login)}>
						<LogoIcon2023 className={css(pluginStyleSheet.logo)} />
						<div className={css(pluginStyleSheet.formError)}>{loginVM?.getErrorMessage('global')}</div>
						<div className={css(pluginStyleSheet.form)}>
							{/* @ts-ignore */}
							<Login.EmailField />
							{/* @ts-ignore */}
							<Login.PasswordField onKeyDown={this.showingTwoFA ? this.onLastFieldKeyDown : undefined} />
							{/* @ts-ignore */}
							<Login.TwoFactorFields labelStyles={[pluginStyleSheet.resend]} />
							<button className={css(styleSheet.submitButton)} onClick={this.onSubmitSignInForm}>
								Sign In
							</button>
							<div className={css(styleSheet.footer)}>
								<span onClick={this.onForgotPasswordPlugInLinkClick} className={css(styleSheet.forgotPasswordLink)}>
									Forgot Password
								</span>
							</div>
						</div>
						<a className={css(styleSheet.signUpButton)} href='https://levitate.ai/#/pricing'>
							Don&apos;t have an account? Sign Up
						</a>
					</div>
				)}
			</div>
		);
	}

	private getStandardContent() {
		const { loginVM, showForgotPasswordDialog } = this.state;
		const { showSignupButton, userSession } = this.props;
		return (
			<div className={css(styleSheet.standardContainer)}>
				<div className={css(styleSheet.flexContainer)}>
					<div>
						{/* @ts-ignore */}
						{!loginVM.isBusy && <LogoIcon2023 className={css(styleSheet.logo)} />}
						{loginVM?.isBusy ? (
							<LoadingSpinner type='login' className={css(styleSheet.loader)} />
						) : (
							<div className={css(styleSheet.login)}>
								<div className={css(styleSheet.graphicContainer)}>
									<MoonAndStarsGraphic className={css(styleSheet.moonAndStars)} />
									<CityLeftGraphic className={css(styleSheet.cityLeft)} />
									<CityRightGraphic className={css(styleSheet.cityRight)} />
									<div className={css(styleSheet.containerBottom)} />
								</div>
								<div className={css(styleSheet.loginForm)}>
									{/* @ts-ignore */}
									<Login.FormFieldErrorMessagePopover fieldName='email'>
										{/* @ts-ignore */}
										<Login.EmailField />
									</Login.FormFieldErrorMessagePopover>
									{/* @ts-ignore */}
									<Login.FormFieldErrorMessagePopover fieldName='password'>
										{/* @ts-ignore */}
										<Login.PasswordField onKeyDown={!this.showingTwoFA ? this.onLastFieldKeyDown : undefined} />
									</Login.FormFieldErrorMessagePopover>
									{/* @ts-ignore */}
									<Login.TwoFactorFields />
									<Popover
										anchor={
											<button className={css(styleSheet.submitButton)} onClick={this.onSubmitSignInForm}>
												{loginVM?.needsPhone ? 'Next' : loginVM?.challenging ? 'Verify' : 'Sign In'}
											</button>
										}
										isOpen={loginVM?.hasErrorMessage('global')}
										type={PopoverType.error}
										contentClassName='login-error-popover'
										preferredPlacement='right'
									>
										<span>{loginVM?.getErrorMessage('global')}</span>
									</Popover>
									<div className={css(styleSheet.footer)}>
										{!loginVM?.challenging && !loginVM?.needsPhone && (
											<span
												onClick={this.onSetForgotPasswordModalOpen(true)}
												className={css(styleSheet.forgotPasswordLink)}
											>
												Forgot Password
											</span>
										)}
									</div>
								</div>
								{!!showSignupButton && (
									<a className={css(styleSheet.signUpButton)} href='https://levitate.ai/#/pricing'>
										Don&apos;t have an account? Sign Up
									</a>
								)}
								<span className={css(styleSheet.googleMessage)}>
									{
										"For GSuite and Gmail Users: Levitate's use of information received from Google APIs will adhere to "
									}
									<a
										className={css(baseStyleSheet.brandSecondaryLink)}
										href='https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes'
										target='_blank'
										rel='noreferrer'
									>
										Google API Services User Data Policy
									</a>
									, including the Limited Use requirements.
								</span>
								<ForgotPasswordModal
									modalProps={{
										isOpen: showForgotPasswordDialog,
										onRequestClose: this.onSetForgotPasswordModalOpen(false),
									}}
									userSession={userSession}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}

	private onForgotPasswordPlugInLinkClick = () => {
		this.props.history.push('/user/forgot-password');
	};

	private onSetForgotPasswordModalOpen = (show: boolean) => () => {
		this.setState({ showForgotPasswordDialog: show });
	};

	/** Handle the event where the user hits enter in while editing the password field. */
	private onLastFieldKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.keyCode === 13 || e.key === 'Enter') {
			this.onSubmitSignInForm();
		}
	};

	private onSubmitSignInForm = () => {
		// @ts-ignore
		this.state.loginVM.submit();
	};
}

export const Login: Partial<ILoginComponent> & IReactComponent<ILoginProps> = withRouter(observer(_Login));

Login.EmailField = LoginEmailField;
Login.FormFieldErrorMessagePopover = FormFieldErrorMessagePopover;
Login.PasswordField = LoginPasswordField;
Login.PhoneNumberField = LoginPhoneNumberField;
Login.TwoFactorFields = Login2FAFields;
