import { GroupVisibilityIcon } from '../svgs/icons/GroupVisibilityIcon';
import { PrivateVisibilityIcon } from '../svgs/icons/PrivateVisibilityIcon';
import { PublicVisibilityIcon } from '../svgs/icons/PublicVisibilityIcon';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	color?: string;
	title?: string;
	visibility?: 'admin' | 'all' | string;
}

export const VisibilityIndicatorIcon: React.FC<IProps> = props => {
	const { className = '', visibility, color, title } = props;
	// @ts-ignore
	let content: JSX.Element = null;

	if (visibility === 'all') {
		content = <PublicVisibilityIcon fillColor={color} className={`${css(styleSheet.icon)} ${className}`} />;
	}

	if (!content && visibility === 'admin') {
		content = <PrivateVisibilityIcon className={`${css(styleSheet.icon)} ${className}`} fillColor={color} />;
	}

	if (!content) {
		// group
		content = (
			<GroupVisibilityIcon
				className={`${css(styleSheet.icon)}  ${css(styleSheet.groupIcon)} ${className}`}
				strokeColor={color}
			/>
		);
	}

	if (title) {
		return <span title={title}>{content}</span>;
	}

	return content;
};
