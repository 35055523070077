import { nameCircles, titles } from '../../../styles/colors';
import { baseStyles, truncateTextStyle } from '../../../styles/styles';
import { CSSProperties, StyleSheet } from 'aphrodite';

export const FeedCardAvatarSize = 35;

export const styleSheet = StyleSheet.create({
	avatar: {
		fontSize: 14,
		height: FeedCardAvatarSize,
		width: FeedCardAvatarSize,
	},
	content: {
		alignItems: 'center',
		display: 'flex',
	},
	overflow: {
		alignItems: 'center',
		background: nameCircles,
		border: '1px solid #fff',
		borderRadius: '50%',
		boxSizing: 'border-box',
		color: '#fff',
		cursor: 'pointer',
		display: 'inline-flex',
		fontSize: 14,
		height: FeedCardAvatarSize,
		justifyContent: 'center',
		position: 'relative',
		width: FeedCardAvatarSize,
		...truncateTextStyle,
	},
	overflowPopoverContent: {},
	overflowPopoverEntityDetails: {
		marginLeft: 15,
		width: `calc(100% - ${FeedCardAvatarSize + 15}px)`,
		...truncateTextStyle,
	},
	overflowPopoverEntityName: {
		color: titles,
		fontSize: 14,
		...truncateTextStyle,
	},
	overflowPopoverEntityRow: {
		':hover': {
			...(baseStyles.dropdownMenuItemHighlighted as CSSProperties),
		},
		alignItems: 'center',
		boxSizing: 'border-box',
		cursor: 'pointer',
		display: 'flex',
		maxWidth: 300,
		minWidth: FeedCardAvatarSize,
		padding: '5px 15px',
	},
	overflowPopoverEntityTitle: {
		color: '#8E8E8E',
		fontSize: 12,
		...truncateTextStyle,
	},
});
