import { brandPrimaryHover, darkGrayFontColor, emailActivityTintColor } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { SpinnerLargeSize } from '../../LoadingSpinner/styles';
import { StyleSheet } from 'aphrodite';

const LoadingMarginTop = 30;

export const styleSheet = StyleSheet.create({
	actionItemRightAccessory: {
		color: brandPrimaryHover,
		cursor: 'pointer',
	},
	container: {
		paddingBottom: SpinnerLargeSize + LoadingMarginTop,
		position: 'relative',
		...CssSelectors.allChildren(
			{
				borderBottom: '1px solid #d8d8d8',
			},
			':not(:last-child):not(.placeholder-container)'
		),
	},
	item: {
		border: 'none',
		padding: '30px 0px 30px 25px',
		...CssSelectors.allDescendants(
			{
				overflowX: 'auto',
			},
			'.rich-content-document-editor *[class^="readOnlyContent"]'
		),
	},
	loading: {
		marginTop: 30,
	},
	mentionField: {
		':nth-child(1n).note-editor-mention-field': {
			borderBottom: 'none',
			borderTop: 'none',
		},
	},
	moreMenu: {
		marginLeft: 20,
	},
	noteEditor: {
		':nth-child(1n).note-editor-body-editor': {
			minHeight: 0,
		},
	},
	noteEditorBody: {
		marginTop: 14,
	},
	textingIcon: {
		marginLeft: 10,
	},
	textingIconSvg: {
		height: 16,
		width: 19,
	},
	viewedOn: {
		alignItems: 'center',
		background: '#f2f2f2',
		borderRadius: 5,
		color: darkGrayFontColor,
		display: 'flex',
		padding: 5,
		...CssSelectors.allChildren({
			background: emailActivityTintColor,
			borderRadius: '50%',
			display: 'inline-block',
			height: 18,
			marginRight: 8,
			position: 'relative',
			width: 18,
		}),
	},
});
