import { IFabContext } from '../../../models';
import { FabViewModelKey, IFabComponentProps } from '../../../models/AppState';
import { inject } from 'mobx-react';
import * as React from 'react';

class _FabContext<T = any> extends React.PureComponent<IFabContext<T> & IFabComponentProps> {
	// @ts-ignore
	private mFabContextDisposer: () => void;
	public componentDidMount() {
		this.registerContext();
	}

	public componentWillUnmount() {
		this.unregisterContext();
	}

	public componentDidUpdate() {
		this.registerContext();
	}

	public render() {
		// @ts-ignore
		return null as JSX.Element;
	}

	private unregisterContext = () => {
		if (this.mFabContextDisposer) {
			this.mFabContextDisposer();
		}
	};

	private registerContext = () => {
		this.unregisterContext();
		const { fab, ...restProps } = this.props;
		// @ts-ignore
		const disposer = fab.registerContext(restProps || {});

		this.mFabContextDisposer = () => {
			disposer();
			// @ts-ignore
			this.mFabContextDisposer = null;
		};
	};
}

export const FabContext = inject(FabViewModelKey)(_FabContext);
