import { StyleSheet } from 'aphrodite';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	searchField: {
		...CssSelectors.allDescendants(
			{
				width: '100%',
			},
			'.autocomplete-search-field-input'
		),
	},
	searchFieldClearIcon: {
		height: 16,
		width: 16,
	},
	searchFieldDropDown: {
		width: '100% !important',
	},
	searchFieldDropDownContent: {
		maxHeight: 300,
		overflow: 'auto',
		width: '362px !important',
	},
	searchFieldIcon: {
		flexShrink: 0,
		marginRight: 8,
	},
});
