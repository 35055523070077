import { SvgIcon } from '../SvgIcon';

export const AIStarsIcon = ({ height = 14, width = 14, stopColorOne = '#32C5FF', stopColorTwo = '#B620E0' }) => {
	return (
		<SvgIcon height={height} width={width} fill='none' viewBox='0 0 16 16'>
			<defs>
				<linearGradient id='gradient_0' gradientUnits='userSpaceOnUse' x1='9.2935915' y1='4.1609106' x2='0' y2='18'>
					<stop offset='0%' stopColor={stopColorOne} />
					<stop offset='51%' stopColor={stopColorTwo} />
				</linearGradient>
			</defs>
			<path
				fill='url(#gradient_0)'
				d='M6.1764708 2.4545455C6.1764708 6.747292 8.9417706 10.227252 12.352942 10.227252C8.9417706 10.227252 6.1764708 13.707211 6.1764708 18C6.1764708 13.707211 3.4111702 10.227252 0 10.227252C3.4111702 10.227252 6.1764708 6.747292 6.1764708 2.4545455ZM11.117647 0C11.117647 2.0334063 12.40812 3.6818082 14 3.6818082C12.40812 3.6818082 11.117647 5.3302102 11.117647 7.3636365C11.117647 5.3302102 9.8271732 3.6818082 8.2352943 3.6818082C9.8271732 3.6818082 11.117647 2.0334063 11.117647 0Z'
				fillRule='evenodd'
			/>
		</SvgIcon>
	);
};
