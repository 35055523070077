import { baseStyleSheet } from '../../../styles/styles';
import { LoadingSpinner } from '../../LoadingSpinner';
import { RadioButton } from '../../RadioButton';
import {
	ISimpleAutoCompleteSearchFieldEvent,
	ISimpleAutoCompleteSearchFieldItemSelectionEvent,
	SimpleAutoCompleteSearchField,
} from '../../autocomplete/SimpleAutoCompleteSearchField';
import { styleSheet } from './styles';
import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	userSession: Api.IUserSession;
	preview: Api.IPreviewCreateAutomationResult;
	isBusy: boolean;
	onAutomationSelectionComesFrom?: (type: Api.AutomationSelectionType) => void;
	onSelectedEmployee: (employee: Api.IUser | null) => void;
}

export const AutomationStepsBy = ({
	userSession,
	preview,
	isBusy,
	onAutomationSelectionComesFrom,
	onSelectedEmployee,
}: IProps) => {
	const contactOwners = preview?.contactOwners?.slice(0, 3) || [];
	// @ts-ignore
	const previewContactsOverflowCount = preview?.contactOwners?.length - contactOwners?.length || 0;
	const [automationSelectionComesFrom, setAutomationSelectionComesFrom] = React.useState<Api.AutomationSelectionType>(
		Api.AutomationSelectionType.Myself
	);
	const [selectedEmployee, setSelectedEmployee] = React.useState<Api.IUser | null>(null);
	const [event, setEvent] = React.useState(null);
	const onSelectionChanged = (type: Api.AutomationSelectionType) => {
		setAutomationSelectionComesFrom(type);
		// @ts-ignore
		onAutomationSelectionComesFrom(type);
		if (event && type !== Api.AutomationSelectionType.Employee) {
			clearSelectedUser();
			// @ts-ignore
			event.target?.clearInput();
		}
	};
	const onSearchFieldBlur = (e: ISimpleAutoCompleteSearchFieldEvent<React.FocusEvent<HTMLInputElement>>) => {
		if (!selectedEmployee && e.target) {
			e.target.clearInput();
			return;
		}
		// @ts-ignore
		setEvent(e);
	};

	const onSearchFieldKeyDown = (e: ISimpleAutoCompleteSearchFieldEvent<React.KeyboardEvent<HTMLInputElement>>) => {
		if (e.sourceEvent && e.sourceEvent.key !== 'Enter') {
			clearSelectedUser();
		}
	};

	const onUserSelected = (e: ISimpleAutoCompleteSearchFieldItemSelectionEvent<Api.IUser>) => {
		if (e.target) {
			e.target.setSearchQuery(Api.VmUtils.getDisplayName(e.selection));
		}

		setSelectedEmployee(e.selection);
		onSelectedEmployee(e.selection);
	};

	const clearSelectedUser = () => {
		setSelectedEmployee(null);
		onSelectedEmployee(null);
	};

	if (isBusy) {
		return <LoadingSpinner type='small' />;
	}

	return (
		<div>
			<div className={css(styleSheet.formControl)}>
				<RadioButton
					checked={automationSelectionComesFrom === Api.AutomationSelectionType.Myself}
					id='selectMyself'
					name='comes-from-selection'
					onChange={() => onSelectionChanged(Api.AutomationSelectionType.Myself)}
				>
					{/* @ts-ignore */}
					<div>{`Myself (${userSession?.user.email})`}</div>
				</RadioButton>
			</div>
			<div className={css(styleSheet.formControl, styleSheet.formControlAdjusted)}>
				<RadioButton
					checked={automationSelectionComesFrom === Api.AutomationSelectionType.ContactOwners}
					id='selectOwners'
					name='comes-from-selection'
					onChange={() => onSelectionChanged(Api.AutomationSelectionType.ContactOwners)}
				>
					<div className={css(styleSheet.contactOwners)}>
						Contact Owners (
						<>
							{contactOwners.map((x: Partial<Api.IProjectedContact>, idx: number) => {
								const firstname = x.firstName || Api.VmUtils.getEntityDisplayName(x);
								return (
									<React.Fragment key={x.id}>
										<span className={css(baseStyleSheet.truncateText)}>{firstname}</span>
										{idx < contactOwners.length - 1 && (
											<span>
												,&nbsp;
												{idx + 1 === contactOwners.length - 1 && previewContactsOverflowCount < 1 && <>and&nbsp;</>}
											</span>
										)}
									</React.Fragment>
								);
							})}
							{previewContactsOverflowCount > 0 && (
								<span>
									&nbsp;
									{`and ${previewContactsOverflowCount} other${previewContactsOverflowCount === 1 ? '' : 's'}`}
								</span>
							)}
						</>
						)
					</div>
				</RadioButton>
			</div>
			<div className={css(styleSheet.formControl)}>
				<RadioButton
					checked={automationSelectionComesFrom === Api.AutomationSelectionType.Employee}
					id='selectEmployee'
					name='comes-from-selection'
					onChange={() => onSelectionChanged(Api.AutomationSelectionType.Employee)}
				>
					<>Specific employee</>
				</RadioButton>
				<div className={css(styleSheet.searchFieldWrap)}>
					<SimpleAutoCompleteSearchField
						id='employeeSearchField'
						disabled={isBusy || automationSelectionComesFrom !== Api.AutomationSelectionType.Employee}
						onBlur={onSearchFieldBlur}
						onClear={clearSelectedUser}
						// @ts-ignore
						onItemSelected={onUserSelected}
						onKeyDown={onSearchFieldKeyDown}
						pageSize={5}
						placeholder='Search employees'
						resultsLimit={5}
						type={Api.ResourceAutoCompleteViewModelType.User}
					/>
				</div>
			</div>
		</div>
	);
};
