import { SvgIcon } from '../../icons/SvgIcon';
import * as React from 'react';

export function GiftingSuccessGraphic(props: React.SVGProps<SVGSVGElement>) {
	return (
		<SvgIcon width={173} height={173} viewBox='0 0 173 173' {...props}>
			<circle cx='86.5' cy='86.5' r='86.5' fill='#E8F3F9' />
			<mask id='a' width='173' height='173' x='0' y='0' maskUnits='userSpaceOnUse' style={{ maskType: 'luminance' }}>
				<circle cx='86.5' cy='86.5' r='86.5' fill='#fff' />
			</mask>
			<g mask='url(#a)'>
				<path fill='#046CB6' d='M21 84h131v95H21z' />
				<path fill='#00AAE8' d='M76 103h23v76H76z' />
				<path fill='#000' d='M23 92v15.048L151 114l-.377-18.568L23 92Z' opacity='.2' />
				<path fill='#046CB6' d='M14 75h145v28H14z' />
				<path fill='#00AAE8' d='M76 73h23v30H76z' />
				<path
					fill='#00AAE8'
					d='M87.09 46.91c6.327 10.769.366 28.09.366 28.09s-16.22-.14-22.546-10.91C58.583 53.322 64.544 36 64.544 36s16.22.14 22.546 10.91Z'
				/>
				<path
					fill='#00AAE8'
					d='M87.91 46.91C81.583 57.678 87.544 75 87.544 75s16.219-.14 22.546-10.91c6.327-10.769.366-28.09.366-28.09s-16.22.14-22.546 10.91Z'
				/>
			</g>
			<circle cx='27' cy='34' r='19' fill='#89C947' stroke='#DBF2C3' strokeWidth='5' />
			<path
				fill='#fff'
				fillRule='evenodd'
				d='M25.403 43.362 17.72 35.68l3.359-3.36 3.718 3.72 7.87-11.019 3.865 2.759-11.129 15.582Z'
				clipRule='evenodd'
			/>
		</SvgIcon>
	);
}
