import { nameCircles } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

export const PostcardType = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<SvgIcon width='60' height='38' viewBox='0 0 60 38' fill='none' {...props}>
			<path fill={nameCircles} fillOpacity='.388' stroke='#D3DBDE' strokeWidth='2' d='M53.5 7.374v37h-48v-37h48Z' />
			<path fill='#fff' stroke={nameCircles} strokeWidth='2' d='M49.5 1.374v37h-48v-37h48Z' />
		</SvgIcon>
	);
};
