import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useEventLogging } from '../../../../models/Logging';
import { isAccountFinancialReviewEnabled } from '../../../../models/UiUtils';
import { useErrorMessages, useUserSession } from '../../../../models/hooks/appStateHooks';
import {
	useAllClassifiedContactPolicies,
	useContactKeyDateCreateMutation,
	useHouseholdCreateMutation,
} from '../../../../queries';
import { ISelectOption, Select } from '../../../components/Select';
import { TextInput } from '../../../components/TextInput';
import { SimpleAutoCompleteSearchField } from '../../../components/autocomplete/SimpleAutoCompleteSearchField';
import { PolicySelector } from '../../../components/email/PolicySelector';
import { bs } from '../../../styles/styles';
import { styleSheet } from './styles';

interface Props {
	compactLayout: boolean;
	contact: Api.IContact;
	onClose: () => void;
	onSuccess: () => void;
}

const getKeyFactOptions = (
	accountPreferences: Api.IAccountPreferences,
	contact: Api.IContact,
	policyBoardEnabled: boolean
) => {
	const options: ISelectOption<Api.KeyDateKind | 'General' | 'Household'>[] = [
		{
			text: 'General',
			dataContext: 'General',
			id: 'General',
		},
		{
			text: 'Birthday',
			dataContext: Api.KeyDateKind.Birthday,
			id: Api.KeyDateKind.Birthday,
		},
		{
			text: 'Anniversary',
			dataContext: Api.KeyDateKind.Anniversary,
			id: Api.KeyDateKind.Anniversary,
		},
		{
			text: 'Wedding',
			dataContext: Api.KeyDateKind.Wedding,
			id: Api.KeyDateKind.Wedding,
		},
	];
	if (!policyBoardEnabled) {
		options.push({
			text: 'Renewal',
			dataContext: Api.KeyDateKind.Renewal,
			id: Api.KeyDateKind.Renewal,
		});
	}
	if (!contact.household?.id) {
		options.push({
			text: 'Household',
			dataContext: 'Household',
			id: 'Household',
		});
	}
	if (isAccountFinancialReviewEnabled(accountPreferences)) {
		options.push({
			text: 'Financial Review',
			dataContext: Api.KeyDateKind.FinancialReview,
			id: Api.KeyDateKind.FinancialReview,
		});
	}
	if (accountPreferences.resourceSelectorSettings) {
		for (const customKeyDateKind of [
			Api.ResourceSelectorId.Custom1,
			Api.ResourceSelectorId.Custom2,
			Api.ResourceSelectorId.Custom3,
			Api.ResourceSelectorId.Custom4,
			Api.ResourceSelectorId.Custom5,
			Api.ResourceSelectorId.Custom6,
			Api.ResourceSelectorId.Custom7,
			Api.ResourceSelectorId.Custom8,
			Api.ResourceSelectorId.Custom9,
			Api.ResourceSelectorId.Custom10,
		]) {
			const resourceSetting = accountPreferences.resourceSelectorSettings[customKeyDateKind];
			if (resourceSetting) {
				options.push({
					dataContext: customKeyDateKind as unknown as Api.KeyDateKind,
					id: customKeyDateKind,
					text: resourceSetting.displayName,
				});
			}
		}
	}
	return options;
};

const validateDateForKeyFacts = ({ day, month, year }: { day: number; month: number; year: number }) => {
	if (!day) {
		return 'Please enter a day';
	}
	if (!month) {
		return 'Please pick a month';
	}
	if (day < 1 || day > 31) {
		return 'Invalid day';
	}
	const date = new Date(year, month - 1, day);
	if (isNaN(date.getTime())) {
		return 'Invalid date';
	}
};

export function ContactInfoSmartKeyFactsModalContent({ onClose, contact, onSuccess, compactLayout }: Props) {
	const contactId = contact.id;
	const errorMessages = useErrorMessages();
	const userSession = useUserSession();
	const { logApiError } = useEventLogging('ContactInfoSmartKeyFactsModal');

	const policyBoardEnabled = userSession.account?.features?.boards?.policiesEnabled;

	const keyFactOptions = getKeyFactOptions(userSession.account.preferences, contact, policyBoardEnabled);
	const keyFactOptionsRef = React.useRef(keyFactOptions);
	keyFactOptionsRef.current = keyFactOptions;

	const [day, setDay] = React.useState<number | null>(null);
	const [month, setMonth] = React.useState<number | null>(null);
	const [year, setYear] = React.useState<number | null>(null);
	const [selectedKeyFactTypeOption, setSelectedKeyFactTypeOption] = React.useState(keyFactOptions[0]);
	const [birthdayPersonName, setBirthdayPersonName] = React.useState('');
	const [spouseContactId, setSpouseContactId] = React.useState<string | null>(null);
	const [selectedPolicies, setSelectedPolicies] = React.useState<string[]>([]);
	const keyFactInputRef = React.useRef<HTMLInputElement | null>(null);

	const contactKeyDateCreateMutation = useContactKeyDateCreateMutation({
		onError: error => {
			logApiError('Error creating key fact', error);
			errorMessages.pushApiError(error);
		},
		onSuccess: () => {
			onClose();
			onSuccess();
		},
	});
	const householdCreateMutation = useHouseholdCreateMutation({
		onError: error => {
			logApiError('Error creating key household', error);
			errorMessages.pushApiError(error);
		},
		onSuccess: () => {
			onClose();
			onSuccess();
		},
	});
	const handleSave = () => {
		if (selectedKeyFactTypeOption.dataContext === 'General') {
			if (!keyFactInputRef.current?.value) {
				errorMessages.push({ messages: ['Please enter a text'] });
				return;
			}
			contactKeyDateCreateMutation.mutate({
				contactId,
				keyFactMetaData: {
					_type: Api.IKeyFactType.CustomerReferenceKeyFactMetadata,
					label: keyFactInputRef.current.value,
				} as Api.ICustomerReferenceKeyFactMetadata,
			});
			return;
		}
		if (selectedKeyFactTypeOption.dataContext === Api.KeyDateKind.Birthday) {
			if (!year) {
				errorMessages.push({ messages: ['Please enter a year'] });
				return;
			}
			const validationError = validateDateForKeyFacts({ day, month, year });
			if (validationError) {
				errorMessages.push({ messages: [validationError] });
				return;
			}
			const date = new Date(year, month - 1, day);
			contactKeyDateCreateMutation.mutate({
				contactId,
				keyFactMetaData: {
					_type: Api.IKeyFactType.BirthInfoKeyFactMetadata,
					birthInfo: {
						date: date.toISOString(),
						name: birthdayPersonName,
					},
				} as Api.IBirthInfoKeyFactMetadata,
				ignoreYear: !year,
			});
			return;
		}
		if (
			[
				Api.KeyDateKind.Anniversary,
				Api.KeyDateKind.FinancialReview,
				Api.KeyDateKind.Wedding,
				Api.ResourceSelectorId.Custom1,
				Api.ResourceSelectorId.Custom2,
				Api.ResourceSelectorId.Custom3,
				Api.ResourceSelectorId.Custom4,
				Api.ResourceSelectorId.Custom5,
				Api.ResourceSelectorId.Custom6,
				Api.ResourceSelectorId.Custom7,
				Api.ResourceSelectorId.Custom8,
				Api.ResourceSelectorId.Custom9,
				Api.ResourceSelectorId.Custom10,
			].includes(selectedKeyFactTypeOption.dataContext)
		) {
			const validationError = validateDateForKeyFacts({ day, month, year });
			if (validationError) {
				errorMessages.push({ messages: [validationError] });
				return;
			}
			const date = new Date(year || 0, month - 1, day);
			contactKeyDateCreateMutation.mutate({
				contactId,
				keyFactMetaData: {
					_type: Api.IKeyFactType.CustomerReferenceDateKeyFactMetadata,
					date: date.toISOString(),
					keyDateKind: selectedKeyFactTypeOption.dataContext,
				} as Api.ICustomerReferenceDateKeyFactMetadata,
				ignoreYear: !year,
			});
			return;
		}
		if (selectedKeyFactTypeOption.dataContext === 'Household') {
			if (!spouseContactId) {
				errorMessages.push({ messages: ['Please select a spouse'] });
				return;
			}
			householdCreateMutation.mutate({
				createHouseholdRequest: {
					relationships: [
						{
							contactId,
							relationship: {
								title: Api.HouseholdTitle.Spouse,
							},
						},
						{
							contactId: spouseContactId,
							relationship: {
								title: Api.HouseholdTitle.Spouse,
							},
						},
					],
				},
			});
		}
		if (selectedKeyFactTypeOption.dataContext === Api.KeyDateKind.Renewal) {
			if (!selectedPolicies.length) {
				errorMessages.push({ messages: ['Please select a policy'] });
				return;
			}
			const validationError = validateDateForKeyFacts({ day, month, year });
			if (validationError) {
				errorMessages.push({ messages: [validationError] });
				return;
			}
			const date = new Date(year || 0, month - 1, day);
			contactKeyDateCreateMutation.mutate({
				contactId,
				keyFactMetaData: {
					_type: Api.IKeyFactType.PolicyKeyFactMetadata,
					renewalDate: date.toISOString(),
					lineOfBusiness: selectedPolicies[0],
				} as Api.IPolicyKeyFactMetaData,
				ignoreYear: !year,
			});
			return;
		}
	};
	return (
		<div style={{ width: !compactLayout ? 420 : undefined }}>
			<header className={css(bs.pb5, bs.textBrandSecondary, bs.textLg)}>New Key Fact</header>
			<div className={css(bs.flex, bs.flexCol, bs.gap4, bs.mb4)}>
				<div>
					<label className={css(bs.textLabel, bs.block, bs.textSm, bs.mb1)}>Key Fact Type</label>
					<Select
						styles={[bs.wFull, bs.maxWFull]}
						options={keyFactOptions}
						selectedOption={selectedKeyFactTypeOption}
						onOptionClick={option => setSelectedKeyFactTypeOption(option)}
					/>
				</div>
				{selectedKeyFactTypeOption.dataContext === 'General' ? (
					<>
						<div>
							<label htmlFor='keyFactInput' className={css(bs.textLabel, bs.block, bs.textSm, bs.mb1)}>
								Text
							</label>
							<TextInput inputId='keyFactInput' type='input' inputRef={keyFactInputRef} />
						</div>
					</>
				) : null}
				{selectedKeyFactTypeOption.dataContext === Api.KeyDateKind.Birthday ? (
					<>
						<SplitDatePicker
							day={day}
							month={month}
							year={year}
							onChangeDay={setDay}
							onChangeMonth={setMonth}
							onChangeYear={setYear}
						/>
						<div>
							<label className={css(bs.textLabel, bs.block, bs.textSm, bs.mb1)}>Whose birthday is it? (Optional)</label>
							<TextInput
								type='input'
								inputId='birthday-person-name'
								value={birthdayPersonName}
								onChange={ev => setBirthdayPersonName(ev.target.value)}
							/>
						</div>
					</>
				) : null}
				{[
					Api.KeyDateKind.Anniversary,
					Api.KeyDateKind.FinancialReview,
					Api.KeyDateKind.Wedding,
					Api.KeyDateKind.Custom1,
					Api.KeyDateKind.Custom2,
					Api.KeyDateKind.Custom3,
					Api.KeyDateKind.Custom4,
					Api.KeyDateKind.Custom5,
					Api.KeyDateKind.Custom6,
					Api.KeyDateKind.Custom7,
					Api.KeyDateKind.Custom8,
					Api.KeyDateKind.Custom9,
					Api.KeyDateKind.Custom10,
				].includes(selectedKeyFactTypeOption.dataContext) ? (
					<div>
						<label className={css(bs.textLabel, bs.block, bs.textSm, bs.mb1)}>Date</label>
						<SplitDatePicker
							day={day}
							month={month}
							year={year}
							onChangeDay={setDay}
							onChangeMonth={setMonth}
							onChangeYear={setYear}
							yearPlaceholder='Optional'
						/>
					</div>
				) : null}
				{selectedKeyFactTypeOption.dataContext === 'Household' ? (
					<>
						<div>
							<label className={css(bs.textLabel, bs.block, bs.textSm, bs.mb1)}>Spouse</label>
							<SimpleAutoCompleteSearchField
								onClear={() => setSpouseContactId(null)}
								onItemSelected={ev => {
									const user = ev.selection as Api.IUser;
									if (ev.target) {
										ev.target.setSearchQuery(Api.VmUtils.getDisplayName(user));
									}
									setSpouseContactId(user.id!);
								}}
								onKeyDown={ev => {
									if (ev.sourceEvent && ev.sourceEvent.keyCode !== 13) {
										setSpouseContactId(null);
									}
								}}
								placeholder='Search contact'
								style={[bs.textSm]}
								type={Api.ResourceAutoCompleteViewModelType.Contact}
								onCreateAutoCompleteViewModel={(_, vm) => {
									vm.addParam({ includeDeactivated: false });
									return vm;
								}}
							/>
						</div>
					</>
				) : null}
				{selectedKeyFactTypeOption.dataContext === Api.KeyDateKind.Renewal ? (
					<div>
						<div className={css(bs.mb4)}>
							<label className={css(bs.textLabel, bs.block, bs.textSm, bs.mb1)}>Date</label>
							<SplitDatePicker
								day={day}
								month={month}
								year={year}
								onChangeDay={setDay}
								onChangeMonth={setMonth}
								onChangeYear={setYear}
								yearPlaceholder='Optional'
							/>
						</div>
						<div>
							<label className={css(bs.textLabel, bs.block, bs.textSm, bs.mb1)}>Policy type</label>
							<ClassifiedPoliciesSelector
								selectedPolicies={selectedPolicies}
								onSelectedPoliciesChanged={setSelectedPolicies}
							/>
						</div>
					</div>
				) : null}
			</div>
			<div className={css(bs.flex, bs.gap2)}>
				<button
					className={css(bs.ctaButton)}
					onClick={handleSave}
					disabled={contactKeyDateCreateMutation.isLoading || householdCreateMutation.isLoading}
				>
					Save
				</button>
				<button
					className={css(bs.outlineButtonContainer, bs.px4, bs.textBrandPrimary)}
					onClick={onClose}
					disabled={contactKeyDateCreateMutation.isLoading || householdCreateMutation.isLoading}
				>
					Cancel
				</button>
			</div>
		</div>
	);
}

const DefaultFilterRequest: Api.IContactsFilterRequest = {
	criteria: [],
};
function ClassifiedPoliciesSelector({
	onSelectedPoliciesChanged,
	selectedPolicies,
}: {
	onSelectedPoliciesChanged: (values: string[]) => void;
	selectedPolicies: string[];
}) {
	const { data: classifiedPolicies, isLoading } = useAllClassifiedContactPolicies({
		contactsRequest: DefaultFilterRequest,
		enabled: true,
		refetchOnWindowFocus: false,
	});

	return (
		<PolicySelector
			classifiedPolicies={classifiedPolicies}
			isLoadingClassifiedPolicies={isLoading}
			onSelectedPoliciesChanged={onSelectedPoliciesChanged}
			selectedPolicies={selectedPolicies}
			singleSelectionOnly={true}
			styles={[styleSheet.policySelector]}
		/>
	);
}

const monthOptions = Array.from({ length: 12 }, (_, i) => ({
	text: [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	][i],
	dataContext: i + 1,
	id: `keyfact-month-${i + 1}`,
}));

function SplitDatePicker({
	day,
	month,
	year,
	onChangeDay,
	onChangeMonth,
	onChangeYear,
	yearPlaceholder,
}: {
	day: number | null;
	month: number | null;
	year: number | null;
	onChangeDay: (day: number) => void;
	onChangeMonth: (month: number) => void;
	onChangeYear: (year: number) => void;
	yearPlaceholder?: string;
}) {
	return (
		<div className={css(bs.flex, bs.flex, bs.gap2)}>
			<div className={css(bs.flex, bs.flexCol, bs.flex1, bs.gap2)}>
				<label>Month</label>
				<Select
					options={monthOptions}
					selectedOption={month ? monthOptions[month - 1] : undefined}
					onOptionClick={option => onChangeMonth(option.dataContext)}
				/>
			</div>
			<div className={css(bs.flex, bs.flexCol, bs.flex1, bs.gap2)}>
				<label htmlFor='smartkeyfact-day-input'>Day</label>
				<TextInput
					value={day ?? ''}
					onChange={ev => onChangeDay(ev.target.value ? parseInt(ev.target.value, 10) : null)}
					inputClassName={css(bs.wFull)}
					type='number'
					min={1}
					max={31}
					inputId='smartkeyfact-day-input'
				/>
			</div>
			<div className={css(bs.flex, bs.flexCol, bs.flex1, bs.gap2)}>
				<label htmlFor='smartkeyfact-year-input'>Year</label>
				<TextInput
					value={year ?? ''}
					onChange={ev => onChangeYear(ev.target.value ? parseInt(ev.target.value, 10) : null)}
					type='number'
					inputClassName={css(bs.wFull)}
					placeholder={yearPlaceholder}
					inputId='smartkeyfact-year-input'
				/>
			</div>
		</div>
	);
}
