import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useEventLogging } from '../../../../models/Logging';
import { useErrorMessages, useToaster } from '../../../../models/hooks/appStateHooks';
import { invalidateGiftingBalance, useGifting, useGiftingAddBalanceMutation } from '../../../../queries';
import { useCurrentUserGiftingBalance } from '../../../containers/PostcardComposer/hooks/useCurrentUserGiftingBalance';
import { baseStyleSheet } from '../../../styles/styles';
import { Checkbox } from '../../Checkbox';
import { LoadingSpinner } from '../../LoadingSpinner';
import { Modal } from '../../Modal';
import { TextInput } from '../../TextInput';
import { styleSheet } from './styles';

const currencyRegex = /^\d+(\.\d{1,2})?$/;

export const PurchaseCreditsModal = ({ isOpen, onRequestClose }: { isOpen: boolean; onRequestClose: () => void }) => {
	const [amount, setAmount] = React.useState<number>();
	const [confirmation, setConfirmation] = React.useState(false);
	const errorMessages = useErrorMessages();
	const { logApiError } = useEventLogging('HandwrittenCardAccountSettings');
	const toaster = useToaster();
	const currentBalanceQuery = useGifting();
	const { currentBalanceFormatted, cardsAvailable } = useCurrentUserGiftingBalance();
	const addBalanceMutation = useGiftingAddBalanceMutation({
		onError: (error: Api.IOperationResultNoValue) => {
			// @ts-ignore
			errorMessages.pushApiError(error);
			logApiError('MutateHandwrittenCardAccountSettings-Error', error);
		},
		onSuccess: () => {
			invalidateGiftingBalance();
			toaster?.push({
				message: 'Balance added successfully!',
				type: 'successMessage',
			});
			onRequestClose();
		},
	});

	return (
		<Modal useDefaultHeader={true} shouldCloseOnOverlayClick={false} isOpen={isOpen} onRequestClose={onRequestClose}>
			<h1 className={css(styleSheet.modalHeaderTitle)}>Add Credits</h1>
			<div>
				<label className={css(styleSheet.label)} htmlFor='address-modal-city-input'>
					Amount
				</label>
				<TextInput
					inputId='purchase-credit-amount'
					onChange={ev => {
						if (confirmation) {
							setConfirmation(false);
						}
						const value = ev.target.value;
						if (value && !currencyRegex.test(value)) {
							return;
						}
						setAmount(parseFloat(value));
					}}
					type='number'
					placeholder='$'
					value={amount}
				/>
				<span className={css(styleSheet.currentBalanceCopy)}>
					{currentBalanceQuery.isLoading ? (
						'Loading current balance...'
					) : (
						<>
							Current balance: <strong>{currentBalanceFormatted}</strong> / Cards Available:{' '}
							<strong>{cardsAvailable}</strong>
						</>
					)}
				</span>
			</div>
			<Checkbox
				checked={confirmation}
				className={css(styleSheet.checkboxContainer)}
				disabled={!amount}
				id='purchase-confirmation-checkbox'
				onChange={() => setConfirmation(!confirmation)}
				type='large'
			>
				<div className={css(styleSheet.checkboxTextOnly)}>
					<div className={css(styleSheet.checkboxText)}>I agree to process this purchase with the card on file</div>
				</div>
			</Checkbox>
			<footer className={css(styleSheet.modalFooter)}>
				<button
					className={css(baseStyleSheet.ctaButtonSmall)}
					disabled={!amount || !confirmation || addBalanceMutation.isLoading}
					// @ts-ignore
					onClick={() => addBalanceMutation.mutate({ amount })}
				>
					{addBalanceMutation.isLoading ? <LoadingSpinner type='tiny' /> : null} Confirm
				</button>
				<button
					className={css(baseStyleSheet.ctaButtonReverseSmall)}
					disabled={addBalanceMutation.isLoading}
					onClick={onRequestClose}
				>
					Cancel
				</button>
			</footer>
		</Modal>
	);
};
