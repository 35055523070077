import { ToolbarDefault, convertHtmlStringValueToPlainText, nodeListToArray } from '@AppModels/UiUtils';
import { DefaultRawRichTextContentBlockStyleAttribute, IDictionary, UserSessionContext, VmUtils } from '@ViewModels';
import { Editor } from '@tinymce/tinymce-react';
import { inject } from 'mobx-react';
import outy, { IOutyHandle } from 'outy';
import * as React from 'react';
import * as tinymce from 'tinymce';
import {
	IImpersonationContextComponentProps,
	IRichContentDocumentEditorImageOptions,
	IRichContentDocumentEditorPlaceholder,
	ImpersonationContextKey,
} from '../../../../models';
import { AppState, ErrorMessagesViewModelKey } from '../../../../models/AppState';
import { IEmailComposerContextChildProps, withEmailComposerContext } from '../../../../models/Email';
import { Token, TokenAttributes } from '../../../../models/Token';
import AppBuildInfo from '../../../assets/build-info.json';
import { navigation, titles } from '../../../styles/colors';
import ElementDtd from './elements.dtd.json';
import {
	AlignmentMenuToolbarPlugin,
	FileAttachmentToolbarPlugin,
	GiphyToolbarPlugin,
	InsertImageToolbarPlugin,
	InsertMenuSignatureToolbarPlugin,
	InsertMenuToolbarPlugin,
	ListMenuToolbarPlugin,
	MergeFieldMenuToolbarPlugin,
	PlaceholdersPlugin,
	SurveyLinksPlugin,
	parsePastedContent,
} from './plugins';

interface IHtmlEditorDtd {
	attrMap: IDictionary<string[]>;
	commonAttrs: string[];
	customElements: string[];
	elements: string[];
}

const PlaceholderDataId = 'htmlEditorPlaceholderText';
const Dtd: IHtmlEditorDtd = ElementDtd as any;
const ImgTagFilterRegExp = /<img[^>]*src="(?!(https|data):\/\/)[^"]*"[^>]*\/?>/gim;

interface IProps extends IImpersonationContextComponentProps, IEmailComposerContextChildProps {
	autoFocus?: boolean;
	contentPlaceholderText?: string;
	disablePasteRichText?: boolean;
	imageOptions?: IRichContentDocumentEditorImageOptions;
	init?: tinymce.RawEditorSettings;
	onBlur?(
		e: tinymce.EditorEvent<{
			focusedEditor: tinymce.Editor;
		}>
	): void;
	onEditorChanged?(contentHtmlStringValue: string, editorRef: tinymce.Editor): void;
	onExecuteCommand?(e: tinymce.Events.ExecCommandEvent): void;
	onFocus?(
		e: tinymce.EditorEvent<{
			blurredEditor: tinymce.Editor;
		}>
	): void;
	onLoad?(editorRef: tinymce.Editor): void;
	readOnly?: boolean;
	userSession: UserSessionContext;
	value?: string;
}

interface IState {
	showingPlaceholderText?: boolean;
	value?: string;
}

const DefaultTinyMceInit: tinymce.RawEditorSettings = {
	branding: false,
	browser_spellcheck: true,
	content_style: `
		body {
			background: transparent;
			color: ${titles};
			margin: 0;
			padding: 10px;
		}
		h1, h2, h3, h4, h5, h6, p {
			margin: 0px;
			padding: 0px;
		}
	`,
	convert_urls: false,
	fontsize_formats: '10px 11px 12px 14px 16px 18px 24px 36px 48px',
	forced_root_block_attrs: {
		style: DefaultRawRichTextContentBlockStyleAttribute,
	},
	height: '100%',
	link_assume_external_targets: 'https',
	link_context_toolbar: true,
	menubar: false,
	paste_data_images: false,
	paste_retain_style_properties:
		'color font-size font-weight font-style font-family border padding margin width height text-align',
	plugins: 'lists paste link placeholders noneditable table',
	quickbars_insert_toolbar: false,
	quickbars_selection_toolbar: false,
	resize: false,
	statusbar: false,
	toolbar: ToolbarDefault,
};

export const ValidElementsStringValue = Dtd.elements.reduce((result, element) => {
	const attrsStringValue = [...Dtd.commonAttrs, ...(Dtd.attrMap[element] || [])].filter(x => !!x).join('|');
	const nextResult = `${result}${result ? ',' : ''}${element}${attrsStringValue ? `[${attrsStringValue}]` : ''}`;
	return nextResult;
}, '');

class HtmlEditorBase extends React.Component<IProps, IState> {
	public static defaultProps: Partial<IProps> = {
		disablePasteRichText: false,
	};
	// @ts-ignore
	private outsideClickHandle: IOutyHandle;
	// @ts-ignore
	private mEditorRef: tinymce.Editor;
	// @ts-ignore
	private mMounted: boolean;
	private mSettings: tinymce.RawEditorSettings;
	public readonly state: IState = {};
	private mPluginDisposers: (() => void)[];

	public static getDerivedStateFromProps(props: IProps, state: IState) {
		const nextState: IState = {};

		if (state.value !== props.value) {
			nextState.value = props.value || '';
		}

		return Object.keys(nextState).length > 0 ? nextState : null;
	}

	constructor(props: IProps) {
		super(props);

		this.mPluginDisposers = [];
		const { init, value, imageOptions } = this.props;

		const initSettings = { ...init, ...{ paste_data_images: imageOptions?.allowPasteImages } };

		const settings: tinymce.RawEditorSettings = {
			...DefaultTinyMceInit,
			...initSettings,
			// set this to the output path for the tinymce themes, plugins, etc.
			// need to cast to override readonly
			base_url: `./static/tinymce/${encodeURIComponent(AppBuildInfo.tinyMceVersion)}`,
			contextmenu: false,
			default_link_target: '_blank',
			setup: this.onSetup,
			valid_elements: ValidElementsStringValue,
		};
		this.mSettings = settings;
		this.state = {
			value,
		};
	}

	public componentWillUnmount() {
		this.mMounted = false;
		if (this.mEditorRef) {
			this.mEditorRef.off('blur', this.onBlur);
			this.mEditorRef.off('ExecCommand', this.onExecuteCommand);
			this.mEditorRef.off('focus', this.onFocus);
			this.mEditorRef.off('keyup', this.onKeyUp);
			this.mEditorRef.off('init', this.onEditorInit);
			this.mEditorRef.off('PastePreProcess', this.onContentPastePreProcess);
			this.unRegisterClickOutside();
		}
		(this.mPluginDisposers || []).forEach(x => x());
		// @ts-ignore
		this.mPluginDisposers = null;
	}

	public render() {
		const { value } = this.state;
		return <Editor init={this.mSettings} onEditorChange={this.onEditorChanged} value={value || undefined} />;
	}

	private onEditorChanged = (contentHtmlStringValue: string) => {
		const { onEditorChanged } = this.props;
		const { showingPlaceholderText } = this.state;
		if (contentHtmlStringValue !== this.state.value && !showingPlaceholderText) {
			if (onEditorChanged) {
				onEditorChanged(contentHtmlStringValue, this.mEditorRef);
			} else {
				this.setState({
					value: contentHtmlStringValue,
				});
			}
		}
	};

	private onSetup = (editor: tinymce.Editor) => {
		const { init, userSession, impersonationContext, imageOptions, emailComposerContext } = this.props;
		this.mEditorRef = editor;

		// add on event handlers
		this.mEditorRef.on('blur', this.onBlur);
		this.mEditorRef.on('ExecCommand', this.onExecuteCommand);
		this.mEditorRef.on('focus', this.onFocus);
		this.mEditorRef.on('init', this.onEditorInit);
		this.mEditorRef.on('keyup', this.onKeyUp);
		this.mEditorRef.on('PastePreProcess', this.onContentPastePreProcess);
		this.mEditorRef.addCommand('levError', VmUtils.Noop);
		[
			// @ts-ignore
			// @ts-ignore
			FileAttachmentToolbarPlugin.onInit(userSession, this.mEditorRef, impersonationContext) || null,
			InsertImageToolbarPlugin.onInit(userSession, this.mEditorRef, impersonationContext, imageOptions) || null,
			// @ts-ignore
			// @ts-ignore
			PlaceholdersPlugin.onInit(userSession, this.mEditorRef, impersonationContext) || null,
			// @ts-ignore
			// @ts-ignore
			SurveyLinksPlugin.onInit(userSession, this.mEditorRef, impersonationContext) || null,
			// @ts-ignore
			// @ts-ignore
			GiphyToolbarPlugin.onInit(userSession, this.mEditorRef, impersonationContext) || null,
			InsertMenuToolbarPlugin.onInit(userSession, this.mEditorRef, impersonationContext, imageOptions) || null,
			InsertMenuSignatureToolbarPlugin.onInit(userSession, this.mEditorRef, impersonationContext, imageOptions) || null,
			// @ts-ignore
			// @ts-ignore
			ListMenuToolbarPlugin.onInit(userSession, this.mEditorRef, impersonationContext) || null,
			// @ts-ignore
			// @ts-ignore
			AlignmentMenuToolbarPlugin.onInit(userSession, this.mEditorRef, impersonationContext) || null,
			// @ts-ignore
			MergeFieldMenuToolbarPlugin.onInitWithRenewalFlowContext(
				userSession,
				this.mEditorRef,
				emailComposerContext,
				impersonationContext
			) || null,
		].forEach(x => {
			if (x) {
				this.mPluginDisposers.push(x);
			}
		});

		if (!!init && init.setup) {
			init.setup(editor);
		}
	};

	private onExecuteCommand = (e: tinymce.Events.ExecCommandEvent) => {
		const { onExecuteCommand } = this.props;
		if (onExecuteCommand) {
			onExecuteCommand(e);
		}
	};

	private onEditorInit = () => {
		const { autoFocus, onLoad } = this.props;
		this.mMounted = true;
		this.registerClickOutside();

		if (autoFocus) {
			setTimeout(() => {
				if (!!this.mMounted && !!this.mEditorRef) {
					this.mEditorRef.focus();
					this.togglePlaceholderText(false);
				}
			});
		} else {
			this.togglePlaceholderText(true);
		}

		if (onLoad) {
			onLoad(this.mEditorRef);
		}
	};

	private onFocus = (
		e: tinymce.EditorEvent<{
			blurredEditor: tinymce.Editor;
		}>
	) => {
		const { onFocus } = this.props;
		this.togglePlaceholderText(false);
		if (onFocus) {
			onFocus(e);
		}
	};

	private onKeyUp = (e: tinymce.EditorEvent<KeyboardEvent>) => {
		// @ts-ignore
		const { placeholders } = this.props.init;
		if (placeholders?.length > 0) {
			(placeholders as IRichContentDocumentEditorPlaceholder[]).forEach(placeholder => {
				const symbols = [placeholder.symbol, ...(placeholder.additionalSymbols || [])].filter(x => x.endsWith(e.key));
				// Also fire if it is ] since tinymce has some race condition of releasing the shift key
				if (!!this.mEditorRef && (symbols.length || ']' === e.key)) {
					const content = this.mEditorRef.getContent().toString();
					if (!symbols.some(x => !!content.match(new RegExp(x, 'igm')))) {
						return;
					}
					try {
						const [newContent, addedTokens] = Token.replaceContent(content, placeholder);
						if (newContent) {
							this.mEditorRef.setContent(newContent);
							// set the cursor after the token
							const elements = nodeListToArray(this.mEditorRef.dom.doc.querySelectorAll(`span[data-placeholder=true]`));
							const element =
								(addedTokens?.length === 1
									? elements?.find(x => {
											const id = x.getAttribute(TokenAttributes.DataId);
											return !!id && addedTokens[0].getAttribute(TokenAttributes.DataId) === id;
										})
									: null) || (elements ? elements[elements.length - 1] : null);
							if (element) {
								// @ts-ignore
								this.mEditorRef.selection.select(element);
								// @ts-ignore
								this.mEditorRef.selection.collapse(false);
							}
						}
					} catch (err) {
						// @ts-ignore
						AppState[ErrorMessagesViewModelKey].push({
							// @ts-ignore
							messages: [err.message],
						});
					}
				}
			});
		}
	};

	private onBlur = (
		e: tinymce.EditorEvent<{
			focusedEditor: tinymce.Editor;
		}>
	) => {
		const { onBlur } = this.props;
		this.togglePlaceholderText(true);
		this.dismissToolbarOverflowMenu();
		if (onBlur) {
			onBlur(e);
		}
	};

	private registerClickOutside = () => {
		this.unRegisterClickOutside();
		if (!!this.mEditorRef && !!this.mEditorRef.editorContainer) {
			this.outsideClickHandle = outy(
				[this.mEditorRef.editorContainer],
				['click', 'touchstart'],
				this.dismissToolbarOverflowMenu
			);
		}
	};

	private unRegisterClickOutside = () => {
		if (this.outsideClickHandle) {
			this.outsideClickHandle.remove();
			// @ts-ignore
			this.outsideClickHandle = null;
		}
	};

	private dismissToolbarOverflowMenu = () => {
		if (!!this.mEditorRef && !!this.mEditorRef.editorContainer) {
			// find the more button
			const el = this.mEditorRef.editorContainer.querySelector(
				'.tox-toolbar__group button[aria-label="More..."].tox-tbtn'
			) as HTMLElement;
			if (el) {
				const pressed =
					!!el.hasAttribute('aria-expanded') && el.getAttribute('aria-expanded') === 'true' ? true : false;
				if (pressed) {
					// close the menu by clicking
					el.click();
				}
			}
		}
	};

	private onContentPastePreProcess = (
		e: React.ChangeEvent<HTMLElement> & {
			content: string;
			internal: boolean;
			wordContent: boolean;
		}
	) => {
		const { imageOptions } = this.props;

		// If an image tag is the only thing pasted in (in position 0), do not pre-process it
		if (imageOptions?.allowPasteImages && e.content.indexOf('<img') === 0) {
			// If the image is a data:image, it is probably a preview the OS tried to generate, so clear the content
			if (e.content.indexOf('src="data:image') > -1) {
				e.content = '';
			}
			return;
		}
		const { readOnly, disablePasteRichText } = this.props;
		if (!readOnly && !!this.mEditorRef && !!e.content) {
			e.preventDefault();
			if (disablePasteRichText) {
				this.mEditorRef.insertContent(convertHtmlStringValueToPlainText(e.content));
				return;
			}

			let transformed = e.content;
			// @ts-ignore
			if (this.mSettings.plugins?.indexOf('surveylinks') >= 0) {
				transformed = SurveyLinksPlugin.onContentPastePreProcess(transformed);
			}
			// @ts-ignore
			const { placeholders } = this.props.init;
			if (placeholders && placeholders.length > 0) {
				transformed = Token.replaceImpostorPlaceholders(transformed, placeholders);
			}
			this.mEditorRef.insertContent(parsePastedContent(transformed.replace(ImgTagFilterRegExp, '')));
		}
	};

	private togglePlaceholderText = (show = true) => {
		if (this.mMounted) {
			this.setState(
				{
					showingPlaceholderText: show,
				},
				() => {
					if (this.mMounted) {
						const { contentPlaceholderText } = this.props;
						if (!!contentPlaceholderText && !!this.mEditorRef) {
							if (show) {
								const content = this.mEditorRef.getContent();
								if (!content) {
									this.mEditorRef.setContent(
										// @ts-ignore
										`<p data-id="${PlaceholderDataId}" style="color:${navigation};${DefaultTinyMceInit.forced_root_block_attrs.style}">${contentPlaceholderText}</p>`
									);
								}
							} else {
								const placeholder = this.mEditorRef.getDoc().querySelector(`p[data-id="${PlaceholderDataId}"]`);
								if (placeholder) {
									// @ts-ignore
									placeholder.parentNode.removeChild(placeholder);
									// add an empty <p>
									// @ts-ignore
									this.mEditorRef.setContent(`<p style="${DefaultTinyMceInit.forced_root_block_attrs.style}"></p>`);
								}
							}
						}
					}
				}
			);
		}
	};
}

const HtmlEditorWithEmailComposerContext = withEmailComposerContext(HtmlEditorBase);
const HtmlEditor = inject(ImpersonationContextKey)(HtmlEditorWithEmailComposerContext);
// eslint-disable-next-line import/no-default-export
export default HtmlEditor;
