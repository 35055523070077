import { darkGrayFontColor } from '../../styles/colors';
import { CssSelectors } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

const pillHeight = 32;
const pillHorizontalPadding = 16;
const pillVerticalPadding = 8;

export const styleSheet = StyleSheet.create({
	container: {},
	icon: {
		display: 'flex',
		flexShrink: 0,
	},
	menu: {
		boxSizing: 'border-box',
		color: darkGrayFontColor,
		fontSize: 12,
		marginTop: 0,
		width: '100%',
	},
	option: {
		alignItems: 'center',
		display: 'flex',
	},
	optionTitle: {
		marginLeft: 10,
		width: 'calc(100% - 9px - 10px)',
	},
	pill: {
		alignItems: 'center',
		background: '#F5FAFD',
		border: '1px solid #DDEAF1',
		borderRadius: pillHeight / 2.0,
		boxSizing: 'border-box',
		color: darkGrayFontColor,
		display: 'flex',
		fontSize: 12,
		height: pillHeight,
		letterSpacing: '0px',
		minWidth: 200,
		padding: `${pillVerticalPadding}px ${pillHorizontalPadding}px`,
	},
	selectBox: {
		...CssSelectors.allDescendants(
			{
				marginLeft: pillHorizontalPadding,
			},
			'.select-box-menu'
		),
	},
	visibility: {
		alignItems: 'center',
		display: 'flex',
	},
	visibilityTitle: {
		color: darkGrayFontColor,
		fontSize: 12,
		letterSpacing: '0px',
		marginLeft: 10,
	},
});
