import { EnvironmentKey, IEnvironmentComponentProps } from '../../../../models/AppState';
import { baseStyleSheet } from '../../../styles/styles';
import ActionItemPopoverHeaderCloseIconUrl from './actionItemPopoverHeaderCloseIcon.svg';
import ActionItemPopoverHeaderIconUrl from './actionItemPopoverHeaderIcon.svg';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import { inject } from 'mobx-react';
import * as React from 'react';

interface IProps extends IEnvironmentComponentProps {
	className?: string;
	onClose?(): void;
	title?: string;
}

const actionItemPopoverHeader: React.FC<IProps> = props => {
	return (
		<div
			// @ts-ignore
			className={`${css(styleSheet.header, props.environment.appType === 'plugin' && styleSheet.pluginHeader)} ${
				props.className || ''
			}`}
		>
			<div className={css(styleSheet.leftAccessory)}>
				<img src={ActionItemPopoverHeaderIconUrl} />
				<span className={css(styleSheet.headerTitle, baseStyleSheet.truncateText)}>{props.title}</span>
			</div>
			<button className={css(styleSheet.closeButton)} onClick={props.onClose}>
				<img src={ActionItemPopoverHeaderCloseIconUrl} />
			</button>
		</div>
	);
};

export const ActionItemPopoverHeader = inject(EnvironmentKey)(actionItemPopoverHeader);
