import { StyleSheet } from 'aphrodite';
import { black } from '../../styles/colors';

const fadeInKeyframes = {
	'0%': {
		opacity: 0,
	},
	'100%': {
		opacity: 1,
	},
};

export const styleSheet = StyleSheet.create({
	dayPickerDateWithCampaignIndicator: {
		animationDuration: '150ms',
		animationIterationCount: 1,
		animationName: [fadeInKeyframes],
		animationTimingFunction: 'ease-in',
		background: black,
		borderRadius: '50%',
		display: 'inline-block',
		height: 6,
		overflow: 'visible',
		position: 'absolute',
		right: 0,
		top: 0,
		width: 6,
	},
});
