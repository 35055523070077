import { Observer } from 'mobx-react';
import * as React from 'react';
import { Draggable, DraggableProps, Droppable, DroppableProps } from 'react-beautiful-dnd';

export const DraggableObserver: React.FunctionComponent<DraggableProps> = props => {
	const { children, ...restProps } = props;
	return (
		<Draggable {...restProps}>
			{(draggableProvided, snapshot) => {
				return <Observer>{() => children?.(draggableProvided, snapshot) || null}</Observer>;
			}}
		</Draggable>
	);
};

export const DroppableObserver: React.FunctionComponent<DroppableProps> = props => {
	const { children, ...restProps } = props;
	return (
		<Droppable {...restProps}>
			{(droppableProvided, snapshot) => {
				return <Observer>{() => children?.(droppableProvided, snapshot) || null}</Observer>;
			}}
		</Droppable>
	);
};
