import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const OpenLetter: React.FC<IProps> = props => {
	const { className } = props;
	return (
		<SvgIcon className={`open-letter-icon ${className || ''}`} height={71} width={147}>
			<circle cx='8' cy='56' r='2' fill='#E8F3F9' stroke='#00AAE8' />
			<circle cx='138.5' cy='17.5' r='1.5' fill='#E8F3F9' stroke='#00AAE8' />
			<circle cx='123' cy='14' r='1' fill='#E8F3F9' stroke='#00AAE8' />
			<circle cx='113' cy='5' r='1' fill='#00AAE8' />
			<circle cx='16.5' cy='64.5' r='1.5' fill='#00AAE8' />
			<circle cx='14' cy='44' r='1' fill='#00AAE8' />
			<rect x='131' y='6' width='3' height='1' rx='.5' fill='#00AAE8' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M132.5 5a.5.5 0 01.5.5v2a.5.5 0 01-1 0v-2a.5.5 0 01.5-.5z'
				fill='#00AAE8'
			/>
			<rect x='137' y='33' width='3' height='1' rx='.5' fill='#00AAE8' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M138.5 32a.5.5 0 01.5.5v2a.5.5 0 01-1 0v-2a.5.5 0 01.5-.5z'
				fill='#00AAE8'
			/>
			<mask id='a' maskUnits='userSpaceOnUse' x='0' y='0' width='147' height='71'>
				<path fill='#fff' d='M0 0h147v71H0z' />
			</mask>
			<g mask='url(#a)'>
				<path
					d='M33.667 39.19a3 3 0 011.076-2.301L74.298 3.822a3 3 0 013.848 0l39.556 33.066a3 3 0 011.076 2.302v46.925a3 3 0 01-3 3H36.667a3 3 0 01-3-3V39.19z'
					fill='#CAE0EC'
					stroke='#00AAE8'
					strokeWidth='2'
				/>
				<path fill='#fff' d='M42.648 10.923h65.333V70.09H42.648z' />
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M33.574 40.962v44.243a4 4 0 004 4h59.519L33.574 40.962z'
					fill='#E8F3F9'
					stroke='#00AAE8'
					strokeWidth='2'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M118.88 40.962v44.243a4 4 0 01-4 4H55.361l63.519-48.243z'
					fill='#E8F3F9'
					stroke='#00AAE8'
					strokeWidth='2'
				/>
				<rect
					x='61.252'
					y='34.135'
					width='29.034'
					height='.91'
					rx='.455'
					fill='#D8D8D8'
					stroke='#C75DA6'
					strokeWidth='.91'
				/>
				<rect
					x='61.252'
					y='41.417'
					width='29.034'
					height='.91'
					rx='.455'
					fill='#D8D8D8'
					stroke='#C75DA6'
					strokeWidth='.91'
				/>
				<rect
					x='61.252'
					y='48.699'
					width='22.682'
					height='.91'
					rx='.455'
					fill='#D8D8D8'
					stroke='#C75DA6'
					strokeWidth='.91'
				/>
				<rect
					x='61.252'
					y='25.032'
					width='9.979'
					height='.91'
					rx='.455'
					fill='#D8D8D8'
					stroke='#C75DA6'
					strokeWidth='.91'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M60.796 62.808l-20.87 25.487 27.222-20.936-6.352-4.551zm30.852 0l20.871 25.487-27.223-20.936 6.352-4.551z'
					fill='#CAE0EC'
				/>
				<path
					d='M74.232 58.626a3 3 0 013.98 0l34.393 30.49H39.839l34.393-30.49z'
					fill='#fff'
					stroke='#00AAE8'
					strokeWidth='2'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M73.843 64.546a4 4 0 014.758 0l32.103 23.749H41.741l32.102-23.75z'
					fill='#E8F3F9'
				/>
			</g>
			<rect x='15' y='30' width='3' height='1' rx='.5' fill='#00AAE8' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M16.5 29a.5.5 0 01.5.5v2a.5.5 0 01-1 0v-2a.5.5 0 01.5-.5z'
				fill='#00AAE8'
			/>
		</SvgIcon>
	);
};
