import { navigation } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const NotesIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;
	return (
		<SvgIcon className={`notes-icon ${className || ''}`} height={20} width={18}>
			<g
				fill='none'
				fillRule='evenodd'
				stroke={fillColor || navigation}
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='2'
				transform='translate(1 1)'
			>
				<polygon points='9.533 0 13 3.733 3.467 14 0 14 0 10.267' />
				<path d='M0,18 L16,18' />
			</g>
		</SvgIcon>
	);
};
