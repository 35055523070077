import { StyleSheet } from 'aphrodite';
import { brandPrimaryHover, navigation } from '../../../styles/colors';
import { CssSelectors, truncateTextStyle } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	attachmentSize: {
		color: navigation,
		flexShrink: 0,
	},
	clickable: {
		cursor: 'pointer',
	},
	container: {
		alignItems: 'center',
		background: '#EDEDED',
		borderRadius: 4,
		color: brandPrimaryHover,
		display: 'flex',
		fontSize: 12,
		lineHeight: '22px',
		overflow: 'hidden',
		padding: 8,
		...CssSelectors.allChildren(
			{
				marginLeft: 5,
			},
			':not(:first-child)'
		),
	},
	error: {
		background: 'rgba(208,2,27,0.10)',
	},
	fileName: {
		maxWidth: 200,
		...truncateTextStyle,
	},
});
