import * as React from 'react';
import { SvgIcon } from '../../icons/SvgIcon';

type Props = React.SVGProps<SVGSVGElement>;

export const EmailGraphic = ({ width = 97, height = 97, className, ...rest }: Props) => {
	return (
		<SvgIcon className={`email-graphic ${className || ''}`} viewBox='0 0 97 97' height={height} width={width} {...rest}>
			<g width='97' height='97' fill='white'>
				<path
					d='M1.43926 37.8006L45.4393 1.13396C46.9226 -0.10219 49.0774 -0.10219 50.5607 1.13396L94.5607 37.8006C95.4727 38.5606 96 39.6864 96 40.8735V93C96 95.2092 94.2091 97 92 97H4C1.79086 97 0 95.2092 0 93L0 40.8735C0 39.6864 0.527292 38.5606 1.43926 37.8006Z'
					fill='#CAE0EC'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M2.07945 38.5689C1.39547 39.1388 1 39.9832 1 40.8735V93C1 94.6569 2.34315 96 4 96H92C93.6569 96 95 94.6569 95 93V40.8735C95 39.9832 94.6045 39.1388 93.9206 38.5689L49.9206 1.90218C48.808 0.975069 47.192 0.975069 46.0794 1.90218L2.07945 38.5689Z'
					stroke='#00AAE8'
					strokeWidth='2'
				/>
				<path d='M83 10H11V75H83V10Z' fill='white' />
				<path d='M82 11H12V74H82V11Z' stroke='#00AAE8' strokeWidth='2' />
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M1 43V92C1 94.2092 2.79086 96 5 96H71L1 43Z'
					fill='#E8F3F9'
					stroke='#00AAE8'
					strokeWidth='2'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M95.0104 43V92C95.0104 94.2092 93.2196 96 91.0104 96H25.0104L95.0104 43Z'
					fill='#E8F3F9'
					stroke='#00AAE8'
					strokeWidth='2'
				/>
				<path
					d='M63 35H32C31.4477 35 31 35.4477 31 36C31 36.5523 31.4477 37 32 37H63C63.5523 37 64 36.5523 64 36C64 35.4477 63.5523 35 63 35Z'
					fill='#D8D8D8'
				/>
				<path
					d='M63 35.5H32C31.7239 35.5 31.5 35.7239 31.5 36C31.5 36.2762 31.7239 36.5 32 36.5H63C63.2761 36.5 63.5 36.2762 63.5 36C63.5 35.7239 63.2761 35.5 63 35.5Z'
					stroke='#00AAE8'
				/>
				<path
					d='M63 43H32C31.4477 43 31 43.4477 31 44C31 44.5523 31.4477 45 32 45H63C63.5523 45 64 44.5523 64 44C64 43.4477 63.5523 43 63 43Z'
					fill='#D8D8D8'
				/>
				<path
					d='M63 43.5H32C31.7239 43.5 31.5 43.7239 31.5 44C31.5 44.2762 31.7239 44.5 32 44.5H63C63.2761 44.5 63.5 44.2762 63.5 44C63.5 43.7239 63.2761 43.5 63 43.5Z'
					stroke='#00AAE8'
				/>
				<path
					d='M56 51H32C31.4477 51 31 51.4477 31 52C31 52.5523 31.4477 53 32 53H56C56.5523 53 57 52.5523 57 52C57 51.4477 56.5523 51 56 51Z'
					fill='#D8D8D8'
				/>
				<path
					d='M56 51.5H32C31.7239 51.5 31.5 51.7239 31.5 52C31.5 52.2762 31.7239 52.5 32 52.5H56C56.2761 52.5 56.5 52.2762 56.5 52C56.5 51.7239 56.2761 51.5 56 51.5Z'
					stroke='#00AAE8'
				/>
				<path
					d='M42 25H32C31.4477 25 31 25.4477 31 26C31 26.5523 31.4477 27 32 27H42C42.5523 27 43 26.5523 43 26C43 25.4477 42.5523 25 42 25Z'
					fill='#D8D8D8'
				/>
				<path
					d='M42 25.5H32C31.7239 25.5 31.5 25.7239 31.5 26C31.5 26.2762 31.7239 26.5 32 26.5H42C42.2761 26.5 42.5 26.2762 42.5 26C42.5 25.7239 42.2761 25.5 42 25.5Z'
					stroke='#00AAE8'
				/>
				<path fillRule='evenodd' clipRule='evenodd' d='M31 67L8 95L38 72L31 67Z' fill='#CAE0EC' />
				<path fillRule='evenodd' clipRule='evenodd' d='M65 67L88 95L58 72L65 67Z' fill='#CAE0EC' />
				<path
					d='M50.6488 61.3408L91 97H5L45.3512 61.3408C46.864 60.0039 49.136 60.0039 50.6488 61.3408Z'
					fill='white'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M7.6417 96H88.3583L49.9866 62.0901C48.852 61.0875 47.148 61.0875 46.0134 62.0901L7.6417 96Z'
					stroke='#00AAE8'
					strokeWidth='2'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M50.3741 68.7268L86 95H10L45.6259 68.7268C47.0375 67.6858 48.9625 67.6858 50.3741 68.7268Z'
					fill='#E8F3F9'
				/>
			</g>
			<defs>
				<clipPath id='clip0'>
					<rect width='97' height='97' fill='white' />
				</clipPath>
			</defs>
		</SvgIcon>
	);
};
