import { css } from 'aphrodite';
import { useContactSuggestedTagsQuery } from '../../../../../queries';
import { IAccountTag } from '../../../../../viewmodels/AppViewModels';
import { green4, surveysTint } from '../../../../styles/colors';
import { baseStyleSheet } from '../../../../styles/styles';
import { LoadingSpinner } from '../../../LoadingSpinner';
import { TinyPopover } from '../../../TinyPopover';
import { HistoryIcon } from '../../../svgs/icons/HistoryIcon';
import { StarIcon } from '../../../svgs/icons/StarIcon';
import { Tag } from '../../../tags/Tag';
import { useTagsStorage } from './hooks';
import { styleSheet } from './styles';
import './styles.less';

export const AddTagInput = ({
	isAutocompleteOpen,
	highlightedAutoCompleteSuggestionIndex,
	pendingTag,
	input,
	isBusy,
	suggestions,
	searchResults,
	recentSearches,
	addTag,
}: {
	input: JSX.Element;
	pendingTag: string | undefined;
	highlightedAutoCompleteSuggestionIndex?: number;
	isAutocompleteOpen: boolean | undefined;
	isBusy: boolean;
	suggestions: (string | IAccountTag)[];
	searchResults: (string | IAccountTag)[];
	recentSearches: (string | IAccountTag)[];
	addTag: (pendingTag: IAccountTag) => void;
}) => {
	const isOpen = (isBusy || suggestions.length > 0 || searchResults?.length === 0) && isAutocompleteOpen;

	return (
		<TinyPopover
			anchor={input}
			contentStyles={[styleSheet.autoCompleteMenu, styleSheet.autoCompleteMenuInnerStyles]}
			isOpen={isOpen}
		>
			<div>
				{isBusy ? (
					<div className={css(styleSheet.autoCompleteMenuItem, styleSheet.autoCompleteMenuCenterAlign)}>
						<LoadingSpinner type='small' />
					</div>
				) : (
					<TagsList
						title={!pendingTag?.length ? 'Favorites' : 'Top Results'}
						icon={
							!pendingTag?.length ? (
								<StarIcon fillColor={surveysTint} className={css(styleSheet.sectionIcon)} filled={true} />
							) : (
								<></>
							)
						}
						altKey='searches-suggestions'
						list={suggestions}
						highlightedAutoCompleteSuggestionIndex={highlightedAutoCompleteSuggestionIndex}
						addTag={addTag}
					/>
				)}
				{!pendingTag?.length && recentSearches?.length > 0 ? (
					<>
						<TagsList
							title='Recent Searches'
							icon={<HistoryIcon fillColor={green4} className={css(styleSheet.sectionIcon)} />}
							altKey='searches'
							list={recentSearches.slice(0, 3)}
							newIndexStart={suggestions.length}
							highlightedAutoCompleteSuggestionIndex={highlightedAutoCompleteSuggestionIndex}
							addTag={addTag}
						/>
					</>
				) : null}
				{suggestions?.length === 0 && pendingTag ? (
					<button
						onMouseDown={() => addTag({ tag: pendingTag })}
						className={css(styleSheet.autoCompleteMenuItem, styleSheet.addTagButton)}
						key={`${pendingTag}-pending`}
						title={pendingTag}
					>
						<span className={css(styleSheet.sectionTitle)}>{`Create new tag "${pendingTag}"`}</span>
					</button>
				) : null}
			</div>
		</TinyPopover>
	);
};

export const FeaturedAndRecentTags = ({
	onSelected,
	showNoResults,
}: {
	onSelected: (tag: IAccountTag) => void;
	showNoResults: boolean;
}) => {
	const tagsQuery = useContactSuggestedTagsQuery({
		addKeepInTouchTag: false,
		pageSize: 25,
	});
	const { recentSearches } = useTagsStorage();
	const suggestions = (tagsQuery.data as (string | IAccountTag)[]) || [];

	return (
		<>
			{!showNoResults ? (
				<>
					{suggestions?.length > 0 ? (
						<TagsList
							title='Favorites'
							icon={<StarIcon fillColor={surveysTint} className={css(styleSheet.sectionIcon)} filled={true} />}
							altKey='searches-suggestions'
							list={suggestions.slice(0, 3)}
							addTag={onSelected}
						/>
					) : null}
					{recentSearches?.length > 0 ? (
						<TagsList
							title='Recent Searches'
							icon={<HistoryIcon fillColor={green4} className={css(styleSheet.sectionIcon)} />}
							altKey='searches'
							list={recentSearches.slice(0, 3)}
							addTag={onSelected}
						/>
					) : null}
				</>
			) : null}
			{showNoResults ? (
				<p className={css(baseStyleSheet.m0)}>
					<span className={css(styleSheet.noResult)}>No Results</span>
				</p>
			) : null}
		</>
	);
};

export const TagsList = ({
	title,
	icon,
	list,
	highlightedAutoCompleteSuggestionIndex,
	addTag,
	altKey,
	newIndexStart = 0,
}: {
	title: string;
	icon: JSX.Element;
	list: (string | IAccountTag)[];
	highlightedAutoCompleteSuggestionIndex?: number;
	addTag: (pendingTag: IAccountTag) => void;
	altKey?: string;
	newIndexStart?: number;
}) => {
	return (
		<>
			{!icon ? (
				<div className={css(styleSheet.sectionWarp)}>
					<p className={css(styleSheet.sectionTitle)}>{title}</p>
				</div>
			) : (
				<figure className={css(styleSheet.sectionWarp)}>
					{icon} <figcaption className={css(styleSheet.sectionTitle)}>{title}</figcaption>
				</figure>
			)}
			{list.map((x, i) => {
				const index = i + newIndexStart;
				const className = `${css(
					styleSheet.autoCompleteMenuItem,
					highlightedAutoCompleteSuggestionIndex === index ? styleSheet.autoCompleteMenuItemHighlighted : null
				)} truncate-text`;
				const stringValue = typeof x === 'string' ? x : x.tag;

				return (
					<button
						onMouseDown={() => addTag({ tag: stringValue })}
						className={className}
						key={`${stringValue}-${i} ${altKey ?? ''}`}
						title={stringValue}
					>
						<Tag tagValue={{ tag: stringValue }} />
					</button>
				);
			})}
		</>
	);
};
