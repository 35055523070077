import { ResourceAutoCompleteViewModel, UserSessionContext } from '../../../../viewmodels/AppViewModels';
import { brandPrimary, grayIconFill } from '../../../styles/colors';
import { Popover, PopoverType } from '../../Popover';
import { ContactSearchListItem } from '../../contacts/ContactSearchListItem';
import { AssigneeIcon } from '../../svgs/icons/AssigneeIcon';
import { ActionItemPopoverHeader } from '../ActionItemPopoverHeader';
import ActionItemUserSearchIconUrl from './actionItemUserSearchIcon.svg';
import { styleSheet } from './styles';
import { IUser, ResourceAutoCompleteViewModelType } from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';

interface IProps {
	assignee?: IUser;
	className?: string;
	disabled?: boolean;
	onAssigneeSelected?(assignee: IUser): void;
	onToggleAssigneePopover?(showing: boolean): void;
	popoverClassName?: string;
	readOnly?: boolean;
	userSession?: UserSessionContext;
}

interface IState {
	assignee?: IUser;
	assigneeSearchQuery?: string;
	disabled?: boolean;
	highlightedUserIndex?: number;
	readOnly?: boolean;
	showingAssigneePicker?: boolean;
}

class _AssigneeButton extends React.Component<IProps, IState> {
	public state: IState = {
		highlightedUserIndex: -1,
	};
	// @ts-ignore
	private searchViewModel: ResourceAutoCompleteViewModel<IUser>;

	public UNSAFE_componentWillMount() {
		// @ts-ignore
		this.searchViewModel = new ResourceAutoCompleteViewModel<IUser>(this.props.userSession, {
			type: ResourceAutoCompleteViewModelType.User,
		});
		this.setState({
			assignee: this.props.assignee,
			readOnly: this.props.readOnly,
		});
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		const nextState: IState = {};
		if (this.state.readOnly !== nextProps.readOnly) {
			nextState.readOnly = nextProps.readOnly;
		}

		if (this.state.assignee !== nextProps.assignee) {
			nextState.assignee = nextProps.assignee;
		}

		if (this.state.disabled !== nextProps.disabled) {
			nextState.disabled = nextProps.disabled;
		}

		if (Object.keys(nextState).length > 0) {
			this.setState(nextState);
		}
	}

	public render() {
		const { assignee, disabled, readOnly, showingAssigneePicker } = this.state;
		const { className, children, popoverClassName } = this.props;
		const hasAssignee = !!assignee;
		const button = (
			<button
				className={`action-item-assignee-popover-button ${className || ''}`}
				disabled={disabled}
				onClick={!readOnly ? this.onToggleEditingAssignedUser : undefined}
			>
				<AssigneeIcon stroke={hasAssignee ? brandPrimary : grayIconFill} fill='transparent' />
				{children}
			</button>
		);
		return (
			<Popover
				anchor={button}
				className={`action-item-assignee-popover ${popoverClassName || ''}`}
				dismissOnClickOutside={true}
				isOpen={showingAssigneePicker}
				type={PopoverType.white}
				onRequestClose={this.onToggleEditingAssignedUser}
			>
				{this.getUserPicker()}
			</Popover>
		);
	}

	private onToggleEditingAssignedUser = () => {
		const showingAssigneePicker = !this.state.showingAssigneePicker;
		const nextState: IState = {
			showingAssigneePicker,
		};
		if (!showingAssigneePicker) {
			this.searchViewModel.reset();
			nextState.assigneeSearchQuery = '';
		}

		this.setState(nextState);

		if (this.props.onToggleAssigneePopover) {
			this.props.onToggleAssigneePopover(showingAssigneePicker);
		}
	};

	private getUserPicker() {
		return (
			<div className='action-item-assignee-picker'>
				<ActionItemPopoverHeader onClose={this.onToggleEditingAssignedUser} title='Please select a person to assign' />
				<div className='action-item-assignee-picker-search'>
					<div className={`${css(styleSheet.field)} action-item-assignee-picker-search-field`}>
						<img src={ActionItemUserSearchIconUrl} />
						<input
							autoFocus={true}
							className={css(styleSheet.fieldInput)}
							id='action-item-assignee-picker-search-field-input'
							onChange={this.onUserSearchInputTextChanged}
							onKeyDown={this.onUserSearchKeyDown}
							placeholder='Search employees'
							type='text'
							value={this.state.assigneeSearchQuery || ''}
						/>
					</div>
					<div className={`${css(styleSheet.list)} action-item-assignee-picker-search-results-list`}>
						{!!this.searchViewModel.searchResults &&
							this.searchViewModel.searchResults.map((user, i) => {
								const highLighted = this.state.highlightedUserIndex === i;
								const className = `${css(
									styleSheet.item,
									highLighted && styleSheet.highlighted
								)} action-item-assignee-picker-search-results-list-item`;
								return (
									<ContactSearchListItem
										className={className}
										key={user.id || i}
										onClick={this.onAssignUser(user)}
										onKeyDown={this.onUserSearchKeyDown}
										tabIndex={0}
										user={user}
									/>
								);
							})}
					</div>
				</div>
			</div>
		);
	}

	private onUserSearchKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
		if (this.searchViewModel.searchResults) {
			switch (e.keyCode) {
				case 40: {
					// down
					// @ts-ignore
					const index = this.state.highlightedUserIndex + 1;
					if (index < this.searchViewModel.searchResults.length) {
						this.setState({
							highlightedUserIndex: index,
						});
					}
					break;
				}
				case 38: {
					// up
					// @ts-ignore
					const index = this.state.highlightedUserIndex - 1;
					if (index >= 0) {
						this.setState({
							highlightedUserIndex: index,
						});
					}
					break;
				}
				case 13: {
					// enter
					if (
						// @ts-ignore
						this.state.highlightedUserIndex >= 0 &&
						// @ts-ignore
						this.state.highlightedUserIndex < this.searchViewModel.searchResults.length
					) {
						// @ts-ignore
						this.onAssignUser(this.searchViewModel.searchResults[this.state.highlightedUserIndex])();
					}
					break;
				}
				default: {
					break;
				}
			}
		}
	};

	private onAssignUser = (assignee: IUser) => () => {
		this.setState({
			// @ts-ignore
			assigneeSearchQuery: null,
			showingAssigneePicker: false,
		});

		if (this.props.onAssigneeSelected) {
			this.props.onAssigneeSelected(assignee);
		} else {
			this.setState({
				assignee,
			});
		}

		this.searchViewModel.reset();
	};

	private onUserSearchInputTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		const assigneeSearchQuery = e.target.value;
		this.setState({ assigneeSearchQuery });

		if (!!assigneeSearchQuery && assigneeSearchQuery.length > 1) {
			this.searchViewModel.setSearchQuery(assigneeSearchQuery);
		}
	};
}

export const AssigneeButton = observer(_AssigneeButton);
