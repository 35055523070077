import * as Api from '@ViewModels';
import { useLocalStorage } from '../../hooks/useLocalStorage';

export interface IRecentSearch {
	entity?: Api.IEntity;
	tag?: Api.IAccountTag;
	type: 'contact' | 'tag' | 'company';
}

export const useRecentSearches = (storageKey: string) => {
	const [recentSearches, setRecentSearches] = useLocalStorage<IRecentSearch[]>({
		key: storageKey,
		initialValue: [],
	});
	const addToRecent = (search: IRecentSearch) => {
		if (search.type === 'company' && !search.entity) {
			return;
		}
		// remove the element if it is already in the list leaving it at the first position only
		const filtered = recentSearches.filter(x => {
			return x.type === 'contact' || x.type === 'company'
				? x.entity?.id !== search.entity?.id
				: x.tag?.id !== search.tag?.id;
		});
		const length = filtered.unshift(search);
		if (length > 8) {
			filtered.pop();
		}
		setRecentSearches(filtered);
	};

	return { addToRecent, recentSearches };
};
