import { styleSheet } from './styles';
import { StyleDeclaration, css } from 'aphrodite';
import * as React from 'react';

export function HandwrittenFoldedCardThumbnail(
	props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
		styleDeclaration?: StyleDeclaration;
	}
) {
	const { styleDeclaration, ...rest } = props;
	return (
		<figure className={css(styleSheet.root, styleDeclaration)}>
			<img {...rest} />
		</figure>
	);
}
