import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	emojiPopoverPortalRoot: {
		display: 'inline',
		left: -5000,
		position: 'absolute',
		top: -5000,
		visibility: 'hidden',
		zIndex: -100,
	},
});
