import { brandPrimary } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const ActionItemCheckboxIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;
	return (
		<SvgIcon className={className} height={18} width={18}>
			<path
				className='action-item-checkbox-unchecked-icon'
				d='M2,3 L2,15 C2,15.5522847 2.4477153,16 3,16 L15,16 C15.5522847,16 16,15.5522847 16,15 L16,3 C16,2.4477153 15.5522847,2 15,2 L3,2 C2.4477153,2 2,2.4477153 2,3 Z M2,0 L16,0 C17.1045695,0 18,0.8954305 18,2 L18,16 C18,17.1045695 17.1045695,18 16,18 L2,18 C0.8954305,18 0,17.1045695 0,16 L0,2 C0,0.8954305 0.8954305,0 2,0 Z'
				fill={fillColor || brandPrimary}
			/>
			<path
				className='action-item-checkbox-checked-icon'
				d='M2,0 L16,0 C17.1045695,-2.02906125e-16 18,0.8954305 18,2 L18,16 C18,17.1045695 17.1045695,18 16,18 L2,18 C0.8954305,18 1.3527075e-16,17.1045695 0,16 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 Z M5.48393532,14.2447959 C5.57339515,14.3364285 5.67144904,14.419257 5.77674646,14.4921407 C6.68497344,15.1207881 7.93085627,14.8941441 8.55950366,13.9859171 L14.7896959,4.98495921 C15.1744612,4.42907648 15.0616336,3.66997122 14.5318284,3.25002319 C14.5151426,3.23679728 14.4980883,3.22404299 14.4806858,3.21177544 C13.967792,2.85022096 13.2589115,2.97290577 12.897357,3.48579964 L6.88327785,12.0172506 L5.02537459,10.2263921 C5.01575953,10.2171241 5.00598819,10.2080195 4.99606477,10.1990824 C4.51069905,9.76195754 3.7628727,9.8010645 3.32574787,10.2864302 C2.86529693,10.7976963 2.88101572,11.5786596 3.36166636,12.0709838 L5.48393532,14.2447959 Z'
				fill={fillColor || brandPrimary}
				fillRule='evenodd'
			/>
		</SvgIcon>
	);
};
