import { StyleSheet } from 'aphrodite';
import {
	borderColor,
	brandPrimary,
	brandPrimaryText,
	brandSecondary,
	campaignTintColor,
	darkGrayFontColor,
	destructive,
	error,
	header,
	inputBorderColor,
	mention,
	mentionDark,
	nameCircles,
	navigation,
	titles,
} from '../../styles/colors';
import { CssSelectors, Layouts, baseStyleSheet } from '../../styles/styles';

export const styleSheet = StyleSheet.create({
	addAddressButton: {
		backgroundColor: '#E8F3F9',
		border: '1px dashed rgb(0,170,232)',
		borderRadius: 3,
		boxShadow: '1px solid rgb(202, 224, 236)',
		color: brandPrimaryText,
		padding: '0.5rem',
		textAlign: 'center',
		width: '100%',
	},
	addressMissingPill: {
		alignItems: 'center',
		background: '#E8D9F4',
		borderRadius: '4px',
		boxSizing: 'border-box',
		color: '#6E27A3',
		display: 'flex',
		fontSize: '11px',
		padding: '0.125rem 0.25rem',
		...CssSelectors.allChildren(
			{
				marginLeft: '0.25rem',
			},
			':not(:first-child)'
		),
	},
	addressMissingPillPlacement: {
		bottom: '0.5rem',
		position: 'absolute',
		right: '0.5rem',
	},
	addressMissingPillPostageWrapper: {
		alignItems: 'center',
		background: '#6E27A3',
		borderRadius: '50%',
		boxSizing: 'border-box',
		display: 'flex',
		height: '1rem',
		padding: '0.25rem',
		width: '1rem',
	},
	autocompleteDropdownContent: {
		width: '100%',
	},
	balanceContainer: {
		color: header,
		margin: '0 0 0.5rem',
		padding: 0,
	},
	balanceText: {
		color: titles,
		fontSize: '1rem',
	},
	cardPreviewText: {
		color: navigation,
		margin: 0,
		padding: 0,
		textTransform: 'uppercase',
	},
	configDrawerAddressHeader: {
		alignItems: 'center',
		display: 'flex',
		marginBottom: '0.5rem',
		...CssSelectors.allChildren(
			{
				marginLeft: '0.5rem',
			},
			':not(:first-child)'
		),
	},
	configDrawerAddressHeaderTitle: {
		color: brandSecondary,
		flex: 1,
	},
	configDrawerAddressInput: {
		minHeight: 120,
	},
	configDrawerCharCount: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	configDrawerCharCountOver: {
		color: 'red',
	},
	configDrawerHeader: {
		color: brandPrimaryText,
		fontSize: 28,
		fontWeight: 'normal',
		lineHeight: '38px',
		margin: '0 0 1.5rem 0',
		padding: 0,
	},
	configDrawerMessage: {
		color: brandSecondary,
		fontSize: '1rem',
		margin: 0,
		padding: 0,
	},
	configDrawerSection: {
		marginBottom: '1rem',
		width: '100%',
	},
	configDrawerSelectSig: {
		color: navigation,
		fontSize: '0.75rem',
		margin: '0 0 0.5rem 0',
		padding: 0,
		textTransform: 'uppercase',
	},
	configDrawerSubtitle: {
		color: header,
		display: 'flex',
		fontSize: '0.75rem',
	},
	configDrawerThirdHeader: {
		color: titles,
		display: 'block',
		fontSize: '1rem',
	},
	customCardContactContextTabView: {
		height: '100%',
	},
	customCardHeaderTitle: {
		color: brandPrimaryText,
		fontSize: '24px',
	},
	customCardHeaderUser: {
		alignItems: 'center',
		display: 'flex',
		...CssSelectors.allChildren(
			{
				marginLeft: '0.5rem',
			},
			':not(:first-child)'
		),
	},
	customCardHeaderUserInfo: {
		color: header,
		fontSize: '0.75rem',
	},
	customCardHeaderUserName: {
		color: titles,
		fontSize: '18px',
	},
	customCardUserAvatar: {
		height: 35,
		width: 35,
	},
	customLayoutFooter: {
		boxSizing: 'border-box',
		height: '4.5rem',
		padding: '1rem 0',
	},
	customLayoutOuterWrapper: {
		boxSizing: 'border-box',
		display: 'flex',
		height: 'calc(100% - 4.5rem)',
	},
	customMainNav: {
		border: '1px solid rgb(202, 224, 236)',
		borderRadius: 3,
		boxShadow: 'rgb(232, 243, 249) 0px 0px 12px 0px',
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
	},
	customRoot: {
		boxSizing: 'border-box',
		height: '100%',
		padding: '1rem',
	},
	divider: {
		background: nameCircles,
		flex: '0 0 1px',
	},
	dividerMargin: {
		margin: '1.5rem 0 1.5rem',
	},
	dropdownAnchor: {
		alignItems: 'center',
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'space-between',
	},
	dropdownAnchorText: {
		maxWidth: 600,
	},
	dropdownIcon: {
		height: '1rem',
		marginLeft: '0.75rem',
		transform: 'rotate(90deg)',
		width: '0.5rem',
	},
	dropdownInline: {
		borderColor: inputBorderColor,
		borderRadius: 3,
		borderStyle: 'solid',
		borderWidth: 1,
		boxSizing: 'border-box',
		maxWidth: 600,
		overflowY: 'visible',
		padding: 10,
		width: '100%',
	},
	dropdownItem: {
		':hover': {
			background: mention,
		},
		cursor: 'pointer',
		padding: 10,
		...CssSelectors.allChildren({
			color: navigation,
			fontSize: 12,
		}),
	},
	dropdownMenu: {
		background: '#fff',
		border: '1px solid #E2E2E2',
		boxShadow: '0 0 6px 0 rgba(184,184,184,0.30)',
		marginTop: 21,
		maxHeight: 300,
		overflowY: 'auto',
		width: '100%',
	},
	emailFallbackInfo: {
		alignItems: 'center',
		background: mention,
		borderRadius: 3,
		color: brandPrimaryText,
		display: 'flex',
		fontSize: '12px',
		fontStyle: 'italic',
		justifyContent: 'center',
		padding: '1rem 0.5rem',
		...CssSelectors.allChildren(
			{
				marginLeft: '0.5rem',
			},
			':not(:first-child)'
		),
	},
	errorContactLoad: {
		color: error,
		margin: 0,
		padding: 0,
	},
	groupingLabel: {
		fontSize: '0.75rem',
	},
	hwcActionsToolbar: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'flex-end',
		padding: '0.75rem 1.25rem',
		...CssSelectors.allChildren(
			{
				marginLeft: '0.5rem',
			},
			':not(:first-child)'
		),
	},
	hwcTemplatesDropdown: {
		border: '1px solid #CCC',
		borderRadius: 3,
		boxSizing: 'border-box',
		maxWidth: 600,
		overflowY: 'visible',
		padding: '0.5rem',
		width: '100%',
	},
	hwcTemplatesDropdownAnchor: {
		alignItems: 'center',
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'space-between',
	},
	hwcTemplatesDropdownIcon: {
		height: 16,
		marginLeft: 12,
		transform: 'rotate(90deg)',
		width: 10,
	},
	hwcTemplatesDropdownItem: {
		':hover': {
			background: '#E8F3F9',
		},
		cursor: 'pointer',
		padding: 10,
	},
	hwcTemplatesDropdownMenu: {
		background: '#fff',
		border: '1px solid #E2E2E2',
		boxShadow: '0 0 6px 0 rgba(184,184,184,0.30)',
		marginTop: 21,
		width: '100%',
	},
	hwcTemplatesDropdownPostageIcon: {
		alignItems: 'center',
		background: campaignTintColor,
		borderRadius: '50%',
		display: 'flex',
		flexShrink: 0,
		height: 30,
		justifyContent: 'center',
		width: 30,
	},
	mainNav: {
		border: '1px solid rgb(202, 224, 236)',
		borderRadius: 3,
		boxShadow: 'rgb(232, 243, 249) 0px 0px 12px 0px',
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		height: '100%',
	},
	mainPreview: {
		aspectRatio: '105 / 76',
		background: 'white',
		boxSizing: 'border-box',
		display: 'flex',
		filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
		flexDirection: 'column',
		maxWidth: 525,
		overflow: 'hidden',
		width: 525,
	},
	mainPreviewContainer: {
		alignItems: 'center',
		boxSizing: 'border-box',
		display: 'flex',
		flex: '1',
		flexDirection: 'column',
		justifyContent: 'center',
		overflow: 'auto',
		padding: '1rem 1.5rem',
	},
	mainPreviewImage: {
		height: '100%',
		objectFit: 'cover',
		width: '100%',
	},
	mainPreviewModeImage: {
		alignItems: 'center',
	},
	outlineButton: {
		boxSizing: 'border-box',
		color: brandPrimary,
		height: '2rem',
		width: 80,
	},
	previewWrapper: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		...CssSelectors.allChildren(
			{
				marginTop: '1rem',
			},
			':not(:first-child)'
		),
		width: 532,
	},
	recipientsCountContainer: {
		display: 'flex',
		marginRight: '0.5rem',
	},
	recipientsCountHeader: {
		alignItems: 'center',
		display: 'flex',
		flexWrap: 'wrap',
		fontSize: 14,
		justifyContent: 'flex-start',
	},
	recipientsCountSort: {
		border: 'none',
	},
	recipientsCountSortText: {
		color: titles,
		fontSize: 14,
		marginRight: 8,
	},
	recipientsCountSortTrigger: {
		padding: 0,
	},
	recipientsFooter: {
		padding: '0.5rem 1rem',
	},
	recipientsFooterCta: {
		width: '100%',
	},
	recipientsHeader: {
		...Layouts.verticalStack(20),
		boxSizing: 'border-box',
		padding: '30px 0 10px 0',
	},
	recipientsHeaderTitle: {
		alignItems: 'center',
		color: titles,
		display: 'flex',
		fontSize: 18,
		justifyContent: 'space-between',
	},
	recipientsList: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		overflowX: 'hidden',
		overflowY: 'auto',
	},
	recipientsListItem: {
		alignItems: 'center',
		borderBottom: `1px solid ${borderColor}`,
		cursor: 'pointer',
		display: 'flex',
		padding: '1rem 0',
		position: 'relative',
	},
	recipientsListItemAvatar: {
		flexShrink: 0,
		marginLeft: '0.5rem',
	},
	recipientsListItemAvatarList: {
		height: 60,
		marginLeft: '0.5rem',
		position: 'relative',
		width: 60,
	},
	recipientsListItemAvatarsFirst: {
		height: 30,
		left: 0,
		position: 'absolute',
		top: 0,
		width: 30,
	},
	recipientsListItemAvatarsSecond: {
		bottom: 0,
		height: 30,
		position: 'absolute',
		right: 0,
		width: 30,
	},
	recipientsListItemDetails: {
		flex: 1,
		fontSize: '14px',
		marginLeft: '1rem',
		overflow: 'hidden',
	},
	recipientsListItemDetailsCompanyName: {
		color: header,
		fontSize: '14px',
		marginBottom: '0.5rem',
		marginTop: '0.25rem',
	},
	recipientsListItemDetailsInfo: {
		color: brandPrimaryText,
		letterSpacing: 0,
		marginTop: '0.25rem',
	},
	recipientsListItemDetailsInfoCustomized: {
		color: '#89C947',
	},
	recipientsListItemDetailsName: {
		color: titles,
		fontSize: '18px',
		lineHeight: '22px',
	},
	recipientsListItemRightAccessory: {
		marginRight: '1rem',
	},
	requiredIndicator: {
		color: destructive,
		fontSize: 18,
	},
	requiredLabel: {
		color: destructive,
		display: 'block',
		fontSize: 12,
		marginTop: '0.5rem',
		textAlign: 'right',
	},
	reviewOrderForm: {
		boxSizing: 'border-box',
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		padding: '0.5rem 1.5rem 0',
		width: '100%',
	},
	reviewOrderRecipientsCount: {
		alignItems: 'center',
		color: titles,
		display: 'flex',
		fontSize: '1rem',
		justifyContent: 'space-between',
	},
	root: {
		boxSizing: 'border-box',
		display: 'flex',
		height: '100%',
		padding: '1rem',
	},
	saveAsDropdownCaret: {
		alignItems: 'center',
		borderLeftColor: brandPrimary,
		borderLeftStyle: 'solid',
		borderLeftWidth: 1,
		display: 'flex',
		height: '100%',
		justifyContent: 'center',
		padding: '0 6px',
	},
	sendFromDropdown: {
		marginLeft: '0.5rem',
		maxWidth: 260,
		width: '80%',
	},
	sendFromDropdownContainer: {
		alignItems: 'center',
		color: darkGrayFontColor,
		display: 'flex',
		fontSize: 14,
		width: '100%',
	},
	showingAndDelivery: {
		display: 'flex',
		...CssSelectors.allChildren({
			':not(:first-child)': {
				marginLeft: '0.5rem',
			},
		}),
	},
	showingFilterDropdownMenu: {
		fontSize: 14,
	},
	showingFilterDropdownTrigger: {
		background: '#fff',
		color: '#858585',
		fontSize: 14,
	},
	showingHeaderOptions: {
		color: '#858585',
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		fontSize: 14,
		marginBottom: '0.5rem',
	},
	sideNav: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		maxWidth: 440,
		overflow: 'auto',
		paddingLeft: '1.5rem',
		width: 440,
	},
	suppressionModal: {
		maxWidth: 400,
	},
	tagPointDown: {
		transform: 'rotateZ(90deg)',
	},
	tagPointUp: {
		transform: 'rotateZ(270deg)',
	},
	tagSearches: {
		height: 'auto',
		marginLeft: '-0.5rem',
	},
	tagTokenContainer: {
		maxHeight: 'unset',
		...CssSelectors.allChildren({
			marginLeft: '0.5rem',
		}),
	},
	tagTriggerText: {
		marginRight: '0.5rem',
	},
	templateContainerHeader: {
		borderBottom: `1px solid ${mentionDark}`,
		boxSizing: 'border-box',
		color: brandPrimaryText,
		display: 'flex',
		flexDirection: 'column',
		fontSize: 24,
		padding: '12px 20px',
		width: '100%',
		...CssSelectors.allChildren(
			{
				marginTop: '0.5rem',
			},
			':not(:first-child)'
		),
	},
	templateSaveModal: {
		':nth-child(1n) > .modal-overlay': {
			':nth-child(1n) > .modal-overlay-content': {
				width: 540,
			},
		},
	},
	total: {
		color: titles,
		...baseStyleSheet.fontBold,
		fontSize: '1.125rem',
		marginRight: '0.5rem',
	},
});
