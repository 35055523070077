import { StyleSheet } from 'aphrodite';

const animationKeyframes = {
	'0%': {
		transform: 'translateY(0)',
	},
	'100%': {
		transform: 'translateY(0)',
	},
	'33': {
		transform: 'translateY(4px)',
	},
	'66%': {
		transform: 'translateY(-4px)',
	},
};

export const styleSheet = StyleSheet.create({
	dot0: {},
	dot1: {
		animationDelay: '50ms',
	},
	dot2: {
		animationDelay: '100ms',
	},
	jump: {
		animationDirection: 'alternate',
		animationDuration: '0.5s',
		animationIterationCount: 1,
		animationName: [animationKeyframes],
		animationTimingFunction: 'ease-in-out',
	},
});
