import { StyleSheet } from 'aphrodite';
import { brandPrimary, brandPrimaryText, success } from '../../../styles/colors';
import { CssSelectors, Layouts } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	blueBackground: {
		background: '#E8F3F9',
		borderRadius: '3px 3px 0px 0px',
		paddingBottom: 40,
	},
	container: {
		height: 578,
		textAlign: 'center',
		width: 500,
		overflow: 'hidden',
	},
	copyLinkButton: {
		...Layouts.horizontalStack(10),
		alignItems: 'flex-end',
		display: 'inline-flex',
		marginLeft: 10,
	},
	detailMessage: {
		color: brandPrimaryText,
		fontSize: 16,
		lineHeight: 4,
		margin: '0 auto',
		marginTop: 14,
	},
	earn: {
		color: success,
		fontSize: 14,
	},
	header: {
		background: '#E8F3F9',
		borderRadius: '3px 3px 0px 0px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		paddingBottom: 10,
		width: '100%',
	},
	headerButton: {
		marginRight: 15,
		marginTop: 15,
	},
	icon: {},
	iconContainer: {
		margin: '100px auto 0',
		position: 'relative',
	},
	link: {
		':hover': {
			textDecoration: 'underline',
		},
		textDecoration: 'none',
	},
	money: {
		color: success,
		fontSize: '30px',
	},
	other: {
		color: '#A7ABAD',
		fontSize: 12,
	},
	removePadding: {
		...CssSelectors.allChildren(
			{
				...CssSelectors.allChildren(
					{
						borderRadius: 4,
						boxSizing: 'border-box',
						overflow: 'hidden',
						padding: 0,
					},
					'.modal-overlay-content'
				),
				padding: 0,
			},
			'.modal-overlay'
		),
	},
	titleMessage: {
		background: '#E8F3F9',
		borderRadius: '3px 3px 0px 0px',
		color: brandPrimary,
		fontSize: '30px',
		padding: '0 0 42px',
		margin: 0,
	},
	graphic: {
		width: '100%',
		height: 200,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
});
