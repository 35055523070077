import { StyleSheet } from 'aphrodite';
import { borderColor, brandPrimary } from '../../../styles/colors';

export const styleSheet = StyleSheet.create({
	dropdown: {},
	dropdownAnchor: {
		display: 'flex',
	},
	dropdownContent: {},
	loading: {
		padding: 10,
	},
	loadingWrap: {
		width: 200,
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		padding: '20px 0',
		background: '#fff',
	},
	sectionWrap: {
		borderBox: 'box-sizing',
		alignItems: 'center',
		display: 'flex',
		padding: 10,
		borderTop: `1px solid ${borderColor}`,
		borderBottom: `1px solid ${borderColor}`,
	},
	sectionTitle: {
		minWidth: 200,
		color: brandPrimary,
		fontSize: 14,
		margin: 0,
	},
});
