import { EnvironmentKey, IEnvironmentComponentProps } from '../../../../models/AppState';
import { brandPrimary, grayIconFill } from '../../../styles/colors';
import { DayPicker } from '../../DayPicker';
import { Popover, PopoverType } from '../../Popover';
import { ClockIcon } from '../../svgs/icons/ClockIcon';
import { ActionItemPopoverHeader } from '../ActionItemPopoverHeader';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';

interface IProps extends IEnvironmentComponentProps {
	className?: string;
	disabled?: boolean;
	dueDate?: Date;
	onDaySelected?(date: Date): void;
	onToggleDueDatePopover?(showing: boolean): void;
	popoverClassName?: string;
	readOnly?: boolean;
}

interface IState {
	disabled?: boolean;
	dueDate?: Date;
	readOnly?: boolean;
	showingDayPicker?: boolean;
}

class _AssignDueDateButton extends React.Component<IProps, IState> {
	public state: IState = {};
	// @ts-ignore
	private defaultSelectedDueDate: Date;

	public UNSAFE_componentWillMount() {
		this.defaultSelectedDueDate = moment().add(1, 'day').toDate();
		this.setState({
			dueDate: this.props.dueDate,
			readOnly: this.props.readOnly,
		});
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		const nextState: IState = {};
		if (this.state.readOnly !== nextProps.readOnly) {
			nextState.readOnly = nextProps.readOnly;
		}

		if (this.state.dueDate !== nextProps.dueDate) {
			nextState.dueDate = nextProps.dueDate;
		}

		if (this.state.disabled !== nextProps.disabled) {
			nextState.disabled = nextProps.disabled;
		}

		if (Object.keys(nextState).length > 0) {
			this.setState(nextState);
		}
	}

	public render() {
		const { dueDate, readOnly, showingDayPicker, disabled } = this.state;
		const hasDueDate = !!dueDate;
		const button = (
			<button
				className={`action-item-assign-due-date-popover-button ${this.props.className || ''}`}
				disabled={disabled}
				onClick={!readOnly ? this.onToggleEditingDueDate : undefined}
			>
				<ClockIcon fill={hasDueDate ? brandPrimary : grayIconFill} />
				{this.props.children}
			</button>
		);
		return (
			<Popover
				anchor={button}
				className={`action-item-assign-due-date-popover ${this.props.popoverClassName || ''}`}
				dismissOnClickOutside={true}
				isOpen={showingDayPicker}
				type={PopoverType.white}
				onRequestClose={this.onToggleEditingDueDate}
			>
				{this.getDayPickerPopoverContent()}
			</Popover>
		);
	}

	private onToggleEditingDueDate = () => {
		const showingDayPicker = !this.state.showingDayPicker;
		this.setState({
			showingDayPicker,
		});

		if (this.props.onToggleDueDatePopover) {
			this.props.onToggleDueDatePopover(showingDayPicker);
		}
	};

	private getDayPickerPopoverContent() {
		const { environment } = this.props;
		return (
			<div className='action-item-acttachment-day-picker-popover-content'>
				<ActionItemPopoverHeader
					onClose={this.onToggleEditingDueDate}
					title='Please select a due date for the action item'
				/>
				<DayPicker
					defaultSelectedDay={this.defaultSelectedDueDate}
					environment={environment}
					onDayClick={this.onDaySelected}
					selectedDays={this.state.dueDate}
				/>
			</div>
		);
	}

	private onDaySelected = (day: Date) => {
		const nextState: IState = { showingDayPicker: false };
		if (this.props.onDaySelected) {
			this.props.onDaySelected(day);
		} else {
			nextState.dueDate = day;
		}
		this.setState(nextState);
	};
}

const AssignDueDateButtonAsObserver = observer(_AssignDueDateButton);
export const AssignDueDateButton = inject(EnvironmentKey)(AssignDueDateButtonAsObserver);
