import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const RemoveTagIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;
	return (
		<SvgIcon className={`remove-tag-icon ${className || ''}`} height={15} width={15}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.5 15a7.5 7.5 0 100-15 7.5 7.5 0 000 15z'
				fill={fillColor || '#00AAE8'}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11.314 4.78l-.578-.578L7.758 7.18 4.78 4.202l-.577.578L7.18 7.758l-2.978 2.978.577.577 2.978-2.977 2.978 2.977.578-.577-2.978-2.978 2.978-2.978z'
				fill='#fff'
			/>
		</SvgIcon>
	);
};
