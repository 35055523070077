import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	disabled?: boolean;
	fillColor?: string;
	height?: number;
	width?: number;
}

export const EmailIcon: React.FC<IProps> = ({ className, fillColor = '#fff', disabled, width = 18, height = 16 }) => {
	return (
		<SvgIcon
			className={`email-icon ${className}`}
			fill='none'
			height={height}
			style={{ opacity: disabled ? 0.5 : 1 }}
			viewBox='0 0 18 16'
			width={width}
		>
			<path
				fill={fillColor}
				fillRule='evenodd'
				clipRule='evenodd'
				d='M15.75 3.90857L9 7.82743L2.25 3.90857V2.28571H15.75V3.90857ZM15.75 13.7143H2.25V6.54057L8.442 10.1349C8.7885 10.336 9.21263 10.336 9.558 10.1349L15.75 6.54057V13.7143ZM16.875 0H1.125C0.504 0 0 0.510857 0 1.14286V14.8571C0 15.4891 0.504 16 1.125 16H16.875C17.496 16 18 15.4891 18 14.8571V1.14286C18 0.510857 17.496 0 16.875 0Z'
			/>
		</SvgIcon>
	);
};
