import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import {
	actionItemTintColor,
	brandPrimary,
	brandSecondary,
	campaignTintColor,
	emailActivityTintColor,
} from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';

interface Props {
	className?: string;
	styles?: StyleDeclarationValue[];
	type: Api.AutomationStepModelType;
}

export const AutomationStepIcon = ({ type, styles, className }: Props) => {
	if (type === 'EmailAutomationStep') {
		return (
			<SvgIcon className={`automation-step-icon ${className || ''} ${css(...(styles || []))}`} height={30} width={30}>
				<g fill='none' fillRule='evenodd'>
					<circle cx='15' cy='15' r='15' fill={emailActivityTintColor} />
					<path
						fill='#FFF'
						d='M14,3.42 L8,6.849 L2,3.42 L2,2 L14,2 L14,3.42 Z M14,12 L2,12 L2,5.723 L7.504,8.868 C7.812,9.044 8.189,9.044 8.496,8.868 L14,5.723 L14,12 Z M15,0 L1,0 C0.448,0 0,0.447 0,1 L0,13 C0,13.553 0.448,14 1,14 L15,14 C15.552,14 16,13.553 16,13 L16,1 C16,0.447 15.552,0 15,0 L15,0 Z'
						transform='translate(7 8)'
					/>
				</g>
			</SvgIcon>
		);
	}
	if (type === 'ActionItemAutomationStep') {
		return (
			<SvgIcon className={`automation-step-icon ${className || ''} ${css(...(styles || []))}`} height={30} width={30}>
				<g fill='none' fillRule='evenodd'>
					<circle cx='15' cy='15' r='15' fill={actionItemTintColor} />
					<g fill='#FFF' transform='translate(6 6)'>
						<path d='M6.90330678,12.1383976 L4.38660175,9.81678214 C3.89915918,9.36712506 3.86852798,8.60745568 4.31818506,8.12001311 C4.32912251,8.10815657 4.34029776,8.09652162 4.35170402,8.08511537 L4.35170402,8.08511537 C4.8496831,7.58713629 5.64795226,7.55899201 6.1797654,8.02066409 L8.19270402,9.76811537 L14.6635268,1.50108291 C15.0942743,0.95076603 15.8819126,0.837075495 16.450704,1.24311537 L16.450704,1.24311537 C16.9928913,1.63016351 17.1186564,2.38345765 16.7316083,2.92564492 C16.7233613,2.93719744 16.7149111,2.94860349 16.7062616,2.95985773 L9.84515867,11.8871056 C9.17206129,12.7628988 7.9164374,12.9272162 7.04064422,12.2541189 C6.99315542,12.217621 6.94733008,12.1790083 6.90330678,12.1383976 Z' />
						<path
							fillRule='nonzero'
							d='M16,15 L16,9 C16,8.44771525 16.4477153,8 17,8 L17,8 C17.5522847,8 18,8.44771525 18,9 L18,16 C18,17.1045695 17.1045695,18 16,18 L2,18 C0.8954305,18 1.3527075e-16,17.1045695 0,16 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 L10,0 C10.5522847,-1.01453063e-16 11,0.44771525 11,1 L11,1 C11,1.55228475 10.5522847,2 10,2 L3,2 C2.44771525,2 2,2.44771525 2,3 L2,15 C2,15.5522847 2.44771525,16 3,16 L15,16 C15.5522847,16 16,15.5522847 16,15 Z'
						/>
					</g>
				</g>
			</SvgIcon>
		);
	}
	if (type === 'AddTagAutomationStep') {
		return (
			<SvgIcon className={`automation-step-icon ${className || ''} ${css(...(styles || []))}`} height={30} width={30}>
				<g fill='none' fillRule='evenodd'>
					<circle cx='15' cy='15' r='15' fill={brandPrimary} />
					<g fill='#FFF' transform='translate(7 6)'>
						<path d='M1.5,8.689425 L9,16.189425 L13.1895,11.999925 L5.6895,4.499925 L1.5,4.499925 L1.5,8.689425 Z M9,17.999925 C8.808,17.999925 8.616,17.926425 8.46975,17.780175 L0.21975,9.530175 C0.0795,9.389925 0,9.198675 0,8.999925 L0,3.749925 C0,3.335925 0.336,2.999925 0.75,2.999925 L6,2.999925 C6.19875,2.999925 6.38925,3.079425 6.53025,3.219675 L14.78025,11.469675 C15.0735,11.762925 15.0735,12.236925 14.78025,12.530175 L9.53025,17.780175 C9.384,17.926425 9.192,17.999925 9,17.999925 L9,17.999925 Z' />
						<path d='M5.25 6.75C5.25 7.57725 4.578 8.25 3.75 8.25 2.9205 8.25 2.25 7.57725 2.25 6.75 2.25 5.92125 2.9205 5.25 3.75 5.25 4.578 5.25 5.25 5.92125 5.25 6.75M15.21975 9.53025L7.1895 1.5 2.25 1.5 2.25 1.77635684e-15 7.5 1.77635684e-15C7.69875 1.77635684e-15 7.88925.07875 8.03025.21975L16.28025 8.46975 15.21975 9.53025z' />
					</g>
				</g>
			</SvgIcon>
		);
	}
	if (type === 'TextingAutomationStep') {
		return (
			<SvgIcon className={`automation-step-icon ${className || ''} ${css(...(styles || []))}`} height={30} width={30}>
				<g fill='none' fillRule='evenodd'>
					<circle cx='15' cy='15' r='15' fill={brandSecondary} />
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M14.5 22.227H6.773a.773.773 0 01-.567-1.298l1.782-1.92a6.373 6.373 0 01-1.215-3.736c0-3.835 3.466-6.955 7.727-6.955v1.546c-3.408 0-6.182 2.426-6.182 5.409 0 1.187.44 2.318 1.27 3.27a.773.773 0 01-.017 1.034l-1.026 1.105H14.5c3.409 0 6.182-2.426 6.182-5.41h1.546c0 3.835-3.467 6.955-7.728 6.955zM23 8.318h-2.318V6h-1.545v2.318h-2.319v1.546h2.319v2.318h1.545V9.864H23V8.318z'
						fill='#fff'
					/>
				</g>
			</SvgIcon>
		);
	}
	if (type === 'RemoveTagAutomationStep') {
		return (
			<SvgIcon className={`automation-step-icon ${className || ''} ${css(...(styles || []))}`} height={30} width={30}>
				<g fill='none' fillRule='evenodd'>
					<circle cx='15' cy='15' r='15' fill='#F89143' />
					<path stroke='#FFF' strokeLinecap='square' strokeWidth='2' d='M25,5 L4.5,24.5' />
					<g fill='#FFF' transform='translate(7 6)'>
						<path d='M1.5,8.689425 L9,16.189425 L13.1895,11.999925 L5.6895,4.499925 L1.5,4.499925 L1.5,8.689425 Z M9,17.999925 C8.808,17.999925 8.616,17.926425 8.46975,17.780175 L0.21975,9.530175 C0.0795,9.389925 0,9.198675 0,8.999925 L0,3.749925 C0,3.335925 0.336,2.999925 0.75,2.999925 L6,2.999925 C6.19875,2.999925 6.38925,3.079425 6.53025,3.219675 L14.78025,11.469675 C15.0735,11.762925 15.0735,12.236925 14.78025,12.530175 L9.53025,17.780175 C9.384,17.926425 9.192,17.999925 9,17.999925 L9,17.999925 Z' />
						<path d='M5.25 6.75C5.25 7.57725 4.578 8.25 3.75 8.25 2.9205 8.25 2.25 7.57725 2.25 6.75 2.25 5.92125 2.9205 5.25 3.75 5.25 4.578 5.25 5.25 5.92125 5.25 6.75M15.21975 9.53025L7.1895 1.5 2.25 1.5 2.25 1.77635684e-15 7.5 1.77635684e-15C7.69875 1.77635684e-15 7.88925.07875 8.03025.21975L16.28025 8.46975 15.21975 9.53025z' />
					</g>
				</g>
			</SvgIcon>
		);
	}
	if (type === 'SwitchAutomationStep') {
		return (
			<SvgIcon
				className={`automation-step-icon ${className || ''} ${css(...(styles || []))}`}
				viewBox='0 0 16 16'
				height={30}
				width={30}
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M20.7142 20H17.8582V17.8571H20.7142V20ZM12.1433 20H9.28589V17.8571H12.1433V20ZM13.572 8.57139H16.4295V10.7143H13.572V8.57139ZM21.4285 16.4285H19.9998V15.7143C19.9998 14.5328 19.039 13.5714 17.8582 13.5714H15.7151V12.1428H17.1438C17.5381 12.1428 17.8582 11.8228 17.8582 11.4285V7.85711C17.8582 7.46282 17.5381 7.14282 17.1438 7.14282H12.8577C12.4634 7.14282 12.1433 7.46282 12.1433 7.85711V11.4285C12.1433 11.8228 12.4634 12.1428 12.8577 12.1428H14.2864V13.5714H12.1433C10.9618 13.5714 10.0002 14.5328 10.0002 15.7143V16.4285H8.57153C8.17721 16.4285 7.85718 16.7485 7.85718 17.1428V20.7143C7.85718 21.1085 8.17721 21.4285 8.57153 21.4285H12.8577C13.252 21.4285 13.572 21.1085 13.572 20.7143V17.1428C13.572 16.7485 13.252 16.4285 12.8577 16.4285H11.429V15.7143C11.429 15.3207 11.7497 15 12.1433 15H17.8582C18.2511 15 18.5711 15.3207 18.5711 15.7143V16.4285H17.1438C16.7495 16.4285 16.4295 16.7485 16.4295 17.1428V20.7143C16.4295 21.1085 16.7495 21.4285 17.1438 21.4285H21.4285C21.8229 21.4285 22.1429 21.1085 22.1429 20.7143V17.1428C22.1429 16.7485 21.8229 16.4285 21.4285 16.4285Z'
					fill='white'
				/>
				<defs>
					<linearGradient id='paint0_linear' x1='0' y1='0' x2='0' y2='30' gradientUnits='userSpaceOnUse'>
						<stop stopColor='#59DEFA' />
						<stop offset='1' stopColor='#BC4DF9' />
					</linearGradient>
				</defs>
			</SvgIcon>
		);
	}
	if (type === 'HandwrittenCardAutomationStep') {
		return (
			<SvgIcon
				className={`automation-step-icon ${className || ''} ${css(...(styles || []))}`}
				height={30}
				width={30}
				viewBox='0 0 30 30'
				fill='none'
			>
				<circle cx='15' cy='15' r='15' fill={campaignTintColor} />
				<path
					fill='#fff'
					fillRule='evenodd'
					d='M13 14.2a1.2 1.2 0 1 0 0-2.4 1.2 1.2 0 0 0 0 2.4M19 18.18l-2.98-4.78-2.071 3.324-1-1.604-1.907 3.06H19Z'
					clipRule='evenodd'
				/>
				<path
					fill='#fff'
					fillRule='evenodd'
					d='M19.8 19.8h-9.6v-9.6h9.6v9.6ZM23 9.4v-.8A1.6 1.6 0 0 0 21.4 7h-.8v1.6H19V7h-1.6v1.6h-1.6V7h-1.6v1.6h-1.6V7H11v1.6H9.4V7h-.8A1.6 1.6 0 0 0 7 8.6v.8h1.6V11H7v1.6h1.6v1.6H7v1.6h1.6v1.6H7V19h1.6v1.6H7v.8A1.6 1.6 0 0 0 8.6 23h.8v-1.6H11V23h1.6v-1.6h1.6V23h1.6v-1.6h1.6V23H19v-1.6h1.6V23h.8a1.6 1.6 0 0 0 1.6-1.6v-.8h-1.6V19H23v-1.6h-1.6v-1.6H23v-1.6h-1.6v-1.6H23V11h-1.6V9.4H23Z'
					clipRule='evenodd'
				/>
			</SvgIcon>
		);
	}
	if (type === 'NoActionAutomationStep') {
		return (
			<SvgIcon className={`automation-step-icon ${className || ''} ${css(...(styles || []))}`} height={30} width={30}>
				<g fill='none' fillRule='evenodd'>
					<circle cx='15' cy='15' r='15' fill='#BEBEBE' />
				</g>
			</SvgIcon>
		);
	}
	return null;
};
