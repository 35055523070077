import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const XMarkIcon: React.FC<IProps> = props => {
	const { className } = props;
	return (
		<SvgIcon className={className} width={16} height={16}>
			<g fill='none' fillRule='evenodd' transform='translate(2 2)'>
				<circle cx='6' cy='6' r='6' fill='#F89143' stroke='#F89143' strokeOpacity='.202' strokeWidth='4' />
				<g stroke='#FFF' strokeWidth='1.5' transform='translate(3 3)'>
					<path d='M0,0 L6,6' />
					<path d='M0,0 L6,6' transform='matrix(-1 0 0 1 6 0)' />
				</g>
			</g>
		</SvgIcon>
	);
};
