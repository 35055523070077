import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { TinyPopover } from '../TinyPopover';
import { SearchIcon } from '../svgs/icons/SearchIcon';
import { styleSheet } from './styles';

interface IProps {
	anchorStyles?: StyleDeclarationValue[];
	className?: string;
	forceClose?: boolean;
	id?: string;
	inputStyles?: StyleDeclarationValue[];
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
	onRequestClose?: () => void;
	placeholder?: string;
	renderResults?: () => JSX.Element;
	resultsStyles?: StyleDeclarationValue[];
	value: string;
}

export const SearchBase: React.FC<IProps> = ({
	anchorStyles = [],
	className,
	forceClose,
	id = '',
	inputStyles = [],
	onChange,
	onKeyDown,
	onRequestClose,
	placeholder = 'Search',
	renderResults,
	resultsStyles = [],
	value,
}) => {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);

	useEffect(() => {
		if (forceClose) {
			setIsPopoverOpen(false);
			onRequestResultsClose();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [forceClose]);

	const onInputChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setIsPopoverOpen(!!e.target.value);
			onChange(e);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isPopoverOpen, onChange]
	);

	const onRequestResultsClose = useCallback(() => {
		setIsPopoverOpen(false);
		onRequestClose?.();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPopoverOpen, onRequestClose]);

	return (
		<div className={`${css(styleSheet.container)} ${className}`}>
			<TinyPopover
				anchor={
					<div className={css(styleSheet.inputContainer, ...inputStyles)}>
						<SearchIcon />
						<input
							autoComplete='off'
							id={id}
							onChange={onInputChange}
							value={value}
							onKeyDown={onKeyDown}
							placeholder={placeholder}
						/>
					</div>
				}
				anchorStyles={anchorStyles}
				dismissOnOutsideAction={true}
				isOpen={isPopoverOpen}
				onRequestClose={onRequestResultsClose}
			>
				{!!renderResults && <div className={css(styleSheet.resultsContainer, ...resultsStyles)}>{renderResults()}</div>}
			</TinyPopover>
		</div>
	);
};
