import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	stroke?: string;
}

export const SupportIcon: React.FC<IProps> = ({ className, stroke = '#fff' }) => {
	const height = 20;
	const width = 16;
	return (
		<SvgIcon className={className} height={height} viewBox={`0 0 ${width} ${height}`} width={width}>
			<g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
				<g transform='translate(-60.000000, -464.000000)' stroke={stroke} strokeWidth='2'>
					<g transform='translate(61.000000, 465.000000)'>
						<path d='M0,16.25 C0,15.0073593 0.97937711,14 2.1875,14 L14,14' />
						<path d='M2.1875,0 L14,0 L14,18 L2.1875,18 C0.97937711,18 0,16.9926407 0,15.75 L0,2.25 C0,1.00735931 0.97937711,3.99680289e-16 2.1875,0 Z' />
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
