import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fill?: string;
}

export const TagsAndReportingIcon: React.FC<IProps> = ({ className, fill = '#fff' }) => {
	const height = 20;
	const width = 20;
	return (
		<SvgIcon
			className={`tags-and-reporting-icon ${className}`}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			width={width}
		>
			<g fill={fill} fillRule='evenodd'>
				<polygon points='6 7.414 6 13 8 13 8 7.414 9.293 8.707 10.707 7.293 7 3.586 3.293 7.293 4.707 8.707' />
				<polygon points='10.707 11.293 9.293 12.707 13 16.414 16.707 12.707 15.293 11.293 14 12.586 14 7 12 7 12 12.586' />
				<path d='M17.9971,18 L2.0001,18 L2.0001,2 L18.0001,2 L17.9971,18 Z M18.0001,0 L2.0001,0 C0.8971,0 0.0001, 0.897 0.0001,2 L0.0001,18 C0.0001,19.103 0.8971,20 2.0001,20 L18.0001,20 C19.1031,20 20.0001,19.103 20.0001,18 L20.0001,2 C20.0001,0.897 19.1031,0 18.0001,0 L18.0001,0 Z' />
			</g>
		</SvgIcon>
	);
};
