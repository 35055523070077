import { BreakPoints, CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	chip: {
		cursor: 'pointer',
		maxWidth: '400px',
		[BreakPoints.forMinWidth(768)]: {
			maxWidth: 200,
		},
		[BreakPoints.forMaxWidth(1024)]: {
			maxWidth: 300,
		},
	},
	chipAvatar: {
		fontSize: '12px',
		fontWeight: 500,
		height: '100%',
		width: '100%',
		...CssSelectors.allDescendants(
			{
				height: 'auto',
				width: '100%',
			},
			'.avatar-image'
		),
	},
	compactChip: {
		maxWidth: '240px',
	},
});
