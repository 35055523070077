import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const MoreIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;
	return (
		<SvgIcon className={`more-icon ${className || ''}`} height={4} width={18}>
			<path
				fill={fillColor || '#858585'}
				fillRule='evenodd'
				d='M4 2C4 3.106 3.104 4 2 4 .895 4 0 3.106 0 2 0 .895.895 0 2 0 3.104 0 4 .895 4 2M11 2C11 3.106 10.104 4 9 4 7.895 4 7 3.106 7 2 7 .895 7.895 0 9 0 10.104 0 11 .895 11 2M18 2C18 3.106 17.104 4 16 4 14.895 4 14 3.106 14 2 14 .895 14.895 0 16 0 17.104 0 18 .895 18 2'
			/>
		</SvgIcon>
	);
};
