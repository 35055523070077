import * as React from 'react';
import { alternateTitleColor, background } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';

interface Props extends React.SVGProps<SVGSVGElement> {
	strokeColor?: string;
	bgCircleFill?: string;
}

export const BusinessPlanPlaceholderIcon = ({
	width = 140,
	height = 140,
	className,
	strokeColor = alternateTitleColor,
	bgCircleFill = background,
}: Props) => {
	return (
		<SvgIcon className={className} viewBox='0 0 140 140' height={height} width={width}>
			<g fill='none' fillRule='evenodd'>
				<circle cx='70' cy='70' r='70' fill={bgCircleFill} />
				<g transform='translate(20.222 19.444)'>
					<polygon fill='#E8F3F9' points='17.889 17.111 79.333 17.111 79.333 87.889 17.889 87.889' />
					<rect
						width='70.333'
						height='2.667'
						x='13.444'
						y='11.889'
						fill='#CAE0EC'
						stroke={strokeColor}
						strokeWidth='2'
					/>
					<path d='M86.7222222,61.0555556 L86.7222222,75.0555556' />
					<rect width='1' height='74.444' x='15.278' y='16.056' fill='#D8D8D8' stroke={strokeColor} />
					<rect width='1' height='74.444' x='81.389' y='16.056' fill='#D8D8D8' stroke={strokeColor} />
					<g stroke={strokeColor} transform='translate(21 62.222)'>
						<rect width='8.889' height='20.556' x='4.111' y='1' fill='#FFF' strokeWidth='2' rx='2' />
						<circle cx='6.222' cy='11.667' r='1' />
						<rect width='12' height='2.667' x='2.556' y='22' strokeWidth='2' />
						<rect width='15.111' height='2.667' x='1' y='25.111' strokeWidth='2' />
					</g>
					<rect width='21.556' height='1' x='4.389' y='89.944' stroke={strokeColor} />
					<circle cx='95.667' cy='66.889' r='8.556' fill='#E8F3F9' stroke={strokeColor} strokeWidth='2' />
					<rect width='1' height='18.222' x='95.889' y='70.222' stroke={strokeColor} strokeWidth='2' />
					<rect width='41' height='1' x='54.944' y='89.944' stroke={strokeColor} />
					<g stroke={strokeColor} transform='translate(23.333 21.778)'>
						<rect width='10.444' height='12' x='1' y='1' fill='#FFF' strokeWidth='2' rx='2' />
						<rect width='1' height='10.667' x='5.944' y='2.056' />
						<rect width='11.444' height='1' x='.5' y='6.722' />
						<g transform='translate(0 20.222)'>
							<rect width='10.444' height='12' x='1' y='1' fill='#FFF' strokeWidth='2' rx='2' />
							<rect width='1' height='10.667' x='5.944' y='2.056' />
							<path d='M0.5,6.72222222 L0.5,7.27777778 L11.9444444,7.27777778 L11.9444444,6.72222222 L0.5,6.72222222 Z' />
						</g>
						<g transform='translate(19.444)'>
							<rect width='10.444' height='12' x='1' y='1' fill='#FFF' strokeWidth='2' rx='2' />
							<path d='M5.94444444,2.05555556 L5.94444444,12.7222222 L6.5,12.7222222 L6.5,2.05555556 L5.94444444,2.05555556 Z' />
							<rect width='11.444' height='1' x='.5' y='6.722' />
						</g>
						<g transform='translate(19.444 20.222)'>
							<rect width='10.444' height='12' x='1' y='1' fill='#FFF' strokeWidth='2' rx='2' />
							<path d='M5.94444444,2.05555556 L5.94444444,12.7222222 L6.5,12.7222222 L6.5,2.05555556 L5.94444444,2.05555556 Z' />
							<path d='M0.5,6.72222222 L0.5,7.27777778 L11.9444444,7.27777778 L11.9444444,6.72222222 L0.5,6.72222222 Z' />
						</g>
						<g transform='translate(19.444 40.444)'>
							<rect width='10.444' height='12' x='1' y='1' fill='#FFF' strokeWidth='2' rx='2' />
							<path d='M5.94444444,2.05555556 L5.94444444,12.7222222 L6.5,12.7222222 L6.5,2.05555556 L5.94444444,2.05555556 Z' />
							<rect width='11.444' height='1' x='.5' y='6.722' />
						</g>
						<g transform='translate(38.889)'>
							<rect width='10.444' height='12' x='1' y='1' fill='#FFF' strokeWidth='2' rx='2' />
							<rect width='1' height='10.667' x='5.944' y='2.056' />
							<rect width='11.444' height='1' x='.5' y='6.722' />
						</g>
						<g transform='translate(38.889 20.222)'>
							<rect width='10.444' height='12' x='1' y='1' fill='#FFF' strokeWidth='2' rx='2' />
							<rect width='1' height='10.667' x='5.944' y='2.056' />
							<path d='M0.5,6.72222222 L0.5,7.27777778 L11.9444444,7.27777778 L11.9444444,6.72222222 L0.5,6.72222222 Z' />
						</g>
						<g transform='translate(38.889 40.444)'>
							<rect width='10.444' height='12' x='1' y='1' fill='#FFF' strokeWidth='2' rx='2' />
							<rect width='1' height='10.667' x='5.944' y='2.056' />
							<rect width='11.444' height='1' x='.5' y='6.722' />
						</g>
					</g>
					<g transform='translate(0 76.222)'>
						<rect width='1' height='7.333' x='4.889' y='6.444' stroke={strokeColor} strokeWidth='2' />
						<path
							fill='#00AAE8'
							stroke={strokeColor}
							strokeWidth='2'
							d='M3.47013619,1 L3.91299333,4.44444444 L8.33333333,4.44444444 L8.33333333,3 C8.33333333,1.8954305 7.43790283,1 6.33333333,1 L3.47013619,1 Z'
						/>
						<path
							fill='#CAE0EC'
							d='M2.33333333,0 L2.33333333,0 C3.62199775,-2.36723813e-16 4.66666667,1.04466892 4.66666667,2.33333333 L4.66666667,5.44444444 L0,5.44444444 L0,2.33333333 C-1.57815875e-16,1.04466892 1.04466892,2.36723813e-16 2.33333333,0 Z'
						/>
					</g>
					<rect width='1' height='9.889' x='65.833' y='.5' fill='#D8D8D8' stroke={strokeColor} />
					<path
						fill='#D8D8D8'
						stroke={strokeColor}
						d='M60.3888889,2.83333333 L60.3888889,10.3888889 L60.9444444,10.3888889 L60.9444444,2.83333333 L60.3888889,2.83333333 Z'
					/>
					<path
						fill='#D8D8D8'
						stroke={strokeColor}
						d='M63.2679715,2.512294 L63.2679715,7.07382945 L63.823527,7.07382945 L63.823527,2.512294 L63.2679715,2.512294 Z'
						transform='rotate(70 63.546 4.793)'
					/>
					<path
						fill='#D8D8D8'
						stroke={strokeColor}
						d='M63.2679715,5.62340511 L63.2679715,10.1849406 L63.823527,10.1849406 L63.823527,5.62340511 L63.2679715,5.62340511 Z'
						transform='rotate(70 63.546 7.904)'
					/>
				</g>
			</g>
		</SvgIcon>
	);
};
