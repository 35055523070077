import { header, inputBorderColor } from '../../../styles/colors';
import { BrowserMediaQueries, CssSelectors, baseStyleSheet, textFieldPadding } from '../../../styles/styles';
import { InputErrorStyle } from '../../InputFieldError/styles';
import { StyleSheet } from 'aphrodite';

export const bodyFieldFooterToolbarHeight = 40;
const fieldErrorHeight = 40;
const editorToolbarHeight = 39;

export const styleSheet = StyleSheet.create({
	advancedSettingsButton: {
		alignItems: 'center',
		boxSizing: 'border-box',
		color: '#858585',
		display: 'flex',
		fontSize: 12,
		padding: '0 40px',
		width: '50%',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	advancedSettingsPopoverContent: {
		minWidth: 420,
		padding: '10px 30px 20px 30px',
	},
	advancedSettingsPopoverContentHeader: {
		margin: '0 -20px',
		textAlign: 'right',
	},
	body: {
		background: '#fff',
		flexBasis: 0,
		flexGrow: 1,
		minHeight: 100,
		padding: 0,
		position: 'relative',
		width: '100%',
		...CssSelectors.allDescendants(
			{
				overflow: 'inherit',
			},
			'.tox.tox-tinymce'
		),
		...CssSelectors.allDescendants(
			{
				overflow: 'inherit',
			},
			'.tox.tox-tinymce .tox-editor-container'
		),
		...CssSelectors.allDescendants(
			{
				background: '#fff',
				borderBottom: '1px solid #ccc',
				position: 'sticky',
				top: 0,
				zIndex: 1,
				[BrowserMediaQueries.IE11]: {
					position: 'fixed',
					top: 'auto',
					transform: `translate(0, -${editorToolbarHeight}px)`,
				},
			},
			'.tox.tox-tinymce .tox-editor-container .tox-editor-header'
		),
	},
	bodyFieldContent: {
		cursor: 'text',
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		justifyContent: 'space-between',
		overflowX: 'hidden',
		overflwoY: 'auto',
		width: '100%',
	},
	bodyFieldContentToolbar: {
		background: '#fff',
		borderTop: `1px solid ${inputBorderColor}`,
		boxSizing: 'border-box',
		display: 'flex',
		height: bodyFieldFooterToolbarHeight,
		width: '100%',
	},
	bodyFieldContentWithError: {
		height: `calc(100% - ${fieldErrorHeight}px)`,
	},
	bodyFieldContentWithToolbar: {
		height: `calc(100% - ${bodyFieldFooterToolbarHeight}px)`,
	},
	bodyFieldContentWithToolbarAndError: {
		height: `calc(100% - ${bodyFieldFooterToolbarHeight + fieldErrorHeight}px)`,
	},
	bodyFieldEditor: {
		[BrowserMediaQueries.IE11]: {
			marginTop: `${editorToolbarHeight}px`,
		},
	},
	bodyFieldFooter: {
		padding: '10px',
	},
	bodyFieldPlaceholder: {
		left: `${textFieldPadding}px`,
		pointerEvents: 'none',
		position: 'absolute',
		top: `${textFieldPadding + 40}px`,
	},
	container: {},
	emailComposer: {
		...CssSelectors.allChildren(
			{
				marginTop: '10px',
			},
			':not(:first-child)'
		),
		display: 'flex',
		flexDirection: 'column',
	},
	emailComposerInputLabel: {
		color: header,
		fontSize: '12px',
		lineHeight: '22px',
		marginRight: '6px',
	},
	emailComposerSubjectField: {
		position: 'relative',
	},
	emailComposerTemplatesSelectorButton: {
		marginTop: '10px',
	},
	fieldError: {
		...InputErrorStyle,
		alignItems: 'center',
		display: 'flex',
		height: fieldErrorHeight,
		padding: '0 20px',
	},
	mergeFieldPopoverBody: {
		padding: '8px 14px 12px 14px',
	},
	mergeFieldPopoverContent: {
		background: '#fff',
		boxSizing: 'border-box',
		color: '#4A4A4A',
		fontSize: 12,
		width: 250,
	},
	mergeFieldPopoverContentHeader: {
		background: '#E8F3F9',
		display: 'flex',
		...baseStyleSheet.fontBold,
		padding: '8px 12px',
	},
	signatureToolbarItem: {
		borderRight: `1px solid ${inputBorderColor}`,
		boxSizing: 'border-box',
		width: '50%',
	},
	simpleEditor: {
		display: 'flex',
		flexDirection: 'column',
		flexShrink: 0,
		maxHeight: '100%',
		position: 'relative',
	},
	simpleEditorContent: {
		flexBasis: 0,
		flexGrow: 1,
		[BrowserMediaQueries.IE11]: {
			// IE11 BS... no position: sticky
			...CssSelectors.allDescendants({}, '.tox.tox-tinymce .tox-editor-container .tox-editor-header'),
		},
	},
});
