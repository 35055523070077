import { navigation } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
	onMouseEnter?: React.MouseEventHandler<SVGSVGElement>;
	onMouseLeave?: React.MouseEventHandler<SVGSVGElement>;
}

export const InfoIcon: React.FC<IProps> = props => {
	const { className, fillColor, onMouseLeave, onMouseEnter } = props;
	return (
		<SvgIcon
			className={`${className || ''}`}
			height={14}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			width={14}
		>
			<g fill={fillColor || navigation} fillRule='evenodd'>
				<path d='M7,1.4 C3.9123,1.4 1.4,3.9123 1.4,7 C1.4,10.0877 3.9123,12.6 7,12.6 C10.0877,12.6 12.6,10.0877 12.6,7 C12.6,3.9123 10.0877,1.4 7,1.4 M7,14 C3.1402,14 0,10.8605 0,7 C0,3.1402 3.1402,0 7,0 C10.8598,0 14,3.1402 14,7 C14,10.8605 10.8598,14 7,14' />
				<path d='M7.7 9.1L7.7 6.3C7.7 5.9136 7.3864 5.6 7 5.6L5.6 5.6 5.6 7 6.3 7 6.3 9.1 4.9 9.1 4.9 10.5 9.1 10.5 9.1 9.1 7.7 9.1zM7.875 4.2C7.875 4.683 7.483 5.075 7 5.075 6.517 5.075 6.125 4.683 6.125 4.2 6.125 3.717 6.517 3.325 7 3.325 7.483 3.325 7.875 3.717 7.875 4.2' />
			</g>
		</SvgIcon>
	);
};
