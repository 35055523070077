import * as Api from '@ViewModels';
import BlankUnlayerDesign from '@WebComponents/HtmlNewsletterEditor/blankDesign.unlayer.json';
import { computed, observable, runInAction } from 'mobx';
import { Design } from 'react-email-editor';
import { isIE11 } from '../models/Browser';

export interface IHtmlNewsletter<TTemplate extends object = Api.ITemplate> {
	canEdit: boolean;
	design: Design;
	id: string;
	name: string;
	readonly files: Api.AttachmentsViewModel;
	save(template: TTemplate): Promise<TTemplate>;
	saveAsNewTemplate(template: TTemplate): Promise<TTemplate>;
	sendTestEmail(): Promise<Api.IOperationResult<any>>;
	setScope(scope: Api.TemplateScope): Promise<void>;
	template: Api.ITemplate;
	updateContent(design: Design, htmlStringValue: string): Promise<TTemplate>;
	updateName(name: string): Promise<TTemplate>;
}

export type HtmlNewsletter<T extends object = Api.ITemplate> = Api.ViewModel & IHtmlNewsletter<T>;

export class HtmlNewsletterViewModel extends Api.ViewModel implements IHtmlNewsletter {
	@observable.ref private mTemplate: Api.ITemplate;
	private mTemplates: Api.TemplatesViewModel;
	// @ts-ignore
	@observable.ref private mDesign: Design;
	public readonly files: Api.AttachmentsViewModel;

	public static removeDoctypeFromHtmlStringValue = (html: string) => {
		return html?.replace(/<!DOCTYPE[^>]*>/gim, '').trim();
	};

	public static create = async (userSession: Api.UserSessionContext, template: Api.ITemplate) => {
		const templates = new Api.TemplatesViewModel(userSession);
		const createdTemplate = await templates.create(template);
		return new HtmlNewsletterViewModel(userSession, createdTemplate);
	};

	public static createDefaultBlankTemplateModel = (template: Partial<Api.ITemplate>) => {
		const name = (template?.name || '').trim();
		if (!name) {
			throw Api.asApiError('Template name is required');
		}
		const templateToSave: Api.ITemplate = {
			...template,
			content: {
				document: '<html></html>', // Initial value doesn't have to match rendered source. On first save in the editor, these props will sync
				documentVersion: 1,
				source: JSON.stringify(BlankUnlayerDesign),
				sourceFormat: Api.ContentSourceFormat.UnlayerHtmlNewsletter,
			},
			name,
			templateType: Api.TemplateType.HtmlNewsletter,
		};
		return templateToSave;
	};

	/**
	 * Create a new base template
	 *
	 * @param template Template.name is required
	 */
	public static createDefaultBlankTemplate = async (
		userSession: Api.UserSessionContext,
		template: Partial<Api.ITemplate>,
		save?: boolean
	) => {
		const templates = new Api.TemplatesViewModel(userSession);
		const templateToSave = HtmlNewsletterViewModel.createDefaultBlankTemplateModel(template);
		if (save) {
			const createdTemplate = await templates.create(templateToSave);
			return createdTemplate;
		}
		return templateToSave;
	};

	constructor(userSession: Api.UserSessionContext, template: Api.ITemplate) {
		super(userSession);
		this.sendTestEmail = this.sendTestEmail.bind(this);
		this.mTemplate = template;
		this.mTemplates = new Api.TemplatesViewModel(userSession);
		this.files = new Api.AttachmentsViewModel(userSession);
		if (template?.content?.source) {
			this.mDesign = JSON.parse(template.content.source);
		}
	}

	@computed
	public get canEdit() {
		if (
			this.mTemplate.scope === Api.TemplateScope.Industry ||
			this.mTemplate.scope === Api.TemplateScope.System ||
			isIE11()
		) {
			return false;
		}
		return (
			// @ts-ignore
			this.creator?.id === this.mUserSession.user.id ||
			this.creator?.id === this.mImpersonationContext?.user?.id ||
			this.mUserSession.userRole.toLocaleLowerCase().includes('admin')
		);
	}

	@computed
	public get isLoaded() {
		return (
			this.loaded ||
			(!!this.mTemplate &&
				!!this.mTemplate.id &&
				!!this.mTemplate.name &&
				!!this.mTemplate.creator &&
				!!this.mTemplate.content)
		);
	}

	@computed
	// @ts-ignore
	public get name() {
		return this.mTemplate.name;
	}

	@computed
	// @ts-ignore
	public get id() {
		return this.mTemplate.id;
	}

	@computed
	public get design() {
		return this.mDesign;
	}

	@computed
	public get creator() {
		return this.mTemplate?.creator;
	}

	@computed
	public get isShared() {
		return this.mTemplate?.scope === Api.TemplateScope.Account;
	}

	@computed
	public get template() {
		return this.mTemplate;
	}

	@computed
	public get summary() {
		return this.mTemplate?.summary;
	}

	@computed
	public get canShare() {
		if (this.mTemplate.scope === Api.TemplateScope.Industry || this.mTemplate.scope === Api.TemplateScope.System) {
			return false;
		}
		return (
			this.mTemplate?.creator?.id === this.mUserSession?.user?.id ||
			this.mTemplate?.creator?.id === this.mImpersonationContext?.user?.id ||
			this.mUserSession?.user?.role?.toLocaleLowerCase().includes('admin')
		);
	}

	// @ts-ignore
	public load() {
		if (!this.loading) {
			// @ts-ignore
			const promise = this.mTemplates.getById(this.mTemplate?.id);
			if (promise) {
				this.loading = true;
				promise
					.then(template => {
						runInAction(() => {
							this.loading = false;
							this.mTemplate = template;
							this.mDesign = template.content?.source ? JSON.parse(template.content.source) : null;
						});
					})
					.catch(() => {
						this.loading = false;
					});
			}
			return promise;
		}
	}
	// @ts-ignore
	public save = async (template: Api.ITemplate) => {
		if (!this.busy) {
			this.busy = true;
			try {
				const updatedTemplate =
					this.mTemplate.id && this.mTemplate.id !== 'new'
						? await this.mTemplates.update(template)
						: await this.mTemplates.create(template);

				runInAction(() => {
					this.busy = false;
					this.mTemplate = updatedTemplate;
					// @ts-ignore
					this.mDesign = JSON.parse(updatedTemplate.content.source);
				});

				return updatedTemplate;
			} catch (err) {
				this.busy = false;
				throw Api.asApiError(err);
			}
		}
	};

	// @ts-ignore
	public updateName = async (name: string) => {
		if (!this.busy) {
			this.busy = true;
			try {
				const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ITemplate>(
					this.composeApiUrl({ queryParams: { name }, urlPath: `template/${this.mTemplate.id}/name` }),
					'PUT'
				);
				if (!opResult.success) {
					this.busy = false;
					throw opResult;
				}

				runInAction(() => {
					// @ts-ignore
					this.mTemplate = opResult.value;
					this.busy = false;
				});
				return opResult.value;
			} catch (err) {
				this.busy = false;
				throw Api.asApiError(err);
			}
		}
	};

	/**
	 * Note: This vm will represent the vm for the newly created template after calling this.
	 *
	 * @param name Name of new template
	 */
	// @ts-ignore
	public saveAsNewTemplate = async (template: Api.ITemplate) => {
		if (!this.busy) {
			this.busy = true;
			try {
				const newsletter = await HtmlNewsletterViewModel.create(this.mUserSession, template);
				this.busy = false;
				this.mTemplate = newsletter.toJs();
				return this.mTemplate;
			} catch (err) {
				this.busy = false;
				throw Api.asApiError(err);
			}
		}
	};

	// @ts-ignore
	public updateContent = async (design: Design, htmlStringValue: string) => {
		if (!this.busy) {
			this.busy = true;
			try {
				const content: Api.IRawRichTextContentState = {
					document: htmlStringValue,
					documentVersion: 1,
					source: JSON.stringify(design),
					sourceFormat: Api.ContentSourceFormat.UnlayerHtmlNewsletter,
				};
				const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ITemplate>(
					this.composeApiUrl({ urlPath: `template/${this.mTemplate.id}/content` }),
					'PUT',
					content
				);
				if (!opResult.success) {
					this.busy = false;
					throw opResult;
				}

				runInAction(() => {
					// @ts-ignore
					this.mTemplate = opResult.value;
					this.busy = false;
				});
				return opResult.value;
			} catch (err) {
				this.busy = false;
				throw Api.asApiError(err);
			}
		}
	};

	public setScope = async (scope: Api.TemplateScope): Promise<void> => {
		if (this.isBusy || scope === this.mTemplate?.scope) {
			// @ts-ignore
			return null;
		}

		this.busy = true;
		const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ITemplate>(
			this.composeApiUrl({
				queryParams: {
					scope: scope === Api.TemplateScope.Account ? 1 : 0,
				},
				// @ts-ignore
				urlPath: `template/${encodeURIComponent(this.mTemplate.id)}/scope`,
			}),
			'PATCH'
		);

		this.busy = false;
		if (!opResult.success) {
			throw Api.asApiError(opResult);
		}
		// @ts-ignore
		this.mTemplate = opResult.value;
	};

	// @ts-ignore
	public async sendTestEmail() {
		if (!this.isBusy) {
			this.busy = true;
			const email = new Api.EmailMessageViewModel<File>(this.mUserSession, false, {
				content: this.mTemplate.content,
				options: {
					saveAsNote: false,
					scheduledSend: {
						criteria: Api.ScheduleCriteria.Immediately,
					},
				},
				subject: this.mTemplate.subject || this.mTemplate.name,
				templateReference: {
					isCustomized: false,
					isSystemTemplate: false,
					templateId: this.mTemplate.id,
					name: this.mTemplate.name,
				},
			});
			email.impersonate(this.mImpersonationContext);
			const contactId = this.mImpersonationContext?.user?.contactId ?? this.mUserSession.user.contactId;
			try {
				const contact = new Api.ContactViewModel(this.mUserSession, {
					id: contactId,
				}).impersonate(this.mImpersonationContext);
				email.contactsToAdd.add(contact);
				const res = await email.send();
				this.busy = false;
				return res;
			} catch (err) {
				this.busy = false;
				throw Api.asApiError(err);
			}
		}
	}

	public toJs = () => {
		return this.mTemplate;
	};

	public impersonate(impersonationContext?: Api.IImpersonationContext) {
		super.impersonate(impersonationContext);
		this.mTemplates?.impersonate(impersonationContext);
		return this;
	}
}
