import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		fontSize: 14,
		textAlign: 'left',
	},
	label: {
		color: '#4A4A4A',
		marginBottom: 6,
	},
});
