import { baseStyleSheet } from '../../../styles/styles';
import { EntityInfoFieldLabel } from '../EntityInfoFieldLabel';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';
import Linkify from 'react-linkify';

interface IProps {
	className?: string;
	enableLinkify?: boolean;
	label?: string;
	truncateText?: boolean;
	value: React.ReactNode;
	valueClassName?: string;
}

export const EntityInfoField: React.FC<IProps> = props => {
	const { className, truncateText, value, label, valueClassName, enableLinkify } = props;
	if (!value) {
		return null;
	}

	const onRenderLink = (decoratedHref: string, decoratedText: string, key: number) => {
		return (
			<a
				key={key}
				className={css(styleSheet.autoLink)}
				href={decoratedHref}
				target='_blank'
				rel='noreferrer'
			>{`${decoratedText}`}</a>
		);
	};

	const renderOutput = (output: React.ReactNode) => {
		return (
			<div
				className={`${css(baseStyleSheet.breakWord)} ${valueClassName || ''} ${truncateText ? 'truncate-text' : ''}`}
			>
				{output}
			</div>
		);
	};

	return (
		<div className={`${css(styleSheet.container)} entity-info-field ${className || ''}`}>
			{!!label && <EntityInfoFieldLabel title={label} />}
			{enableLinkify ? <Linkify componentDecorator={onRenderLink}>{renderOutput(value)}</Linkify> : renderOutput(value)}
		</div>
	);
};
