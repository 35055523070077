import * as PubSub from 'pubsub-js';
import { useRef } from 'react';

export interface ILocalNotification<T> {
	info?: T;
	topic: string;
}

export type LocalNotificationObserver<T> = (topic: string, notification: ILocalNotification<T>) => void;

export const addObserver = <T>(topic: string, observer: LocalNotificationObserver<T>) => {
	return PubSub.subscribe(topic, observer);
};

export const removeObserver = <T>(observer: LocalNotificationObserver<T> | any) => {
	return PubSub.unsubscribe(observer);
};

export const postNotification = <T>(notification: ILocalNotification<T>) => {
	PubSub.publish(notification.topic, notification);
};

interface ILocalNotificationService<T = any> {
	addObserver(topic: string, observer: LocalNotificationObserver<T>): any;
	postNotification(notification: ILocalNotification<T>): void;
	removeObserver(observer: LocalNotificationObserver<T> | any): any;
}

export const useLocalNotificationService = <T = any>(): ILocalNotificationService<T> => {
	return useRef({ addObserver, postNotification, removeObserver } as const).current;
};
