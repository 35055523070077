import { navigation } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const RoundAddIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;
	return (
		<SvgIcon className={`round-add-icon ${className || ''}`} height={16} width={16}>
			<g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
				<g fill={fillColor || navigation}>
					<path d='M8,14.4 C4.4712,14.4 1.6,11.5288 1.6,8 C1.6,4.4712 4.4712,1.6 8,1.6 C11.5288,1.6 14.4,4.4712 14.4,8 C14.4,11.5288 11.5288,14.4 8,14.4 M8,0 C3.5888,0 0,3.5888 0,8 C0,12.4112 3.5888,16 8,16 C12.4112,16 16,12.4112 16,8 C16,3.5888 12.4112,0 8,0' />
					<polygon points='8.8 4 7.2 4 7.2 7.2 4 7.2 4 8.8 7.2 8.8 7.2 12 8.8 12 8.8 8.8 12 8.8 12 7.2 8.8 7.2' />
				</g>
			</g>
		</SvgIcon>
	);
};
