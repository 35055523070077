import { SvgIcon } from '../../../components/svgs/icons/SvgIcon';
import { nameCircles } from '../../../styles/colors';
import * as React from 'react';

export function HwSampleW3(props: React.SVGProps<SVGSVGElement>) {
	return (
		<SvgIcon {...props} viewBox='0 0 20 12'>
			<path
				fill={nameCircles}
				fillRule='evenodd'
				d='M1.168 6.674c.15-.023.298-.145.422-.252.318-.274.615-.57.934-.845.278-.239.718-.149.833.161.145.393.282.79.42 1.184a15.42 15.42 0 0 0 1.81 3.589c.694 1.019 2.573 1.107 3.322.132.509-.663.925-1.398 1.383-2.1.222-.34.57-.382.784-.093.497.676.98 1.363 1.496 2.025.378.486.916.763 1.515.813.58.048 1.182.053 1.75-.056a2.18 2.18 0 0 0 1.077-.586c.96-.968 1.709-2.099 2.026-3.433.472-1.983.505-3.986.022-5.98-.02-.078-.075-.178-.14-.204a1.514 1.514 0 0 0-.465-.107c-.047-.004-.12.1-.151.167-.34.738.028 1.484-.054 2.557 0 .424.039 1.182-.008 1.934-.075 1.198-.501 2.29-1.168 3.284a3.691 3.691 0 0 1-1.497 1.313c-.4.19-.83.216-1.215.008-.365-.197-.747-.422-1.016-.727-1.183-1.342-2.132-2.807-2.165-4.689-.004-.25-.008-.529-.12-.737-.107-.202-.332-.378-.546-.474-.2-.09-.69.372-.697.612-.008.25-.054.51-.004.748.354 1.707-.295 3.13-1.255 4.456-.191.264-.492.45-.748.665-.25.209-.653.146-.917-.059-.553-.426-.895-1.007-1.19-1.606-.745-1.512-1.3-3.093-1.568-4.764-.04-.248-.055-.5-.093-.749s-.323-.51-.51-.45c-.145.046-.366.124-.396.232-.303 1.103-1.037 1.903-1.811 2.684a4.178 4.178 0 0 0-.626.775c-.056.092.03.335.127.435a.575.575 0 0 0 .439.137Z'
				clipRule='evenodd'
			/>
		</SvgIcon>
	);
}
