import { navigation } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		alignItems: 'center',
		color: navigation,
		display: 'inline-flex',
		fontSize: 12,
		justifyContent: 'center',
	},
	name: {
		maxWidth: 400,
	},
	nameCompact: {
		maxWidth: 180,
	},
	privacyIcon: {
		marginLeft: 10,
	},
});
