import * as React from 'react';
import { navigation } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';

export const FoldedCardInsideIcon = ({
	height = 21,
	width = 33,
	fillColor = navigation,
	...rest
}: React.SVGProps<SVGSVGElement> & { fillColor?: string }) => {
	return (
		<SvgIcon height={height} width={width} {...rest}>
			<mask id='a' fill='#fff'>
				<rect width='33' height='21' rx='1' />
			</mask>
			<rect
				width='33'
				height='21'
				fill={fillColor}
				fillOpacity='.052'
				stroke={fillColor}
				strokeWidth='4'
				mask='url(#a)'
				rx='1'
			/>
			<mask id='b' width='20' height='6' x='7' y='7' maskUnits='userSpaceOnUse' style={{ maskType: 'luminance' }}>
				<path fill='#fff' fillRule='evenodd' d='M7 7h20v6H7V7Z' clipRule='evenodd' />
			</mask>
			<g mask='url(#b)'>
				<path
					fill={fillColor}
					fillRule='evenodd'
					d='M26.6 7.093a.851.851 0 0 0-.658-.049.713.713 0 0 0-.427.38 6.62 6.62 0 0 1-.707 1.204l-.103.133a6.775 6.775 0 0 1-1.068 1.063l-.057.046a6.574 6.574 0 0 1-1.34.785l-.066.028a6.824 6.824 0 0 1-1.614.432l-.015.002-.077.01a6.817 6.817 0 0 1-1.567-.006l-.073-.01a6.53 6.53 0 0 1-.369-.064 5.178 5.178 0 0 1-.785-.221l-.076-.03a.454.454 0 0 0-.02-.008l-.033-.014a2.951 2.951 0 0 1-.34-.182 2.015 2.015 0 0 1-.072-.046l-.034-.025a1.66 1.66 0 0 1-.184-.176.97.97 0 0 1-.078-.126l-.011-.025a.442.442 0 0 0-.013-.028l-.024-.066c-.056-.172-.088-.36-.123-.56-.019-.112-.038-.225-.062-.336-.101-.48-.279-.862-.542-1.169a1.37 1.37 0 0 0-.785-.46c-.306-.053-.617-.01-.95.133-.409.175-.758.449-1.018.668-.607.513-1.053 1.148-1.445 1.76a3.103 3.103 0 0 0-.245-.454c-.36-.545-.865-.852-1.421-.865h-.032c-.475 0-.873.246-1.201.488-.297.219-.548.441-.769.68a5.398 5.398 0 0 0-1.16 1.952.755.755 0 0 0 .067.611c.11.193.29.33.497.377a.796.796 0 0 0 .944-.528c.02-.058.04-.116.062-.174l.046-.11a4.05 4.05 0 0 1 .463-.787l.085-.107a4.149 4.149 0 0 1 .588-.576l.033-.026c.05-.037.1-.074.151-.109a2.126 2.126 0 0 1 .2-.121.171.171 0 0 0 .004.003l.01.008.013.012a.825.825 0 0 1 .046.049l.016.021a1.659 1.659 0 0 1 .177.328l.003.007c.059.147.11.309.157.493a6.366 6.366 0 0 1 .135.691l.006.047c.009.07.016.142.023.213.04.422.376.741.774.741h.033c.395-.018.718-.313.763-.677a7.631 7.631 0 0 1 1.376-2.098l.003-.004.031-.04a5.08 5.08 0 0 1 .396-.44c.1-.097.212-.196.343-.301l.004-.003.026-.02a4.629 4.629 0 0 1 .303-.203 1.93 1.93 0 0 1 .12-.066l.004.008c.012.02.022.042.033.063l.002.004v.003c.006.011.01.023.014.035.038.106.07.226.1.392.015.073.027.146.04.219.045.262.091.533.197.806.156.4.394.734.709.993.28.231.6.416 1.005.582.605.248 1.291.398 2.097.458a8.007 8.007 0 0 0 4.578-1.05 7.994 7.994 0 0 0 2.978-3.068c.08-.148.157-.304.23-.464.18-.4.043-.845-.322-1.036'
					clipRule='evenodd'
				/>
			</g>
		</SvgIcon>
	);
};
