import * as Api from '@ViewModels';
import * as React from 'react';
import { useEventLogging } from '../../../models/Logging';
import { useErrorMessages, useFullscreenModal, useToaster, useUserSession } from '../../../models/hooks/appStateHooks';
import {
	invalidateSchedulerConfigsQuery,
	useSchedulerConfigAddMutation,
	useSchedulerConfigDeleteMutation,
	useSchedulerConfigsQuery,
} from '../../../queries';
import { IMeetingConfigModalLocationState } from '../../components/settings/PersonalSettings/MeetingConfigModal';

export function useScheduleMeetingSettings({
	user,
	onEditMeetingClicked,
}: {
	user: Api.IUser;
	onEditMeetingClicked?: (meeting: Api.MeetingConfigViewModel) => void;
}) {
	const userSession = useUserSession();
	const logger = useEventLogging();
	const errorMessages = useErrorMessages();
	const fullscreenModal = useFullscreenModal();
	const toaster = useToaster();
	// @ts-ignore
	const schedulerConfigQuery = useSchedulerConfigsQuery({ userId: user.id });
	const addMeetingMutation = useSchedulerConfigAddMutation({
		onError: err => {
			logger.logApiError('ScheduleMeetingCreateNew-Error', err);
			// @ts-ignore
			errorMessages.pushApiError(err);
		},
		onSuccess: meetingResponse => {
			// @ts-ignore
			invalidateSchedulerConfigsQuery({ userId: user.id });
			editMeeting(new Api.MeetingConfigViewModel(userSession, meetingResponse, user));
		},
	});
	const deleteMeetingMutation = useSchedulerConfigDeleteMutation({
		onError: err => {
			logger.logApiError('ScheduleMeetingDelete-Error', err);
			// @ts-ignore
			errorMessages.pushApiError(err);
		},
		onSuccess: () => {
			// @ts-ignore
			invalidateSchedulerConfigsQuery({ userId: user.id });
			// @ts-ignore
			toaster.push({
				message: 'Meeting deleted successfully.',
				type: 'successMessage',
			});
		},
	});
	const meetingConfigs = React.useMemo(() => {
		if (schedulerConfigQuery.data) {
			return schedulerConfigQuery.data.values.map(config => new Api.MeetingConfigViewModel(userSession, config, user));
		}
		return [];
	}, [schedulerConfigQuery.data, user, userSession]);

	const editMeeting = (meeting: Api.MeetingConfigViewModel) => {
		const locationState: IMeetingConfigModalLocationState = {
			viewModel: meeting,
		};
		// @ts-ignore
		fullscreenModal.history.push({
			pathname: '/meeting/config',
			state: locationState,
		});
		onEditMeetingClicked?.(meeting);
	};

	return {
		isLoadingMeetingLinks: schedulerConfigQuery.isLoading,
		meetingConfigs,
		// @ts-ignore
		onAddMeeting: () => addMeetingMutation.mutate({ userId: user.id }),
		onDeleteMeeting: (meeting: Api.MeetingConfigViewModel) =>
			// @ts-ignore
			// @ts-ignore
			deleteMeetingMutation.mutate({ meetingId: meeting.id, userId: user.id }),
		onEditMeeting: editMeeting,
	};
}
