import { brandSecondary } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const BackArrowIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;
	return (
		<SvgIcon className={`back-arrow-icon ${className || ''}`} height={12} width={16}>
			<polygon
				fill={fillColor || brandSecondary}
				fillRule='evenodd'
				points='3.907 9.33 1.619 11.619 7.619 17.619 13.619 11.619 11.33 9.33 9.237 11.423 9.237 2.381 6 2.381 6 11.423'
				transform='rotate(90 9.619 8)'
			/>
		</SvgIcon>
	);
};
