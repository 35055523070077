import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { AutomationPreview } from '.';
import { DefaultEditableActionItemContentCSS } from '../../../../models';
import {
	convertRawRichTextContentStateToRichContentEditorState,
	devElementDataProps,
} from '../../../../models/UiUtils';
import { titles } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import {
	IRichContentDocumentEditorConfig,
	RichContentDocumentEditor,
} from '../../richContent/RichContentDocumentEditor';
import { AutomationStepIcon } from '../../svgs/icons/AutomationStepIcon';
import { Tag } from '../../tags/Tag';
import { styleSheet } from './styles';

interface IAutomationStepPreviewProps {
	automationTemplate?: Api.AutomationTemplateViewModel;
	/** Default = true */
	renderCustomAutomationPreview?: (template: Api.AutomationTemplateViewModel) => React.ReactNode;
	showPublished?: boolean;
	step?: Api.IAutomationStep;
	steps?: Api.IAutomationStepReference[];
	styles?: StyleDeclarationValue[];
}

const DefaultEditorConfig: IRichContentDocumentEditorConfig = {
	autoresizeToFitContent: true,
	contentRawCss: DefaultEditableActionItemContentCSS.replace(/color:[ ]?[#]?[a-z0-9]*;/i, `color: ${titles};`),
	minHeight: 14,
	plugins: [], // none
	toolbar: false,
};

export interface IAutomationPreviewStepsContext {
	caseStatement?: Api.IAutomationStepCaseStatement;
	rootAutomationTemplate?: Api.AutomationTemplateViewModel;
}

// @ts-ignore
export const AutomationPreviewStepsContext = React.createContext<IAutomationPreviewStepsContext>(null);

const getTrigger = (automationTemplate?: Api.AutomationTemplateViewModel, published = true) => {
	return (
		(published ? automationTemplate?.publishedTriggerReference : automationTemplate?.draftTriggerReference) ||
		automationTemplate?.publishedTriggerReference
	);
};

export const AutomationPreviewStep: React.FC<IAutomationStepPreviewProps> = observer(props => {
	const {
		automationTemplate,
		children,
		renderCustomAutomationPreview,
		showPublished = true,
		step,
		steps,
		styles = [],
	} = props;
	const context = React.useContext(AutomationPreviewStepsContext);
	const trigger =
		getTrigger(context?.rootAutomationTemplate, showPublished) || getTrigger(automationTemplate, showPublished);

	let name = step?._type || '';
	const days = step?.schedule?.numberOfDays || 0;
	const dayCount = Math.abs(days);

	let content: React.ReactNode;
	const onRenderHeader = React.useCallback(
		(stepName = step?._type || '', titleStyle?: StyleDeclarationValue, showDescription = true) => {
			// @ts-ignore
			let triggerDescription: string = null;
			switch (trigger?._type) {
				case Api.AutomationTriggerType.Tag: {
					triggerDescription = 'adding the tag';
					break;
				}
				case Api.AutomationTriggerType.ResourceSelector: {
					const t = trigger as Api.IResourceSelectorAutomationTrigger;
					if (
						t.resourceSelector === Api.ResourceSelectorId.HappyBirthday ||
						t.resourceSelector === Api.ResourceSelectorId.Turning65 ||
						t.resourceSelector === Api.ResourceSelectorId.Turning72 ||
						t.resourceSelector === Api.ResourceSelectorId.Turning73
					) {
						triggerDescription = 'the birthday';
					} else if (t.resourceSelector === Api.ResourceSelectorId.PolicyRenew) {
						triggerDescription = 'renewal';
					} else if (t.resourceSelector === Api.ResourceSelectorId.FinancialReview) {
						triggerDescription = 'the review';
					}
					break;
				}
				default: {
					break;
				}
			}

			const isDoNothing = stepName === 'NoActionAutomationStep';

			stepName = isDoNothing ? 'Do Nothing' : stepName;

			return (
				<div className={css(baseStyleSheet.tableColumnHeader, styleSheet.stepTitle, titleStyle)}>
					{showDescription
						? `${stepName} ${
								days === 0
									? 'same day as '
									: `${dayCount} day${dayCount > 1 ? 's' : ''} ${days < 0 ? 'before' : 'after '}`
							} ${triggerDescription ? ` ${triggerDescription}` : ''}`
						: stepName}
				</div>
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[step, trigger, showPublished]
	);

	const [actionItemContentEditorState, setActionItemContentEditorState] =
		// @ts-ignore
		React.useState<Api.IRichContentEditorState>(null);
	// @ts-ignore
	const [emailContentEditorState, setEmailContentEditorState] = React.useState<Api.IRichContentEditorState>(null);
	// @ts-ignore
	const [textingContentEditorState, setTextingContentEditorState] = React.useState<Api.IRichContentEditorState>(null);
	React.useEffect(() => {
		if (step?._type === 'ActionItemAutomationStep') {
			const s = step as Api.IActionItemAutomationStep;
			setActionItemContentEditorState(convertRawRichTextContentStateToRichContentEditorState(s.content));
		} else if (step?._type === 'EmailAutomationStep') {
			const s = step as Api.IEmailAutomationStep;
			setEmailContentEditorState(convertRawRichTextContentStateToRichContentEditorState(s.content));
		} else if (step?._type === 'TextingAutomationStep') {
			const s = step as Api.IEmailAutomationStep;
			setTextingContentEditorState(convertRawRichTextContentStateToRichContentEditorState(s.content));
		}
	}, [automationTemplate, step]);

	const [childTemplates, setChildTemplates] = React.useState<
		Record<
			string,
			{
				template: Api.AutomationTemplateViewModel;
				context?: IAutomationPreviewStepsContext;
			}
		>
	>({});
	React.useEffect(() => {
		if (automationTemplate?.allStepsLoaded || automationTemplate?.published) {
			if (step?._type === 'SwitchAutomationStep') {
				const s = step as Api.ISwitchAutomationStep;
				const templates = s.cases?.reduce<
					Record<
						string,
						{
							template: Api.AutomationTemplateViewModel;
							context?: IAutomationPreviewStepsContext;
						}
					>
				>((result, caseStatement) => {
					const templateId = caseStatement.automationTemplateId;
					const childTemplate = new Api.AutomationTemplateViewModel(automationTemplate.userSession, {
						id: templateId,
						templateType: Api.TemplateType.Automation,
					});
					// @ts-ignore
					result[templateId] = {
						context: {
							caseStatement,
							rootAutomationTemplate: context?.rootAutomationTemplate || automationTemplate,
						},
						template: childTemplate,
					};
					return result;
				}, {});
				// @ts-ignore
				setChildTemplates(templates);
			} else {
				setChildTemplates({});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [automationTemplate?.allStepsLoaded]);

	switch (step?._type) {
		case 'ActionItemAutomationStep': {
			name = 'Action Item';
			const s = step as Api.IActionItemAutomationStep;
			content = !!s.content && (
				<RichContentDocumentEditor
					config={DefaultEditorConfig}
					contentState={actionItemContentEditorState}
					key={`${s.id}-action-item-content`}
					readOnly={true}
					styles={[styleSheet.richContentEditor]}
				/>
			);
			break;
		}
		case 'AddTagAutomationStep':
		case 'RemoveTagAutomationStep': {
			name = step._type === 'AddTagAutomationStep' ? 'Add Tag' : 'Remove Tag';
			const s = step as Api.ITagAutomationStep;
			content = !!s.tagName && <Tag className={css(styleSheet.tag)} tagValue={s.tagName} />;
			break;
		}
		case 'TextingAutomationStep':
		case 'EmailAutomationStep': {
			const texting = step._type === 'TextingAutomationStep';
			name = texting ? 'Texting' : 'Email';
			const s = step as Api.IEmailAutomationStep | Api.ITextingAutomationStep;
			content = !!s.content && (
				<RichContentDocumentEditor
					config={DefaultEditorConfig}
					contentState={texting ? textingContentEditorState : emailContentEditorState}
					key={`${s.id}-${step._type}-content`}
					readOnly={true}
					styles={[styleSheet.richContentEditor]}
				/>
			);
			break;
		}
		case 'SwitchAutomationStep': {
			const s = step as Api.ISwitchAutomationStep;
			if (!childTemplates) {
				return null;
			}
			return (
				<>
					{s.cases?.map((caseStatement, index) => {
						const templateId = caseStatement.automationTemplateId;
						// @ts-ignore
						const child = childTemplates[templateId];
						if (!child) {
							return null;
						}
						// handle the display name for the segment
						let caseStatementName = `${
							caseStatement.name || `Segment ${index + 1}${caseStatement.isDefault ? ' (everyone else)' : ''}`
						}`;
						if (
							!caseStatement.name &&
							!caseStatement.isDefault &&
							Api.VmUtils.Automations.triggers.isResourceSelectorTrigger(trigger, Api.ResourceSelectorId.PolicyRenew)
						) {
							// @ts-ignore
							const sorted = Api.VmUtils.sortContactFilterCriteria(caseStatement?.filter);
							const filter = sorted.filters[0];
							const policyCriteria = filter?.criteria?.find(
								x => x.property === Api.ContactFilterCriteriaProperty.Policy
							);
							if (policyCriteria) {
								caseStatementName = `${caseStatementName}: ${policyCriteria.value}`;
							}
						}
						return (
							<AutomationPreviewStepsContext.Provider key={templateId} value={child.context}>
								<div className={css(...styles)} {...devElementDataProps({ id: templateId })}>
									<div className={css(styleSheet.segmentStepHeader)}>
										<div className={css(styleSheet.stepIndicator)}>
											<AutomationStepIcon
												className={css(styleSheet.stepIndicatorIcon)}
												key={step.id}
												type={step._type as Api.AutomationStepModelType}
											/>
										</div>
										{onRenderHeader(caseStatementName, styleSheet.stepTitleNoMargin, false)}
									</div>
									{renderCustomAutomationPreview ? (
										renderCustomAutomationPreview?.(child.template) || null
									) : (
										<AutomationPreview key={templateId} showPublished={showPublished} template={child.template} />
									)}
									{children}
								</div>
							</AutomationPreviewStepsContext.Provider>
						);
					})}
				</>
			);
		}
		default: {
			break;
		}
	}

	const isLast = !!steps && step === steps[steps.length - 1];
	const hideLine = context?.caseStatement ? isLast && !!context?.caseStatement.isDefault : isLast;
	return (
		<div className={css(styleSheet.step, ...styles)}>
			<div className={css(styleSheet.stepIndicator)}>
				<AutomationStepIcon
					className={css(styleSheet.stepIndicatorIcon)}
					// @ts-ignore
					key={step.id}
					type={step._type as Api.AutomationStepModelType}
				/>
				{!hideLine && <div className={css(styleSheet.stepIndicatorLine)} />}
			</div>
			<div className={css(styleSheet.stepContent)}>
				{onRenderHeader(name)}
				{content}
				{children}
			</div>
		</div>
	);
});
