import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	message: {
		':not(:first-child)': {
			marginTop: 10,
		},
	},
	messages: {
		boxSizing: 'border-box',
		padding: '0 20px',
	},
	modal: {
		':nth-child(1n).modal.modal-active': {
			zIndex: 7,
		},
	},
	modalContent: {
		boxSizing: 'border-box',
		width: 420,
	},
});
