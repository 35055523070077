import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IToasterComponentProps, IUserSessionComponentProps, UserSessionViewModelKey } from '../../../models/AppState';
import { brandPrimary } from '../../styles/colors';
import { INavBarLink } from '../NavBarLink';
import { PortalDestination } from '../Portal';
import { UserProfileNav } from '../UserProfileNav';
import { SvgIcon } from '../svgs/icons/SvgIcon';
import { AppBarContext } from './context';
import { styleSheet } from './styles';

export const AppBarNavigationItemPortalDestinationId = 'AppBarNavigationItemPortalDestinationId';

interface IProps extends IUserSessionComponentProps, IToasterComponentProps {
	className?: string;
	style?: React.CSSProperties;
	coffeeLinks?: INavBarLink[];
}

class _AppBar extends React.Component<IProps> {
	public render() {
		const { className, style, coffeeLinks } = this.props;
		return (
			<AppBarContext.Consumer>
				{context => {
					return (
						<div
							className={`app-bar ${css(styleSheet.appBar, ...(context?.appBarStyles || []))} ${className || ''}`}
							style={style}
						>
							<div
								className={`app-bar-content ${css(styleSheet.appBarContent, ...(context?.appBarContentStyles || []))}`}
							>
								<div className={`app-bar-navigation-item ${css(styleSheet.navItem)}`}>
									<PortalDestination id={AppBarNavigationItemPortalDestinationId} />
								</div>
								<div className={css(styleSheet.rightAccessoryContainer)}>
									<UserProfileNav
										className={`app-bar-user-profile-button ${css(styleSheet.userProfileNav)}`}
										coffeeLinks={coffeeLinks}
									/>
									<a
										className={css(styleSheet.helpLink)}
										href='https://help.levitate.ai'
										target='_blank'
										rel='noreferrer'
									>
										<SvgIcon height={32} width={64}>
											<rect fill={brandPrimary} fillRule='nonzero' x='0' y='0' width='64' height='32' rx='16' />
											<text transform='translate(17 21)' className={css(styleSheet.helpText)}>
												Help
											</text>
										</SvgIcon>
									</a>
								</div>
							</div>
						</div>
					);
				}}
			</AppBarContext.Consumer>
		);
	}
}

const AppBarAsObserver = observer(_AppBar);
export const AppBar = inject(UserSessionViewModelKey)(AppBarAsObserver);
