import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	automationCardSubTitle: {
		margin: '0 0 16px 0',
	},
	footer: {
		display: 'flex',
		justifyContent: 'center',
		margin: '32px 0 0 0',
	},
	main: {
		boxSizing: 'border-box',
		maxWidth: 460,
		padding: '0 30px',
	},
});
