import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { TemplateActions } from '../../../../admin/containers/templates/TemplateCategory';
import { CardSize, IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../models';
import { IErrorMessageComponentProps } from '../../../../models/AppState';
import { getPlainTextPreviewForRawRichTextContent, isTemplateNew } from '../../../../models/UiUtils';
import { IRawRichTextContentState, ITemplateCard } from '../../../../viewmodels/AppViewModels';
import { baseStyleSheet, bs } from '../../../styles/styles';
import { IMoreMenuItem } from '../../MoreMenu';
import '../../richContent/RichContentDocumentEditor/placeholders.tinymce.css';
import { CampaignExpiryHint } from '../CampaignExpiryHint';
import { campaignTemplateCardStyleSheet, styleSheet } from './styles';

import { useLocation } from 'react-router';
import { ConditionalAccess } from '../../../../admin/components/ConditionalAccess';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { useLambda } from '../../../../models/hooks/useLambda';
import { AdminPermission } from '../../../../viewmodels/AdminViewModels';
import { DeprecatedMoreMenu } from '../../DeprecatedMoreMenu';
import { PopoverType, TinyPopover } from '../../TinyPopover';
import { OpenLetter } from '../../svgs/icons/OpenLetter';
import { SendNow } from '../../svgs/icons/SendNow';
import { SocialMediaIndicatorIcon } from '../../svgs/icons/SocialMediaIndicatorIcon';
import {
	CampaignSummaryFromContent,
	CampaignTemplateCardMenu,
} from '../../templateCards/CampaignTemplateCard/presentation';

export interface CampaignCalendarTemplateCardProps extends IImpersonationContextComponentProps {
	card: ITemplateCard;
	ctaText?: string;
	className?: string;
	copyMenu?: IMoreMenuItem<string>[];
	industry?: Api.Industry;
	isAccountTemplate?: boolean;
	isFeatured?: boolean;
	onCtaClicked?(e?: React.MouseEvent<Element>): void;
	onDelete?(): void;
	onMenuItemClicked?(menuItem: IMoreMenuItem<TemplateActions>, e: React.MouseEvent<HTMLElement>): void;
	onShare?(isShared: boolean): void;
	onSocialClicked?(e: React.MouseEvent<Element>): void;
	rowIndex?: number;
	styles?: StyleDeclarationValue[];
	templateIndex?: number;
	useWholeCardAsCTA?: boolean;
}

const CampaignCalendarTemplateCardBase = ({
	card,
	ctaText,
	copyMenu,
	impersonationContext,
	isAccountTemplate,
	isFeatured,
	onCtaClicked,
	onDelete,
	onMenuItemClicked,
	onShare,
	onSocialClicked,
	rowIndex,
	styles,
	templateIndex,
	useWholeCardAsCTA,
}: CampaignCalendarTemplateCardProps) => {
	const userSession = useUserSession();
	const loc = useLocation();
	const cardSize: CardSize = CardSize.Small;
	const [showSocialPopover, , setShowSocialPopover] = useLambda(false);
	const expirationDate = card?.schedule?.expirationDate ? moment(card?.schedule?.expirationDate) : undefined;
	// intentionally disabling social media indicator while under impersonation context until we have compliance figured out
	const containsSocialMedia =
		userSession?.account?.features?.socialMedia?.enabled && card?.associatedTemplates?.length > 0;
	const showingSocialMedia = !impersonationContext?.isValid && !loc.pathname?.includes('ghost') && containsSocialMedia;

	const onSocialMediaIndicatorClicked = (e: React.MouseEvent<SVGSVGElement>) => {
		if (useWholeCardAsCTA) {
			return;
		}
		setShowSocialPopover(false)();
		onSocialClicked?.(e);
	};

	const isNew = isTemplateNew(card);
	return (
		<div
			className={css(
				bs.relative,
				campaignTemplateCardStyleSheet.container,
				isFeatured && campaignTemplateCardStyleSheet.featured,
				cardSize === CardSize.Small ? campaignTemplateCardStyleSheet.smallContainer : undefined,
				...(styles || [])
			)}
			onClick={useWholeCardAsCTA ? onCtaClicked : undefined}
		>
			<ConditionalAccess permission={AdminPermission.CreateCampaigns}>
				{copyMenu?.length && (
					<DeprecatedMoreMenu
						menuButtonClassName={css(campaignTemplateCardStyleSheet.actionsColMoreButton)}
						menuItems={copyMenu}
						onMenuItemClicked={onMenuItemClicked}
					/>
				)}
			</ConditionalAccess>
			{rowIndex === 0 && isFeatured && templateIndex === 0 && (
				<SendNow className={css(campaignTemplateCardStyleSheet.sendNow)} />
			)}
			{isAccountTemplate ? (
				<CampaignTemplateCardMenu card={card} userSession={userSession} onDelete={onDelete} onShare={onShare} />
			) : null}
			{rowIndex === 0 && isFeatured && templateIndex === 2 && (
				<OpenLetter className={css(campaignTemplateCardStyleSheet.openLetter)} />
			)}
			<div className={css(campaignTemplateCardStyleSheet.headerContainer)}>
				<div
					className={css(
						campaignTemplateCardStyleSheet.nameContainer,
						cardSize === CardSize.Small ? campaignTemplateCardStyleSheet.nameContainerSmall : undefined,
						isFeatured && rowIndex === 0 ? campaignTemplateCardStyleSheet.flagHighlight : undefined
					)}
				>
					<div className={css(bs.truncateText)}>{card.name || 'Custom Post'} </div>
					{expirationDate ? <CampaignExpiryHint expirationDate={expirationDate} /> : null}
					{isNew ? <span className={css(campaignTemplateCardStyleSheet.newItem)}>(New!)</span> : null}
				</div>
				{showingSocialMedia ? (
					<TinyPopover
						align='end'
						anchor={
							<SocialMediaIndicatorIcon
								className={css(bs.cursorPointer)}
								onClick={onSocialMediaIndicatorClicked}
								onMouseEnter={setShowSocialPopover(true)}
								onMouseLeave={setShowSocialPopover(false)}
							/>
						}
						anchorStyles={[bs.absolute, campaignTemplateCardStyleSheet.popoverAnchor]}
						dismissOnOutsideAction={true}
						isOpen={showSocialPopover}
						placement={['top', 'left', 'bottom']}
						type={PopoverType.lightBlue}
					>
						<div className={css(campaignTemplateCardStyleSheet.popover)}>Also available for social media. </div>
					</TinyPopover>
				) : (
					<div className={css(campaignTemplateCardStyleSheet.popoverContainer)} />
				)}
			</div>

			<CampaignSummaryFromContent card={card} cardSize={cardSize} isAccountTemplate={isAccountTemplate} />
			<CampaignCalendarTemplateCardCta
				ctaText={ctaText}
				onCtaClicked={onCtaClicked}
				useWholeCardAsCTA={useWholeCardAsCTA}
			/>
		</div>
	);
};

export const CampaignCalendarTemplateCard = inject(ImpersonationContextKey)(CampaignCalendarTemplateCardBase);

export const CampaignCalendarTemplateCardCta = ({
	ctaText,
	onCtaClicked,
	useWholeCardAsCTA,
}: {
	ctaText: string;
	onCtaClicked: () => void;
	useWholeCardAsCTA: boolean;
}) => {
	if (!onCtaClicked && useWholeCardAsCTA) {
		return null;
	}
	return (
		<div className={css(baseStyleSheet.horizontalStack, bs.mt5)}>
			<button onClick={onCtaClicked} className={css(bs.ctaButtonReverseSmall, styleSheet.button)}>
				<span>{ctaText || 'View Campaign'}</span>
			</button>
		</div>
	);
};

interface SocialCampaignCalendarTemplateCardProps
	extends IImpersonationContextComponentProps,
		IErrorMessageComponentProps {
	card: ITemplateCard | Api.ISocialMediaPost;
	className?: string;
	ctaText?: string;
	isFeatured?: boolean;
	onCtaClicked?(): void;
	onMenuItemClicked?(menuItem: IMoreMenuItem<TemplateActions>, e: React.MouseEvent<HTMLElement>): void;
}

const SocialCampaignCalendarTemplateCardBase: React.FC<SocialCampaignCalendarTemplateCardProps> = props => {
	const { card, ctaText, onCtaClicked } = props;
	const { text, truncated } = getPlainTextPreviewForRawRichTextContent(card?.content, {
		maxCharCount: 58,
	});
	const tCard = card as ITemplateCard;
	const expirationDate = tCard?.schedule?.expirationDate ? moment(tCard?.schedule?.expirationDate) : undefined;

	return (
		<div
			className={css(
				bs.p4,
				bs.gap2,
				bs.flex,
				bs.rounded,
				bs.relative,
				bs.bgWhite,
				bs.borderSolid,
				bs.border,
				bs.borderAntiFlashWhite,
				styleSheet.containerPostShadow,
				styleSheet.socialContainer
			)}
		>
			<div className={css(bs.flex, bs.flexCol, bs.overflowHidden, bs.flex1, bs.gap2)}>
				<div className={css(bs.flex, bs.itemsCenter, bs.gap2, bs.boxBorder, bs.wFull, bs.truncateText)}>
					<div className={css(bs.textSm, bs.textBrandPrimary, bs.truncateText)}>{card.name || 'Custom Post'}</div>
					{expirationDate ? <CampaignExpiryHint expirationDate={expirationDate} /> : null}
				</div>
				<div className={css(bs.h12)}>
					<p className={css(bs.bgTransparent, bs.textLabel, bs.textXs, bs.p0, bs.m0, bs.overflowHidden)}>
						<span>{text}</span>
						{truncated ? <span>{' ...'}</span> : null}
					</p>
				</div>
				{onCtaClicked && (
					<button className={css(bs.ctaButtonReverseSmall, bs.textXs, styleSheet.button)} onClick={onCtaClicked}>
						<span>{ctaText || 'View Post Now'}</span>
					</button>
				)}
			</div>
		</div>
	);
};

export const SocialCampaignCalendarTemplateCard = inject(ImpersonationContextKey)(
	SocialCampaignCalendarTemplateCardBase
);

function BlogCampaignCalendarTemplateCardSummary({
	content,
	maxCharCount,
}: {
	content: IRawRichTextContentState;
	maxCharCount: number;
}) {
	const { text, truncated } = getPlainTextPreviewForRawRichTextContent(content, {
		maxCharCount,
	});

	return (
		<div className={css(styleSheet.textContainer)}>
			<p className={css(styleSheet.body)}>
				<span>{text}</span>
				{truncated ? <span>{' ...'}</span> : null}
			</p>
		</div>
	);
}

const BlogCampaignCalendarTemplateCardBase: React.FC<SocialCampaignCalendarTemplateCardProps> = props => {
	const { card, className, ctaText, onCtaClicked } = props;
	const tCard = card as ITemplateCard;
	const hasAssociatedTemplateOrCampaign = tCard.associatedTemplates?.length > 0;

	return (
		<div
			className={`${css(
				bs.gap2,
				bs.flex,
				bs.rounded,
				bs.relative,
				bs.bgWhite,
				bs.borderSolid,
				bs.border,
				bs.borderAntiFlashWhite,
				styleSheet.containerPost,
				styleSheet.containerPostShadow
			)}
			blog-campaign-template-card
			${className || ''}`}
		>
			<div className={css(bs.flex, bs.flexCol, bs.overflowHidden, bs.flex1, bs.gap2, styleSheet.columnCard)}>
				<div className={css(styleSheet.titleContainer)}>
					<div
						className={`campaign-template-card-name ${css(
							!hasAssociatedTemplateOrCampaign && styleSheet.name,
							styleSheet.postName,
							bs.truncateText
						)}`}
					>
						<div className={css(styleSheet.card)}>{card.name || 'Custom Post'}</div>
					</div>
				</div>
				<div className={css(styleSheet.textContainerSmall)}>
					<BlogCampaignCalendarTemplateCardSummary content={card?.content} maxCharCount={60} />
				</div>
				{onCtaClicked && (
					<button className={css(baseStyleSheet.ctaButtonReverseSmall, styleSheet.button)} onClick={onCtaClicked}>
						<span>{ctaText || 'View Blog Post'}</span>
					</button>
				)}
			</div>
		</div>
	);
};

export const BlogCampaignCalendarTemplateCard = inject(ImpersonationContextKey)(BlogCampaignCalendarTemplateCardBase);
