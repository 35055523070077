import { css } from 'aphrodite';
import { Observer, inject, observer } from 'mobx-react';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { BillingType } from '../../../extViewmodels';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../models/Logging';
import { getDisplayName, getPrincipalInitials } from '../../../models/UiUtils';
import { baseStyleSheet } from '../../styles/styles';
import { Avatar2 } from '../Avatar2';
import { INavBarLink, NavBarLink } from '../NavBarLink';
import { TinyPopover } from '../TinyPopover';
import { InviteEmployeesModal } from '../settings/InviteEmployees';
import { ReferAFriendModal } from '../settings/ReferAFriend/ReferAFriend';
import { DisclosureIcon } from '../svgs/icons/DisclosureIcon';
import { NavIconType } from '../svgs/icons/NavIcon';
import { styleSheet } from './styles';

interface IProps extends RouteComponentProps<any>, IUserSessionComponentProps, IEventLoggingComponentProps {
	className?: string;
	isAdminPage?: boolean;
	coffeeLinks?: INavBarLink[];
}

interface IState {
	showDropdown?: boolean;
	showInviteEmployees?: boolean;
	showReferAFriend?: boolean;
}

const AdminLinks: INavBarLink[] = [
	{
		linkName: 'Invite Teammates',
		path: '/settings/manage-users',
	},
];

const _UserProfileNav = (props: IProps) => {
	const [state, setState] = React.useState<IState>({
		showDropdown: false,
		showInviteEmployees: false,
		showReferAFriend: false,
	});
	const { showDropdown, showInviteEmployees, showReferAFriend } = state;
	const { userSession, className, isAdminPage, coffeeLinks } = props;
	const isAdmin = !!userSession.user && userSession.user.groups.findIndex(x => x === 'admin') >= 0;
	let menuOptions = isAdmin && !isAdminPage ? [...AdminLinks, ...getBottomLinks()] : getBottomLinks();
	if (coffeeLinks != null) {
		menuOptions = coffeeLinks;
	}

	const renderDropdownAnchor = () => {
		const companyName = userSession.account?.companyName;
		const userName = getDisplayName(userSession.user);
		return (
			<Observer>
				{() => (
					<div className={css(styleSheet.dropdownAnchor)} onClick={onToggleOpen(!showDropdown)}>
						{userSession?.user && (
							<Avatar2
								imgSrc={userSession.user.profilePic}
								fallbackText={getPrincipalInitials(userSession.user)}
								styleDeclaration={styleSheet.dropdownAnchorAvatar}
							/>
						)}
						<div className={css(styleSheet.dropdownAnchorUserInfo)}>
							<div
								className={css(baseStyleSheet.truncateText, styleSheet.dropdownAnchorUserInfoName)}
								title={userName ?? undefined}
							>
								{userName}
							</div>
							<div
								className={css(baseStyleSheet.truncateText, styleSheet.dropdownAnchorUserInfoCompanyName)}
								title={companyName ?? undefined}
							>
								{companyName ?? null}
							</div>
						</div>
						<DisclosureIcon
							type='chevron'
							className={css(styleSheet.chevron, showDropdown ? styleSheet.chevronUp : undefined)}
						/>
					</div>
				)}
			</Observer>
		);
	};

	const onToggleOpen = (open: boolean) => () => {
		setState(prevState => ({
			...prevState,
			showDropdown: open,
		}));
	};

	const linkClick = (linkProps: INavBarLink) => (e: React.MouseEvent<HTMLElement>) => {
		const nextState: Partial<IState> = {
			showDropdown: false,
		};

		// look to see if the invite option was clicked
		if (linkProps.linkName === AdminLinks[0].linkName) {
			// prevent normal navigation
			e.stopPropagation();
			e.preventDefault();
			nextState.showInviteEmployees = true;
		}

		if (linkProps.linkName === 'Refer a Friend') {
			// prevent normal navigation
			e.stopPropagation();
			e.preventDefault();
			nextState.showReferAFriend = true;
		}

		setState(prevState => ({
			...prevState,
			...nextState,
		}));
	};

	const onInviteEmployeesClose = () => {
		setState(prevState => ({
			...prevState,
			showInviteEmployees: false,
		}));
	};
	const onReferAFriendClose = () => {
		setState(prevState => ({
			...prevState,
			showReferAFriend: false,
		}));
	};

	function getBottomLinks() {
		let bottomLinks: INavBarLink[] = [
			{
				linkName: 'Logout',
				path: '/logout',
			},
		];

		if (
			userSession.account.planDetails.planId > 0 &&
			!isAdminPage &&
			userSession.account.planDetails.billingType !== BillingType.Trial
		) {
			bottomLinks = [{ linkName: 'Refer a Friend', path: '/settings/referafriend' }, ...bottomLinks];
		}

		if (!isAdminPage) {
			bottomLinks = [
				{
					linkName: 'Settings',
					path: '/settings',
				},
				{
					linkName: 'Integrations',
					path: '/integrations',
				},
				...bottomLinks,
			];
		}

		if (
			userSession.user.emailProviderFeatures.calendar.canAddUpdateEvents &&
			userSession.account.features.meetingScheduler.enabled &&
			!isAdminPage
		) {
			bottomLinks = [{ linkName: 'Schedule a Meeting', path: '/schedule-meeting' }, ...bottomLinks];
		}

		if (userSession.account.isAdmin && userSession.account.features?.surveys?.enabled && !isAdminPage) {
			bottomLinks.splice(bottomLinks.findIndex(x => x.linkName === NavIconType.ScheduleMeeting) + 1, 0, {
				linkName: NavIconType.Surveys,
				path: '/surveys',
			});
		}

		return bottomLinks;
	}
	return (
		<div className={`user-profile-nav ${css(styleSheet.container)} ${className || ''}`}>
			<TinyPopover
				anchor={renderDropdownAnchor()}
				onRequestClose={onToggleOpen(false)}
				placement={['bottom']}
				dismissOnOutsideAction={true}
				isOpen={!!showDropdown}
			>
				<div className={css(styleSheet.dropdownContainer)}>
					{showDropdown &&
						menuOptions.map((linkProps, i) => {
							return (
								<NavBarLink
									{...linkProps}
									className={`${css(
										styleSheet.dropdownMenuItem,
										linkProps.path === AdminLinks[0].path ? styleSheet.dropdownMenuItemInvite : null
									)}`}
									key={i}
									onLinkClick={linkClick(linkProps)}
								/>
							);
						})}
				</div>
			</TinyPopover>
			{isAdmin && (showDropdown || showInviteEmployees) && (
				<InviteEmployeesModal
					account={userSession.account.toJs()}
					modalProps={{
						isOpen: showInviteEmployees,
						onRequestClose: onInviteEmployeesClose,
					}}
				/>
			)}
			{isAdmin && (showDropdown || showReferAFriend) && (
				<ReferAFriendModal
					modalProps={{
						isOpen: showReferAFriend,
						onRequestClose: onReferAFriendClose,
					}}
				/>
			)}
		</div>
	);
};

const UserProfileNavAsObserver = observer(_UserProfileNav);
const UserProfileNavWithContext = inject(UserSessionViewModelKey)(UserProfileNavAsObserver);
const UserProfileNavWithRouter = withRouter(UserProfileNavWithContext);
export const UserProfileNav = withEventLogging(UserProfileNavWithRouter, 'UserProfileNav');
