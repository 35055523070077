import { brandSecondary } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
	type?: 'default' | 'bold';
}

export const CheckmarkIcon: React.FC<IProps> = props => {
	const { className, fillColor, type } = props;
	return (
		<SvgIcon
			className={`checkmark-icon ${className || ''}`}
			height={type === 'bold' ? 12 : 8}
			width={type === 'bold' ? 12 : 10}
		>
			{type === 'bold' ? (
				<polygon
					fill={fillColor || brandSecondary}
					fillRule='evenodd'
					points='5.017 11.974 .116 7.074 2.259 4.931 4.63 7.303 9.65 .274 12.116 2.034'
				/>
			) : (
				<path
					d='M0.536339879,5.53633988 C0.240127543,5.24012754 0.231933594,4.76806641 0.523429871,4.47657013 L0.476570129,4.52342987 C0.765652465,4.23434754 1.23728013,4.23728013 1.52547646,4.52547646 L3,6 L8.46754003,0.532459974 C8.76160955,0.238390451 9.23193359,0.231933594 9.52342987,0.523429871 L9.47657013,0.476570129 C9.76565246,0.765652465 9.76709747,1.23290253 9.47386229,1.52613771 L3,8 L0.536339879,5.53633988 Z'
					fill={fillColor || brandSecondary}
					fillRule='evenodd'
				/>
			)}
		</SvgIcon>
	);
};
