import { grayIconFill } from '../../../styles/colors';
import { TinyPopover } from '../../TinyPopover';
import { PrivateVisibilityIcon } from '../../svgs/icons/PrivateVisibilityIcon';
import { styleSheet } from './styles';
import { IKeyFact } from '@ViewModels';
import { StyleDeclaration, css } from 'aphrodite';
import { useState } from 'react';
import * as React from 'react';

interface IProps {
	keyFact: IKeyFact;
	popoverStyles?: StyleDeclaration[];
}

export const UneditableKeyFactIcon: React.FC<IProps> = ({ keyFact, popoverStyles = [] }) => {
	const [isOpen, setIsOpen] = useState(false);

	const onEngagement = (open: boolean) => () => setIsOpen(open);

	const popupMessage = `This information is from ${keyFact?.source?.source ?? 'a data board'} and cannot be edited or deleted.`;

	return (
		<TinyPopover
			align='center'
			anchor={
				<button
					className={`${css(styleSheet.button)} data-origin-icon`}
					onMouseOut={onEngagement(false)}
					onMouseOver={onEngagement(true)}
				>
					<PrivateVisibilityIcon fillColor={grayIconFill} />
				</button>
			}
			dismissOnOutsideAction={true}
			autoCloseOtherPopoversOnHover={true}
			isOpen={isOpen}
			onRequestClose={onEngagement(false)}
			placement={['right', 'left']}
			styles={[styleSheet.popover, ...popoverStyles]}
		>
			<div className={css(styleSheet.msg)}>{popupMessage}</div>
		</TinyPopover>
	);
};
