import * as React from 'react';
import { SvgIcon } from '../SvgIcon';

export const AttachIcon = ({
	width = 12,
	height = 18,
	fillColor,
	...props
}: React.SVGProps<SVGSVGElement> & { fillColor?: string }) => {
	return (
		<SvgIcon height={height} width={width} {...props}>
			<path
				d='M4.28571429,18 C1.80257143,18 0,16.0174286 0,13.2857143 L0,6 C0,2.57914286 2.39485714,0 5.57142857,0 C8.748,0 11.1428571,2.57914286 11.1428571,6 L11.1428571,12 L9.42857143,12 L9.42857143,6 C9.42857143,3.51685714 7.806,1.71428571 5.57142857,1.71428571 C3.33685714,1.71428571 1.71428571,3.51685714 1.71428571,6 L1.71428571,13.2857143 C1.71428571,14.7797143 2.50971429,16.2857143 4.28571429,16.2857143 C6.06171429,16.2857143 6.85714286,14.7797143 6.85714286,13.2857143 L6.85714286,6.85714286 C6.85714286,6.34114286 6.732,5.14285714 5.57142857,5.14285714 C4.41085714,5.14285714 4.28571429,6.34114286 4.28571429,6.85714286 L4.28571429,12.8571429 L2.57142857,12.8571429 L2.57142857,6.85714286 C2.57142857,4.806 3.77742857,3.42857143 5.57142857,3.42857143 C7.36542857,3.42857143 8.57142857,4.806 8.57142857,6.85714286 L8.57142857,13.2857143 C8.57142857,16.0174286 6.76885714,18 4.28571429,18'
				fill={fillColor}
				fillRule='evenodd'
			/>
		</SvgIcon>
	);
};
