import { UserSessionContext } from '../../../viewmodels/AppViewModels';
import * as Api from '@ViewModels';
import * as React from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';

interface IProps extends RouteComponentProps<any> {
	isAida?: boolean;
	userSession?: UserSessionContext;
	textMessaging?: Api.TextMessagingViewModel;
}

class _Logout extends React.PureComponent<IProps> {
	public componentDidMount() {
		// Clear the local storage and session info
		this.props.textMessaging?.fullReset();
		// @ts-ignore
		this.props.userSession.logout();
	}

	public render() {
		return (
			<Redirect
				push={true}
				to={{
					pathname: '/login',
					state: { isAida: this.props.isAida },
				}}
			/>
		);
	}
}

export const Logout = withRouter(_Logout);
