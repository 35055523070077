import { IUser, ResourceAutoCompleteViewModelType } from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useCallback } from 'react';
import { Browser } from '../../../../models/Browser';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { useLambda } from '../../../../models/hooks/useLambda';
import { baseStyleSheet } from '../../../styles/styles';
import { Checkbox } from '../../Checkbox';
import { ParentPopover } from '../../Popover';
import { PrivacyToggle } from '../../PrivacyToggle';
import { TextInput } from '../../TextInput';
import { ResourceVisibility, VisibilityDropdown } from '../../VisibilityDropdown';
import {
	ISimpleAutoCompleteSearchFieldItemSelectionEvent,
	SimpleAutoCompleteSearchField,
} from '../../autocomplete/SimpleAutoCompleteSearchField';
import { DangerMessage } from '../../errorMessages/DangerMessage';
import { styleSheet } from './styles';

interface IProps {
	alwaysTrack?: boolean;
	bccToCrm?: boolean;
	ccEmployee?: IUser;
	className?: string;
	createFollowUpReminder?: boolean;
	followUpClassName?: string;
	followUpReminderDays?: number;
	followUpReminderLabelClassName?: string;
	hideCcEmployee?: boolean;
	hideFollowUpReminderOptions?: boolean;
	lockedDueToSendOnBehalf?: boolean;
	noteVisibility?: string;
	onAlwaysTrackChange?(checked: boolean): void;
	onBccToCrmCheckedChange?(checked: boolean): void;
	onCcEmployeeSelected?(user: IUser): void;
	onCreateFollowUpReminderCheckedChanged?(checked: boolean): void;
	onFollowUpReminderDaysChanged?(days: number): void;
	onNotePrivacyToggleChange?(isPublic: boolean): void;
	onSaveAsNoteCheckedChange?(checked: boolean): void;
	onVisibilityChanged?(visibility: ResourceVisibility): void;
	saveAsNote?: boolean;
	saveAsNoteClassName?: string;
	showBccToCrmOption?: boolean;
	showFollowUpWarning?: boolean;
	toggleClassName?: string;
	useVisibilityDropdown?: boolean;
}

export const EmailComposerOptions: React.FC<IProps> = props => {
	const { alwaysTrack, onAlwaysTrackChange, showFollowUpWarning, ccEmployee, hideCcEmployee } = props;
	const userSession = useUserSession();
	const [ccEmployeeCheckboxChecked, setCcEmployeeCheckboxChecked] = React.useState(!!ccEmployee);
	const [showingAutocompleteForCcEmployee, , onCcEmployeeAutocompleteFocus] = useLambda(false);

	const warningMessageStyles = React.useRef([styleSheet.warningMessage]).current;

	const onAlwaysTrackChangeCallback = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		onAlwaysTrackChange?.(e.target.checked);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSaveAsNoteCheckedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (props.onSaveAsNoteCheckedChange) {
			props.onSaveAsNoteCheckedChange(e.target.checked);
		}
	};

	const onBccToCrmCheckedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (props.onBccToCrmCheckedChange) {
			props.onBccToCrmCheckedChange(e.target.checked);
		}
	};

	const onCreateFollowUpReminderCheckedChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (props.onCreateFollowUpReminderCheckedChanged) {
			props.onCreateFollowUpReminderCheckedChanged(e.target.checked);
		}
	};

	const onFollowUpReminderDaysChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (props.onFollowUpReminderDaysChanged) {
			let value = parseInt(e.target.value, 10);
			if (!value || isNaN(value)) {
				value = 0;
			}
			props.onFollowUpReminderDaysChanged(value);
		}
	};

	const onFollowUpInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
		const inputElement = e.target as HTMLInputElement;
		if (Browser.isMSEdge()) {
			setTimeout(() => {
				inputElement.select();
			}, 0);
		} else {
			inputElement.select();
		}
	};

	const onCcEmployeeCheckboxChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCcEmployeeCheckboxChecked(e.target.checked);
		// @ts-ignore
		props.onCcEmployeeSelected?.(null);
	};

	const onCcEmployeeSelected = (e: ISimpleAutoCompleteSearchFieldItemSelectionEvent<IUser>) => {
		props.onCcEmployeeSelected?.(e.selection);
	};

	const onCcEmployeeCleared = () => {
		// @ts-ignore
		props.onCcEmployeeSelected?.(null);
	};

	return (
		<div className={props.className || undefined}>
			<ParentPopover preventClose={showingAutocompleteForCcEmployee} />
			{props.lockedDueToSendOnBehalf && (
				<div className={css(styleSheet.sendOnBehalfDisabledWarning)}>
					Some options cannot be changed when sending on behalf of someone else.
				</div>
			)}
			<div className={`${props.saveAsNoteClassName || ''} ${css(styleSheet.option)}`}>
				<Checkbox
					checked={!!props.saveAsNote}
					disabled={props.lockedDueToSendOnBehalf}
					id='email-composer-options-save-as-note-checkbox'
					onChange={onSaveAsNoteCheckedChange}
				>
					<span>Save a copy of this email as a note</span>
				</Checkbox>
				{!!props.saveAsNote && !props.useVisibilityDropdown && (
					<PrivacyToggle
						className={props.toggleClassName || ''}
						disabled={props.lockedDueToSendOnBehalf}
						id='composeEmailMessagePrivacyToggle'
						isOn={props.noteVisibility === 'all' ? true : false}
						onToggleCheckChanged={props.onNotePrivacyToggleChange}
						privateText='Private note'
						publicText='Shared note'
					/>
				)}
				{!!props.saveAsNote && !!props.useVisibilityDropdown && (
					<VisibilityDropdown
						className={props.toggleClassName || ''}
						onVisibilityChanged={props.onVisibilityChanged}
						visibility={props.noteVisibility || 'admin'}
					/>
				)}
			</div>
			{!!props.showBccToCrmOption && (
				<div className={css(styleSheet.option)}>
					<Checkbox
						checked={!!props.bccToCrm}
						id='email-composer-options-bcc-to-crm-checkbox'
						onChange={onBccToCrmCheckedChange}
					>
						<span>BCC this email to my CRM</span>
					</Checkbox>
				</div>
			)}
			{!props.hideFollowUpReminderOptions && (
				<div className={css(styleSheet.option)} style={showFollowUpWarning ? { display: 'block' } : undefined}>
					<div className={props.followUpClassName}>
						<Checkbox
							checked={!!props.createFollowUpReminder}
							id='email-composer-options-follow-up-reminder-checkbox'
							onChange={onCreateFollowUpReminderCheckedChanged}
						>
							<div className={`${css(styleSheet.followUpReminderLabel)} ${props.followUpReminderLabelClassName}`}>
								<span>{`Remind ${props.lockedDueToSendOnBehalf ? 'senders' : 'me'} to follow up in`}</span>
								<TextInput
									className={css(baseStyleSheet.removeChildInputSpinButton, styleSheet.textInput)}
									inputClassName={css(styleSheet.daysInput)}
									inputId='create-follow-up-reminder-days-input'
									min={1}
									onChange={onFollowUpReminderDaysChanged}
									onFocus={onFollowUpInputFocus}
									step={1}
									type='number'
									value={props.followUpReminderDays}
								/>
								{/* @ts-ignore */}
								<span>{`day${props.followUpReminderDays > 1 ? 's' : ''} if there's no reply`}</span>
							</div>
						</Checkbox>
					</div>
					{!!showFollowUpWarning && !!props.createFollowUpReminder && (
						<DangerMessage animate={false} styles={warningMessageStyles} type='error'>
							<span className={css(styleSheet.warningMessageContent)}>
								An action item will be created for &nbsp;
								<span style={{ fontStyle: 'italic' }}>each recipient</span>.
							</span>
						</DangerMessage>
					)}
				</div>
			)}
			{/* @ts-ignore */}
			{!hideCcEmployee && !!userSession.account.preferences.enableCcInCampaigns && (
				<div className={css(styleSheet.option, styleSheet.ccEmployeeOptions)}>
					<div className={css(styleSheet.ccEmployeeOptionsInputs)}>
						<Checkbox
							checked={ccEmployeeCheckboxChecked}
							id='email-composer-options-cc-emplyee-checkbox'
							onChange={onCcEmployeeCheckboxChanged}
						>
							<span>CC an employee</span>
						</Checkbox>
						<SimpleAutoCompleteSearchField
							disabled={!ccEmployeeCheckboxChecked}
							initialSearchQuery={ccEmployee ? ccEmployee.email : undefined}
							onBlur={onCcEmployeeAutocompleteFocus(false)}
							onClear={onCcEmployeeCleared}
							onFocus={onCcEmployeeAutocompleteFocus(true)}
							// @ts-ignore
							onItemSelected={onCcEmployeeSelected}
							placeholder='Search employee'
							style={styleSheet.ccEmployeeSearchField}
							type={ResourceAutoCompleteViewModelType.User}
						/>
					</div>
					{!!ccEmployeeCheckboxChecked && (
						<DangerMessage animate={false} styles={warningMessageStyles} type='error'>
							<span className={css(styleSheet.warningMessageContent)}>
								Employee will get a copy of the email for &nbsp;
								<span style={{ fontStyle: 'italic' }}>each recipient</span>.
							</span>
						</DangerMessage>
					)}
				</div>
			)}
			{onAlwaysTrackChange && (
				<div className={`${props.saveAsNoteClassName || ''} ${css(styleSheet.option)}`}>
					<Checkbox
						checked={alwaysTrack}
						id='email-composer-options-always-track'
						onChange={onAlwaysTrackChangeCallback}
					>
						<span>Always track sent emails with Levitate</span>
					</Checkbox>
				</div>
			)}
		</div>
	);
};
