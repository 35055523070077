import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { PopoverPlacement } from 'react-popover';
import { v4 as uuidgen } from 'uuid';
import { NextMeetingPopover } from '../../../../admin/components/NextMeetingPopover';
import { DefaultEditableNoteContentCSS, SizeConstraint } from '../../../../models';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IToasterComponentProps,
	ToasterViewModelKey,
} from '../../../../models/AppState';
import { EventLogger } from '../../../../models/Logging';
import {
	ToolbarDefault,
	ToolbarNotes,
	convertRawRichTextContentStateToRichContentEditorState,
	getNormalizedNoteVisibility,
} from '../../../../models/UiUtils';
import {
	ActionItemAttachmentViewModel,
	ActionItemViewModel,
	AttachmentsToBeUploadedViewModel,
	ContactViewModel,
	EntityViewModel,
	ErrorMessageViewModel,
	IAccount,
	IRichContentEditorState,
	NoteViewModel,
	UserSessionContext,
} from '../../../../viewmodels/AppViewModels';
import { baseStyleSheet } from '../../../styles/styles';
import { Attachments } from '../../Attachments';
import { Portal } from '../../Portal';
import { PrivacyToggle } from '../../PrivacyToggle';
import { EmbeddedActionItem } from '../../actionItems/EmbeddedActionItem';
import {
	IRichContentDocumentEditorConfig,
	RichContentDocumentEditor,
} from '../../richContent/RichContentDocumentEditor';
import { FutureMeetingIcon } from '../../svgs/icons/FutureMeetingIcon';
import { CCField } from '../CCField';
import { EmailMessageHeader } from '../EmailMessageHeader';
import { MeetingAttendeesHeader } from '../MeetingAttendeesHeader';
import { MentionField } from '../MentionField';
import NoteEditorAddActionItemIconUrl from './noteEditorAddActionItemIcon.svg';
import { styleSheet } from './styles';

interface IProps extends IToasterComponentProps, IErrorMessageComponentProps {
	account?: IAccount;
	actionItemsContainerClassName?: string;
	alwaysShowsSaveOptions?: boolean;
	autoFocus?: boolean;
	bodyClassName?: string;
	canChangeActionItemCompletedStateForReadOnly?: boolean;
	ccUsers?: Api.IUser[];
	className?: string;
	content?: Api.IRichContentEditorState;
	editorConfig?: IRichContentDocumentEditorConfig;
	footerStyles?: StyleDeclarationValue[];
	getPopularMentionSuggestions?(trigger: string): EntityViewModel<Api.IEntity>[];
	headersPortalId?: string;
	hideActionItems?: boolean;
	hideMeetingAttendeesHeader?: boolean;
	hideMentionField?: boolean;
	hidesCCField?: boolean;
	hidesPrivacyToggle?: boolean;
	isCollapsible?: boolean;
	isPublicNote?: boolean;
	mentionedEntities?: EntityViewModel<Api.IEntity>[];
	mentionFieldClassName?: string;
	note?: NoteViewModel;
	onActionItemSendMessageClicked?(actionItem: ActionItemViewModel, e: React.MouseEvent<HTMLElement>): void;
	onCancel?(): void;
	onEditorFocusChanged?(hasFocus: boolean): void;
	onEntityClicked?(entity: EntityViewModel<Api.IEntity>, e: React.MouseEvent<HTMLElement>): void;
	onNextMeetingSelected?(nextMeeting: Date): void;
	onNoteSaved?(note: Api.INote): void;
	onPrivacyToggleChanged?(isPublicNote: boolean): void;
	onValidateBeforeSaving?(note: Api.INote, errorMessages?: ErrorMessageViewModel): boolean;
	placeholderText?: string;
	popoverClassName?: string;
	preferredMentionFieldSuggestionsPopoverPlacement?: PopoverPlacement;
	readOnly?: boolean;
	sendMessageDisabled?: boolean;
	showNextMeeting?: boolean;
	sizeConstraint?: SizeConstraint;
	styles?: StyleDeclarationValue[];
	textEditorClassName?: string;
	toastOnSuccess?: boolean;
	userSession?: UserSessionContext;
}

interface IState {
	actionItemAttachments?: ActionItemAttachmentViewModel[];
	ccUsers?: Api.IUser[];
	editorState?: IRichContentEditorState;
	hidesCCField?: boolean;
	isEditorFocused?: boolean;
	isOpen?: boolean;
	isPublicNote?: boolean;
	mentionedEntities?: EntityViewModel<Api.IEntity>[];
	newAttachments?: AttachmentsToBeUploadedViewModel<File>;
	noteVm?: NoteViewModel;
	placeholderText?: string;
	readOnly?: boolean;
	showNextMeetingPopover?: boolean;
	nextMeeting?: Date;
	showSaveOptions?: boolean;
}

const DefaultEditorConfig: IRichContentDocumentEditorConfig = {
	autoresizeToFitContent: true,
	contentHorizontalPadding: 20,
	contentRawCss: DefaultEditableNoteContentCSS,
	minHeight: 100,
	toolbar: `${ToolbarDefault} file-attachments`,
};

export const DefaultCompactEditorConfig: IRichContentDocumentEditorConfig = {
	...DefaultEditorConfig,
	contentHorizontalPadding: 10,
	maxHeight: 420,
	toolbar: ToolbarNotes,
};

class _NoteEditor extends React.Component<IProps, IState> {
	// @ts-ignore
	private filesInputRef: HTMLInputElement;
	private uuid = uuidgen();
	// @ts-ignore
	private bottomScrollAnchorRef: HTMLSpanElement;

	public static defaultProps: IProps = {
		sizeConstraint: 'normal',
		// @ts-ignore
		userSession: null,
	};

	public state: IState = {
		isOpen: false,
		isPublicNote: true,
	};

	public UNSAFE_componentWillMount() {
		const nextState: IState = {
			...this.getNextStateWithProps(this.props),
			placeholderText: this.props.placeholderText,
			readOnly: this.props.readOnly,
			showSaveOptions: this.props.autoFocus,
		};
		this.setState(nextState);
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		const nextState = this.getNextStateWithProps(nextProps);
		if (Object.keys(nextState).length > 0) {
			this.setState(nextState);
		}
	}

	public render() {
		const {
			alwaysShowsSaveOptions,
			autoFocus,
			bodyClassName,
			canChangeActionItemCompletedStateForReadOnly,
			content,
			className,
			editorConfig,
			headersPortalId,
			hideActionItems,
			isCollapsible,
			note,
			placeholderText,
			sendMessageDisabled,
			sizeConstraint,
			styles,
			textEditorClassName,
		} = this.props;
		const { actionItemAttachments, editorState, isOpen, newAttachments, noteVm, readOnly, showSaveOptions } =
			this.state;

		const attachments = this.readOnlyActionItemAttachements || actionItemAttachments;
		const mentionedEntities = this.readOnlyMentionedEntities || this.state.mentionedEntities;
		const bodyStyles: StyleDeclarationValue[] = [
			styleSheet.noteEditorBody,
			// @ts-ignore
			!readOnly ? styleSheet.editableNoteEditorBody : null,
		];
		const isEmail = note?.context?.source === Api.RichContentContextSource.EmailMessageSend;
		const preview = isCollapsible ? (
			<div className={css(styleSheet.previewContainer)}>
				{/* @ts-ignore */}
				{/* @ts-ignore */}
				<p>{isEmail ? note.preview.split(/\u00A0/g)[0] : note.preview}</p>
				<button className={css(styleSheet.previewCta)} onClick={this.togglePreviewOpenState}>
					Show More
				</button>
			</div>
		) : null;

		return (
			<div
				className={`note-editor ${className || ''} ${css(styleSheet.noteEditor, ...(styles || []))}`}
				data-mention-field-empty={!mentionedEntities || mentionedEntities.length === 0}
				data-showing-save-options={showSaveOptions}
			>
				{headersPortalId ? <Portal destination={headersPortalId}>{this.renderHeaders()}</Portal> : this.renderHeaders()}
				<div className={`note-editor-body ${css(bodyStyles)} ${bodyClassName || ''}`}>
					<div className={css(styleSheet.richContentContainer)}>
						{!isOpen && preview}
						{((preview && isOpen) || !preview) && (
							<RichContentDocumentEditor
								autoFocus={autoFocus}
								className={`note-editor-body-editor ${css(styleSheet.editor)} ${textEditorClassName || ''}`}
								config={
									editorConfig
										? editorConfig
										: sizeConstraint === 'compact'
											? DefaultCompactEditorConfig
											: DefaultEditorConfig
								}
								contentPlaceholderText={placeholderText}
								// @ts-ignore
								contentState={content ?? !!readOnly ? this.readOnlyEditorState : editorState}
								onBlur={this.onToggleEditorFocus(false)}
								onContentStateChanged={this.onContentStateChanged}
								onExecuteCommand={this.onEditorExecuteCommand}
								onFocus={this.onToggleEditorFocus(true)}
								readOnly={!!this.isReadOnly}
							/>
						)}
						{!hideActionItems && !!attachments && attachments.length > 0 && (
							<div
								className={`note-editor-body-action-items ${css(
									styleSheet.actionItems,
									readOnly ? styleSheet.readOnlyNoteActionItems : null
								)}`}
								data-has-action-items={attachments.length > 0}
							>
								{attachments.map((x, i) => {
									return (
										<EmbeddedActionItem
											actionItemAttachment={x}
											canToggleCompleted={canChangeActionItemCompletedStateForReadOnly}
											className={css(styleSheet.actionItem)}
											key={x.actionItem.uuid}
											loggingCategory='NoteEditorActionItem'
											onRequestRemove={this.onRequestRemoveActionItem(i)}
											onSendMessageClicked={this.onSendMessageClicked(x.actionItem)}
											readonly={this.isReadOnly}
											sendMessageDisabled={sendMessageDisabled}
											sizeConstraint={sizeConstraint}
										/>
									);
								})}
							</div>
						)}
						{preview && isOpen && (
							<div className={css(styleSheet.previewShowLessContainer)}>
								<button className={css(styleSheet.previewCta)} onClick={this.togglePreviewOpenState}>
									Show Less
								</button>
							</div>
						)}
						<span ref={this.onNoteEditorBodyBottomScrollAnchorRef} style={{ fontSize: 0, height: 0 }} />
					</div>
					<div>
						<input
							id='note-editor-files-input'
							multiple={true}
							onChange={this.onFilesInputChanged}
							// @ts-ignore
							ref={this.onFilesInputRef}
							style={{
								height: 0,
								opacity: 0,
								pointerEvents: 'none',
								position: 'fixed',
								visibility: 'hidden',
								width: 0,
								zIndex: -1,
							}}
							type='file'
						/>
						{!!noteVm && !!noteVm.attachments && (
							<Attachments
								attachments={noteVm.attachments}
								className={`${css(styleSheet.attachments)} note-editor-attachments`}
								newAttachments={newAttachments}
							/>
						)}
					</div>
				</div>
				{(showSaveOptions || alwaysShowsSaveOptions) && this.renderFooter()}
			</div>
		);
	}

	private togglePreviewOpenState = () => {
		this.setState({ isOpen: !this.state.isOpen });
	};

	private onEditorExecuteCommand = (
		e: React.ChangeEvent<any> & {
			command: string;
			type: string;
			ui?: any;
			value?: any;
		}
	) => {
		if (e.command === 'levAddFileAttachement' && !e.defaultPrevented) {
			if (this.filesInputRef) {
				this.filesInputRef.click();
			}
		}
	};

	private onFilesInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		const { note, userSession } = this.props;
		let { newAttachments } = this.state;

		if (!!files && files.length > 0 && !!note) {
			const fileAttachments = Array.from(files);

			EventLogger.logEvent(
				{
					action: 'FilesAttached',
					category: 'NoteEditor',
				},
				{ count: fileAttachments.length }
			);

			if (!newAttachments) {
				newAttachments = new AttachmentsToBeUploadedViewModel(
					// @ts-ignore
					null,
					// @ts-ignore
					Api.VmUtils.getMaxAttachmentByteCountForEmailConfigurations(userSession?.account?.emailProviderConfiguration)
				);
				this.setState({ newAttachments });
			}

			newAttachments.add(fileAttachments);
		}
	};

	public onFilesInputRef = (ref?: HTMLInputElement) => {
		// @ts-ignore
		this.filesInputRef = ref;
	};

	private renderHeaders() {
		const { hideMeetingAttendeesHeader, userSession } = this.props;
		const { noteVm, showSaveOptions, ccUsers, hidesCCField, isPublicNote } = this.state;
		return (
			<>
				{!hideMeetingAttendeesHeader && (
					// @ts-ignore
					<MeetingAttendeesHeader note={noteVm} className={css(styleSheet.attendeesHeader)} />
				)}
				{!hidesCCField && !!isPublicNote && showSaveOptions && (
					<CCField
						ccUsers={ccUsers}
						className={css(styleSheet.ccField)}
						onCCUsersChanged={this.onCCUsersChanged}
						// @ts-ignore
						userSession={userSession}
					/>
				)}
				{this.onRenderNonBodyReferencedEnities()}
			</>
		);
	}

	@computed
	private get readOnlyEditorState() {
		const { noteVm } = this.state;
		return noteVm
			? convertRawRichTextContentStateToRichContentEditorState(noteVm.rawContentState, noteVm.preview)
			: null;
	}

	private get isReadOnly() {
		const { alwaysShowsSaveOptions } = this.props;
		const { showSaveOptions, readOnly } = this.state;
		return !!readOnly || (!alwaysShowsSaveOptions && !showSaveOptions);
	}

	@computed
	private get readOnlyMentionedEntities() {
		if (this.isReadOnly) {
			const { noteVm } = this.state;
			// @ts-ignore
			return (noteVm.mentionedEntities || [])
				.filter(x => x.method === Api.RichContentReferenceMethod.Explicit)
				.map(x => x.entity);
		}
		return null;
	}

	@computed
	private get readOnlyActionItemAttachements() {
		if (this.isReadOnly) {
			const { noteVm } = this.state;
			// @ts-ignore
			return (noteVm.actionItems || []).map(x => {
				const att = new ActionItemAttachmentViewModel(x);
				att.contentState = convertRawRichTextContentStateToRichContentEditorState(x.rawContentState);
				return att;
			});
		}
		return null;
	}

	@computed
	private get noteContext() {
		const { noteVm } = this.state;
		return !!noteVm && !!noteVm.context ? noteVm.context : null;
	}

	@computed
	private get isFromPhoneCall() {
		return !!this.noteContext && this.noteContext.source === Api.RichContentContextSource.PhoneCall;
	}

	private onContentStateChanged = (content: IRichContentEditorState) => {
		this.setState({
			editorState: content,
		});
	};

	private onNoteEditorBodyBottomScrollAnchorRef = (ref: HTMLSpanElement) => {
		this.bottomScrollAnchorRef = ref;
	};

	private onRenderNonBodyReferencedEnities() {
		const {
			note,
			hideMentionField,
			mentionFieldClassName,
			sizeConstraint,
			onEntityClicked,
			getPopularMentionSuggestions,
			preferredMentionFieldSuggestionsPopoverPlacement,
		} = this.props;
		const mentionedEntities = this.readOnlyMentionedEntities || this.state.mentionedEntities;
		if (note?.context?.source === Api.RichContentContextSource.EmailMessageSend) {
			// render the email header
			const recipients = (note.mentionedEntities || [])
				.filter(x => Api.getTypeForEntity(x.entity.toJs()) !== 'company')
				.map(x => x.entity as ContactViewModel);
			const emailMessageContext = note.context as Api.IEmailMessageSendContext;
			return (
				<EmailMessageHeader
					className={`note-editor-email-message-header ${css(styleSheet.emailHeader)}`}
					recipients={recipients}
					seenOnDate={emailMessageContext.openDate ? new Date(emailMessageContext.openDate) : undefined}
				/>
			);
		} else {
			// render the normal mention field
			if (!hideMentionField) {
				return (
					<MentionField
						className={`note-editor-mention-field ${css(styleSheet.mentionField)} ${mentionFieldClassName || ''}`}
						getPopularMentionSuggestions={getPopularMentionSuggestions}
						mentionedEntities={mentionedEntities}
						onEntityClicked={onEntityClicked}
						onMentionedEntitiesChanged={this.onMentionedEntitiesChanged}
						preferredSuggestionsPopoverPlacement={preferredMentionFieldSuggestionsPopoverPlacement}
						readOnly={this.isReadOnly}
						sizeConstraint={sizeConstraint}
					/>
				);
			}
		}

		return null;
	}

	private onSendMessageClicked = (actionItem: ActionItemViewModel) => (e: React.MouseEvent<HTMLElement>) => {
		const { onActionItemSendMessageClicked } = this.props;
		if (onActionItemSendMessageClicked) {
			onActionItemSendMessageClicked(actionItem, e);
		}
	};

	private onCCUsersChanged = (ccUsers: Api.IUser[]) => {
		this.setState({
			ccUsers,
		});
	};

	private onMentionedEntitiesChanged = (mentionedEntities: EntityViewModel<Api.IEntity>[]) => {
		this.setState({
			mentionedEntities,
		});
	};

	private renderFooter() {
		const mode = this.props.sizeConstraint || 'normal';
		if (mode === 'normal') {
			return this.renderFullFooterLayout();
		}

		return this.renderCompactFooterLayout();
	}

	private renderFullFooterLayout() {
		const { footerStyles } = this.props;
		return (
			<div className={`note-editor-footer ${css(styleSheet.noteEditorFooter, ...(footerStyles || []))}`}>
				<div className={css(styleSheet.noteEditorFooterItemGroup)}>
					{this.renderSaveButton()}
					{!this.props.alwaysShowsSaveOptions && (
						<button
							className={css(baseStyleSheet.link, styleSheet.noteEditorFooterItem)}
							onClick={this.onCancelClicked}
						>
							Cancel
						</button>
					)}
				</div>
				<div className={css(styleSheet.noteEditorFooterItemGroup)}>
					{this.renderPrivacyToggle()}
					{this.renderNextMeetingButton()}
					{this.renderAddActionItemButton()}
				</div>
			</div>
		);
	}

	private renderCompactFooterLayout() {
		const { footerStyles } = this.props;
		return (
			<div className={`note-editor-footer ${css(...(footerStyles || []))}`}>
				<div className={css(styleSheet.noteEditorFooter)}>
					<div className={css(styleSheet.noteEditorFooterItemGroup, styleSheet.noteEditorFooterRow)}>
						{this.renderPrivacyToggle()}
						{this.renderNextMeetingButton()}
						{this.renderAddActionItemButton()}
					</div>
				</div>
				<div className={css(styleSheet.noteEditorFooter)}>
					<div className={css(styleSheet.noteEditorFooterItemGroup, styleSheet.noteEditorFooterRow)}>
						{this.renderSaveButton([
							baseStyleSheet.ctaButton,
							styleSheet.noteEditorFooterItem,
							styleSheet.noteEditorCompactFooterSave,
						])}
					</div>
				</div>
			</div>
		);
	}

	private renderSaveButton(styleSheets?: React.CSSProperties[]) {
		const { noteVm } = this.state;
		let appliedStyleSheets = [baseStyleSheet.ctaButtonSmall];
		if (!!styleSheets && styleSheets.length > 0) {
			appliedStyleSheets = styleSheets;
		}
		return (
			<button className={css(baseStyleSheet.ctaButton, appliedStyleSheets)} onClick={this.onSave}>
				{/* @ts-ignore */}
				{!!this.isFromPhoneCall && !noteVm.id ? 'Log Phone Call' : 'Save'}
			</button>
		);
	}

	private renderPrivacyToggle() {
		if (this.state.showSaveOptions && !this.props.hidesPrivacyToggle) {
			return (
				<PrivacyToggle
					publicText='Shared note'
					privateText='Private note'
					isOn={this.state.isPublicNote}
					onToggleCheckChanged={this.onToggleCheckChanged}
					className={css(styleSheet.privacyToggle)}
					id={`note-editor-privacy-toggle-${this.uuid}`}
				/>
			);
		}

		return null;
	}

	private renderAddActionItemButton() {
		const { hideActionItems } = this.props;
		if (hideActionItems) {
			return null;
		}
		return (
			<button className={css(styleSheet.addActionItemButton)} onClick={this.onAddActionItemClicked}>
				<img src={NoteEditorAddActionItemIconUrl} />
				<span className={css(baseStyleSheet.defaultNotFirstChildLeftSpacing)}>Add action item</span>
			</button>
		);
	}

	private renderNextMeetingButton() {
		const { account, showNextMeeting } = this.props;
		const { nextMeeting, showNextMeetingPopover } = this.state;
		const defaultNextMeeting = nextMeeting || account?.additionalInfo?.nextMeeting;
		if (!showNextMeeting) {
			return null;
		}
		return (
			<NextMeetingPopover
				anchor={
					<button className={css(styleSheet.addActionItemButton)} onClick={this.onNextMeetingClick}>
						<FutureMeetingIcon selected={!!defaultNextMeeting} />
						<span className={css(baseStyleSheet.defaultNotFirstChildLeftSpacing)}>
							{`Next Meeting: ${
								defaultNextMeeting ? moment(defaultNextMeeting).utc().format('MM/DD/YYYY') : 'Not Set!'
							}`}
						</span>
					</button>
				}
				isOpen={showNextMeetingPopover}
				onDaySelected={this.onNextMeetingDaySelected}
				onRequestClose={this.onNextMeetingRequestClose}
				selectedDay={nextMeeting}
			/>
		);
	}

	private onCancelClicked = () => {
		const { showSaveOptions, noteVm } = this.state;
		if (showSaveOptions) {
			this.setState({
				// @ts-ignore
				actionItemAttachments: (noteVm.actionItems || []).map(x => new ActionItemAttachmentViewModel(x)),
				// @ts-ignore
				isPublicNote: this.state.noteVm.isPublic,
				// @ts-ignore
				mentionedEntities: (this.state.noteVm.mentionedEntities || [])
					.filter(x => x.method === Api.RichContentReferenceMethod.Explicit)
					.map(x => x.entity),
				showSaveOptions: false,
			});
		}
		if (this.props.onCancel) {
			this.props.onCancel();
		}
	};

	private onRequestRemoveActionItem = (index: number) => () => {
		const actionItemAttachments = [...(this.state.actionItemAttachments || [])];
		actionItemAttachments.splice(index, 1);
		this.setState({
			actionItemAttachments,
		});
	};

	private onAddActionItemClicked = () => {
		const { userSession } = this.props;
		const actionItemAttachments = [...(this.state.actionItemAttachments || [])];
		// @ts-ignore
		const actionItem = new ActionItemViewModel(userSession);
		actionItemAttachments.push(new ActionItemAttachmentViewModel(actionItem));

		this.setState(
			{
				actionItemAttachments,
			},
			() => {
				if (!this.props.hideActionItems && !!this.bottomScrollAnchorRef) {
					this.bottomScrollAnchorRef.scrollIntoView();
				}
			}
		);
	};

	private onNextMeetingClick = () => {
		this.setState({ showNextMeetingPopover: true });
	};

	private onNextMeetingRequestClose = () => {
		this.setState({ showNextMeetingPopover: false });
	};

	private onNextMeetingDaySelected = (nextMeeting: Date) => {
		this.setState({ nextMeeting, showNextMeetingPopover: false });
	};

	private getNextStateWithProps(nextProps: IProps) {
		let nextState: IState = {};
		if (this.props.note !== nextProps.note || this.props === nextProps) {
			// @ts-ignore
			nextState = this.getNextStateWithNote(nextProps.note, nextProps);
		}

		if (
			Object.prototype.hasOwnProperty.call(nextProps, 'isPublicNote') &&
			nextProps.isPublicNote !== undefined &&
			nextProps.isPublicNote !== null
		) {
			nextState.isPublicNote = nextProps.isPublicNote;
		}

		if (
			Object.prototype.hasOwnProperty.call(nextProps, 'mentionedEntities') &&
			nextProps.mentionedEntities !== undefined &&
			nextProps.mentionedEntities !== null
		) {
			nextState.mentionedEntities = nextProps.mentionedEntities;
		}

		if (this.state.ccUsers !== nextProps.ccUsers) {
			nextState.ccUsers = nextProps.ccUsers;
		}

		if (this.state.placeholderText !== nextProps.placeholderText) {
			nextState.placeholderText = nextProps.placeholderText;
		}

		if (this.state.readOnly !== nextProps.readOnly) {
			nextState.readOnly = nextProps.readOnly;
		}

		if (this.state.hidesCCField !== nextProps.hidesCCField) {
			nextState.hidesCCField = nextProps.hidesCCField;
		}

		return nextState;
	}

	private getNextStateWithNote = (note: NoteViewModel, nextProps = this.props) => {
		const nextState: IState = {};
		// reset
		// @ts-ignore
		nextState.noteVm = note || new NoteViewModel(nextProps.userSession);
		nextState.showSaveOptions = false;
		nextState.ccUsers =
			nextProps.ccUsers ||
			(nextState.noteVm.userReferences || [])
				.filter(x => x.method === Api.RichContentReferenceMethod.Explicit)
				.map(x => x.entity);
		nextState.isPublicNote = nextState.noteVm.isPublic;
		nextState.mentionedEntities = (nextState.noteVm.mentionedEntities || [])
			.filter(x => x.method === Api.RichContentReferenceMethod.Explicit)
			.map(x => x.entity);
		nextState.actionItemAttachments = nextState.noteVm.actionItems.map(x => {
			const att = new ActionItemAttachmentViewModel(x);
			att.contentState = convertRawRichTextContentStateToRichContentEditorState(x.rawContentState);
			return att;
		});

		nextState.editorState = convertRawRichTextContentStateToRichContentEditorState(nextState.noteVm.rawContentState);
		return nextState;
	};

	private onToggleEditorFocus = (focused: boolean) => () => {
		this.setState({
			isEditorFocused: focused,
			showSaveOptions: true,
		});

		if (this.props.onEditorFocusChanged) {
			this.props.onEditorFocusChanged(focused);
		}
	};

	public onTextChanged = (editorState: IRichContentEditorState) => {
		this.setState({
			editorState,
		});
	};

	private onToggleCheckChanged = (isOn: boolean) => {
		const nextState: IState = { isPublicNote: isOn };
		if (!isOn) {
			nextState.ccUsers = [];
		}

		this.setState(nextState);

		if (this.props.onPrivacyToggleChanged) {
			this.props.onPrivacyToggleChanged(isOn);
		}
	};

	/** Save changes to remote */
	private onSave = () => {
		const { onValidateBeforeSaving, errorMessages } = this.props;
		const { editorState, actionItemAttachments, newAttachments } = this.state;
		const hasContent = !!editorState && editorState.hasContent();

		// make sure the note has content
		if (!hasContent && !!this.props.errorMessages) {
			this.props.errorMessages.push({
				messages: ['Please enter some text before saving this note.'],
			});
			return;
		}

		if (hasContent) {
			const actionItemModels: Api.IActionItem[] = [];

			// make sure all the action items have content
			const indexOfEmptyActionItem = (actionItemAttachments || []).findIndex(x => {
				if (!x.hasContent) {
					return true;
				}
				actionItemModels.push(x.toJs(true));
				return false;
			});

			if (indexOfEmptyActionItem >= 0) {
				// @ts-ignore
				this.props.errorMessages.push({
					messages: ['Please enter some text for each action item before saving this note.'],
				});
				return;
			}

			// take different paths based on if we're editing an existing note
			const updateExistingNote = !!this.state.noteVm && !!this.state.noteVm.id;
			let note: Api.INote = {};
			if (this.state.noteVm) {
				// be selective on updateExistingNote=true server barfs on json parsing if you post the entire obj.
				note = updateExistingNote
					? {
							context: this.state.noteVm.context,
							id: this.state.noteVm.id,
						}
					: {
							...this.state.noteVm.toNote(),
						};
			}

			// apply changes
			// @ts-ignore
			note.content = this.state.editorState.getRawRichTextContent() as Api.IRawRichTextContentState;
			note.visibility = this.state.isPublicNote ? 'all' : 'admin';
			note.actionItems = actionItemModels;
			note.referencedEntities = {
				companies: [],
				contacts: [],
				users: [],
			};

			// add mentioned entitied from field
			let referencedContactsCount = 0;
			let referencedCompaniesCount = 0;
			const mentionedEntities = this.state.mentionedEntities || [];
			mentionedEntities.forEach(x => {
				const mentionEnity = x.toMention();
				if (Api.getTypeForEntity(mentionEnity) === 'company') {
					// @ts-ignore
					// @ts-ignore
					note.referencedEntities.companies.push({
						entity: {
							id: mentionEnity.id,
						},
						method: Api.RichContentReferenceMethod.Explicit,
					});
					referencedCompaniesCount++;
				} else {
					// contact
					// @ts-ignore
					// @ts-ignore
					note.referencedEntities.contacts.push({
						entity: {
							id: mentionEnity.id,
						},
						method: Api.RichContentReferenceMethod.Explicit,
					});
					referencedContactsCount++;
				}
			});

			// add users from cc field
			let ccUsersCount = 0;
			(this.state.ccUsers || []).forEach(x => {
				// @ts-ignore
				// @ts-ignore
				note.referencedEntities.users.push({
					entity: {
						id: x.id,
					},
					method: Api.RichContentReferenceMethod.Explicit,
				});
				ccUsersCount++;
			});

			// remove legacy keys
			delete (note.content as any).entityMap;
			delete (note.content as any).blocks;

			if (!!onValidateBeforeSaving && !onValidateBeforeSaving(note, errorMessages)) {
				return;
			}

			// save
			// @ts-ignore
			// @ts-ignore
			const savePromise = this.state.noteVm.save(note, newAttachments);
			if (savePromise) {
				savePromise
					.then(savedNote => {
						// @ts-ignore
						const normalizedVisibility = getNormalizedNoteVisibility(savedNote.visibility);
						EventLogger.logEvent(
							{
								action: 'Save',
								category: 'NoteEditor',
								label: updateExistingNote ? 'Edit' : 'New',
							},
							{
								note: {
									cc: ccUsersCount,
									id: note.id,
									refCompanies: referencedCompaniesCount,
									refContacts: referencedContactsCount,
									visibility: normalizedVisibility,
								},
							}
						);

						if (!updateExistingNote) {
							EventLogger.logEvent({
								action: `NoteSavedWithVisibility: ${normalizedVisibility}`,
								category: 'NoteEditor',
							});

							// log event for cc count
							if (ccUsersCount > 0) {
								EventLogger.logEvent({
									action: 'NoteSavedWith: CC',
									category: 'NoteEditor',
								});
							}

							// log event for mention field companies count
							if (referencedCompaniesCount > 0) {
								EventLogger.logEvent({
									action: 'NoteSavedWith: ReferencedCompanies',
									category: 'NoteEditor',
								});
							}

							// log event for mention field contacts count
							if (referencedContactsCount > 0) {
								EventLogger.logEvent({
									action: 'NoteSavedWith: ReferencedContacts',
									category: 'NoteEditor',
								});
							}
						}

						this.setState({
							showSaveOptions: false,
						});

						// @ts-ignore
						this.setState(this.getNextStateWithNote(this.state.noteVm));
						this.onSaveSuccess(savedNote, updateExistingNote);
					})
					.catch(this.displayErrorMessage);
			}
		}
	};

	/**
	 * On save/update callback.
	 *
	 * @param toastSuccess Override for this.props.toastOnSuccess
	 */
	private onSaveSuccess = (note: Api.INote, toastSuccess: boolean) => {
		const { onNextMeetingSelected, onNoteSaved, toaster, toastOnSuccess } = this.props;
		const { nextMeeting } = this.state;
		if (onNoteSaved) {
			onNoteSaved(note);
		}

		if (toaster && (toastOnSuccess || toastSuccess)) {
			toaster.push({
				linkTitle: 'See Notes',
				message: 'Your note was saved.',
				onLinkClicked: routerProps => {
					// @ts-ignore
					routerProps.history.push('/notes-action-items?activeTab=notes');
				},
				type: 'successMessage',
			});
		}

		if (nextMeeting) {
			onNextMeetingSelected?.(nextMeeting);
		}
	};

	private displayErrorMessage = (error: Api.IOperationResultNoValue) => {
		if (this.props.errorMessages) {
			this.props.errorMessages.push({
				// @ts-ignore
				messages: [error.systemMessage],
			});
		}
	};
}

const ObservableNoteEditor = observer(_NoteEditor);
export const NoteEditor = inject(ToasterViewModelKey, ErrorMessagesViewModelKey)(ObservableNoteEditor);
