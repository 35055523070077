import { PendingActions } from '@ViewModels';
import { HiddenDefaultMessageTemplateKey } from '../models/MessageTemplates';
import { PushNotificationsStorageKey } from '../viewmodels/PushNotifications';
import { RecentCompanySearchesStorageKey } from '../web/containers/PeopleV2/CompaniesContainer/CompaniesGlobalSearch';
import { RecentPeopleSearchesStorageKey } from '../web/containers/PeopleV2/PeopleContainer/PeopleGlobalSearch';
import { SUPPRESS_DUPLICATE_CAMPAIGN_MODAL_KEY, SUPPRESS_DUPLICATE_POST_MODAL_KEY } from './Campaigns';

interface IKeyValueStorageProvider {
	clear(): void;
	get(key: string): string;
	getLength(): number;
	getObject<TObject>(key: string): Promise<TObject>;
	remove(key: string): void;
	set(key: string, value: string): void;
	setObject<TObject>(key: string, object: TObject): Promise<boolean>;
}

export const HasBeenNotifiedExcludingContactWillSnoozeResourceSelectorStorageKey =
	'hasBeenNotifiedExcludingContactWillSnoozeResourceSelectorStorageKey';
export const AlwaysTrackWithLevStorageKey = 'alwaysTrackWithLev';
export const UsedAccountCreationImagesStorageKey = 'usedAccountCreationImagesStorageKey';
export const storagePrefixExcludedFromClear = 'exclude-from-clear';

const StoragePropertiesExcludedFromClear = [
	`${PendingActions.ConnectRedtail}`,
	`${HiddenDefaultMessageTemplateKey}`,
	`${HasBeenNotifiedExcludingContactWillSnoozeResourceSelectorStorageKey}`,
	`${RecentPeopleSearchesStorageKey}`,
	`${RecentCompanySearchesStorageKey}`,
	`${AlwaysTrackWithLevStorageKey}`,
	`${PushNotificationsStorageKey}`,
	SUPPRESS_DUPLICATE_CAMPAIGN_MODAL_KEY,
	SUPPRESS_DUPLICATE_POST_MODAL_KEY,
];
const TestKey = '___SafeStorageTestKey___';
export const storageTypeAvailable = (type: 'localStorage' | 'sessionStorage') => {
	if (!type) {
		return false;
	}

	try {
		if (window) {
			const storage: Storage = window[type];
			storage.setItem(TestKey, TestKey);
			storage.removeItem(TestKey);
			return true;
		} else {
			return false;
		}
	} catch (_) {
		return false;
	}
};

class KeyValueStorage implements IKeyValueStorageProvider {
	protected store: Record<string, string>;
	constructor() {
		this.store = {};
		this.getLength = this.getLength.bind(this);
	}

	public clear() {
		const exclusions = Object.values(this.store).filter(item => StoragePropertiesExcludedFromClear.includes(item[0]));
		this.store = {};
		exclusions.forEach(item => (this.store[item[0]] = item[1]));
	}

	public get = (key: string) => {
		return this.store[key];
	};

	public getLength() {
		return Object.keys(this.store).length;
	}

	public getObject = <TObject>(key: string) => {
		if (!key) {
			return Promise.resolve(null as TObject);
		}

		const value = this.get(key);
		if (!value) {
			return Promise.resolve(null as TObject);
		}

		return new Promise<TObject>(resolve => {
			const objectValue = JSON.parse(value);
			resolve(objectValue);
		});
	};

	public set = (key: string, value: string) => {
		if (key) {
			this.store[key] = value;
		}
	};

	public setObject = <TObject>(key: string, object: TObject) => {
		if (key) {
			this.store[key] = JSON.stringify(object);
			return Promise.resolve(true);
		}
		return Promise.resolve(false);
	};

	public remove = (key: string) => {
		if (key) {
			delete this.store[key];
		}
	};
}

class LocalStorage extends KeyValueStorage {
	private canUseLocalStorage: boolean;
	constructor() {
		super();
		this.canUseLocalStorage = storageTypeAvailable('localStorage');
		this.store = this.canUseLocalStorage ? window.localStorage : {};

		this.getLength = this.getLength.bind(this);
		this.clear = this.clear.bind(this);
	}

	public getLength() {
		if (this.canUseLocalStorage) {
			return (this.store as Storage).length;
		}

		return super.getLength();
	}

	public clear() {
		if (this.canUseLocalStorage) {
			Object.keys(this.store).forEach(item => {
				if (!StoragePropertiesExcludedFromClear.includes(item) && !item.startsWith(storagePrefixExcludedFromClear)) {
					(this.store as Storage)?.removeItem(item);
				}
			});
			return;
		}

		super.clear();
	}
}

class PersistentStorage {
	private inMemoryStorage: IKeyValueStorageProvider;
	private localStorage: IKeyValueStorageProvider;
	constructor() {
		this.inMemoryStorage = new KeyValueStorage();
		this.localStorage = new LocalStorage();
	}

	public get memory() {
		return this.inMemoryStorage;
	}

	public get local() {
		return this.localStorage;
	}
}

export const PersistentStorageManager = new PersistentStorage();
