import { CssSelectors } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const dataOriginDark = '#EB9554';
export const dataOriginLight = '#FEF6F0';

export const styleSheet = StyleSheet.create({
	button: {
		height: 14,
		marginRight: 8,
	},
	childrenContainer: {
		width: '100%',
	},
	container: {
		alignItems: 'stretch',
		background: 'transparent',
		borderRadius: 8,
		display: 'inline-flex',
		height: 'auto',
		position: 'relative',
		transition: 'background .25s ease-in-out',
	},
	enabled: {
		background: dataOriginLight,
		paddingLeft: 8,
	},
	icon: {},
	iconContainer: {
		alignItems: 'flex-end',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		maxWidth: 30,
		minWidth: 30,
		transition: 'width .25s ease-in-out',
		...CssSelectors.allChildren({
			alignItems: 'flex-end',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
		}),
	},
	iconContainerBottom: {
		justifyContent: 'flex-end',
		paddingBottom: 5,
	},
	iconContainerTop: {
		justifyContent: 'flex-start',
		paddingTop: 5,
	},
	noMargin: {
		margin: 0,
	},
	originData: {
		background: dataOriginLight,
		border: `1px solid ${dataOriginDark}`,
		borderRadius: 8,
		maxWidth: 300,
		padding: '10px 15px',
	},
	pluginEnv: {
		maxWidth: 315,
	},
	popover: {
		margin: '0 10px',
	},
	popoverOffset: {
		marginTop: -30,
	},
	popoverParagraph: {
		':last-child': {
			margin: 0,
		},
		margin: '0 0 20px',
		padding: 0,
		wordBreak: 'break-word',
	},
});
