import { StyleSheet } from 'aphrodite';
import { navigation } from '../../../styles/colors';

export const styleSheet = StyleSheet.create({
	factDate: {
		color: navigation,
		flexShrink: 0,
		fontSize: 12,
		marginTop: 2,
	},
	hoverCardArrow: {
		fill: 'white',
	},
	hoverCardContent: {
		animationDuration: '400ms',
		animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
		backgroundColor: 'white',
		border: '1px solid #f3f3f3',
		borderRadius: 3,
		boxShadow: '0 1px 2px 0 hsla(0, 0%, 63.5%, .5)',
		willChange: 'transform, opacity',
		zIndex: 8,
	},
	keyDate: {
		alignItems: 'center',
		display: 'inline-flex',
		minWidth: 60,
	},
	keyDateIcon: {
		height: 14,
		marginLeft: 7,
		width: 14,
	},
	popoverContent: {
		color: '#858585',
		fontSize: 12,
		padding: '8px 15px',
	},
	popoverRemoveButton: {
		marginTop: 10,
	},
});
