import { DefaultModalName, IModalContext, ModalChildComponentContextKey } from '@AppModels/.';
import {
	EnvironmentKey,
	FullScreenModalViewModelKey,
	IEnvironmentComponentProps,
	IFullscreenModalComponentProps,
} from '@AppModels/AppState';
import { openUrlInNewTab } from '@AppModels/UiUtils';
import * as H from 'history';
import { inject } from 'mobx-react';
import * as React from 'react';
import { LinkProps, NavLink } from 'react-router-dom';

const FullscreenModalNavLinkSfc: React.FC<
	Partial<LinkProps> & IFullscreenModalComponentProps & IModalContext & IEnvironmentComponentProps
> = props => {
	const { fullscreenModal, onClick, to, replace, parentModal, environment, ...restProps } = props;
	const onLinkClicked = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		if (onClick) {
			onClick(e);
		}

		// open this link in a new tab if it was rendered in a regular modal
		if ((!e.defaultPrevented && !!parentModal && parentModal.name === DefaultModalName) || !!e.ctrlKey || !!e.metaKey) {
			e.preventDefault();
			e.stopPropagation();
			openUrlInNewTab(
				`${window.location.origin}/${
					// @ts-ignore
					environment.appType === 'admin' ? 'admin.html' : ''
					// @ts-ignore
				}#${typeof to === 'string' ? to : to.pathname}`
			);
			return;
		}

		// open in the fullscreen modal router
		if (!e.defaultPrevented) {
			e.preventDefault();
			e.stopPropagation();
			if (
				// @ts-ignore
				((fullscreenModal.history.location || {}).pathname || '') !==
				(typeof to === 'string' ? to : (to as H.LocationDescriptorObject<any>).pathname)
			) {
				// @ts-ignore
				if (!!replace && !!fullscreenModal.hasValidLocation) {
					// @ts-ignore
					fullscreenModal.contextAwareReplaceLocation(to);
				} else {
					// @ts-ignore
					fullscreenModal.contextAwarePushLocation(to);
				}
			}
		}
	};

	// @ts-ignore
	return <NavLink {...restProps} onClick={fullscreenModal ? onLinkClicked : onClick} replace={replace} to={to} />;
};

export const FullscreenModalNavLink = inject(
	FullScreenModalViewModelKey,
	ModalChildComponentContextKey,
	EnvironmentKey
)(FullscreenModalNavLinkSfc);
