import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fill?: string;
	width?: number;
	height?: number;
}

export const ActionItemsIcon: React.FC<IProps> = ({ className, fill = '#fff', width = 18, height = 18 }) => {
	return (
		<SvgIcon className={className} height={height} viewBox='0 0 18 18' width={width}>
			<g fill={fill} fillRule='evenodd'>
				<path d='M6.90330678,12.1383976 L4.38660175,9.81678214 C3.89915918,9.36712506 3.86852798,8.60745568 4.31818506,8.12001311 C4.32912251,8.10815657 4.34029776,8.09652162 4.35170402,8.08511537 L4.35170402,8.08511537 C4.8496831,7.58713629 5.64795226,7.55899201 6.1797654,8.02066409 L8.19270402,9.76811537 L14.6635268,1.50108291 C15.0942743,0.95076603 15.8819126,0.837075495 16.450704,1.24311537 L16.450704,1.24311537 C16.9928913,1.63016351 17.1186564,2.38345765 16.7316083,2.92564492 C16.7233613,2.93719744 16.7149111,2.94860349 16.7062616,2.95985773 L9.84515867,11.8871056 C9.17206129,12.7628988 7.9164374,12.9272162 7.04064422,12.2541189 C6.99315542,12.217621 6.94733008,12.1790083 6.90330678,12.1383976 Z' />
				<path
					fillRule='nonzero'
					d='M16,15 L16,9 C16,8.44771525 16.4477153,8 17,8 L17,8 C17.5522847,8 18,8.44771525 18,9 L18,16 C18,17.1045695 17.1045695,18 16,18 L2,18 C0.8954305,18 1.3527075e-16,17.1045695 0,16 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 L10,0 C10.5522847,-1.01453063e-16 11,0.44771525 11,1 L11,1 C11,1.55228475 10.5522847,2 10,2 L3,2 C2.44771525,2 2,2.44771525 2,3 L2,15 C2,15.5522847 2.44771525,16 3,16 L15,16 C15.5522847,16 16,15.5522847 16,15 Z'
				/>
			</g>
		</SvgIcon>
	);
};
