import { getValueAtPropertyPath, IPolicyKeyFactMetaData, IKeyFact } from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { v4 as uuidgen } from 'uuid';
import { TextInput } from '../../TextInput';
import { XIcon } from '../../svgs/icons/XIcon';
import { RoundAddIcon } from '../../svgs/icons/RoundAddIcon';
import { styleSheet } from './styles';

interface IProps<T = any> {
	addButtonContent?: React.ReactNode;
	className?: string;
	items?: T[];
	itemValueKeyPath: string;
	listItemClassName?: string;
	onAddButtonClicked?(): void;
	onItemValueChanged?(item: T, index: number, value: string): void;
	onRemoveButtonClicked?(item: T, index: number): void;
	onRenderItem?(item: T, index: number): React.ReactNode;
}

interface IState<T = any> {
	items?: T[];
}

class _EditableEntityInfoBulletList<T extends object = any> extends React.Component<IProps<T>, IState<T>> {
	// @ts-ignore
	private mWeakMap: WeakMap<T, string>;
	public readonly state: IState<T> = {
		items: [],
	};

	public UNSAFE_componentWillMount() {
		this.mWeakMap = new WeakMap<T, string>();
		const nextState = this.getNextStateWithProps(this.props);
		if (nextState) {
			this.setState(nextState);
		}
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IProps<T>) {
		const nextState = this.getNextStateWithProps(nextProps);
		if (nextState) {
			this.setState(nextState);
		}
	}

	public render() {
		const { className, onAddButtonClicked, itemValueKeyPath, addButtonContent, listItemClassName, onRenderItem } =
			this.props;
		const { items } = this.state;
		return (
			<div className={`${css(styleSheet.container)} editable-entity-info-bullet-list ${className || ''}`}>
				{/* @ts-ignore */}
				{items.map((x, i) => {
					const isProtectedKeyFact = !!((x as IKeyFact).source?.additionalMetadata as IPolicyKeyFactMetaData)?.policyId;

					const value = itemValueKeyPath ? getValueAtPropertyPath(x, itemValueKeyPath) : null;
					return (
						<div className={`${css(styleSheet.listItem)} ${listItemClassName || ''}`} key={this.mWeakMap.get(x) || i}>
							<button
								className={css(styleSheet.listItemIcon)}
								onClick={this.onRemoveButtonClicked(i)}
								disabled={isProtectedKeyFact}
							>
								<XIcon height={12} width={12} />
							</button>
							{onRenderItem ? (
								onRenderItem(x, i)
							) : (
								<TextInput
									autoComplete='off'
									className={css(styleSheet.listItemTextField)}
									inputId='editable-entity-info-bullet-list-input'
									onChange={this.onItemValueChanged(i)}
									type='text'
									value={value || ''}
									disabled={isProtectedKeyFact}
								/>
							)}
						</div>
					);
				})}
				<button className={css(styleSheet.button)} onClick={onAddButtonClicked}>
					<RoundAddIcon />
					{addButtonContent}
				</button>
			</div>
		);
	}

	private getNextStateWithProps = (nextProps: IProps<T>) => {
		const nextState: IState<T> = {};

		if (this.state.items !== nextProps.items) {
			this.mWeakMap = new WeakMap<T, string>();
			const items = nextProps.items || [];
			items.forEach(x => this.mWeakMap.set(x, uuidgen()));
			nextState.items = items;
		}

		if (Object.keys(nextState).length > 0) {
			return nextState;
		}
	};

	private onItemValueChanged = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
		const { onItemValueChanged } = this.props;
		if (onItemValueChanged) {
			// @ts-ignore
			const item = this.state.items[index];
			onItemValueChanged(item, index, e.target.value);
		}
	};

	private onRemoveButtonClicked = (index: number) => () => {
		const { onRemoveButtonClicked } = this.props;
		const { items } = this.state;
		if (onRemoveButtonClicked) {
			// @ts-ignore
			onRemoveButtonClicked(items[index], index);
		} else {
			const nextItems = [...(items || [])];
			this.mWeakMap.delete(nextItems[index]);
			nextItems.splice(index, 1);
			this.setState({
				items: nextItems,
			});
		}
	};
}

export const EditableEntityInfoBulletList = observer(_EditableEntityInfoBulletList);
