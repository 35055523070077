import { StyleSheet } from 'aphrodite';
import { brandSecondary } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

const tokenTopMargin = 4;

export const styleSheet = StyleSheet.create({
	ccDropdown: {
		marginLeft: 20,
		width: '100%',
	},
	ccDropdownContent: {
		background: '#FFFFFF',
		border: '1px solid #F3F3F3',
		borderRadius: 3,
		boxShadow: '0 0 6px 0 rgba(139,139,139,0.10)',
		boxSizing: 'border-box',
		width: '100% !important',
	},
	ccField: {
		display: 'flex',
		padding: '0 20px',
	},
	ccFieldHelp: {
		alignItems: 'center',
		alignSelf: 'flex-start',
		display: 'inline-flex',
		justifyContent: 'center',
		marginTop: 9,
		padding: 2,
	},
	ccFieldHelpContent: {
		color: '#4A4A4A',
		fontSize: 12,
		margin: 15,
		whiteSpace: 'pre',
	},
	ccFieldInput: {
		':focus': {
			outline: 'none',
		},
		background: 'transparent',
		border: 0,
		display: 'inline-flex',
		flexGrow: 1,
		marginLeft: 10,
		minWidth: 100,
		padding: '2px 0',
	},
	ccFieldLabel: {
		alignSelf: 'flex-start',
		color: '#8E8E8E',
		display: 'inline-flex',
		fontSize: 12,
		marginTop: 9,
	},
	ccFieldNoMatches: {
		color: '#000000',
		fontSize: 14,
		letterSpacing: 0,
		margin: '18px 20px',
	},
	ccFieldRemoveButton: {
		alignItems: 'center',
		display: 'none',
		height: 18,
		justifyContent: 'center',
		margin: '0 -5px 0 0',
		padding: 5,
		position: 'absolute',
		right: 4,
		top: '50%',
		transform: 'translateY(-50%)',
		width: 18,
		...CssSelectors.allChildren({
			height: '100%',
			width: '100%',
		}),
	},
	ccFieldText: {
		color: '#767676',
		fontSize: 12,
		letterSpacing: 0,
	},
	ccFieldToken: {
		':hover': {
			paddingRight: 22,
			...CssSelectors.allChildren(
				{
					display: 'flex',
				},
				'.cc-field-remove-button'
			),
		},
		alignItems: 'center',
		border: `1px solid ${brandSecondary}`,
		borderRadius: 3,
		boxSizing: 'border-box',
		display: 'inline-flex',
		justifyContent: 'center',
		marginLeft: 10,
		marginTop: tokenTopMargin,
		padding: '4px 10px',
		position: 'relative',
		transition: 'padding .25s ease-in-out',
	},
	ccFieldTokenContainer: {
		alignItems: 'center',
		display: 'flex',
		flexGrow: 1,
		marginTop: -tokenTopMargin,
		minHeight: 31,
	},
	ccFieldTokens: {
		flexGrow: 1,
	},
	ccFieldUser: {
		':focus': {
			outline: 'none',
		},
		':hover': {
			backgroundColor: '#E3E7E9',
		},
		cursor: 'pointer',
	},
	ccFieldUserHighlighted: {
		backgroundColor: '#e8f3f9',
	},
});
