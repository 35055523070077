import {
	DefaultRichContentRawCSS,
	IImpersonationContextComponentProps,
	ImpersonationContextKey,
	levPlaceholderPopoverCloseCommand,
	levPlaceholderPopoverCommand,
} from '@AppModels/.';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '@AppModels/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import {
	ToolbarFull,
	convertRawRichTextContentStateToRichContentEditorState,
	replaceFontFamilyInCss,
} from '@AppModels/UiUtils';
import * as Api from '@ViewModels';
import { Popover, PopoverType } from '@WebComponents/Popover';
import { TextInput } from '@WebComponents/TextInput';
import { EmailAttachments } from '@WebComponents/email/EmailAttachments';
import { EmailCCField } from '@WebComponents/email/EmailCCFIeld';
import { SignatureToolbarItem } from '@WebComponents/email/SignatureToolbarItem';
import { EmailMessageTemplateSelectorButton } from '@WebComponents/email/templates/EmailMessageTemplateSelectorButton';
import {
	IRichContentDocumentEditorConfig,
	IRichContentDocumentEditorError,
	RichContentDocumentEditor,
} from '@WebComponents/richContent/RichContentDocumentEditor';
import { getSettingsIcon } from '@WebComponents/svgs/icons/NavIcon';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { isIE11 } from '../../../../models/Browser';
import {
	AttachmentsToBeUploadedViewModel,
	ComposeEmailViewModel,
	ContactViewModel,
	IRichContentEditorState,
	TemplatesViewModel,
} from '../../../../viewmodels/AppViewModels';
import { brandSecondary, navigation } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { DeprecatedCloseButton } from '../../DeprecatedCloseButton';
import { EmailComposerOptions } from '../EmailComposerOptions';
import { IPlaceholderPopoverProps, MergeFieldPopOverMessage } from './presentation';
import { bodyFieldFooterToolbarHeight, styleSheet } from './styles';

const placeholderRawCSS = `
	span[data-placeholder="true"] {
		border-radius: 3px;
		border: 1px dashed ${brandSecondary};
		box-sizing: border-box;
		padding: 1px 5px;
	}

	span[data-placeholder="true"][contentEditable=false] {
		cursor: pointer;
	}

	span[data-placeholder="true"][contentEditable=false][data-mce-selected] {
		cursor: pointer;
		outline: none;
	}
`;

export interface IEmailComposerProps
	extends IUserSessionComponentProps,
		IEventLoggingComponentProps,
		IErrorMessageComponentProps,
		IImpersonationContextComponentProps {
	advancedSettingsPopoverContent?: React.ReactNode;
	attachments?: AttachmentsToBeUploadedViewModel<File>;
	bodyEditorState?: IRichContentEditorState;
	bodyError?: string;
	/** Displays inside the body as part of scrollable content */
	bodyContentFooter?: React.ReactNode;
	bodyFooter?: React.ReactNode;
	bodyTemplate?: Api.ITemplate;
	ccRecipients?: Api.IRecipient[];
	className?: string;
	disableFileAttachment?: boolean;
	emailComposer?: ComposeEmailViewModel;
	hideBodyFooterToolbar?: boolean;
	hideBodyTemplateSelectorButton?: boolean;
	hideEditSignature?: boolean;
	individualRecipient?: ContactViewModel;
	onAttachmentToolbarButtonClicked?(): void;
	onBodyEditorFocusChanged?(hasFocus: boolean): void;
	onBodyEditorStateChanged?(bodyEditorState: IRichContentEditorState): void;
	onBodyFieldScroll?(): void;
	onBodyInputFieldClicked?(e: React.MouseEvent<HTMLElement>): void;
	onBodyTemplateChanged?(bodyTemplate: Api.ITemplate): void;
	onCcRecipientsChanged?(recipients: Api.IRecipient[]): void;
	preBodyContent?: React.ReactNode;
	onRequestRemoveSavedEmailAttachment?(savedAttachment: Api.IFileAttachment): Promise<any>;
	onSignatureTemplateChanged?(signatureTemplate: Api.ITemplate): void;
	onSubjectChanged?(subject: string): void;
	onSubjectInputRef?(ref: HTMLInputElement): void;
	onCustomFileChanged?(): void;
	readonly?: boolean;
	savedAttachments?: Api.IFileAttachment[];
	showCcField?: boolean;
	signatureTemplate?: Api.ITemplate | null;
	subject?: string;
	subjectError?: string;
	templates?: TemplatesViewModel;
}

interface IState {
	bodyEditorHasFocus?: boolean;
	bodyEditorState?: IRichContentEditorState;
	bodyError?: string;
	bodyHasText?: boolean;
	bodyTemplate?: Api.ITemplate;
	ccRecipients?: Api.IRecipient[];
	editorLoaded?: boolean;
	emailSignature?: Api.ITemplate;
	hideBodyTemplateSelectorButton?: boolean;
	placeholderPopover?: IPlaceholderPopoverProps;
	showingAdvancedSettings?: boolean;
	signatureTemplate?: Api.ITemplate | null;
	subject?: string;
	subjectError?: string;
	templates?: TemplatesViewModel;
}

const RawRichTextContentBlockStyleAttribute = replaceFontFamilyInCss(
	'arial',
	Api.DefaultRawRichTextContentBlockStyleAttribute
);

class _EmailComposer extends React.Component<IEmailComposerProps, IState> {
	// @ts-ignore
	private filesInputRef: HTMLInputElement;
	private static DefaultEditorConfig: IRichContentDocumentEditorConfig;

	public static getDerivedStateFromProps(props: IEmailComposerProps, state: IState) {
		const nextState: IState = {};
		if (props.bodyEditorState !== state.bodyEditorState) {
			nextState.bodyEditorState = props.bodyEditorState || convertRawRichTextContentStateToRichContentEditorState();
			nextState.bodyHasText = nextState.bodyEditorState.hasContent();
		}

		if (props.signatureTemplate !== state.signatureTemplate) {
			nextState.signatureTemplate = props.signatureTemplate;
		}

		if (props.subject !== state.subject) {
			nextState.subject = props.subject;
		}

		if (props.bodyError !== state.bodyError) {
			nextState.bodyError = props.bodyError;
		}

		if (props.subjectError !== state.subjectError) {
			nextState.subjectError = props.subjectError;
		}

		if (props.ccRecipients !== state.ccRecipients) {
			nextState.ccRecipients = props.ccRecipients;
		}

		if (props.bodyTemplate !== state.bodyTemplate) {
			nextState.bodyTemplate = props.bodyTemplate;
			nextState.subject = nextState.bodyTemplate ? nextState.bodyTemplate.subject : nextState.subject;
		}

		if (props.hideBodyTemplateSelectorButton !== state.hideBodyTemplateSelectorButton) {
			nextState.hideBodyTemplateSelectorButton = props.hideBodyTemplateSelectorButton;
		}

		return Object.keys(nextState).length > 0 ? nextState : null;
	}

	constructor(props: IEmailComposerProps) {
		super(props);
		if (!_EmailComposer.DefaultEditorConfig) {
			_EmailComposer.DefaultEditorConfig = {
				autoresizeToFitContent: true,
				contentHorizontalPadding: 10,
				contentRawCss: replaceFontFamilyInCss('arial', placeholderRawCSS.concat(DefaultRichContentRawCSS)),
				defaultBlockStyleAttributeStringValue: RawRichTextContentBlockStyleAttribute,
				minHeight: 100,
				plugins: 'placeholders table emoticons autolink surveylinks',
				toolbar: ToolbarFull,
				toolbarOverflow: 'floating',
				useDefaultEmailPlaceholders: true,
			};
		}

		const initialState: IState = {
			bodyEditorState: convertRawRichTextContentStateToRichContentEditorState(),
			// @ts-ignore
			templates: props.templates || new TemplatesViewModel(props.userSession).impersonate(props.impersonationContext),
		};
		this.state = {
			...initialState,
			...(_EmailComposer.getDerivedStateFromProps(props, initialState) || {}),
		};
	}

	public componentWillUnmount() {
		this.dismissPlaceholderPopover(false)();
	}

	public render() {
		const {
			advancedSettingsPopoverContent,
			attachments,
			bodyContentFooter,
			bodyFooter,
			className,
			disableFileAttachment,
			emailComposer,
			hideBodyFooterToolbar,
			hideEditSignature,
			impersonationContext,
			individualRecipient,
			onSubjectInputRef,
			preBodyContent,
			readonly,
			savedAttachments,
			showCcField,
			subject,
		} = this.props;
		const {
			bodyEditorHasFocus,
			bodyEditorState,
			bodyHasText,
			bodyError,
			bodyTemplate,
			ccRecipients,
			editorLoaded,
			hideBodyTemplateSelectorButton,
			placeholderPopover,
			showingAdvancedSettings,
			signatureTemplate,
			subjectError,
			templates,
		} = this.state;
		const advancedSettingsPopoverAnchor = !!advancedSettingsPopoverContent && (
			<button
				className={css(styleSheet.advancedSettingsButton)}
				onClick={this.toggleShowingAdvancedSettings(!showingAdvancedSettings)}
			>
				{getSettingsIcon(navigation)}
				<span>Advanced Settings</span>
			</button>
		);
		const bodyFieldContentStyles = [
			styleSheet.bodyFieldContent,
			!hideBodyFooterToolbar ? styleSheet.bodyFieldContentWithToolbar : null,
			bodyError
				? !hideBodyFooterToolbar
					? styleSheet.bodyFieldContentWithToolbarAndError
					: styleSheet.bodyFieldContentWithError
				: null,
		];

		const isCustomOrTurningXX =
			emailComposer?.resourceSelectorId?.startsWith('Custom') || emailComposer?.resourceSelectorId?.includes('XX');

		const hideEmailMessageTemplateSelectorButton =
			(!!bodyTemplate || readonly || hideBodyTemplateSelectorButton) && !isCustomOrTurningXX;

		const editorConfig: IRichContentDocumentEditorConfig = {
			..._EmailComposer.DefaultEditorConfig,
			imageOptions: { allowPasteImages: true },
		};

		return (
			<div className={`${css(styleSheet.emailComposer)} ${className}`}>
				{!!showCcField && <EmailCCField onRecipientsChange={this.onCcRecipientsChanged} recipients={ccRecipients} />}
				<div className={css(styleSheet.emailComposerSubjectField)}>
					<TextInput
						autoComplete='off'
						disabled={!!readonly}
						inputId='email-composer-subject-input'
						inputRef={onSubjectInputRef}
						leftAccessory={
							<span className={css(styleSheet.emailComposerInputLabel)}>
								Subject:<span className='required'>*</span>
							</span>
						}
						onChange={this.onSubjectChanged}
						type='text'
						value={subject || ''}
					/>
					{Boolean(subjectError) && <div className={css(styleSheet.fieldError)}>{subjectError}</div>}
				</div>
				{preBodyContent ?? null}
				<div className={`${css(baseStyleSheet.textField, styleSheet.body)}`} onClick={this.onBodyFieldClicked}>
					<div className={`body-field-content ${css(...bodyFieldContentStyles)}`} onScroll={this.onBodyFieldScroll}>
						<RichContentDocumentEditor
							className={css(styleSheet.bodyFieldEditor)}
							config={editorConfig}
							contentState={bodyEditorState}
							disableFileAttachment={disableFileAttachment}
							onBlur={this.bodyEditorDidChangeFocus(false)}
							onContentStateChanged={this.onBodyEditorStateChanged}
							onExecuteCommand={this.onEditorExecuteCommand}
							onFocus={this.bodyEditorDidChangeFocus(true)}
							onLoad={this.onEditorLoaded}
							readOnly={readonly}
						/>
						{!bodyHasText && !bodyEditorHasFocus && (
							<span className={css(styleSheet.bodyFieldPlaceholder, styleSheet.emailComposerInputLabel)}>
								Message:<span className='required'>*</span>
							</span>
						)}
						{placeholderPopover?.coordinate ? (
							<Popover
								anchor={
									<span
										style={{
											left: placeholderPopover.coordinate.x,
											position: 'absolute',
											top: placeholderPopover.coordinate.y + bodyFieldFooterToolbarHeight,
											zIndex: 1,
										}}
									/>
								}
								isOpen={!!placeholderPopover?.coordinate}
								preferredPlacement='right'
								type={PopoverType.white}
							>
								<MergeFieldPopOverMessage
									dismiss={this.dismissPlaceholderPopover()}
									// @ts-ignore
									emailComposer={emailComposer}
									// @ts-ignore
									individualRecipient={
										emailComposer?.selectedCustomEmail
											? emailComposer?.selectedCustomEmailContact
											: individualRecipient ?? null
									}
									popoverProps={placeholderPopover}
								/>
							</Popover>
						) : null}
						<input
							id='email-composer-files-input'
							multiple={true}
							onChange={this.onFilesInputChanged}
							// @ts-ignore
							ref={this.onFilesInputRef}
							style={{
								height: 0,
								opacity: 0,
								pointerEvents: 'none',
								position: 'fixed',
								visibility: 'hidden',
								width: 0,
								zIndex: -1,
							}}
							type='file'
						/>
						<div className={css(styleSheet.bodyFieldFooter)}>
							<EmailAttachments
								className='email-composer-attachments'
								// @ts-ignore
								emailAttachments={!emailComposer?.selectedCustomEmail ? attachments : null}
								inProgressAttachments={
									emailComposer?.selectedCustomEmail?.attachments?.filter(x =>
										Boolean((x as Api.IInProgressFileAttachment).uploadTaskId)
									) as Api.IInProgressFileAttachment[]
								}
								onRequestRemoveSavedEmailAttachment={this.onRequestRemoveSavedEmailAttachment}
								readonly={disableFileAttachment}
								savedAttachments={savedAttachments}
								impersonationContext={impersonationContext}
							/>
							{bodyContentFooter}
						</div>
					</div>
					{!hideBodyFooterToolbar && (
						<>
							<div className={css(styleSheet.bodyFieldContentToolbar)}>
								{emailComposer?.emailMessage?.options?.sendEmailFrom !== Api.SendEmailFrom.ContactOwner &&
									(emailComposer?.emailMessage?.options?.sendEmailFrom !== Api.SendEmailFrom.SelectedUser ||
										impersonationContext?.isValid) && (
										<SignatureToolbarItem
											className={css(styleSheet.signatureToolbarItem)}
											disabled={!editorLoaded}
											hideAddNew={hideEditSignature}
											hideEditing={hideEditSignature}
											initialSignatureTemplate={signatureTemplate}
											onEmailSignatureSelected={this.onEmailSignatureSelected}
											templates={templates}
										/>
									)}
								{!!advancedSettingsPopoverContent && (
									<Popover
										anchor={advancedSettingsPopoverAnchor}
										contentClassName={css(styleSheet.advancedSettingsPopoverContent)}
										dismissOnClickOutside={true}
										isOpen={!!showingAdvancedSettings}
										onRequestClose={this.toggleShowingAdvancedSettings(false)}
										preferredPlacement='above'
										type={PopoverType.white}
									>
										<>
											<div className={css(styleSheet.advancedSettingsPopoverContentHeader)}>
												<DeprecatedCloseButton onClick={this.toggleShowingAdvancedSettings(false)} />
											</div>
											{advancedSettingsPopoverContent}
										</>
									</Popover>
								)}
							</div>
						</>
					)}
					{!!bodyError && <div className={`email-field-error ${css(styleSheet.fieldError)}`}>{bodyError}</div>}
				</div>
				{bodyFooter}
				<EmailMessageTemplateSelectorButton
					className={`${css(styleSheet.emailComposerTemplatesSelectorButton)} ${
						hideEmailMessageTemplateSelectorButton ? 'hide' : ''
					}`}
					onTemplateSelected={this.onEmailMessageTemplateSelected}
					templates={templates}
				/>
			</div>
		);
	}

	private onBodyFieldScroll = () => {
		this.dismissPlaceholderPopover()();
		this.props.onBodyFieldScroll?.();
	};

	private dismissPlaceholderPopover =
		(updateState = true) =>
		() => {
			this.state.placeholderPopover?.mutationObserver?.disconnect();
			if (updateState) {
				this.setState({
					// @ts-ignore
					placeholderPopover: null,
				});
			}
		};

	private toggleShowingAdvancedSettings = (showingAdvancedSettings: boolean) => () => {
		this.setState({
			showingAdvancedSettings,
		});
	};

	private onEditorLoaded = () => {
		this.setState({
			editorLoaded: true,
		});
	};

	private onEditorExecuteCommand = (
		e: React.ChangeEvent<any> & {
			command: string;
			type: string;
			ui?: any;
			value?: any;
		}
	) => {
		if (!e.defaultPrevented) {
			const { logApiError, errorMessages } = this.props;
			if (e.command === 'levAddFileAttachement') {
				const { onAttachmentToolbarButtonClicked } = this.props;
				if (onAttachmentToolbarButtonClicked) {
					onAttachmentToolbarButtonClicked();
				}

				if (this.filesInputRef) {
					this.filesInputRef.click();
				}
			} else if (e.command === 'levError' && !!e.value) {
				const value: IRichContentDocumentEditorError = e.value;
				if (value.error) {
					// @ts-ignore
					logApiError(value.source === 'insert-image' ? 'ImageInsert-Error' : 'Editor-Error', value.error);
					if (e.ui) {
						// @ts-ignore
						errorMessages.pushApiError(value.error);
					}
				}
			} else if (e.command === levPlaceholderPopoverCommand) {
				const mouseEvent = e.value?.sourceEvent as MouseEvent;
				const documentBounds = e.value?.documentBounds as DOMRect;
				const anchor = mouseEvent?.target as HTMLElement;
				const placeholderValue = e.value?.placeholderValue;

				if (anchor) {
					const mutationObserver = new MutationObserver((mutations: MutationRecord[]) => {
						const mutation = mutations?.find(x => x.type === 'attributes' && x.attributeName === 'data-mce-selected');
						const mutationTarget = mutation?.target as HTMLElement;
						if (!mutationTarget?.getAttribute('data-mce-selected') || !mutationTarget?.parentElement) {
							mutationObserver.disconnect();
							this.setState({
								// @ts-ignore
								placeholderPopover: null,
							});
						}
					});
					mutationObserver.observe(anchor, {
						attributes: true,
						childList: false,
						subtree: false,
					});
					this.dismissPlaceholderPopover(false)();
					const anchorClient = anchor.getBoundingClientRect();
					const clientX = anchorClient.x + anchorClient.width;
					const clientY = anchorClient.y + anchorClient.height / 2;
					this.setState({
						placeholderPopover: {
							coordinate: isIE11()
								? { documentBounds, x: mouseEvent.x, y: mouseEvent.y }
								: { documentBounds, x: clientX, y: clientY },
							mutationObserver,
							placeholderValue,
						},
					});
				}
			} else if (e.command === levPlaceholderPopoverCloseCommand) {
				this.dismissPlaceholderPopover(true)();
			}
		}
	};

	private onEmailMessageTemplateSelected = (bodyTemplate: Api.ITemplate) => {
		const { onBodyTemplateChanged } = this.props;
		if (onBodyTemplateChanged) {
			onBodyTemplateChanged(bodyTemplate);
		} else {
			this.setState({
				bodyEditorState: convertRawRichTextContentStateToRichContentEditorState(bodyTemplate.content),
				bodyTemplate,
				subject: bodyTemplate ? bodyTemplate.subject : this.state.subject,
			});
		}
	};

	private onEmailSignatureSelected = (signatureTemplate: Api.ITemplate) => {
		const { onSignatureTemplateChanged } = this.props;
		// set the content of the signature field and notify the consumer
		if (onSignatureTemplateChanged) {
			onSignatureTemplateChanged(signatureTemplate);
		} else {
			this.setState({
				signatureTemplate,
			});
		}
	};

	private onRequestRemoveSavedEmailAttachment = async (savedAttachment: Api.IFileAttachment) => {
		const { onRequestRemoveSavedEmailAttachment, onCustomFileChanged } = this.props;
		if (onRequestRemoveSavedEmailAttachment) {
			await onRequestRemoveSavedEmailAttachment(savedAttachment);
		}
		if (this.props.emailComposer?.selectedCustomEmail) {
			// @ts-ignore
			onCustomFileChanged();
		}
	};

	private onFilesInputChanged = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		const { attachments, logEvent, emailComposer, onCustomFileChanged, errorMessages, userSession } = this.props;

		if (!!files && files.length > 0 && !!attachments && !emailComposer?.selectedCustomEmail) {
			const fileAttachments = Array.from(files);
			attachments.add(fileAttachments);
			// @ts-ignore
			e.target.value = null;

			// @ts-ignore
			logEvent('FilesAttached', { count: fileAttachments.length });
		}
		// Add attachments to the selected custom email
		if (emailComposer?.selectedCustomEmail) {
			let totalAttachmentSize = 0;
			// @ts-ignore
			if (emailComposer.selectedCustomEmail?.attachments?.length > 0) {
				// @ts-ignore
				totalAttachmentSize = emailComposer.selectedCustomEmail.attachments.reduce((totalSize, attachment) => {
					return totalSize + attachment.fileSize;
				}, 0);
			}

			const attachmentByteSizeLimit = Api.VmUtils.getMaxAttachmentByteCountForEmailConfigurations(
				// @ts-ignore
				// @ts-ignore
				userSession.account.toJs().emailProviderConfigurations
			);

			const filesThatExceedLimits: File[] = [];

			// @ts-ignore
			const filesToAdd = Array.from(files)
				.map(file => {
					if (totalAttachmentSize + file.size <= attachmentByteSizeLimit) {
						totalAttachmentSize += file.size;
						return file;
					} else {
						filesThatExceedLimits.push(file);
						return null;
					}
				})
				.filter(Boolean);

			if (filesThatExceedLimits.length > 0) {
				// @ts-ignore
				errorMessages.pushApiError(
					Api.asApiError(
						`Attachment${
							filesThatExceedLimits.length > 1 ? 's' : ''
						} exceed total file size limits: ${filesThatExceedLimits.reduce(
							(res, x, i) => `${res}${i < filesThatExceedLimits.length - 1 ? ', ' : ''}${x.name}`,
							''
						)}`
					)
				);
			}

			// @ts-ignore
			let promises: Promise<Api.IOperationResult<Api.IFileAttachment>>[] = null;
			if (filesToAdd.length > 0) {
				// @ts-ignore
				promises = filesToAdd.map(file => {
					const formData = new FormData();
					// @ts-ignore
					formData.append(`file`, file);
					// @ts-ignore
					return emailComposer.addEmailAttachmentToSelectedEmail(file);
				});
			}

			// Clear the file input ref
			if (this.filesInputRef) {
				// @ts-ignore
				this.filesInputRef.value = null;
			}

			// Wait for all promises to resolve before calling onCustomFileChanged
			if (promises) {
				await Promise.all(promises);
			}
			// @ts-ignore
			onCustomFileChanged();
		}
	};

	public onFilesInputRef = (ref?: HTMLInputElement) => {
		// @ts-ignore
		this.filesInputRef = ref;
	};

	private onCcRecipientsChanged = (recipients: Api.IRecipient[]) => {
		const { onCcRecipientsChanged } = this.props;
		if (onCcRecipientsChanged) {
			onCcRecipientsChanged(recipients);
		} else {
			this.setState({
				ccRecipients: recipients,
			});
		}
	};

	private onBodyFieldClicked = (e: React.MouseEvent<HTMLElement>) => {
		if (this.props.onBodyInputFieldClicked) {
			this.props.onBodyInputFieldClicked(e);
		}
	};

	private onBodyEditorStateChanged = (bodyEditorState: IRichContentEditorState) => {
		if (this.props.onBodyEditorStateChanged) {
			this.props.onBodyEditorStateChanged(bodyEditorState);
		} else {
			this.setState({
				bodyEditorState,
				bodyHasText: bodyEditorState.hasContent(),
			});
		}
	};

	private bodyEditorDidChangeFocus = (hasFocus: boolean) => () => {
		if (this.props.onBodyEditorFocusChanged) {
			this.props.onBodyEditorFocusChanged(hasFocus);
		}

		this.setState({
			bodyEditorHasFocus: hasFocus,
		});
	};

	private onSubjectChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		const subject = e.target.value;
		if (this.props.onSubjectChanged) {
			this.props.onSubjectChanged(subject);
		} else {
			this.setState({
				subject,
			});
		}
	};
}

const EmailComposerAsObserver = observer(_EmailComposer);
const EmailComposerWithContext = inject(
	UserSessionViewModelKey,
	ErrorMessagesViewModelKey,
	ImpersonationContextKey
)(EmailComposerAsObserver);
export const EmailComposer = withEventLogging(EmailComposerWithContext, 'EmailComposer');

const SimpleEmailComposerSfc: React.FC<
	IEmailComposerProps & {
		emailMessage?: Api.EmailMessageViewModel<File>;
		emailComposer?: ComposeEmailViewModel;
		styles?: StyleDeclarationValue[];
	}
> = props => {
	const { emailMessage, styles, advancedSettingsPopoverContent, subject, attachments, className, ...restProps } = props;

	const onBccToCrmCheckedChange = (checked: boolean) => {
		// @ts-ignore
		// @ts-ignore
		// @ts-ignore
		const bccEmail = props.userSession.user.userPreferences.bccEmail;
		if (bccEmail) {
			// @ts-ignore
			const bcc = [...(emailMessage.bcc || [])];
			const index = bcc.findIndex(x => x.email === bccEmail);
			if (checked) {
				if (index < 0) {
					bcc.push({
						email: bccEmail,
					});
				}
			} else {
				bcc.splice(index, 1);
			}
			// @ts-ignore
			emailMessage.bcc = bcc;
		}
	};

	const onCreateFollowUpReminderCheckedChanged = (checked: boolean) => {
		if (emailMessage?.options) {
			// @ts-ignore
			emailMessage.options.followUpIfNoResponseInDays = checked ? 7 : null;
		}
		// @ts-ignore
		props.logInput(`CreateFollowUpReminder:${checked ? 'Checked' : 'Unchecked'}`, 'Click');
	};

	const onFollowUpReminderDaysChanged = (days: number) => {
		const computedDays = Math.max(1, days);
		if (emailMessage?.options) {
			emailMessage.options.followUpIfNoResponseInDays = computedDays;
		}
	};

	const onNotePrivacyToggleChange = (checked: boolean) => {
		if (emailMessage?.options) {
			emailMessage.options.noteVisibility = checked ? 'all' : 'admin';
		}
	};

	const onSaveAsNoteCheckedChange = (checked: boolean) => {
		if (emailMessage?.options) {
			emailMessage.options.saveAsNote = checked;
		}
	};

	const [ccEmployee, setCcEmployee] = React.useState<Api.IUser>(
		// @ts-ignore
		emailMessage?.cc?.[0] ? { email: emailMessage.cc[0]?.email } : null
	);

	const onCcEmployeeSelected = React.useCallback((user?: Api.IUser) => {
		// @ts-ignore
		emailMessage.cc = user
			? [
					{
						email: user.primaryEmail?.value,
						id: user.id,
						name: {
							firstName: user.firstName,
							lastName: user.lastName,
						},
					},
				]
			: [];
		// @ts-ignore
		setCcEmployee(user);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onRenderEmailComposerOptions = () => {
		const { userSession } = props;
		if (!emailMessage) {
			return null;
		}
		// @ts-ignore
		// @ts-ignore
		// @ts-ignore
		const crmEmail = userSession.user.userPreferences.bccEmail;
		let lockedDueToSendOnBehalf = false;

		if (
			emailMessage.isSendFromOption(Api.SendEmailFrom.ContactOwner) ||
			emailMessage?.isSendFromOption(Api.SendEmailFrom.SelectedUser)
		) {
			if (emailMessage.options) {
				emailMessage.options.noteVisibility = 'all';
				emailMessage.options.saveAsNote = true;
			}
			lockedDueToSendOnBehalf = true;
		}

		return (
			<EmailComposerOptions
				bccToCrm={!!crmEmail && !!emailMessage.bcc.find(x => x.email === crmEmail)}
				ccEmployee={ccEmployee}
				// @ts-ignore
				createFollowUpReminder={emailMessage.options?.followUpIfNoResponseInDays > 0}
				followUpReminderDays={emailMessage.options?.followUpIfNoResponseInDays || 7}
				lockedDueToSendOnBehalf={lockedDueToSendOnBehalf}
				noteVisibility={emailMessage.options?.noteVisibility}
				onBccToCrmCheckedChange={onBccToCrmCheckedChange}
				onCcEmployeeSelected={onCcEmployeeSelected}
				onCreateFollowUpReminderCheckedChanged={onCreateFollowUpReminderCheckedChanged}
				onFollowUpReminderDaysChanged={onFollowUpReminderDaysChanged}
				onNotePrivacyToggleChange={onNotePrivacyToggleChange}
				onSaveAsNoteCheckedChange={onSaveAsNoteCheckedChange}
				saveAsNote={emailMessage.options?.saveAsNote}
				showBccToCrmOption={!!crmEmail}
				showFollowUpWarning={!!emailMessage.contactsFilterRequest || emailMessage.contactsToAdd.length > 1}
			/>
		);
	};
	return (
		<div className={`${className ?? ''} ${css(styleSheet.simpleEditor, ...(styles || []))}`}>
			<EmailComposer
				{...restProps}
				advancedSettingsPopoverContent={
					advancedSettingsPopoverContent ? advancedSettingsPopoverContent : onRenderEmailComposerOptions()
				}
				attachments={attachments || emailMessage?.attachments}
				className={`${css(styleSheet.simpleEditorContent)}`}
				subject={subject || emailMessage?.subject}
			/>
		</div>
	);
};

const SimpleEmailComposerAsObserver = observer(SimpleEmailComposerSfc);
const SimpleEmailComposerWithContext = inject(
	UserSessionViewModelKey,
	ImpersonationContextKey
)(SimpleEmailComposerAsObserver);
export const SimpleEmailComposer = withEventLogging(SimpleEmailComposerWithContext, 'SimpleEmailComposer');
