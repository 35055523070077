import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEventLogging } from '../../../../models/Logging';
import { LoadingSpinner } from '../../LoadingSpinner';
import { AutomationPreviewStep } from './presentation';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	/** Default = true */
	showPublished?: boolean;
	styles?: StyleDeclarationValue[];
	template?: Api.AutomationTemplateViewModel;
}

export const AutomationPreview: React.FC<IProps> = observer(props => {
	const { className, styles = [], template, showPublished = true } = props;
	const logger = useEventLogging('AutomationPreview');
	React.useEffect(() => {
		if (!!template && !template.allStepsLoaded) {
			logger.logEvent('TemplateLoad', { id: template.id });
			template.load()?.catch(error => {
				logger.logApiError('TemplateLoad-Error', error);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [template]);
	if (!template) {
		return null;
	}

	const steps =
		(showPublished ? template.publishedStepReferences : template.draftStepReferences) ||
		template.publishedStepReferences ||
		[];
	return (
		<div className={`${css(styleSheet.container, ...styles)} automation-preview ${className || ''}`}>
			{!!template?.isLoading && <LoadingSpinner type='large' />}
			{steps.map(step => {
				return (
					<AutomationPreviewStep
						automationTemplate={template}
						key={step.id}
						showPublished={showPublished}
						step={step}
						steps={steps}
					/>
				);
			})}
		</div>
	);
});
