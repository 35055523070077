import { StyleSheet } from 'aphrodite';
import { brandSecondary } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	automationTemplateSelectorAutomationIcon: {
		alignItems: 'center',
		background: brandSecondary,
		borderRadius: 15,
		display: 'flex',
		flexShrink: 0,
		height: 30,
		justifyContent: 'center',
		width: 30,
		...CssSelectors.allChildren(
			{
				height: 15,
				width: 15,
			},
			'.svg-icon'
		),
	},
	automationTemplateSelectorItem: {
		alignItems: 'center',
		display: 'flex',
	},
	container: {},
	dropdownAnchor: {
		alignItems: 'center',
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'space-between',
		padding: 10,
	},
	dropdownAnchorText: {
		maxWidth: 600,
	},
	dropdownIcon: {
		height: 16,
		marginLeft: 12,
		transform: 'rotate(90deg)',
		width: 10,
	},
	dropdownInline: {
		borderColor: '#CCCCCC',
		borderRadius: 3,
		borderStyle: 'solid',
		borderWidth: 1,
		boxSizing: 'border-box',
		maxWidth: 600,
		overflowY: 'visible',
		width: '100%',
	},
	dropdownItem: {
		':hover': {
			background: '#E8F3F9',
		},
		cursor: 'pointer',
		padding: 10,
	},
	dropdownMenu: {
		background: '#fff',
		border: '1px solid #E2E2E2',
		boxShadow: '0 0 6px 0 rgba(184,184,184,0.30)',
		marginTop: 21,
		width: '100% !important',
	},
});
