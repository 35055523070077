import { LoadingSpinner } from '../LoadingSpinner';
import DefaultPlaceholderUrl from './defaultPlaceholder.svg';
import './styles.less';
import * as React from 'react';

interface IProps {
	className?: string;
	isEmpty?: boolean;
	isLoading?: boolean;
	loaderType?: 'login' | 'large' | 'small';
	message?: string;
	placeholderIcon?: React.ReactNode;
	placeholderUrl?: string;
}

export const Placeholder: React.FC<IProps> = props => {
	if (!props.isLoading && !props.isEmpty) {
		return null;
	}

	const placeholderUrl = props.placeholderUrl || DefaultPlaceholderUrl;

	const emptyContent = props.children ? (
		props.children
	) : (
		<div className='placeholder-container-placeholder'>
			{props.placeholderIcon ? (
				<div className='placeholder-container-placeholder-icon'>{props.placeholderIcon}</div>
			) : (
				<img className='placeholder-container-placeholder-icon' src={placeholderUrl} />
			)}
			{!!props.message && <div className='placeholder-container-placeholder-message'>{props.message}</div>}
		</div>
	);

	return (
		<div className={`placeholder-container ${props.className || ''}`}>
			{!!props.isLoading && <LoadingSpinner type={props.loaderType || 'small'} />}
			{!!props.isEmpty && emptyContent}
		</div>
	);
};
