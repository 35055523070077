import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	link: {
		fontSize: 12,
		textDecoration: 'none',
	},
	metaData: {
		color: '#ADB1B3',
		fontSize: '12px',
	},
});
