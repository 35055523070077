import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	showGreenPlus?: boolean;
}

export const NewMessageIcon: React.FC<IProps> = props => {
	const { className, showGreenPlus } = props;
	return (
		<SvgIcon className={`new-message-icon ${className || ''}`} height={120} width={120}>
			<defs>
				<path
					id='b-a'
					d='M0,0 L18.6666667,0 L18.6666667,13.3333333 C18.6666667,18.487991 14.487991,22.6666667 9.33333333,22.6666667 L9.33333333,22.6666667 C4.17867567,22.6666667 6.31263501e-16,18.487991 0,13.3333333 L0,0 Z'
				/>
			</defs>
			<g fill='none' fillRule='evenodd'>
				<circle cx='60' cy='60' r='60' fill='#E8F3F9' />
				<path
					fill='#FFF'
					stroke='#00AAE8'
					strokeWidth='2'
					d='M23,85 L97,85 L97,36.6666667 C97,35.0098124 95.6568542,33.6666667 94,33.6666667 L26,33.6666667 C24.3431458,33.6666667 23,35.0098124 23,36.6666667 L23,85 Z'
				/>
				<path
					fill='#E8F3F9'
					stroke='#00AAE8'
					strokeWidth='2'
					d='M23,40.3333333 L97,40.3333333 L97,36.6666667 C97,35.0098124 95.6568542,33.6666667 94,33.6666667 L26,33.6666667 C24.3431458,33.6666667 23,35.0098124 23,36.6666667 L23,40.3333333 Z'
				/>
				<circle cx='26.333' cy='37' r='1' fill='#00AAE8' />
				<circle cx='29.667' cy='37' r='1' fill='#00AAE8' />
				<circle cx='33' cy='37' r='1' fill='#00AAE8' />
				<rect width='18.667' height='2' x='41.333' y='47.333' fill='#E8F3F9' rx='1' />
				<rect width='10' height='2' x='41.333' y='51.333' fill='#E8F3F9' rx='1' />
				<rect width='17.333' height='2' x='41.333' y='59.333' fill='#E8F3F9' rx='1' />
				<path
					fill='#E8F3F9'
					d='M42.3333333,71.3333333 L61.6666667,71.3333333 C62.2189514,71.3333333 62.6666667,71.7810486 62.6666667,72.3333333 L62.6666667,72.3333333 C62.6666667,72.8856181 62.2189514,73.3333333 61.6666667,73.3333333 L42.3333333,73.3333333 C41.7810486,73.3333333 41.3333333,72.8856181 41.3333333,72.3333333 L41.3333333,72.3333333 C41.3333333,71.7810486 41.7810486,71.3333333 42.3333333,71.3333333 Z'
				/>
				<rect width='23.333' height='2' x='41.333' y='63.333' fill='#E8F3F9' rx='1' />
				<rect width='13.333' height='2' x='41.333' y='75.333' fill='#E8F3F9' rx='1' />
				<rect width='18' height='1' x='19.667' y='85.667' stroke='#00AAE8' strokeWidth='2' />
				<rect width='18' height='1' x='80.333' y='93.667' stroke='#00AAE8' strokeWidth='2' />
				<path
					fill='#CAE0EC'
					stroke='#00AAE8'
					strokeWidth='2'
					d='M77.6666667 67L77.6666667 81.3333333C77.6666667 87.7766554 82.8900113 93 89.3333333 93 95.7766554 93 101 87.7766554 101 81.3333333L101 67 77.6666667 67zM101.333333 82L103.689833 81.7054375C105.390464 81.4928587 106.666667 80.0471986 106.666667 78.3333333L106.666667 76.3333333C106.666667 75.7810486 106.218951 75.3333333 105.666667 75.3333333L101.333333 75.3333333 101.333333 72 108 72C109.104569 72 110 72.8954305 110 74L110 78.6666667C110 82.348565 107.015232 85.3333333 103.333333 85.3333333L101.333333 85.3333333 101.333333 82z'
				/>
				<g transform='translate(80 67.333)'>
					<mask id='b-b' fill='#fff'>
						<use xlinkHref='#b-a' />
					</mask>
					<use fill='#E8F3F9' xlinkHref='#b-a' />
					<rect width='4' height='22.667' x='10' fill='#F2FAFF' mask='url(#b-b)' />
				</g>
				<path
					stroke='#00AAE8'
					strokeWidth='2'
					d='M90.6666667,52 C89.3333333,52.8665731 88.6666667,53.9776842 88.6666667,55.3333333 C88.6666667,57.2998597 90.4848485,58 90.6666667,60 C90.7676768,61.1111111 90.1010101,62.4444444 88.6666667,64'
				/>
				{!!showGreenPlus && (
					<g fill='none' fillRule='evenodd' transform='translate(72 .5)'>
						<ellipse cx='24' cy='24' fill='#89C947' rx='24' ry='23' />
						<path
							fill='#FFF'
							d='M24.4229911,13 C23.8737883,13 23.4285714,13.4547596 23.4285714,14.0090223 L23.4285714,22.4285714 L15.0090223,22.4285714 C14.4517547,22.4285714 14,22.8788662 14,23.4229911 L14,24.5770089 C14,25.1262117 14.4547596,25.5714286 15.0090223,25.5714286 L23.4285714,25.5714286 L23.4285714,33.9909777 C23.4285714,34.5482453 23.8788662,35 24.4229911,35 L25.5770089,35 C26.1262117,35 26.5714286,34.5452404 26.5714286,33.9909777 L26.5714286,25.5714286 L34.9909777,25.5714286 C35.5482453,25.5714286 36,25.1211338 36,24.5770089 L36,23.4229911 C36,22.8737883 35.5452404,22.4285714 34.9909777,22.4285714 L26.5714286,22.4285714 L26.5714286,14.0090223 C26.5714286,13.4517547 26.1211338,13 25.5770089,13 L24.4229911,13 Z'
						/>
					</g>
				)}
			</g>
		</SvgIcon>
	);
};
