import { brandPrimary, brandSecondary, brandSecondaryActive, brandSecondaryHover } from '../../styles/colors';
import { ctaButtonBase } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	button: {
		...ctaButtonBase,
		alignItems: 'center',
		borderBottomRightRadius: 0,
		borderTopRightRadius: 0,
		display: 'flex',
		height: '100%',
		minWidth: 90,
		padding: '10px 20px',
	},
	container: {
		alignItems: 'center',
		display: 'flex',
		position: 'relative',
	},
	divider: {
		boxShadow: '0 1px 2px 0 rgba(140,140,140,0.50)',
		height: '100%',
		width: 1,
	},
	optionsTrigger: {
		...ctaButtonBase,
		alignItems: 'center',
		borderBottomLeftRadius: 0,
		borderTopLeftRadius: 0,
		display: 'flex',
		height: '100%',
		justifyConent: 'center',
		minWidth: 'auto',
		padding: '0px 10px',
	},
	optionsTriggerIcon: {
		transform: 'rotateZ(90deg)',
	},
	popupContent: {
		left: 0,
		position: 'absolute',
		top: 0,
		transform: 'translateY(-100%)',
		width: 'auto',
		zIndex: 1,
	},
	popupContentDown: {
		top: '100%',
		transform: 'translateY(0%)',
	},
	typeReverse: {
		':active': {
			background: '#E8F3F9',
		},
		':hover': {
			background: '#F3F8FB',
		},
		background: '#fff',
		border: `1px solid ${brandPrimary}`,
		boxShadow: '0 1px 2px 0 #f9f9f9',
		color: '#8E8E8E',
	},
	typeSecondary: {
		':active': {
			background: brandSecondaryActive,
		},
		':hover': {
			background: brandSecondaryHover,
		},
		background: brandSecondary,
	},
});
