import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { KeyboardEvent, useCallback, useEffect, useRef } from 'react';
import { getDisplayName } from '../../../extViewmodels/Utils';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../models';
import { isIE11 } from '../../../models/Browser';
import { getContactCompanyLine } from '../../../models/UiUtils';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { useInput } from '../../../models/hooks/inputHooks';
import { useLambda } from '../../../models/hooks/useLambda';
import {
	IAccountTag,
	IContact,
	ResourceAutoCompleteViewModel,
	ResourceAutoCompleteViewModelType,
} from '../../../viewmodels/AppViewModels';
import { LoadingSpinner } from '../LoadingSpinner';
import { SearchBase } from '../SearchBase';
import { AssigneeIcon } from '../svgs/icons/AssigneeIcon';
import { TagIcon } from '../svgs/icons/TagIcon';
import { styleSheet } from './styles';

interface IProps extends IImpersonationContextComponentProps {
	anchorStyles?: StyleDeclarationValue[];
	id?: string;
	/** If not included, contacts will not be included in the results */
	onContactSelected?: (contact: IContact) => void;
	/** If not included, tags will not be included in the results */
	onTagSelected?: (tag: IAccountTag) => void;
	resultsStyles?: StyleDeclarationValue[];
}

const pageSize = 5;
const autoCompleteDebounce = isIE11() ? 300 : 50;

export const GlobalSearchBase = ({
	anchorStyles = [],
	id = 'global-search',
	impersonationContext,
	onContactSelected,
	onTagSelected,
	resultsStyles = [],
}: IProps) => {
	const userSession = useUserSession();
	const [inputValue, setInputValue, onInputChange] = useInput('');
	const [highlight, setHighlight] = useLambda(-1, []);
	let inputPlaceholder = 'Search tags or contacts';
	if (!onContactSelected) {
		inputPlaceholder = 'Search tags';
	} else if (!onTagSelected) {
		inputPlaceholder = 'Search contacts';
	}

	const contacts = useRef(
		new ResourceAutoCompleteViewModel(userSession, {
			pageSize,
			type: ResourceAutoCompleteViewModelType.Contact,
		}).impersonate(impersonationContext)
	).current;

	const tags = useRef(
		new ResourceAutoCompleteViewModel(userSession, {
			pageSize,
			type: ResourceAutoCompleteViewModelType.AccountTag,
		}).impersonate(impersonationContext)
	).current;

	useEffect(() => {
		setHighlight(-1);
		if (inputValue) {
			if (onContactSelected) {
				contacts.setSearchQuery(inputValue, autoCompleteDebounce);
			}

			if (onTagSelected) {
				tags.setSearchQuery(inputValue, autoCompleteDebounce);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputValue, onContactSelected, onTagSelected]);

	const getTotalResultsCount = React.useCallback(() => {
		return (contacts.searchResults?.length ?? 0) + (tags.searchResults?.length ?? 0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contacts.searchResults, contacts.searchResults]);

	const onContactClick = React.useCallback(
		(contact: IContact) => () => {
			// @ts-ignore
			onContactSelected(contact);
			setInputValue('');
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onContactSelected]
	);

	const onTagClick = React.useCallback(
		(tag: IAccountTag) => () => {
			// @ts-ignore
			onTagSelected(tag);
			setInputValue('');
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onTagSelected]
	);

	const onKeyDown = React.useCallback(
		(e: KeyboardEvent<HTMLInputElement>) => {
			const total = getTotalResultsCount();
			if (e.key === 'Enter') {
				if (inputValue !== '' && highlight >= 0) {
					if (highlight < contacts.searchResults.length) {
						onContactClick(contacts.searchResults[highlight] as IContact)();
					} else if (highlight < tags.searchResults.length + contacts.searchResults.length) {
						onTagClick(tags.searchResults[highlight - contacts.searchResults.length] as IAccountTag)();
					}
					setHighlight(-1);
					setInputValue('');
					return;
				}

				if (highlight === -1) {
					return;
				}

				setInputValue('');
				setHighlight(-1);
			} else if (e.key === 'ArrowDown') {
				if (highlight + 1 < total) {
					setHighlight(highlight + 1);
				}
			} else if (e.key === 'ArrowUp') {
				if (highlight - 1 > -1) {
					setHighlight(highlight - 1);
				}
			} else {
				// set back to the input
				setHighlight(-1);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[getTotalResultsCount, onContactClick, onTagClick, highlight]
	);

	const onRequestClose = () => {
		setInputValue('');
	};

	const renderNoResults = (icon: JSX.Element) => {
		return (
			<div className={css(styleSheet.resultItem)}>
				{icon}
				<div className={css(styleSheet.resultContentContainer)}>
					<span className='noResults'>{`No results matching "${inputValue}"`}.</span>
				</div>
			</div>
		);
	};

	const renderResults = useCallback(() => {
		let contactsResults: JSX.Element;
		let tagsResults: JSX.Element;

		if (onContactSelected) {
			contactsResults = (
				<div>
					<div className={css(styleSheet.title)}>CONTACTS</div>
					{contacts.isFetchingResults ? (
						<div className={css(styleSheet.loadingSpinnerContainer)}>
							<LoadingSpinner type='tiny' />
						</div>
					) : contacts.searchResults.length === 0 ? (
						renderNoResults(<AssigneeIcon />)
					) : (
						// @ts-ignore
						contacts.searchResults.map((c: IContact, i) => (
							<div
								className={css(styleSheet.resultItem, i === highlight && styleSheet.highlightContact)}
								key={c.id}
								onClick={onContactClick(c)}
							>
								<AssigneeIcon />
								<div className={css(styleSheet.resultContentContainer)}>
									<span>{getDisplayName(c)}</span>
									<span>{getContactCompanyLine(c)}</span>
								</div>
							</div>
						))
					)}
				</div>
			);
		}

		if (onTagSelected) {
			tagsResults = (
				<div>
					<div className={css(styleSheet.title)}>TAGS</div>
					{tags.isFetchingResults ? (
						<div className={css(styleSheet.loadingSpinnerContainer)}>
							<LoadingSpinner type='tiny' />
						</div>
					) : tags.searchResults.length === 0 ? (
						renderNoResults(<TagIcon />)
					) : (
						// @ts-ignore
						tags.searchResults.map((t: IAccountTag, i) => (
							<div
								className={css(
									styleSheet.resultItem,
									i + contacts.searchResults.length === highlight && styleSheet.highlightTag
								)}
								key={t.id}
								onClick={onTagClick(t)}
							>
								<TagIcon />
								<div className={`tag ${css(styleSheet.tag)}`}>{t.tag}</div>
							</div>
						))
					)}
				</div>
			);
		}

		return (
			<div>
				{/* @ts-ignore */}
				{contactsResults}
				{/* @ts-ignore */}
				{tagsResults}
			</div>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contacts.isFetchingResults, contacts.searchResults, tags.isFetchingResults, tags.searchResults, highlight]);

	return (
		<div>
			<SearchBase
				anchorStyles={anchorStyles}
				forceClose={!inputValue}
				id={id}
				onChange={onInputChange}
				onKeyDown={onKeyDown}
				onRequestClose={onRequestClose}
				placeholder={inputPlaceholder}
				renderResults={renderResults}
				value={inputValue}
				resultsStyles={resultsStyles}
			/>
		</div>
	);
};

const GlobalSearchAsObserver = observer(GlobalSearchBase);
export const GlobalSearch = inject(ImpersonationContextKey)(GlobalSearchAsObserver);
