import { IGif } from '@giphy/js-types';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject } from 'mobx-react';
import * as React from 'react';
import * as tinymce from 'tinymce';
import {
	IImpersonationContextComponentProps,
	IRichContentDocumentEditorImageOptions,
	IRichContentDocumentEditorPlaceholder,
	ImpersonationContextKey,
	disableFileAttachmentCommand,
	enableFileAttachmentCommand,
	levInsertGiphyCommand,
} from '../../../../models';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../models/AppState';
import { isIE11 } from '../../../../models/Browser';
import { FirstNamePlaceholder, GetDefaultEmailPlaceholders, Token } from '../../../../models/Token';
import { ToolbarSignature, createContentStateWithHtmlStringValue } from '../../../../models/UiUtils';
import { useContactCustomFields } from '../../../../queries';
import {
	IOperationResultNoValue,
	IRichContentEditorState,
	IRichContentEditorStateAttributes,
	getContentHtmlStringValueFromRawRichTextContentState,
} from '../../../../viewmodels/AppViewModels';
import { LoadingSpinner } from '../../LoadingSpinner';
import { GiphyChooserModal } from '../GiphyChooser';
import { PlaceholdersPlugin } from './plugins';
import { styleSheet } from './styles';
import './styles.less';

const LazyHtmlEditor = React.lazy(() => import(/* webpackPrefetch: true */ './HtmlEditor'));

interface IWordCountProvider {
	getCharacterCount(): number;
	getCharacterCountWithoutSpaces(): number;
	getWordCount(): number;
}

export interface IWordCountPlugin {
	body: IWordCountProvider;
	selection: IWordCountProvider;
}

export interface IRichContentDocumentEditorConfig {
	autoresizeContentBottomPadding?: number;
	autoresizeToFitContent?: boolean;
	contentHorizontalPadding?: number;
	contentRawCss?: string;
	defaultBlockStyleAttributeStringValue?: string;
	imageOptions?: IRichContentDocumentEditorImageOptions;
	maxHeight?: number;
	minHeight?: number;
	/**
	 * this overrides the useDefaultEmailPlaceholders, if provided
	 */
	placeholders?: IRichContentDocumentEditorPlaceholder[];
	plugins?: string | string[];
	toolbar?: boolean | string | string[];
	toolbarOverflow?: 'floating' | 'sliding';
	useDefaultEmailPlaceholders?: boolean;
	validStyles?: string | Record<string, string>;
}

export interface IRichContentDocumentEditorProps
	extends IUserSessionComponentProps,
		IImpersonationContextComponentProps {
	autoFocus?: boolean;
	className?: string;
	collapsible?: boolean;
	config?: IRichContentDocumentEditorConfig;
	contentClassName?: string;
	contentPlaceholderText?: string;
	contentState: IRichContentEditorState;
	contentStyles?: StyleDeclarationValue[];
	disableFileAttachment?: boolean;
	disablePasteRichText?: boolean;
	loadingPlaceholder?: React.ReactNode;
	onBlur?(
		e: tinymce.EditorEvent<{
			focusedEditor: tinymce.Editor;
		}>
	): void;
	onContentStateChanged?(content: IRichContentEditorState): void;
	onExecuteCommand?(
		e: React.ChangeEvent<any> & {
			command: string;
			type: string;
			ui?: any;
			value?: any;
		}
	): void;
	onFocus?(
		e: tinymce.EditorEvent<{
			blurredEditor: tinymce.Editor;
		}>
	): void;
	onLoad?(editor: tinymce.Editor, contentState: IRichContentEditorState): void;
	readOnly?: boolean;
	readonlyChildren?: React.ReactNode;
	readOnlyUseFullEditor?: boolean;
	showAsPlainTextPreview?: boolean;
	showFirstNameTokenByDefault?: boolean;
	styles?: StyleDeclarationValue[];
}

export const DefaultRichContentEditorToolbarConfig = [ToolbarSignature];

export const DefaultRichContentEditorPlugins = ['lists', 'link', 'paste', 'wordcount'];

export interface IRichContentDocumentEditorError {
	error?: IOperationResultNoValue;
	source?: string;
}

interface IHtmlEditorSettings {
	autoresize_bottom_margin?: number;
	autoresize_overflow_padding?: number;
	content_style?: string;
	font_formats?: string;
	forced_root_block_attrs?: { style?: string };
	max_height?: number;
	max_width?: number;
	min_height?: number;
	min_width?: number;
	placeholders?: IRichContentDocumentEditorPlaceholder[];
	plugins?: string | string[];
	toolbar_drawer?: 'floating' | 'sliding';
	toolbar?: boolean | string | string[];
	valid_styles?: string | Record<string, string>;
}

const getContentAttributes = (editorRef: tinymce.Editor): IRichContentEditorStateAttributes => {
	const counter = editorRef?.plugins?.wordcount;
	if (counter) {
		return {
			document: {
				characterCount: counter.body.getCharacterCount(),
				wordCount: counter.body.getWordCount(),
			},
			selection: {
				characterCount: counter.selection.getCharacterCount(),
				wordCount: counter.selection.getWordCount(),
			},
		};
	}
	return {};
};

const getCurrentContentState = (
	contentHtmlStringValue: string,
	editorRef: tinymce.Editor,
	clearContentCallback?: () => void
) => {
	const content = createContentStateWithHtmlStringValue(contentHtmlStringValue || '', clearContentCallback);
	if (editorRef) {
		const plainTextContent = editorRef.getContent({
			format: 'text',
		}) as string;
		content.getPlainTextPreview = (truncate = true, terminator?: string) => {
			if (!truncate) {
				return `${plainTextContent || ''}${!!plainTextContent && !!terminator ? terminator : ''}`;
			}
			return `${(plainTextContent || '').substring(0, 100)}${!!plainTextContent && !!terminator ? terminator : ''}`;
		};

		const attributes = getContentAttributes(editorRef);
		if (attributes) {
			content.attributes = attributes;
		}
	}
	return content;
};

const resizeIE11Toolbar = (rootElement: HTMLDivElement) => {
	if (rootElement) {
		const toolbar =
			rootElement.querySelector('.tox.tox-tinymce .tox-editor-container .tox-editor-header') ||
			rootElement.querySelector('.tox.tox-tinymce .tox-editor-container .tox-editor-header');
		const targetWidth = rootElement.clientWidth - 2;
		if (!!toolbar && toolbar.clientWidth !== targetWidth) {
			toolbar.setAttribute('style', `width: ${targetWidth}px`);
		}
	}
};

function RichContentDocumentEditorBase(props: IRichContentDocumentEditorProps) {
	const {
		readOnly,
		className,
		styles,
		contentState,
		showAsPlainTextPreview,
		showFirstNameTokenByDefault,
		disableFileAttachment = false,
		readOnlyUseFullEditor,
		contentStyles,
		readonlyChildren,
		contentClassName,
		onContentStateChanged,
		autoFocus,
		contentPlaceholderText,
		disablePasteRichText = false,
		config,
		onLoad: onLoadProp,
		impersonationContext,
		onFocus,
		onBlur,
		userSession,
	} = props;
	const contentHtmlStringValueRef = React.useRef<string>();
	const contentHtmlStringValue = React.useMemo(() => {
		let value = contentState
			? (showAsPlainTextPreview
					? contentState.getPlainTextPreview(true, '...')
					: getContentHtmlStringValueFromRawRichTextContentState(contentState.getRawRichTextContent())) || ''
			: '';
		if (!contentHtmlStringValueRef.current && !value && showFirstNameTokenByDefault) {
			const token = new Token(FirstNamePlaceholder);
			value = `Hi ${token.toHTMLString()},`;
		}
		return value;
	}, [contentState, showAsPlainTextPreview, showFirstNameTokenByDefault]);

	const [loadingEditor, setLoadingEditor] = React.useState<boolean>(true);
	const [showGiphyChooser, setShowGiphyChooser] = React.useState<boolean>(false);

	const editorRef = React.useRef<tinymce.Editor>();
	const rootElementRef = React.useRef<HTMLDivElement>(null);

	const account = impersonationContext?.account || userSession.account;
	const hasCustomContactFields = account?.additionalInfo?.hasCustomContactFields;
	const customFieldsQuery = useContactCustomFields({
		impersonationContext,
		refetchOnWindowFocus: false,
		enabled: hasCustomContactFields,
	});

	const editorConfig = React.useMemo(() => {
		const settings: IHtmlEditorSettings = {
			autoresize_bottom_margin: 10,
			// @see https://www.tiny.cloud/docs/configure/editor-appearance/#font_formats
			font_formats: `'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva;`,
			plugins: [...DefaultRichContentEditorPlugins],
			toolbar: [...DefaultRichContentEditorToolbarConfig],
		};

		// handle initial config
		if (config) {
			if (config.plugins) {
				if (typeof config.plugins === 'string') {
					(settings.plugins as string[]).push(config.plugins as string);
				} else {
					settings.plugins = [...(settings.plugins as string[]), ...(config.plugins as string[])];
				}
			}

			if (config.placeholders) {
				settings.placeholders = config.placeholders;
			} else if (config.useDefaultEmailPlaceholders) {
				settings.placeholders = [
					...GetDefaultEmailPlaceholders(userSession, impersonationContext),
					...PlaceholdersPlugin.getCustomFieldPlaceholders(customFieldsQuery.data?.fields),
				];
			}
			if (config.toolbar !== undefined && config.toolbar !== null) {
				settings.toolbar = config.toolbar;
			}

			if (config.contentRawCss) {
				settings.content_style = config.contentRawCss;
			}

			if (config.defaultBlockStyleAttributeStringValue) {
				settings.forced_root_block_attrs = {
					style: config.defaultBlockStyleAttributeStringValue,
				};
			}

			if (config.autoresizeToFitContent) {
				const plugins = settings.plugins
					? typeof settings.plugins === 'string'
						? settings.plugins || ''
						: (settings.plugins || []).join(' ')
					: '';
				settings.plugins = `${plugins.trim()} autoresize noneditable importcss`;
			}

			if (config.minHeight !== undefined && config.minHeight !== null) {
				settings.min_height = config.minHeight;
			}

			if (config.maxHeight !== undefined && config.maxHeight !== null) {
				settings.max_height = config.maxHeight;
			}

			if (config.contentHorizontalPadding !== undefined && config.contentHorizontalPadding !== null) {
				settings.autoresize_overflow_padding = config.contentHorizontalPadding;
			}

			if (config.autoresizeContentBottomPadding !== undefined && config.autoresizeContentBottomPadding !== null) {
				settings.autoresize_bottom_margin = config.autoresizeContentBottomPadding;
			}

			if (config.toolbarOverflow !== undefined && config.toolbarOverflow !== null) {
				settings.toolbar_drawer = config.toolbarOverflow;
			}

			if (config.contentRawCss) {
				settings.content_style = config.contentRawCss;
			}

			if (config.validStyles) {
				settings.valid_styles = config.validStyles;
			}
		}
		return settings;
	}, [config, customFieldsQuery.data?.fields, impersonationContext, userSession]);

	const onReadOnlyContentMouseOver = (e: React.MouseEvent<HTMLElement>) => {
		const el = e?.target as HTMLElement;
		if (!!config?.placeholders && el.getAttribute('data-placeholder') === 'true') {
			const dataValue = el.getAttribute('data-placeholder-value');
			const placeholder = config.placeholders?.find(x => x.value === dataValue);
			if (placeholder?.onClickCommand) {
				onExecuteCommand?.({
					...e,
					command: placeholder.onClickCommand,
					value: { sourceEvent: e, placeholderValue: placeholder.value },
				});
			}
		}
	};

	const onReadOnlyContentMouseOut = (e: React.MouseEvent<HTMLElement>) => {
		const el = e?.target as HTMLElement;
		if (!!config?.placeholders && el.getAttribute('data-placeholder') === 'true') {
			const dataValue = el.getAttribute('data-placeholder-value');
			const placeholder = config.placeholders?.find(x => x.value === dataValue);
			if (placeholder?.onCloseCommand) {
				onExecuteCommand?.({
					...e,
					command: placeholder.onCloseCommand,
					value: { sourceEvent: e },
				});
			}
		}
	};

	const onEditorChangedRef = React.useRef<(htmlStringValue: string, editorRef?: tinymce.Editor) => void>();
	const clearContent = React.useCallback(() => {
		onEditorChangedRef.current?.('', null);
	}, []);
	const onEditorChanged = React.useCallback(
		(htmlStringValue: string, editor?: tinymce.Editor) => {
			if (htmlStringValue !== contentHtmlStringValueRef.current && onContentStateChanged) {
				const content = getCurrentContentState(htmlStringValue, editor, clearContent);
				onContentStateChanged(content);
			}
		},
		[clearContent, onContentStateChanged]
	);

	const onExecuteCommand = (
		e: React.ChangeEvent<any> & {
			command: string;
			type: string;
			ui?: any;
			value?: any;
		}
	) => {
		const { onExecuteCommand: onExecuteCommandProps } = props;
		onExecuteCommandProps?.(e);

		if (!e.defaultPrevented && e.command === levInsertGiphyCommand) {
			setShowGiphyChooser(true);
		}
	};

	const onGiphyChooserRequestClose = (gif?: IGif, cancel?: boolean) => {
		setShowGiphyChooser(false);
		if (!!gif && !cancel && !!editorRef.current) {
			// try to constrain the width to something sane
			const width = Math.min(
				gif.images.original.width,
				rootElementRef.current ? rootElementRef.current.clientWidth - 40 : 300
			);
			editorRef.current.insertContent(
				`<img title="${gif.title || ''}" src="${gif.images.original.url}" width="${width}px"/>`
			);
			onEditorChanged(editorRef.current.getContent() as string, editorRef.current);
		}
	};

	const onLoad = (editor: tinymce.Editor) => {
		editorRef.current = editor;
		setLoadingEditor(false);
	};

	React.useEffect(() => {
		const handler = () => {
			resizeIE11Toolbar(rootElementRef.current);
		};
		if (isIE11()) {
			window.addEventListener('resize', handler);
		}
		return () => {
			if (isIE11()) {
				window.removeEventListener('resize', handler);
			}
		};
	}, []);

	React.useEffect(() => {
		editorRef.current?.execCommand(disableFileAttachment ? disableFileAttachmentCommand : enableFileAttachmentCommand);
	}, [disableFileAttachment]);

	React.useEffect(() => {
		if (!loadingEditor) {
			if (isIE11()) {
				resizeIE11Toolbar(rootElementRef.current);
			}
			if (onLoadProp) {
				requestAnimationFrame(() => {
					onLoadProp(
						editorRef.current,
						getCurrentContentState(contentHtmlStringValueRef.current, editorRef.current, clearContent)
					);
				});
			}
		}
	}, [clearContent, loadingEditor, onLoadProp]);

	React.useEffect(() => {
		onEditorChangedRef.current = onEditorChanged;
		contentHtmlStringValueRef.current = contentHtmlStringValue;
	});

	const renderLoading = () => {
		const { loadingPlaceholder } = props;
		if (loadingPlaceholder) {
			return loadingPlaceholder;
		}

		return <LoadingSpinner className='absolute-center' type='small' />;
	};

	return (
		<div
			className={`${css(styleSheet.container, ...(styles || []))} rich-content-document-editor ${className || ''}`}
			ref={!readOnly ? rootElementRef : undefined}
		>
			{readOnly && !readOnlyUseFullEditor ? (
				<>
					<div
						className={`${css(
							styleSheet.readOnlyContent,
							...(contentStyles || [])
						)} rich-content-document-editor-readonly ${contentClassName || ''}`}
						dangerouslySetInnerHTML={{ __html: contentHtmlStringValue }}
						onMouseOver={onReadOnlyContentMouseOver}
						onMouseOut={onReadOnlyContentMouseOut}
					/>
					{readonlyChildren}
				</>
			) : (
				<>
					{customFieldsQuery.isLoading && hasCustomContactFields ? null : (
						<React.Suspense fallback={null}>
							<LazyHtmlEditor
								autoFocus={autoFocus}
								contentPlaceholderText={contentPlaceholderText}
								disablePasteRichText={disablePasteRichText}
								imageOptions={config?.imageOptions}
								init={editorConfig}
								onBlur={onBlur}
								onEditorChanged={onEditorChanged}
								onExecuteCommand={onExecuteCommand}
								onFocus={onFocus}
								onLoad={onLoad}
								readOnly={readOnly}
								userSession={userSession}
								value={contentHtmlStringValue || ''}
							/>
						</React.Suspense>
					)}

					{loadingEditor ? (
						renderLoading()
					) : (
						<GiphyChooserModal
							modalProps={{
								isOpen: showGiphyChooser,
								onRequestClose: onGiphyChooserRequestClose,
							}}
						/>
					)}
				</>
			)}
		</div>
	);
}

export const RichContentDocumentEditor = inject(
	UserSessionViewModelKey,
	ImpersonationContextKey
)(RichContentDocumentEditorBase);
