import { StyleSheet } from 'aphrodite';
import { brandSecondary } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { TagCssProps } from '../Tag/styles';

export const styleSheet = StyleSheet.create({
	container: {
		...TagCssProps,
		alignItems: 'center',
		background: '#F2F2F2',
		color: brandSecondary,
		display: 'flex',
		justifyContent: 'center',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
});
