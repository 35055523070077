import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fill?: string;
}

export const LogoutIcon: React.FC<IProps> = ({ className, fill = '#fff' }) => {
	const height = 20;
	const width = 20;
	return (
		<SvgIcon className={className} height={height} viewBox={`0 0 ${width} ${height}`} width={width}>
			<g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' transform='translate(-59.000000, -513.000000)'>
				<g fill={fill}>
					<g transform='translate(59.000000, 513.000000)'>
						<polygon points='10.7065 5.292 9.2935 6.708 11.5905 9 0.0005 9 0.0005 11 11.5815 11 9.2925 13.294 10.7085 14.706 15.4145 9.991' />
						<path d='M10,0 C5.531,0 1.739,2.948 0.461,7 L2.589,7 C3.779,4.073 6.65,2 10,2 C14.411,2 18,5.589 18,10 C18,14.411 14.411,18 10,18 C6.65,18 3.779,15.928 2.589,13 L0.461,13 C1.739,17.052 5.531,20 10,20 C15.514,20 20,15.514 20,10 C20,4.486 15.514,0 10,0' />
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
