import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

const removeButtonLeftMargin = 10;
const removeButtonSize = 12;
const removeButtonPadding = 4;

export const styleSheet = StyleSheet.create({
	card: {
		width: '100%',
	},
	container: {
		alignItems: 'flex-start',
		display: 'flex',
		...CssSelectors.allChildren({
			':not(:first-child)': {
				marginLeft: 10,
			},
		}),
	},
	containerWithRemoveButton: {
		width: `calc(100% - ${removeButtonSize + removeButtonLeftMargin + removeButtonPadding})`,
	},
	content: {
		alignItems: 'flex-start',
		display: 'flex',
		width: '100%',
	},
	editor: {
		width: '100%',
	},
	info: {
		width: `calc(100% - ${46 + removeButtonLeftMargin}px)`,
	},
	infoReadOnly: {
		width: '100%',
	},
	loading: {
		height: 19,
		width: 19,
	},
	removeButton: {
		height: removeButtonSize,
		margin: '20px 0 0 0',
		padding: removeButtonPadding,
		width: removeButtonSize,
	},
	rightAccessory: {
		alignItems: 'center',
		display: 'flex',
		marginLeft: removeButtonLeftMargin,
		...CssSelectors.allChildren({
			':not(:first-child)': {
				marginLeft: 10,
			},
			alignItems: 'center',
			display: 'flex',
		}),
	},
	sendButton: {
		marginTop: 20,
	},
});
