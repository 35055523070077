import { titles } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		boxSizing: 'border-box',
	},
	history: {
		margin: '25px 20px 0 20px',
	},
	historyHeader: {
		color: titles,
		fontSize: 16,
		lineHeight: '22px',
	},
	historyItem: {
		borderBottom: 'none',
		padding: '10px 0',
	},
	historyItems: {
		marginTop: 20,
	},
	upgradeHeader: {
		margin: '20px 20px 0 20px',
	},
	upgradeHeaderCompact: {
		margin: 0,
	},
});
