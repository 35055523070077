import { brandSecondary } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fill?: string;
}

export const PaperPlaneIcon: React.FC<IProps> = ({ className, fill = brandSecondary }) => {
	return (
		<SvgIcon className={`paper-plane-icon ${className}`} height={21} width={23}>
			<path
				fill={fill}
				fillRule='evenodd'
				d='M8.8618,14.2554 C4.9198,16.0144 2.7928,16.9634 2.0348,17.0954 L5.6938,10.9994 L16.2088,10.9994 C13.1878,12.3264 10.7758,13.4014 8.8618,14.2554 L8.8618,14.2554 Z M2.0838,2.9144 C2.8838,3.0784 5.0128,4.0284 8.8908,5.7574 C10.8008,6.6094 13.2028,7.6794 16.2088,8.9994 L5.6938,8.9994 L5.6928,8.9994 L2.0838,2.9144 Z M21.3978,9.0814 C16.2268,6.8384 12.4628,5.1604 9.7058,3.9314 C4.3288,1.5324 2.8838,0.8884 1.8758,0.8884 C1.0118,0.8884 0.5868,1.4124 0.4478,1.5834 C-0.0812,2.2354 -0.1462,3.1554 0.2848,3.8734 L3.9608,10.0004 L0.2858,16.1244 C-0.1462,16.8434 -0.0812,17.7634 0.4478,18.4144 C0.5868,18.5874 1.0128,19.1124 1.8768,19.1124 L1.8778,19.1124 C2.8838,19.1124 4.3228,18.4694 9.6768,16.0814 C12.4358,14.8504 16.2078,13.1674 21.3978,10.9174 C21.7638,10.7584 21.9998,10.3974 21.9998,9.9994 C21.9998,9.6004 21.7638,9.2404 21.3978,9.0814 L21.3978,9.0814 Z'
				transform='rotate(-40 9.31 4.944)'
			/>
		</SvgIcon>
	);
};
