import { StyleSheet } from 'aphrodite';
import { brandPrimaryHover } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

const disclosureIconSize = 8;
const toolbarHeight = 39;

export const styleSheet = StyleSheet.create({
	addNewButton: {
		whiteSpace: 'nowrap',
		width: 160,
	},
	anchor: {
		alignItems: 'center',
		display: 'flex',
		height: '100%',
		padding: '0 0 0 10px',
		width: '100%',
	},
	anchorDisclosureIcon: {
		height: disclosureIconSize,
		marginLeft: 10,
		transform: 'rotateZ(90deg)',
		width: disclosureIconSize,
	},
	anchorText: {
		color: '#858585',
		fontSize: 12,
		lineHeight: '22px',
		width: `calc(100% - ${disclosureIconSize}px - 20px)`,
	},
	container: {
		maxWidth: 800,
	},
	dropdownContainer: {
		alignItems: 'center',
		display: 'flex',
		height: toolbarHeight,
		marginRight: 15,
		width: '100%',
		...CssSelectors.allChildren(
			{
				height: '100%',
				width: '100%',
				...CssSelectors.allChildren(
					{
						height: '100%',
						width: '100%',
					},
					'.dropdown-anchor'
				),
			},
			'.dropdown'
		),
	},
	dropdownContainerNarrow: {
		width: 'auto',
	},
	dropdownInline: {
		borderColor: '#CCCCCC',
		borderRadius: 3,
		borderStyle: 'solid',
		borderWidth: 1,
		minWidth: 300,
	},
	dropdownMenu: {
		background: '#fff',
		border: '1px solid #E2E2E2',
		boxShadow: '0 0 6px 0 rgba(184,184,184,0.30)',
		width: '100% !important',
	},
	dropdownMenuContent: {
		maxHeight: 300,
		overflowY: 'scroll',
		position: 'relative',
	},
	dropdownMenuContentItem: {
		':hover': {
			background: '#f2f2f2',
		},
		alignItems: 'center',
		color: '#4A4A4A',
		cursor: 'pointer',
		display: 'flex',
		fontSize: 14,
		justifyContent: 'space-between',
		lineHeight: '22px',
		padding: 10,
	},
	dropdownMenuContentItemAddSignatureButton: {},
	dropdownMenuContentItemEditButton: {
		color: brandPrimaryHover,
		fontSize: 14,
		lineHeight: '22px',
	},
	dropdownMenuContentItemName: {
		width: '80%',
	},
	dropdownMenuContentScrollable: {
		maxHeight: 105, // lines (2.5) x dropdownMenuContentItem height (42)
	},
	dropdownMenuInline: {
		marginLeft: -1,
		maxHeight: 125,
		minWidth: '100%',
		width: '100%',
	},
	dropdownTitle: {
		color: '#858585',
		marginRight: 12,
	},
	emptyContainer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		margin: '40px 0 50px',
		width: '100%',
	},
	emptyLink: {
		marginTop: 12,
	},
});
