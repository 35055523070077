import { brandSecondary, titles } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		boxSizing: 'border-box',
		color: titles,
		fontSize: 14,
		textAlign: 'center',
	},
	ctaButton: {
		margin: '30px auto 0 auto',
	},
	icon: {
		margin: '30px auto 10px auto',
	},
	title: {
		color: brandSecondary,
		fontSize: 18,
		marginBottom: 15,
		textAlign: 'center',
	},
});
