import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	stroke?: string;
}

export const PeopleIcon: React.FC<IProps> = ({ className, stroke = '#fff' }) => {
	const height = 19;
	const width = 22;
	return (
		<SvgIcon
			className={`people-icon ${className}`}
			fill='none'
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			width={width}
		>
			<g>
				<path
					d='M15.5455 17.8564V16.0563C15.5455 14.0678 13.9174 12.4559 11.9091 12.4559H4.63636C2.62806 12.4559 1 14.0678 1 16.0563V17.8564'
					stroke={stroke}
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<ellipse
					cx='8.27272'
					cy='5.25524'
					rx='3.63636'
					ry='3.60034'
					stroke={stroke}
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M21 17.8564V16.0562C20.9987 14.4155 19.8772 12.9831 18.2727 12.5729'
					stroke={stroke}
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M14.6364 1.77191C16.2454 2.1798 17.3708 3.61528 17.3708 5.25974C17.3708 6.9042 16.2454 8.33967 14.6364 8.74756'
					stroke={stroke}
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</g>
		</SvgIcon>
	);
};
