import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	type?: WarningIconType;
}

export enum WarningIconType {
	Blue,
	Red,
}

export const WarningIcon: React.FC<IProps> = props => {
	const { className } = props;
	return (
		<SvgIcon className={`warning-icon ${className || ''}`} height={68} width={90}>
			<g fill='none' fillRule='evenodd'>
				<path
					fill='#FFF'
					stroke={props.type === WarningIconType.Red ? '#D0021B' : '#00AAE8'}
					strokeWidth='2'
					d='M52.5660788,3.16738652 C52.3153319,2.75336256 51.968128,2.40615867 51.5541041,2.15541177 C50.1368979,1.29710379 48.2922292,1.75018032 47.4339212,3.16738652 L11.5328521,62.4458959 C11.2489997,62.9145825 11.0989309,63.452059 11.0989309,64 C11.0989309,65.6568542 12.4420767,67 14.0989309,67 L85.9010691,67 C86.4490101,67 86.9864866,66.8499313 87.4551732,66.5660788 C88.8723794,65.7077708 89.3254559,63.8631021 88.4671479,62.4458959 L52.5660788,3.16738652 Z'
				/>
				<path
					fill={props.type === WarningIconType.Red ? '#FDDFE3' : '#E8F3F9'}
					d='M50.9159883,4.93202066 L86.0842689,63.1853052 C86.3697069,63.6581091 86.2178168,64.2727857 85.7450128,64.5582237 C85.5890599,64.6523746 85.410351,64.7021364 85.2281816,64.7021364 L14.77486,64.7021364 C14.2225752,64.7021364 13.77486,64.2544212 13.77486,63.7021364 C13.77486,63.5194686 13.8248943,63.3402901 13.9195321,63.1840493 L49.2045732,4.93076477 C49.4907048,4.45838025 50.1056036,4.30739245 50.5779882,4.59352407 C50.7163801,4.67735053 50.8323651,4.79350581 50.9159883,4.93202066 Z'
				/>
				<polygon fill={props.type === WarningIconType.Red ? '#D0021B' : '#00AAE8'} points='48 27 52 27 51 45 49 45' />
				<circle cx='50' cy='51' r='2' fill={props.type === WarningIconType.Red ? '#D0021B' : '#00AAE8'} />
				<rect
					width='17'
					height='3'
					x='61'
					y='25'
					fill={props.type === WarningIconType.Red ? '#FDDFE3' : '#E8F3F9'}
					rx='1.5'
				/>
				<rect
					width='10'
					height='3'
					x='80'
					y='25'
					fill={props.type === WarningIconType.Red ? '#FDDFE3' : '#E8F3F9'}
					rx='1.5'
				/>
				<rect
					width='24'
					height='3'
					x='61'
					y='30'
					fill={props.type === WarningIconType.Red ? '#FDDFE3' : '#E8F3F9'}
					rx='1.5'
				/>
				<rect
					width='17'
					height='3'
					x='11'
					y='47'
					fill={props.type === WarningIconType.Red ? '#FDDFE3' : '#E8F3F9'}
					rx='1.5'
				/>
				<rect width='10' height='3' y='52' fill={props.type === WarningIconType.Red ? '#FDDFE3' : '#E8F3F9'} rx='1.5' />
				<rect
					width='13'
					height='3'
					x='12'
					y='52'
					fill={props.type === WarningIconType.Red ? '#FDDFE3' : '#E8F3F9'}
					rx='1.5'
				/>
			</g>
		</SvgIcon>
	);
};
