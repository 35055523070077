import { css } from 'aphrodite';
import moment from 'moment';
import Waypoint from 'react-waypoint';
import { IUserSessionComponentProps } from '../../../../../../models/AppState';
import { IEventLoggingComponentProps } from '../../../../../../models/Logging';
import { useHistoryByContactInfiniteQuery } from '../../../../../../queries';
import { ActivityListItem } from '../../../../../components/ActivityListItem';
import { Placeholder } from '../../../../../components/Placeholder';
import { Portal } from '../../../../../components/Portal';
import ContactHistoryEmailIconUrl from './contactHistoryEmailIcon.svg';
import ContactHistoryMeetingIconUrl from './contactHistoryMeetingIcon.svg';
import ContactHistoryPhoneIconUrl from './contactHistoryPhoneIcon.svg';
import { styleSheet } from './styles';

interface IProps extends IUserSessionComponentProps, IEventLoggingComponentProps {
	className?: string;
	contactId: string;
	contactName?: string;
	hideListItemIcons?: boolean;
	itemClassName?: string;
	listClassName?: string;
	scrollToBottomWaypointPortalId?: string;
}

function getIconSrc(historyItemType: string) {
	switch (historyItemType) {
		case 'phonecall': {
			return ContactHistoryPhoneIconUrl;
		}
		case 'email': {
			return ContactHistoryEmailIconUrl;
		}
		case 'meeting': {
			return ContactHistoryMeetingIconUrl;
		}
		default: {
			return ContactHistoryEmailIconUrl;
		}
	}
}

export function ContactHistory({
	contactId,
	className,
	contactName,
	hideListItemIcons,
	itemClassName,
	scrollToBottomWaypointPortalId,
	listClassName,
}: IProps) {
	const contactHistoryQuery = useHistoryByContactInfiniteQuery({ contactId });
	// @ts-ignore
	const isEmpty = contactHistoryQuery.status === 'success' && contactHistoryQuery.data.pages[0].values[0] == null;
	const renderBottomWaypoint = () => {
		return <Waypoint bottomOffset='-200px' onEnter={() => contactHistoryQuery.fetchNextPage()} />;
	};
	return (
		<div className={`${css(styleSheet.contactHistory)} ${className || ''}`}>
			<ul className={`${css(styleSheet.contactHistoryList)} ${listClassName || ''}`}>
				{contactHistoryQuery.data
					? contactHistoryQuery.data.pages.map((page, i) => {
							// @ts-ignore
							// @ts-ignore
							// @ts-ignore
							return page.values.map((historyItem, j) => {
								return (
									<ActivityListItem
										className={`${css(styleSheet.contactHistoryItem)} ${itemClassName || ''}`}
										date={moment(historyItem.eventTime).format('MM/DD/YY')}
										// @ts-ignore
										iconSrc={hideListItemIcons ? null : getIconSrc(historyItem.type)}
										key={historyItem.id || `${i}-${j}`}
										data={historyItem.id}
									>
										{!!historyItem.header && (
											<span className={css(styleSheet.contactHistoryItemHeader)}>{historyItem.header}</span>
										)}
										{!!historyItem.body && (
											<span className={css(styleSheet.contactHistoryItemBody)}>{historyItem.body}</span>
										)}
									</ActivityListItem>
								);
							});
						})
					: null}
				{scrollToBottomWaypointPortalId ? (
					<Portal destination={scrollToBottomWaypointPortalId}>{renderBottomWaypoint()}</Portal>
				) : (
					renderBottomWaypoint()
				)}
			</ul>
			<Placeholder
				isEmpty={isEmpty}
				isLoading={contactHistoryQuery.isLoading}
				message={`You do not have past meetings or emails with ${contactName || 'this contact'}.`}
			/>
		</div>
	);
}
