import * as React from 'react';
import { alternateTitleColor, background } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';

interface Props extends React.SVGProps<SVGSVGElement> {
	strokeColor?: string;
	bgCircleFill?: string;
}

export const NotesPlaceholderIcon = ({
	className,
	width = 180,
	height = 180,
	strokeColor = alternateTitleColor,
	bgCircleFill = background,
}: Props) => {
	return (
		<SvgIcon className={className} viewBox='0 0 180 180' height={height} width={width}>
			<g fill='none' fillRule='evenodd'>
				<circle cx='90' cy='90' r='90' fill={bgCircleFill} />
				<rect width='72' height='96' x='54' y='46' stroke={strokeColor} strokeWidth='2' rx='4' />
				<rect width='66' height='90' x='57' y='49' fill='#E8F3F9' />
				<rect width='54' height='84' x='63' y='49' fill='#FFF' />
				<rect width='52' height='82' x='64' y='50' stroke={strokeColor} strokeWidth='2' />
				<g transform='translate(74 31)'>
					<path
						fill='#FFF'
						d='M6.5,11 L26.5,11 C30.0898509,11 33,13.9101491 33,17.5 L33,22 C33,23.1045695 32.1045695,24 31,24 L2,24 C0.8954305,24 1.3527075e-16,23.1045695 0,22 L0,17.5 C-4.39629938e-16,13.9101491 2.91014913,11 6.5,11 Z'
					/>
					<path
						stroke={strokeColor}
						strokeWidth='2'
						d='M6.5,12 C3.46243388,12 1,14.4624339 1,17.5 L1,22 C1,22.5522847 1.44771525,23 2,23 L31,23 C31.5522847,23 32,22.5522847 32,22 L32,17.5 C32,14.4624339 29.5375661,12 26.5,12 L6.5,12 Z'
					/>
					<path
						fill='#E8F3F9'
						d='M7,15 L26,15 C27.6568542,15 29,16.3431458 29,18 L29,20 L4,20 L4,18 C4,16.3431458 5.34314575,15 7,15 Z'
					/>
					<circle cx='16' cy='4' r='4' fill='#E8F3F9' stroke={strokeColor} strokeWidth='2' />
				</g>
				<rect width='33' height='2' x='74' y='67' fill='#D8D8D8' rx='1' />
				<rect width='32' height='1' x='74.5' y='67.5' stroke={strokeColor} rx='.5' />
				<rect width='18' height='2' x='74' y='73' fill='#D8D8D8' rx='1' />
				<rect width='17' height='1' x='74.5' y='73.5' stroke={strokeColor} rx='.5' />
				<rect width='33' height='2' x='74' y='87' fill='#D8D8D8' rx='1' />
				<rect width='32' height='1' x='74.5' y='87.5' stroke={strokeColor} rx='.5' />
				<g transform='translate(74 93)'>
					<rect width='18' height='2' fill='#D8D8D8' rx='1' />
					<rect width='17' height='1' x='.5' y='.5' stroke={strokeColor} rx='.5' />
				</g>
				<g transform='translate(74 107)'>
					<rect width='33' height='2' fill='#D8D8D8' rx='1' />
					<rect width='32' height='1' x='.5' y='.5' stroke={strokeColor} rx='.5' />
				</g>
				<g transform='translate(74 113)'>
					<rect width='18' height='2' fill='#D8D8D8' rx='1' />
					<rect width='17' height='1' x='.5' y='.5' stroke={strokeColor} rx='.5' />
				</g>
			</g>
		</SvgIcon>
	);
};
