import { brandPrimaryHover, titles } from '../../../styles/colors';
import { chipHeight } from '../../chips/Chip/styles';
import { StyleSheet } from 'aphrodite';

export const entityTokenLeftMargin = 10;
export const entityTokenTopMargin = 4;

export const styleSheet = StyleSheet.create({
	content: {
		minHeight: chipHeight,
		overflow: 'hidden',
	},
	contentTokens: {
		display: 'flex',
		flexGrow: 1,
		flexWrap: 'wrap',
		marginLeft: -entityTokenLeftMargin,
	},
	entity: {
		alignItems: 'center',
		alignSelf: 'flex-start',
		color: titles,
		display: 'inline-flex',
		fontSize: '14px',
		height: chipHeight,
		marginLeft: entityTokenLeftMargin,
		marginTop: entityTokenTopMargin,
	},
	inputField: {
		color: titles,
		display: 'inline-flex',
		flexGrow: 1,
		fontSize: '14px',
	},
	label: {
		color: '#858585',
		fontSize: '12px',
	},
	mentionFieldInput: {
		':focus': {
			outline: 'none',
		},
		':placeholder': {
			color: '#8E8E8E',
			opacity: 0.5,
		},
		background: 'none',
		border: 'none',
		color: titles,
		flexGrow: 1,
		fontSize: '14px',
		margin: 0,
		padding: 0,
	},
	suggestionAddEntityItem: {
		color: brandPrimaryHover,
		fontSize: '14px',
		width: '100%',
	},
	suggestionAddEntityItemIcon: {
		alignItems: 'center',
		display: 'flex',
		height: 30,
		justifyContent: 'center',
		width: 30,
	},
	suggestionAddEntityItemTitle: {
		marginLeft: 20,
	},
	suggestionAnchor: {
		display: 'inline-flex',
		height: '100%',
	},
	suggestionBody: {
		minWidth: 200,
	},
	suggestionFocused: {
		backgroundColor: '#e8f3f9',
	},
	suggestionListItem: {
		':focus': {
			outline: 'none',
		},
		':hover': {
			backgroundColor: '#E3E7E9',
		},
		':not(:last-child)': {
			borderBottom: '1px #E3E7E9 solid',
		},
		alignItems: 'center',
		boxSizing: 'border-box',
		cursor: 'pointer',
		display: 'flex',
		minHeight: 50,
		padding: '10px 20px',
	},
	suggestionListItemAvatar: {
		fontSize: '12px',
		fontWeight: 600,
		height: 30,
		marginRight: 20,
		width: 30,
	},
	suggestionListItemName: {
		color: '#000000',
		fontSize: '14px',
	},
	suggestionLoading: {
		padding: 20,
	},
});
