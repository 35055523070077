import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { LocationDescriptorObject } from 'history';
import produce from 'immer';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useDebounceValue } from '../../../../../hooks/useDebounceValue';
import { ILocationState } from '../../../../../models';
import { isIE11 } from '../../../../../models/Browser';
import { getContactCompanyLine, getDisplayName, isTagSearchContactFilterCriteria } from '../../../../../models/UiUtils';
import { useFullscreenModal, useUserSession } from '../../../../../models/hooks/appStateHooks';
import { useRecentSearches } from '../../../../../models/hooks/useRecentSearches';
import { useContactAutocomplete, useTagAutocompleteV2Query } from '../../../../../queries';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { TinyPopover } from '../../../../components/TinyPopover';
import { AssigneeIcon } from '../../../../components/svgs/icons/AssigneeIcon';
import { SearchIcon } from '../../../../components/svgs/icons/SearchIcon';
import { grayIconFill } from '../../../../styles/colors';
import { baseStyleSheet, bs } from '../../../../styles/styles';
import { ContactsAutocompleteResultsItem } from '../../shared/ContactsAutocompleteResultsItem';
import { ContactsNoRecentSearches } from '../../shared/ContactsNoRecentSearches';
import { ContactsPagePeopleSearchTab, getCriteriaFromTab } from '../PeopleContext';
import { styleSheet } from './styles';

const pageSize = 3;
export const RecentPeopleSearchesStorageKey = 'RecentPeopleSearches';
const autoCompleteDebounce = isIE11() ? 300 : 50;

interface IProps {
	search: string;
	onSearchChange: (search: string) => void;
	searchTab: ContactsPagePeopleSearchTab;
	filter: Api.IBulkContactsRequest;
	onFilterChange: (filter: Api.IBulkContactsRequest) => void;
}

export const PeopleGlobalSearch = observer(({ search, onSearchChange, searchTab, onFilterChange, filter }: IProps) => {
	const userSession = useUserSession();
	const { history } = useFullscreenModal();
	const { recentSearches, addToRecent } = useRecentSearches(RecentPeopleSearchesStorageKey);
	const [isOpen, setIsOpen] = React.useState(false);
	const [highlight, setHighlight] = React.useState(-1);
	const inputRef = React.useRef<HTMLInputElement>(null);
	const debouncedSearch = useDebounceValue(search, autoCompleteDebounce);
	const contactAutocompleteQuery = useContactAutocomplete({
		enabled: Boolean(debouncedSearch),
		pageSize,
		enforcePageSize: true,
		fragment: debouncedSearch,
		searchFields: 'nameParts',
	});
	let contactResults: Api.IContact[][] = [];
	if (contactAutocompleteQuery.isSuccess) {
		const contactResultMap = new Map<string, Api.IContact[]>();
		for (const contact of contactAutocompleteQuery.data.pages[0].values) {
			const displayName = getDisplayName(contact);
			if (contactResultMap.has(displayName)) {
				contactResultMap.get(displayName).push(contact);
			} else {
				contactResultMap.set(displayName, [contact]);
			}
		}
		contactResults = Array.from(contactResultMap.values());
	}
	const contactsLength = contactAutocompleteQuery.isSuccess
		? contactAutocompleteQuery.data.pages[0].values.length
		: null;
	const totalContacts = contactAutocompleteQuery.isSuccess ? contactAutocompleteQuery.data.pages[0].totalCount : null;
	const hasMoreContacts = contactsLength != null && totalContacts != null ? totalContacts > contactsLength : null;
	const tagAutocompleteQuery = useTagAutocompleteV2Query({
		enabled: Boolean(debouncedSearch),
		pageSize,
		query: debouncedSearch,
		enforcePageSize: true,
	});
	const tagResults = tagAutocompleteQuery.isSuccess ? tagAutocompleteQuery.data.pages[0].values : [];
	const tagsLength = tagAutocompleteQuery.data?.pages[0].values.length ?? 0;
	const onSearch = ({ value = search, newTab }: { value?: string; newTab?: ContactsPagePeopleSearchTab } = {}) => {
		const nextFilter = produce(filter, draftFilter => {
			const newCriteria = draftFilter.filter.criteria.filter(x => {
				return (
					x.property !== Api.ContactFilterCriteriaProperty.All &&
					x.property !== Api.ContactFilterCriteriaProperty.Company &&
					x.property !== Api.ContactFilterCriteriaProperty.Name &&
					!isTagSearchContactFilterCriteria(x)
				);
			});
			newCriteria.push({
				property: getCriteriaFromTab(newTab ?? searchTab),
				value,
			});
			draftFilter.filter.criteria = newCriteria;
		});
		onFilterChange(nextFilter);
		setIsOpen(false);
	};
	const addTagToSearch = (tag: string) => {
		const nextFilter = produce(filter, draftFilter => {
			const criteriaWithNoTags = draftFilter.filter.criteria.filter(x => !isTagSearchContactFilterCriteria(x));

			const tagCriteria = draftFilter.filter.criteria.find(x => x.op === Api.FilterOperator.Or);

			// add tag to the list, or create an empty list if not present
			const tagCriterionSafe = tagCriteria ?? {
				criteria: [],
				op: Api.FilterOperator.Or,
			};
			// don't allow dupes

			tagCriterionSafe.criteria = tagCriterionSafe.criteria.filter(x => x.value !== tag);
			tagCriterionSafe.criteria.push({
				property: Api.ContactFilterCriteriaProperty.Tag,
				value: tag,
			});

			draftFilter.filter.criteria = [...criteriaWithNoTags, tagCriterionSafe];
		});
		onFilterChange(nextFilter);
	};
	const onTagSearched = (tag: Api.IAccountTag) => () => {
		addTagToSearch(tag.tag);
		setIsOpen(false);

		inputRef.current?.blur();
		onSearchChange('');
		addToRecent({
			tag,
			type: 'tag',
		});
	};

	const onContactSearched = (contact: Api.IContact) => () => {
		const locationState: ILocationState<Api.EntityViewModel, Api.IEntity> = {
			viewModel: new Api.ContactViewModel(userSession, contact),
		};
		const redirection: LocationDescriptorObject = {
			pathname: `/people/${contact.id}`,
			state: locationState,
		};
		history.push(redirection);
		addToRecent({
			entity: contact,
			type: 'contact',
		});
		setIsOpen(false);
		inputRef.current?.blur();
	};

	const onViewMoreContacts = () => {
		onSearch({ newTab: 'Name' });
		setIsOpen(false);
		inputRef.current?.blur();
		onSearchChange('');
	};

	const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			if (!search && highlight < 0) {
				return;
			}
			if (search === '') {
				const recent = recentSearches[highlight];
				const action =
					recent.type === 'contact'
						? onContactSearched(recent.entity as Api.IContact)
						: onTagSearched(recent.tag as Api.IAccountTag);
				action();
				return;
			}
			if (highlight === -1) {
				onSearch();
				return;
			}
			const results = [...contactResults, hasMoreContacts ? { contacts: 'more' } : null, ...tagResults].filter(x =>
				Boolean(x)
			);
			const selectedItem = results[highlight] as any;
			if ('tag' in selectedItem) {
				onTagSearched(selectedItem)();
			} else if ('id' in selectedItem) {
				onContactSearched(selectedItem)();
			} else if ('contacts' in selectedItem) {
				onViewMoreContacts();
			}
		} else if (event.key === 'ArrowDown') {
			const contactsLengthWithMore = contactsLength + (hasMoreContacts ? 1 : 0);
			if (search === '' && highlight < recentSearches.length - 1) {
				setHighlight(highlight + 1);
			} else if (highlight < contactsLengthWithMore + tagsLength - 1) {
				setHighlight(highlight + 1);
			}
		} else if (event.key === 'ArrowUp') {
			if (highlight > -1) {
				setHighlight(highlight - 1);
			}
		} else {
			// set back to the input
			setHighlight(-1);
			setIsOpen(true);
		}
	};
	const handleContactResultClicked = (contactResultIndex: number) => {
		if (contactResults[contactResultIndex].length === 1) {
			onContactSearched(contactResults[contactResultIndex][0])();
			return;
		}
		const displayName = getDisplayName(contactResults[contactResultIndex][0]);
		onSearch({ newTab: 'Name', value: displayName });
	};

	return (
		<TinyPopover
			isOpen={isOpen}
			anchor={
				<div className={css(baseStyleSheet.textField, styleSheet.inputContainer)}>
					<input
						ref={inputRef}
						autoComplete='off'
						id='people-search'
						onChange={ev => {
							const value = ev.target.value;
							onSearchChange(value);
						}}
						placeholder='Search'
						type='text'
						value={search}
						onKeyDown={onKeyDown}
						onFocus={() => setIsOpen(true)}
					/>
					<div className={css(styleSheet.searchButton)} onClick={() => onSearch()}>
						<SearchIcon className={css(styleSheet.searchIcon)} fillColor='white' />
					</div>
				</div>
			}
			anchorStyles={[styleSheet.inputAnchor]}
			onRequestClose={() => setIsOpen(false)}
			dismissOnOutsideAction={true}
			placement={['bottom']}
		>
			<div className={css(styleSheet.autocompleteContainer)}>
				{search ? (
					<div>
						<div className={css(styleSheet.title)}>contacts</div>
						{contactAutocompleteQuery.status !== 'success' ? (
							<div>
								<LoadingSpinner />
							</div>
						) : null}
						{totalContacts === 0 ? (
							<div className={css(bs.flex, bs.itemsCenter, bs.textNavigation, bs.textSm, bs.italic, bs.h8, bs.pl12)}>
								No results matching
								<span className={css(bs.fontBold, bs.italic, bs.pl1)}>{search}</span>
							</div>
						) : null}
						{contactResults.map((contacts, i) => {
							const contact = contacts[0];
							return (
								<div
									key={contact.id}
									className={css(
										bs.flex,
										bs.itemsCenter,
										bs.gap2,
										bs.py1,
										bs.px12,
										bs.cursorPointer,
										bs.truncateText,
										styleSheet.contactDropdownItem,
										i === highlight ? styleSheet.contactDropdownItemHighlighted : undefined
									)}
									onClick={() => handleContactResultClicked(i)}
								>
									<div className={css(bs.relative)}>
										<AssigneeIcon fillColor={grayIconFill} />
									</div>
									<div className={css(bs.flex, bs.itemsCenter)}>
										<span>{getDisplayName(contact)}</span>
									</div>
									{contact.companyName ? (
										<div className={css(bs.textNavigation, bs.textXs)}>{getContactCompanyLine(contact)}</div>
									) : null}
									{contacts.length > 1 ? (
										<span
											className={css(
												bs.boxBorder,
												bs.flex,
												bs.itemsCenter,
												bs.justifyCenter,
												bs.aspectSquare,
												bs.p1,
												bs.boxBorder,
												bs.bgBrandPrimary,
												bs.roundedFull,
												bs.textWhite,
												bs.textXs,
												bs.fontBold,
												bs.minW6
											)}
										>
											{contacts.length}
										</span>
									) : null}
								</div>
							);
						})}
						{hasMoreContacts ? (
							<div
								style={{ backgroundColor: highlight === contactsLength ? 'rgba(232, 243, 249, 0.5)' : undefined }}
								className={css(bs.flex, bs.itemsCenter, bs.textNavigation, bs.textSm, bs.italic, bs.h8, bs.pl12)}
							>
								<span>and {totalContacts - pageSize} more...</span>
								<span className={css(bs.brandLink)} onClick={onViewMoreContacts}>
									view results
								</span>
							</div>
						) : null}
						<div className={css(styleSheet.title)}>tags</div>
						{tagAutocompleteQuery.status !== 'success' ? (
							<div>
								<LoadingSpinner />
							</div>
						) : null}
						{tagAutocompleteQuery.data?.pages[0].totalCount === 0 ? (
							<div className={css(bs.flex, bs.itemsCenter, bs.textNavigation, bs.textSm, bs.italic, bs.h8, bs.pl12)}>
								No results matching
								<span className={css(bs.fontBold, bs.italic, bs.pl1)}>{search}</span>
							</div>
						) : null}
						{tagAutocompleteQuery.data?.pages.map(page => {
							return page.values.map((tag, i) => {
								return (
									<ContactsAutocompleteResultsItem
										key={tag.id}
										onClick={onTagSearched(tag as Api.IAccountTag)}
										highlight={
											contactsLength != null ? contactsLength + i + (hasMoreContacts ? 1 : 0) === highlight : false
										}
										entity={tag}
										type='tag'
									/>
								);
							});
						})}
					</div>
				) : (
					<div>
						<div className={css(styleSheet.title)}>recent searches</div>
						<div>
							{recentSearches.length ? (
								recentSearches.map((x, i) => (
									<ContactsAutocompleteResultsItem
										key={i}
										type={x.type}
										highlight={highlight === i}
										entity={x.type === 'tag' ? x.tag : x.entity}
										onClick={
											x.type === 'contact'
												? onContactSearched(x.entity as Api.IContact)
												: onTagSearched(x.tag as Api.IAccountTag)
										}
										className={css(styleSheet.recentSearch)}
									/>
								))
							) : (
								<ContactsNoRecentSearches />
							)}
						</div>
					</div>
				)}
			</div>
		</TinyPopover>
	);
});
