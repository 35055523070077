import * as Api from '@ViewModels';
import * as React from 'react';
import { useEventLogging } from '../../../models/Logging';
import { useErrorMessages } from '../../../models/hooks/appStateHooks';

export const useContactTagsUtils = (contactVm: Api.ContactViewModel) => {
	const [tags, setTags] = React.useState<Api.IAccountTag[]>(contactVm.tags?.map(tag => ({ tag })) || []);
	const errorMessages = useErrorMessages();
	const { logEvent } = useEventLogging('ContactInfoTags');

	const updateTags = (oldTags: Api.IAccountTag[], newTags: Api.IAccountTag[], eventName: string) => {
		const promise = contactVm.updateTags(newTags.map(({ tag }) => tag));
		if (promise) {
			logEvent(eventName, { count: newTags.length });
			setTags(newTags);
			promise.catch((error: Api.IOperationResultNoValue) => {
				setTags(oldTags);
				logEvent(`${eventName}-Error`, { ...error });
				errorMessages.pushApiError(error);
			});
		}
	};
	const onRequestAddTag = (accountTag: Api.IAccountTag) => {
		if (
			tags.length &&
			accountTag.tag &&
			tags.find(({ tag }) => tag.toLocaleLowerCase() === accountTag.tag.toLocaleLowerCase())
		) {
			return;
		}
		const newTags: Api.IAccountTag[] = [...tags, accountTag];
		updateTags([...tags], newTags, 'AddTag');
	};
	const onRequestRemoveTag = (accountTag: Api.IAccountTag) => {
		const newTags = [...tags];
		const index = newTags.findIndex(({ tag }) => tag === accountTag.tag);
		if (index >= 0) {
			newTags.splice(index, 1);
			updateTags([...tags], newTags, 'RemoveTag');
		}
	};

	return { tags, onRequestAddTag, onRequestRemoveTag };
};
