import { brandSecondary, titles } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		background: '#F9F9F9',
		boxSizing: 'border-box',
		padding: 20,
		position: 'relative',
	},
	loading: {},
	statsTable: {
		boxSizing: 'border-box',
		marginTop: 26,
		padding: '0 10px 0 10px',
	},
	statsTableContent: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		margin: '-10px 0 0 -10px',
		overflow: 'hidden',
	},
	statsTableMetric: {
		margin: '10px 0 0 10px',
	},
	statsTableMetricTitle: {
		color: '#AAAAAA',
		fontSize: 11,
		letterSpacing: '1.1px',
		textTransform: 'uppercase',
	},
	statsTableMetricValue: {
		color: brandSecondary,
		fontSize: 16,
		fontWeight: 700,
		lineHeight: '22px',
		marginTop: 7,
	},
	timeSpanFilter: {
		'@media only screen and (max-width: 900px)': {
			marginTop: 20,
		},
		alignItems: 'center',
		color: '#858585',
		display: 'flex',
		fontSize: 14,
		letterSpacing: 0,
	},
	timeSpanFilterSelect: {
		marginLeft: 10,
		width: 165,
	},
	timeSpanSelectOption: {
		width: 163,
	},
	title: {
		'@media only screen and (max-width: 900px)': {
			display: 'block',
		},
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
	},
	titleText: {
		color: titles,
		fontSize: 16,
		lineHeight: '22px',
	},
});
