import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { ITextInputProps, TextInput } from '../TextInput';
import { styleSheet } from './styles';

interface IProps extends ITextInputProps {
	label?: React.ReactNode;
	labelStyles?: StyleDeclarationValue[];
	styles?: StyleDeclarationValue[];
}

export const TextInputFormField: React.FC<IProps> = props => {
	const { className, label, styles = [], labelStyles = [], ...restProps } = props;
	return (
		<div className={`${css(styleSheet.container, ...styles)} text-input-form-field ${className || ''}`}>
			{!!label && <div className={`text-input-form-field-label ${css(styleSheet.label, ...labelStyles)}`}>{label}</div>}
			<TextInput {...restProps} className='text-input-form-field-input' />
		</div>
	);
};
