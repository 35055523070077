import { styleSheet } from './styles';
import * as Avatar from '@radix-ui/react-avatar';
import { StyleDeclaration, css } from 'aphrodite';

export function Avatar2({
	styleDeclaration,
	imgSrc,
	altText,
	fallbackText,
}: {
	styleDeclaration?: StyleDeclaration;
	imgSrc?: string;
	altText?: string;
	fallbackText: string;
}) {
	return (
		<Avatar.Root className={css(styleSheet.root, styleDeclaration)}>
			{imgSrc ? <Avatar.Image className={css(styleSheet.image)} src={imgSrc} alt={altText} /> : null}
			<Avatar.Fallback className={css(styleSheet.fallback)}>{fallbackText}</Avatar.Fallback>
		</Avatar.Root>
	);
}
