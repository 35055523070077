import { StyleSheet } from 'aphrodite';
import { background, inputBorderColor, titles } from '../../../styles/colors';

export const styleSheet = StyleSheet.create({
	container: {
		':nth-child(1n) > *:not(:first-child)': {
			marginLeft: 10,
		},
		alignItems: 'center',
		display: 'flex',
		flexGrow: 1,
		flexWrap: 'wrap',
	},
	input: {
		':focus': {
			outline: 'none',
		},
		background,
		border: `1px solid ${inputBorderColor}`,
		boxSizing: 'border-box',
		borderRadius: 8,
		color: titles,
		flexGrow: 1,
		fontSize: 12,
		padding: '11px 10px 11px 30px',
		width: 'auto',
	},
	inputWrap: {
		flexGrow: 1,
		position: 'relative',
	},
	inputCompact: {
		flexGrow: 'initial',
		minWidth: 0,
		width: '100%',
	},
	searchIcon: {
		position: 'absolute',
		left: 10,
		top: '50%',
		transform: 'translateY(-50%)',
	},
});
