import { StyleSheet } from 'aphrodite';
import { urgentDate, warningDark, warningLight } from '../../../styles/colors';

export const styleSheet = StyleSheet.create({
	container: {
		borderRadius: '3px',
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'row',
		padding: '10px 12px',
	},
	content: {
		color: '#4A4A4A',
		fontSize: '14px',
		lineHeight: '22px',
		paddingLeft: '4px',
	},
	errorMessage: {
		background: '#FFE7EA',
		border: `1px solid ${urgentDate}`,
	},
	icon: {
		display: 'flex',
		flexShrink: 0,
		paddingRight: '4px',
	},
	warningMessage: {
		background: warningLight,
		border: `1px solid ${warningDark}`,
	},
});
