import { css } from 'aphrodite';
import { inject } from 'mobx-react';
import * as React from 'react';
import { IModalContext, ModalChildComponentContextKey } from '../../../models';
import { baseStyleSheet } from '../../styles/styles';
import { DeprecatedCloseButton } from '../DeprecatedCloseButton';
import { asModalComponent } from '../Modal';
import { styleSheet } from './styles';

export const DefaultDeleteConfirmationOptions: IConfirmationDialogOption<boolean>[] = [
	{
		isDestructive: true,
		representedObject: true,
		title: 'Delete',
	},
	{
		isCancel: true,
		representedObject: false,
		title: 'Cancel',
	},
];

export const DefaultConfirmationOptions: IConfirmationDialogOption<boolean>[] = [
	{
		isDestructive: true,
		representedObject: true,
		title: 'Confirm',
	},
	{
		isCancel: true,
		representedObject: false,
		title: 'Cancel',
	},
];

export interface IConfirmationDialogOption<T = any> {
	disabled?: boolean;
	isCancel?: boolean;
	isCancelLink?: boolean;
	isCta?: boolean;
	isDestructive?: boolean;
	key?: string | number;
	representedObject?: T;
	title: React.ReactNode;
}

export interface IConfirmationDialogProps<T = any> extends IModalContext<IConfirmationDialogOption<T>> {
	className?: string;
	footer?: React.ReactNode;
	icon?: React.ReactNode;
	options?: IConfirmationDialogOption<T>[];
	stackButtonsVertically?: boolean;
	title?: React.ReactNode;
}

class _ConfirmationDialog<T = any> extends React.Component<IConfirmationDialogProps<T>> {
	public render() {
		const { className, options, icon, title, children, footer, stackButtonsVertically } = this.props;
		return (
			<div className={`confirmation-dialog ${className || ''}`}>
				<div className={css(styleSheet.header)}>
					<DeprecatedCloseButton onClick={this.onCloseClicked} />
				</div>
				<div className={`body ${css(styleSheet.body)}`}>
					{!!icon && <div className={css(styleSheet.icon)}>{icon}</div>}
					{!!title && <div className={css(styleSheet.title)}>{title}</div>}
					{children}
				</div>
				{((!!options && options.length > 0) || !!footer) && (
					<div
						className={css(
							styleSheet.footer,
							stackButtonsVertically
								? [baseStyleSheet.verticalStack, styleSheet.footerVertical]
								: baseStyleSheet.horizontalStack
						)}
					>
						{/* @ts-ignore */}
						{options.map((x, i) => {
							let optionClassName = x.isDestructive
								? css(baseStyleSheet.ctaButtonDestructiveSolid)
								: css(baseStyleSheet.ctaButtonSecondary);
							optionClassName = x.isCancel ? css(baseStyleSheet.ctaButtonReverse) : optionClassName;
							optionClassName = x.isCancelLink ? css(baseStyleSheet.minorActionLink) : optionClassName;
							optionClassName = x.isCta ? css(baseStyleSheet.ctaButton) : optionClassName;

							return (
								<button
									className={optionClassName}
									disabled={x.disabled}
									key={x.key || i}
									onClick={this.onOptionSelected(x)}
								>
									{x.title}
								</button>
							);
						})}
						{footer}
					</div>
				)}
			</div>
		);
	}

	private onOptionSelected = (option: IConfirmationDialogOption<T>) => () => {
		const { parentModal } = this.props;
		if (parentModal) {
			parentModal.onRequestClose(option, option.isCancel || option.isCancelLink);
		}
	};

	private onCloseClicked = () => {
		const { parentModal, options } = this.props;
		if (parentModal) {
			parentModal.onRequestClose(
				(options || []).find(x => x.isCancel || x.isCancelLink),
				true
			);
		}
	};
}

const ConfirmationDialogWithContext = inject(ModalChildComponentContextKey)(_ConfirmationDialog);

export const ConfirmationDialog = asModalComponent(ConfirmationDialogWithContext, {
	className: 'confirmation-dialog-modal',
});
