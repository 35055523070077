import { Modal } from '../../components/Modal';
import { TextInput } from '../../components/TextInput';
import { CountrySelector } from '../../components/ValueSelectors/CountrySelector';
import { INameValue } from '../../components/ValueSelectors/ValueSelector';
import { baseStyleSheet } from '../../styles/styles';
import { styleSheet } from './styles';
import * as Api from '@ViewModels';
// eslint-disable-next-line import/no-internal-modules
import { yupResolver } from '@hookform/resolvers/yup';
import { css } from 'aphrodite';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

const validationSchema = yup
	.object({
		address1: yup.string().required('Address line 1 is required'),
		address2: yup.string(),
		city: yup.string().required('City is required'),
		country: yup.string().required('Country is required'),
		firstName: yup.string(),
		lastName: yup.string(),
		postalCode: yup.string().required('Postal Code is required'),
		stateProvince: yup.string().required('State/Province is required'),
	})
	.required();

interface IFormData {
	address1: string;
	address2?: string;
	city: string;
	country: string;
	firstName?: string;
	lastName?: string;
	postalCode: string;
	stateProvince: string;
}

export const ReturnContactModal = ({
	initialRecipient,
	isOpen,
	onSave,
	onCancel,
}: {
	initialRecipient?: Api.IHandwrittenCardReturnContact;
	onSave: (hwcRecipient: IFormData) => void;
	onCancel: () => void;
	isOpen: boolean;
}) => {
	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		defaultValues: {
			address1: initialRecipient?.address?.address1 ?? '',
			address2: initialRecipient?.address?.address2 ?? '',
			city: initialRecipient?.address?.city ?? '',
			country: initialRecipient?.address?.country ?? '',
			firstName: initialRecipient?.firstName ?? '',
			lastName: initialRecipient?.lastName ?? '',
			postalCode: initialRecipient?.address?.postalCode ?? '',
			stateProvince: initialRecipient?.address?.stateProvince ?? '',
		},
		resolver: yupResolver(validationSchema),
	});

	return (
		<Modal shouldCloseOnOverlayClick={false} useDefaultHeader isOpen={isOpen} onRequestClose={onCancel}>
			<form onSubmit={handleSubmit(onSave)}>
				<div className={css(styleSheet.modalHeader)}>
					<h1 className={css(styleSheet.modalHeaderName)}>Add Return Address</h1>
				</div>
				<div className={css(styleSheet.addresslineGroup)}>
					<div className={css(styleSheet.addresslineCol)}>
						<label className={css(styleSheet.label)} htmlFor='address-modal-firstName-input'>
							First Name
						</label>
						<Controller
							name='firstName'
							control={control}
							render={({ field: { ref, ...fieldProps } }) => (
								<TextInput inputId='address-modal-firstName-input' type='text' inputRef={ref} {...fieldProps} />
							)}
						/>
					</div>
					<div className={css(styleSheet.addresslineCol)}>
						<label className={css(styleSheet.label)} htmlFor='address-modal-lastName-input'>
							Last Name
						</label>
						<Controller
							name='lastName'
							control={control}
							render={({ field: { ref, ...fieldProps } }) => (
								<TextInput inputId='address-modal-lastName-input' type='text' inputRef={ref} {...fieldProps} />
							)}
						/>
					</div>
				</div>

				<label className={css(styleSheet.label, styleSheet.labelRequired)} htmlFor='address-modal-address1-input'>
					Address Line 1
				</label>
				<Controller
					name='address1'
					control={control}
					render={({ field: { ref, ...fieldProps } }) => (
						<TextInput inputId='address-modal-address1-input' type='text' inputRef={ref} {...fieldProps} />
					)}
				/>
				{errors.address1 != null ? (
					<p role='alert' className={css(styleSheet.formErrorLine)}>
						{errors.address1.message}
					</p>
				) : null}
				<label className={css(styleSheet.label)} htmlFor='address-modal-address2-input'>
					Address Line 2
				</label>
				<Controller
					name='address2'
					control={control}
					render={({ field: { ref, ...fieldProps } }) => (
						<TextInput inputId='address-modal-address2-input' type='text' inputRef={ref} {...fieldProps} />
					)}
				/>
				<div className={css(styleSheet.addresslineGroup)}>
					<div className={css(styleSheet.addresslineCol)}>
						<label className={css(styleSheet.label, styleSheet.labelRequired)} htmlFor='address-modal-city-input'>
							City
						</label>
						<Controller
							name='city'
							control={control}
							render={({ field: { ref, ...fieldProps } }) => (
								<TextInput inputId='address-modal-city-input' type='text' inputRef={ref} {...fieldProps} />
							)}
						/>
						{errors.city != null ? (
							<p role='alert' className={css(styleSheet.formErrorLine)}>
								{errors.city.message}
							</p>
						) : null}
					</div>
					<div className={css(styleSheet.addresslineCol)}>
						<label
							className={css(styleSheet.label, styleSheet.labelRequired)}
							htmlFor='address-modal-stateProvince-input'
						>
							State/Province
						</label>
						<Controller
							name='stateProvince'
							control={control}
							rules={{ required: true }}
							render={({ field: { ref, ...fieldProps } }) => (
								<TextInput inputId='address-modal-stateProvince-input' type='text' inputRef={ref} {...fieldProps} />
							)}
						/>
						{errors.stateProvince != null ? (
							<p role='alert' className={css(styleSheet.formErrorLine)}>
								{errors.stateProvince.message}
							</p>
						) : null}
					</div>
					<div className={css(styleSheet.addresslineCol)}>
						<label className={css(styleSheet.label, styleSheet.labelRequired)} htmlFor='address-modal-postalCode-input'>
							Zip/Postal Code
						</label>
						<Controller
							name='postalCode'
							control={control}
							rules={{ required: true }}
							render={({ field: { ref, ...fieldProps } }) => (
								<TextInput inputId='address-modal-postalCode-input' type='text' inputRef={ref} {...fieldProps} />
							)}
						/>
						{errors.postalCode != null ? (
							<p role='alert' className={css(styleSheet.formErrorLine)}>
								{errors.postalCode.message}
							</p>
						) : null}
					</div>
				</div>
				<label className={css(styleSheet.label, styleSheet.labelRequired)} htmlFor='address-modal-country-input'>
					Country
				</label>
				<Controller
					name='country'
					control={control}
					render={({ field: { ref, ...fieldProps } }) => (
						<CountrySelector
							onSelectionChanged={(countrySelection: INameValue) =>
								fieldProps.onChange(countrySelection.value !== 'None' ? countrySelection.value : '')
							}
							selectedValue={fieldProps.value}
						/>
					)}
				/>
				{errors.country != null ? (
					<p role='alert' className={css(styleSheet.formErrorLine)}>
						{errors.country.message}
					</p>
				) : null}
				<div className={css(styleSheet.modalFooter)}>
					<button className={css(baseStyleSheet.ctaButton)} type='submit'>
						Save
					</button>
					<button
						className={css(baseStyleSheet.outlineButtonContainer, styleSheet.customOutlineButton)}
						onClick={onCancel}
					>
						<span>Cancel</span>
					</button>
				</div>
			</form>
		</Modal>
	);
};
