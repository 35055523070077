import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	city: {
		width: '80%',
	},
	container: {
		...CssSelectors.allChildren(
			{
				marginTop: 20,
			},
			':not(:first-child)'
		),
	},
	country: {
		width: '50%',
	},
	postalCode: {
		width: '50%',
	},
	stateProvince: {
		width: '50%',
	},
});
