import { StyleSheet } from 'aphrodite';
import { brandPrimaryText, destructive } from '../../../styles/colors';

export const styleSheet = StyleSheet.create({
	archivedContainer: {
		backgroundColor: 'rgba(208, 2, 27, 0.1)',
		display: 'flex',
		left: -30,
		position: 'relative',
		top: 0,
		width: 450,
	},
	archivedText: {
		color: destructive,
		flex: 1,
		fontSize: 12,
		marginBottom: 4,
		marginLeft: 26,
		marginTop: 4,
		textAlign: 'center',
	},
	archivedTooltip: {
		margin: 4,
	},
	container: {},
	dataOriginLink: {
		textAlign: 'center',
		width: 'auto',
	},
	dataOriginLinkContainer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		marginBottom: 30,
	},
	editButton: {
		color: brandPrimaryText,
		fontSize: 14,
		position: 'absolute',
		right: 30,
		top: 60,
	},
	master: {
		boxSizing: 'border-box',
		height: '100%',
		padding: '25px 30px 30px 0',
		position: 'relative',
	},
	masterCompact: {
		boxSizing: 'border-box',
		height: '100%',
		overflowX: 'hidden',
		paddingVertical: 30,
		position: 'relative',
	},
	originDataLoadingSpinner: {
		top: 250,
	},
	toggle: {
		':hover': {
			cursor: 'pointer',
		},
		marginLeft: 10,
	},
});
