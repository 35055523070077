import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
	className?: string;
	fillColor?: string;
}

export const AutomationIcon: React.FC<IProps> = props => {
	const { className, fillColor, ...restProps } = props;
	return (
		<SvgIcon {...restProps} className={`automation-icon ${className || ''}`} height={16} width={16}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M14.398 14.4H11.2V12h3.198v2.4zm-9.598 0H1.6V12h3.2v2.4zM6.4 1.6h3.2V4H6.4V1.6zm8.798 8.8h-1.6v-.8c0-1.323-1.076-2.4-2.398-2.4H8.8V5.6h1.6a.8.8 0 00.8-.8v-4a.8.8 0 00-.8-.8H5.6a.8.8 0 00-.8.8v4a.8.8 0 00.8.8h1.6v1.6H4.8a2.403 2.403 0 00-2.4 2.4v.8H.8a.8.8 0 00-.8.8v4a.8.8 0 00.8.8h4.8a.8.8 0 00.8-.8v-4a.8.8 0 00-.8-.8H4v-.8c0-.441.36-.8.8-.8h6.4a.8.8 0 01.798.8v.8H10.4a.8.8 0 00-.8.8v4a.8.8 0 00.8.8h4.798a.8.8 0 00.8-.8v-4a.8.8 0 00-.8-.8z'
				fill={fillColor || '#00528C'}
			/>
		</SvgIcon>
	);
};
