import { StyleSheet } from 'aphrodite';
import { borderColor, brandPrimary } from '../../../../styles/colors';
import { CssSelectors, baseStyles } from '../../../../styles/styles';

export const gutter = 5;

export const styleSheet = StyleSheet.create({
	addTagButton: {
		padding: 10,
	},
	autoCompleteMenu: {
		marginTop: 2,
		width: '233px !important',
		transform: 'translateY(-7px)',
	},
	autoCompleteMenuInnerStyles: {
		backgroundColor: '#fff',
		boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
		maxHeight: '304px !important',
		overflowY: 'auto',
		overflowX: 'hidden',
	},
	autoCompleteMenuItem: {
		...baseStyles.dropdownMenuItem,
		display: 'block',
		padding: '0',
		margin: '5px 10px',
		...CssSelectors.allChildren({
			display: 'inline-flex',
		}),
	},
	autoCompleteMenuCenterAlign: {
		display: 'flex',
		justifyContent: 'center',
		padding: '20px 0',
	},
	autoCompleteMenuItemHighlighted: {
		...baseStyles.dropdownMenuItemHighlighted,
	},
	chevron: {
		marginLeft: 2,
		marginRight: 6,
	},
	chevronFlip: {
		transform: 'rotate3d(0,0,1,180deg)',
	},
	chevronIcon: {
		transform: 'rotate3d(0,0,1,0deg)',
	},
	expander: {
		height: '100%',
		overflow: 'hidden',
		position: 'relative',
		width: '100%',
	},
	sectionWarp: {
		borderBox: 'box-sizing',
		alignItems: 'center',
		display: 'flex',
		padding: 10,
		borderTop: `1px solid ${borderColor}`,
		borderBottom: `1px solid ${borderColor}`,
	},
	sectionTitle: {
		color: brandPrimary,
		fontSize: 14,
		margin: 0,
	},
	sectionIcon: {
		marginRight: 8,
		height: 16,
		width: 16,
	},
	sectionIconResent: {
		height: 20,
		width: 20,
	},
	tagList: {
		display: 'flex',
		flexWrap: 'wrap',
		margin: `0 -${gutter}px`,
	},
	tagListItem: {
		alignItems: 'center',
		display: 'inline-flex',
		margin: gutter,
	},
	tagListItemFooter: {
		':nth-child(1n) > *:not(:first-child)': {
			alignItems: 'center',
			marginLeft: 10,
		},
		alignItems: 'center',
		display: 'inline-flex',
		margin: gutter,
	},
	noResult: {
		display: 'block',
		padding: 10,
		fontSize: 14,
	},
});
