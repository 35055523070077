import {
	AppState,
	ErrorMessagesViewModelKey,
	GamificationViewModelKey,
	ToasterViewModelKey,
	WebsocketsViewModelKey,
} from '../models/AppState';

export const useErrorMessages = () => {
	return AppState[ErrorMessagesViewModelKey];
};

export const useGamification = () => {
	return AppState[GamificationViewModelKey];
};

export const useToaster = () => {
	return AppState[ToasterViewModelKey];
};

export const useWebsockets = () => {
	return AppState[WebsocketsViewModelKey];
};
