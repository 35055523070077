import { navigation } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
	onMouseEnter?: React.MouseEventHandler<SVGSVGElement>;
	onMouseLeave?: React.MouseEventHandler<SVGSVGElement>;
}

export const QuestionMarkIcon: React.FC<IProps> = props => {
	const { className, fillColor, onMouseLeave, onMouseEnter } = props;
	return (
		<SvgIcon
			className={`question-mark-icon ${className || ''}`}
			height={14}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			width={14}
		>
			<g fill={fillColor || navigation} fillRule='evenodd'>
				<path
					fillRule='nonzero'
					d='M7,1.4 C3.9123,1.4 1.4,3.9123 1.4,7 C1.4,10.0877 3.9123,12.6 7,12.6 C10.0877,12.6 12.6,10.0877 12.6,7 C12.6,3.9123 10.0877,1.4 7,1.4 M7,14 C3.1402,14 0,10.8605 0,7 C0,3.1402 3.1402,0 7,0 C10.8598,0 14,3.1402 14,7 C14,10.8605 10.8598,14 7,14'
				/>
				<path d='M7.7 9.1L6.3 9.1 6.3 7 7 7C7.7721 7 8.4 6.3721 8.4 5.6 8.4 4.8272 7.7721 4.2 7 4.2 6.2279 4.2 5.6 4.8272 5.6 5.6L4.2 5.6C4.2 4.0558 5.4558 2.8 7 2.8 8.5442 2.8 9.8 4.0558 9.8 5.6 9.8 6.902 8.9061 7.9996 7.7 8.3118L7.7 9.1zM7.875 10.5C7.875 10.983 7.483 11.375 7 11.375 6.517 11.375 6.125 10.983 6.125 10.5 6.125 10.017 6.517 9.625 7 9.625 7.483 9.625 7.875 10.017 7.875 10.5' />
			</g>
		</SvgIcon>
	);
};
