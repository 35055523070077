import { darkGrayFontColor } from '../../../styles/colors';
import { StyleSheet, css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	hideBorder?: boolean;
	title?: string;
}

export const styleSheet = StyleSheet.create({
	container: {
		':not(:first-of-type)': {
			marginTop: 20,
		},
		borderTop: '1px solid #D8D8D8',
		boxSizing: 'border-box',
		paddingTop: 20,
	},
	containerWithoutBorder: {
		border: 'none',
	},
	title: {
		color: darkGrayFontColor,
		fontSize: 14,
		marginBottom: 30,
		textTransform: 'uppercase',
	},
});

export class EntityInfoSection extends React.PureComponent<IProps> {
	public render() {
		const { className, children, title, hideBorder } = this.props;
		return (
			<div
				className={`${css(
					styleSheet.container,
					hideBorder ? styleSheet.containerWithoutBorder : null
				)} entity-info-section ${className || ''}`}
			>
				{!!title && (
					<div className={`${css(styleSheet.title)} truncate-text`} title={title}>
						{title}
					</div>
				)}
				{children}
			</div>
		);
	}
}
