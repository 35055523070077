import { SvgIcon } from '../../../components/svgs/icons/SvgIcon';
import { nameCircles } from '../../../styles/colors';
import * as React from 'react';

export function HwSampleW5(props: React.SVGProps<SVGSVGElement>) {
	return (
		<SvgIcon {...props} viewBox='0 0 16 11'>
			<path
				fill={nameCircles}
				fillRule='evenodd'
				d='M1.465 9.85c.38.836 1.148 1.215 2.044 1.104 1.061-.13 1.904-.65 2.561-1.448.475-.576.856-1.23 1.312-1.9.202.09.472.134.616.29.451.495.837 1.047 1.281 1.548.45.509 1.008.872 1.665 1.084.418.135.81.116 1.226-.014.765-.238 1.305-.745 1.726-1.39 1.25-1.921 1.512-4.044 1.225-6.266-.014-.117-.188-.233-.31-.313-.147-.094-.54.177-.553.38-.045.667-.092 1.335-.116 2.004-.034.937-.285 1.828-.657 2.669-.233.525-.607.998-.973 1.45-.313.389-1.194.488-1.644.22-.284-.168-.57-.358-.796-.596-.936-.983-1.599-2.14-2.126-3.386-.189-.446-.175-1.01-.663-1.305-.39.22-.61.558-.636 1a7.403 7.403 0 0 1-.649 2.67c-.395.868-.996 1.545-1.854 1.978-.147.075-.31.119-.469.17-.49.16-1.066-.088-1.242-.565-.143-.39-.308-.79-.349-1.195a20.028 20.028 0 0 1-.087-2.258c.017-1.342.11-2.678.397-3.996.087-.398.161-.828-.082-1.217-.04-.064-.109-.156-.162-.155-.15.003-.336.005-.442.089C1.59.595 1.51.77 1.47.926c-.656 2.553-.614 5.146-.4 7.743.033.402.223.804.395 1.18Z'
				clipRule='evenodd'
			/>
		</SvgIcon>
	);
}
