import { brandSecondary, grayIconFill, titles } from '../../../../web/styles/colors';
import { Layouts } from '../../../../web/styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	calendarPopoverContent: {
		boxSizing: 'border-box',
		marginBottom: 20,
		padding: '10px 0',
	},
	calendarPopoverContentHeader: {
		alignItems: 'center',
		borderBottom: '1px solid #D8D8D8',
		display: 'flex',
		fontSize: 12,
		justifyContent: 'space-between',
		margin: '0 20px 20px 20px',
		padding: '10px 0',
	},
	inputTitle: {
		color: titles,
		fontSize: 14,
		margin: '10px 0 5px',
	},
	modalButtons: {
		marginTop: 20,
		...Layouts.horizontalStack(20),
	},
	modalTitle: {
		color: brandSecondary,
		fontSize: 18,
	},
	or: {
		color: titles,
		fontSize: 12,
		margin: '0 3px',
	},
	radio: {
		marginTop: 10,
	},
	searchIcon: {
		marginRight: 6,
	},
	selectedTags: {
		display: 'flex',
		margin: '10px 0',
	},
	selectedTime: {
		background: '#f9f9f9',
		color: grayIconFill,
		marginLeft: 20,
		padding: '8px 14px',
	},
	tag: {
		':hover': {
			background: '#efefef',
		},
		fontSize: '14px',
		padding: '4px 20px',
	},
	tagInput: {
		width: 370,
	},
	tagsContainer: {
		background: 'white',
		borderRadius: 2,
		boxShadow: '0px 0px 6px rgba(139, 139, 139, 0.1)',
		maxHeight: 200,
		overflowY: 'auto',
		width: 370,
	},
	textBlack: {
		color: titles,
	},
	time: {
		alignItems: 'center',
		display: 'flex',
	},
});
