import { IEntity, IHandleAutoCompleteResult, ResourceAutoCompleteViewModelType } from '@ViewModels';
import { css } from 'aphrodite';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { PopoverPlacement } from 'react-popover';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { SizeConstraint } from '../../../../models';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../models/AppState';
import {
	CompanyViewModel,
	ContactViewModel,
	EntityViewModel,
	ResourceAutoCompleteViewModel,
} from '../../../../viewmodels/AppViewModels';
import { EntityChip } from '../../chips/EntityChip';
import { IEditorKeyboardEventListener, MentionSuggestionsPopover } from './MentionSuggestionsPopover';
import { styleSheet } from './styles';

interface IProps extends RouteComponentProps<any>, IUserSessionComponentProps {
	className?: string;
	getPopularMentionSuggestions?(trigger: string): EntityViewModel<IEntity>[];
	label?: string;
	mentionedEntities?: EntityViewModel<IEntity>[];
	onEntityClicked?(entity: EntityViewModel<IEntity>, e: React.MouseEvent<HTMLElement>): void;
	onMentionedEntitiesChanged?(mentionedEntities: EntityViewModel<IEntity>[]): void;
	placeholder?: string;
	preferredSuggestionsPopoverPlacement?: PopoverPlacement;
	readOnly?: boolean;
	sizeConstraint?: SizeConstraint;
}

interface IState {
	mentionedEntities?: EntityViewModel<IEntity>[];
	searchQuery?: string;
	showingMentionSuggestions?: boolean;
}

class _MentionField extends React.Component<IProps, IState> {
	private keyboardEventListeners: Set<IEditorKeyboardEventListener>;
	private autocompleteViewModel: ResourceAutoCompleteViewModel<IHandleAutoCompleteResult>;
	private fieldInput: HTMLInputElement;
	private ignoreBlur: boolean;
	public state: IState = {
		mentionedEntities: this.props.mentionedEntities || [],
	};

	public UNSAFE_componentWillMount() {
		this.autocompleteViewModel = new ResourceAutoCompleteViewModel<IHandleAutoCompleteResult>(this.props.userSession, {
			type: ResourceAutoCompleteViewModelType.Entity,
		});
		this.reset();
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		if (nextProps.mentionedEntities !== this.props.mentionedEntities) {
			this.setState({
				mentionedEntities: nextProps.mentionedEntities || [],
			});
		}
	}

	public render() {
		const { className = '', label, placeholder, preferredSuggestionsPopoverPlacement, readOnly } = this.props;
		const { mentionedEntities, searchQuery, showingMentionSuggestions } = this.state;
		const computedPlaceholder = Object.prototype.hasOwnProperty.call(this.props, 'placeholder')
			? placeholder
			: 'Reference contacts (optional)';
		const isEmpty = !mentionedEntities || mentionedEntities.length === 0;
		return (
			<div className={className}>
				<div className={css(styleSheet.content)} onClick={this.onContentClicked}>
					<div className={css(styleSheet.label)}>
						<span>{label || 'Who is this note about?'}</span>
					</div>
					<div className={css(styleSheet.contentTokens)}>
						{this.onRenderMentions()}
						{!readOnly && (
							<div className={css(styleSheet.entity, styleSheet.inputField)}>
								<MentionSuggestionsPopover
									addKeyboardEventListener={this.addKeyboardEventListener}
									anchor={<span> </span>}
									entities={this.entities}
									isOpen={showingMentionSuggestions}
									isSearching={this.isSearching}
									onAddCompanyClicked={this.toggleMentionSuggestions(false)}
									onAddContactClicked={this.toggleMentionSuggestions(false)}
									onEntitySelected={this.onEntitySelected}
									onMouseDown={this.onMentionSuggestionsMouseDown}
									onMouseUp={this.onMentionSuggestionsMouseUp}
									onRequestClose={this.onBlurInput}
									preferredPlacement={preferredSuggestionsPopoverPlacement || 'above'}
									removeKeyboardEventListener={this.removeKeyboardEventListener}
								/>
								<input
									style={{
										opacity: this.state.showingMentionSuggestions || (isEmpty && !!computedPlaceholder) ? 1 : 0,
										position:
											this.state.showingMentionSuggestions || (isEmpty && !!computedPlaceholder)
												? 'relative'
												: 'absolute',
									}}
									className={css(styleSheet.mentionFieldInput)}
									id='mention-field-input'
									onBlur={this.toggleMentionSuggestions(false)}
									onChange={this.onInputTextChanged}
									onFocus={this.toggleMentionSuggestions(true)}
									onKeyDown={this.onKeyDown}
									placeholder={isEmpty ? computedPlaceholder : undefined}
									ref={this.onInputRef}
									value={searchQuery || ''}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}

	private onContentClicked = () => {
		if (this.fieldInput) {
			this.fieldInput.focus();
		}
	};

	@computed
	private get searchResults() {
		const results = this.autocompleteViewModel.searchResults
			? this.autocompleteViewModel.searchResults.slice(0, 6)
			: null;
		return results;
	}

	@computed
	private get entities() {
		const { searchQuery } = this.state;
		const showPopularList = !searchQuery && !!this.props.getPopularMentionSuggestions;

		let entities: EntityViewModel<IEntity>[] = null;
		if (showPopularList) {
			entities = this.props.getPopularMentionSuggestions('#');
		} else if (!!searchQuery && !!this.searchResults) {
			entities = this.searchResults.map(({ company, contact }) => {
				return company
					? new CompanyViewModel(this.props.userSession, company)
					: new ContactViewModel(this.props.userSession, contact);
			});
		}
		return entities;
	}

	@computed
	private get isSearching() {
		return this.autocompleteViewModel.isBusy;
	}

	private onMentionSuggestionsMouseDown = () => {
		this.ignoreBlur = true;
	};

	private onMentionSuggestionsMouseUp = () => {
		this.ignoreBlur = false;
	};

	private onInputRef = (ref: HTMLInputElement) => {
		this.fieldInput = ref;
	};

	private onBlurInput = () => {
		if (this.fieldInput) {
			this.fieldInput.blur();
		}
	};

	private onEntitySelected = (entity: EntityViewModel<IEntity>) => {
		if ((this.state.mentionedEntities || []).findIndex(x => x.id === entity.id) >= 0) {
			this.setState({
				searchQuery: null,
			});
			return;
		}

		const mentionedEntities = [...(this.state.mentionedEntities || [])];
		mentionedEntities.push(entity);
		this.autocompleteViewModel.reset();

		const nextState: IState = {
			searchQuery: null,
			showingMentionSuggestions: true,
		};

		if (this.props.onMentionedEntitiesChanged) {
			this.props.onMentionedEntitiesChanged(mentionedEntities);
		} else {
			nextState.mentionedEntities = mentionedEntities;
		}

		this.setState(nextState, () => {
			if (this.fieldInput) {
				this.fieldInput.focus();
			}
		});
	};

	private toggleMentionSuggestions = (showingMentionSuggestions: boolean) => () => {
		if (!showingMentionSuggestions && this.ignoreBlur) {
			return;
		}
		this.setState({
			showingMentionSuggestions,
		});
	};

	private addKeyboardEventListener = (listener: IEditorKeyboardEventListener) => {
		this.keyboardEventListeners.add(listener);
	};

	private removeKeyboardEventListener = (listener: IEditorKeyboardEventListener) => {
		this.keyboardEventListeners.delete(listener);
	};

	private reset = () => {
		this.keyboardEventListeners = new Set<IEditorKeyboardEventListener>();
	};

	private onInputTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		const searchQuery = e.target.value || '';
		this.setState({
			searchQuery,
			showingMentionSuggestions: true,
		});

		this.autocompleteViewModel.setSearchQuery(searchQuery);
	};

	private onRenderMentions() {
		const { mentionedEntities } = this.state;
		const { readOnly, sizeConstraint } = this.props;
		return (mentionedEntities || []).map((entity, i) => {
			return (
				<EntityChip
					className={css(styleSheet.entity)}
					entity={entity}
					key={entity.id || i}
					onRemoveClicked={!readOnly ? this.onRemoveEntityClicked(entity) : undefined}
					readOnly={readOnly}
					sizeConstraint={sizeConstraint}
					onClick={this.onEntityClicked(entity)}
				/>
			);
		});
	}

	private onEntityClicked = (entity: EntityViewModel) => (e: React.MouseEvent<HTMLElement>) => {
		const { onEntityClicked } = this.props;
		if (onEntityClicked) {
			onEntityClicked(entity, e);
		}
	};

	private onRemoveEntityClicked = (entity: EntityViewModel<IEntity>) => () => {
		const mentionedEntities = [...this.state.mentionedEntities];
		mentionedEntities.splice(mentionedEntities.indexOf(entity), 1);
		this.autocompleteViewModel.reset();

		const nextState: IState = {
			showingMentionSuggestions: false,
		};

		if (this.props.onMentionedEntitiesChanged) {
			this.props.onMentionedEntitiesChanged(mentionedEntities);
		} else {
			nextState.mentionedEntities = mentionedEntities;
		}

		this.setState(nextState);
	};

	private onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		switch (e.keyCode) {
			case 38: {
				this.onUpArrow(e);
				break;
			}
			case 40: {
				this.onDownArrow(e);
				break;
			}
			case 27: {
				this.onEscape(e);
				break;
			}
			case 9: {
				this.onTab(e);
				break;
			}
			case 13: {
				this.handleReturn(e);
				break;
			}
			case 8:
			case 46: {
				// delete or backspace
				if (!this.state.searchQuery && !!this.state.mentionedEntities && this.state.mentionedEntities.length > 0) {
					// remove previous
					const mentionedEntities = [...this.state.mentionedEntities];
					mentionedEntities.pop();
					this.autocompleteViewModel.reset();

					const nextState: IState = {
						showingMentionSuggestions: true,
					};

					if (this.props.onMentionedEntitiesChanged) {
						this.props.onMentionedEntitiesChanged(mentionedEntities);
					} else {
						nextState.mentionedEntities = mentionedEntities;
					}

					this.setState(nextState, () => {
						if (this.fieldInput) {
							this.fieldInput.focus();
						}
					});
				}
				break;
			}
			default: {
				break;
			}
		}
	};

	private onDownArrow = (e: React.KeyboardEvent<HTMLElement>) => {
		this.keyboardEventListeners.forEach(listener => (listener.onDownArrow ? listener.onDownArrow(e) : null));
	};

	private onEscape = (e: React.KeyboardEvent<HTMLElement>) => {
		this.keyboardEventListeners.forEach(listener => (listener.onEscape ? listener.onEscape(e) : null));
		if (!e.defaultPrevented) {
			this.toggleMentionSuggestions(false)();
		}
	};

	private onTab = (e: React.KeyboardEvent<HTMLElement>) => {
		this.keyboardEventListeners.forEach(listener => (listener.onTab ? listener.onTab(e) : null));
	};

	private onUpArrow = (e: React.KeyboardEvent<HTMLElement>) => {
		this.keyboardEventListeners.forEach(listener => (listener.onUpArrow ? listener.onUpArrow(e) : null));
	};

	private handleReturn = (e: React.KeyboardEvent<HTMLElement>) => {
		this.keyboardEventListeners.forEach(listener => {
			listener.handleReturn(e);
		});
	};
}

const MentionFieldAsObserver = observer(_MentionField);
const MentionFieldWithContext = inject(UserSessionViewModelKey)(MentionFieldAsObserver);
export const MentionField = withRouter(MentionFieldWithContext);
