import * as Api from '@ViewModels';
import * as React from 'react';
import { useEventLogging } from '../../../../models/Logging';
import { FirstNamePlaceholder, Token } from '../../../../models/Token';
import { createContentStateWithHtmlStringValue } from '../../../../models/UiUtils';
import { useErrorMessages, useToaster, useUserSession } from '../../../../models/hooks/appStateHooks';
import { useTemplateCardCreateMutation, useUpdateTemplateMutation } from '../../../../queries';

export function useHwcSaveTemplate({
	template,
	postcardContent,
	onSavedNewTemplate,
}: {
	postcardContent: string;
	template: Api.IHandwrittenCardTemplate;
	onSavedNewTemplate?: (template: Api.IHandwrittenCardTemplate) => void;
}) {
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const toaster = useToaster();
	const { logApiError } = useEventLogging('useHwcSaveTemplate');
	const [isSaveAsPopoverShowing, setIsSaveAsPopoverShowing] = React.useState(false);
	const [isShowingSaveAsModal, setIsShowingSaveAsModal] = React.useState(false);
	const updateTemplateMutation = useUpdateTemplateMutation({
		onError: error => {
			// @ts-ignore
			errorMessages.pushApiError(error);
			logApiError('Update-Error', error);
		},
		onSuccess: () => {
			// @ts-ignore
			toaster.push({
				message: `Template "${template.name}" has been updated.`,
				type: 'successMessage',
			});
		},
	});
	const createTemplateMutation = useTemplateCardCreateMutation({
		onError: error => {
			logApiError(`Save-Error`, error);
			// @ts-ignore
			errorMessages.pushApiError(error);
		},
		onSuccess: newTemplate => {
			// @ts-ignore
			toaster.push({
				message: `Template "${newTemplate.name}" has been saved.`,
				type: 'successMessage',
			});
			onSavedNewTemplate?.(newTemplate);
			setIsShowingSaveAsModal(false);
		},
	});
	const isSaveAllowed = React.useMemo(() => {
		if (!template) {
			return false;
		}
		const isValidScope = template.scope === Api.TemplateScope.User || template.scope === Api.TemplateScope.Account;
		if (!isValidScope) {
			return false;
		}
		const isUserAuthor = template.creator?.id === userSession.user?.id;
		const isUserAdmin = userSession.userRole === 'admin' || userSession.userRole === 'superAdmin';
		return isUserAuthor || isUserAdmin;
	}, [template, userSession]);
	const toggleSaveAsPopover = () => {
		setIsSaveAsPopoverShowing(!isSaveAsPopoverShowing);
	};
	const handleSaveButtonClicked = () => {
		const content = createContentStateWithHtmlStringValue(postcardContent).getRawRichTextContent();
		if (!Token.isFirstNameTokenFormattedCorrectlyLoose(content)) {
			// @ts-ignore
			errorMessages.push({
				messages: [
					`The first name token does not appear to be formatted properly. It should be ${FirstNamePlaceholder.symbol}.`,
				],
			});
			return;
		}
		const newTemplate: Api.IHandwrittenCardTemplate = {
			...template,
			content,
		};
		updateTemplateMutation.mutate({ template: newTemplate });
	};
	const handleSaveAsButtonClicked = () => {
		const content = createContentStateWithHtmlStringValue(postcardContent).getRawRichTextContent();
		if (!Token.isFirstNameTokenFormattedCorrectlyLoose(content)) {
			// @ts-ignore
			errorMessages.push({
				messages: [
					`The first name token does not appear to be formatted properly. It should be ${FirstNamePlaceholder.symbol}.`,
				],
			});
			return;
		}
		setIsShowingSaveAsModal(true);
		setIsSaveAsPopoverShowing(false);
	};
	const handleSaveAsModalClose = () => {
		setIsShowingSaveAsModal(false);
	};
	const handleSaveAsModalSave = ({ name, scope }: { name: string; scope: Api.TemplateScope }) => {
		const content = createContentStateWithHtmlStringValue(postcardContent).getRawRichTextContent();
		const newTemplate: Api.IHandwrittenCardTemplate = {
			...template,
			content,
			name,
			parentTemplateId: template.id,
			scope,
		};
		delete newTemplate.id;
		createTemplateMutation.mutate({
			template: newTemplate,
		});
	};
	return {
		createTemplateMutation,
		handleSaveAsButtonClicked,
		handleSaveAsModalClose,
		handleSaveAsModalSave,
		handleSaveButtonClicked,
		isSaveAllowed,
		isSaveAsPopoverShowing,
		isShowingSaveAsModal,
		toggleSaveAsPopover,
		updatingTemplateStatus: updateTemplateMutation.status,
	};
}
