import { IModalContext, ModalChildComponentContextKey, SignatureTemplateEmailParserDomain } from '@AppModels/.';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IToasterComponentProps,
	IUserSessionComponentProps,
	ToasterViewModelKey,
	UserSessionViewModelKey,
} from '@AppModels/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { convertRawRichTextContentStateToRichContentEditorState, copyToClipboard } from '@AppModels/UiUtils';
import {
	IOperationResultNoValue,
	IRichContentEditorState,
	ITemplate,
	IUser,
	TemplateType,
	TemplatesViewModel,
} from '@ViewModels';
import { DeprecatedCloseButton } from '@WebComponents/DeprecatedCloseButton';
import { LoadingSpinner } from '@WebComponents/LoadingSpinner';
import { asModalComponent } from '@WebComponents/Modal';
import { TextInputFormField } from '@WebComponents/TextInputFormField';
import { RichContentDocumentEditor } from '@WebComponents/richContent/RichContentDocumentEditor';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { baseStyleSheet } from '../../../styles/styles';
import { CanSpamLink } from '../../CanSpamLink';
import { Checkbox } from '../../Checkbox';
import {
	ConfirmationDialog,
	DefaultDeleteConfirmationOptions,
	IConfirmationDialogOption,
} from '../../ConfirmationDialog';
import { TrashIcon } from '../../svgs/icons/TrashIcon';
import { WarningIcon } from '../../svgs/icons/WarningIcon';
import { styleSheet } from './styles';

interface IProps
	extends IToasterComponentProps,
		IUserSessionComponentProps,
		IErrorMessageComponentProps,
		IToasterComponentProps,
		IModalContext<ITemplate>,
		IEventLoggingComponentProps {
	className?: string;
	emailSignature?: ITemplate;
	forUser?: IUser;
	hideHeader?: boolean;
	onCancel?(): void;
	onEmailSignatureDeleted?(emailSignature: ITemplate): void;
	onEmailSignatureSaved?(emailSignature: ITemplate): void;
	onEmailSignatureEditClick?(emailSignature: ITemplate): void;
	shouldShowDeleteConfirmation?: boolean;
	templates?: TemplatesViewModel;
}

interface IState {
	contentErrorMessage?: string;
	creatingFirstSignature?: boolean;
	deleting?: boolean;
	editorContentState?: IRichContentEditorState;
	editorHasFocus?: boolean;
	emailSignature?: ITemplate;
	isDefault?: boolean;
	name?: string;
	nameErrorMessage?: string;
	saving?: boolean;
	showingDeleteConfirmation?: boolean;
	templates?: TemplatesViewModel;
}

class _EditEmailSignature extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		const nextState: IState = {
			...(_EditEmailSignature.getDerivedStateFromProps(props, {}) || {}),
			templates: props.templates || new TemplatesViewModel(props.userSession, { forUserId: props?.forUser?.id }),
		};

		if (nextState.templates.signatureTemplates.fetchResults.length === 0) {
			nextState.isDefault = true;
			nextState.name = 'Default Signature';
			nextState.creatingFirstSignature = true;
		}
		this.state = nextState;
	}

	public static getDerivedStateFromProps(props: IProps, state: IState) {
		const nextState: IState = {};

		const { emailSignature } = props;
		if ((!state.emailSignature && !!emailSignature) || state.emailSignature !== emailSignature) {
			nextState.emailSignature = emailSignature;
			nextState.name = emailSignature ? emailSignature.name || '' : '';
			nextState.isDefault = emailSignature ? emailSignature.defaultTemplate || false : false;
			nextState.editorContentState = convertRawRichTextContentStateToRichContentEditorState(
				emailSignature ? emailSignature.content : null
			);
		}

		return Object.keys(nextState).length > 0 ? nextState : null;
	}

	public render() {
		const { className, hideHeader, parentModal } = this.props;
		const {
			emailSignature,
			contentErrorMessage,
			nameErrorMessage,
			isDefault,
			saving,
			name,
			editorContentState,
			deleting,
			creatingFirstSignature,
			showingDeleteConfirmation,
		} = this.state;
		if (!emailSignature) {
			return null;
		}

		const editingExisting = !!emailSignature.id;
		const signatureTemplateParserEmailAddress = this.getSignatureTemplateParserEmailAddress();
		return (
			<div className={`${css(styleSheet.container)} edit-email-signature ${className || ''}`}>
				{!hideHeader && (
					<div>
						<div className={css(styleSheet.header)}>
							<DeprecatedCloseButton disabled={!!saving} onClick={this.onRequestClose(null)} />
						</div>
						<div className={css(styleSheet.title)}>{`${editingExisting ? 'Edit' : 'Add'} Signature`}</div>
					</div>
				)}
				{!creatingFirstSignature && (
					<div className={css(styleSheet.nameField)}>
						<TextInputFormField
							className='edit-email-signature-name-field-input'
							inputId='edit-email-signature-name-field-input'
							label={
								<span>
									<span>Signature name:</span>
									<span className='required'>*</span>
								</span>
							}
							onChange={this.onNameChanged}
							type='text'
							value={name || ''}
						/>
						{!!nameErrorMessage && <div className={css(styleSheet.error)}>{nameErrorMessage}</div>}
					</div>
				)}
				{parentModal && (
					<Checkbox
						checked={!!isDefault}
						className={css(styleSheet.defaultCheckbox)}
						id='add-email-signature-default-checkbox'
						onChange={this.onDefaultChanged}
					>
						<span>Default signature</span>
					</Checkbox>
				)}
				<div className={css(styleSheet.body)}>
					{parentModal && (
						<div className={css(styleSheet.canSpamText)}>
							{'To comply with the '}
							<CanSpamLink />, your signature should include a valid postal address.
						</div>
					)}
					<RichContentDocumentEditor
						className={css(styleSheet.documentEditor, parentModal?.name === 'modal' && styleSheet.documentEditorModal)}
						config={{ imageOptions: { base64EmbedOnInsert: true } }}
						contentState={editorContentState}
						onBlur={this.onEditorDidChangeFocus(false)}
						onContentStateChanged={this.onContentStateChanged}
						onFocus={this.onEditorDidChangeFocus(true)}
						readOnly={!parentModal}
						readOnlyUseFullEditor={parentModal?.name === 'modal'}
					/>
					{!!contentErrorMessage && <div className={css(styleSheet.error)}>{contentErrorMessage}</div>}
				</div>
				{parentModal && (
					<div className={css(styleSheet.signatureEmailMessage)}>
						<span>Already have an email signature? Compose a new email with your signature and send it to</span>
						&nbsp;
						<button
							className={css(baseStyleSheet.brandLink, styleSheet.signatureEmailMessageLink)}
							onClick={this.onSignatureTemplateMailToClicked}
							title='Copy to clipboard'
						>
							{signatureTemplateParserEmailAddress}
						</button>
						&nbsp;
						<span>and we&apos;ll take care of the rest.</span>
					</div>
				)}
				<div className={css(styleSheet.footer)}>
					<div className={css(styleSheet.footerLeft)}>
						<button
							className={css(baseStyleSheet.ctaButton)}
							disabled={!!saving}
							onClick={parentModal ? this.onSaveClicked : this.onEditClick}
						>
							{parentModal ? 'Save' : 'Edit'}
						</button>
					</div>
					{!!editingExisting && (
						<button className={css(styleSheet.deleteButton)} onClick={this.onDeleteButtonClicked}>
							<TrashIcon />
							<span className={css(styleSheet.deleteButtonText)}>Delete</span>
						</button>
					)}
				</div>
				{(!!saving || !!deleting) && <LoadingSpinner className='absolute-center' type='large' />}
				<ConfirmationDialog
					icon={<WarningIcon />}
					modalProps={{
						isOpen: !!showingDeleteConfirmation,
						onRequestClose: this.onDeleteConfirmationRequestClose,
					}}
					options={DefaultDeleteConfirmationOptions}
					title={`Are you sure you want to delete "${emailSignature?.name || ''}"`}
				/>
			</div>
		);
	}

	private getSignatureTemplateParserEmailAddress = () => {
		const { forUser, userSession } = this.props;
		return `${forUser ? forUser.id : userSession.user.id}@${SignatureTemplateEmailParserDomain}`;
	};

	private onDeleteButtonClicked = () => {
		const { shouldShowDeleteConfirmation } = this.props;
		if (shouldShowDeleteConfirmation) {
			this.setState({ showingDeleteConfirmation: true });
		} else {
			this.deleteTemplate();
		}
	};

	private onDeleteConfirmationRequestClose = (result?: IConfirmationDialogOption<boolean>, canceled?: boolean) => {
		this.setState({ showingDeleteConfirmation: false });
		if (!canceled && !!result?.representedObject) {
			this.deleteTemplate();
		}
	};

	private deleteTemplate = () => {
		const { logApiError, logInput, errorMessages, parentModal, onEmailSignatureDeleted } = this.props;
		const { templates, emailSignature } = this.state;

		const promise = templates.delete(emailSignature);
		if (promise) {
			logInput('Delete', 'Click');
			this.setState({
				deleting: true,
			});
			promise
				.then(() => {
					templates.emailTemplates.fetchResults.removeItems([emailSignature]);
					this.setState(
						{
							deleting: false,
						},
						() => {
							if (onEmailSignatureDeleted) {
								onEmailSignatureDeleted(emailSignature);
							}
							if (parentModal) {
								parentModal.onRequestClose();
							}
						}
					);
				})
				.catch((error: IOperationResultNoValue) => {
					logApiError('Delete-Error', error);
					errorMessages.pushApiError(error);
					this.setState({
						deleting: false,
					});
				});
		}
	};

	private onSignatureTemplateMailToClicked = () => {
		const { logInput, toaster } = this.props;
		logInput('SignatureTemplateMailTo', 'Click');
		const signatureEmailAddress = this.getSignatureTemplateParserEmailAddress();
		const success = copyToClipboard(signatureEmailAddress);
		if (success) {
			toaster.push({
				message: `${signatureEmailAddress} copied to your clipboard.`,
				type: 'successMessage',
			});
		}
	};

	private onContentStateChanged = (editorContentState: IRichContentEditorState) => {
		this.setState({
			contentErrorMessage: null,
			editorContentState,
			nameErrorMessage: null,
		});
	};

	private onDefaultChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		const checked = !!e.target.checked;
		this.setState({
			isDefault: checked,
		});
		this.props.logInput(`SetDefault:${checked ? 'Checked' : 'Unchecked'}`, 'Click');
	};

	private onNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			contentErrorMessage: null,
			name: e.target.value,
			nameErrorMessage: null,
		});
	};

	private getSignature = () => {
		const { emailSignature, name, isDefault, editorContentState } = this.state;

		if (!editorContentState.hasContent()) {
			this.setState({
				contentErrorMessage: 'Please enter some text before saving.',
			});
			return;
		}

		const trimmedName = (name || '').trim();
		if (!trimmedName) {
			this.setState({
				contentErrorMessage: null,
				nameErrorMessage: 'Please enter a name for this signature template.',
			});
			return;
		}

		// save updated template
		const signatureTemplate: ITemplate = {
			...(emailSignature || {}),
			content: editorContentState.getRawRichTextContent(),
			defaultTemplate: !!isDefault,
			name: trimmedName,
			templateType: TemplateType.Signature,
		};

		return signatureTemplate;
	};

	private onEditClick = () => {
		const { onEmailSignatureEditClick } = this.props;

		onEmailSignatureEditClick?.(this.getSignature());
	};

	private onSaveClicked = () => {
		const { logInput, logApiError, onEmailSignatureSaved, forUser } = this.props;
		const { templates, emailSignature, isDefault } = this.state;

		// save updated template
		const signatureTemplate: ITemplate = this.getSignature();

		const createNew = !emailSignature.id;
		const saveSignaturePromise = createNew
			? templates.create(signatureTemplate, forUser?.id || null)
			: templates.update(signatureTemplate, forUser?.id || null);
		if (saveSignaturePromise) {
			logInput(`${createNew ? 'Create' : 'Update'}SignatureTemplate`, 'Click', {
				defaultTemplate: !!isDefault,
			});

			this.setState({
				contentErrorMessage: null,
				nameErrorMessage: null,
				saving: true,
			});

			saveSignaturePromise
				.then((updatedTemplate: ITemplate) => {
					this.setState({
						saving: false,
					});
					this.onRequestClose(updatedTemplate)();
					if (onEmailSignatureSaved) {
						onEmailSignatureSaved(updatedTemplate);
					}
				})
				.catch((error: IOperationResultNoValue) => {
					this.setState({
						saving: false,
					});
					logApiError(`${createNew ? 'Create' : 'Update'}SignatureTemplate-Error`, error);
					this.props.errorMessages.pushApiError(error);
				});
		}
	};

	private onRequestClose = (editedEmailSignature?: ITemplate) => () => {
		const { parentModal, logInput, onCancel } = this.props;
		if (parentModal) {
			parentModal.onRequestClose(editedEmailSignature, !editedEmailSignature);
		}

		if (onCancel) {
			onCancel();
		}

		if (!editedEmailSignature) {
			logInput('Close', 'Click');
		}
	};

	private onEditorDidChangeFocus = (hasFocus: boolean) => () => {
		this.setState({
			contentErrorMessage: null,
			editorHasFocus: hasFocus,
			nameErrorMessage: null,
		});
	};
}

const EditEmailSignatureAsObserver = observer(_EditEmailSignature);
const EditEmailSignatureWithContext = inject(
	UserSessionViewModelKey,
	ErrorMessagesViewModelKey,
	ToasterViewModelKey,
	ModalChildComponentContextKey,
	ToasterViewModelKey
)(EditEmailSignatureAsObserver);
export const EditEmailSignature = withEventLogging(EditEmailSignatureWithContext, 'EditEmailSignature');
export const EditEmailSignatureModal = asModalComponent(EditEmailSignature, {
	className: 'edit-email-signature-modal',
});
