import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		width: '100%',
	},
	content: {
		alignItems: 'flex-start',
		display: 'flex',
		width: '100%',
	},
	editor: {
		width: '100%',
	},
	info: {
		width: '100%',
	},
	mentionsWithNoteHeader: {
		marginTop: 20,
	},
	sendButton: {
		marginTop: 20,
	},
});
