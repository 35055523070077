import { StyleDeclarationValue, css } from 'aphrodite';
import { bs } from '../../styles/styles';
import { XIcon } from '../svgs/icons/XIcon';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	styles?: StyleDeclarationValue[];
	fillColor?: string;
}

export function CloseButton({ styles = [], fillColor, ...props }: IProps) {
	return (
		<button className={css(bs.px3, bs.py2, ...styles)} {...props}>
			<XIcon width={14} height={14} fillColor={fillColor} />
		</button>
	);
}
