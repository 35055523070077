import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	backgroundColor?: string;
	fillColor?: string;
}

export const ClearFieldIcon: React.FC<IProps> = props => {
	const { className, fillColor, backgroundColor } = props;
	return (
		<SvgIcon className={`clear-field-icon ${className || ''}`} height={22} width={22}>
			<g fill='none' fillRule='evenodd'>
				<circle cx='11' cy='11' r='11' fill={backgroundColor || '#D3DBDE'} />
				<polygon
					fill={fillColor || '#FFF'}
					points='17 5.974 16.026 5 11 10.026 5.974 5 5 5.974 10.026 11 5 16.026 5.974 17 11 11.975 16.026 17 17 16.026 11.974 11'
				/>
			</g>
		</SvgIcon>
	);
};
