import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	popover: {
		borderBottomRightRadius: 0,
		boxSizing: 'border-box',
		color: '#4A4A4A',
		fontSize: 12,
		lineHeight: '18px',
		marginLeft: 14,
		maxWidth: 300,
		padding: '6px 6px 6px 6px',
	},
});
