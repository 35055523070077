import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { InternalLevitateAccountIds } from '../../../models';
import { EnvironmentKey, IEnvironmentComponentProps } from '../../../models/AppState';
import { EventLogger } from '../../../models/Logging';
import { UserSessionContext } from '../../../viewmodels/AppViewModels';

export interface IRouterLocation {
	hash: string;
	pathname: string;
	search: string;
	state: any;
}

interface IRouteComputedMatch {
	isExact: boolean;
	params?: any;
	path: string;
	url: string;
}

export interface IRoutePropsWithLoggedPageView extends RouteProps {
	pageViewLogName?: string;
}

/** Note: Does not log path where Array.isArray(this.props.path) === true; */
export class RouteWithLoggedPageView extends React.PureComponent<IRoutePropsWithLoggedPageView> {
	public componentDidMount(): void {
		const { pageViewLogName, path } = this.props;
		const pageName = pageViewLogName || (Array.isArray(path) ? null : (path as string));
		if (pageName) {
			EventLogger.logPageView(pageName);
		}
	}

	public render() {
		const { pageViewLogName, ...restProps } = this.props;
		return <Route {...restProps} />;
	}
}

export interface IPrivateRouteProps extends IRoutePropsWithLoggedPageView, IEnvironmentComponentProps {
	/**
	 * Function called right before successful rendering of the route. Return a redirection path if the route shouldn't be
	 * render.
	 */
	onLoad?(routerLocation: IRouterLocation, userSession: UserSessionContext): string;

	redirectPath?: string;
	userSession?: UserSessionContext;
}

const ConnectEmailCheckBypasses = ['/user/connect-email', '/logout'];

/**
 * Route that redirects to props.redirectPath or '/login' (default) if the user is not authenticated
 *
 * @param props RouteProps
 */
class _PrivateRoute extends React.Component<IPrivateRouteProps & { computedMatch?: IRouteComputedMatch }, any> {
	public componentDidMount(): void {
		const { pageViewLogName } = this.props;
		if (pageViewLogName) {
			EventLogger.logPageView(pageViewLogName);
		}
	}

	public render() {
		const { pageViewLogName, redirectPath, userSession, environment, onLoad, ...restProps } = this.props;
		// check to see if we're authed, if not render Redirect component to push to props.redirectPath or /login (default)
		// @ts-ignore
		if (userSession.isAuthenticated) {
			const paths = this.props.path ? (Array.isArray(this.props.path) ? this.props.path : [this.props.path]) : [];
			if (
				// @ts-ignore
				environment.appType === 'web' &&
				paths.every(path => ConnectEmailCheckBypasses.indexOf(path.toLowerCase()) === -1) &&
				// @ts-ignore
				!!userSession.user &&
				userSession.hasAnyEmailPendingActions &&
				// @ts-ignore
				!userSession.isCustomerImpersonation
			) {
				// used hasn't connected his/her email... redirect
				return (
					<Redirect
						to={{
							pathname: `/user/connect-email`,
							search: userSession.needsToReconnectEmail ? 'reconnect=true' : undefined,
							state: { from: this.props.location },
						}}
					/>
				);
			}

			// check the additional load condition, if provided
			// @ts-ignore
			const redirection = onLoad ? onLoad(this.props.location, this.props.userSession) : null;
			if (redirection) {
				return (
					<Redirect
						to={{
							pathname: redirection,
							state: { from: this.props.location },
						}}
					/>
				);
			}

			return <RouteWithLoggedPageView {...restProps} />;
		} else {
			return (
				<Redirect
					to={{
						pathname: redirectPath || '/login',
						state: { from: this.props.location },
					}}
				/>
			);
		}
	}
}

const PrivateRouteAsObserver = observer(_PrivateRoute);
export const PrivateRoute = inject(EnvironmentKey)(PrivateRouteAsObserver);

/**
 * Route that limits access where userSession.account.id in InternalAccountIds. Redirects to /dashboard if not an
 * internal account
 */
export const InternalPrivateRoute: React.FC<IPrivateRouteProps> = ({ onLoad, ...restProps }) => {
	const onLoadInternal = React.useCallback(
		(routerLocation: IRouterLocation, userSession: UserSessionContext) => {
			// @ts-ignore
			if (!!userSession && InternalLevitateAccountIds.has(userSession.account?.id)) {
				return onLoad?.(routerLocation, userSession);
			}

			return '/dashboard';
		},
		[onLoad]
	);

	// @ts-ignore
	return <PrivateRoute onLoad={onLoadInternal} {...restProps} />;
};
