import { IToggleProps, Toggle } from '../Toggle';
import * as React from 'react';

interface IProps extends IToggleProps {
	disabled?: boolean;
	privateText?: string;
	publicText?: string;
}

interface IState {
	isOn?: boolean;
}

export class PrivacyToggle extends React.PureComponent<IProps, IState> {
	public state: IState = {};

	public UNSAFE_componentWillReceiveProps(nextProps: IToggleProps) {
		if (this.state.isOn !== nextProps.isOn) {
			this.setState({ isOn: nextProps.isOn });
		}
	}

	public render() {
		const { className, disabled, publicText, privateText, isOn, text, ...restProps } = this.props;
		const isChecked = this.props.onToggleCheckChanged ? this.props.isOn : this.state.isOn;
		// Note: order matters with isOn and onToggleCheckChanged below. Add them affer applying {...restProps}
		return (
			<Toggle
				text={text ? text : isChecked ? publicText : privateText}
				className={`privacy-toggle ${className || ''}`}
				{...restProps}
				isOn={isChecked}
				onToggleCheckChanged={this.onToggleCheckChanged}
				disabled={disabled}
			/>
		);
	}

	private onToggleCheckChanged = (isOn: boolean) => {
		if (this.props.onToggleCheckChanged) {
			this.props.onToggleCheckChanged(isOn);
		} else {
			this.setState({ isOn });
		}
	};
}
